
import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { components } from 'react-select';
import cx from 'classnames';
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import { useLoadingContext } from '../../contexts/LoadingContext';
import { useThemeContext } from '../../contexts/ThemeContext';

import Checkbox from '../Common/Checkbox';
import Dropdown, { menuIsOpenStyle } from '../Common/ReactSelectDropdown';

const Settings = () => {
    const { selectedCatalogs, setSelectedCatalogs } = usePageContext();
    const { getLabel } = usePageContextLabels();
    const { setIsLoading } = useLoadingContext();
    const { isDarkMode, hasDarkModePreference, setHasDarkModePreference } = useThemeContext()

    useEffect(() => {
        setIsLoading(false);
      
    }, []);//eslint-disable-line

    //This is messing stuff up! As we are removing UK ... this should have come from sites/config at least!
    //Need to make this a loop at some point when we have the next few member firms!
    const availableCatalogs = [
        //{ key: "mf_AccountingCatalogglobal", label: getLabel("mf_accountingCatalog_global", "International Accounting", false) },
        { key: "mf_AuditingCatalogglobal", label: getLabel("mf_auditingCatalog", "International Audit", false) },
        { key: "mf_AssuranceCatalogglobal", label: getLabel("mf_assuranceCatalog", "International Assurance and Other Services", false) },

        //{ key: "mf_AccountingCatalogus", label: getLabel("mf_accountingCatalog_us", "US Accounting", false) },
        { key: "mf_AuditingCatalogus", label: getLabel("mf_auditingCatalog_us", "US Auditing Catalog", false) },
        { key: "mf_AssuranceCatalogus", label: getLabel("mf_assuranceCatalog_us", "US Attestation and Other Services", false) },

        //{ key: "mf_AccountingCataloguk", label: getLabel("mf_accountingCatalog_uk", "UK Accounting", false) },
        //{ key: "mf_AuditingCataloguk", label: getLabel("mf_auditingCatalog_uk", "UK Auditing", false) },
        //{ key: "mf_AssuranceCataloguk", label: getLabel("mf_assuranceCatalog_uk", "UK Assurance and Other Services", false) },
    ];

    const initCatalogs = availableCatalogs.map(catalog => {
        const updatedCatalog = { ...catalog };
        updatedCatalog.isChecked = !!selectedCatalogs[catalog.key]
        return updatedCatalog
    })

    const [catalogs, setCatalogs] = useState(initCatalogs);
    const [selectedLanguage, setSelectedLanguage] = useState();
    const [tempDarkModePreference, setTempDarkModePrefrerence] = useState(hasDarkModePreference)
    const [initialValuesGlobal, setInitialValuesGlobal] = useState({
        catalogs,
        selectedLanguage,
    });

    const [initialValuesPage, setInitialValuesPage] = useState({
        tempDarkModePreference,
    });

    const hasChangeBeenMadeGlobal = () => {
        const { catalogs: initCatalogs } = initialValuesGlobal;
        if (initialValuesGlobal?.selectedLanguage !== selectedLanguage) return true;

        for (let i = 0; i < initCatalogs.length; i++) {
            if (!!initCatalogs[i]?.isChecked !== !!catalogs[i]?.isChecked) return true;
        }

        return false;
    };

    const renderCatalogCheckboxes = (catalogs) => {
        return catalogs.map(catalog => {
            const { key } = catalog;
            return (
                <Checkbox
                    key={key}
                    classNameCheckmark="checkmark-settings-page"
                    label={getLabel(catalog.key, catalog.label)}
                    isChecked={catalogs.find(_catalog => _catalog.key === key)?.isChecked}
                    onChange={() => setCatalogs(prevCatalogs => {
                        const newCatalogs = cloneDeep(prevCatalogs);
                        const index = prevCatalogs.findIndex(_catalog => _catalog.key === key)
                        newCatalogs[index].isChecked = !newCatalogs[index].isChecked
                        return newCatalogs
                    })}
                />
            )
        })
    }

    const handleSaveChangesGlobal = () => {
        const selectedCatalogsObj = catalogs.reduce((accumlator, catalog) => {
            if (!catalog.isChecked) return { ...accumlator };
            return { ...accumlator, [catalog.key]: true }
        }, {});
        setSelectedCatalogs(selectedCatalogsObj);
        setInitialValuesGlobal({ catalogs, selectedLanguage });
    };

    const handleSaveChangesPage = () => {
        setHasDarkModePreference(tempDarkModePreference);
        setInitialValuesPage({ tempDarkModePreference });
    };

    const createDropdownStyles = (provided, state) => {
        const stylesObj = {
            control: {
                borderColor: state.selectProps.menuIsOpen && !isDarkMode ? '#00b500 !important' : isDarkMode ? '#444444' : '#ccc',
            },
            placeholder: {
                color: menuIsOpenStyle(state, isDarkMode ? '#e8e8e8' : '#ffffff', isDarkMode ? '#e8e8e8' : '#333333'),
            },
            singleValue: {
                color: state.selectProps.menuIsOpen && !isDarkMode ? '#ffffff !important' : isDarkMode ? '#e8e8e8' : '#333333',
            },
        }
        return stylesObj
    };

    return (
        <div className="settings-page-outer-container w-100">
            <div className="settings-page-container d-flex w-100">
                <div className="settings-col-container">
                    <h1>{getLabel("up_GlobalSettings", "Global Settings")}</h1>
                    <Dropdown
                        classNameContainer="settings-page-dropdown"
                        placeholder={getLabel("g_PreferredLanguage", "Preferred Language")}
                        customComponents={{ Control: ControlForPreventingTransitionOnMenuOpenChange }}
                        // add defaultValue once we actually implement allowing a user to save a preferrence
                        // defaultValue={selectedLanguage}
                        getIsOptionSelected={(option) => option.value === selectedLanguage}
                        onAfterChange={(option) => setSelectedLanguage(option?.value)}
                        selectedDisplayValue={(option) => option.label}
                        options={[
                            { label: 'English (US)', value: 'en-US' },
                            //{ label: 'English (UK)', value: 'en-UK' },
                            { label: 'English (Global)', value: 'en-US' },
                        ]}
                        styles={createDropdownStyles}
                    />

                    <div className="global-settings-divider" />
                    <h2 className="preferred-catalogs-heading">{getLabel("up_PreferredCatalogs", "Preferred Catalogs")}</h2>
                    <div className="d-flex w-100 flex-wrap">
                        {renderCatalogCheckboxes(catalogs)}
                    </div>
                    <div>
                        <button
                            disabled={!hasChangeBeenMadeGlobal()}
                            onClick={handleSaveChangesGlobal}
                            className="btn-settings-page-save-changes"
                        >
                            {getLabel("up_SaveChanges", "Save Changes")}
                        </button>
                    </div>
                </div>
                <div className="settings-col-container">
                    <div className="flex-grow">
                        <h1>{getLabel("up_GlobalSettings", "Global Settings")}Page Settings</h1>
                        <div>
                            <button 
                                className="toggle-container-dark-mode-settings-page"
                                onClick={() => setTempDarkModePrefrerence(prevVal => !prevVal)}
                            >
                                <div className="font-bold text-toggle-dark-mode-settings-page">
                                    {getLabel("ps_ReadingMode", "Dark Mode")}
                                </div>
                                <div className={tempDarkModePreference ? "toggle-dark-mode-page-settings-on" : "toggle-dark-mode-page-settings-off"} />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button
                            disabled={tempDarkModePreference === initialValuesPage?.tempDarkModePreference}
                            onClick={handleSaveChangesPage}
                            className="btn-settings-page-save-changes"
                        >
                            {getLabel("up_SaveChanges", "Save Changes")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ControlForPreventingTransitionOnMenuOpenChange = ({ children, ...props }) => {
    const { menuIsOpen } = props;
    const [shouldPreventTransition, setShouldPreventTransition] = useState(false)

    // The following two useEffects prevent a border-color transition flicker when the dropdown closes
    useEffect(() => {
        setShouldPreventTransition(!menuIsOpen)
    }, [menuIsOpen])

    useEffect(() => {
        if (shouldPreventTransition) {
            setTimeout(setShouldPreventTransition, 30, false)
        }
    }, [shouldPreventTransition])

    return (
        <components.Control {...props} className={cx({ 'prevent-transitions': shouldPreventTransition })}>
            {children}
        </components.Control>
    )
};

export default Settings
