
import React, { createContext, useContext } from 'react'
import { useState } from 'react';

export const EmbeddedModeContext = createContext()
export const useEmbeddedModeContext = () => useContext(EmbeddedModeContext)

const EmbeddedModeContextProvider = ({ children }) => {
    const [isEmbeddedMode, setIsEmbeddedMode] = useState(false);

    const store = {
        isEmbeddedMode,
        setIsEmbeddedMode,
    }

    return (
        <EmbeddedModeContext.Provider value={store}>
            {children}
        </EmbeddedModeContext.Provider>
    )
}

export default EmbeddedModeContextProvider;
