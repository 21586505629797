import React from 'react';

function List({ children }) {

    return (
        <div className="mfdd__list">
           { children } 
        </div>
    );
}

export default List;