
import React, { useCallback, useEffect, useState } from 'react';
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import RETRIEVALSERVICES from '../../services/rp-service';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import TierHelpers from '../../helpers/TierHelpers';
import Dropdown from '../Common/Dropdown';
import TriggerValueResearchAssistant from './TriggerValueResearchAssistant';
import { useResearchAssistantContext, ACTIONS } from '../../contexts/ResearchAssistantContext';
import cx from "classnames";
import useDCSService from '../../services/useDCSService';
import { MemberFirmID } from '../Constants/Constants';

const sortDisplayOrderAsc = ((a, b) => a.id - b.id);

const MenuListItem = ({ children, setIsShown }) => {
    return (
        <button key={UTILITIESHELPER.generateUUID()}
            onMouseEnter={() => onMouseHover(setIsShown)}
            className={`uk-selector__option dropdown-btn`}
            type="button"
        >
            {children}
        </button>
    )
}

function onMouseHover(setIsShown) {
    setIsShown(true);
}

const SubMenu = ({ className, title, options, selected, onChange }) => {
    return (
        <div className={`submenu-item submenu-col ${className}`}>
            <h4>{title}</h4>

            <ul>
                {options.map((option) => {
                    const isChecked = selected?.filter(x => x.id === option.id)?.length > 0;
                    return (
                        <SubMenuItem
                            key={UTILITIESHELPER.generateUUID()}
                            option={option}
                            onChange={onChange}
                            checked={isChecked}
                        />
                    )
                })}
            </ul>
        </div>
    )
}

const SubMenuItem = ({ option, checked, onChange }) => {
    const id = `check_${option.id}`;
    function onClick(e) {
        e.preventDefault();
        onChange(option);
    }
    return (
        <li className="menu-item">
            <label className="container" htmlFor={id} onClick={onClick} > {option.label}
                <input type="checkbox" id={id} name={option.name} value={option.value} checked={checked} onChange={() => onChange(option)} onKeyPress={(e) => handleCheckboxClick(e, option, onChange)}></input>
                <span className="checkmark-header"></span>
            </label>
        </li>
    )
}

const handleCheckboxClick = (event, option, onChange) => {
    if (event.key === "Enter") {
        onChange(option)
    }
};

const DropdownPreferencesRA = () => {
    const { dispatch, state: raState } = useResearchAssistantContext();
    const { getLabel } = usePageContextLabels();
    const { taxonomyMemberFirmContext, setTaxonomyMemberFirmContext, countryKDSettings, isCountryKDSettingsLoaded } = usePageContext()
    const [data, setData] = useState(null);
    const [isShown, setIsShown] = useState(false);
    const [value, setValue] = useState();
    const { retrieveFrameworkList } = useDCSService()

    const parseResults = useCallback((frameworks) => {
        const narrowScope = ({ id, name, value, label, display_order }) => ({ id, name, value, label, display_order })
        if (!frameworks) {
            return null;
        }

        return {
            frameworks: {
                accounting: raState?.knowledgeDomain === "accounting" ? frameworks?.gaapItems?.sort(sortDisplayOrderAsc).map(narrowScope) : [],
                auditing: raState?.knowledgeDomain === "auditing" ? frameworks?.gaasItems?.sort(sortDisplayOrderAsc).map(narrowScope) : [],
                assurance: raState?.knowledgeDomain === "assurance" ? frameworks?.assuranceItems?.sort(sortDisplayOrderAsc).map(narrowScope) : []
            },

        }
    }, [raState?.knowledgeDomain])

    useEffect(() => {
        async function fetchData() {
            const context = {
                memberFirm: raState.memberFirm,
                language: raState.language,
                knowledgeDomain: raState.knowledgeDomain,

            }
            const TaxonomyMemberfirmValue= countryKDSettings
                        ?.filter(x => x.country === context.memberFirm)[0]?.knowledgeDomain?.filter(x => x.name === (context.knowledgeDomain ? context.knowledgeDomain : 'auditing'))[0]?.taxonomyMemberFirm ;
            const taxonomyArray = (UTILITIESHELPER.isStringNullorEmpty(TaxonomyMemberfirmValue)? MemberFirmID.dtt :TaxonomyMemberfirmValue)
            if(JSON.stringify(taxonomyArray) !== JSON.stringify(taxonomyMemberFirmContext)) {
                setTaxonomyMemberFirmContext(taxonomyArray)
            }
            const resFramework = await retrieveFrameworkList(taxonomyArray);
            const parsed = parseResults(resFramework);
            if (parsed) {
                setData(parsed);
            }
        }
        fetchData();
    }, [raState?.memberFirm, raState?.knowledgeDomain]);



    const MenuList = ({ children }) => {
        const onTierChange = (option) => {
            const mfTier = getMfPrefs(raState.tier, raState.memberFirm);
            const find = mfTier?.find(x => x.id === option.id);
            let tiers = [];
            if (find) {
                tiers = mfTier.filter(x => x.id !== find.id);
            } else {
                tiers = [...mfTier, option].sort(sortDisplayOrderAsc);
            }
            const tierObject = { ...raState.tier, [raState.memberFirm]: { ...raState.tier[raState.memberFirm], [raState.knowledgeDomain]: tiers } };
            TierHelpers.setLocalStorageResearchAssistant(tierObject);
            // let memberfirm= raState.memberFirm ; 
            // let knowledgedomain= raState.knowledgeDomain;
            let TierArray = [];

            for (let key in tierObject) {
                if (tierObject.hasOwnProperty(key)) {
                    let subObject = tierObject[key];
                    for (let subKey in subObject) {
                        if (subObject.hasOwnProperty(subKey)) {
                            let subArray = subObject[subKey];
                            if (Array.isArray(subArray)) {
                                TierArray = [...TierArray, ...subArray.map(item => item.name)];
                            }
                        }
                    }
                }
            }
            
            const payload = {
                memberFirm: raState.memberFirm,
                language: 'en-us',
                memberFirmId:raState.memberFirmId,
                knowledgeDomain: raState.knowledgeDomain,
                tier: tierObject,
                tierSelected: TierArray
            }
            dispatch({
                type: ACTIONS.RA_LOAD,
                payload: payload,
            })
        }



        function getMfPrefs(prefs, mf) {
            return prefs.hasOwnProperty(mf) ? prefs[mf][raState.knowledgeDomain] : [];
        }

        return (
            <section className="dropdown-wrapper" onMouseLeave={() => { setIsShown(false) }}>
                <div className={cx("menu-list uk-selector__menu-list", {
                    "showed": isShown
                })}>

                    <MenuListItem
                        key={UTILITIESHELPER.generateUUID()}
                        setIsShown={setIsShown}
                        value={value}
                        setValue={setValue}>
                        {children}
                    </MenuListItem>
                </div>
                <div className="framework-lists">
                    {isShown && data && (
                        <div className={` frameworks-panel dropdown__submenu ${((data.frameworks.auditing.length > 0) || (data.frameworks.accounting.length > 0) || (data.frameworks.assurance.length > 0)) ? 'show' : 'hide'}`} >
                            {data.frameworks.auditing.length > 0 && <SubMenu className="col-left" title={getLabel("dd_FrameworkAuditing", "Auditing Framework")} options={data.frameworks.auditing} selected={getMfPrefs(raState?.tier, raState.memberFirm)} onChange={onTierChange} />}
                            {data.frameworks.accounting.length > 0 && <SubMenu className="col-right" title={getLabel("dd_FrameworkAccounting", "Accounting Framework")} options={data.frameworks.accounting} selected={getMfPrefs(raState?.tier, raState.memberFirm)} onChange={onTierChange} />}
                            {data.frameworks.assurance.length > 0 && <SubMenu className="col-right" title={getLabel("dd_FrameworkAssurance", "Assurance Framework")} options={data.frameworks.assurance} selected={getMfPrefs(raState?.tier, raState.memberFirm)} onChange={onTierChange} />}
                        </div>
                    )}
                </div>

            </section>
        )
    };

    return (
        <Dropdown
            triggerEl={<TriggerValueResearchAssistant />}
            triggerText={getLabel("c_Filters", "Filters")}
            containerClassNames="uk-selector__menus small-dropdown"
        >

            <Dropdown.Panel  >
                <MenuList>
                    {getLabel("dd_Frameworks", "Frameworks")}
                </MenuList>
            </Dropdown.Panel>
        </Dropdown>
    )
};

export default DropdownPreferencesRA;