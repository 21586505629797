import React from "react";

const PreviewWarning = () => {
  if (window.DART.ENV.RP_REACT_APP_PREVIEW === "true") {
    return (
      <div className="preview-warning" role="complementary" id="non-production-message">
        <p>You are accessing a non-production system</p>
      </div>
    );
  }
  return null;
};

export default PreviewWarning;
