import React from 'react';
import PropTypes from 'prop-types';
import ShowIf from '../ShowIf';
function Trigger({ value, condition }) {
    return (
        <button type="button" className="dd__trigger-button">
            <ShowIf condition={condition}>
                <svg height="10" width="10">
                    <circle cx="5" cy="5" r="5" fill="red" />
                </svg>       
            </ShowIf>
            <span className="dd__trigger-label">{value}</span>
        </button>
    )
}

Trigger.propTypes = {
    condition:PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node
    ]).isRequired
}


export default Trigger;