import { useQuery } from 'react-query';
import { get } from 'lodash';
import useDCSService from '../services/useDCSService';
import { usePageContext } from '../contexts/PageContext';
import UTILITIESHELPER from '../helpers/UtilitiesHelper';

const useCatalogSectionsQuery = (collection) => {
    const { generateQuerySpec, getQueryKey, getCatalogSections } = useDCSService();
    const { context, getMemberFirmID } = usePageContext();

    let querySpec = collection.publicationType ? generateQuerySpec(collection.publicationType, get(collection, 'resultLimit', 0)) : null;
    let queryKey = querySpec ? getQueryKey(querySpec, 'CatalogSections') : `${context.knowledgeDomain}-${getMemberFirmID()}-${collection.collectionType}`;

    const retrieveLinks = async (collection) => {
        const linksPromise = () => collection.links ? collection.links : [];
        const linksFromService = async () => {
            try {
                const data = await getCatalogSections(querySpec, collection.collectionType);
                const dataFormated = (UTILITIESHELPER.isArrayNullorEmpty(data) || data?.length === 0) ? [] : data;
                //ALREADY LOGGED IN getCatalogSections! : logs.debug(page.DCSService, 'retrieveLinks', "retrieveLinks collectionType, dataFormated", collection.collectionType, dataFormated);
                return dataFormated;
            }
            catch (error) {
                //ALREADY LOGGED IN getCatalogSections! : logs.error(page.DCSService, 'retrieveLinks', ErrorMessages.catalogSections + ' for ' + collection.collectionType, error, { eventId: ErrorCodes.Catalog });
                return [];
            }
        }

        return collection.publicationType ? await linksFromService() : linksPromise();
    }

    return useQuery(queryKey, () => retrieveLinks(collection))
};

export default useCatalogSectionsQuery;
