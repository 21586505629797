
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { useLoadingContext } from '../../contexts/LoadingContext';
import { usePageContextLabels } from '../../contexts/PageContext';
import RETRIEVALSERVICES from '../../services/rp-service';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import ShowIf from '../Common/ShowIf';
import HTMLContent from '../Common/HTMLContent';
import { logs, page } from '../../helpers/log';
import { ErrorCodes,ErrorMessages } from '../Constants/Errors';


const ContentArticle = () => {
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const { setIsLoading } = useLoadingContext();
	const { getLabel } = usePageContextLabels();
    const {language} = useParams();

	function InjectOneTrustCookieServiceIfItDoesntExist() {
		if (document.querySelectorAll(`script[src="${window.CookiesServices.ENV.Cookie_Service_Url}"]`).length > 0) {
			return;
		}
		var src = window.CookiesServices.ENV.Cookie_Service_Url;
		var dataDomainScript = window.CookiesServices.ENV.Cookie_Data_Domain_Script;
		var script = document.createElement("script");
		script.setAttribute("src", src);
		script.setAttribute("data-domain-script", dataDomainScript);
		script.setAttribute("type", "text/javascript");
		script.setAttribute("charset", "UTF-8");
		document.getElementsByTagName("head")[0].appendChild(script);
	}

	useEffect(() => {
		const fetchArticle = async () => {
			try {
				const res = await RETRIEVALSERVICES.retrieveContentArticle();
				logs.debug(page.Article, 'ContentArticle', "ContentArticle:retrieveContentArticle", res);
				const locationUrl = window.location.href;
                const isCookiesPage=locationUrl.split("/");
				if (isCookiesPage[5].includes('content') && isCookiesPage[6].includes('cookies-notice')) {
					InjectOneTrustCookieServiceIfItDoesntExist();
				}
				setData(res);
				setIsLoading(false);
			}
			catch (err) {
				logs.error(page.Article, 'ContentArticle', ErrorMessages.contentArticle, err,{eventId:ErrorCodes.Article})
				navigate("", 500);
			}
		}
		fetchArticle();
	}, []);//eslint-disable-line

	function createMarkup(articleBody) {
		var fragmentsText = "";
		articleBody.Fragments.forEach((fragment) => {
			if (typeof (fragment) === 'string') {
				fragmentsText += fragment
			}
			//else {
			//  //This is where we may address images, if they are resolved.
			//}
		})
		return fragmentsText;
	}

	function renderArticleBody(articleBody) {
		if (typeof (articleBody) === 'string') {
			return articleBody;
		}
		else if (articleBody?.Fragments) {
			return (
				<div><HTMLContent>{createMarkup(articleBody)}</HTMLContent></div>
			)
		}
	}

	function renderArticleDate(articleModel) {
		if (articleModel.Metadata?.standardMeta?.dateCreated) {
			return (
				<em>
					{getLabel("a_ReleaseDate", "Date released")}: {UTILITIESHELPER.formatDate(articleModel.Metadata?.standardMeta?.dateCreated, language)}
				</em>
			)
		}
	}

	if (!data) {
		return (
			<div className="article-container cf-right shimmerArticleCard shimmerArticleWrapper">
				<h2 className="article-title shimmerArticleCardBG shimmerArticleCardTitleLine"><span className="ph"></span></h2>
				<div className="article-content-container">
					<div className="article">
						<div className="body">
							<div className="shimmerArticleCardBG shimmerArticleCardIntroLine"></div>
							<div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
							<div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
							<div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
							<div className="shimmerArticleCardBG shimmerArticleCardContentLine shimmerArticleCardContentLineEnd"></div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="settings-page-outer-container w-100">
			<div className="content-article-container">
				<div className="content-article-container-inner">
					<h1>{data.articleModel.Content.headline}</h1>
					{renderArticleDate(data.articleModel)}
					{data.articleModel.Content.body.map((articleBody, abi) => {
						return (
							<div key={abi} >
								<div className="topic-title"><strong>{articleBody.intro}</strong></div>
								<div className="articlecontent-container">
									{renderArticleBody(articleBody.body)}
									<ShowIf condition={articleBody.image?.BinaryContent?.Url}>
										<img src={articleBody.image?.BinaryContent?.Url} alt={articleBody.image?.Metadata?.altText || "Content Article Image"} />
									</ShowIf>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default ContentArticle
