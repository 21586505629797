import React from 'react';
import PropTypes from 'prop-types';

export const RelatedLinks = (props) => {
    if (props.item.content) {
        var rellinks = props.item.content.related_links;
        if (rellinks && rellinks.length > 0) {
            return (
                <div className="related-links">
                    <span className="related-links-icon" title="Related Materials">&nbsp;</span>
                    <ul>
                        {rellinks.map((value, index) => (
                            <li key={value.uri}><a href="/#" onClick={(e) => e.preventDefault()}>RelatedLinks :{value.text}</a></li>
                        ))}
                    </ul>
                </div>
            );
        }
        else
            return null;
    }
    else
        return null;
}

RelatedLinks.propsTypes = {
	title: PropTypes.string,
    typeName: "RelatedLinks"
}

export default RelatedLinks;

