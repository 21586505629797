import React, { useState, useRef, useEffect } from "react";
import {
    useUserPreferencesContext,
    ACTIONS,
} from "../../contexts/AnnotationsContext";
import Dropdown from '../Common/Dropdown';
import ColorPicker from "./ColorPicker";
import AnnotationDelete from "./AnnotationDelete";
import { usePageContextLabels,usePageContext } from "../../contexts/PageContext";
import ANNSERVICES from "../../helpers/AnnotationServices";
import ARTICLEHELPERS from "../../helpers/ArticleHelpers";
import { useLocation } from "react-router-dom";
import UTILITIESHELPER from "../../helpers/UtilitiesHelper";
import ShowIf from "../Common/ShowIf";
import { useTocContext } from "../../contexts/TocContext";
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import { useArticleContext } from '../../contexts/ArticleContext';
import CategoryService from "../../helpers/CategoryServices";


const AnnotationSidePanelEdit = (props) => {
    const { categoryselected,comments, selected, id, onChange, date } = props;
    const { language, context, getMemberFirmID } = usePageContext(); //eslint-disable-line
    const { setTocAnnotations, setTocIconBookmark } = useTocContext();
    let btnRef = useRef();
    const MAX_COMMENT_LENGTH = 1000;
    const dateValue = UTILITIESHELPER.formatDate(date, "en-GB");
    const { state: annState, dispatch } = useUserPreferencesContext();
    const [selectedCategory, setSelectedCategory] = useState(selected);
    const [editableCategory, setEditableCategory] = useState({
        category: {},
        isEditable: false,
    });
    const [isPickerShown, setPickerShown] = useState(false);
    const [isDeleteAnnotationShown, setDeleteAnnotationShown] = useState(false);
    const [comment, setComment] = useState(comments ? comments : '');
    const { getLabel } = usePageContextLabels();
    const location = useLocation();
    const [editOpen, setEditOpen] = useState(true);
    const [PageURL] = useState(location.pathname);
    const [shouldShowDarkOverlay, setshouldShowDarkOverlay] = useState(true);
    const { guidebookArticle } = useGuidebookTocContext();
    const { articleVersion } = useArticleContext();
    const [annotationsCount, setAnnotationsCount] = useState();
    const [bookmarksCount, setBookmarksCount] = useState();
    const [annotationCategories, setAnnotationCategories] = useState([])


    useEffect(() => {
        if (editOpen) {
            if (annState.annotations.length > 0) {
                const annotationPanelNodeList = document.querySelector(".annotation-panel-body")
                const annotationSidePanelEdit = annotationPanelNodeList.querySelector('.box-container');
                if (annotationSidePanelEdit) {
                    annotationSidePanelEdit.setAttribute('tabindex', '-1')
                    annotationSidePanelEdit.focus()
                    annotationSidePanelEdit.removeAttribute('tabindex')
                }
            }
        }
    }, []);//eslint-disable-line

    useEffect(() => {
        let categories = annState.categories.filter(item => !item.is_specific_to_bookmark);
        setAnnotationCategories(categories);
    }, [annState.categories]);

    function handleCategoryAddClick() {
        setPickerShown(true);
        setEditableCategory({ category: {}, isEditable: false });
    }

    function handleCategoryEditClick(category) {
        setPickerShown(true);
        setEditableCategory({ category: category, isEditable: true });
    }

    function handleCategorySelect(category) {
        setSelectedCategory(category);
        document.dispatchEvent(new CustomEvent("dd:requestClose"));
    }

    function handlePickerClose() {
        setPickerShown(false);
    }

    const handlePickerUpdate = async (category) => {
        category.category_guid = await CategoryService.updateCategory(location.pathname, category, category.category_guid, getMemberFirmID());
        dispatch({
            type: ACTIONS.CATEGORY_UPDATE,
            payload: category,
        });
        setPickerShown(false);
        setSelectedCategory(category);
    };


    const handlePickerSave = async (category) => {
        category.category_guid = await CategoryService.createCategory(location.pathname, category, getMemberFirmID());
        if (category.category_guid) {
            dispatch({
                type: ACTIONS.CATEGORY_CREATE,
                payload: category,
            });
        }

        setPickerShown(false);
        setSelectedCategory(category);
    };

    const handleDeleteSide = async () => {
        const deleteCategory = await CategoryService.deleteCategory(location.pathname, editableCategory.category.category_guid, getMemberFirmID(), null, true);
        if (deleteCategory) {
            dispatch({
                type: ACTIONS.CATEGORY_REMOVE,
                payload: { category_guid: editableCategory.category.category_guid },
            });
            dispatch({
                type: ACTIONS.ANNOTATIONS_REMOVE_MULTIPLE,
                payload: annState.annotations.filter(i => i.category_guid === editableCategory.category.category_guid).map(item => item.annotation_guid),
            });
            setTocIconBookmark(prev => {
                return prev.filter(i => i.category_guid !== editableCategory.category.category_guid);
            });

            dispatch({
                type: ACTIONS.BOOKMARK_BULK_REMOVE,
                payload: annState.bookmarks.filter(i => i.category_guid === editableCategory.category.category_guid).map(item => item.bookmark_guid),
            });
            setTocAnnotations(prev => {
                return prev.filter(i => i.category_guid !== editableCategory.category.category_guid);
            });
        }
        setEditOpen(false);
        onChange();
    };

    const handleCategoryDeleteClick = async (category) => {
        setEditableCategory({ category: category, isEditable: true });
        const categoryDelete = await CategoryService.deleteCategory(location.pathname, category.category_guid, getMemberFirmID(), null, false);
        setAnnotationsCount(categoryDelete.number_annotation_linked);
        setBookmarksCount(categoryDelete.number_Bookmark_linked);
        setDeleteAnnotationShown(true);
    }

    function handleAnnotationDeleteClose() {
        setDeleteAnnotationShown(false)
    }

    function handleCommentChange(e) {
        const value = e.target.value;
        setComment(value.slice(0, MAX_COMMENT_LENGTH));
    }

    const saveAnnotation = async () => {
        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }
        if (annState.annotations.length > 0) {
            let annotationUpdate = annState.annotations.find(item => item.annotation_guid === id);
            annotationUpdate.comment =  !UTILITIESHELPER.isStringNullorEmpty(comment) ? comment.trim():"";
            annotationUpdate.category_guid = selectedCategory.category_guid;
            annotationUpdate.version = guidebookArticle?.version ?? articleVersion;
            annotationUpdate.page_url = annotationUpdate.page_url === undefined ? PageURL : annotationUpdate.page_url;
            annotationUpdate.page_bread_crumb = annotationUpdate.page_bread_crumb === undefined ? ARTICLEHELPERS.getBreadcrumbData() : annotationUpdate.page_bread_crumb
            annotationUpdate.range_position_start = annotationUpdate.range_position.start;
            annotationUpdate.range_position_end = annotationUpdate.range_position.end;
            const annotation = await ANNSERVICES.updateAnnotation(location.pathname, annotationUpdate, getMemberFirmID());
            if (annotation) {
                dispatch({
                    type: ACTIONS.ANNOTATIONS_UPDATE,
                    payload: annotation,
                });
            }
            setTocAnnotations(prev => {
                return prev.map(item => { return item.annotation_guid === annotation.annotation_guid ? annotation : item });
            });
            setEditOpen(false);
            onChange();
        }
    };

    function getTriggerElClose() {
        document.dispatchEvent(new CustomEvent('dd:requestClose'));
    }

    function handleCloseClick() {
        setEditOpen(false);
        onChange();
        setshouldShowDarkOverlay(false);
    }

    function CategoryTrigger({ category }) {

        //styling for background category color
        const backgroundColorStyle = {
            backgroundColor: category.color
        }

        return (
            <div className='an-dd-category-trigger-sidepanel-edit'>
                <div className="an-dd-category-chip" style={backgroundColorStyle}></div>
                <div className="an-dd-category-label-sidepanel-edit">{category.label}</div>
            </div>
        )
    }

    function CategoryItem({ category, selected, onClick, onEditClick, onDeleteClick, className }) {
        function handleClick() {
            onClick(category);
        }

        function handleEditClick(event) {
            onEditClick(category)
            event.stopPropagation();
        }

        function handleDeleteClick(event) {
            onDeleteClick(category)
            event.stopPropagation();
        }
        let classNames = '';
        if (className) {
            classNames = 'an-dd-category-item-sidepanel-edit' + className;
        }
        else {
            classNames = 'an-dd-category-item-sidepanel-edit';
        }
        if (selected.category_guid === category.category_guid) classNames += ' ad-dd-category-item--selected';

        //styling for background category color
        const backgroundColorStyle = {
            backgroundColor: category.color
        }

        return (
            <div className={classNames} onClick={handleClick}>
                <div className="an-dd-category-chip" style={backgroundColorStyle}></div>
                <div className="an-dd-category-label-sidepanel-edit">  {category?.label?.length > 20 ? (category?.label === category?.label?.toUpperCase() ? category?.label?.substring(0, 17).concat('...') : category?.label?.substring(0, 20).concat('...')) : category?.label ? category.label : ""}</div>

                <button type="button" className="an-dd-category-edit-sidepanel-edit" onClick={(e) => handleEditClick(e)}></button>
                <button type="button" className={(selected.category_guid === category.category_guid) ? 'hide' : 'an-dd-category-delete-sidepanel-edit'} onClick={(e) => handleDeleteClick(e)}></button>
                <button type="button" className={(selected.category_guid !== category.category_guid) ? 'hide' : 'category-dropdown-sidepanel-edit-close'} onClick={getTriggerElClose} />
            </div>
        )
    }

    function CategoryDropdown({ categories, selected, onSelect, onEditClick, onAddClick, onDeleteClick }) {
        const { getLabel } = usePageContextLabels();
        const selectedCategory = categories.filter(category => category.category_guid === selected.category_guid);
        const sortedCategories = categories.filter(category => category.category_guid !== selected.category_guid);

        function handleSelect(category) {
            onSelect(category);
        }

        function handleAddClick(e) {
            e.stopPropagation();
            onAddClick();
        }

        function handleEditClick(category, index) {
            onEditClick(category, index);
        }

        function handleDeleteClick(category, index) {
            onDeleteClick(category, index);
        }

        const listItems = sortedCategories.map((c, index) => (
            <CategoryItem
                key={`${c.label}-${c.color}`}
                category={c}
                onClick={handleSelect}
                selected={selected}
                onEditClick={(e) => handleEditClick(e, index)}
                onDeleteClick={(e) => handleDeleteClick(e, index)}

            />
        ))

        const selectedItem = selectedCategory.map((c, index) => (
            <CategoryItem
                key={`${c.label}-${c.color}`}
                category={c}
                onClick={handleSelect}
                selected={selected}
                onEditClick={(e) => handleEditClick(e, index)}
                onDeleteClick={(e) => handleDeleteClick(e, index)}
                className={"-highlighted"}
            />
        ))
        function getTriggerEl() {
            return <CategoryTrigger category={selected} />;
        }

        return (
            <Dropdown
                triggerEl={getTriggerEl()}
                ddClassNames="an-dd"
                containerClassNames="an-dd-container-sidepanel-edit"
            >
                <Dropdown.Panel additionalClassNames="an-dd-panel-sidepanel-edit">
                    {selectedItem}
                    <Dropdown.ScrollArea
                        maxHeight="55px"
                        additionalClassNames="an-dd-scroll-area-sidepanel-edit"
                    >
                        {listItems}
                    </Dropdown.ScrollArea>
                    <button className="an-dd-category-add-sidepanel-edit" onClick={handleAddClick}>
                        <div className="an-dd-category-add-image-sidepanel-edit"></div>
                        <div className="an-dd-category-add-label-sidepanel-edit">
                            {getLabel("hh_CategoryCreate", "CREATE NEW CATEGORY")}
                        </div>
                    </button>
                </Dropdown.Panel>
            </Dropdown>
        );
    }

    return (
        <ShowIf condition={editOpen}>
            <div >
                {shouldShowDarkOverlay &&
                    <div className='full-screen-overlay-sidepanel' onClick={handleCloseClick} />
                }
                <div className="box-container">
                    <div className="an-modal-content-sidepanel-edit">
                        <CategoryDropdown
                            className={"edit-sidepanel"}
                            categories={annotationCategories}
                            selected={selectedCategory}
                            categoryName={categoryselected}
                            onSelect={handleCategorySelect}
                            onAddClick={handleCategoryAddClick}
                            onEditClick={handleCategoryEditClick}
                            onDeleteClick={handleCategoryDeleteClick}
                        />
                        <div className="an-modal-comment-sidepanel-edit">
                            <textarea
                                className="an-modal-comment-input-sidepanel-edit"
                                value={comment}
                                onChange={handleCommentChange}
                                placeholder={getLabel(
                                    "hh_OptionalTypeCommentHere",
                                    "(Optional) Type comment here..."
                                )}
                            ></textarea>
                        </div>
                        <div className="an-modal-comment-count-sidepanel-edit">
                            {comment.length}/{MAX_COMMENT_LENGTH}
                        </div>
                        <div className="an-modal-date-count-sidepanel-edit">
                            {dateValue}
                        </div>
                        <div className="an-modal-footer-sidepanel-edit">
                            <button className="an-modal-cancel-sidepanel-edit" onClick={(e) => onChange()}>
                                {getLabel("hh_AnnotationsCancel", "Cancel")}
                            </button>
                            <button className="an-modal-save-sidepanel-edit" onClick={saveAnnotation} onChange={onChange} ref={btnRef} >
                                {getLabel("hh_CategorySave", "SAVE")}
                            </button>
                        </div>
                        {isPickerShown && (
                            <ColorPicker
                                onUpdate={handlePickerUpdate}
                                classNames={"side-panel-edit"}
                                onSave={handlePickerSave}
                                onClose={handlePickerClose}
                                colour={editableCategory.category.color ?? "#FFFF00"}
                                labelTxt={editableCategory.category.label ?? ""}
                                category_guid={editableCategory.category.category_guid ?? ""}
                                isUpdate={editableCategory.isEditable}
                                isSidePanel={true}
                            />
                        )}
                        {isDeleteAnnotationShown && (
                            <AnnotationDelete
                                onDelete={handleDeleteSide}
                                onClose={handleAnnotationDeleteClose}
                                categories={annotationCategories}
                                selected={selectedCategory}
                                selectedCategoryid={editableCategory.category.category_guid}
                                sidePanelClose={onChange}
                                isSidePanelOpen={true}
                                annotationCount={annotationsCount}
                                bookmarkCount={bookmarksCount}

                            />
                        )}
                    </div>
                </div>
            </div>
        </ShowIf>
    );
}

export default AnnotationSidePanelEdit;