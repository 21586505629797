// src/screens/HomeScreen.js
import React, { useEffect, useState } from 'react';
import { Link,  useNavigate, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { logs, page } from "../../helpers/log";

import RETRIEVALSERVICES from '../../services/rp-service';
import LINKHELPERS from '../../helpers/LinkHelpers';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';

import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';

import HomeLayout from './HomeLayout';
import MemberFirmSelector from './MemberFirmSelector';
import ProfileHelpers from '../../helpers/ProfileHelpers';
import RedirectModal from '../Article/RedirectModal';
import { ErrorCodes, ErrorMessages } from '../Constants/Errors';


const HomeScreen = () => {
	const navigate = useNavigate();
	const location = useLocation();

	//When we have components on the page that change contents, and have listeners inside the contents they change...
	//	we cannot "return" nothing as it kills the child objects that are listening, and trying to replace their contents
	//	this causes the problem for the unmounted child components that are trying to replace themselves.
	//	SO - we cannot return "loading" when child components are also listening to changes!
	const { context, memberFirms, sitesLabelsLoaded } = usePageContext();
	const { getLabel, getMFLabel } = usePageContextLabels();
	const [data, setData] = useState();

	const [shouldShowOverlayRedirect, setShouldShowOverlayRedirect] = useState(false);
	const [isRedirectModalVisible, setIsRedirectModalVisible] = useState(false);
	const [redirectURL, setRedirectURL] = useState('');
	const [messageRedirectTL, setMessageRedirectTL] = useState('');
	
	useEffect(() => {
		const fetchLanding = async () => {
			try {
				const res = await RETRIEVALSERVICES.retrieveLanding(context);
				setData(res);

				logs.trackPageView({
					name: 'Home Screen',
					properties: {
						memberFirm: context.memberFirm,
						serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
						level: UTILITIESHELPER.getSessionStorage('Level')
					}
				});
			}
			catch (err) {
				logs.error(page.PageContext,'HomeScreen', ErrorMessages.homeScreen,err,{eventId:ErrorCodes.PageContext});
				navigate("", 500);
			}
		}

		//BDP: This stops us from getting the trigger error in the memberfirm drop down (as the labels are not ready yet!)
		if (sitesLabelsLoaded) {
			//Clearing the object makes the page "refresh" completely (flash) we dont want that.
			//if (data) {
			//	setData(null);
			//}
			fetchLanding();
		}

	}, [context.memberFirm, context.language, sitesLabelsLoaded]);//eslint-disable-line

	if (sitesLabelsLoaded && data) {
		var catalogSelectorContent = get(data, "catalogSelector.Content", null);

		function handleMemberFirmChange(targetMemberFirm) {
			ProfileHelpers.setLastMemberFirm(targetMemberFirm);
			navigate(CONTEXTHELPERS.resolveMemberFirmPath(location.pathname, targetMemberFirm, "", context));
		}

		function handleKnowledgeDomainClick(e) {
			if (e.currentTarget.nodeName === "A") {
				const href = e.currentTarget.getAttribute('href');
				if (href.indexOf("http://") > -1 || href.indexOf("https://") > -1) {
					e.preventDefault();
					setMessageRedirectTL(getLabel("l_LinkModalViewLinkDART", "The Accounting and Reporting Catalog currently resides in the Deloitte Accounting Research Tool. Would you like to continue there?"))
					setRedirectURL("http://" + href.split("://")[1]);
					setShouldShowOverlayRedirect(true);
					setIsRedirectModalVisible(true);
				}
			}
		}

		

		return (
			<HomeLayout>
				<img className="dashboard-background" src={data.backgroundImageUrl} alt="Background Alt text" />
				<section className="dashboard">
					<div className="dashboard-content">
						<h1><img src="/images/deloitte-dark.svg" alt="Omnia Research Portal" /></h1>
						<h2>{getLabel("g_ResearchPortalSiteHeader", "RP")}</h2>
						<div className="home-catalog-selector">
							<MemberFirmSelector 
								memberFirms={memberFirms} 
								onChange={handleMemberFirmChange} dropdownScrollAreaMaxHeight={"175px"}
							></MemberFirmSelector>
						</div>
						<div className="dashboard-buttons">
							<p className="dashboard-intro">{getMFLabel(catalogSelectorContent.title.Content.intro, "catalogSelectorContent.title.Content.intro")}</p>
							<ul className="topic-buttons">
								{catalogSelectorContent.knowledgeDomains.map((knowledgeDomain, idx) => {
									const linkTitle = getMFLabel(knowledgeDomain.Content.linkTitle, "knowledgeDomain.Content.linkTitle");

									return (
										<li key={`li_${idx}_${knowledgeDomain.Content.knowledgeDomain.Id}`}>
											<Link key={`link_${idx}_${knowledgeDomain.Content.knowledgeDomain.Id}`}
												to={`${LINKHELPERS.sanitizeUrl(LINKHELPERS.resolveLandingPageLink(context, knowledgeDomain.Content.linkTarget))}`}
												title={linkTitle}
												onClick={handleKnowledgeDomainClick}											>
												<span>
													<img src={knowledgeDomain.Content.icon.LinkUrl} alt={knowledgeDomain.Content.icon.Metadata?.altText || " "} />
												</span>
												<span className="topic-title">{linkTitle}</span>
											</Link>
										</li>
									)
								})}
							</ul>
						</div>
					</div>
				</section>
				<RedirectModal
					setShouldShowRedirectModal={setIsRedirectModalVisible}
					shouldShowRedirectModal={isRedirectModalVisible}
					redirectURL={redirectURL}
					setShouldShowOverlay={setShouldShowOverlayRedirect}
					shouldShowOverlay={shouldShowOverlayRedirect}
					messageRedirectTL={messageRedirectTL}
				/>
			</HomeLayout>
		)
	}

	return null;
};

export default HomeScreen;
