
import React, { useCallback} from 'react';
import { Link } from "react-router-dom"
import cx from 'classnames';
import LINKHELPERS from '../../helpers/LinkHelpers';
import SidebarIconNextToTitle from "./SidebarIconNextToTitle"
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import ShowIf from '../Common/ShowIf';
import { useFolioPickerContext } from '../../contexts/FolioPickerContext';
import FolioPickerDropdown from '../Folio/FolioPickerDropdown';
import { PAGE_CONTROLLER_TYPE,FOLIO_SUBJECT_ACCOUNT, FOLIO_SUBJECT_NON_ACCOUNT } from "../Constants/Constants";
import { useLocation } from 'react-router-dom'

const SidebarTocTitle = ({
    navTitle,
    pubLandingUrl,
    shouldBeFixed,
    isFixed,
    publicationType,

}) => {
    const {setSearchResults} = useSearchResultContext();
    const onClick = useCallback(() => { setSearchResults([])}, []);
    const {state:taxonomyListItems}=useFolioPickerContext();
    const getFolioId =(pubLandingUrl)=> {
        const regex= /(\d+)-(\d+)/ ;
        const match = pubLandingUrl?.match(regex);
        let value= "";
        if(match) {
          value = match[2];
          return value;
        }
        return value;   
    }

    const folioObject = {
        id: getFolioId(pubLandingUrl),
        label: navTitle
    }
    const location = useLocation()
    const queryParams = new URLSearchParams(location?.search);
    const isSearchPage = !!queryParams?.get('searchPhrase');
    const renderFolioPickerDropdown = (publicationType === PAGE_CONTROLLER_TYPE.folio && !isSearchPage);
    let folioPickerLists=[];
    let folioPickerListsID='';
    function isAccountORNonAccount (pubLandingUrl)  {
            let taxonomyLists=[];
        switch (true) {
            case pubLandingUrl.includes(FOLIO_SUBJECT_NON_ACCOUNT.auditingNonAccountFolios):
                 taxonomyLists= taxonomyListItems.folioLists.filter(item =>item.id == FOLIO_SUBJECT_NON_ACCOUNT.auditingNonAccountFolios)[0];
                break;
            case pubLandingUrl.includes(FOLIO_SUBJECT_ACCOUNT.auditingAccountingFolios):
                taxonomyLists= taxonomyListItems.folioLists.filter(item =>item.id == FOLIO_SUBJECT_ACCOUNT.auditingAccountingFolios)[0];
                break;
            case pubLandingUrl.includes(FOLIO_SUBJECT_NON_ACCOUNT.assuranceFolios):
                taxonomyLists= taxonomyListItems.folioLists.filter(item =>item.id == FOLIO_SUBJECT_NON_ACCOUNT.assuranceFolios)[0];
                break;
            case pubLandingUrl.includes(FOLIO_SUBJECT_ACCOUNT.accountingFolios):
                taxonomyLists= taxonomyListItems.folioLists.filter(item =>item.id == FOLIO_SUBJECT_ACCOUNT.accountingFolios)[0];
                break;
        }
        return taxonomyLists          
    }

   
    if (renderFolioPickerDropdown) {
     folioPickerLists = isAccountORNonAccount(pubLandingUrl);
     folioPickerListsID= folioPickerLists?.id;
    }
  
    return (
        <>
        <div
            className={cx(
                "d-flex",
                {'sidebar-toc-title-container-fixed': isFixed && shouldBeFixed},
                {'align': !isFixed && !shouldBeFixed}
            )}
        >
           <SidebarIconNextToTitle />
          
            <ShowIf condition={publicationType !== PAGE_CONTROLLER_TYPE.folio || isSearchPage }   >
            <Link
                to={LINKHELPERS.sanitizeUrl(pubLandingUrl)}
                className={cx("sidebar-toc-title-link", {'sidebar-toc-title-link-fixed': isFixed})}
                onClick={onClick}
            >
                {navTitle}
            </Link>
            </ShowIf>
            </div>
            <ShowIf condition={taxonomyListItems?.folioLists && taxonomyListItems?.folioLists?.length > 0 && renderFolioPickerDropdown} >
                <FolioPickerDropdown folioPickerLists={folioPickerLists} folioPickerId= {folioPickerListsID} selected={folioObject} isFixed ={isFixed}/>
            </ShowIf>

        </>
    )
};

export default SidebarTocTitle;
