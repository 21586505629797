import RETRIEVALSERVICES from "../services/rp-service";
import { logs, page } from "./log";
import CONTEXTHELPERS from "./ContextHelpers";
import { ErrorCodes, ErrorMessages } from "../components/Constants/Errors";

async function fetchAnnotations(url, memberFirmId) {
    try {
        var model = {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
            timezone_offset: CONTEXTHELPERS.getTimezoneOffset(),
        };
        const response = await RETRIEVALSERVICES.retrieveAnnotation(model);
        return response ? response : [];
    } catch (err) {
        logs.error(page.Annotation, 'AnnotationServices', ErrorMessages.annotationFetch, err,{eventId:ErrorCodes.Annotation});
    }
}

async function createAnnotation(url, payload, memberFirmId) {
    try {
        payload.query_spec_base_model = CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId);
        return await RETRIEVALSERVICES.createAnnotation(payload);
    } catch (err) {
        logs.error(page.Annotation, 'AnnotationServices', ErrorMessages.annotationCreate, err,{eventId:ErrorCodes.Annotation});
    }
}

async function updateAnnotation(url, payload, memberFirmId) {
    try {
        payload.query_spec_base_model = CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId);
        return await RETRIEVALSERVICES.updateAnnotation(payload, payload.annotation_guid);
    } catch (err) {
        logs.error(page.Annotation, 'AnnotationServices', ErrorMessages.annnotationUpdate, err,{eventId:ErrorCodes.Annotation});
    }
}

async function deleteAnnotation(url, guidArray, memberFirmId) {
    try {
        const model = {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
            guids: guidArray
        };
        return await RETRIEVALSERVICES.deleteAnnotation(model);
    } catch (err) {
        logs.error(page.Annotation, 'AnnotationServices',ErrorMessages.annotationDelete , err,{eventId:ErrorCodes.Annotation});

    }
}

const ANNSERVICES = {
    createAnnotation,
    fetchAnnotations,
    updateAnnotation,
    deleteAnnotation,
};

export default ANNSERVICES;
