
import React from 'react';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import PDF from './PDF';
import ShowIf from '../Common/ShowIf';
import HTMLContent from '../Common/HTMLContent';
import cx from 'classnames';

//For search
import getTextWithSearchHighlight from '../../helpers/getTextWithSearchHighlight';
import { useArticleContext } from '../../contexts/ArticleContext';
import { usePageContextLabels } from '../../contexts/PageContext';
import RETRIEVALSERVICES from '../../services/rp-service';


export const getIconSrc = (_format) => {
    if (!UTILITIESHELPER.isStringNullorEmpty(_format)) {
        if (_format === ".pdf")
            return '/images/pdf-file.svg';
        else if (_format?.startsWith(".x"))
            return '/images/microsoft-excel.svg';
        else if (_format?.startsWith(".d"))
            return '/images/microsoft-word.svg';
        else if (_format?.startsWith(".p"))
            return '/images/microsoft-powerpoint.svg';
        else
            return '/images/undefined-doc.svg';
    }
    else {
        return '/images/undefined-doc.svg';
    }
}

const File = ({
    binaryItemGUID,
    fileTitle,
    format,
    date,
    displayName,
    description,
    templateNote,
    bytes,
    uri,
    hasSubItemsOrSiblings,
    shouldIndent,
    isDownloadPdfView,
    text,
    displayDownload
}) => {
    if (!UTILITIESHELPER.isStringNullorEmpty(format))
        format = format.toLowerCase();

    const { searchText = "" } = useArticleContext() || {};
    const { getLabel } = usePageContextLabels();
    fileTitle = (fileTitle.includes("/")) ? fileTitle.replaceAll("/", "_") : fileTitle;
    if (format === '.pdf' && displayDownload !== "true") {
        const pdfProps = { fileTitle, format, uri }
        return <div className="binary-block">
            <p className="file-block-description">
                <HTMLContent>{getTextWithSearchHighlight((UTILITIESHELPER.checkStringIsHtml(description)), searchText)}</HTMLContent>
            </p>
            <PDF  {...pdfProps} />
        </div>
    }
    //const token = UTILITIESHELPER.getAuthToken();
    // const downloadLink = UTILITIESHELPER.getBaseDomain() + "/secure/download/" + token + "/" + uri + "/" + UTILITIESHELPER.removeSpecialCharacterFromFileName(fileTitle) + format;
    // XSS Link Sanitization
    // const sanitizedDownloadLink = LinkHelpers.sanitizeUrl(downloadLink);
    const sanitizedDownloadLink = () => {
        fileTitle = UTILITIESHELPER.removeLargeSpacesFromRichText(fileTitle);
        fileTitle = fileTitle.replace(/\u2013|\u2014/g, "-").replace(/[^\x00-\x7F]/g, ""); //handle any non-ascii chars in the filename
        fileTitle = UTILITIESHELPER.removeSpecialCharacterFromFileName(fileTitle);
        fileTitle = UTILITIESHELPER.restrictLengthOfFileName(fileTitle);
        RETRIEVALSERVICES.requestFile(uri, fileTitle + format);
    };

    var filename = fileTitle + format + ` (${UTILITIESHELPER.formatBytes(bytes, 0)})`;
    const dateValue = UTILITIESHELPER.formatDate(date);
    return (
        <div id={binaryItemGUID} className={cx(`jumplink-${binaryItemGUID}`, "file-container", { 'file-container-indent': shouldIndent })}>
            <h3 className="file-title"><HTMLContent>{getTextWithSearchHighlight((UTILITIESHELPER.checkStringIsHtml(displayName)), searchText)}</HTMLContent></h3>

            <ShowIf condition={dateValue !== ""}>
                <em className="file-date-container" >
                    <span><HTMLContent>{getTextWithSearchHighlight(getLabel("b_BinaryCurrentAsOf", "Current as of:"), searchText)}</HTMLContent></span> <span
                        className="file-date-formatted"><HTMLContent>{getTextWithSearchHighlight((UTILITIESHELPER.checkStringIsHtml(dateValue)), searchText)}</HTMLContent></span>
                </em>
            </ShowIf>
            <div className="binary-block">
                <p className="file-block-description"><HTMLContent>{getTextWithSearchHighlight((UTILITIESHELPER.checkStringIsHtml(description === undefined?"":description)), searchText)}</HTMLContent></p>
                <div className="d-flex">
                    <img className="icon-file-component" src={getIconSrc(format)} alt="Binary File" />
                    <div className="my-auto file-container-title-with-extension"><HTMLContent>{getTextWithSearchHighlight((UTILITIESHELPER.checkStringIsHtml(filename)), searchText)}</HTMLContent></div>
                </div>
                <div className="d-flex">
                    {/*eslint-disable-next-line*/}
                    <a className="m-auto normal-link-in-reading-mode" onClick={sanitizedDownloadLink} download={fileTitle + format}>
                        {getLabel("b_BinaryDownload", "Download")}
                    </a>
                </div>
                {templateNote && <p className="file-block-description"><HTMLContent>{getTextWithSearchHighlight((UTILITIESHELPER.checkStringIsHtml(templateNote)), searchText)}</HTMLContent></p>}
            </div>
            <ShowIf condition={text !== "" && text !==undefined}>
                <div>
                    <HTMLContent>{getTextWithSearchHighlight((UTILITIESHELPER.checkStringIsHtml(text)), searchText)}</HTMLContent>
                </div>
            </ShowIf>
        </div>
    )
};

export default File;