import React from 'react'; // , useRef
import { get } from 'lodash';
import File from './File';
import ShowIf from '../Common/ShowIf';
import Loading from '../Loading/Loading';
import ContentItem from './ContentItem';
import Copyright from './Copyright';
import StructuredArticle from './StructuredArticle';
import { isFile, isSection, isStructuredArticle } from '../../helpers/getComponentInfo';
import { getIsArticlePdf } from '../Publication/Article';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';

export const RenderFormsAndTemplates = ({ pubData, excludeBinaries, isScrollDisabled = false }) => {
    const content = get(pubData, 'content', []);
    const isSingleTopic = content.length && content.length < 2 ? true : false;

    if (!pubData?.content) {
        return (
            <Loading />
        )
    }

    const getComponentArray = () => {
        let componentArray = [];
        content.forEach((processedItem) => {
            processedItem.containsBinary = !UTILITIESHELPER.isStringNullorEmpty(processedItem.content.binary_length);
            processedItem.content.uri = processedItem.content.fileGuid;
            if (isFile(processedItem.content_type, processedItem.containsBinary) && !isSection(processedItem.content_type)) {
                if (isSingleTopic === true && processedItem.containsBinary === true) {
                    if (isStructuredArticle(processedItem.content_type)) {
                        componentArray.push(<StructuredArticle
                            key={processedItem.id}
                            item={processedItem}
                            isEmbedded={false} />)
                    }
                    else {
                        componentArray.push(
                            <File
                                binaryItemGUID={processedItem.id}
                                fileTitle={processedItem.content.display_name || processedItem.content.title}
                                key={processedItem.id}
                                format={processedItem.content.format}
                                date={processedItem.content.current_date}
                                displayName={processedItem.content.display_name}
                                description={processedItem.content.display_name_desc}
                                templateNote={processedItem.content.template_note}
                                bytes={processedItem.content.binary_length}
                                uri={processedItem.content.uri}
                                shouldIndent={false}
                                text={processedItem.content.text}
                                displayDownload={processedItem.content.display_download}
                            />
                        )
                    }
                }
            }
            else if (isStructuredArticle(processedItem.content_type)) {
                componentArray.push(<StructuredArticle
                    key={processedItem.id}
                    item={processedItem}
                    isEmbedded={false}
                />)
            }
            else {
                componentArray.push(<ContentItem key={processedItem.id} subItem={processedItem} />)
            }
        });

        return componentArray;
    }

    return (
        <>
            {getComponentArray()}
            <ShowIf condition={!getIsArticlePdf(pubData)}>
                <Copyright text={pubData.context?.pub_notices_text} />
            </ShowIf>
        </>
    )
};

RenderFormsAndTemplates.propsTypes = {
    typeName: "RenderFormAndTemplates"
}

export default RenderFormsAndTemplates;
