import React, { useRef, useState,useContext } from 'react';
import cx from 'classnames';
import { useThemeContext } from '../../../contexts/ThemeContext';
import { usePageContext, usePageContextLabels } from '../../../contexts/PageContext';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { Link } from 'react-router-dom';
import ShowIf from '../ShowIf';
import { usePageContextSettings } from '../../../contexts/PageContext';
import { useMsal } from '@azure/msal-react';
import LogoutContext from '../../../contexts/LogoutContext';

const DropdownPageSettings = () => {
    const userSettingsRef = useRef();
    const { hasDarkModePreference, setHasDarkModePreference} = useThemeContext();
    const [isUserSettingsOpen, setIsUserSettingsOpen] = useState(false);
    const { getLabel } = usePageContextLabels();
    const { memberFirm, language, knowledgeDomain } = usePageContext();
    const { isDashboardEnabled } = usePageContextSettings();
    const { instance } = useMsal();
    const { setLogoutState } = useContext(LogoutContext);

    useOutsideClick(userSettingsRef, () => {
        setIsUserSettingsOpen(false);
    });
	
	const firstName = localStorage.getItem('firstName');
	const lastName = localStorage.getItem('lastName');
	let userProfileInside;
	if(firstName && lastName && firstName.length > 0 && lastName.length > 0){	
		userProfileInside = firstName.charAt(0) + lastName.charAt(0);
	} else {
		userProfileInside = <img className="default-profile" src="/images/signin-placeholder.svg" alt="Default User" />;
	}
	
    function onClickProfile(){
        document.dispatchEvent(new CustomEvent('dd:requestClose'));
        setIsUserSettingsOpen(prevVal => !prevVal)
    }

    const handleKeyPress = (event) => {
        document.dispatchEvent(new CustomEvent('dd:requestClose'));
        if(event.key === 'Enter'){
            setIsUserSettingsOpen(prevVal => !prevVal)
        }
    }

    const logout = () => {
        var currentAccount = instance.getActiveAccount();
        setLogoutState(currentAccount.idTokenClaims.login_hint);
        localStorage.setItem('logout-event', 'started');
        localStorage.removeItem('logout-event');
        sessionStorage.setItem('timeoutvalue', '');
        instance.logoutRedirect({ logoutHint: currentAccount.idTokenClaims.login_hint });
    }   

    return (
        
        <div className="btn-profile-outer">
            <div
                ref={userSettingsRef}
                className={cx("btn-profile", { 'open': isUserSettingsOpen })}
                onClick={onClickProfile} tabIndex="0" onKeyPress={handleKeyPress}>
				<span className="user-initials">{userProfileInside}</span>
            </div>
            <div
                className={"page-settings " + (isUserSettingsOpen ? "" : 'hide')}
            >
                {/* <div className="d-flex user-settings-link-container">
                    <Link
                        to={`/${params.memberFirm}/${params.language}/settings`}
                        className="user-settings-link d-flex"
                    >
                        {getLabel("ps_User Settings", "User Settings")}
                    </Link>
                </div> */}
                <strong className="page-settings-text">{getLabel("ps_PageSettings", "Page Settings")}</strong>
                <ShowIf condition={isDashboardEnabled()}>
                <div className="dark-mode-row-container">
                    <button
                        className="toggle-container toggle-container-dark-mode d-flex"
                    >
                        <Link
                            to={`/${memberFirm}/${language}/${knowledgeDomain}/dashboard/annotations`}
                            className="dashboard-link text-toggle"
                        >
                            {getLabel("ps_UserDashboard", "User Dashboard")}
                        </Link>
                    </button>
                </div>
                
                <hr className='divide-bar'/>
                </ShowIf>
                <div className="dark-mode-row-container">
                    <button 
                        className="toggle-container toggle-container-dark-mode d-flex"
                        onClick={() => setHasDarkModePreference(prevVal => !prevVal)}
                    >
                        <div className="text-toggle text-toggle-dark-mode">
                            {getLabel("ps_ReadingMode", "Dark Mode")}
                        </div>
                        <div className={cx("toggle-dark-mode-dropdown", {"toggle-dark-mode-dropdown-off": !hasDarkModePreference})}/>
                    </button>
                </div>

                <hr className='divide-bar'/>
                <div className="dark-mode-row-container">
                    <button 
                        className="toggle-container toggle-container-dark-mode d-flex"
                        onClick={() => logout()}
                    >
                        {getLabel("ps_Logout", "Logout")}
                        
                    </button>
                </div>
            </div>
        </div>

    )
}

DropdownPageSettings.defaultProps = {
    isUserSettingsOpen: false,
    setIsUserSettingsOpen: () => {},
};

export default DropdownPageSettings;
