import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import IconX from '../Common/IconX';
import ShowIf from '../Common/ShowIf';
import HTMLContent from '../Common/HTMLContent';
import ResolveSources from "./ResolveSources";
import { RESEARCH_ASSISTANT_SOURCE_TYPES } from "../Constants/Constants";
import CONTEXTHELPERS from "../../helpers/ContextHelpers";
 
const FootnotePopup = ({ documentSource, currentIndex, isChat, researchAssistantResponse }) => {
    const [footnotePopupCoords, setFootnotePopupCoords] = useState({ x: 0, y: 0 });
    const [footnoteVerticalPosition, setFootnoteVerticalPosition] = useState('below');
    const isFootnotePositionedBelow = footnoteVerticalPosition === 'below';
    const [selectedFootnotePopup, setSelectedFootnotePopup] = useState()
    const location = useLocation();
 
    const memberFirm = CONTEXTHELPERS.getMemberFirmWithTaxonomyId(researchAssistantResponse?.inputFields?.userLibrariesSelected?.[0]);
    const memberFirmId =  researchAssistantResponse?.inputFields?.userLibrariesSelected?.[0];
    const knowledgeDomain = CONTEXTHELPERS.getknowledgeDomain(researchAssistantResponse?.inputFields?.userFilterSelected?.[1]?.filterID?.[0]);
    const knowledgeDomainId =  researchAssistantResponse?.inputFields?.userFilterSelected?.[1]?.filterID?.[0];
    const language = CONTEXTHELPERS.getLanguageByMemberFirm(memberFirm);
   
    const currentFootnote = (documentSource || []).find(footnote => footnote.key.toString() === selectedFootnotePopup);
    const modalDimensions = { height: 200, width: 425 };
    const offsetY = 15;
 
    const setPopupPosition = (_footnoteNum, el) => {
        if (!el) return;
        const popupBounds = el.getBoundingClientRect();
        const additionalPaddingBottom = 20;
        const amountPastViewportY = popupBounds.bottom + modalDimensions.height + offsetY + additionalPaddingBottom - window.innerHeight;
        const amountPastViewportXLeft = popupBounds.left - (isChat ? (window.innerWidth * 10/100) : 120) - modalDimensions.width / 2 - 30;
        const amountPastViewportXRight = popupBounds.right + modalDimensions.width / 2 + 30 - window.innerWidth;
        const offsetXLeft = amountPastViewportXLeft < 0 ? amountPastViewportXLeft : 0;
        const offsetXRight = amountPastViewportXRight > 0 ? amountPastViewportXRight : 0;
        const articleContainer = document.querySelector(`#research-assistant-box-${currentIndex}`);
        const bounds = articleContainer.getBoundingClientRect();
        const x = ((popupBounds.left + popupBounds.right) / 2) - bounds.left - offsetXLeft - offsetXRight;
        const y = ((popupBounds.top + popupBounds.bottom) / 2) - bounds.top;
        setFootnoteVerticalPosition(amountPastViewportY > 0 ? 'above' : 'below');
        setFootnotePopupCoords({ x, y });
    };
 
    useEffect(() => {
        const handleDocumentClick = (ev) => {
            if (!ev.target.classList.contains('footnote-link')) return;
            const footnoteNum = ev.target.dataset.footnoteNum;
            setSelectedFootnotePopup(footnoteNum);
            ev.preventDefault();
            setPopupPosition(footnoteNum, ev.target);
        }
        const myElem = document.getElementById(`research-assistant-box-${currentIndex}`)
        if (myElem) {
            myElem.addEventListener('click', handleDocumentClick)
        }
        return () => {
            if (myElem) {
                myElem.removeEventListener('click', handleDocumentClick);
            }
        }
    }, []); //eslint-disable-line
 
    useEffect(() => {
        const handleDocumentResize = () => {
            if (!selectedFootnotePopup) return;
            setPopupPosition(selectedFootnotePopup);
        }
        window.addEventListener('resize', handleDocumentResize);
        return () => {
            window.removeEventListener('resize', handleDocumentResize);
        }
    }, [selectedFootnotePopup])//eslint-disable-line
 
    useEffect(() => {
        setSelectedFootnotePopup();
        return () => setSelectedFootnotePopup()
    }, [location?.pathname]);//eslint-disable-line
 
    return (
<ShowIf condition={selectedFootnotePopup}>
<div
                className="d-flex"
                id="modal-footnote-popup"
                style={{
                    transform: `translate(-50%, ${isFootnotePositionedBelow ? '0' : '-100%'}) `,
                    left: footnotePopupCoords.x,
                    top: footnotePopupCoords.y + (isFootnotePositionedBelow ? offsetY : -1 * offsetY),
                    width: modalDimensions.width,
                    maxHeight: modalDimensions.height,
                }}
>
<div className="footnote-popup-text-container">
<span className="footnote-popup-inner-html">
                        {currentFootnote &&
<ResolveSources
                                sourceType={RESEARCH_ASSISTANT_SOURCE_TYPES.DOCUMENT}
                                uniqueIdentifier={currentFootnote.citation}
                                title={currentFootnote.title}
                                type={currentFootnote.type}
                                footnote={currentFootnote.footnote}
                                isPopupFootnote={true}
                                memberFirm={memberFirm}
                                language={language}
                                knowledgeDomain={knowledgeDomain}
                                memberFirmId={memberFirmId}
                                knowledgeDomainId={knowledgeDomainId}
                            />
                        }
</span>
</div>
<IconX
                    className="icon-x-footnote-popup"
                    onClick={() => setSelectedFootnotePopup(false)}
                />
</div>
</ShowIf>
    )
};
 
export default FootnotePopup;
