import React from 'react';
import Dropdown from '../Common/Dropdown';
import cx from 'classnames'
import { usePageContextLabels } from '../../contexts/PageContext';
import ShowIf from '../Common/ShowIf';

function CategoryTrigger({ selectedCategories, dropDownCategories, isAllCategorySelected, customClass }) {
    const { getLabel } = usePageContextLabels();
    const getCategoriesLabel = () => {
        let selectedLabels = [];
        dropDownCategories.forEach(cat => {
            if (selectedCategories.includes(cat.category_guid)) {
                selectedLabels.push(cat.label);
            }
        })
        return selectedLabels.join(', ');
    }

    return (
        <div className={cx("an-dd-category-trigger-dropdown an-dd-category-trigger-dropdown-cat", { "an-dd-dashboard-filter-active": selectedCategories?.length > 0, "an-dd-dashboard-filter-active-all-cat": isAllCategorySelected }, customClass)}>
            {!selectedCategories.length ? <strong>{getLabel("hh_Category", "Category")}</strong> :
                isAllCategorySelected ? <strong>{getLabel("hh_AllCategories", "All Categories")}
                </strong> : dropDownCategories.map((ele) => {
                    return <>
                        {ele && selectedCategories.length === 1 && selectedCategories.includes(ele.category_guid) ?
                            <div key={`${ele.category_guid}`} style={{ width: "100%" }}>
                                <div className="an-dd-dashboard-category-label-dropdown">
                                    <strong className='an-dd-cat-single-selection'>
                                        {
                                            ele?.label
                                        }
                                    </strong>
                                </div>
                            </div> : null
                        }

                    </>
                })}
            {selectedCategories?.length > 1 && !isAllCategorySelected ?
                <strong className='an-dd-cat-single-selection an-dd-cat-selection' >
                    <div className='div-wrap-ellipsis'>
                        {getCategoriesLabel()}
                    </div>
                </strong> : null
            }
        </div>
    )
}

function CategoryItem({ category, onClick, isChecked, classNameCheckmark, onChange }) {
    function handleClick() {
        onClick(category);
    }

    let classNames = 'an-dd-category-item-dropdown';

    const styledCheckbox = { visibility: isChecked ? 'visible' : 'hidden' }

    const categoryBackgroundColor = { backgroundColor: category.color }

    return (
        <div className={classNames} onClick={handleClick}>
            <div className={cx("styled-checkbox", { 'checked': isChecked })}>
                <div style={styledCheckbox} className={cx(classNameCheckmark)}>
                    <div className="tick-mark"></div>
                </div>
            </div>
            <input type="checkbox" className="hidden-checkbox" checked={isChecked} onChange={onChange} tabIndex={1} />
            <ShowIf condition={category.color !== ""}>
                <div className="an-dd-category-chip-dropdown" style={categoryBackgroundColor}></div>
            </ShowIf>
            <div className={cx("an-dd-label-dropdown", { 'all': category.color === "" })}>
                {category?.label?.length > 20 ? (category?.label === category?.label?.toUpperCase() ? category?.label?.substring(0, 17).concat('...') : category?.label?.substring(0, 20).concat('...')) : category?.label ? category.label : "" }
            </div>
        </div>
    )
}


function CategoryDropdown({ categories, selectedCategories, onSelect, isAllCategorySelected, bookmarks, customClass }) {
    function handleSelect(category) {
        onSelect(category);
    }

    function isCheckedValue(c) {
        if (c.category_guid === 'cat0') {
            return isAllCategorySelected;
        }
        if (c.category_guid && selectedCategories.length) {
            return selectedCategories.includes(c.category_guid)
        }
    }

    const allCategoryModel = { category_guid: 'cat0', label: 'All Categories', color: '', last_referenced_date: '', IsSpecificToBookmark: false };
    const dropDownCategories = [allCategoryModel, ...categories];
    const listItems = dropDownCategories.map((c) => (
        <CategoryItem
            key={`${c.category_guid}-${c.label}-${c.color}`}
            category={c}
            onClick={handleSelect}
            onChange={handleSelect}
            isChecked={isCheckedValue(c)}
            classNameCheckmark="checkmark-dropdown-panel"

        />
    ))

    function getTriggerEl() {
        return <CategoryTrigger selectedCategories={selectedCategories} dropDownCategories={dropDownCategories} isAllCategorySelected={isAllCategorySelected} customClass={customClass} />;
    }

    // function getTriggerElClose() {
    //     document.dispatchEvent(new CustomEvent('dd:requestClose'));
    // }

    return (
        <Dropdown
            triggerEl={getTriggerEl()}
            ddClassNames={(selectedCategories.length > 0 && isAllCategorySelected !== true) ? "an-dd-cat-dropdown an-dd-cat-dropdown-select-fix" : "an-dd-cat-dropdown"}
            containerClassNames="an-dd-container-dropdown an-dd-container-cat-dropdown"
        >
            <Dropdown.Panel additionalClassNames="an-dd-panel-dropdown">
                <Dropdown.ScrollArea maxHeight="153px" additionalClassNames="an-dd-scroll-area-dropdown">
                    {listItems}
                </Dropdown.ScrollArea>
            </Dropdown.Panel>
        </Dropdown>
    );
}

export default CategoryDropdown;
