import React from 'react';
import PropTypes from 'prop-types';

function Panel({ children, additionalClassNames }) {
    return (
        <div className={"dd__panel " + additionalClassNames}>
            {children}
        </div>
    )
}

Panel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    additionalClassNames: PropTypes.string
}

Panel.defaultProps = {
    additionalClassNames: ''
}

export default Panel;