import React, { useState, useEffect, useRef } from "react";
import ShowIf from "../Common/ShowIf";
import { usePageContextLabels } from "../../contexts/PageContext";
import useOutsideClick from '../../hooks/useOutsideClick';

const FollowUpQuestions = ({ isResearchAssistantLoading, researchAssistantResponse, handleFollowUpClick }) => {
    const [isOpen, setisOpen] = useState(false)
    const { getLabel } = usePageContextLabels();
    const ref = useRef();

    useEffect(() => {
        setisOpen(false);
    }, [isResearchAssistantLoading])

    // close on outside click
    useOutsideClick(ref, handleOutsideClick);

    const followUpClick = event => {
        handleFollowUpClick(event.target.innerText);
        setisOpen(false);
    };

    function handleOutsideClick() {
        setisOpen(false);
    }

    return (
        <div className={`followup-questions-container ${isOpen ? 'show-followup-questions' : ''}`} ref={ref}>
            <ShowIf condition={!isResearchAssistantLoading && researchAssistantResponse !== null && researchAssistantResponse !== undefined}>
                {isOpen &&
                    <><div className='followup-questions-close' onClick={() => setisOpen(!isOpen)}>×</div>
                        <div className="research-assistant-suggestion-container">
                            {researchAssistantResponse?.suggestedQuestions?.map((item, index) => (
                                <div className="research-assistant-suggestions" key={`citation-${index}`} >
                                    <label data-testid="followup-suggestions-item" className="research-assistant-suggestions-item" onClick={followUpClick}>{item}</label>
                                </div>
                            ))}
                        </div></>}
                <div className='followup-questions-title' data-testid="followup-button-area" onClick={() => setisOpen(!isOpen)}>
                    <img src='/images/blue-right-arrow.svg' alt='right-arrow' className={`right-arrow ${isOpen ? 'hidden-icon' : ''}`} />
                    <img src='/images/blue-down-arrow.svg' alt="down-arrow" className={`down-arrow ${!isOpen ? 'hidden-icon' : ''}`} />
                    <h3>
                        {getLabel('ra_FollowUpQuestionsTitle', 'Suggested Follow-Up Questions')}
                    </h3>
                </div>
            </ShowIf>
        </div>
    )
}

export default FollowUpQuestions;