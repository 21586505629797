import React, { useRef, useEffect  } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import ARTICLEHELPERS from '../../helpers/ArticleHelpers';
import StructuredPanel from '../Article/StructuredPanel';
import { isFigureOrSidebar, isIndexedComponent } from '../../helpers/getComponentInfo';
import getTextWithSearchHighlight from '../../helpers/getTextWithSearchHighlight';
import { useArticleContext } from '../../contexts/ArticleContext';
import HTMLContent from '../Common/HTMLContent';
import { usePageContext } from '../../contexts/PageContext';
import { PAGE_CONTROLLER_TYPE } from '../Constants/Constants';

function StructuredArticleTitleButton({
	item,
	renderingCount,
	structuredArticleTitle,
	open,
	setOpen,
	pageControllerType,
	searchText
}) {
	const { isJournalPage } = usePageContext();
	const getShouldRenderTitle = () => {
		return ![PAGE_CONTROLLER_TYPE.folio, PAGE_CONTROLLER_TYPE.standard].includes(pageControllerType) && !isJournalPage &&
		!isFigureOrSidebar(item.content_type) &&
		structuredArticleTitle &&
		!isIndexedComponent(item.content_type);
	}

	//In the case of a Question/Example, we will get -1 (and therefore, NOT render a title)
	if (getShouldRenderTitle()) {

		// if(isIndexedComponent(item.content_type)) {
		// 	return <strong id={item.id}><HTMLContent>{getTextWithSearchHighlight(structuredArticleTitle, searchText)}</HTMLContent></strong>
		// }
		return (

			<div className="article-title-button-container">
                <div className={open ? 'expanded-title' : 'collapsed-title'}>
                    <p className={open ? '' : 'closed'} onClick={setOpen}></p>
                    <h2 id={item.id}><HTMLContent>{getTextWithSearchHighlight(structuredArticleTitle, searchText)}</HTMLContent></h2>
                </div>
            </div>
		)
	}

	return null;
}


export const StructuredArticle = (props) => {
	const { searchText='', getIsSectionExpanded, removeExpandedSectionKey, setOpenMultipleExpandedSections } = useArticleContext()||{};
    const uuidRef = useRef(UTILITIESHELPER.generateUUID());
	const uuid = uuidRef.current;
	const isSectionExpanded = typeof(getIsSectionExpanded)==="function"?getIsSectionExpanded(uuid):true;

	useEffect(() => {
		if(typeof(setOpenMultipleExpandedSections)==="function"){
			setOpenMultipleExpandedSections([uuid], true);
		}
		if(typeof(removeExpandedSectionKey)==="function"){
			return () => removeExpandedSectionKey(uuid);
		}
	}, [])//eslint-disable-line

	var structuredArticleTitle = UTILITIESHELPER.getContentItemTitle(props.item.content);

	//Addition check to make the footnotes elements with the same number come up properly with click handling
	if (structuredArticleTitle !== '' && UTILITIESHELPER.IsTextHavingFnTag(props.item.content.rich_text_title)) {
		let htmlOfRTT = ARTICLEHELPERS.getContentHtmlDocument(props.item.content.rich_text_title);
		let fnOfRTT = htmlOfRTT.querySelector("fn .p");
		let idOfRTT = fnOfRTT.id;

		var detailsFromMatchingFN = props.footnoteArray.filter(x => x.idOfRTT === idOfRTT);

		if (detailsFromMatchingFN.length > 0) {
			var fnMarkup = UTILITIESHELPER.createMarkupFootnote(detailsFromMatchingFN[0].number, detailsFromMatchingFN[0].label);

			var el = document.createElement('div');
			el.innerHTML = structuredArticleTitle;

			var fnEl = el.querySelector('sup');
			fnEl.outerHTML = fnMarkup;

			structuredArticleTitle = el.innerHTML;
		}
	}

    const location = useLocation();
    var context = CONTEXTHELPERS.generateContext(location.pathname);
	const titleRendered = !isFigureOrSidebar(props.item.content_type) && structuredArticleTitle
	var jumpLinkClass = 'jumplink-' + props.item.id;

	return (
		<div
			data-expandable-uuid={uuid}
			id={props.item.id}
			className={isSectionExpanded ? 'jumplink-' + jumpLinkClass + ' structured-article open' : 'jumplink-' + jumpLinkClass + ' structured-article'}
		>
			<StructuredArticleTitleButton
				item={props.item}
				renderingCount={props.renderingCount}
				structuredArticleTitle={structuredArticleTitle}
				setOpen={() => {
					if(typeof(setOpenMultipleExpandedSections)==="function"){
						setOpenMultipleExpandedSections([uuid], 'toggle');
					}
				} }
				pageControllerType={context.pageControllerType}
				open={isSectionExpanded}
				searchText={searchText}
			/>
			<StructuredPanel
				item={props.item}
				level={1}
				parentTitle={structuredArticleTitle}
				publication={props.publication}
				isEmbedded={props.isEmbedded}
				titleRendered={titleRendered}
				pageType={context.pageControllerType}
			/>
		</div>
	);
}

StructuredArticle.propsTypes = {
	title: PropTypes.string,
    typeName: "StructuredArticle"
}

export default StructuredArticle;
