// src/components/Loading.js

import React from 'react';
import { component } from './Loading.module.css';
//import { usePageContextLabels } from '../../contexts/PageContext';
import cx from 'classnames';

const Loading = ({ className, style }) => {
	/*const { getLabel } = usePageContextLabels();*/

	return (
		<div className={(cx(component, className))} data-testid="loading" style={{ style }}>
			{/*<p>{getLabel("g_Loading", "Loading...")}</p>*/}
			<svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</div>
	)
}

export default Loading;