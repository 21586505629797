import React from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import { useGuidebookTocContext } from "../../contexts/GuidebookTocContext";
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import { useUserPreferencesContext } from "../../contexts/AnnotationsContext";
import AnnotationsDeletedArticlesModal from "../Annotations/AnnotationsDeletedArticlesModal";
import BookmarkService from "../../helpers/BookmarkServices";

const WormholeNotification = ({style}) => {
    const { guidebookArticle } = useGuidebookTocContext();

	const navigate = useNavigate();
	const location = useLocation();
	const { context, isWormholeActive } = usePageContext();
	const { getLabel } = usePageContextLabels();
    const {  viewedBookmark,setViewedBookmark,state: annState , viewedAnnotation} = useUserPreferencesContext();
	
	//TODO:Mitali: While just the fact that filters are selected does not mean that the article was deleted, we will assume that if the user has filters selected
	//	and we get a wormhole, that the article does not exist "now" becuase of the filter, not because it was deleted
	//	this can always be wrong in all cases, inless when we deturmine wormhole, we knew it did exist before!

	function closeNotification() {
		//WE just navigate to the pageBaseURL from wherever, and we will "exit" out of the wormhole message
		navigate(context.pageBaseURL);
	}
	const deleteBookmark = async (bookmark_guid) => {
		const bookmark = await BookmarkService.deleteBookmarks(location.pathname, [bookmark_guid]);
		if (bookmark) {
			setViewedBookmark(null);
		}
	};

	if (isWormholeActive && viewedBookmark) {
		if (annState?.bookmarks && annState?.bookmarks?.length > 0) {
			const bookmarktoDelete = annState.bookmarks.find(item => item.reference_identifier === viewedBookmark);
			if (bookmarktoDelete) {
				deleteBookmark(bookmarktoDelete.bookmark_guid);
			}
		}
	}

	if (isWormholeActive && viewedAnnotation) {
		return (
			<AnnotationsDeletedArticlesModal
				bookmarkPage={false}
				/>
		)
	}
	if (isWormholeActive) {
		return (
			<div style={style} className={isWormholeActive ? "wormhole-redirect-message" : "wormhole-redirect-message hide"}>
				<span className="warning">&nbsp;</span>
				<p>{guidebookArticle && guidebookArticle.is_filtered ? 
				 getLabel("g_wormholeNotification", "The page you are looking for is no longer available due to your current catalog or filters you have applied.") 
				:getLabel("hh_DeletedArticleMesage", "The exact item you are looking for is no longer available.")}</p>
				<button className="close-warning" onClick={closeNotification}>&nbsp;</button>
			</div>
		);
	}
	else {
		return null;
	}
};

export default WormholeNotification;