import React,{useEffect, useState} from 'react'
import { GotoIcon } from './GotoIcon';
import RaFormsAndTemplatesModal from './RaFormsAndTemplatesModal';

function FormsAndTemplatesSource({...props}) {
  const{ title, format, memberFirm, language, knowledgeDomain, memberFirmId, knowledgeDomainId, contentItemGuid, pubGuid } = props
  const[showModal, setShowModal]=useState(false);

  const openFormsAndTemplatesModal= async ()=>{
      setShowModal(true);
  }

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <div>
      {showModal && (
        <RaFormsAndTemplatesModal
          closeModal={closeModal}
          isScrollDisabled={true}
          memberFirm={memberFirm}
          memberFirmId={memberFirmId}
          language={language}
          knowledgeDomain={knowledgeDomain}
          knowledgeDomainId={knowledgeDomainId}
          contentItemGuid = {contentItemGuid}
          pubGuid={pubGuid}
        />
      )}
      <div className='resolve-form-source'>
        <div
          className='sourcelink'
          onClick={() =>
            openFormsAndTemplatesModal()
          }
        >
          {title}
          {
            <GotoIcon
              className='goto-next-icon'
              handleClick={() => openFormsAndTemplatesModal()}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default FormsAndTemplatesSource
