import React from 'react';
import getIconSrc from '../../helpers/getIconSrc';
import { usePageContext } from '../../contexts/PageContext';

const SidebarIconNextToTitle = () => {
    const { context } = usePageContext();
    return (
        <img
            className="sidebar-toc-icon-next-to-title"
            src={getIconSrc(context.pageType)} alt="Icon"
        />
    )
};

export default SidebarIconNextToTitle;
