import React from 'react'
import ShowIf from '../Common/ShowIf';
import UTILITIESHELPER from "../../helpers/UtilitiesHelper";
import { ErrorCodes,ErrorMessages } from '../Constants/Errors';
import { page,logs } from '../../helpers/log';
import { usePageContextLabels } from '../../contexts/PageContext';
import { RESEARCH_ASSISTANT_ERROR_TYPES } from '../Constants/Constants';

export default function ResearchAssistantErrorMessage(props) {
  const { getLabel } = usePageContextLabels()

  const errorMapping = (errorType) => {
    switch (errorType) {
      case RESEARCH_ASSISTANT_ERROR_TYPES.NO_CONTENT:
        return {
          label: 'err_ContentErrorMessage',
          fallback:
            'Oh no, something has gone wrong! Please try again later. If the problem persists, please contact support. Code – ',
          errorCode: ErrorCodes.Publication,
        }
      default:
        return {
          label: '',
          fallback: '',
          errorCode: '',
        }
    }
  }

  const {label, fallback, errorCode} = errorMapping(props.errorType);
  return (
      <div className={'content-error-message'}>
        <span className='alert'>&nbsp;</span>
        <p>
          {getLabel(
            label,
            fallback
          )}{' '}
          {errorCode}
        </p>
        <button className='close-warning' onClick={props.closeErrorNotification}>
          &nbsp;
        </button>
      </div>
  )
}
