
import React from 'react';
import { useLocation } from "react-router-dom";
import cx from 'classnames';
import ShowIf from '../ShowIf';
import { useReadingModeContext } from '../../../contexts/ReadingModeContext';
import LayoutChildren from './LayoutChildren';
import PageTitle from './PageTitle';
import UTILITIESHELPER from '../../../helpers/UtilitiesHelper';
import Footer from "./Footer";
import {useThemeContext} from "../../../contexts/ThemeContext";
import {usePageContext} from "../../../contexts/PageContext";

const Layout = ({ children, includesTitleRow }) => {
    const { isReadingMode } = useReadingModeContext();
    const location = useLocation();
    const { isCatalogPage } = usePageContext();
    const searchParam = location.search;
    const { isDarkMode } = useThemeContext();

    return (
        <>
            <div style={{ width: isReadingMode ? '100%' : '' }} className={cx("layout-container", { 'layout-container-non-prod-env': window.DART.ENV.RP_REACT_APP_PREVIEW === 'true' })}>
            <main className={cx("all-but-header-container d-flex flex-column", { 'readmode-header-container': searchParam.includes('reading-mode=true') })}>
                    <ShowIf condition={includesTitleRow &&  UTILITIESHELPER.isStringNullorEmpty(searchParam)}>
                        <div className="d-flex title-and-search-container" >
                            <ShowIf condition={true}>
                                <div className="title-and-description-container">
                                    <PageTitle />
                                </div>
                            </ShowIf>
                        </div>
                    </ShowIf>
                    <LayoutChildren>
                        {children}
                    </LayoutChildren>
                </main>
                <Footer className={cx({ "footer-reading-mode": isReadingMode, "footer-catalog": isCatalogPage && !isDarkMode })} />
            </div>
        </>
    )
}

Layout.defaultProps = {
    includesTitleRow: false,
}

export default Layout;
