import React from 'react';
import EmbeddedArticleBlockSubsection from './EmbeddedArticleBlockSubsection';
import HTMLContent from '../Common/HTMLContent';

export const EmbeddedArticleBlockSection = (props) => {
    const isQnA = props.isQnA;
    const isObjective = props.isObjective;
    const subItem = props.subItem;

    return (
        <div className="expandable-article-block-body">
            <span><HTMLContent>{subItem.content.text}</HTMLContent></span>
            {subItem.subItems.map((innerSubItem, i) => {
                return (
                    <EmbeddedArticleBlockSubsection key={innerSubItem.id + i} isQnA={isQnA} isObjective={isObjective} subItem={subItem} innerSubItem={innerSubItem} />
                )
            })}

        </div>
    )


}

export default EmbeddedArticleBlockSection;
