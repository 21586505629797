import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import UTILITIESHELPER from './helpers/UtilitiesHelper'
import Layout from './components/Common/Layout/Layout'
import { logs } from './helpers/log'
import PrivateRoute from './components/Common/PrivateRoute'
import ArticleContextProvider from './contexts/ArticleContext'
import Catalog from './components/Catalog'
import Collection from './components/Catalog/Collection'
import ContentArticle from './components/ContentArticle'
import EmbeddedModeContextProvider from './contexts/EmbeddedModeContext'
import Header from './components/Common/Layout/Header'
import HomeScreen from './components/Home/HomeScreen'
import { LinkHandler } from './components/Common/LinkResolver'
import ResearchDashboard from './components/ResearchDashboard'
import Settings from './components/Settings'
import ThemeContextProvider from './contexts/ThemeContext'
import Unauthorized from './components/Common/Unauthorized'
import Manual from './components/Manual'
import Journal from './components/Journal'
import Guidebook from './components/Guidebook'
import Folio from './components/Folio'
import {
  MEMBERFIRM_CONSTANTS,
  REGEX_PATTERN,
} from './components/Constants/Constants'
import ChatBot from './components/ChatBot/ChatBot'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './config/queryClientConfig'
import ConversationMode from './components/ChatBot/ConversationMode';
import RAContextProvider from './contexts/ResearchAssistantContext'

const AppRoutes = () => {
  const navigate = useNavigate()
  const location = useLocation()
  let query = new URLSearchParams(location.search)
  //const isSearchPage = !!query?.get('searchPhrase');
  let campaign = query.get('campaign')

  if (campaign !== null && campaign !== undefined && campaign !== '') {
    logs.trackEvent({
      name: 'Campaign',
      properties: {
        campaignId: campaign,
        memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
        serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
        level: UTILITIESHELPER.getSessionStorage('Level'),
      },
    })
  }

  useEffect(() => {
    if (location.pathname.includes(`/${MEMBERFIRM_CONSTANTS.global}/`)) {
      navigate(
        encodeURI(
          UTILITIESHELPER.replaceGlobalWithDTTFromPath(location.pathname)
        )
      )
    }
    if (location.pathname.match(REGEX_PATTERN.DoubleForwardSlash)) {
      let pathArray = location.pathname.split('/')
      navigate(
        pathArray
          .filter((e) => !!e)
          .join('/')
          .concat(location.search)
          .concat(location.hash)
      )
    }
  }, [location.pathname]) //eslint-disable-line

  return (
    <ThemeContextProvider>
      <Routes>
        <Route exact path='/unauthorized' element={<Unauthorized />} />
        <Route
          path='/link/:targetIdentifier'
          element={
            <PrivateRoute>
              <LinkHandler />
            </PrivateRoute>
          }
        />
        <Route
          path='/content/:targetIdentifier'
          element={
            <PrivateRoute>
              <LinkHandler />
            </PrivateRoute>
          }
        />
        <Route
          path='/'
          element={
            <PrivateRoute>
              <HomeScreen />
            </PrivateRoute>
          }
        />
        <Route
          path='/:memberFirm'
          element={
            <PrivateRoute>
              <HomeScreen />
            </PrivateRoute>
          }
        />
        <Route
          path='/:memberFirm/:language'
          element={
            <PrivateRoute>
              <HomeScreen />
            </PrivateRoute>
          }
        />
        <Route
          path='/:memberFirm/:language/link/:targetIdentifier'
          element={
            <PrivateRoute>
              <LinkHandler />
            </PrivateRoute>
          }
        />
        <Route
          path='/:memberFirm/:language/settings'
          element={
            <PrivateRoute>
              <Layout>
                <Settings />
              </Layout>
            </PrivateRoute>
          }
        ></Route>

        <Route
          path='/researchassistant'
          element={
            <PrivateRoute>
              <ConversationMode />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/:memberFirm/:language/content/*'
          element={
            <PrivateRoute>
              <Header></Header>
              <Layout>
                <ContentArticle />
              </Layout>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/:memberFirm/:language/:knowledgeDomain/collection/:collectionType'
          element={
            <PrivateRoute>
              <Header></Header>
              <Layout includesTitleRow>
                <Collection />
              </Layout>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/:memberFirm/:language/:knowledgeDomain/'
          element={
            <PrivateRoute>
              <Header></Header>
              <Layout>
                <QueryClientProvider client={queryClient}>
                  <Catalog />
                </QueryClientProvider>
              </Layout>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/:memberFirm/:language/:knowledgeDomain/dashboard/*'
          element={
            <PrivateRoute>
              <Header></Header>
              <Layout>
                <ResearchDashboard />
              </Layout>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/:memberFirm/:language/:knowledgeDomain/chatBot'
          element={
            <PrivateRoute>
              <Header></Header>
              <Layout>
                <ChatBot />
              </Layout>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/:memberFirm/:language/:knowledgeDomain/folio/:guidebookFileName/*'
          element={
            <PrivateRoute>
              <Header></Header>
              <Layout classNameBody='d-flex'>
                <ArticleContextProvider>
                  <EmbeddedModeContextProvider>
                    <Folio />
                  </EmbeddedModeContextProvider>
                </ArticleContextProvider>
              </Layout>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/:memberFirm/:language/:knowledgeDomain/guide/:guidebookFileName/*'
          element={
            <PrivateRoute>
              <Header></Header>
              <Layout classNameBody='d-flex'>
                <ArticleContextProvider>
                  <EmbeddedModeContextProvider>
                    <Guidebook />
                  </EmbeddedModeContextProvider>
                </ArticleContextProvider>
              </Layout>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/:memberFirm/:language/:knowledgeDomain/deloitte-communications/:journalBrand/*'
          element={
            <PrivateRoute>
              <Header></Header>
              <Layout classNameBody='d-flex'>
                <ArticleContextProvider>
                  <EmbeddedModeContextProvider>
                    <Journal />
                  </EmbeddedModeContextProvider>
                </ArticleContextProvider>
              </Layout>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/:memberFirm/:language/:knowledgeDomain/professional-news/:journalBrand/*'
          element={
            <PrivateRoute>
              <Header></Header>
              <Layout classNameBody='d-flex'>
                <ArticleContextProvider>
                  <EmbeddedModeContextProvider>
                    <Journal />
                  </EmbeddedModeContextProvider>
                </ArticleContextProvider>
              </Layout>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path='/:memberFirm/:language/:knowledgeDomain/:pageControllerType/:manualTitle/:manualGUID/*'
          element={
            <PrivateRoute>
              <Header></Header>
              <Layout classNameBody='d-flex'>
                <ArticleContextProvider>
                  <EmbeddedModeContextProvider>
                    <Manual />
                  </EmbeddedModeContextProvider>
                </ArticleContextProvider>
              </Layout>
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </ThemeContextProvider>
  )
}

export default AppRoutes
