import React from "react";
import { usePageContext, usePageContextLabels } from "../../contexts/PageContext";


const ArticleNotification = () => {
    const { context } = usePageContext();
    const { getLabel } = usePageContextLabels();

    let parentGuide = `/${context.memberFirm}/${context.language}/${context.knowledgeDomain}/${context.pageSelectedSection}/${context.pageSelectedPublicationTitle}/${context.pageSelectedPublicationGUID}`;

    return (
        <div className="notification">
            <span className="notification-warning">&nbsp;</span>
            <p className="notification-label">{getLabel("lbl_PubPortion")} <a className="notification-link" href={parentGuide} target="_blank" rel="noopener noreferrer">{decodeURI(context.pageSelectedPublicationTitle)}</a></p>
        </div>
    );
}

export default ArticleNotification;