import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ShowIf from '../Common/ShowIf';
import SidebarTOC from "./SidebarTOC";
import IconWideModeTOC from './WideModeIconTOC';
import { useReadingModeContext } from '../../contexts/ReadingModeContext';
import WormholeNotification from '../Notification/WormholeNotification';
import ContentErrorMessage from '../Common/ContentErrorMessage';
import UTILITIESHELPER from "../../helpers/UtilitiesHelper";
import MessageNotification from '../Notification/MessageNotification';
import ResearchAssistantErrorMessage from '../Common/ResearchAssistantErrorMessage';
import { useResearchAssistantContext, ACTIONS } from '../../contexts/ResearchAssistantContext';

const PublicationLayout = ({
    children,
    isArticlePage,
    shouldBeFixed,
    isWideMode,
    setIsWideMode,
    publicationType,
    navTitle,
    pubLandingUrl,
    tocBody,
    yAxisOfToc,
    tocMaxHeight
}) => {

    const location = useLocation();
    const { isReadingMode } = useReadingModeContext();
    const { dispatch, state: raState } = useResearchAssistantContext();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    useEffect(() => {
        UTILITIESHELPER.setLocalStorage("isContentLoadError", false);
    }, []);

    const closeErrorNotification=()=>{
        let errorPayload = {
            hasError:false
        }

        dispatch({
            type: ACTIONS.RA_HIDE_ERROR,
            payload: errorPayload,
        })
    }

    return (
        <div className={isReadingMode ? "pannels-container-reading-mode" : "pannels-container"}>
            <ShowIf condition={raState.hasError}>
                 <ResearchAssistantErrorMessage errorType={raState.errorType} closeErrorNotification={closeErrorNotification}/>
            </ShowIf>
            <ContentErrorMessage />
            <MessageNotification />
			<WormholeNotification />
            <ShowIf condition={!isReadingMode}>
                <ShowIf condition={isWideMode && !isArticlePage}>
                    <IconWideModeTOC
                        publicationType={publicationType}
                        onClick={() => setIsWideMode(false)}
                    />
                </ShowIf>
            </ShowIf>
            <SidebarTOC
                isHidden={isWideMode || isReadingMode}
                setIsWideMode={setIsWideMode}
                navTitle={navTitle}
                shouldBeFixed={shouldBeFixed}
                pubLandingUrl={pubLandingUrl}
                yAxisOfToc={yAxisOfToc}
                tocMaxHeight={tocMaxHeight}
                publicationType={publicationType}
            >
                {tocBody}
            </SidebarTOC>
            {children}
        </div>
    )
};

export default PublicationLayout;

