
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import RETRIEVALSERVICES from '../../services/rp-service.js';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import LINKHELPERS from '../../helpers/LinkHelpers';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { useLoadingContext } from '../../contexts/LoadingContext';
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import { get,set } from 'lodash';
import { appInsightUIMapping, logs, page } from "../../helpers/log";
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import CollectionItemExpandable from './CollectionItemExpandable';
import CollectionTOC from './CollectionTOC';
import CollectionSearchbar from "./CollectionSearchbar"
import CollectionSearchResultItem from "./CollectionSearchResultItem"
import CollectionSearch from './CollectionSearch'
import ListItem from '../Common/ListItem';
import Loading from '../Loading/Loading';
import MainPannel from '../Common/MainPannel';
import PublicationLayout from './../Publication/PublicationLayout';
import SearchHelpers from '../../helpers/SearchHelpers';
import SidePannel from '../Common/SidePannel';
import WormholeNotification from '../Notification/WormholeNotification';
import MessageNotification from '../Notification/MessageNotification.js';
import { SCS_KEYS, getFallback } from '../Constants/SCSConstants';
import { PAGE_CONTROLLER_TYPE,PUBLICATIONTYPE_IDS,PUBTYPES_CATALOG_PAGE } from '../Constants/Constants.js';
import { ErrorCodes, ErrorMessages,ErrorTypes } from '../Constants/Errors.js';
import useDCSService from '../../services/useDCSService';

const getIsJournalCollection = (_listType) => {
    switch (_listType) {
        case PUBLICATIONTYPE_IDS.deloitteCommunications: //deloitte-communications
        case PUBLICATIONTYPE_IDS.professionalNews: //professional-news
            return true;
        default:
            return false;
    }
};


const Collection = () => {
    const [data, setData] = useState({});
    const [isFixedSearchBar, setIsFixedSearchBar] = useState(false);
    const navigate = useNavigate();
    const { getLabel, getMFLabel } = usePageContextLabels();
    const { isLoading, setIsLoading } = useLoadingContext()
    const { generateQuerySpec, getCatalogSections, getDelCummAndProfNewsCollectionSections } = useDCSService();
    const {
        pageTitle, setPageTitle, setPageDescription,
        isSearchPage, searchPhrase, setSearchPhrase,
        memberFirm, language, knowledgeDomain, pageControllerType, tierVal, industryVal, setNotification, isMemberFirmsLoaded, contextMemberFirm
    } = usePageContext();
    const wildcardsPrefixSearchLimitationNotification = getLabel(SCS_KEYS.wildcardsPrefixSearchLimitation, getFallback(SCS_KEYS.wildcardsPrefixSearchLimitation));

    const collectionItems = get(data, 'CatalogCollection[0].Content.links', []);
    const catalogCollection = get(data, 'CatalogCollection[0]', null);
    const collectionIcon = get(data, 'currentPageContext.pageFileName', null);
    const listType = get(data, 'CatalogCollection[0].Content.context.publicationType.Key', '');
    //StructureStandards = 60194865 <--> StructureManuals = 60194866
    const recentListType = (listType === PUBLICATIONTYPE_IDS.standards) ? PUBLICATIONTYPE_IDS.manual : (listType === PUBLICATIONTYPE_IDS.manual) ? PUBLICATIONTYPE_IDS.standards : (listType === PUBLICATIONTYPE_IDS.deloitteCommunications || listType === PUBLICATIONTYPE_IDS.professionalNews) ? listType : "";
    const isJournalCollection = useMemo(() => getIsJournalCollection(listType), [listType]);
    const sidePanelHeading = isJournalCollection ? getLabel("col_RecentlyPublished", "Recently Published") : getLabel("col_SeeAlso", "Related Collections");
    const recentLinks = listType ? get(data, 'CatalogCollection[0].Content.context.publicationLists["' + recentListType + '"]', []) : [];
    const maxRecentLinksToDisplay = isJournalCollection ? 10 : 5;
    const [showResults, setShowResults] = useState(false);
    const [isWideMode, setIsWideMode] = useState(false)
    const hasAllTocDataLoaded = true;
    const navTitle = getMFLabel(pageTitle, "pageTitle");
    const publicationType = get(catalogCollection, "Content.context.publicationType.Key", "folio");
    const [groupList, setGroupList] = useState([]);
    const accountBalanceOptions = get(catalogCollection, 'Content.taxonomyAccountBalance.items', []);
    const nonAccountBalanceOptions = get(catalogCollection, 'Content.taxonomyAuditWorkflowAreaNonAccount.items', []);
    const assuranceOptions = get(catalogCollection, 'Content.taxonomyAssurance.items', []);

    const {
        searchResults,
        filteredSearchResults,
        setFilteredSearchResults,
        setSearchTimeStamp,
        tocControl,
        setTocControl,
        setCollectionSearchLimit,
        collectionSearchLimit
    } = useSearchResultContext();


    useEffect(() => {
        //var tocArray = getTocArray();
        //let tempTocItem = tocArray;
        //setTocControl([]);
        //setTocControl(tempTocItem.length > 0 ? tempTocItem : []);
        var tocArray = getTocArray();
        setTocControl(tocArray);
        //eslint-disable-next-line
    }, [searchResults, filteredSearchResults]);

    useEffect(() => {
        (async () => {
            if (collectionSearchLimit === 0) {
                var collectionSearchlimitTemp = await SearchHelpers.getCollectionSearchLimitFromAppSettings();
                setCollectionSearchLimit(collectionSearchlimitTemp);
            }
        })();
        window.onscroll = function () {
            const ele = document.getElementById('collection-page-search');
            if (ele) {
                setIsFixedSearchBar(window.pageYOffset > 60);
            }
        };
        //eslint-disable-next-line
    }, [])


    //Load the CatalogCollection
    useEffect(() => {
        async function getCollectionData(collectionType) {
            try {
                let querySpec = generateQuerySpec(null, 0);
                const data = await getCatalogSections(querySpec, collectionType, 'collection');
                const dataFormated = (UTILITIESHELPER.isArrayNullorEmpty(data) || data?.length === 0) ? [] : data;                
                //ALREADY LOGGED IN getCatalogSections! : logs.debug(page.DCSService, 'retrieveLinks', "retrieveLinks collectionType, dataFormated", collection.collectionType, dataFormated);
                return dataFormated;
            }
            catch (error) {
                //ALREADY LOGGED IN getCatalogSections! : logs.error(page.DCSService, 'retrieveLinks', ErrorMessages.catalogSections + ' for ' + collection.collectionType, error, { eventId: ErrorCodes.Catalog });
                return [];
            }
        }

        //For Journal Collection
        async function getJournalCollectionData(collectionType, limit = 0) {
            try {
                let querySpec = generateQuerySpec(null, limit);
                const data = await getDelCummAndProfNewsCollectionSections(querySpec, collectionType, collectionType === "deloitte-communications" ? "deloittecommunicationcollections" : "professionalnewscollections");
                const dataFormated = (UTILITIESHELPER.isArrayNullorEmpty(data) || data?.alllinkItems.length === 0) ? [] : data;               
                //ALREADY LOGGED IN getCatalogSections! : logs.debug(page.DCSService, 'retrieveLinks', "retrieveLinks collectionType, dataFormated", collection.collectionType, dataFormated);
                return dataFormated;
            }
            catch (error) {
                //ALREADY LOGGED IN getCatalogSections! : logs.error(page.DCSService, 'retrieveLinks', ErrorMessages.catalogSections + ' for ' + collection.collectionType, error, { eventId: ErrorCodes.Catalog });
                return [];
            }
        }

        //return formate data for the deloitte-communications|professional-news collection page
        async function FormateDelCummAndProfNewsCollectionData(data) {
            let linkItems = [];
            if (UTILITIESHELPER.isArrayNullorEmpty(data)) {
                return data;
            }
            data.map((item) => {
                const linkTextTitle = item.journal_brand;
                const siteLinkText = linkTextTitle.replace(" ", "");
                const linkObj = {
                    linkInternal: {
                        //LinkUrl: "/" + memberFirm + "/" + language + "/" + knowledgeDomain + "/" + collectionType + "/" + linkTextTitle
                        LinkUrl :  `/${memberFirm}/${language}/${knowledgeDomain}/${pageControllerType}/${linkTextTitle}`                            
                    },
                    linkText: getLabel('ch_JournalBrand' + siteLinkText, linkTextTitle),
                    linkExternal: linkTextTitle,
                    linkAltText: getLabel('ch_JournalBrandByLine' + siteLinkText, '')
                };
                linkItems.push(linkObj);
            });
            return linkItems;
        }

        async function getData() {
            try {
                setIsLoading(true);
                setPageDescription("");
                setPageTitle("");
                setData({});

                const res = await RETRIEVALSERVICES.retrieveCollection();
                logs.debug(page.Collection, 'retrieveCollection', "retrieveCollection:res ", res);
                if (pageControllerType !== PAGE_CONTROLLER_TYPE.folio) {
                    //Check for fall-back, if we have no contnet, we do not continue...
                    //With the collection page, you should NOT actually "Get" here through a navigatable link!                   

                    if (`${PAGE_CONTROLLER_TYPE.deloitteCommunications}|${PAGE_CONTROLLER_TYPE.professionalNews}`.indexOf(pageControllerType) >= 0) {
                        let catalogCollectionLinks = await getJournalCollectionData(pageControllerType, 10);
                        let formatteddata = await FormateDelCummAndProfNewsCollectionData(catalogCollectionLinks.allcollectionsItems);
                        let pubtype = get(res, "CatalogCollection[0].Content.context.publicationType.Key", pageControllerType === PUBLICATIONTYPE_IDS.deloitteCommunications ? PUBLICATIONTYPE_IDS.deloitteCommunications : PUBLICATIONTYPE_IDS.professionalNews);
                        set(res, 'CatalogCollection[0].Content.context.publicationLists["' + pubtype + '"]', catalogCollectionLinks.alllinkItems);
                        set(res, 'CatalogCollection[0].Content.links', formatteddata);
                    }
                    else if((`${PAGE_CONTROLLER_TYPE.manual}|${PAGE_CONTROLLER_TYPE.standards}`.indexOf(pageControllerType) >= 0)) {
                        const [Manuals, Standards] = await Promise.all([
                             await getCollectionData(PAGE_CONTROLLER_TYPE.manual),
                             await getCollectionData(PAGE_CONTROLLER_TYPE.standards),
                        ]);
                        if(pageControllerType === PAGE_CONTROLLER_TYPE.manual){
                            let pubtype = get(res, "CatalogCollection[0].Content.context.publicationType.Key",PUBLICATIONTYPE_IDS.standards);
                            console.log(pubtype);
                            set(res, 'CatalogCollection[0].Content.links', Manuals);
                            // set Recently Viewed to TOP 10 Standards
                            set(res, 'CatalogCollection[0].Content.context.publicationLists["' + PUBLICATIONTYPE_IDS.standards + '"]', Standards);
                        }
                        else if(pageControllerType === PAGE_CONTROLLER_TYPE.standards) {
                            let pubtype = get(res, "CatalogCollection[0].Content.context.publicationType.Key",PUBLICATIONTYPE_IDS.manual);
                            set(res, 'CatalogCollection[0].Content.links', Standards);
                            // set Recently Viewed to TOP 10 Manuals
                            set(res, 'CatalogCollection[0].Content.context.publicationLists["' + PUBLICATIONTYPE_IDS.manual + '"]', Manuals);
                        }
                    }
                    else if ((`${PAGE_CONTROLLER_TYPE.guidance}`.indexOf(pageControllerType) >= 0)) {
                        const [Guidance, Standards] = await Promise.all([
                            await getCollectionData(PAGE_CONTROLLER_TYPE.guidance),
                            await getCollectionData(PAGE_CONTROLLER_TYPE.standards),
                        ]);
                        if (pageControllerType === PAGE_CONTROLLER_TYPE.guidance) {
                            let pubtype = get(res, "CatalogCollection[0].Content.context.publicationType.Key", PUBLICATIONTYPE_IDS.standards);
                            console.log(pubtype);
                            set(res, 'CatalogCollection[0].Content.links', Guidance);
                            // set Recently Viewed to TOP 10 Standards
                            set(res, 'CatalogCollection[0].Content.context.publicationLists["' + PUBLICATIONTYPE_IDS.standards + '"]', Standards);
                        }
                    }      
                    else {
                        let catalogCollectionLinks = await getCollectionData(pageControllerType);
                        set(res, 'CatalogCollection[0].Content.links', catalogCollectionLinks);
                    }
                    let catalogCollectionLinks = get(res, 'CatalogCollection[0].Content.links',[]);
                    
                   //For folio, this will be null! As there are no collection items, therefore no links.
                    if ((!catalogCollectionLinks || catalogCollectionLinks?.length === 0) && !isSearchPage) {
                        navigate(CONTEXTHELPERS.getFallbackUrl(window.location.href));
                    }
                }

                setData(res);
                setPageTitle(get(res, 'CatalogCollection[0].Content.title.Content.title', ''))
                setPageDescription(get(res, 'CatalogCollection[0].Content.title.Content.intro', ''));
                setIsLoading(false);
            }
            catch (err) {
                logs.error(page.Collection, "getCollection", ErrorMessages.collectionData, err,{eventId:ErrorCodes.Collection});
            }
        }

        if(isMemberFirmsLoaded)
            getData();

        return () => {
            setPageTitle('');
            setPageDescription('');
            setData({});
        }
        //eslint-disable-next-line
    }, [tierVal, industryVal, isMemberFirmsLoaded, contextMemberFirm]);

    useEffect(() => {
        //groupList is a object array used to store unique publication id with pub nav title
        //this groupList is used for grouping just to display "View All Result" with pub nav title in the list shown below search bar as a search result
        var newObject = [];
        if (filteredSearchResults && filteredSearchResults.length > 0) {
            filteredSearchResults.forEach((item, index) => {
                if (index === 0) {
                    let obj = { "publicationId": item.document?.publicationId, "publicationNavTitle": item.document?.publicationNavTitle, "result": item }
                    newObject.push(obj);
                }
                else {
                    if (newObject.findIndex(a => a.publicationId === item.document?.publicationId) < 0) {
                        let obj = { "publicationId": item.document?.publicationId, "publicationNavTitle": item.document?.publicationNavTitle, "result": item }
                        newObject.push(obj);
                    }
                }
            });
        }

        setGroupList(newObject);
    }, [filteredSearchResults]);

    function SidePanelComponent() {
        if (!recentLinks?.length) return null;

        return <SidePannel heading={sidePanelHeading}>
            <ul className="list side-pannel-collection-list">
                {recentLinks.slice(0, maxRecentLinksToDisplay).map((item) => {
                    return (
                        <ListItem href={item.url} key={`spl_${item.PublicationGUID}`}>
                            {isJournalCollection && item?.context?.journal_brand ? item.context.journal_brand + ": " + item.context.display_title : item.context.display_title}
                        </ListItem>
                    )
                })}
            </ul>
        </SidePannel>
    }

    const handleSearchTrigger = async (typedSearchPhrase) => {
        if (String(typedSearchPhrase).length > 2) {
            if (SearchHelpers.IsSearchPrefixWithWildcards(searchPhrase)) {
                setNotification({ message: wildcardsPrefixSearchLimitationNotification, type:  ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'Collection' });
                return;
            }
            setNotification({ message: '', type:  ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null });
            setSearchPhrase(typedSearchPhrase);
        }

        setFilteredSearchResults([]); 
        setShowResults(false);
    }

    const handleSearchSubmit = event => {
        event.preventDefault();
        setSearchTimeStamp(Date.now())
        if (searchPhrase.length < 2) {
            alert(getLabel("s_SearchPhraseTooShort", "Phrase too short (for now)"));
            return;
        }
        if (SearchHelpers.IsSearchPrefixWithWildcards(searchPhrase)) {
            setNotification({ message: wildcardsPrefixSearchLimitationNotification, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'Collection' });
            return;
        }
        setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null });

        //The human readable, is only for when we show the user what they searched for?
        //setSearchPhrase(SearchHelpers.toHumanReadable(parsedSearchPhrase));
        //TODO: Need to remove the existing QS from this ?!
        const currentLocation = window.location.pathname;
        navigate(`${currentLocation}?${SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase))}`);
        return;
    };

    const getSubjectTOC = (subject) => {
        var subjectTOC = {
            id: subject.id,
            name: subject.name,
            visible: false,
            filterAppliedStateOn: false,
            resultCount: 0,
            pageControllerType: pageControllerType,
            tocItem: subject,
            searchResult: null,
            href: subject.href
        };
        return subjectTOC
    }
    const getItemTOC = (item, filteredTocResults) => {
        var itemTOC = {
            id: item.linkExternal,
            name: item.linkText,
            visible: filteredTocResults.length > 0 ? true : false,
            filterAppliedStateOn: filteredTocResults.length > 0 ? true : false,
            resultCount: filteredTocResults.length,
            pageControllerType: pageControllerType,
            tocItem: item,
            searchResult: filteredTocResults,
            href: item.linkInternal.LinkUrl
        };
        return itemTOC;
    }
    const getPublicationTOC = (publication, filteredTocResults) => {
        var publicationTOC = {
            id: publication.PublicationGUID,
            name: publication.linkText,
            visible: filteredTocResults.length > 0 ? true : false,
            filterAppliedStateOn: filteredTocResults.length > 0 ? true : false,
            resultCount: filteredTocResults.length,
            pageControllerType: pageControllerType,
            tocItem: publication,
            searchResult: filteredTocResults,
            href: publication?.url
        };
        return publicationTOC;
    }

    function getTocArray() {
        var tocArray = [];

        //For Folio-Subjects, we ONLY want to start presenting Subjects when we have search results...
        if (isSearchPage && searchResults.length > 0) {
            if (pageControllerType === "folio") {
                switch (knowledgeDomain) {
                    case "auditing":
                        if (nonAccountBalanceOptions.length > 0) {
                            nonAccountBalanceOptions.forEach((nonAccountSubject) => {
                                var nonAccountSubjectTOC = getSubjectTOC(nonAccountSubject);
                                tocArray.push(nonAccountSubjectTOC);
                            });
                        }
                        if (accountBalanceOptions.length > 0) {
                            accountBalanceOptions.forEach((accountSubject) => {
                                var accountSubjectTOC = getSubjectTOC(accountSubject);
                                tocArray.push(accountSubjectTOC);
                            });
                        }
                        break;
                    //no break, as we fall through to also add the account balance options for Auditing as well!
                    case "accounting":
                        if (accountBalanceOptions.length > 0) {
                            accountBalanceOptions.forEach((accountSubject) => {
                                var accountSubjectTOC = getSubjectTOC(accountSubject);
                                tocArray.push(accountSubjectTOC);
                            });
                        }
                        break;

                    case "assurance":
                        if (assuranceOptions.length > 0) {
                            assuranceOptions.forEach((assuranceSubject) => {
                                var assuranceSubjectTOC = getSubjectTOC(assuranceSubject);
                                tocArray.push(assuranceSubjectTOC);
                            });
                        }
                        break;
                    default :
                        break;
                }

                const uniqueID = {};

                const tmeplaterepositories = ["68169717","58035381" , "68489711"];
                //eslint-disable-next-line
                tocArray.map((folioSubjectItemTOC) => {
                    //Need to find the current Folio Subject in the search results for it to be presented
                    //For assurance, the result.document subject source field will be the same as auditing publicationAuditWorkflowNonAccount
                    var accountIndex = searchResults.findIndex(searchResult => (searchResult.document?.publicationAuditWorkflowAccount?.includes(folioSubjectItemTOC.id)));
                    var nonAccountIndex = searchResults.findIndex(searchResult => (searchResult.document?.publicationAuditWorkflowNonAccount?.includes(folioSubjectItemTOC.id)));

                    //If we found the subject inside the results... then we present it...
                    if (accountIndex > -1 || nonAccountIndex > -1) {
                        //folioSubjectItemTOC.visible = true; //We ONLY show the Folio Subject if we have search results for them.
                        folioSubjectItemTOC.filterAppliedStateOn = true;
                        var hasFTEOGuide = false;
                        const filteredTocResults = searchResults.filter((result) => {
                            const isTemplateRepository = tmeplaterepositories.includes(result.document?.publicationType);

                            if(isTemplateRepository && !hasFTEOGuide){
                                if((result.document?.auditWorkflowAccount && (result.document.auditWorkflowAccount.length > 0))
                                    || (result.document?.auditWorkflowNonAccount && (result.document.auditWorkflowNonAccount.length > 0))) {
                                    if (result.document.auditWorkflowAccount.includes(folioSubjectItemTOC.id) || result.document.auditWorkflowNonAccount.includes(folioSubjectItemTOC.id)) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                }
                            }

                            else if((result.document?.publicationAuditWorkflowAccount && (result.document.publicationAuditWorkflowAccount.length > 0))
                                || (result.document?.publicationAuditWorkflowNonAccount && (result.document.publicationAuditWorkflowNonAccount.length > 0))) {
                                if (result.document.publicationAuditWorkflowAccount.includes(folioSubjectItemTOC.id) || result.document.publicationAuditWorkflowNonAccount.includes(folioSubjectItemTOC.id)) {
                                    hasFTEOGuide = hasFTEOGuide || result.document?.publicationType === "66029858";
                                    return true;
                                }
                                else {
                                    return false;
                                }
                            }
                            return false;
                        });
                        const filteredTocsearchresults  = hasFTEOGuide ? filteredTocResults?.filter((result) => !tmeplaterepositories.includes(result.document?.publicationType)) : filteredTocResults ;
                        folioSubjectItemTOC.searchResult = filteredTocsearchresults.filter(x=>{
                            if(uniqueID[x.document.topicId+x.document.publicationObjectId]){
                                return false;
                            }
                            uniqueID[x.document.topicId+x.document.publicationObjectId] = true;
                            return true;
                        })
                        folioSubjectItemTOC.resultCount = folioSubjectItemTOC.searchResult.length;
                        folioSubjectItemTOC.visible =  folioSubjectItemTOC.searchResult.length > 0;
                    }
                });
            }
            else if (`${PAGE_CONTROLLER_TYPE.deloitteCommunications}|${PAGE_CONTROLLER_TYPE.professionalNews}`.indexOf(pageControllerType) >= 0)  {
                collectionItems.forEach((item, idx) => {
                    const filteredTocResults = searchResults.filter(result => result.document.publicationObjectId === item.linkExternal);
                    var itemTOC = getItemTOC(item, filteredTocResults);
                    tocArray.push(itemTOC);
                })
            }
            else {
                collectionItems.forEach((publicationItem, idx) => {
                    var filteredTocResults =  searchResults.filter(result => result.document.publicationId === publicationItem.PublicationGUID);
                    var publicationTOC = getPublicationTOC(publicationItem, filteredTocResults);
                    tocArray.push(publicationTOC);
                })
            }
        }

        //Sort alpahbetically...
        tocArray = UTILITIESHELPER.sortArray(tocArray, "name");

        return tocArray;
    }
    function displayCollectionToc() 
    {
        let collectionTocArray=[]
        if(tocControl &&
            tocControl.length > 0) {
            tocControl.forEach((tocItem, index) => {
                if (tocItem.visible) {
                    collectionTocArray.push(<div key={`ab_${index}`}>
                        <CollectionTOC
                            item={tocItem.tocItem}
                            url={`${tocItem.href}?${SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase))}`}
                            pageControllerType={pageControllerType}
                            id={`ab_${index}`}
                            toc={tocItem}
                        ></CollectionTOC>
                    </div>);
                }
            })
        }
        return collectionTocArray;
    }
    const renderSearchResultTocBody = (collectionItems, searchTocBody) => {
        if (searchTocBody && !(Array.isArray(searchResults) && searchResults.length > 0)) {
            return null;
        }
        //For Folio, we have the Taxonomy values, not the collecitonItems!
        if (pageControllerType !== "folio" && (!(Array.isArray(collectionItems) && collectionItems.length > 0))) {
            return null;
        }
        return (
            <div>
                {
                    displayCollectionToc()
                }
            </div>
        );
    }

    const handlePublicationResult = (e, data) => {
        var publicationlink = LINKHELPERS.resolveSearchLink(data.result, memberFirm, language, knowledgeDomain, pageControllerType, searchPhrase, catalogCollection, true);
        navigate(`${publicationlink}`);
    };
    const handleViewAllResult = () => {

        setSearchTimeStamp(Date.now())

        if (searchPhrase.length < 3) {
            alert(getLabel("s_SearchPhraseTooShort", "Phrase too short (for now)"));
            return;
        }
        //setTocControl([]);

        //parentOnSubmit(searchPhrase)

        const currentLocation = window.location.pathname;

        if ((currentLocation.indexOf("/collection") > 0) && (PUBTYPES_CATALOG_PAGE.some(currentLocation))) {

            const parsedSearchPhrase = SearchHelpers.parse(searchPhrase);
            const qs = SearchHelpers.toQueryString(parsedSearchPhrase);

            navigate(`${currentLocation}?${qs}`);

            logs.trackEvent({
                name: 'Search',
                properties: {
                    searchText: searchPhrase,
                    memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                    serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                    level: UTILITIESHELPER.getSessionStorage('Level')
                }
            })
            return;
        }

        //alert(getLabel("s_SearchNotAvailableWhereYouAreCurrently", "Search not available where you are currently (for now)"));

        return;
    };

    let pubLandingUrls=window.location.pathname;
    if(pageControllerType === "folio")
    {
        pubLandingUrls=`/${memberFirm}/${language}/${knowledgeDomain}`;
    }

    const pageProps = {
        isWideMode,
        setIsWideMode,
        publicationType,
        hasAllTocDataLoaded,
        navTitle,
        pubLandingUrl: pubLandingUrls
    }


    if (isLoading) {
        return <Loading />;
    }

    //styling for collection search result panel
    const collectionSearcResult= {
        display: showResults ? 'block' : 'none'
    }
    function collectionSearchResultItemList(topicIndex,group){
        var collectionSearchResultItemList=[];
        filteredSearchResults.forEach((result, idx) => {
            if (result.document.publicationId === group.publicationId) {
                if (topicIndex < 3) {
                    result["url"] = LINKHELPERS.resolveSearchLink(result, memberFirm, language, knowledgeDomain, pageControllerType, searchPhrase, catalogCollection);
                    topicIndex++;
                    collectionSearchResultItemList.push(<CollectionSearchResultItem flag={document.flag} key={idx + '_' + result.document.id} {...result} onSelectLanguage={handleSearchSubmit}></CollectionSearchResultItem>)
                }
            }
        })
        return collectionSearchResultItemList;
    }
    function getLinkInternal(item){
        if ("deloitte-communications|professional-news".indexOf(pageControllerType) > -1)
            return get(item,'linkInternal.LinkUrl');             
        else if (pageControllerType === PAGE_CONTROLLER_TYPE.standards && item.PublicationType !== PUBLICATIONTYPE_IDS.standards) 
            return [`/${memberFirm}/${language}/${knowledgeDomain}/${PAGE_CONTROLLER_TYPE.standard}`,get(item,'linkInternal.LinkUrl')].join('/');           
        else 
            return [`/${memberFirm}/${language}/${knowledgeDomain}/${pageControllerType}`,get(item,'linkInternal.LinkUrl')].join('/');           

    }

    return (
        <div className= "collections-container">
            {
                isSearchPage ?
                    <PublicationLayout {...pageProps} tocBody={renderSearchResultTocBody(collectionItems, true)} TOCitems={collectionItems}>
                        <CollectionSearch {...pageProps} catalogCollection={catalogCollection} handleSearchSubmit={handleSearchSubmit} showRecentSearches={true} />
                    </PublicationLayout> :
                    <div className="pannels-container pannels-side-panel">
                        <div className={isFixedSearchBar ? `collection-searchbar-fixed` : `collection-searchbar`} id="collection-page-search">
                            <CollectionSearchbar
                                placeholder={`${getLabel("s_Search", "Search")}`}
                                className="searchbar-publication-collection"
                                autocomplete="off"
                                parentOnTrigger={handleSearchTrigger}
                                parentOnSubmit={handleSearchSubmit}
                                showRecentSearches={true}
                                calledFrom={page.Collection}
                            />
                            <div className='collection-search-result-panel' style={collectionSearcResult}>
                                {groupList.map((group, idx) => {
                                    let topicIndex = 0;// this topicIndex is used to displayonly 3 sub topics uncer singlr publication

                                    return <div className= "collection-search-result-list"key={idx}>
                                        <div className='d-flex'>
                                            <div  className= "collection-search-result-section"> { /* eslint-disable-next-line */}
                                                <a onClick={((e) => handlePublicationResult(e, group))} className='collection-searchbar-publication-title' >  {group?.publicationNavTitle}</a>
                                            </div>
                                        </div>
                                        {collectionSearchResultItemList(topicIndex,group)}
                                    </div>
                                })}
                                <div  className="viewAll-results"> { /* eslint-disable-next-line */}
                                    <a onClick={((e) => handleViewAllResult())} className='collection-search-view-all'>{getLabel("dd_ViewAllResults", "View All Results")}</a>
                                </div>
                            </div>
                        </div>
                        <WormholeNotification />
                        <MessageNotification />
                        <MainPannel className="mr-auto main-pannel-left">
                            {pageControllerType === "folio" ?
                                renderSearchResultTocBody(collectionItems, false) :
                                collectionItems.map((item, idx) => {
                                    return (
                                        <CollectionItemExpandable
                                            key={`ci_${idx}`}
                                            title={item.linkText}
                                            text={item.linkAltText}
                                            href={getLinkInternal(item)}
                                            icon={collectionIcon}
                                            viewAll={item.linkText}
                                        />
                                    )
                                })
                            }
                        </MainPannel>
                        <SidePanelComponent />
                    </div>
            }
        </div>
    )
}

export default Collection;
