import UTILITIESHELPER  from './UtilitiesHelper';
import {isIndexedComponent} from './getComponentInfo';
import {logs, page} from '../helpers/log.js';
import { ErrorCodes, ErrorMessages } from '../components/Constants/Errors';
import {  PUBLICATIONTYPE_IDS } from '../components/Constants/Constants';
var skipFootNoteInJumpLinks = true;

function createJumpLink(title, guid) {
    return {
        title,
        href: '#' + guid,
        jumpClass: 'jumplink-' + guid,
        children: []
    }
}

function getChildren(rootEl, preventAddDetailsOnlyChild = false) {
    let childrenStructuredArticleSection = Array.from(rootEl.querySelectorAll(':scope > .structured-article > .article-title-button-container'));
    if (!childrenStructuredArticleSection.length) {
        childrenStructuredArticleSection = Array.from(rootEl.querySelectorAll(':scope > .structured-article-panel > .structured-article-section > .article-sub-title-button-container'));
    }
    let childrenStructuredArticleSubSection = Array.from(rootEl.querySelectorAll(':scope > .structured-article-section > .article-sub-title-button-container'));
    if (!childrenStructuredArticleSubSection.length) {
        childrenStructuredArticleSubSection = Array.from(rootEl.querySelectorAll(':scope > .structured-article-subpanel > .structured-article-section > .article-sub-title-button-container'));
    }
    let childrenArticleDetailsSubtopicHeading = Array.from(rootEl.querySelectorAll(':scope > .article > details > summary > .subtopicheading'));

    let childrenStructuredArticleTechSpeaking = Array.from(rootEl.querySelectorAll(':only-child > .ts-section  .ts-toc-main > .ts-toc-heading'));

    let childrenDetails = [];
    if (rootEl.nodeName === 'DETAILS') {
        childrenDetails = Array.from(rootEl.querySelectorAll(':scope > details'));
    } else {
        childrenDetails = Array.from(rootEl.querySelectorAll(':only-child > details'));
        if (preventAddDetailsOnlyChild) {
            childrenDetails = childrenDetails.filter(el => el.parentElement.parentElement.parentElement.parentElement.className.includes('structured-article-panel'))
        }
    }
    childrenStructuredArticleSection = childrenStructuredArticleSection.map(el => ({ content: el, type: 'structured-article-section' }))
    childrenStructuredArticleSubSection = childrenStructuredArticleSubSection.map(el => ({ content: el, type: 'structured-article-subsection' }));
    childrenArticleDetailsSubtopicHeading = childrenArticleDetailsSubtopicHeading.map(el => ({ content: el, type: 'subtopicheading'}));
    childrenDetails = childrenDetails.map(el => ({ content: el, type: 'details'}));
    childrenStructuredArticleTechSpeaking = childrenStructuredArticleTechSpeaking.map(el => ({ content: el, type: 'technicallyspeaking'}));


    return [...childrenStructuredArticleSection, ...childrenStructuredArticleSubSection, ...childrenArticleDetailsSubtopicHeading, ...childrenDetails, ...childrenStructuredArticleTechSpeaking];
}

function buildAndGetChildrenFromStructuredSubArticle(subtopicHeading) {
    const link = createJumpLink(subtopicHeading.textContent, subtopicHeading.parentElement.id);
    const children = processChildren(subtopicHeading.parentElement);
    const filteredChildren = children.filter(x => x.href !== link.href);
    link.children = filteredChildren;
    return link;
}

function buildAndGetChildrenFromStructuredArticle(subtopicHeading) {
    const link = createJumpLink(subtopicHeading.textContent, subtopicHeading.parentElement.id);
    const children = processChildren(subtopicHeading.parentElement);
    const filteredChildren = children.filter(x => x.href !== link.href);
    link.children = filteredChildren;
    return link;
}

function buildAndGetChildrenFromSubtopicHeading(subtopicHeading) {
    const parentEl = subtopicHeading.parentElement.parentElement;
    // add jumplink class to parent so we have a jump target
    parentEl.classList.add('jumplink-' + parentEl.id);
    const link = createJumpLink(subtopicHeading.textContent, parentEl.id);
    const children = processChildren(parentEl);
    const filteredChildren = children.filter(x => x.href !== link.href);
    link.children = filteredChildren;
    return link;
}

function buildAndGetChildrenFromDetails(el) {
    const title = el.querySelector(':scope > summary > .subtopicheading')
    const parentEl = el.parentElement;
    // add jumplink class to parent so we have a jump target
    el.classList.add('jumplink-' + (UTILITIESHELPER.isStringNullorEmpty(title.id) ? parentEl.id : title.id));
    const link = createJumpLink(title.textContent, UTILITIESHELPER.isStringNullorEmpty(title.id) ? parentEl.id : title.id);
    const children = processChildren(el);
    const filteredChildren = children.filter(x => x.href !== link.href);
    link.children = filteredChildren;
    return link;
}

function buildAndGetChildrenFromTechnicallySpeakingHeading(subtopicHeading) {
    const parentEl = subtopicHeading.parentElement.parentElement;
    parentEl.classList.add('jumplink-' + parentEl.id);
    const link = createJumpLink(subtopicHeading.textContent, parentEl.id);
    return link;
}

function getPosition(rootHtml, link, compareLinks) {
    let counter = 0;

    compareLinks.forEach(l => {
        if (rootHtml.indexOf(l.href.replace('#', '')) >= 0) {
            counter = counter + 1;
        }
    })

    return counter;
}

function orderLinks(rootEl, links) {
    const rootHtml = rootEl.innerHTML;
    return links.map(link => {
        const linksWithoutActiveLink = links.filter(l => l.href !== link.href)
        const position = getPosition(rootHtml, link, linksWithoutActiveLink)
        return {
            ...link,
            position
        }
    }).sort((a, b) => a.position - b.position)
}

function processChildren(rootEl, preventAddDetailsOnlyChild = false) {
    let links = [];
    const children = getChildren(rootEl, preventAddDetailsOnlyChild);
    children.forEach(child => {
        let link;
        switch (child.type) {
            case 'structured-article-section':
                link = buildAndGetChildrenFromStructuredArticle(child.content);
                break;
            case 'structured-article-subsection':
                link = buildAndGetChildrenFromStructuredSubArticle(child.content);
                break;
            case 'subtopicheading':
                link = buildAndGetChildrenFromSubtopicHeading(child.content);
                break;
            case 'technicallyspeaking':
                link = buildAndGetChildrenFromTechnicallySpeakingHeading(child.content);
                break;
            default:
                link = buildAndGetChildrenFromDetails(child.content);
        }
        links.push(link)
    });

    links = orderLinks(rootEl, links);

    return links;
}

export function getJumpLinks(pubData, _isGuidebook, isJournalPage, contentSelector = '.structured-article-panel') {
    try {
        let jumplinks = [];
        const containerEl = document.querySelector(contentSelector);
        if (containerEl) {
            //jumplinks = getSubItemsLinks(containerEl);
            jumplinks = processChildren(containerEl, true);
            const containerElStructuredArticlePanel = document.querySelector('.article-content-container');
            jumplinks = !jumplinks.length ? processChildren(containerElStructuredArticlePanel, true) : jumplinks;
        }

        return jumplinks;
    } catch (err) {
        return []
    }
}
export function hasJumpLinks(pubData, _isGuidebook) {
    //This method needs to be called at the begining
    if (window.location.pathname.toLocaleLowerCase().includes("technically%20speaking")) {
        addClassInTechSpeakingDoc();
    }
    addCollapsibleSectionsToSubtopics();
    try {
        if(pubData !== null && pubData !== undefined){
            const items = _isGuidebook || (pubData?.publication_type === PUBLICATIONTYPE_IDS.externalCollection)? (pubData?.content?.[0]?.subItems || []) : pubData.content
            // H2, H3s and the like
            if (items !== null) {
                const filteredLength = items
                    .filter(({ content_type }) => !isIndexedComponent(content_type))
                    .map(({ content, id }) => createJumpLink(UTILITIESHELPER.getContentItemTitle(content, skipFootNoteInJumpLinks), id))
                    .filter(link => link.title)
                    .length

                if (filteredLength > 0) return true;
            }

            // .subtopic
            const pubText = pubData ? pubData?.content : [];
            if (!UTILITIESHELPER.isArrayNullorEmpty(pubText)) {
                const pubParsedText = pubText
                    .filter(c => !isIndexedComponent(c.content_type) && (c.content.text && c.content.text.length))
                    .map(c => c.content.text)
                    .join('');
                const parser = new DOMParser();
                const pubHTML = parser.parseFromString(pubParsedText, 'text/html');
                const subTopicHeadingNodeList = pubHTML.querySelectorAll('.subtopicheading');
                const filteredSubTopicHeadings = Array.from(subTopicHeadingNodeList).filter(el => el.textContent.trim().length > 0)
                return filteredSubTopicHeadings.length > 0;
            }
            else {
                return false;
            }
        }
    } catch (err) {
        logs.error(page.Link, 'Jumplinks', ErrorMessages.jumplinks, err,{eventId:ErrorCodes.Article});
        return true; // default to true, empty is better than hidden w/ content
    }
}

export function addCollapsibleSectionsToSubtopics()
{
    let subTopicHeadingNodeList = document.querySelectorAll('.subtopic');
    subTopicHeadingNodeList = subTopicHeadingNodeList ? subTopicHeadingNodeList : [];

    Array.from(subTopicHeadingNodeList)
        .map(subTopic => { //eslint-disable-line
            const summaryTag = document.createElement('summary');
            const titleTag = subTopic.querySelector('.subtopicheading');
            if (titleTag) {
                summaryTag.append(titleTag.cloneNode(true));
                subTopic.replaceChild(summaryTag, titleTag);

                const detailsTag = document.createElement('details');
                const subTopicId = subTopic.getAttribute('id')
                const subTopicAriaLabelled = subTopic.getAttribute('aria-labelledby')
                detailsTag.setAttribute('open', 'open');
                detailsTag.setAttribute('id', subTopicId);
                detailsTag.setAttribute('aria-labelledby', subTopicAriaLabelled);
                detailsTag.innerHTML = subTopic.innerHTML;
                subTopic.parentNode.replaceChild(detailsTag, subTopic);
            }
        })
}

export function addClassInTechSpeakingDoc() {
    if (document.querySelectorAll('.ts-section').length <= 0) {
        let tocHeadingNodeList = document.querySelectorAll('.div .table tbody tr td h2:not(.sectiontitle), .div .table tbody tr td h3:not(.sectiontitle)');
        tocHeadingNodeList = tocHeadingNodeList ? tocHeadingNodeList : [];
        Array.from(tocHeadingNodeList)
            .map(subTopic => { //eslint-disable-line
                subTopic.parentElement.classList.add("ts-toc-main");
                subTopic.classList.add("ts-toc-heading");
                subTopic.parentElement.parentElement.setAttribute("id", UTILITIESHELPER.generateUUID())
            });

        let tsSection = document.querySelectorAll('.div .table');
        tsSection = tsSection ? tsSection : [];
        let index = 1;
        Array.from(tsSection)
            .map(subTopic => {//eslint-disable-line
                if (subTopic.parentElement.parentElement.className.includes('article')) {
                    subTopic.style.width = "100%";
                    subTopic.parentElement.classList.add("ts-section");
                    if (index <= 2) {
                        subTopic.parentElement.classList.add("ts-banner_" + index);
                    }
                    if (subTopic.querySelector("img")?.src?.includes("deloitte-logo")) {
                        subTopic.parentElement.style.display  = "none";
                    }
                    index++;
                }
            });

        let tspSection = document.querySelectorAll('.div .table p, .div .table .p');
        tspSection = tspSection ? tspSection : [];
        Array.from(tspSection)
            .map(subTopic => {//eslint-disable-line
                if (subTopic.parentElement.style.color) {
                    subTopic.style.color = subTopic.parentElement.style.color;
                }
            });
    }

}
export default {
    getJumpLinks,
    hasJumpLinks
}
