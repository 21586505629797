import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { usePageContextLabels } from '../../contexts/PageContext';

const AnnotationDeleteModal = ({
    shouldShowDeleteModal,
    setShouldShowDeleteModal,
    shouldShowOverlay,
    setShouldShowOverlay,
    deleteAnnotationMessage,
    deleteGuids,
    deleteItems,
    isBookmarkDelete,
}) => {
    const DeleteModalRef = useRef();
    const { getLabel } = usePageContextLabels();
    const [deleteStarted] = useState(false);

    const hrefValue="#";
    const selectedHeading= isBookmarkDelete ? getLabel("hh_DeleteBookmark", "Delete Bookmark") :getLabel("hh_DeleteAnnotation", "Delete Annotation") ;
    const closeMessage = isBookmarkDelete ? getLabel("hh_DeleteBookmarkCancel", "Do not delete Bookmarks") : getLabel("hh_DeleteAnnotationCancel", "Do not delete Annotations");

    function closeModal() {
        setShouldShowOverlay(false);
        setShouldShowDeleteModal(false);
        document.body.style.overflow = 'unset';
    }

    useEffect(() => {
        return () => {
            setShouldShowOverlay(false);
            setShouldShowDeleteModal(false);
            closeModal();
        }
    }, [])//eslint-disable-line

    async function deleteAnnotation() {
        deleteItems(deleteGuids);
        closeModal();
    }

    return (
        <div>
            {shouldShowOverlay &&
                <div className='full-screen-darken-overlay-fixed' />
            }

            <div ref={DeleteModalRef} className={cx("delete-modal", { 'hide': !shouldShowDeleteModal })}>
                <div className="delete-modal-header">
                {selectedHeading}
                    <button className="delete-modal-close" onClick={closeModal} title="Close"></button>
                </div>
                {!deleteStarted &&
                    <div align="center">
                        <div className="delete-modal-body">
                            <p>{deleteAnnotationMessage}</p>
                        </div>

                        <div>
                            <button
                                className="btn btn-primary btn-delete-confirm"
                                onClick={deleteAnnotation}
                                title={getLabel('', '')}
                            >Delete
                            </button>
                            </div>
                            <div className="close-message">
                            <a className='delete-cancel' href={hrefValue} onClick={closeModal}>{closeMessage}</a>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

AnnotationDeleteModal.defaultProps = {
    setShouldShowDeleteModal: () => { },
    setShouldShowOverlay: () => { },
}

export default AnnotationDeleteModal;
