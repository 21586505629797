import React from 'react';
import { useLocation } from 'react-router-dom';
import HandleError from './HandleError';
//import Page404 from './Page404';

const ErrorHandler = ({ children }) => {
    const location = useLocation();

    //console.log("ErrorHandler", location.state);

    switch (location.state) {
        case 404:
            return (
                <HandleError errorHeading ="Custom 404" errorMessage = "" /> 
            )
        case 500:
            return (
                <HandleError errorHeading="Custom 500" errorMessage="" /> 
            )
        default:
            //console.log("ErrorHandler::children",children)
            //console.log("ErrorHandler::default::location.state", location.state);
            return children;
    }
};

export default ErrorHandler;