import React from 'react';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import ReactHtmlParser from 'react-html-parser';

const CollectionSearchResultItem = (props) => {
    const { viewAllResultsUrl, document, url } = props;

    function getDocumentTitle() {
		// fallback to Publication Nav Title just incase if NavTitle is null
    let documenttitle="";
    if(url.includes("/folio") || url.includes("/manual") || url.includes("/standards") || url.includes("/guidance")){
      documenttitle = UTILITIESHELPER.isStringNullorEmpty(document.navTitle) ? 
			(UTILITIESHELPER.isStringNullorEmpty(document.publicationNavTitle) ? ReactHtmlParser(document.publicationTitle) : ReactHtmlParser(document.publicationNavTitle))
			: ReactHtmlParser(document.navTitle);
    }
    else{
      documenttitle = UTILITIESHELPER.isStringNullorEmpty(document.publicationNavTitle) ? 
      (UTILITIESHELPER.isStringNullorEmpty(document.publicationTitle) ? '' : ReactHtmlParser(document.publicationTitle))
      : ReactHtmlParser(document.publicationNavTitle);
    }  
		return documenttitle;
	}    

    return (
      <div className="collection-result-item-main">
        <div  className="collection-result-item-main-container">
          {(url.includes("/folio")) ? (
            <a href={viewAllResultsUrl}>{getDocumentTitle()}</a>
          ) : (
            <a href={url}>{getDocumentTitle()}</a>
          )}
        </div>
      </div>
    );
}

export default CollectionSearchResultItem;
