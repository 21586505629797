import React, { useState, useRef } from "react";
import { usePageContextLabels } from "../../contexts/PageContext";
import ShowIf from "../Common/ShowIf";
import cx from 'classnames';
import useDCSService from '../../services/useDCSService';

function UserFeedbackModal({ userFeedBackType, setIsFeedBackModalOpen, isFeedBackModalOpen }) {
    const MAX_COMMENT_LENGTH = 2000;
    let btnRef = useRef();
    const [shouldShowDarkOverlay, setshouldShowDarkOverlay] = useState(true);
    const [comment, setComment] = useState("");
    const { getLabel } = usePageContextLabels();
    const [selectedOptons, setSelectedOptons] = useState([]);
    const positiveFeedBackResponse = ["Accuracy of Response", "Completeness of Response", "Understandability of Response", "Other"];
    const negativeFeedBackResponse = ["Accuracy of Response", "Completeness of Response", "Understandability of Response", "Incorrect Topic/Off-Topic Response", "Correct Response provided but sources are unrelated or incomplete", "Inappropriate Response provided", "Other"];
    const { researchAssistantUserFeedback } = useDCSService();

    function handleChange(e) {
        if (e.target.checked) {
            setSelectedOptons([...selectedOptons, e.target.value]);
        } else {
            setSelectedOptons(selectedOptons.filter((item) => item !== e.target.value));
        }
    }

    function handleCloseClick() {
        setshouldShowDarkOverlay(false);
        setIsFeedBackModalOpen(false);
    }

    function handleCommentChange(e) {
        const value = e.target.value;
        setComment(value.slice(0, MAX_COMMENT_LENGTH));
    }

    const handleSaveClick = async () => {
        let payload = {
            selected_Options: selectedOptons,
            comment: (comment.trim() && comment.trim().length > 0) ? comment : "",
        };
        if (payload.selected_Options.length > 0 || payload.comment.length > 0) {
            alert(JSON.stringify(payload));
            let response = await researchAssistantUserFeedback(payload);
            setIsFeedBackModalOpen(false);
        }
    };

    return (
        <div className={cx("modal-container-ra", { 'hide': !isFeedBackModalOpen })}>
            {shouldShowDarkOverlay &&
                <div className='full-screen-darken-overlay-fixed' onClick={handleCloseClick} />
            }
            <div className="userfeedback-modal">
                <div className="modal-header">
                    <div className="modal-title">{userFeedBackType === "Positive" ? getLabel("ra_goodresponse", "Good Response") : getLabel("ra_notagoodresponse", "Not a good Response")}</div>
                    <button className="modal-close" data-testid="close-modal" onClick={handleCloseClick} />
                </div>
                <div className='modal-content'>
                    <div className="title">{userFeedBackType === "Positive" ? getLabel("ra_goodresponsetitle", "What did you like about the Response? Select all that apply!") : getLabel("ra_notagoodresponsetitle", "What did you dislike about the Response? Select all that apply!")}</div>
                    <div className="sugegstion-container">
                        <ShowIf condition={userFeedBackType === "Positive"}>
                            {
                                positiveFeedBackResponse.map((item, index) => {
                                    return (
                                        <div className="sugegstion-item" key={`${"Positive"}-${index}`}>
                                            <input className="input-item" value={item} type="checkbox" onChange={handleChange} />
                                            <span>{item}</span>
                                        </div>
                                    )
                                })
                            }
                        </ShowIf>

                        <ShowIf condition={userFeedBackType === "Negative"}>
                            {
                                negativeFeedBackResponse.map((item, index) => {
                                    return (
                                        <div className="sugegstion-item" key={`${"Negative"}-${index}`}>
                                            <input className="input-item" value={item} type="checkbox" onChange={handleChange} />
                                            <span>{item}</span>
                                        </div>
                                    )
                                })
                            }
                        </ShowIf>

                    </div>
                    <div className="modal-comment">
                        <textarea
                            data-testid="modal-comment-input"
                            className="modal-comment-input"
                            value={comment}
                            onChange={handleCommentChange}
                            placeholder={getLabel(
                                "ra_additionalcomment",
                                "Please provide additional comments"
                            )}
                        ></textarea>
                        <span className="modal-comment-count">
                            {comment.length}/{MAX_COMMENT_LENGTH}
                        </span>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="modal-save" data-testid="submit-feedback-button" onClick={handleSaveClick}
                        ref={btnRef}>
                        <img className='feedback-submit-image' alt="" src='/images/arrow-submit-white.svg' />
                        <span>{getLabel("ra_submitbutton", "Submit")}</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default UserFeedbackModal;