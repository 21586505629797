import React from 'react';
import { usePageContextLabels } from '../../contexts/PageContext';
import LanguageSelector from '../Common/LanguageSelector';


const HomeHeader = () => {
	const { getLabel } = usePageContextLabels();

	return (
		<header className="header-homepage">
			<a className="skipnavigation" href="#main">{getLabel("h_SkipNavigation", "Skip Navigation")}</a>
			<div className='language-selector-homepage'>
                 <LanguageSelector />
			</div>
		</header>
	)
}

export default HomeHeader;