import React, { useState, useRef } from 'react';
import { usePageContextLabels, usePageContext } from '../../contexts/PageContext';
import { useLocation } from "react-router-dom";
import {
    useUserPreferencesContext,
    ACTIONS,
} from "../../contexts/AnnotationsContext";
import ARTICLEHELPERS from "../../helpers/ArticleHelpers";
import useOutsideClick from '../../hooks/useOutsideClick';
import BookmarkService from '../../helpers/BookmarkServices';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { logs } from '../../helpers/log';
import { APPLICATION_INSIGHTS_CONSTANTS, LOCAL_STORAGE_CONSTANTS, SESSION_STORAGE_CONSTANTS } from '../Constants/Constants';

function CategoryItem({ category, selected, onClick, index }) {

    function handleClick() {
        onClick(category);
    }
    let classNames = 'an-dd-category-item';

    return (
        <div className={classNames} onClick={handleClick} id={'option-' + index}>
            <div className="an-dd-category-chip" style={{ backgroundColor: category.color }}></div>
            <div className="an-dd-category-label">{category.label}</div>
        </div>
    )
}

function BookmarkCategory({selected, onSelect, onAddClick, active, id }) {
    const {setBookmarkOpen, categoryOn} = usePageContext();
    const { state: annState, dispatch } = useUserPreferencesContext();
    const location = useLocation();
    const [open, setOpen] = useState(active);
    const { getLabel } = usePageContextLabels();
    const ref = useRef();
    const { context, getMemberFirmID } = usePageContext();

    async function handleSelect(category) {
        onSelect(category);
        if (!categoryOn) {
            handleTriggerClick();
            if (category.category_guid !== selected) {
                let payload = {
                    category_guid: category.category_guid,
                    page_url: location.pathname,
                    reference_identifier: CONTEXTHELPERS.getGUIDFromContext(context),
                    page_bread_crumb: ARTICLEHELPERS.getBreadcrumbData()
                }
                payload = await BookmarkService.createBookmark(payload, getMemberFirmID());
                if (payload) {
                    dispatch({
                        type: ACTIONS.BOOKMARKS_CREATE,
                        payload,
                    });

                    if (bookmarkProps.selectedTocItem !== null && bookmarkProps.pageType !== '') {
                        logs.trackEvent({
                            name: APPLICATION_INSIGHTS_CONSTANTS.bookmark_name,
                            properties: bookmarkProps,
                        });
                    }
                }
                CONTEXTHELPERS.sortCategories(annState?.categories, category.category_guid);
            }
        }
    }

    function handleAddClick() {
        onAddClick();
    }

    const listItems = annState?.categories?.map((c, index) => (
        <CategoryItem
            key={`${c.label}-${c.color}`}
            category={c}
            onClick={handleSelect}
            selected={selected}
            index={index - 1}
        />
    ))
    function handleTriggerClick() {
        setOpen(!open);
        setBookmarkOpen((prevVal) => !prevVal);

    }
    function handleCloseClick() {
        setOpen(false);
        setBookmarkOpen((prevVal) => !prevVal);
    }
    useOutsideClick(ref, handleCloseClick);

    const bookmarkProps = {
        eventType: APPLICATION_INSIGHTS_CONSTANTS.bookmark_eventType,
        pageUrl: context.pageRequestURL,
        selectedTocItem: UTILITIESHELPER.getSessionStorage(SESSION_STORAGE_CONSTANTS.selected_content),
        pageType: UTILITIESHELPER.getSessionStorage(SESSION_STORAGE_CONSTANTS.page_type),
        jobTitle: UTILITIESHELPER.getSessionStorage(SESSION_STORAGE_CONSTANTS.level),
        memberFirm: UTILITIESHELPER.getLocalStorage(LOCAL_STORAGE_CONSTANTS.research_portal_memberfirm)
    };

    return (
        <>
            {open && (
                <div className="an-dd-panel">
                    <div
                        maxHeight="145px"
                        className="dd__scroll-area an-dd-scroll-area-bookmark"
                    >
                        {listItems}
                    </div>
                    <button className="an-dd-category-add" onClick={handleAddClick}>
                        <div className="an-dd-category-add-image"></div>
                        <div className="an-dd-category-add-label">
                            {getLabel("hh_CategoryCreate", "CREATE NEW CATEGORY")}
                        </div>
                    </button>
                </div>
            )}
        </>
    );
}

export default BookmarkCategory;