import { useState, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { ErrorMessages } from "../components/Constants/Errors";
/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest 
 * @returns
 */
const useFetchWithMsal = () => {
  const { instance, accounts } = useMsal();
  const [error, setError] = useState(null);//eslint-disable-line
    const [isLoading, setIsLoading] = useState(false);//eslint-disable-line
  const getAccessToken = async () => {
    const DO_NOT_LOGIN = (window.location.href.indexOf("localhost") > 0);
    if(!DO_NOT_LOGIN) {
    if (accounts.length > 0) {
        try {
            const response = await instance.acquireTokenSilent({
                account: accounts[0],
                scopes: [`${window.DART.ENV.REACT_APP_CLIENTID}/.default`]
            });
            return response.accessToken;
        } catch (error) {
            if (error instanceof instance.InteractionRequiredAuthError) {
                const response = await instance.acquireTokenPopup({
                    scopes: [`${window.DART.ENV.REACT_APP_CLIENTID}/.default`]
                });
                return response.accessToken;
            }
        }
    }
  }
    else {
      return process.env.REACT_APP_ACCESSTOKEN;
  }
 }
  /**
   * Execute a fetch request with the given options
   * @param {request} Object: {url,method,body}
   * @param {customheaders} Object: additional headers
   * @returns JSON response
   */
  const apiCall = async (request,customheaders = {}, signal = null) => {
    const accesstoken = await getAccessToken();
    
    if(!accesstoken) {
      setError(ErrorMessages.accesstokennotavailable);
      return ;
    }
    try {
        let response = null;
        const headers = {
          "Content-Type": "application/json",
            "Access-Control-Allow-Origin": window.location.origin,
          "api-version": "1",
          ...customheaders,
        };
        if (accesstoken) {
          headers["Authorization"] = `Bearer ${accesstoken}`;
        }
        let options = {
          method: request.method,
          headers: headers,
          body: request.body,
        };
        if(signal) {
          options.signal = signal
        }
        setIsLoading(true);
        response = await fetch(request.url,options);
        const responseData = await handleResponse(response);
        setIsLoading(false);
        return responseData;
      } catch (e) {
        setError(e);
        setIsLoading(false);
        throw e;
      }
  };

const handleResponse = async (response) => {
  if (response.status === 200) {
    return handleSuccess(response);
  }
  if (response.status === 204) {
    return handleNoContent();
  }
  if (response.status >= 400 && response.status < 500) {
    return handleClientError(response);
  }
  if (response.status >= 500) {
    return handleServerError(response);
  }
  throw new Error(`HTTP error! status: ${response.status}`);
};
const handleSuccess = async (response) => {
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
    try {
        const json = response.json();
    return json;
  } catch (e) {
    throw new Error(`Invalid JSON response: ${e}`);
  }
};
const handleNoContent = () => {
  return null;
};
const handleClientError = async (response) => {
  const error = new Error(`HTTP client error! status: ${response.status}`);
    error.response = Promise.resolve({ response });
  throw error;
};
const handleServerError = async (response) => {
  const error = new Error(`HTTP server error! status: ${response.status}`);
    error.response = Promise.resolve({ response });
  throw error;
};
  return {
    apiCall: useCallback(apiCall, []), // to avoid infinite calls when inside a `useEffect`
  };
};
export default useFetchWithMsal;
