import React, { useState, useEffect,useRef, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { usePageContext, usePageContextLabels, usePageContextSettings } from '../../contexts/PageContext';
import { logs, page, appInsightUIMapping } from '../../helpers/log';
import RETRIEVALSERVICES from '../../services/rp-service';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import ArticleResolver from '../Publication/ArticleResolver';
import { useArticleContext } from '../../contexts/ArticleContext';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import ARTICLEHELPERS from '../../helpers/ArticleHelpers';
import { useTocContext } from '../../contexts/TocContext';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import RecentViewedService from '../../helpers/RecentlyViewedServices';
import { ErrorCodes, ErrorMessages,ErrorTypes } from '../Constants/Errors';
import LINKHELPERS from '../../helpers/LinkHelpers';
import { useRecentViewContext } from '../../contexts/RecentViewContext';

const ManualArticle = memo((props) => {
    const { setHasFetchedArticleData } = props;
    const { knowledgeDomain,memberFirm, language, pageControllerType, manualTitle, manualGUID } = useParams();
    const [pubData, setPubData] = useState({});
    const { industryVal, tierVal, getMemberFirmID } = usePageContext();
    const { isCompareEnabled, isRecentlyViewedEnabled,isRestrictedAccessMemberfirm } = usePageContextSettings();
    const { setArticleVersion } = useArticleContext();
    const [publicationVersions, setPublicationVersions] = useState([])
    const navigate = useNavigate();
    const { notification, setNotification } = usePageContext();//eslint-disable-line
    const { recentViewHistory, setRecentViewHistory } = useRecentViewContext()

    const {
        context,
        isNavigatedFromCollectionSearch,
        isSpinePage,
        isJournalPage
    } = usePageContext();
    const selectedItemTitle = context.pageSelectedContentItemTitle;
    const selectedItemGUID = context.pageSelectedContentItemGUID;
    const selectedSpineTargetPublicationGUID = context.pageSelectedSpineTargetPublicationGUID;
    
    const { setSearchResults,setActiveFilters,tocControl,
        setAreSearchResultsLoading,latestRetrieveSearchResultsTimeStampRef } = useSearchResultContext();

    const { toc, flattenedToc } = useTocContext();
    const { getMFLabel, getLabel } = usePageContextLabels();
    const { getGuidebookSectionData } = useGuidebookTocContext();
    
    const getSecondMostRecentVersion = () => {
        try {
            return publicationVersions?.length > 1 ? publicationVersions[1]?.version : null;
        }
        catch {
            return null;
        }
    }

    const mounted = useRef(true);
    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        // There is a memory leak being caused by fetching the pub after the user navigates way
        // https://dev.to/pallymore/clean-up-async-requests-in-useeffect-hooks-90h
        const retrievePublication = async () => {
            try {
                setPubData({})
                setHasFetchedArticleData(false)

                const res = await RETRIEVALSERVICES.retrieveManualPublication();
                logs.debug(page.Article, 'ManualArticle', "retrieveManualPublication:context, res", context, res);

                if (!UTILITIESHELPER.isNullOrUndefined(res.data)) {
                    let publication = ARTICLEHELPERS.getFilterPubData(res.data);
                    setPubData(publication);
                    setArticleVersion(publication.version);
                }
                if (res.responseStatus === 204) {
                    setNotification({ message: getLabel("err_ContentErrorMessage","Oh no, something has gone wrong! Please try again later. If the problem persists, please contact support. Code – ") + ' ' + ErrorCodes.Publication, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Error, componentName: 'ManualArticle',page:page.Article,eventId:ErrorCodes.Article });

                }
                else if (res.responseStatus === 400) {
                    setNotification({ message: getLabel("err_ContentErrorMessage","Oh no, something has gone wrong! Please try again later. If the problem persists, please contact support. Code – ") + ' ' + ErrorCodes.Publication, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Error, componentName: 'ManualArticle',page:page.Article,eventId:ErrorCodes.Article });
                }
                else {
                    setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null,page:null,eventId:null  });
                }
                setHasFetchedArticleData(true);
            }
            catch (err) {
                logs.error(page.ManualArticle, "ManualArticle", ErrorMessages.manualArticle, err,{eventId:ErrorCodes.ManualArticle});
                //If the article cannot load, then we wormhole up to the parent manual
                navigate(LINKHELPERS.sanitizeUrl(`/${memberFirm}/${language}/${knowledgeDomain}/${pageControllerType}/${manualTitle}/${manualGUID}?wormhole=true`));
            }

            logs.trackPageView({
                name: `${pageControllerType} - ${manualTitle} - ${selectedItemTitle}`,
                properties: {
                    memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                    serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                    level: UTILITIESHELPER.getSessionStorage('Level')
                }
            })

            if (isRecentlyViewedEnabled() && !isRestrictedAccessMemberfirm()) {
                try {
                    // new recently viewed implementation [Refactored]
                    const recentViewUpdatedHistory = await RecentViewedService.updateHistoryObject(window.location.pathname, `${manualTitle} / ${selectedItemTitle}`, getMemberFirmID(), context, recentViewHistory);
                    setRecentViewHistory(recentViewUpdatedHistory)
                } catch (ex) {
                    logs.error(page.ManualArticle, "RecentlyViewed", "RecentlyViewedHistory:Error Processing RecentlyViewed:", ex);
                }
            }
        }

        const retrievePubVersions = async () => {
            try {
                setPublicationVersions([]);
                const res = await RETRIEVALSERVICES.retrieveManualPublicationVersions();
                setPublicationVersions(res.data)
            } catch {
                setPublicationVersions([]);
            }
        };

        retrievePublication();

        if (isCompareEnabled()) {
            retrievePubVersions();
        }
        //eslint-disable-next-line
    }, [selectedItemGUID, selectedSpineTargetPublicationGUID, tierVal, industryVal])

    useEffect(() => {
        if (isNavigatedFromCollectionSearch) {
            const MapSearchResults = async () => {
                const currentReqTime = Date.now();
                latestRetrieveSearchResultsTimeStampRef.current = currentReqTime;
                if (UTILITIESHELPER.isArrayNullorEmpty(flattenedToc)
                    || !Array.isArray(flattenedToc) || flattenedToc?.length === 0
                    || currentReqTime !== latestRetrieveSearchResultsTimeStampRef.current)
                    return ;
                try {
                    setAreSearchResultsLoading(true);
                    setSearchResults([]);
                    setActiveFilters([]);
                    let results = tocControl.find(x => x.id === context.pageSelectedPublicationGUID)?.searchResult
                    if (!UTILITIESHELPER.isArrayNullorEmpty(results)) {
                        let searchresults = results.filter(x => x.highlightsCount > 0);
                        const extendedResults = ARTICLEHELPERS.mapSearchResultsToSelectedToc(searchresults,context,isJournalPage,flattenedToc,isSpinePage,getMFLabel,toc,getGuidebookSectionData);
                        if (!UTILITIESHELPER.isArrayNullorEmpty(extendedResults)) {
                            setSearchResults(extendedResults);
                        }
                        else {
                            setSearchResults([]);
                        }
                    }
                    else {
                        setSearchResults([]);
                    }
                    setAreSearchResultsLoading(false);
                }
                catch (err) {
                    logs.error(page.Publication, 'ManualSearch',ErrorMessages.manualSearch , err, { memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'), knowledgeDomain: context.knowledgeDomain,eventId:ErrorCodes.Publication });
                    navigate(CONTEXTHELPERS.getFallbackUrl(window.location.href));
                }
            }

            MapSearchResults()
        }
        //eslint-disable-next-line
    }, [isNavigatedFromCollectionSearch, flattenedToc]);

    if (!pubData || !pubData?.content) {
        //return <Loading />
        return (
            <div className="article-container cf-right shimmerArticleCard shimmerArticleWrapper">
                <h2 className="article-title shimmerArticleCardBG shimmerArticleCardTitleLine"><span className="ph"></span></h2>
                <div className="article-content-container">
                    <div className="article">
                        <div className="body">
                            <div className="shimmerArticleCardBG shimmerArticleCardIntroLine"></div>
                            <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                            <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                            <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                            <div className="shimmerArticleCardBG shimmerArticleCardContentLine shimmerArticleCardContentLineEnd"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <ArticleResolver
            {...props}
            secondMostRecentVersion={getSecondMostRecentVersion()}
            pubData={pubData}
        />
    )
});
export default ManualArticle;