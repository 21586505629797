import { useArticleContext } from '../../contexts/ArticleContext';
import getTextWithSearchHighlight from '../../helpers/getTextWithSearchHighlight';
import React from 'react';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { logs } from "../../helpers/log"; //, ai
import HTMLContent from '../Common/HTMLContent';

export const Footnote = (props) => {

    const { searchText, setOpenMultipleExpandedSections } = useArticleContext();

    function jumplinkClickHandler(e) {
        logs.trackEvent({
            name: 'Footnote',
            properties: {
                memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                level: UTILITIESHELPER.getSessionStorage('Level')
            }
        })
        const getAllExpandableSectionIds = (internalHref) => {
            const footnoteElem = document.querySelector(internalHref);
            const sectionsToExpand = [];
            const getClosestExpandableSection = (elem) => {
                const expandableSectionElem = elem.closest('[data-expandable-uuid]');
                if (!expandableSectionElem) return
                const sectionUUID = expandableSectionElem.dataset.expandableUuid;
                sectionsToExpand.push(sectionUUID);
                getClosestExpandableSection(expandableSectionElem?.parentNode);
            }

            getClosestExpandableSection(footnoteElem);

            return sectionsToExpand;
        }

        const href = e.currentTarget.getAttribute('href');
        const sectionsToExpand = getAllExpandableSectionIds(href);
        setOpenMultipleExpandedSections(sectionsToExpand, true);

        const scrollToSection = () => {
            var bodyRect = document.body.getBoundingClientRect(),
                elemRect = document.querySelector(href).getBoundingClientRect(),
                offsetTop = elemRect.top - bodyRect.top;
            const offsetCalc = offsetTop - 100;
            window.scrollTo(0, offsetCalc);
        }

        e.preventDefault();
        setTimeout(scrollToSection, 0);
    }
    const convertFootnote = (content) => {
        content = content.replace(/<div/g, '<span')
        content = content.replace(/<\/div>/g, '</span>')
        return content;
    }

    return (
        <div className='footnotes' key={props.footnote.number + "." + props.index} id={"footnote-" + props.footnote.number}>
            <span>
            <sup className="endnote-number">
                <a
                    href={"#footnote-top-" + props.footnote.number}
                    onClick={jumplinkClickHandler}
                ><HTMLContent>{getTextWithSearchHighlight((UTILITIESHELPER.checkStringIsHtml(props.footnote.label)), searchText)}</HTMLContent></a>
            </sup>
            </span>

            <span className="endnote-text"><HTMLContent>{convertFootnote(getTextWithSearchHighlight(props.footnote.text, searchText))}</HTMLContent></span>
        </div>
    )
}

Footnote.propsTypes = {
    typeName: "Footnote"
}

export default Footnote;