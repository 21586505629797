import React, { useState,useEffect } from "react";
import useDCSService from "../../services/useDCSService"
import { usePageContextLabels,usePageContext } from "../../contexts/PageContext";
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import CONTEXTHELPERS from "../../helpers/ContextHelpers";

const ChatBot = ()=>{
    var [chatbotSearchText,setChatBotSearchText]=useState("");
    var [searchResultdata,setSearchResultsData]=useState({});
    const { retrieveChatBotSearchResult } = useDCSService();
    const {  tier, industry, memberFirm,knowledgeDomain,getMemberFirmID } = usePageContext();
    const { getLabel } = usePageContextLabels();




var chatBotSearchHandler=async ()=>{
    const mf = UTILITIESHELPER.getSessionStorage('MemberFirm');
    console.log(industry);
    if(chatbotSearchText !=="")
    {
        var payload={
            userLibrariesSelected:[`${getMemberFirmID()}`],
            userFilterSelected:[
                {
                    filterField: "Industry",
                    filterID: industry.us.auditing[0]?.value
                },
        
                {
                    filterField: "Framework",
                    filterID: tier.us.auditing[0]?.value
                },
                {
                    filterField: "KnowledgeDomain",
                    filterID: [`${CONTEXTHELPERS.getknowledgeDomainId(knowledgeDomain)}`]
                }
            ],
            currentMessage: chatbotSearchText,
            previousMessages:[
                {message: "what is revenue 606?",

            response: "ASC 606, Revenue From Contracts With Customers, outlines the principles that an entity must apply to report useful information to users of financial statements about the nature, amount, timing, and uncertainty of revenue and cash flows arising from a contract with a customer. According to ASC 606-10 15-5"}
        ]
        }
        var response=await retrieveChatBotSearchResult(payload);
     
        setSearchResultsData(response);
    }
    else{
        setSearchResultsData("")
    }
    
}

function handleEnterEvent(e) {
    setChatBotSearchText(e.target.value)
    if (e.keyCode === 13) {
        e.preventDefault();
        chatBotSearchHandler();
    }
}

    return (
        <>
            <div className="main-Container">
                <div className="search-boc-container">
                    <input className="searchbox" type="text" placeholder={getLabel("What would u like to ask?","What would u like to ask?")} required onKeyUp={(e)=>{handleEnterEvent(e)}} />
                    <button className="an-modal-deletepopup" onClick={chatBotSearchHandler} >
                        Send
                    </button>
                </div>
                <div className="body-container">
                    <p>{searchResultdata.response}</p>
                
                <ul>
                {searchResultdata.suggestedQuestions!==undefined?
                searchResultdata.suggestedQuestions.map((iteam,index)=>(
                    <li key={index}>
                        {iteam}
                    </li>
                )):""
                }
                </ul>
                </div>
                
            </div>
        </>
    )
};
export default ChatBot;