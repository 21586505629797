import React from 'react';
import { usePageContextLabels } from '../../contexts/PageContext';

export const Unauthorized = () => {
    const { getLabel } = usePageContextLabels();

    return (
        <div className='container'>
            <div className="message">
                <h1>401 - {getLabel("u_UnAuthorized", "UnAuthorized")}</h1>
                <p>{getLabel("u_UnAuthorized", "UnAuthorized")}</p>
            </div>
        </div>
    )
}

export default Unauthorized;