import React, { useEffect } from 'react';
import { useArticleContext } from '../../contexts/ArticleContext';
import { Footnote } from './Footnote';

export const Footnotes = (props) => {
    const { footNotesContentRef } = useArticleContext()

    function jumplinkClickHandler(e) {
        const href = e.currentTarget.getAttribute('href');
        var bodyRect = document.body.getBoundingClientRect(),
            elemRect = document.querySelector(href).getBoundingClientRect(),
            offsetTop = elemRect.top - bodyRect.top;
        const offsetCalc = offsetTop - 60;
        window.scrollTo(0, offsetCalc);
        e.preventDefault();
    }

    useEffect(() => {
        footNotesContentRef.current = props.footnoteArray;
        return () => footNotesContentRef.current = undefined;
    }, [props.footnoteArray])//eslint-disable-line

    return (
        <div className="endnote-container">
            <p className="endnote-title"><strong>Footnotes</strong></p>
            <div className="endnote-table-container">
                {props.footnoteArray.map((footnote, index) => {
                    return (
                        <Footnote
                            key={footnote.number}
                            footnote={footnote}
                            index={index}
                            jumplinkClickHandler={jumplinkClickHandler}
                        />
                    )
                })}
            </div>
        </div>
    );
}

Footnotes.propsTypes = {
    typeName: "Footnotes"
}

export default Footnotes;
