import React from "react";

export const AnnotationCommentIcon = ({ fillColor, preferenceId }) => {
  return (
    <svg
      className="annotation_comment"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      cursor="pointer"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.19616 0.95166C3.57849 0.95166 1.45632 3.07383 1.45632 5.6915C1.45632 6.53609 1.67952 7.3271 2.06654 8.01366C2.06699 8.01589 2.0661 8.01902 2.06699 8.02036C2.15984 8.1766 2.26296 8.523 2.26296 8.85959C2.26296 9.45285 2.0594 9.83273 1.56658 10.3251C1.22553 10.6662 0.953676 10.9353 0.867968 11.0201C0.849219 11.0389 0.855469 11.0483 0.886717 11.0483H6.19616C9.11158 11.0483 11.1431 8.45158 11.1431 6.03836C11.1431 3.62469 9.53611 2.32835 9.53611 2.32835L9.53521 2.3288C8.67858 1.47796 7.49875 0.95166 6.19616 0.95166Z"
        fill={fillColor}
        id={preferenceId}
      />
    </svg>
  );
};
