import React, { useEffect } from "react";
import cx from "classnames";
import UTILITIESHELPER from "../../helpers/UtilitiesHelper";
import LinkHelpers from "../../helpers/LinkHelpers";
import ShowIf from "../Common/ShowIf";
import { usePageContext, usePageContextLabels,usePageContextSettings } from "../../contexts/PageContext";
import { useTocContext } from "../../contexts/TocContext";
import { useGuidebookTocContext } from "../../contexts/GuidebookTocContext";
import { generateMiniCrumbtrailText } from "../Breadcrumb/Crumbtrail";
import { useUserPreferencesContext, ACTIONS } from "../../contexts/AnnotationsContext";
import { useReadingModeContext } from "../../contexts/ReadingModeContext";
import CategoryService from "../../helpers/CategoryServices";
import { useLocation } from "react-router-dom";
import { FOLIO_SECTION_TYPE } from "../Constants/Constants";

const Toolbar = (props) => {
	const {
		setShouldShowShareModal,
		setShouldShowOverlay,
		setIsSectionPath,
		setClosedToolbar,
		closedToolbar,
		isPdf,
		isDupAnnotation,
		isSearchActive
	} = props;

	const { context, language, getMemberFirmID } = usePageContext();
	const { getLabel } = usePageContextLabels();
	const { toc, selectedItemTOC } = useTocContext();
	const { guidebookToc } = useGuidebookTocContext();
	const { isAnnotationsEnabled, isRestrictedAccessMemberfirm } = usePageContextSettings();
	const { dispatch } = useUserPreferencesContext();
	const { isReadingMode } = useReadingModeContext();
	const location = useLocation();

	useEffect(() => {
		if (isAnnotationsEnabled() && !isRestrictedAccessMemberfirm()) {
		(async () => { //fetchCtegories
			var categories = await CategoryService.fetchCategories(location.pathname, getMemberFirmID());
			dispatch({
				type: ACTIONS.CATEGORY_LOAD,
				payload: categories,
			});
		})();
		}

	}, []);//eslint-disable-line

	function closeToolbar() {
		setClosedToolbar(true);
	}

	function selectionCopy(e) {

		const selectedText = document.getSelection();
		const modalContainer = document.querySelector(".modal");
		var range;
		if (selectedText.toString().trim().length > 0) {

			if (selectedText.rangeCount > 0) {
				range = selectedText.getRangeAt(0);
				
				const div = document.createElement("div");
				const stylingElem = document.createElement("div");
				stylingElem.setAttribute('id', 'word-div-to-remove');
				stylingElem.style.fontFamily = "Roboto,Verdana,sans-serif";
				const urlPara = document.createElement("p");
				urlPara.style.visibility = "visible";
				const urlLink = document.createElement("a");
				urlLink.style.visibility = "visible";
				const datePara = document.createElement("p");
				datePara.style.visibility = "visible";
				let date = new Date();
				const dateValue = UTILITIESHELPER.formatDate(date, language);
				let pubTitle = "";
				const outerParentId = selectedText.baseNode.parentNode.offsetParent.id.toString();
				const sectionParentId = selectedText.baseNode.parentNode.id.toString();

				//if Folio, show the publication display title.
				//If Folio, and display_title is null, show the publication title.
				//Else, show the publication title.
				if (context.pageControllerType === "folio") {
					const currentArticle = document.querySelector(".copy-btn");
					pubTitle = currentArticle.dataset.title;
					if (pubTitle.length < 1) {
						pubTitle = document.querySelector("p.folio-title")
							?.textContent;
					}
				}
				else {
					pubTitle = document.querySelector("a.sidebar-toc-title-link")
						.textContent;
				}

				//REPLACE THIS
				urlLink.innerHTML = generateMiniCrumbtrailText(context, toc, guidebookToc, selectedItemTOC);

				if (sectionParentId.length > 0) {
					urlLink.href = LinkHelpers.sanitizeUrl(
						context.pageRequestURL + "#" + sectionParentId
					);
				}
				else if (sectionParentId.length <= 0 && outerParentId.length > 0) {
					urlLink.href = LinkHelpers.sanitizeUrl(
						context.pageRequestURL + "#" + outerParentId
					);
				}
				else {
					urlLink.href = LinkHelpers.sanitizeUrl(context.pageRequestURL);
				}

				urlPara.append(urlLink);
				datePara.append(`${getLabel("a_CopiedOn", "Copied on:")} ${dateValue}`);
				stylingElem.appendChild(urlPara);
				stylingElem.appendChild(datePara);
				div.appendChild(stylingElem);

				var expandedSelRange;
				if (selectedText) {
					expandedSelRange = range.cloneRange();
					range.collapse(false);
					var frag = document.createDocumentFragment(), node, lastNode;
					while ((node = div.firstChild)) {
						lastNode = frag.appendChild(node);
					}
					range.insertNode(frag);

					// Preserve the selection
					if (lastNode) {
						expandedSelRange.setEndAfter(lastNode);
						selectedText.removeAllRanges();
						selectedText.addRange(expandedSelRange);
					}
					document.execCommand('Copy', false, null);

					setTimeout(() => {
						let element = document.getElementById('word-div-to-remove');
						if (element) {
							element.remove();
						}
					}, 100)
				}
				setClosedToolbar(true);
				modalContainer.classList.remove("hide");
			}
		}
		else {
			closeToolbar();
		}
	}

	function selectionShare(e) {
		const selectedText = document.getSelection();
		if (selectedText.toString().trim().length > 0) {
			setShouldShowShareModal(true);
			setShouldShowOverlay(true);
			setIsSectionPath(true);
		}
		else {
			closeToolbar();
		}
	}

	function selectionAnnotation() {
		const selectedText = document.getSelection();
		if (selectedText.toString().trim().length > 0) {
			if (selectedText.rangeCount > 0) {
				const range = selectedText.getRangeAt(0);
				const cloned = range.cloneContents();
				var preSelectionRange = range.cloneRange();
				const tmpEl = document.createElement("div");
				tmpEl.append(cloned);
				const rawContent = tmpEl.innerHTML;
				const content = selectedText.toString();
				const selectedTextLength = document.getSelection().toString().length;
				let closestGuid = selectedText.baseNode.parentElement.closest('[id^="GUID"]')?.id;

				if (closestGuid === undefined || closestGuid === null) {
					closestGuid = tmpEl.querySelector('[id^="GUID"]')?.id;
				}

				dispatch({
					type: ACTIONS.MODAL_SHOW,
					payload: {
						rawContent,
						content,
						range,
						preSelectionRange,
						selectedTextLength,
						closestGuid
					},
				});
				closeToolbar();
			}
		}
		else {
			closeToolbar();
		}
	}

	return (
		<div className={cx("toolbar", { hide: closedToolbar })}>
			<ShowIf condition={isAnnotationsEnabled() && !isRestrictedAccessMemberfirm() }>
				<button
					className={cx("annotation-btn", { "annotation-btn-greyout": isPdf||context?.pageControllerType == "standards" ||context?.pageSelectedSection == FOLIO_SECTION_TYPE.templates ||isDupAnnotation || isSearchActive ||isReadingMode})}
					disabled={context?.pageControllerType === "standards"  ||isPdf||isDupAnnotation || isSearchActive ||isReadingMode || context?.pageSelectedSection == FOLIO_SECTION_TYPE.templates  ? true : false}
					onClick={selectionAnnotation}
					title={isDupAnnotation ? getLabel("hh_AnnotateDupMessage", "Annotation not available. Some or all of the content in your selection has already been annotated."):
						   isSearchActive ? getLabel("hh_AnnotateSearchActiveMessage", "Looks like you are actively searching. Annotation feature is not available during search."):
						   isReadingMode ? getLabel("hh_AnnotateReadingModemessage", "Annotation feature not available in reading mode. Switch to regular mode to annotate."):
						   context?.pageControllerType === "standards" ? getLabel("hh_AnnotateMessage", "Comments and Highlights cannot be added to this content at this time. Refer to FAQs for more details."):
						   isPdf || context?.pageSelectedSection == FOLIO_SECTION_TYPE.templates ? getLabel("hh_AnnotatePdfMessage", "Comment and highlights cannot be added to this type of content.")
						   :getLabel("hh_Annotate", "Annotate")}				
				>
					&nbsp;
				</button>
			</ShowIf>
			<button
				data-testid="copy-btn"
				className="copy-btn"
				onClick={selectionCopy}
				data-title={props.docsPubDisplayTitle}
				title={getLabel("a_Copy", "Copy")}
			>
				&nbsp;
			</button>
			<button
				data-testid="share-btn"
				className="share-btn"
				onClick={selectionShare}
				title={getLabel("share_Share", "Share")}
			>
				&nbsp;
			</button>
			<button className="toolbar-close" onClick={closeToolbar}></button>
		</div>
	);
}

export default Toolbar;
