import React, { useEffect, useState } from 'react';
import Dropdown from './Dropdown';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import CalendarIcon from './CalendarIcon';
import cx from 'classnames';
import { usePageContextLabels, usePageContext } from '../../contexts/PageContext';
import UTILITIESHELPER from "../../helpers/UtilitiesHelper";


const DateSelector = ({ onChange, reset }) => {
    const [selectedTypeDate, setSelectedTypeDate] = useState("");
    const [singleDate, setSingleDate] = useState()
    const [rangeDate, setRangeDate] = useState([])
    const [displayText, setDisplayText] = useState()
    const { getLabel } = usePageContextLabels();
    const { language, context } = usePageContext();
    const today = new Date();
    const minDate = new Date(2022, 0, 1)
    var dateSelection;
    let closeMessage;
    if (context.pageType === 'annotations' ) {
        closeMessage=getLabel("hh_AnnotationsDatePicker","See annotations dated:")
     } 
     else if (context.pageType === 'bookmarks' )  {
     closeMessage= getLabel("hh_bookmarkDated","bookmark dated:");
     } 
    
    const handleDateSelection = (selectedType, e) => {
        if (selectedType === "single") {
            setSingleDate(e)
            dateSelection = [e, e]
        } else {
            setRangeDate(e)
            dateSelection = e
        }
        onChange(dateSelection)
    }

    const handleSelectedType = (selectedType) => {
        setSelectedTypeDate(selectedType)
        if(selectedType === "single" && singleDate)
            dateSelection = [singleDate, singleDate]
        else if(selectedType === "range" && rangeDate.length>0)
            dateSelection = rangeDate
        else
            dateSelection = undefined
        
        if(dateSelection){
            onChange(dateSelection)
        }
        else{
            onChange()
        }
    }

    function DateTrigger() {
        return (
            <div className='an-dd-category-trigger-dropdown an-dd-category-trigger-dropdown-date'>
                <div className={cx("an-dd-dashboard-category-label-dropdown", { "an-dd-dashboard-filter-active": displayText })}><strong>
                    {
                        displayText ? displayText : getLabel('hh_AnnotationsDate','Date')
                    }
                </strong></div>
            </div>
        )
    }

    function getTriggerEl() {
        return <DateTrigger />;
    }

    useEffect(() => {
        if(selectedTypeDate === "single" && singleDate)
            setDisplayText(UTILITIESHELPER.formatDate(singleDate, language, 'short'))
        else if(selectedTypeDate === "range" && rangeDate.length>1)
            setDisplayText(UTILITIESHELPER.formatDate(rangeDate[0], language, 'short') + " to " + UTILITIESHELPER.formatDate(rangeDate[1], language, 'short'))
        else
            setDisplayText(undefined)
      }, [singleDate, rangeDate, selectedTypeDate]);//eslint-disable-line

    useEffect(() => {
        dateSelection = undefined;//eslint-disable-line
        setSelectedTypeDate("")
        setSingleDate()
        setRangeDate([])
        setDisplayText(undefined)
        onChange()
      }, [reset]); 

    return (
        <Dropdown
            triggerEl={getTriggerEl()}
            ddClassNames="an-dd-date-dropdown"
            containerClassNames="an-dd-container-date-dropdown">
            <Dropdown.Panel>
                <div className="date-picker">
                    <div className="date-picker-single-date date-picker-padding">
                        <div className="checkmarks-container-row checkmarks-container-row-date-selector" onClick={() => handleSelectedType("single")} tabIndex={0}>
                            <input id="date-single" type="radio" checked={selectedTypeDate === "single"} readOnly={true} tabIndex={1} /> <span className="checkmark-date-selector" tabIndex={1}></span>
                            <label htmlFor="date-single" className="radio-date-selector">{closeMessage}</label>
                        </div>
                        <div className="date-picker-calendar">
                            <div className="date-picker-calendar calendar-margin">
                                <DatePicker calendarIcon={<CalendarIcon />} onChange={(e) => handleDateSelection('single', e)}
                                    dateFormat="MM-DD-YYYY" dayPlaceholder='DD' monthPlaceholder='MM' yearPlaceholder='YYYY'
                                    value={singleDate === undefined ? null : singleDate}
                                    maxDate={today} minDate={minDate}
                                    clearIcon={false} onCalendarOpen={() => handleSelectedType("single")} />
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="date-picker-range-date date-picker-padding">
                        <div className="checkmarks-container-row checkmarks-container-row-date-selector-range" onClick={() => handleSelectedType("range")} tabIndex={0}>
                            <input id="date-range" type="radio" checked={selectedTypeDate === "range"} readOnly={true} tabIndex={1} /> <span className="checkmark-date-selector" tabIndex={1}></span>
                            <label htmlFor="date-range" className="radio-date-selector"></label>
                        </div>
                        <div>
                            <div className="date-picker-start-date">
                                <p>Range:</p>
                                <div className="range-date-picker-calendar">
                                    <div className={cx({
                                        'range-calender': true, 'range-calender-padding': rangeDate.length !== 0 ,
                                    })} >
                                        <DateRangePicker calendarIcon={<CalendarIcon />} onChange={(e) => handleDateSelection('range', e)}
                                            dateFormat="MM-DD-YYYY" value={rangeDate.length === 0 ? null : rangeDate} dayPlaceholder='DD' monthPlaceholder='MM' yearPlaceholder='YYYY'
                                            maxDate={today} minDate={minDate}
                                            clearIcon={false} onCalendarOpen={() => handleSelectedType("range")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dropdown.Panel>
        </Dropdown>

    )
}

DateSelector.defaultProps = {
    onChange: () => { },
    classNameCheckmark: "checkmark",
    classNameCheckboxContainer: "checkbox-container"
}


//className={cx(classNameCheckmark, { 'disabled': disabled })}
export default DateSelector