import React, { useState } from 'react';
import ShowIf from "../Common/ShowIf";
import { usePageContextLabels } from "../../contexts/PageContext";
import { useThemeContext } from "../../contexts/ThemeContext";
import { MEMBERFIRM_CONSTANTS, RESEARCH_ASSISTANT } from '../Constants/Constants';

export default function ResearchAssistantNotableExclusions({ isResearchAssistantLoading, researchAssistantResponse }) {
    const [isExpand, setIsExpand] = useState(false)
    const { getLabel } = usePageContextLabels();
    const { isDarkMode } = useThemeContext();


    return (
        <>
            <ShowIf condition={!isResearchAssistantLoading && researchAssistantResponse !== null}>
                <div className='form-title' onClick={() => setIsExpand(!isExpand)}>
                    <img src={isDarkMode ? '/images/white-down-arrow.svg' : '/images/gray-down-arrow.svg'} alt="down-arrow" className={`up-down-arrow ${!isExpand ? 'hidden-icon' : ''}`} />
                    <img src={isDarkMode ? '/images/white-right-arrow.svg' : '/images/gray-right-arrow.svg'} alt='right-arrow' className={`right-arrow ${isExpand ? 'hidden-icon' : ''}`} />
                    <h3 className="research-assistant-forms-heading">
                        {getLabel('ra_NotableExclusions', 'Content Excluded from Response')}
                    </h3>
                </div>
            </ShowIf>
            {(isExpand && !isResearchAssistantLoading && researchAssistantResponse !== null &&
                <div className="notable-conditional-string">
                    <ul className="conditional-strings">
                        <li>{getLabel("ra_ProfessionalStandardContentExclusion", RESEARCH_ASSISTANT.contentExclustionProfessionalStandard)}</li>
                        <li>{getLabel("ra_DeloitteCommunicationContentExclusion", RESEARCH_ASSISTANT.contentExclustionDeloitteCommunication)}</li>
                        {researchAssistantResponse.memberFirm === MEMBERFIRM_CONSTANTS.us && <li>{getLabel("ra_ConditionalStringUS", RESEARCH_ASSISTANT.conditionalStringUS)}</li>}
                    </ul>
                </div>
            )}
        </>
    )
}
