import React, { useState } from 'react';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import LinkHelpers from '../../helpers/LinkHelpers';
import EmbeddedArticleBlock from './EmbeddedArticleBlock';
import { usePageContextLabels } from '../../contexts/PageContext';


export const EmbeddedArticle = (props) => {

    const [guideOpen, toggleGuidance] = useState(UTILITIESHELPER.sectionExpandDisplayStatus());
    const data = props.data;
    const { getLabel } = usePageContextLabels();

    if (!data.content) { return null; }

    // XSS url sanitization
    const sanitizedUrl = LinkHelpers.sanitizeUrl(window.location);

    return (
        <div className={guideOpen ? "expandable-article expandable-article-panel open" : "expandable-article expandable-article-panel"}>
            <button onClick={() => toggleGuidance((prevVal) => !prevVal)}><h2 className="jumplink-5">{getLabel("e_EmbeddedGuidanceFromResearchPortal", "Guidance from Omnia Research Portal")}</h2></button>
            <div className="expandable-article-body">
                {data.content.map((content) => {
                    return (
                        <EmbeddedArticleBlock content={ content } />
                    )
                })}

                <p className="more-info"><a href={sanitizedUrl} target="_blank" rel="noopener noreferrer">{getLabel("e_EmbeddedOpenThisOnTheResearchPortal", "Open this on the Omnia Research Portal")}</a></p>
            </div>
        </div>
    )
}

export default EmbeddedArticle;