import React from 'react';

const CalendarIcon = () => {
    return (
        <div>
            <img src="/images/icon-calendar-picker.svg" className="calendarIcon" alt="calendar" />
        </div>
    )
}

export default CalendarIcon