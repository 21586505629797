import React, { useEffect, useState } from 'react'
import { RESEARCH_ASSISTANT_SOURCE_TYPES } from '../Constants/Constants'
import DocumentSource from './DocumentSource'
import FormsAndTemplatesSource from './FormsAndTemplatesSource'

function ResolveSources({sourceType, ...props}) {

  switch (sourceType) {
    case RESEARCH_ASSISTANT_SOURCE_TYPES.DOCUMENT:
      return (
        <DocumentSource {...props}/>
      )

    case RESEARCH_ASSISTANT_SOURCE_TYPES.FORMS_AND_TEMPLATES:
      return (
        <FormsAndTemplatesSource {...props}/>
      )

    default:
      return null
  }
}

export default ResolveSources
