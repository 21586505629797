//component-processing functions

import UTILITIESHELPER from '../helpers/UtilitiesHelper';
import { cloneDeep } from "lodash";
import { logs, page } from '../helpers/log.js';
import { PAGE_CONTROLLER_TYPE, PUBLICATIONTYPE_IDS } from '../components/Constants/Constants';
import RETRIEVALSERVICES from '../services/rp-service';
import { ErrorCodes, ErrorMessages } from '../components/Constants/Errors';

const RESERVED_FOOTNOTE_CALLOUT_VALUES = [
    null,
    undefined,
    'xref',
    'ordered list',
    'unordered list'
]


function extractAnnotationOrAssociatedContent(item, publication) {
    var relationArray = [];

    //Loop through the relationship tables, see if any of the guids match this item's guid.
    var i;
    if (publication.publicationContent !== undefined && publication.publicationContent.relations !== undefined) {
        for (i = 0; i < publication.publicationContent.relations.length; i++) {
            var currentRelation = publication.publicationContent.relations[i];
            var j;
            for (j = 0; j < currentRelation.content.table.rows.length; j++) {
                var currentRow = currentRelation.content.table.rows[j];

                var k;
                for (k = 0; k < currentRow.columns[0].values.length; k++) {
                    if (currentRow.columns[0].values[k] === item.id) {
                        //We have an annotation or a related link to generate.
                        //Look at the publication's resources, find the one with the matching guid from the table.

                        //logs.debug(page.Article, 'ArticleHelper', "Annotation caught!", currentRow.columns[0].values[0], props.item.id);
                        var relatedId = currentRow.columns[1].values[k];
                        var resources = publication.publicationContent.resources;

                        extractRelationFromResources(relatedId, resources, relationArray);
                    }
                }
            }
        }
    }
    return relationArray;
}

function extractRelationFromResources(targetId, resources, relationArray) {
    var l;
    for (l = 0; l < resources.length; l++) {
        if (resources[l].id === targetId) {
            //logs.debug(page.Article, 'ArticleHelper', "Test", resources[k].id, relatedId);
            relationArray.push(resources[l]);
            break;
        }
        else if (resources[l].subItems.length > 0) {
            extractRelationFromResources(targetId, resources[l].subItems, relationArray);
        }
    }
}

// #region Footnote Extraction Helpers

/**
 * Generator function that counts up from 1
 * @returns Generator object
 */
function* footnoteNumberGenerator() {
    let iterationCount = 0;
    for (let i = 1; i < Infinity; i++) {
        iterationCount++;
        yield i;
    }
    return iterationCount++;
}

/**
 * Test if a value is a reserved Footnote Callout value
 * @param {String|nul} value 
 * @returns Boolean
 */
function isReservedFootnoteCalloutValue(value) {
    return RESERVED_FOOTNOTE_CALLOUT_VALUES.indexOf(value) > -1;
}

/**
 * Creates markup for a footnote link in content
 * @param {String} key 
 * @param {String} label 
 * @returns String HTML String replesenting an inline footnote
 */
function createMarkupFootnote(key, label) {
    return `<sup class="footnote footnote-click" id="footnote-top-${key}">
        <a class="footnote-link" data-footnote-num="${key}" href="#footnote-${key}" >${label}</a>
        <div class="footnote-popup"> </div>
    </sup>`;
}

/**
 * Gets the next numeric value key
 * 
 * Being the that an author can add their own numeric callout values to a 
 * footnote, we need to ensure that we skip those values when generating 
 * numeric values for footnotes with no callout
 * 
 * @param {Generator} generator numeric generator
 * @param {Array} reservedValues List of values to avoid
 * @returns 
 */
function getNextValueKey(generator, reservedValues) {
    let key;
    let valid = false;
    do {
        key = generator.next().value;
        valid = reservedValues.indexOf(key.toString()) === -1;
    } while (!valid);
    return key;
}

function getBreadcrumbData() {
    const elBreadcrumbdata = document.querySelectorAll(".breadcrumb");
    const arrBreadCrumb = [];
    if (elBreadcrumbdata !== null) {
        elBreadcrumbdata.forEach((el) => { arrBreadCrumb.push(el.innerHTML) })
    }
    return arrBreadCrumb.join(' / ')
}

/**
 * Encodes input as a underscode sperated charCode string (ex: 51_53)
 * This will handle most locales
 * @param {String|Int|Number} input 
 * @returns String
 */
function encodeKeyString(input) {
    return input
        .toString()
        .split('')
        .map(x => x.charCodeAt(0))
        .join('_');
}

/**
 * @param {String} content 
 * @returns HTMLDocument
 */
function getContentHtmlDocument(content) {
    var safeContent = "<div class='article-title-div'>" + content + "</div>";
    var parser = new DOMParser();
    return parser.parseFromString(safeContent, "text/html");
}

/**
 * Parse content for footnotes and retreive callout attr, or null if non-existent 
 * @param {String} content 
 * @returns Array An array of callouts
 */
function parseFootnoteCallouts(content) {
    var htmlDoc = getContentHtmlDocument(content);
    return Array.from(htmlDoc.getElementsByTagName("fn")).map(el => el.getAttribute('callout'));
}

/**
 * Extracts author defined footnote callouts from rich text title
 * @param {Object} _item current content item
 * @param {Array} calloutArray Array for callouts extractedfrom footnotes
 * @returns Array<String> containing foonnote callout values
 */
function extractFootnoteRTTCallouts(_item, calloutArray) {
    const item = cloneDeep(_item);

    // parse current item callouts   
    calloutArray = [...calloutArray, ...parseFootnoteCallouts(item)];

    // filter out reserved callout values 
    const filteredCalloutArray = calloutArray.filter(x => !isReservedFootnoteCalloutValue(x));

    return filteredCalloutArray;
}

/**
 * Recursively extracts author defined footnote callouts
 * @param {Object} _item current content item
 * @param {Array} calloutArray Array for callouts extractedfrom footnotes
 * @returns Array<String> containing foonnote callout values
 */
function extractFootnoteCallouts(_item, calloutArray) {
    const item = cloneDeep(_item);

    //parse the title first
    if (item.content && item.content?.rich_text_title && UTILITIESHELPER.IsTextHavingFnTag(item.content?.rich_text_title)) {
        calloutArray = [...calloutArray, ...parseFootnoteCallouts(item.content?.rich_text_title)];
    }

    // parse current item callouts
    if (item.content && item.content.text) {
        calloutArray = [...calloutArray, ...parseFootnoteCallouts(item.content.text)];
    }

    // parse child items callouts
    if (item.subItems) {
        item.subItems.forEach(subItem => {
            calloutArray = extractFootnoteCallouts(subItem, calloutArray);
        })
    }

    // filter out reserved callout values 
    const filteredCalloutArray = calloutArray.filter(x => !isReservedFootnoteCalloutValue(x));

    return filteredCalloutArray;
}

// #endregion


function updateFootnoteContent(htmlContent, footnoteArray, calloutArray, numberGenerator, ignoreDiv = false) {
    if(ignoreDiv){
        let parser = new DOMParser();
          var xmlDoc = parser.parseFromString(htmlContent, "text/html");
    } else {
          xmlDoc = getContentHtmlDocument(htmlContent)
    }

    // retreive all fn elements
    var fnElements = Array.from(xmlDoc.getElementsByTagName('fn'));

    // filter out undefined fn elements and then process each fn element
    fnElements.filter(fn => fn !== undefined)
        .forEach(fn => {
            // Footnote label (superscript)
            // retrieve authored label
            let label = fn.getAttribute('callout')

            // if authored label is not a reserved value
            label = !isReservedFootnoteCalloutValue(label)
                // use the authored label
                ? label
                // else use the next valid numeric value key
                : getNextValueKey(numberGenerator, calloutArray);

            let labelForEncoding = label;
            let footnoteArrayElementCount = footnoteArray.filter(e => e.label === label).length;
            if (footnoteArrayElementCount > 0) {
                labelForEncoding = labelForEncoding + ' ' + footnoteArrayElementCount;
            }
            
            // Footnote key for linking
            const key = encodeKeyString(labelForEncoding);
            const idOfRTT = fn.children[0] ? !UTILITIESHELPER.isStringNullorEmpty(fn.children[0].id) ? fn.children[0].id :fn.id : fn.id;
            const existingFootnote = footnoteArray.find(obj=>obj.idOfRTT===idOfRTT);
            if(!existingFootnote || UTILITIESHELPER.isStringNullorEmpty(idOfRTT)){
            // Footnote text (content)
            // remove the <cite> xml tags, if they exist.
            const text = fn.innerHTML.replace(/<cite>|<\/cite>/gi, "");
            var footnote = { text, number: key, label, idOfRTT };
            // Create Footnote object and push to footnoteArray
            footnoteArray.push(footnote);
            }
            
            // Replace fn element with footnote markup
            fn.outerHTML = createMarkupFootnote(key, label);
        });
    return xmlDoc.body.innerHTML;
}
/**
 * Recursively extract footnotes from content
 * @param {Object} _item Item to parse
 * @param {Array} footnoteArray Array of footnote objects
 * @param {Array} calloutArray Array of authored callouts
 * @param {Generator} numberGenerator 
 * @returns Array An array containing footnotes [0] and the processed item [1]
 */
function extractFootnotes(_item, footnoteArray, calloutArray, numberGenerator) {
    let item = cloneDeep(_item);

    // item has content & content text, extractfootnotes
    if (item.content && item.content.text) {
        item.content.text = updateFootnoteContent(item.content.text, footnoteArray, calloutArray, numberGenerator);
    }

    //parse rich text in title
    if (item.content?.rich_text_title && UTILITIESHELPER.IsTextHavingFnTag(item.content?.rich_text_title)) {
        // update item.content.rich_text_title with processed xml serialized to a string
        item.content.rich_text_title = updateFootnoteContent(item.content.rich_text_title, footnoteArray, calloutArray, numberGenerator, true);
    }

    // if item has subItems, recursively extract footnotes for each item
    if (item.subItems) {
        item.subItems.forEach((subItem, idx, sourceArr) => {
            const [
                extractedFootnotes,
                processedItem
            ] = extractFootnotes(subItem, footnoteArray, calloutArray, numberGenerator);
            footnoteArray = extractedFootnotes.flat();
            sourceArr[idx] = processedItem;
        });
    }

    return ([footnoteArray, item]);
}

//process and create super script using rich text title
function createTitleMarkupFootnote(_item, numberGenerator) {
    let item = cloneDeep(_item);
    let calloutArray = [];
    let titleFnArray = [];
    calloutArray = extractFootnoteRTTCallouts(item, calloutArray);
    // get content as html
    var htmlDoc = getContentHtmlDocument(item)

    // retreive all fn elements
    var fnElements = Array.from(htmlDoc.getElementsByTagName('fn'));

    // filter out undefined fn elements and then process each fn element
    fnElements.filter(fn => fn !== undefined)
        .forEach(fn => {
            // Footnote label (superscript)
            // retrieve authored label
            let label = fn.getAttribute('callout')
            // if authored label is not a reserved value
            let isreserved = isReservedFootnoteCalloutValue(label);
            let nextVal = getNextValueKey(numberGenerator, calloutArray);
            label = !isreserved
                // use the authored label
                ? label
                // else use the next valid numeric value key
                : nextVal

            // Footnote key for linking
            const key = encodeKeyString(label);

            titleFnArray.push(createMarkupFootnote(key, label));
        });

    let regex = new RegExp("(<fn.*>)(.*)(<\/fn>)", "g"); // eslint-disable-line
    let num = 0;
    item = UTILITIESHELPER.removeLargeSpacesFromRichText(item);
    let ArticleTitle = item.replace(regex, titleFnArray[num]);
    return ArticleTitle;
}

function extractFootnotesFromRTT(_item, numberGenerator) {
    let item = cloneDeep(_item);
    let calloutArray = [];
    let footnoteArray = [];
    calloutArray = extractFootnoteRTTCallouts(item, calloutArray);
    // get content as html
    var htmlDoc = getContentHtmlDocument(item)

    // retreive all fn elements
    var fnElements = Array.from(htmlDoc.getElementsByTagName('fn'));

    // filter out undefined fn elements and then process each fn element
    fnElements.filter(fn => fn !== undefined)
        .forEach(fn => {
            // Footnote label (superscript)
            // retrieve authored label
            let label = fn.getAttribute('callout')
            // if authored label is not a reserved value
            let isreserved = isReservedFootnoteCalloutValue(label);
            label = !isreserved
                // use the authored label
                ? label
                // else use the next valid numeric value key
                : getNextValueKey(numberGenerator, calloutArray);

            // Footnote key for linking
            const key = encodeKeyString(label);

            // Footnote text (content)
            // remove the <cite> xml tags, if they exist.
            const text = fn.innerHTML.replace(/<cite>|<\/cite>/gi, "");
            const idOfRTT = fn.children[0] ? fn.children[0].id : fn.id;

            // Create Footnote object and push to footnoteArray
            var footnote = { text, number: key, label, idOfRTT };
            footnoteArray.push(footnote);
        });

    return footnoteArray;
}

function resolveImages(text) {
    //convert the text into a document so that it can be parsed.
    //function stringToHTML(str) {
    //    var parser = new DOMParser();
    //    var doc = parser.parseFromString(str, 'text/html');
    //    return doc.body;
    //};

    //var doc = stringToHTML(text);
    //const promises = []
    ////for each image tag in the text, fetch the image and get it back as a base64 image.
    //let images = doc.getElementsByTagName("img");
    //for (var img of images) {
    //    // If the img src includes 'blob:https:' the original image has already been replaced
    //    if (img.src.includes('blob:https:')) return doc.innerHTML
    //    const imgPromise = RETRIEVALSERVICES.retrieveBlobAsUrl(img.src)
    //    promises.push(imgPromise)
    //}
    //const resolvesPromises = await Promise.all(promises)
    //resolvesPromises.forEach((url, i) => {
    //    images[i].src = url
    //})
    //return doc.innerHTML;
    return text;
}

function createMarkupProcessFlow(contentItem, first) {
    var content = "";

    if (contentItem.content.objective) {
        content = "<p class=\"title\"><strong>" + contentItem.content.objective.title + "</strong></p>";
        content += contentItem.content.objective.text;
    }

    if (contentItem.content.why) {
        content += "<p class=\"title\"><strong>" + contentItem.content.why.title + "</strong></p>";
        content += contentItem.content.why.text;
    }

    if (contentItem.content.text) {
        content += contentItem.content.text;
    }

    return resolveImages(content);
}

function createMarkupQuestionAndAnswer(contentItem) {
    var content = "<p class=\"title\"><strong>" + contentItem.content.question.title + "</strong></p>";
    content += contentItem.content.question.text;
    content += "<p class=\"title\"><strong>" + contentItem.content.answer[0].title + "</strong></p>";

    contentItem.content.answer.map((answer) =>
        content += answer.text
    );

    return resolveImages(content);
}

function createMarkup(item) {
    if (item.content_type === "17377912") {
        //logs.debug(page.Article, 'ArticleHelper', "ArticleHelper::createMarkup:Parent Binary Item:",item);
        return null;
    }
    else if ((item.content_type === '60194857') || (item.content_type === '48693746')) {
        if (item.containsBinary) {
            var htmlContent = "";
            if (item.content.format === ".pdf") {
                htmlContent += "<embed src=\"" + item.binaryLocation + "\" />";
            }

            return htmlContent;
        }
        else {
            return item.content.text;
        }
    }
    else if (item.content_type === "60247324") {
        //const __html = await resolveImages(item.content.pub_additional_text);
        const html = resolveImages(item.content.pub_additional_text);
        return html;
    }
    else if (item.content_type === "63781319") { //Objective & Why
        //return { __html: await createMarkupProcessFlow(item, true) };
        return createMarkupProcessFlow(item, true);

    }
    else if (item.content_type === "60194856") { //Question & Answer
        //return { __html: await createMarkupQuestionAndAnswer(item) };
        return createMarkupQuestionAndAnswer(item);
    }

    else {
        //Handle subtopics if any exist.

        //function to handle sub-subtopics, if any exist, called recursively.
        function extractSubSubTopics(subtopic) {
            if (!item.content.text.includes(subtopic.title)) {
                item.content.text += ("<b>" + subtopic.title + "</b><br />");
                item.content.text += subtopic.text;
            }

            //handle deeper layers of subtopics.
            if (subtopic.sub_topics && subtopic.sub_topics.length > 0) {
                var i;
                for (i = 0; i < subtopic.sub_topics.length; i++) {
                    extractSubSubTopics(subtopic.sub_topics[i]);
                }
            }
        }

        if (item.content?.sub_topics && item.content?.sub_topics?.length > 0) {
            var i;
            for (i = 0; i < item.content.sub_topics.length; i++) {
                //do not add to the text if we already had previously, as this createMarkup function is called multiple times.
                //todo: prevent createMarkup from being called multiple times per item if not needed.

                if (!item.content.text.includes(item.content.sub_topics[i].title)) {
                    item.content.text += ("<b>" + item.content.sub_topics[i].title + "</b><br />");
                    item.content.text += item.content.sub_topics[i].text;
                }

                if (item.content.sub_topics && item.content.sub_topics.length > 0) {
                    var elementID;
                    for (elementID = 0; elementID < item.content.sub_topics.length; elementID++) {
                        extractSubSubTopics(item.content.sub_topics[elementID]);
                    }
                }
            }
        }
        return { __html: item.content?.text };
    }
}

function findElementInArticleArea(hashTag) {
    let foundElementInArticleArea = null;
    const articleContainer = document.querySelector('.article-content-container');

    if (!articleContainer) {
        logs.debug(page.Article, 'ArticleHelper', "findElementInArticleArea:#0:We cannot find the article container on this page, so are not going to look for target element on page!");
        return foundElementInArticleArea;
    }

    try {
        foundElementInArticleArea = articleContainer.querySelector(hashTag);
        //logs.debug(page.Article, 'ArticleHelper', "findElementInArticleArea:#1:Did we find the element on the page with the hashtag of ", hashTag, foundElementInArticleArea)
    }
    catch {
        //logs.debug(page.Article, 'ArticleHelper', "Issue locating the element to scroll to with the hashtag of ", hashTag)
    }

    //If we did not find an exact match, we look for a "ending with" or a "partial match" thereafter.
    if (!foundElementInArticleArea) {
        let hashlessTag = hashTag.replace("#", "");

        foundElementInArticleArea = articleContainer.querySelector("[id$='" + hashlessTag + "']");
        //logs.debug(page.Article, 'ArticleHelper', "findElementInArticleArea:#2:Did we find the element on the page with the hashtag of ", "[id$='" + hashlessTag + "']", foundElementInArticleArea)

        //If we did not find an exact match,  "partial match" thereafter.
        if (!foundElementInArticleArea) {
            foundElementInArticleArea = articleContainer.querySelector("[id*='" + hashlessTag + "']");
            //logs.debug(page.Article, 'ArticleHelper', "findElementInArticleArea:#3:Did we find the element on the page with the hashtag of ", "[id*='" + hashlessTag + "']", foundElementInArticleArea)

            //If we did not find an exact match, and if this is a legacy link, then we may find it with uppercase...
            if (!foundElementInArticleArea && !hashlessTag.startsWith("GUID")) {
                foundElementInArticleArea = articleContainer.querySelector("[id*='" + hashlessTag.toLocaleUpperCase() + "']");
                //logs.debug(page.Article, 'ArticleHelper', "findElementInArticleArea:#4:Did we find the element on the page with the hashtag of ", "[id*='" + hashlessTag.toLocaleUpperCase() + "']", foundElementInArticleArea)

                //If we did not find an exact match, (inferred: and if this is a legacy link), then we may find it with lowercase...
                if (!foundElementInArticleArea) {
                    foundElementInArticleArea = articleContainer.querySelector("[id*='" + hashlessTag.toLocaleLowerCase() + "']");
                    //logs.debug(page.Article, 'ArticleHelper', "findElementInArticleArea:#5:Did we find the element on the page with the hashtag of ", "[id*='" + hashlessTag.toLocaleLowerCase() + "']", foundElementInArticleArea)
                }
            }
        }
    }
    return foundElementInArticleArea;
}

function scrollToHashTag(hashTag) {
    if (!hashTag) {
        return
    }
    hashTag = decodeURI(hashTag);
    let elemToScrollTo = null;
    const linkHashSeparator = "_|RP-HASH|_";

    //If we have a hash-seperator (two possible targets)
    if (hashTag.indexOf(linkHashSeparator) > 0) {
        const hashes = hashTag.split(linkHashSeparator);

        elemToScrollTo = findElementInArticleArea("#" + hashes[1]);

        if (!elemToScrollTo) {
            elemToScrollTo = findElementInArticleArea(hashes[0]);
        }
    }
    else {
        elemToScrollTo = findElementInArticleArea(hashTag);
    }
    
    //If we have not found it - then we cannot scroll
    if (!elemToScrollTo) {
        logs.debug(page.Article, 'ArticleHelper', "scrollToHashTag:element(s) cannot be found for target hashtag", hashTag)
        return;
    }

    //logs.debug(page.Article, 'ArticleHelper', "Prepping to scroll down to element: ", elemToScrollTo);
    
    const y = elemToScrollTo.getBoundingClientRect().top + window.scrollY;
    window.scroll({
        top: y - 130,
        behavior: 'smooth',
        block: 'start'
    });
}

function getpubIdsToQuery(pubData, flattenedToc, isSpinePage, context) {

    let pubIdsToQuery = [];

    if (pubData?.guideBook) {
        pubData.guideBook.Content.guidebookSections.forEach((section) => {
            //Excelude sections that we are searching inside of for this folio  !== "related"
            if (section.sectionContainsPublications && ("excludeSectionNames|related".indexOf(section.sectionFriendlyPath) === -1)) {
                const idsFromToc = flattenedToc
                    .filter(({ tocHash }) => tocHash.startsWith(section.sectionFriendlyPath))
                    .filter(({ url }) => /GUID/g.test(url))
                    .map(({ url }) => {
                        const matches = url.match(/GUID-[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/gi);
                        return matches && matches.length ? matches[0] : null;
                    })
                    .filter(x => x !== null);
                pubIdsToQuery = [...pubIdsToQuery, ...idsFromToc];
            }
        });
    }
    else if (isSpinePage) {
        //if publication is a manual spine pub, we need to send the spine publication ids
        pubIdsToQuery = flattenedToc
            .filter(({ tocHash }) => (tocHash.includes(context.pageSelectedPublicationGUID)))
            .map(x => x.tocHash.split('_').pop());

        //if not and the pubIdsToQuery is empty
        if (pubIdsToQuery.length === 0) {
            pubIdsToQuery = flattenedToc
                .filter(({ url }) => (url.match(/GUID/g) || []).length === 1)
                .map(x => "GUID-" + x.url.split('GUID-')[1]);
        }
    }
    else {
        //TODO: Use the tocContextProvidor and do this differently
        //if publication is a manual spine pub, we need to send the spine publication ids
        pubIdsToQuery = flattenedToc
            .filter(({ url }) => (url.match(/GUID/g) || []).length === 3)
            .map(x => "GUID-" + x.url.split('GUID-')[3]);

        if (pubIdsToQuery.length > 0) {
            //add in the pub Id to the toc items to make them easier to reference later when matching up the results to toc items
            //this should just be undefined or null for non-spine pubs.
            //TODO: Move this to the BE later.
            flattenedToc.forEach((pubItem) => {
                let guids = pubItem.url.split('GUID-');
                if (guids.length > 3) {
                    pubItem.spinePublicationId = "GUID-" + guids[3].split('/')[0];
                }
            })
            //We need to add in the GUID of the Spine pub that points to all the above Spine-Target-Pubs added above.
            pubIdsToQuery = [...pubIdsToQuery, context.pageSelectedPublicationGUID];
        }
        //if not and the pubIdsToQuery is empty
        else if (pubIdsToQuery.length === 0) {
            pubIdsToQuery = flattenedToc
                .filter(({ url }) => (url.match(/GUID/g) || []).length === 1)
                .map(x => "GUID-" + x.url.split('GUID-')[1]);
        }
    }

    if (!pubIdsToQuery.length) {
        logs.debug(page.Publication, 'PublicationSearch', 'Publication ID Array is empty. Awaiting required data', { memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'), knowledgeDomain: context.knowledgeDomain })
        return null;
    }

    // Get unique list of publication ids
    pubIdsToQuery = [...new Set(pubIdsToQuery)];
    let pubIds = 'publication_id/' + pubIdsToQuery.join('/');

    return pubIds;
}

function mapSearchResultsToSelectedToc(results, context, isJournalPage, flattenedToc, isSpinePage, getMFLabel, toc, getGuidebookSectionData, currentsearchPhrase) {
    results.forEach(result => {
        let tocObject = null;

        //NOTE: we would only have alerts if we were on the folio, but not sure if we only do this in a listing!?
        //  isFolioSectionlisting
        if ((context.pageSelectedSection === "alerts")) {
            // for Alerts --> its PublicationObjectId
            tocObject = flattenedToc?.find(({ id }) => result.document.publicationId.startsWith(id));
        }
        else if (isJournalPage) {
            tocObject = flattenedToc?.find(({ id }) => (result.document.publicationId.startsWith(id)));
        }
        else if(context.pageControllerType ==="standard")
        {
            tocObject =flattenedToc?.filter(({ id }) => (result.document.id.startsWith(id)));
        }
        // only for manual,standards
        else if (isSpinePage) {
            tocObject = flattenedToc?.find(({ id }) => { //eslint-disable-line
                if (result.document.publicationContextId) {
                    return result.document.publicationContextId.startsWith(id) || (result.document.id.startsWith(id));
                }
                else{
                    return (result.document.id.startsWith(id));
                }
            });
            //the else above does the same thign as this loop...
            /*if (UTILITIESHELPER.isNullOrUndefined(tocObject)) {
                tocObject = flattenedToc?.find(({ id }) => {//eslint-disable-line
                    if (result.document.id) {
                        return result.document.id.startsWith(id);
                    }
                });
            }*/
        }
        else {
            tocObject = flattenedToc?.find(({ id }) => (result.document.id.startsWith(id)));
            if (!tocObject) {
                tocObject = flattenedToc?.find(({ id }) => (result.document.publicationId.startsWith(id)));
            }
        }

        // ideally we can't even show a search results if it doesnt exist in our TOC
        if (!UTILITIESHELPER.isObjectNullorEmpty(tocObject)) {

            if(context.pageControllerType==="standard")
            {
                result.document.fullTitle = tocObject ? tocObject[0].title : result.document.publicationTitle;
                result.url = tocObject[0].url ? tocObject[0].url : getSearchresultUrl(flattenedToc,result);
                tocObject.forEach(ti=>{
                    result.tocHash=result.tocHash ? result.tocHash +'_'+ ti.tocHash : ti.tocHash;
                })
                result.document.navTitle = tocObject ? (tocObject[0].nav_title ? tocObject[0].title : tocObject[0].nav_title) : (result.document.navTitle ? result.document.publicationTitle : result.document.navTitle);
            }
            else{
                result.document.fullTitle = tocObject ? tocObject.title : result.document.publicationTitle;
                result.url = tocObject.url ? tocObject.url : getSearchresultUrl(flattenedToc,result);
                result.tocHash = tocObject ? tocObject.tocHash : '';
                result.document.navTitle = tocObject ? (tocObject.nav_title ? tocObject.title : tocObject.nav_title) : (result.document.navTitle ? result.document.publicationTitle : result.document.navTitle);
            }
  
            if(currentsearchPhrase)
                result.searchPhrase = currentsearchPhrase;

            const branchSegments = result.tocHash.split('_');
            branchSegments.pop(); // remove target page
            const branch = branchSegments
                .map(guid => {
                    return flattenedToc.find(x => x.id === guid);

                })
                .map((x, i) => x !== undefined ? x.nav_title : getTocSectionTitle(branchSegments[i], context, flattenedToc, getMFLabel, toc, getGuidebookSectionData));

            result.crumb = branch;

            return result;
        }
    });
    let extendedResults = results.filter(x => !UTILITIESHELPER.isStringNullorEmpty(x.url));
    return UTILITIESHELPER.isArrayNullorEmpty(extendedResults) ? null : extendedResults;
}

function getSearchresultUrl(flattenedToc, result){
   let tocObject = flattenedToc?.find(({ id }) => { //eslint-disable-line
        if (result.document.publicationObjectId) {
            return result.document.publicationObjectId.startsWith(id);
        }
    });

    let url = result.document.publicationId === result.document.id ? `${tocObject.url}` 
    : `${tocObject.url}/${encodeURIComponent(result.document.fullTitle)}/${result.document.id}`;
    return url;
}

function getTocSectionTitle(sectionFriendlyPath, context, flattenedToc, getMFLabel, toc, getGuidebookSectionData) {
    if (context && flattenedToc && getMFLabel && toc && getGuidebookSectionData) {
        try {
            if ("manual|standards".indexOf(context.pageControllerType) > -1) {
                // you are already on Section of Manuls/standards
                // TODO : is it necessary to get the Spine Title ??
                let tocobject = flattenedToc.find(({ id }) => sectionFriendlyPath.startsWith(id));
                if (tocobject) {
                    return getMFLabel(tocobject.title, "tocobject.title");
                }
                return toc.nav_title ? toc.nav_title : toc.title;
            }
            else if ("guidance".indexOf(context.pageControllerType) > -1 && "accounting".indexOf(context.knowledgeDomain) > -1) {
                
                let tocobject = flattenedToc.find(({ id }) => sectionFriendlyPath.startsWith(id));
                if (tocobject) {
                    return getMFLabel(tocobject.title, "tocobject.title");
                }
                return toc.title;
            }
            else {
                // this is for folio's since we should find the Pub name
                const section = getGuidebookSectionData(sectionFriendlyPath);
                if (section) {
                    return getMFLabel(section.title.Content.title, "section.title.Content.title");
                }
                return sectionFriendlyPath;
            }
        }
        catch (ex) {
          
            logs.error(page.Publication, 'ArticleHelper',ErrorMessages.tocSectionTitle , ex, { memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'), knowledgeDomain: context.knowledgeDomain,eventId:ErrorCodes.Publication })

            return sectionFriendlyPath;
        }
    }
}

function getHighlightedText(highlights) {
    var result = highlights.find(function (obj) {
      return obj.propertyName === "Content";
    });
    if (result) {
      return result.highlightedText;
    } else {
      return highlights[0]?.highlightedText;
    }
}

function getFilterPubData(pubData) {
    if (pubData) {
        //For external collections, we will display content related only to specific topic, without integrating the content from subitems.
        if (pubData.publication_type === PUBLICATIONTYPE_IDS.externalCollection) {
            if (!UTILITIESHELPER.isArrayNullorEmpty(pubData.content)) {
                pubData.content[0].subItems = [];
            }
        }
    }
    return pubData;
}

function isSpinePageController(pageController) {
    return [PAGE_CONTROLLER_TYPE.manual,
    PAGE_CONTROLLER_TYPE.standards].includes(pageController)
}


const ARTICLEHELPERS = {
    createMarkup,
    createTitleMarkupFootnote,
    extractAnnotationOrAssociatedContent,
    extractFootnotes,
    extractFootnoteCallouts,
    extractFootnotesFromRTT,
    findElementInArticleArea,
    footnoteNumberGenerator,
    getBreadcrumbData,
    getContentHtmlDocument,
    scrollToHashTag,
    getpubIdsToQuery,
    mapSearchResultsToSelectedToc,
    getHighlightedText,
    getFilterPubData,
    isSpinePageController
}

export default ARTICLEHELPERS;