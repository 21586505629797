import React from 'react';
// import { Link } from 'react-router-dom';

function Option({ label, isActive, onClick }) {
    function handleClick() {
        if (!isActive) onClick();
    }
    //mfdd__option
    return (
        <div
            className={'member-firm-option ' + (isActive ? 'active-member-firm-option' : '')}
            onClick={handleClick}
        >{label}</div>
    )
}

export default Option;