import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../Common/Dropdown';
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import ProfileHelpers from '../../helpers/ProfileHelpers';
import AddButton from '../Common/Layout/DropdownMemberFirm/AddButton';
import { MemberFirmItem } from "./MemberFirmItem";
import ManagePanel from "./ManagePanel";
import SH from '../../helpers/StorageHelpers';

function Trigger({ value }) {
    return (
        <button type="button" className="home-mf__trigger-button">
            <span className="home-mf__trigger-label">{value}</span>
        </button>
    )
}

function MemberFirmSelector({ memberFirms, onChange, collapsible = false, dropdownScrollAreaMaxHeight = null }) {
    const { getLabel } = usePageContextLabels();
    const { memberFirm } = usePageContext();
    const [manageActive, setManageActive] = useState(false);
    const [searchMemberFirm, setSearchMemberFirm] = useState('');
    const [profileMemberFirms, setProfileMemberFirms] = useState([]);
    const [memberFirmWithoutActive, setMemberFirmWithoutActive] = useState(memberFirms);
    const globalAuditMapping = new Map([
        ["17573177", "International"],
      ]);
    useEffect(() => {
        getAndFormatMemberFirms();
    }, [memberFirms, memberFirm]);//eslint-disable-line

    function getAndFormatMemberFirms() {
        // Get all member firms and filter out any 'null' values
        const _profileMemberFirms = ProfileHelpers.getAllMemberFirms().filter(x => x !== 'null');

        // Filter the memberFirms based on _profileMemberFirms
        let profileMemberFirmsList = memberFirms.filter(x => _profileMemberFirms.includes(x.reference.toLowerCase()));

        // If there's more than one member firm, move the active one to the beginning of the array
        if (profileMemberFirmsList.length > 1) {
            profileMemberFirmsList = disableDeleteMemberFirmToActiveMemberFirmAndUserMemberFirm(profileMemberFirmsList);
        }

        // Filter out the member firms that are in _profileMemberFirms
        const _memberFirmWithoutActive = memberFirms.filter(x => !_profileMemberFirms.includes(x.reference.toLowerCase()));
        const globalAtTopMFList=findIndexAndUnshift(_memberFirmWithoutActive,"dtt",false);
        const filteredMemberFirms = changeValueForGlobalMF(globalAtTopMFList);
        // Update the state
        setMemberFirmWithoutActive(filteredMemberFirms);
        setProfileMemberFirms(profileMemberFirmsList);
    }
    function changeValueForGlobalMF(_memberFirmWithoutActive) {
        const filteredMfs = _memberFirmWithoutActive.map(mf => {
            if (mf.id === "17573177") {
                mf.name = globalAuditMapping.get("17573177");
                mf.label=mf.name;
            }
            return mf;
        })
        return filteredMfs;
    }
    function findIndexAndUnshift(profileMemberFirms,memberfirm,activeDelete){
        const memberFirmIndex = profileMemberFirms.findIndex(x => x.reference.toLowerCase() === memberfirm.toLowerCase())
        if (memberFirmIndex !== -1) {
            // Use array destructuring to move the global member firm to the beginning and set the activeDelete flag
            const [activeMemberFirm] = profileMemberFirms.splice(memberFirmIndex, 1);
            activeMemberFirm.activeDelete = activeDelete;
            profileMemberFirms.unshift(activeMemberFirm);
        }
        return profileMemberFirms;
    }
    function disableDeleteMemberFirmToActiveMemberFirmAndUserMemberFirm(profileMemberFirmsList) {
        const _profileMemberFirms = profileMemberFirmsList.map(x => ({ ...x, activeDelete: true }))
        let resultProfileMemberFirms =findIndexAndUnshift(_profileMemberFirms,"dtt",true);
        resultProfileMemberFirms =findIndexAndUnshift(resultProfileMemberFirms,memberFirm,false);
        // Get the user profile member firm
        const userProfileMemberFirm = SH.getSessionStorage('MemberFirm');

        if (userProfileMemberFirm) {
            resultProfileMemberFirms=  findIndexAndUnshift(resultProfileMemberFirms,userProfileMemberFirm,false)
        }
        return resultProfileMemberFirms;
    }


    function handleAddButtonClick() {
        setManageActive(!manageActive);
    }

    function handleDisplayStateChange(willBeOpen) {
        if (!willBeOpen) {
            setManageActive(false);
            setSearchMemberFirm('');
        }
    }

    // event handlers 


    const addCurrentMemberFirmIfNotActive = (currentMemberFirm) => {
        const profileMemberFirms = ProfileHelpers.getAllMemberFirms();
        if (!profileMemberFirms.includes(currentMemberFirm.reference.toLowerCase())) {
            handleAddMemberFirm(currentMemberFirm.reference.toLowerCase())
        }
    }

    // helpers
    function getTrigger(mfKey) {
        const currentMemberFirm = memberFirms.find((x) => x.reference.toLowerCase() === mfKey);
        if (!currentMemberFirm) {
            return null;
        }
        addCurrentMemberFirmIfNotActive(currentMemberFirm);
        //These HAVE to have a MF-specific label, otherwise we fall back to the label!
        const value = getLabel(`mf_Library_${mfKey}`, currentMemberFirm.label, true);

        return (<Trigger value={value} />)
    }

    function handleAddMemberFirm(memberFirmLabel) {
        ProfileHelpers.addMFSub(memberFirmLabel);
        getAndFormatMemberFirms();
    }

    function handleRemoveMemberFirm(memberFirm) {
        ProfileHelpers.removeMFSub(memberFirm);
        getAndFormatMemberFirms();
    }

    const activeMemberFirms = useMemo(() => {
        function handleClick(mfName) {
            onChange(mfName);
            document.dispatchEvent(new CustomEvent('dd:requestClose'));
        }

        return profileMemberFirms.map(currentMemberFirm => (
            <MemberFirmItem
                key={currentMemberFirm.reference.toLowerCase()}
                memberFirm={currentMemberFirm}
                active={currentMemberFirm.reference.toLowerCase() === memberFirm}
                onClick={handleClick}
                onClose={handleRemoveMemberFirm}
                activeDelete={currentMemberFirm.activeDelete}
                collapsible={collapsible}
            />
        ));
    }, [memberFirm, memberFirms, profileMemberFirms, onChange]);//eslint-disable-line

    const managePanel = useMemo(() => {
        return <ManagePanel
            memberFirms={memberFirmWithoutActive}
            searchMemberFirm={searchMemberFirm}
            setSearchMemberFirm={setSearchMemberFirm}
            handleAddMemberFirm={handleAddMemberFirm}
            dropdownScrollAreaMaxHeight={dropdownScrollAreaMaxHeight}
        />
    }, [memberFirmWithoutActive, profileMemberFirms, searchMemberFirm])//eslint-disable-line

    return (
        <Dropdown
            triggerEl={getTrigger(memberFirm)}
            triggerText="Select Member Firm"
            onChange={handleDisplayStateChange}
            ddClassNames={`home-mf mf-dropdown`}
            triggerClassNames="home-mf__trigger"
            containerClassNames={`home-mf__container ${collapsible ? 'home-mf-collapsible' : ''}`}
        >
            <Dropdown.Panel additionalClassNames="mfdd__panel">
                <Dropdown.ScrollArea maxHeight="160px">
                    {activeMemberFirms}
                </Dropdown.ScrollArea>
                {(profileMemberFirms.length < memberFirms.length) && <AddButton
                    isHome
                    label={getLabel("mf_addmemberfirm", "Add Member Firm")}
                    active={manageActive}
                    onClick={handleAddButtonClick}
                />}
            </Dropdown.Panel>
            {manageActive && managePanel}
        </Dropdown>
    );
}

MemberFirmSelector.propTypes = {
    onChange: PropTypes.func
};

MemberFirmSelector.defaultProps = {
    onChange: () => {
    }
}

export default MemberFirmSelector;
