
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const JumpLinkSub = ({ title, href, jumpClass, jumpClickHandler }) => {
    return (
        <li>
            <a href={href} data-jumplink={jumpClass} onClick={(e) => jumpClickHandler(e, jumpClass)}>
                {ReactHtmlParser(title)}
            </a>
        </li>
    )
}

export default JumpLinkSub