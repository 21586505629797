import React, { useState, createContext, useLayoutEffect, useEffect, useContext } from 'react'
import { useLocation, useParams} from 'react-router-dom'
import CONTEXTHELPERS from '../helpers/ContextHelpers';
import RETRIEVALSERVICES from '../services/rp-service';
import UTILITIESHELPER from '../helpers/UtilitiesHelper'
import { logs, page } from "../helpers/log";
import TierHelpers from '../helpers/TierHelpers';
import StorageHelpers from '../helpers/StorageHelpers';
import IndustryHelpers from '../helpers/IndustryHelpers';
import ProfileHelpers from '../helpers/ProfileHelpers';
import SearchHelpers from '../helpers/SearchHelpers';
import useUserProfileService from '../services/useUserProfileService';
import { ErrorCodes, ErrorMessages, ErrorTypes } from '../components/Constants/Errors';
import { MemberFirmID } from '../components/Constants/Constants';

export const PageContext = createContext();
export const usePageContext = () => useContext(PageContext);


/**
* Hook to retrieve CMS Labels from PageContext
* @returns Object
*/

export const usePageContextLabels = () => {
	// Retrieve Labels from PageContext
	const { sitesLabels, memberFirm, debugLabelsAndMT, setSitesLabelsLoaded, region  } = usePageContext();
	
	// Compose function w/retrieved labels
	useEffect(()=>{
		if(UTILITIESHELPER.isEmptyObject(sitesLabels))
		{
			
			setSitesLabelsLoaded(false);
		}
	},[]);
	const getLabel = (label, fallback, ignoreMF = false, memberFirmOverride = "") => {
		//If we have labels and requested default label exist, then return label, else return fallback
		//By "default" the fallback would be without the MF, so if there is a non-specific MF version (we are good)
		
		if (sitesLabels && sitesLabels.length > 1 && !sitesLabels[label]) {
			console.warn("usePageContextLabels::getLabel:Label NOT available for:", label, sitesLabels)
			logs.info(page.PageContext,'getLabel',`Label NOT available for: ${label}`);
		}
		if (!ignoreMF && sitesLabels && sitesLabels[`${label}_${memberFirm}`]) {
			label = `${label}_${memberFirm}`;
			//console.log("usePageContextLabels::getLabel:MF-Specific label available for:", label)
		}
		//below method will return MF/Region of the selected country
		//We are giving priority to country as first and the region as second, which means if a lable with country code is available then that will be pulled up.
		//ex. (za-south africa - country) & africa(region) both is configured and label is available for both, then in that case za will hold the higher priority.
		if (!ignoreMF && sitesLabels && !sitesLabels[`${label}_${memberFirm}`] && region && sitesLabels[`${label}_${region}`]) {
			label = `${label}_${region}`;
		}

		//If we do ignore MF, and we pass a MF to override (headers/landing), then we take the ovveride
		if (ignoreMF && memberFirmOverride !== "" && sitesLabels && sitesLabels[`${label}_${memberFirmOverride}`]) {
			label = `${label}_${memberFirmOverride}`;
			//console.log("usePageContextLabels::getLabel:MF-Override label available for:", label)
		}
		if (debugLabelsAndMT) {
			return sitesLabels && sitesLabels[label] ? 'L:' + sitesLabels[label] : 'FB:' + fallback;
		}
		//In the case if the label did not exist, we will then return the "lbl_NameOfLabel" as the text!
		//console.log("usePageContextLabels::getLabel:Final label:", label)
		return sitesLabels && sitesLabels[label] ? sitesLabels[label] : fallback;
	}
	
	//This getMFLabel is ONLY used when we are potentially translating a Content.Field (from sites)
	//These fields from sites may or may not contain a lbl_ (or it may just contain the text we want!)
	const getMFLabel = (label, sourceCode) => {
		if (!label || label === "") {
			//console.warn("usePageContextLabels::getMFLabel:Label was BLANK/EMPTY for this sourceCode:", sourceCode);
			return "";
		}

		label = label.toString();

		//Generic Fallback for labels, would be if this is just the text and not yet made a lbl_LabelName
		if (!label.startsWith("lbl_")) {
			//We log as a warning as we should have NO "text", this text needs to be made into a lbl_LabelName
			//console.warn("usePageContextLabels::getMFLabel:Label missing for, label, sourceCode, sitesLabels:", label, sourceCode, sitesLabels);
			if (debugLabelsAndMT) {
				return '!LBL:' + label;
			}
			//We return the text that was in the content as it was not a lbl_LabelName
			return label;
		}

		//If label starts with lbl, then it should be in the labels, so get it...
		return getLabel(label, label)
	}
	return { getLabel, getMFLabel };
}


/**
* Hook to deturmine within features, if they are enabled or not, based on the CMS Settings from PageContext
 * ApplicationSettings: UPSConfig: "Features": "recentlyViewed, annotations, bookmarks, etc"
* @returns Object
*/
export const usePageContextSettings = () => {
	const { memberFirm, memberFirms, settings } = usePageContext();//eslint-disable-line

	//AppSettings: UPSConfig:Features
	const Feature = {
		Annotations: "Annotations",
		Bookmarks: "Bookmarks",
		Dashboard: "Dashboard",
		RecentlyViewed: "RecentlyViewed",
		RecentSearch: "RecentSearch"
	}
	//Sites/AppSettings: ResearchPortalMemberfirms[memberFirm].features
	//contextMemberFirm (for current context MF setting)
	const MemberFirmFeature = {
		Compare: "Compare",
		UserPreferences: "User Preferences"
	}

	//This is a private function as there is a lot to work out for each setting!
	//	We will used the memberFirm settings in conjuction with the current check for settings being enabled or not.
	const isMemberFirmFeatureEnabled = (featureName) => {
		//MemberFirm Settings are per memberFirm and are not directly all userPreference settings!
		if (memberFirms === undefined || memberFirms?.length === 0) {
			//console.log("usePageContextSettings::isMemberFirmFeatureEnabled:NULL-MFS-FALSE", memberFirm, featureName);
			return false;
		}

		//TODO:DEBO:NEED TO Add the check for the USER's MF's setting (and not the "context" MF!!
		//contextMemberFirm (for current context MF setting)

		//In Local the logged in user Member Firm will be "dtt", if we need to change the MemberFirm we have to pass it manually in this function in indexApp.js, eg: initializeProfile('dtt') or initializeProfile('us')  
        var userMemberFirm = (StorageHelpers.getLocalStorage('MemberFirm'));
		//TODO:Check if we should use the Context? ...contextMemberFirm - or is this PURELY for the user's MF!
		let currentMemberFirm = memberFirms.filter(s => s.memberFirmSettings.country === userMemberFirm);
		if (!currentMemberFirm) {
			//This will get the group'ed MF it there is a group!
			currentMemberFirm = memberFirms.filter(s => s.memberFirmSettings.country.includes(userMemberFirm));
		}
		//If the setting is not there, or we don'g have anything... then its OFF
		if (!currentMemberFirm || currentMemberFirm?.length === 0 || !currentMemberFirm[0].memberFirmSettings.UserFeatures || currentMemberFirm[0].memberFirmSettings.UserFeatures?.length === 0) {
			//console.log("usePageContextSettings::isMemberFirmFeatureEnabled:FALSE", memberFirm, featureName, memberFirms, currentMemberFirm[0]);
			return false;
		}
		//Only when its there explicitly is it ON
		if (currentMemberFirm[0].memberFirmSettings.UserFeatures.filter(f => f.name === featureName && f.Enabled=== true)) {
			//console.log("usePageContextSettings::isMemberFirmFeatureEnabled:TRUE", memberFirm, featureName, memberFirms, currentMemberFirm[0]);
			return true;
		}
		//console.log("usePageContextSettings::isMemberFirmFeatureEnabled:DEFAULT-FALSE", memberFirm, featureName, memberFirms, currentMemberFirm[0]);
		return false;
	}

	//Public functions:
	const isAnnotationsEnabled = () => {
		//MemberFirm setting has to be ON before ConfigSetting's User Preference is ON
		return isMemberFirmFeatureEnabled(Feature.Annotations);
	}

	const getMemberFirmSettings = (mf) => {
		return memberFirms.filter(s => s.memberFirmSettings.country === mf || s.memberFirmSettings.defaultMemberFirm === mf)
	}

	const isRestrictedAccessMemberfirm = () => {
		if (RETRIEVALSERVICES.UPSUserID() === CONTEXTHELPERS.debugUserId) {
			const isMfAccessRestricted = (window.DART.ENV.RP_REACT_APP_RESTRICTACCESSBYMEMBERFIRM !== undefined && String(window.DART.ENV.RP_REACT_APP_RESTRICTACCESSBYMEMBERFIRM).toUpperCase() === 'TRUE')
			if (isMfAccessRestricted) {
				return true;
			}
			return false;
		}
		return false;
	}

	const isBookmarksEnabled = () => {
		//MemberFirm setting has to be ON before ConfigSetting's User Preference is ON
		return isMemberFirmFeatureEnabled(Feature.Bookmarks);
	}

	const isCompareEnabled = () => {
		//Compare is NOT a userPreference setting!
		//console.log("isCompareConfigEnabled", settings?.compareConfigEnabled);
		if (settings?.compareConfigEnabled === undefined || settings?.compareConfigEnabled === null || settings?.compareConfigEnabled !== true) {
			return false;
		}
		//Global settings superseed first over memberfirm settings - if preference is enabled!
		return settings.compareConfigEnabled && isMemberFirmFeatureEnabled(MemberFirmFeature.Compare);
	}

	const isDashboardEnabled = () => {
		//MemberFirm setting has to be ON before ConfigSetting's User Preference is ON
		return isMemberFirmFeatureEnabled(Feature.Dashboard);
	}

	const isRecentlyViewedEnabled = () => {
		//MemberFirm setting has to be ON before ConfigSetting's User Preference is ON
		return isMemberFirmFeatureEnabled(Feature.RecentlyViewed);
	}

	const isRecentSearchEnabled = () => {
		//MemberFirm setting has to be ON before ConfigSetting's User Preference is ON
		return isMemberFirmFeatureEnabled(Feature.RecentSearch);
	}

	//TODO: If this list gets long... Change to call isUserPreferencesFeatureEnabled(Feature.FeatureName) ? 
	//	For when we want to just have the calling function call the feature instead of the isFeatureEnabled
	//	return { Feature, isUserPreferencesFeatureEnabled };
	return { isAnnotationsEnabled, isBookmarksEnabled, isCompareEnabled, isDashboardEnabled, isRecentlyViewedEnabled, isRestrictedAccessMemberfirm, isRecentSearchEnabled ,getMemberFirmSettings };
}


/**
* Hook to retrieve Synonym data from PageContext
 * I did not put this in "SearchResultContext" as we need the context, and we need it BEFORE the search/search results!
 * We need this potentially from the Catalog page, Collection page, and beyond.
 * USAGE: 
 *	console.log("synonyms.AICPA", getSynonyms("AICPA"));
 *	console.log("synonyms.Q&A", getSynonyms("Q&A"));
 *	console.log("synonyms.q&a", getSynonyms("q&a"));
 *	console.log("synonyms.Profit", getSynonyms("Profit")); === [ "Profit" ]
 *	console.log("synonyms.'Profit and loss'", getSynonyms("Profit and loss", true)); === [ "Profit and loss", "P&L" ]
 *	console.log("synonyms.Profit and loss", getSynonyms("Profit and loss")); === [ "Profit", "and", "loss", "P&L" ]
 *	console.log("synonyms.Profit and loss", getSynonyms("PROFIT AND LOSS"));
 *	console.log("synonyms.GAAS", getSynonyms("GAAS"));
 *	console.log("synonyms.gaas", getSynonyms("gaas"));
* @returns Object
*/
export const usePageContextSynonyms = () => {
	// Retrieve Synonyms from PageContext
	const { synonyms } = usePageContext();

	// Compose function get the array of word and matching synonyms for the given word
	// If exactPhraseMatch===true, then we only want to highlight the whole phrase in the page, so will then NOT split out each word
	// Highlight is changed through the "searchText" 
	//	the search should be expanded to also include the synonyms
	const getSynonyms = (word, exactPhraseMatch = false) => {
		//default, if we don't find synonyms, we just return the same word as a single valued array (to loop through for highlight)
		let matchedSynonyms = exactPhraseMatch ? [word] : word.split(' ');
		let matchWord = word.toLowerCase();

		//Find a list of matching words/phrases within synonyms (Must match whole word or phrase)
		//	"word" does not match "acronym,this word synonym"
		var matchingSynonyms = synonyms.filter(function (synonym) {
			var currentSynonym = synonym.toLowerCase();
			if (currentSynonym.indexOf(matchWord) > -1) {
				//console.log("synonyms, match", matchWord, currentSynonym);
				var words = currentSynonym.split(",");
				var exactMatch = false;
				words.forEach((word, idx) => {
					if (word === matchWord) {
						//console.log("synonyms, exact-word-match", word, synonym);
						exactMatch = true;
					}
				});
				return exactMatch;
			}
			return false;
		});

		if (Array.isArray(matchingSynonyms) && matchingSynonyms.length > 0) {
			matchingSynonyms.forEach((matchingSynonym) => {
				let matchingSynonymItems = matchingSynonym.split(",");
				matchingSynonymItems.forEach((matchingSynonymItem) => {
					if (matchWord !== matchingSynonymItem.toLowerCase()) {
						matchedSynonyms.push(matchingSynonymItem);
					}
				});
			});
		}

		return matchedSynonyms;
	}

	return { getSynonyms };
}


const PageContextProvider = ({ children }) => {
	//TODO:BDP: Global context object, must replace all references to url/route/etc: 
	//	let context = CONTEXTHELPERS.generateContext(window.location.href);
	//	everywhere, we re-use the PageContext.Context object!

	const location = useLocation();
	let params = location?.pathname?.split("/");
	const { memberFirmParam= params[1], languageParam = params[2], knowledgeDomainParam = params[3], pageControllerTypeParam = params[4], pageControllerTypeCollection = params[5] } = useParams();
	//This is the CONTEXT OBJECT (that should be "replaced" with the context object!)
	//This gets the LAST-MF, or the user's MF
	const memberFirm = ProfileHelpers.getFallbackMemberFirm(memberFirmParam);
	const language = ProfileHelpers.getFallbackLanguageByMemberFirm(memberFirm, languageParam);
	const knowledgeDomain = ("content|dashboard".indexOf(knowledgeDomainParam) > -1) ? "" : knowledgeDomainParam; //Must ONLY contain KD, otherwise empty!
	const pageControllerType = (pageControllerTypeParam === 'collection') ? pageControllerTypeCollection : pageControllerTypeParam;
	const pageCollectionName = (pageControllerTypeParam !== 'collection') ? pageControllerTypeCollection : "";
	const queryParams = new URLSearchParams(location?.search);
	const currentSearch = SearchHelpers.fromQueryString(queryParams);
	const compareVersionParam = queryParams?.get('compareVersion');
	//Try combine this - is there really a reason to "have" searchPhrase??
	const [searchPhrase, setSearchPhrase] = useState(currentSearch || '');
	const [pageURLWhereSearchInnitiated, setPageURLWhereSearchInnitiated] = useState("");
	const {getUserProfileInfo, getCountryKDSettings} = useUserProfileService();

	//each variable needs to be in the above coming out of the params instead of a context object, and the pageContext object an be used as the context...
	//	as pageContext.memberFirm
	const [context, setContext] = useState(CONTEXTHELPERS.generateContext(window.location.href, searchPhrase, currentSearch, null, memberFirm, language));
	const [contextMemberFirm, setContextMemberFirm] = useState(null);
	const [taxonomyMemberFirmContext, setTaxonomyMemberFirmContext] = useState(null)

	//These are potentially global variables, that will change each URL/context
	const isCatalogPage = (knowledgeDomain !== "") ? context.pageRequestURL.endsWith(knowledgeDomain) : false;
	const isSearchPage = (currentSearch !== null);
	const isFolioPage = ('folio').includes(pageControllerType) || ('guide').includes(pageControllerType);
	const isJournalPage = ('deloitte-communications|professional-news|journals').includes(pageControllerType);
	const isJournalListing = isJournalPage && (!UTILITIESHELPER.isStringNullorEmpty(context.pageSelectedPublicationGUID) && UTILITIESHELPER.isStringNullorEmpty(context.pageSelectedContentItemGUID));
	const isFolioSectionlisting = ((pageControllerType === "folio" || pageControllerType === "guide") && !UTILITIESHELPER.isStringNullorEmpty(context.pageSelectedSection) && UTILITIESHELPER.isStringNullorEmpty(context.pageSelectedPublicationGUID))
	const isRelatedLinksPage = (context.pageSelectedSection === "related");// && context.pageSelectedPublicationGUID !== "");
	const isSpinePage = ('manual|standards|roadmap|guidance').includes(pageControllerType);
	const isWormholeActive = queryParams.get('wormhole');

	//This prevents us from loading "english" multiple times...
	const [previousLanguage, setPreviousLanguage] = useState("");
	const [footerLinks, setFooterLinks] = useState([]);

	// Peer Context AKA Do other tabs exist?
	const backChannel = new BroadcastChannel('rp-peer-network');

	const [annotationPanelOpen, setAnnotationPanelOpen] = useState(false);
	const [annotateTerms, setAnnotateTerms] = useState();
	const [hasPeers, setHasPeers] = useState(false);
	const [categoryOn, setCategoryOn] = useState(false);
	const [downloadPanelOpen, setDownloadPanelOpen] = useState(false);
	const [bookmarkOpen, setBookmarkOpen] = useState(false);
	const [memberFirms, setMemberFirms] = useState([]);
	const [pageDescription, setPageDescription] = useState('');
	const [pageTitle, setPageTitle] = useState('')
	const [peerTestCompleted, setPeerTestCompleted] = useState(false);
	const [selectedCatalogs, setSelectedCatalogs] = useState(StorageHelpers.getLocalStorageJSON('rp-selected-catalogs') || {});
	//TODO:BDP: This needs to move into the ContextHelper when we get the context object, as it does this there!
	//	then we update the usage of this to read from PageContext.context.languageOject ?
	const [selectedLanguageObject,setSelectedLanguageObject] = useState(UTILITIESHELPER.getCurrentLanguageLocalStorage(language));//eslint-disable-line

	//Global Objects
	const [settings, setSettings] = useState(null);
	const [sitesLabelsLoaded, setSitesLabelsLoaded] = useState(false);
	const [sitesLabels, setSitesLabels] = useState({});
	const [synonyms, setSynonyms] = useState([]);
	const [isMemberFirmsLoaded, setIsMemberFirmsLoaded] = useState(false);

	// Pref Context: Tier (Frameworks)
	const [tier, setTier] = useState();
	const [currentTier, setCurrentTier] = useState();
	const [hasGottenCorrectTierValue, setHasGottenCorrectTierValue] = useState(false);
	const tierVal = JSON.stringify(tier);

	// Pref Context: Industry
	const [industry, setIndustry] = useState();
	const [currentIndustry, setCurrentIndustry] = useState();
	const [hasVerifiedIndustry, setHasVerifiedIndustry] = useState(false);
	const industryVal = JSON.stringify(industry);
	const [allIndustry, setAllIndustry] = useState({});
	const [allTier, setAllTier] = useState({});

	//To debug the labels on the page: localStorage.setItem('mt-debug','true');
	const debugLabelsAndMT = UTILITIESHELPER.getIsDebugMTLocalStorage();

	const [isNavigatedFromCollectionSearch, setIsNavigatedFromCollectionSearch] = useState(false);

	//below method will return MF/Region of the selected country
	const [region, setRegion] = useState("");

	//MessageNotification
	//message : text that you want display, type : type of message (error, info, warning), display : to indicate the message should display
	const [notification, setNotification] = useState({ message: '', type: 'error', display: false });
	const getMemberFirmLanguages = () => {
		if (contextMemberFirm) {
			return contextMemberFirm.memberFirmSettings?.knowledgeDomain?.find(kd => kd.name === knowledgeDomain)?.language?.split(",");
		}
	}
	const getMemberFirmDefaultLanguage = () => {
		if (contextMemberFirm) {
			return contextMemberFirm.memberFirmSettings?.defaultLanguage;
		}
	}
	//This gets the current context MF IDs (the list of IDs in the CONFIGURATION) (could be multi-valued, but will return an array!)
	const getMemberFirmIDs = () => {
		if (contextMemberFirm) {
			//Right now DCS only expects, 1x MF ID ... so we will return array of the first one...
			 return contextMemberFirm.memberFirmSettings?.knowledgeDomain?.find(kd=>kd.name===knowledgeDomain)?.memberFirm?.split(",");
			//return [contextMemberFirm.memberFirmSettings.memberFirm.split(",")[0]];
		}
	}
	//This gets the current context MF ID (not the one use for DCS queries, but explicitly the singular currnent context MF!!)
	const getMemberFirmID = () => {
		if (contextMemberFirm) {
			return contextMemberFirm.id;
		}
	}

	const getMemberFirmSettings = () => {
		if (contextMemberFirm) {
			//Right now DCS only expects, 1x MF ID ... so we will return array of the first one...
			return contextMemberFirm.memberFirmSettings;
			//return [contextMemberFirm.memberFirmSettings.memberFirm.split(",")[0]];
		}
	}

	//ChatBot
	const [isResearchAssistantEnabled, setIsResearchAssistantEnabled] = useState(false);
	const [countryKDSettings, setCountryKDSettings] = useState(null);
	const [isUserProfileLoaded, setUserProfileLoaded] = useState(false);
	const [isCountryKDSettingsLoaded, setCountryKDSettingsLoaded] = useState(false);
	const [researchAssistantMemeberfirms,setResearchAssistantMemberfirms]=useState(null);
	const [userCountry, setUserCountry] = useState("");
	

	//Language
	const [selectedLanguage, setSelectedLanguage] = useState(UTILITIESHELPER.getLocalStorage("selected-user-language"));
	
	function countrieslist ( countryKDSettings) {

		const data =countryKDSettings ;

		const filteredData = data.map(item => {
			let filteredItem = {...item};
			filteredItem.knowledgeDomain = item.knowledgeDomain.filter(domain => {
			  if (domain.siteFeatures) {
				for (let j = 0; j < domain.siteFeatures.length; j++) {
				  if (domain.siteFeatures[j].name === 'ResearchAssistant' && domain.siteFeatures[j].Enabled) {
					return true;
				  }
				}
			  }
			  return false;
			});

			return filteredItem;
		  }).filter(item => item.knowledgeDomain.length > 0);
          
		  setResearchAssistantMemberfirms(filteredData);

	}



	const getUserProfileCanSeeRA = async () => {

		const userProfileInfo = await getUserProfileInfo();
		setUserProfileLoaded(true);
		if(userProfileInfo){
			const { CanSeeRA, Country } = userProfileInfo;
			setIsResearchAssistantEnabled(CanSeeRA);
			setUserCountry(Country);
		}
	}
	useEffect(() => {
		getUserProfileCanSeeRA();
	}, [])
	

	//Maintain the page context
	useEffect(() => {
		//Compares against the FULL URL! (incl the http etc)
		//This stops the context from being replaced when the URL did not actually change
		if (context.pageRequestURL !== window.location.href) {
			//TODO: Change this to use the routeInfo instead of URL (so we can "listen" to if only listening to the href does not work)
			setContext(CONTEXTHELPERS.generateContext(window.location.href));
			//console.log("PageContext::useEffect:setContext:<<<< Updated >>>>");

			//This will reset the message notification object when user navigate from current page
			setNotification({ message: '', type:ErrorTypes.Error, display: false });
		}
	}, [location?.pathname]);//eslint-disable-line

	// This determines whether or not the browser has multiple tabs with the same origin
	// please keep as the first useEffect --- order matters
	useEffect(() => {
		// Handles broadcast message
		function messageHandler(event) {
			// if another tab exists, respond no
			if (event.data === 'alone') {
				backChannel.postMessage('no')
			}

			// if we are not alone, we have peers and the test is complete
			if (event.data === 'no') {
				// only do this once
				if (!peerTestCompleted) {
					// console.log('has peers');
					setHasPeers(true);
					setPeerTestCompleted(true);
				}
			}
		}

		// assign message handler
		backChannel.onmessage = messageHandler;

		// post message asking if we are alone
		backChannel.postMessage('alone');

		// since broadcast only goes to subscribers and not the publisher
		// we need to set a timeout to handle no response, because we are alone.
		// This can use a relatively short timeout because the message is sent by
		// the browser's context and not the app.
		setTimeout(() => {
			if (!peerTestCompleted) {
				// console.log('no peers');
				setPeerTestCompleted(true);
			}
		}, 50);

		// finally, on unload we will close the channel
		return () => {
			backChannel.close();
		}
	}, []);//eslint-disable-line

	// Load Global Settings and all member firm configurations (These are the same not matter what the URL/MF is, so only needs to be retreived ONCE)
		useEffect(() => {
			const fetchSettings = async () => {
				try {
					const settingData = await getCountryKDSettings();

					logs.debug(page.PageContext, 'fetchSettings', "settingData:Context, settingData ", context, settingData);

					settingData.memberFirms.forEach((taxonomyMemberFirm, mfIdx) => {
						
						if (taxonomyMemberFirm.memberFirmSettings.country === context.memberFirm) {
							setContextMemberFirm(taxonomyMemberFirm);
							setRegion(taxonomyMemberFirm.memberFirmSettings?.publicationName);
							ProfileHelpers.setSelectedMemberFirmTaxonomyId(taxonomyMemberFirm.id);

						}
					});

					setMemberFirms(settingData.memberFirms);
					setIsMemberFirmsLoaded(true);
					setSettings(settingData.settings);

					if (settingData) {
						const memberfirmSettings = settingData.memberFirms.map(a => a.memberFirmSettings);
						setCountryKDSettings(memberfirmSettings);
						
						const member_firm = !UTILITIESHELPER.isStringNullorEmpty(
							UTILITIESHELPER.getLocalStorage('research-assistant-memberfirm')
						)
						? UTILITIESHELPER.getLocalStorage('research-assistant-memberfirm')
						: UTILITIESHELPER.getLocalStorage('MemberFirm');
						
						const country = memberfirmSettings.filter(x => x.country == member_firm);
						const member_firm_id = country[0]?.defaultMemberFirm;
						localStorage.setItem("research-assistant-member-firm-Id", member_firm_id);
						countrieslist(memberfirmSettings);
						const TaxonomyMemberfirmValue= memberfirmSettings
						.filter(x => x.country === context.memberFirm)[0]?.knowledgeDomain?.filter(x => x.name === (context.knowledgeDomain ? context.knowledgeDomain : 'auditing'))[0]?.taxonomyMemberFirm ;
						const taxonomyArray = (UTILITIESHELPER.isStringNullorEmpty(TaxonomyMemberfirmValue)? MemberFirmID.dtt :TaxonomyMemberfirmValue)
						setCountryKDSettingsLoaded(true);
						setTaxonomyMemberFirmContext(taxonomyArray)
					}

				}
				catch (err) {
					logs.error(page.PageContext, 'fetchSettings', ErrorMessages.settings, err, { eventId: ErrorCodes.PageContext });
				}
			}

			fetchSettings();
		}, []);//eslint-disable-line

	useEffect(() => {
		if (contextMemberFirm && contextMemberFirm.memberFirmSettings.country !== context.memberFirm) {
			//Find the seelcted MF in teh list, and then re-select it...We have to find it or its broken
			var currentMF = memberFirms?.find(m => m.reference.toLowerCase() === context.memberFirm);
			if (currentMF) {
				setContextMemberFirm(currentMF);;
				ProfileHelpers.setSelectedMemberFirmTaxonomyId(currentMF.id);

				//Based on the selected country from the country dropdown we are setting up its region
				setRegion(currentMF?.memberFirmSettings?.publicationName)
			}
		}
	}, [context.memberFirm]);//eslint-disable-line

	// Load cms labels - NOT memberFirm-dependant!
	useEffect(() => {
		const retreiveResourceLabels = async (resource) => {
			try {
				const res = await RETRIEVALSERVICES.retrieveResource(context, resource);
				logs.debug(page.PageContext, 'retreiveResourceLabels', "retreiveResourceLabels:Context, res ", context, res);
				setSitesLabels(res.labels);
				setSitesLabelsLoaded(true);
			}
			catch (err) {
				logs.error(page.PageContext, 'PageContext', ErrorMessages.resource, err,{eventId:ErrorCodes.PageContext});
			}
		}
		const retreiveResourceFooter = async (resource) => {
			try {
				const res = await RETRIEVALSERVICES.retrieveResource(context, resource);
				logs.debug(page.PageContext, 'retreiveResourceFooter', "retreiveResourceFooter:Context, res ", context, res);
				setFooterLinks(res.links.links);
			}
			catch (err) {
				logs.error(page.PageContext, 'retreiveResourceFooter', ErrorMessages.footer, err,{eventId:ErrorCodes.PageContext});
			}
		}

		if (!CONTEXTHELPERS.isSameLanguage(previousLanguage, language) || sitesLabelsLoaded==false) {
			retreiveResourceLabels("labels");
			retreiveResourceFooter("footer");

			setPreviousLanguage(language);
		}
		
	}, [language,sitesLabelsLoaded]);//eslint-disable-line

	//TEMPORARY LOAD OF SYNONYMS - To be removed when we deturmine where they will come from
	// Load settings and member firm configuration [wan tto do this first as it may not need to be done on landing page!]
	useEffect(() => {
		//TODO: When search is merged, and we have the page context owning the url/mf/lang/etc, then we should ONLY load the Synonyms when we are "past" the catalog page, i.e. we will have knowledge domain!
		//	otherwise do not load it
		//FOR NOW - we will just have it populated everywhere!
		setSynonyms([
			"AAG,AICPA Audit and Accounting Guides",
			"AAM,Audit Approach Manual",
			"ABCTOD,Material Class of Transactions Account Balance Disclosure",
			"ACL,Audit Command Language",
			"AERS,Audit and Enterprise Risk Services",
			"AICPA,American Institute of Certified Public Accountants",
			"AIN,AICPA Accounting Interpretations",
			"APB,Accounting Principles Board Opinions",
			"APM,Audit Planning Memorandum",
			"AQA,Audit Quality Alerts",
			"ARB,Accounting Research Bulletins",
			"ARL,Audit Risk Leader",
			"ASC,Accounting Standards Codification",
			"ASM,Audit Summary Memorandum",
			"ASU,Accounting Standards Update",
			"C&M,Clients Markets",
			"CAATs,Computer Assisted Audit Techniques",
			"CEO,Chief Executive Officer",
			"CFO,Chief Financial Officer",
			"CFRR,SEC Financial Reporting Releases",
			"CGU,Cash generating unit",
			"CoCo,Criteria of Control",
			"COSO,Committee of Sponsoring Organizations",
			"CPE,Continuing Professional Education",
			"CPI,Consumer Price Index",
			"DAC,Deferred Acquisition Costs",
			"DCCS,Deloitte Conflict Checking System",
			"DESC,Deloitte Entity Search Compliance",
			"DGC,Designated Global Client",
			"DGSL,Deloitte Global Services Limited",
			"DIG,Derivatives Implementation Group Issues",
			"DPM,DTTL Policies Manual",
			"DPM,Deloitte Policy Manual",
			"DTTL,Deloitte Touche Tohmatsu Limited",
			"DTTS,Deloitte Touche Tohmatsu Services",
			"EDA,Exploratory Data Analysis",
			"EDGAR,Electronic Data Gathering Analysis and Retrieval",
			"EITF,Emerging Issues Task Force Issues",
			"EMTN,Euro Medium Term Note",
			"EPS,Earning Per Share",
			"EQAR,Engagement Quality Assurance Review",
			"EQCR,Engagement Quality Control Review",
			"ERS,Enterprise Risk Services",
			"ESOP,Employee Stock Option Plan",
			"EU,European Union",
			"FAQs,Frequently Asked Questions",
			"FAS,Financial Advisory Services",
			"FAS,FASB Statements of Financial Accounting Standards",
			"FIFO,First In First Out",
			"FIN,FASB Interpretations",
			"FRC,Financial Reporting Council",
			"FRS,Financial Reporting Standard",
			"FRRP,Financial Reporting Review Panel",
			"FSA,Financial Services Authority",
			"FSP,FASB Staff Positions",
			"FTB,FASB Technical Bulletins",
			"FV,Fair Value",
			"GAAP,Generally Accepted Accounting Principles",
			"GASB,Governmental Accounting Standards Board",
			"GCRB,Global Conflicts Resolution Board",
			"GDCs,Global Delivery Centers",
			"GDRs,Global Depositary Receipts",
			"GEN,General Policies Manual",
			"GHCS,Global Hosting Centers",
			"GIMS,Group Independence Monitoring System",
			"GIOS,Global IFRS and Offerings Services group",
			"GPPC,Global Public Policy Committee",
			"GSO,Global Security Office",
			"GSR,Global Strategic Relationship",
			"GTAs,Global Tax Advisories",
			"HTM,Held to maturity",
			"IAASB,The International Auditing and Assurance Standards Board",
			"IAPS,International Auditing Practice Statement",
			"IAS,International Accounting Standards",
			"IASB,International Accounting Standards Board",
			"IASC,International Accounting Standards Committee",
			"IBNR,Incurred But Not Reported",
			"IFAC,International Federation of Accountants",
			"IFRIC,International Financial Reporting Interpretations Committee",
			"IFRS,International Financial Reporting Standards",
			"IFV,Internal Fair Value",
			"IIA,The Institute of Internal Auditors",
			"IND,Industry",
			"IPE,Information Produced by the Entity",
			"IPMA,International Primary Market Association",
			"IPSAS,International Public Sector Accounting Standard",
			"IR,SEC Interpretive Releases",
			"ISA,International Standards on Auditing",
			"ISACA,Information Systems Audit and Control Association",
			"ISAE,International Standard for Assurance Engagements",
			"ISQC,International Standard on Quality Control",
			"ISRE,International Standard on Review Engagements",
			"ISRS,International Standards on Related Services",
			"IT,Information Technology",
			"JEDAR,Journal Entry Data Analysis Routines",
			"JET,Journal Entry Testing",
			"JCE,Joint controlled entity",
			"JV,Joint Venture",
			"KYC,Know Your Client",
			"LCSP,Lead Client Service Partner",
			"LIBOR,London Interbank Offered Rate",
			"LIFO,Last In First Out",
			"M&A,Mergers and Acquisitions",
			"MFSC,Member Firm Selected Clients",
			"MP,Monetary Precision",
			"MSA,Master Services Agreement",
			"MUS,Monetary Unit Sampling",
			"NASDAQ,National Association of Securities Dealers Automated Quotation System",
			"NAV,Net Asset Value",
			"NFP,Not for Profit Entity",
			"NPPD,National Professional Practice Director",
			"NRV,Net Realisable Value",
			"OCEO,Office of the Chief Executive Officer",
			"OSS,Other Specialized Services",
			"PB,AICPA Practice Bulletins",
			"PCAOB,Public Company Accounting Oversight Board",
			"PII,Personally Identifiable Information",
			"PM,Performance Materiality",
			"PPD,Professional Practice Directors",
			"PRD,Practice Review Director",
			"PSE,Public Sector Enterprise",
			"Q&A,Questions and Answers",
			"R&D,Research and Development",
			"RAS,Reporting Advisory Services",
			"RCP,Regulatory Contact Partner",
			"REIT,Real Estate Investment Trust",
			"REP,Accountants Reports Manual",
			"RFI,Request for Information",
			"RFP,Request for Proposal",
			"RFQ,Request for Qualification",
			"RFQ,Request for Quotation",
			"ROI,Return on investment",
			"ROMM,Risk of material Misstatement",
			"RPI,Retail price index",
			"RRL,Reputation and Risk Leader",
			"RWG,Regulatory Working Group",
			"SAB,Staff Accounting Bulletin",
			"SAB,SEC Staff Accounting Bulletins",
			"SAP,Substantive Analytical Procedures",
			"SARBOX,Sarbanes Oxley",
			"SAS,Statement on Auditing Standards",
			"SEC,Securities and Exchange Commission",
			"SIC,Standing Interpretations Committee",
			"SMR,Subject matter Resource",
			"SOP,Statement of Position",
			"SPE,Special Purpose Entity",
			"SSAE,Statement on Standards for Attestation Engagements",
			"SSARS,Statement on Standards for Accounting and Review Services",
			"SX,SEC Regulation S X",
			"TIS,AICPA Technical Inquiry Service",
			"TOC,Test of controls",
			"TOD,Tests of Details",
			"TPA,Technical Practice Aids",
			"TS,Two Strata",
			"TSST,Transaction Services Standards Team",
			"UEE,Understand the entity and its environment",
			"VAL Manual,Valuation Practice Manual",
			"VAT,Value added tax",
			"VIE,Variable Interest Entity",
			"CV,Carrying Value",
			"ED,Exposure draft",
			"DP,Discussion paper",
			"MD&A,Management's discussion and analysis",
			"P&L,Profit and loss",
			"AS,Auditing standard",
			"ASR,SEC Accounting Series Release",
			"CAQ,Center for Audit Quality",
			"C&DI,SEC Compliance and Disclosure Interpretation",
			"DTL,deferred tax liability",
			"EBITDA,earnings before income taxes depreciation and amortization",
			"FDA,Food and Drug Administration",
			"GA,Group Audit",
			"GSE,government sponsored entity",
			"ICFR,internal control over financial reporting",
			"IPO,initial public offering",
			"SG&A,selling general and administrative expense",
			"XBRL,eXtensible Business Reporting Language",
			"AU,U.S. Auditing Standards",
			"FASAB,Federal Accounting Standards Advisory Board",
			"GAAS,generally accepted auditing standards",
			"IAS,International Accounting Standard",
			"PCC,Private Company Council",
			"BS,Balance Sheet",
			"SCF,Statement of Cash Flows"
		]);

		//USAGE:FE
		//import { usePageContext, usePageContextLabels, usePageContextSynonyms } from '../../contexts/PageContext';
		//const { getSynonyms } = usePageContextSynonyms();
		//console.log("synonyms.AICPA", getSynonyms("AICPA"));
		//console.log("synonyms.Q&A", getSynonyms("Q&A"));
		//console.log("synonyms.q&a", getSynonyms("q&a"));
		//console.log("synonyms.Profit and loss", getSynonyms("Profit and loss"));
		//console.log("synonyms.Profit and loss", getSynonyms("PROFIT AND LOSS"));
		//console.log("synonyms.GAAS", getSynonyms("GAAS"));
		//console.log("synonyms.gaas", getSynonyms("gaas"));
	}, []);

	//TODO:BDP: Move tier into context? (as this is also done inside the initialization of the context)
	useLayoutEffect(() => {
		/*
		All the functionality in this effect is to make sure we are grabbing the correct tier value (for uk accounting dropdown) when the app loads
		On a high level the process is as follows:

		(Important info: Every time the user makes a selection from the UK dropdown we are setting that value in both local and session storage)

		- Set an item in local storage ('addStorageEventToSeeIfOtherTabsExist')
		- the storage event listeners in any other tabs (if they exist) will respond to this event
		- The other tabs (if they exist) will respond back to the original tab (the new one), by adding their own local storage event ('otherTabExists')
		- If another tab exists, we are going to update a ref value (isThereAnotherTabOpen) and set it to true to acknowledge we know another tab exists
		- We are momentarily not going to do anything with this information and instead do the following:
		- Check if we've set a tier value in session storage
		- If we have, we're going to set the tier value to the session storage value and return early
		- If not, we are going to check if there is a value in local storage,
		- If there is NOT one, we are going to just set the tier to the default value (null) and return early
		- If there IS a local storage val, we have to check if the val is from a previous session or not
		- This is where checking whether other tabs exist comes into play,
		- We add a setTimeout that should fire in 1 second to check if the storage event listener logic has determined if there are other tabs
		- If before the setTimeout fires no storage event has fired with the key 'otherTabExists' we are making the assumption no other tabs exist
		- We are therefore not going to use the local storage value, as we think it's from a previous session,
			and we're going to set the tier to the default value and clear the local storage value
		- If another tab exists though, we are going to use the value from local storage

		Until hasGottenCorrectTierValue is set to true the layout's body is not going to load
		This prevents us from having components render with the incorrect tier value and having to rerender after the tier value is initiallity set
		*/

		let defaultVal = null;
		if (!UTILITIESHELPER.isStringNullorEmpty(memberFirm) && !UTILITIESHELPER.isStringNullorEmpty(knowledgeDomain)) {
			defaultVal = {
				[memberFirm]: { [knowledgeDomain]: [] }
			};
		}
		const setCorrectVal = (tier) => {
			if (!tier) { tier = defaultVal; }
			else if (!tier[memberFirm]) { tier[memberFirm] = { [knowledgeDomain]: [] } }
			else if (!tier[memberFirm][knowledgeDomain]) { tier[memberFirm][knowledgeDomain] = [] };
			setTier(tier);
			setHasGottenCorrectTierValue(true);
		}
		try {
			if (!UTILITIESHELPER.isStringNullorEmpty(memberFirm) && !UTILITIESHELPER.isStringNullorEmpty(knowledgeDomain)) {
				if (peerTestCompleted) {
					// check if session storage tier val exists
					const sessionVal = TierHelpers.getSessionStorage();
					if (sessionVal) {
						setCorrectVal(sessionVal);
						return;
					}

					// check if local storage val, if not, we know we should just set the default val
					const localVal = TierHelpers.getLocalStorage();
					if (!localVal) {
						setCorrectVal(defaultVal);
						return;
					}

					if (hasPeers) {
						const val = TierHelpers.getLocalStorage();
						setCorrectVal(val);
					}
					else {
						TierHelpers.removeLocalStorage();
						setCorrectVal(defaultVal)
					}
				}
			}
		}
		catch (err) {
			setCorrectVal(defaultVal);
		}
	}, [peerTestCompleted, hasPeers, memberFirm, knowledgeDomain]);

	useLayoutEffect(() => {
		if (hasGottenCorrectTierValue) {
			TierHelpers.setSessionStorage(tier);
		}
	}, [tierVal, hasGottenCorrectTierValue]);//eslint-disable-line

	useLayoutEffect(() => {
		let defaultVal = null;
		if (!UTILITIESHELPER.isStringNullorEmpty(memberFirm) && !UTILITIESHELPER.isStringNullorEmpty(knowledgeDomain)) {
			defaultVal = {
				[memberFirm]: { [knowledgeDomain]: [] }
			};
		}
		const setCorrectVal = (industry) => {
			if (!industry) { industry = defaultVal; }
			else if (!industry[memberFirm]) { industry[memberFirm] = { [knowledgeDomain]: [] } }
			else if (!industry[memberFirm][knowledgeDomain]) { industry[memberFirm][knowledgeDomain] = [] };
			setIndustry(industry);
			setHasVerifiedIndustry(true);
		}

		try {
			if (!UTILITIESHELPER.isStringNullorEmpty(memberFirm) && !UTILITIESHELPER.isStringNullorEmpty(knowledgeDomain)) {
				if (peerTestCompleted) {
					// check if session storage val exists
					const sessionVal = IndustryHelpers.getSessionStorage();
					if (sessionVal) {
						setCorrectVal(sessionVal);
						return;
					}

					// check if local storage val, if not, we know we should just set the default val
					const localVal = IndustryHelpers.getLocalStorage();
					if (!localVal) {
						setCorrectVal(defaultVal);
						return;
					}

					if (hasPeers) {
						const val = IndustryHelpers.getLocalStorage();
						setCorrectVal(val);
					}
					else {
						IndustryHelpers.removeLocalStorage();
						setCorrectVal(defaultVal)
					}
				}
			}
		}
		catch (err) {
			setCorrectVal(defaultVal);
		}
	}, [peerTestCompleted, hasPeers, memberFirm, knowledgeDomain]);

	useLayoutEffect(() => {
		if (hasVerifiedIndustry) {
			IndustryHelpers.setSessionStorage(industry);
		}
	}, [industryVal, hasVerifiedIndustry]);//eslint-disable-line

	useLayoutEffect(() => {
		StorageHelpers.setLocalStorageJSON('rp-selected-catalogs', selectedCatalogs);
	}, [selectedCatalogs]);

	const store = {
		//Global States Helper Values:
		annotateTerms, setAnnotateTerms,
		annotationPanelOpen, setAnnotationPanelOpen,
		categoryOn, setCategoryOn,
		compareVersionParam,
		currentSearch, searchPhrase, setSearchPhrase,
		pageURLWhereSearchInnitiated, setPageURLWhereSearchInnitiated,
		debugLabelsAndMT,
		downloadPanelOpen, setDownloadPanelOpen,
		bookmarkOpen, setBookmarkOpen,
		hasGottenCorrectTierValue,
		hasVerifiedIndustry,
		isCatalogPage,
		isFolioPage,
		isSearchPage,
		isJournalPage,
		isJournalListing,
		isRelatedLinksPage,
		isFolioSectionlisting,
		isSpinePage,
		isWormholeActive,
		footerLinks,
		pageDescription, setPageDescription,
		pageTitle, setPageTitle,
		selectedCatalogs, setSelectedCatalogs,
		selectedLanguageObject, //--> context.selectedLanguageObject !!

		//Global Access Helpers:
		contextMemberFirm,
		getMemberFirmID,
		getMemberFirmIDs,
		getMemberFirmLanguages,
		getMemberFirmDefaultLanguage,
		getMemberFirmSettings,
		isMemberFirmsLoaded,
		memberFirms,
		settings,
		sitesLabels,
		sitesLabelsLoaded,
		synonyms,

		//Replace these with context ... context.language / context.memberFirm / etc
		context,
		language,//: context.language,
		memberFirm,//: context.memberFirm,
		knowledgeDomain,
		pageControllerType,
		pageCollectionName,
		industry, setIndustry,
		industryVal,
		tier, setTier,
		tierVal,
		isNavigatedFromCollectionSearch,
		setIsNavigatedFromCollectionSearch,
		currentTier, setCurrentTier, currentIndustry, setCurrentIndustry,
		//MessageNotification
		notification, setNotification,
		setSitesLabelsLoaded,
		//ChatBot
		isResearchAssistantEnabled, setIsResearchAssistantEnabled,
		countryKDSettings, setCountryKDSettings,
		isUserProfileLoaded, setUserProfileLoaded,
		isCountryKDSettingsLoaded, setCountryKDSettingsLoaded,
		researchAssistantMemeberfirms,setResearchAssistantMemberfirms,
		userCountry, setUserCountry,
		//Language
		selectedLanguage, setSelectedLanguage,
		taxonomyMemberFirmContext,
		setTaxonomyMemberFirmContext,
		allIndustry,
		setAllIndustry,
		allTier,
		setAllTier,
		region
	}

	return (
		<PageContext.Provider value={store}>
			{children}
		</PageContext.Provider>
	)
}

export default PageContextProvider
