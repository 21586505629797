function getHashFromContext(pageContext) {
    return "guidance_GUID-8F9EF5B7-6C69-4ED0-9910-F77509AB2B18";
}

function getItemsArray(item) {
    if (Array.isArray(item) && item.length > 0) {
        return item.map(node => {
            let agg = [node];
            if (Array.isArray(node.subItems) && node.subItems.length) {
                agg = [...agg, getItemsArray(node.subItems).flat()];
            }
            return agg.flat();
        }).flat();
    }
    else if (item.hasOwnProperty('subItems') && item.subItems.length > 0) {
        return item.subItems.map(node => {
            let agg = [node];
            if (Array.isArray(node.subItems) && node.subItems.length) {
                agg = [...agg, getItemsArray(node.subItems).flat()];
            }
            return agg.flat();
        }).flat();
    }
    else if (item.hasOwnProperty('children') && item.children.length > 0) {
        return item.children.map(node => {
            let agg = [node];
            if (Array.isArray(node.children) && node.children.length > 0) {
                agg = [...agg, getItemsArray(node.children).flat()];
            }
            return agg.flat();
        }).flat();
    }
}

function getSelectedTocNodeByContext(root, pageContext) {
    if (!root) return null;
    if (root.isSelected) return root;

    const contextHash = getHashFromContext(pageContext);
    const flattenedHashes = getItemsArray(root);
    console.log("find context hash in root:", contextHash, flattenedHashes)
    
    const selectedNode = flattenedHashes.find(x => x.tocHash === contextHash);
    console.log("selected node by context hash:", selectedNode);
    return selectedNode ?? null;
}

function getSelectedTocNode(root) {
    if (!root) return null;
    if (root.isSelected) return root;

    const selectedNode = getItemsArray(root).find(x => x.isSelected);
    return selectedNode ?? null;
}

function getFlatTocArray(root) {
    if (!root) return null;

    if (root.hasOwnProperty('subItems')) {
        return getItemsArray(root);
    }

    // Filter out invalid tableOfContents content by checking if tableOfContents object has values or not
    Object.values(root).forEach(content => {
        content.tableOfContents = content.tableOfContents?.filter(tocContent => tocContent !== null);
    });

    const subTocs = Object.values(root)
        .filter(value => value.tableOfContents?.length > 0)
        .map(value => value.tableOfContents)
        .flat()
        .map(value => [value, ...getItemsArray(value)])
        .flat();

    return subTocs;
}


function buildTocHashes(root, hash = null) {
    function generateHashes(nodes, hash) {
        return nodes.map(node => {
            node.tocHash = `${hash}_${node.object_id || node.id}`;
          
            if (Array.isArray(node.subItems) && node.subItems.length > 0) {
                node.subItems = generateHashes(node.subItems, node.tocHash);
            }
            if (Array.isArray(node.children) && node.children.length > 0) {
                node.children = generateHashes(node.children, node.tocHash);
            }
            return node;
        })
    }

    if (!root) {
        return null;
    }

    root.tocHash = hash ? `${hash}_${root.id}` : root.id;       
    if (root.subItems) {
        root.subItems = generateHashes(root.subItems, root.tocHash);
    }
    return root;
}

function buildFolioTocHashes(root, hash = null, basePath = null) {
    function generateHashes(nodes, hash,url = null) {
        return nodes.map(node => {
            node.tocHash = `${hash}_${node.object_id || node.id}`;
            node.url = url !== null ? `${url}/${encodeURIComponent(node.title)}/${node.id}` : `${encodeURIComponent(node.title)}/${node.id}`;
            if (Array.isArray(node.subItems) && node.subItems.length > 0) {
                node.subItems = generateHashes(node.subItems, node.tocHash, node.url);
            }
            if (Array.isArray(node.children) && node.children.length > 0) {
                node.children = generateHashes(node.children, node.tocHash, node.url);
            }
            return node;
        })
    }

    if (!root) {
        return null;
    }

    root.tocHash = hash ? `${hash}_${root.id}` : root.id;    
    root.url = basePath !==null ? `${basePath}/${root.url}` : `${root.url}`;
    if (root.subItems) {
        root.subItems = generateHashes(root.subItems, root.tocHash,root.url);
    }
    return root;
}

function getTocBranchFromHash(root, selectedGuid) {
    if (!root) return null;
    if (!selectedGuid) return [root];

    function getFlatNodeArray(nodes) {
        return nodes.map(node => {
            let agg = [node];
            if (Array.isArray(node.subItems) && node.subItems.length) {
                agg = [...agg, getFlatNodeArray(node.subItems).flat()];
            }
            return agg.flat();
        });
    }

    const flatNodeArray = getFlatNodeArray(root.subItems).flat();
    flatNodeArray.unshift(root);

    const branchSegments = flatNodeArray.find(x => x.id === selectedGuid)?.tocHash.split('_');
    if (!branchSegments) {
        return [root];
    }
    const branch = branchSegments.map(guid => {
        return flatNodeArray.find(x => x.id === guid);
    })

    return branch;
}

function scrollParentToChild(parent, child) {
    if (parent !== undefined && parent !== null && child !== undefined && child !==null) {
        var parentRect = parent.getBoundingClientRect();
        var parentViewableArea = {
            height: parent.clientHeight,
            width: parent.clientWidth
        };
        var childRect = child.getBoundingClientRect();
        var isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);
        if (!isViewable) {
            const scrollTop = childRect.top - parentRect.top;
            const scrollBot = childRect.bottom - parentRect.bottom;
            if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
                parent.scrollTop += scrollTop;
            } else {
                parent.scrollTop += (scrollBot + 20);
            }
        }
    }
}

export default {
    getSelectedTocNode,
    getSelectedTocNodeByContext,
    getItemsArray,
    getFlatTocArray,
    buildTocHashes,
    buildFolioTocHashes,
    getTocBranchFromHash,
    scrollParentToChild
}