import React, { useState, createContext, useContext, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import UTILITIESHELPER from '../helpers/UtilitiesHelper'
import { logs } from '../helpers/log'

export const SearchResultContext = createContext()
export const useSearchResultContext = () => useContext(SearchResultContext)

const SearchResultContextProvider = ({ children }) => {
    const [searchResults, setSearchResults] = useState([]);
    const [filteredSearchResults, setFilteredSearchResults] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]);
    const [areSearchResultsLoading, setAreSearchResultsLoading] = useState(true);
    const [searchTimeStamp, setSearchTimeStamp] = useState(Date.now());
    const [tocControl, setTocControl] = useState([]);
    const [showCollectionSearchLimitNotification, setShowCollectionSearchLimitNotification] = useState(false);
    const [collectionSearchLimit, setCollectionSearchLimit] = useState(0);
    const [allButtonsOn, setAllButtonsOn] = useState(false);
    const [allButtonText, setAllButtonText] = useState(false);
    const [isTriggerAllOnOff, setIsTriggerAllOnOff] = useState(false);
    const [isShowAllOnOffButton, setIsShowAllOnOffButton] = useState(false);
    //TODO: @Anand - need to make sure that this time-stamp is in place preventing older resutls from overwriting newer results!
    // latestRetrieveSearchResultsTimeStampRef is used to prevent a previous search which takes longer than the most recent request
    // from overriding the latest request
    // It is different than searchTimeStamp, which is when the user submitted the latest search phraee and triggers 
    // the RetrieveSearchResults function (which itself updates latestRetrieveSearchResultsTimeStampRef)
    const latestRetrieveSearchResultsTimeStampRef = useRef(Date.now());
    const searchProps = {
        eventType: 'search',
        searchLocation: window.location.href,
        jobTitle: UTILITIESHELPER.getSessionStorage("Level"),
        memberFirm: UTILITIESHELPER.getSessionStorage("MemberFirm"),
        rpMemberFirm: UTILITIESHELPER.getLocalStorage("research-portal-member-firm")
    }
    let query = new URLSearchParams(useLocation().search);
    const tmeplaterepositories = ["68169717", "58035381", "68489711"];
    //Each time the searchResults change we will re-set filteredSearchResults
    //  that is what is presented (and filtered when required)
    useEffect(() => {
        setShowCollectionSearchLimitNotification(false);
        if (Array.isArray(searchResults) && searchResults.length > 0) {
            const searchPhrase = query?.get('searchPhrase');
            searchProps.searchText = searchPhrase;
            logs.event('search', searchProps);

            //fetch all the toc which is visible and updated "filterAppliedStateOn" property based on the value got from "AvtiveFilters"
            var tempTocControl = UpdateTocControlByActiveFilter();
            let pageControllerType = tempTocControl.length > 0 ? tempTocControl[0].pageControllerType : '';
            let resultArray = [];
            if (tempTocControl.length > 0) {

                tempTocControl.forEach((folioSubjectItemTOC) => {
                    if (folioSubjectItemTOC.filterAppliedStateOn && folioSubjectItemTOC.visible && folioSubjectItemTOC.resultCount > 0)
                        resultArray = [...resultArray, ...folioSubjectItemTOC.searchResult];
                });
                // Result Array which has Search results is not sorted against score instead sorted as per the TOC items list 
                // Sorting it with descending order of Score value
                UTILITIESHELPER.sortArrayDesc(resultArray, 'score');
            }
            else {
                searchResults.forEach(result => {
                    if (tempTocControl.length > 0) {

                        //check for folio
                        if (pageControllerType === 'folio' && ((result.document.publicationAuditWorkflowAccount
                            && result.document.publicationAuditWorkflowAccount.length > 0)
                            ||
                            (result.document.publicationAuditWorkflowNonAccount
                                && result.document.publicationAuditWorkflowNonAccount.length > 0))
                        ) {
                            let tempIndex = -1;
                            const isTemplateRepository = tmeplaterepositories.includes(result.document?.publicationType);

                            if (isTemplateRepository) {

                                if (result.document.auditWorkflowAccount && result.document.auditWorkflowAccount.length > 0) {
                                    tempIndex = tempTocControl.findIndex(tocIndex => result.document.auditWorkflowAccount.includes(tocIndex.id) && tocIndex.filterAppliedStateOn === true);
                                }
                                if (result.document.auditWorkflowNonAccount && result.document.auditWorkflowNonAccount.length > 0 && tempIndex === -1) {
                                    tempIndex = tempTocControl.findIndex(tocIndex => result.document.auditWorkflowNonAccount.includes(tocIndex.id) && tocIndex.filterAppliedStateOn === true);
                                }
                            }
                            else {
                                if (result.document.publicationAuditWorkflowAccount && result.document.publicationAuditWorkflowAccount.length > 0) {
                                    tempIndex = tempTocControl.findIndex(tocIndex => result.document.publicationAuditWorkflowAccount.includes(tocIndex.id) && tocIndex.filterAppliedStateOn === true);
                                }
                                if (result.document.publicationAuditWorkflowNonAccount && result.document.publicationAuditWorkflowNonAccount.length > 0 && tempIndex === -1) {
                                    tempIndex = tempTocControl.findIndex(tocIndex => result.document.publicationAuditWorkflowNonAccount.includes(tocIndex.id) && tocIndex.filterAppliedStateOn === true);
                                }
                            }
                            if (tempIndex > -1) {
                                resultArray.push(result);
                            }
                        }
                        else {//for standards, manuals, deloitte-communication and professional news
                            let tempIndex = tempTocControl.findIndex(tocIndex => (tocIndex.id === result.document.publicationObjectId || tocIndex.id === result.document.publicationId || tocIndex.id === result.document.publicationContextId) && tocIndex.filterAppliedStateOn === true);
                            if (tempIndex > -1) {
                                resultArray.push(result);
                            }
                        }
                    }
                    else {//in case if tempTocControl is not loaded, will display all the search result
                        resultArray.push(result);
                    }
                });
            }

            if (searchResults.length >= parseInt(collectionSearchLimit)) {
                setShowCollectionSearchLimitNotification(true);
            }
           
            setFilteredSearchResults(resultArray);
            setAreSearchResultsLoading(false);
        }
        if (tocControl.length > 0) {
            let hasVisibleTocs = tocControl.some(x => x.visible);
            if(hasVisibleTocs){
                setIsShowAllOnOffButton(true);
            }else{
                setIsShowAllOnOffButton(false);
            }
            const filtered = tocControl.filter(x => x.filterAppliedStateOn && x.visible);
            if (filtered.length > 0) {
                setAllButtonText(false);
            } else {
                setAllButtonText(true);
            }
        } //eslint-disable-next-line
    }, [searchResults, activeFilters, (tocControl.length > 0)]);

    function UpdateTocControlByActiveFilter() {
        var tempTocControl = tocControl;

        tempTocControl.forEach((item) => {
            var indexA = activeFilters.findIndex(filterItem => filterItem === item.id);
            if (indexA > -1) {
                item.filterAppliedStateOn = false;
            }
            else {
                item.filterAppliedStateOn = true;
            }
        })

        return tempTocControl;
    }
    //TODO: @Anand Need to add a listener here on the filter changes, so to re-set the filteredSearchResults!!




    const store = {
        searchResults,
        setSearchResults,
        filteredSearchResults,
        setFilteredSearchResults,
        activeFilters,
        setActiveFilters,
        areSearchResultsLoading,
        setAreSearchResultsLoading,
        searchTimeStamp,
        setSearchTimeStamp,
        latestRetrieveSearchResultsTimeStampRef,
        tocControl,
        setTocControl,
        showCollectionSearchLimitNotification,
        setCollectionSearchLimit,
        collectionSearchLimit,
        allButtonsOn,
        setAllButtonsOn,
        allButtonText,
        setAllButtonText,
        isTriggerAllOnOff,
        setIsTriggerAllOnOff,
        isShowAllOnOffButton,
        setIsShowAllOnOffButton
    }

    return (
        <SearchResultContext.Provider value={store}>
            {children}
        </SearchResultContext.Provider>
    )
}

export default SearchResultContextProvider