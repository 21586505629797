
import React, { useState, useEffect} from 'react';
import Select, { components } from 'react-select';
import WindowedSelect from 'react-windowed-select';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { useThemeContext } from '../../contexts/ThemeContext';


const white = '#ffffff';
const gray = '#e8e8e8';

export const menuIsOpenStyle = (state, openStyle, closedStyle) => {
    return state.selectProps.menuIsOpen ? openStyle : closedStyle
}

const ReactSelectDropdown = ({
    options,
    styles,
    placeholder,
    menuIsOpen,
    selectedDisplayValue,
    defaultValue,
    onAfterChange,
    renderOptionsAsLinks,
    handleClick,
    shouldUseWindowedSelect,
    classNamePrefix,
    onKeyDown,
    getIsOptionSelected,
    customComponents,
    isSearchable,
    classNameContainer,
}) => {
    const [selectedOption, setSelectedOption] = useState(defaultValue);
    const { isDarkMode } = useThemeContext();

    useEffect(() => {
        onAfterChange(selectedOption)
    }, [selectedOption,onAfterChange])




    const customStyles = {
        placeholder: (provided, state) => ({
            ...provided,
			color: '#a3a3a3',
            maxWidth: 'calc(100% - 32px)',
            overflow: 'hidden',
            position: 'absolute',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...styles(provided, state).placeholder,
        }),
        input: (provided, state) => ({
            ...provided,
            color: isDarkMode ? gray : '#000000',
        }),
        option: (provided, state) => ({
            ...provided,
            background: isDarkMode ? state.isSelected ? 'rgba(255, 255, 255, 0.87)' : '#212121' : state.isSelected ? '#f5f5f5' : white,
            padding: '8px 16px !important',
            color: 'inherit',
            transition: 'all 0.2s ease',
            cursor: state.isDisabled ? 'not-allowed' : 'pointer !important',
            "&:hover": {
                ...provided,
                background: isDarkMode ? 'rgba(255, 255, 255, 0.87)' : '#f5f5f5',
                color: isDarkMode ? 'rgba(0, 0, 0, 0.87)' : '#000000',
                '&:active': {
                    background: isDarkMode ? 'rgba(255, 255, 255, 0.87)' : '#f5f5f5',
                },
            },
            '&:active': {
                ...provided[':active'],
                backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.87)' : '#f5f5f5',
            },
            ...styles(provided, state).option,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            ...styles(provided, state).valueContainer,
        }),

        singleValue: (provided, state) => ({
            ...provided,
            paddingRight: 21,
			color: isDarkMode ? '#ffffff' : '#000000',
            position: 'relative',
            transform: 'none',
            maxWidth: '100%',
            ...styles(provided, state).singleValue,
        }),
        container: (provided, state) => ({
            ...provided,
            fontSize: 14,
            flexGrow: 1,
            ...styles(provided, state)?.container,
        }),
        control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            transition: '0.2s border-color',
            height: '100%',
            minHeight: 37,
            borderRadius: 4,
            borderBottomLeftRadius: menuIsOpenStyle(state, 0),
            borderBottomRightRadius: menuIsOpenStyle(state, 0),
            boxShadow: "none",
            border: `1px solid ${isDarkMode ? '#757575' : '#d9d9d9'}`,
            padding: '0 8px !important',
            backgroundColor: menuIsOpenStyle(state, isDarkMode ? '#212121' : '#ffffff'),
            width: '100%',
            backgroundSize: '20px 20px',
            backgroundImage: isDarkMode ? 'url(/images/dropdown-arrow-default-darkmode.svg)' :'url(/images/dropdown-arrow-gray.svg)',
            backgroundPosition: 'calc(100% - 10px) center',
            backgroundRepeat: 'no-repeat',
			"&:hover": {
                color: 'inherit'
            },
            ...styles(provided, state).control,
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            padding: 0,
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        dropdownIndicator: (provided, state) => {
            return ({
                display: 'none',
            })
        },
        menu: (provided, state) => {
            return ({
                ...provided,
                margin: 'auto',
                marginTop: 0,
                boxShadow: 'none',
                border: `1px solid ${isDarkMode ? '#757575' : '#d9d9d9'}`,
                borderRadius: 4,
                borderBottomLeftRadius: shouldUseWindowedSelect ? 2 : '',
                borderBottomRightRadius: shouldUseWindowedSelect ? 2 : '',
                backgroundColor: isDarkMode ? '#212121' : 'white',
                ...styles(provided, state).menu,
            })
        },
        menuList: (provided, state) => {
            return ({
                ...provided,
                maxHeight: 225,
                "::-webkit-scrollbar": {
                    width: 8,
					background: isDarkMode ? '#212121' : '#ffffff'
                },
                "::-webkit-scrollbar-track": {
                    background: isDarkMode ? '#212121' : '#ffffff'
                },
                "::-webkit-scrollbar-thumb": {
                    background: isDarkMode? 'rgba(255, 255, 255, 0.54)' : '#e0e0e0',
					borderRadius: 4
                },
                ...styles(provided, state).menuList,
                ...styles(provided, state)?.menuList,

            })
        },
        group: (provided, state) => ({
            ...provided,
            ...styles(provided, state)?.group,
        }),
        groupHeading: (provided, state) => ({
            ...provided,
            ...styles(provided, state)?.groupHeading,
        })
    }

    const SingleValue = ({ children, ...props }) => {

        return (
            <components.SingleValue {...props}>
                {selectedDisplayValue(props.data)}
            </components.SingleValue>
        )
    };

    const MenuList = ({ children, ...props }) => {
        return (
            <components.MenuList {...props} className="menu-list">
                {children}
            </components.MenuList>
        )
    };

    const Option = ({ children, ...props }) => {
        const isSelected = getIsOptionSelected(props)
        if (!renderOptionsAsLinks || props.data.isDisabled || !props.data.href) {
            return (
                <components.Option {...props}
                    isSelected={isSelected}
                >
                    {children}
                </components.Option>
            )
        }

        return (
            <Link to={props.data.href}>
                <components.Option className="react-select-option" {...props} isSelected={isSelected}>
                    {children}
                </components.Option>
            </Link>
        )
    };


    const selectProps = {
        placeholder,
        isOptionDisabled: (option) => option.isDisabled,
        onChange: (option) => {
            return renderOptionsAsLinks ? handleClick(option) : setSelectedOption(option)
        },
        options,
        styles: customStyles,
        isSearchable,
        components: { SingleValue, Option, MenuList, ...customComponents },
        defaultValue,
        className: cx("dropdown-container", classNameContainer),
        classNamePrefix,
        onKeyDown,
        menuIsOpen,
    }

    // React-Windowed-Select is used to solve the issue of lagginess that happens when React-Select has too many options
    if (shouldUseWindowedSelect) {
        return (
            <WindowedSelect {...selectProps} />
        )
    }

    return (
        <Select {...selectProps} />
    )
}

ReactSelectDropdown.defaultProps = {
    options: [],
    styles: () => ({
        container: undefined,
        control: undefined,
        menu: undefined,
        menuList: undefined,
        valuesContainer: undefined,
        option: undefined,
    }),
    placeholder: '',
    selectedDisplayValue: (value) => typeof (value) === 'string' ? value : value?.value,
    onAfterChange: () => { },
    handleClick: () => { },
    getIsOptionSelected: () => { },
    customComponents: {},
    isSearchable: false,
}

export default ReactSelectDropdown;
