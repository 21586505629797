import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { logs, page } from '../helpers/log';

function useOPMService() {
    const { apiCall } = useFetchWithMsal();
    const DCSEndPoint = window.DART.ENV.REACT_APP_DCS_ENDPOINT_API;
    const customheaders = { "INK_CONTENT_PRERELEASE": (window.DART.ENV.RP_REACT_INK_CONTENT_PRERELEASE ? window.DART.ENV.RP_REACT_INK_CONTENT_PRERELEASE : window.DART.ENV.RP_REACT_APP_PREVIEW) };

    async function getOpmInfo() {
        let request = {
            url: `${DCSEndPoint}/OPM/opminfo`,
            method: 'GET',
            mode: 'cors',

        }
       
        //retrieve file and trigger download on user's side
        //Added a try catch and await ,since Promise  .then is not resolving here 
        var response=null;
        try {
             response = await apiCall(request, customheaders);
            
        }
        catch {
            logs.error(page.DCSService, 'RPService', "Error in getOpmInfo  api", request.url);
        }
        finally {
            return response;
        }
    
    };

    return {getOpmInfo};
};

export default useOPMService;