import React from 'react';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';

export const AssociatedContent = (props) => {

    //var resource = ARTICLEHELPER.extractAnnotationOrAssociatedContent(props.item, props.publication);
    
    if (props.item.content) {
        //var resource = ARTICLEHELPER.extractAnnotationOrAssociatedContent(props.item, props.publication);
        var resource = props.item.content.associations;
        if (!UTILITIESHELPER.isObjectNullorEmpty(resource) && !UTILITIESHELPER.isObjectNullorEmpty(resource.associated_content)) {
            var associations =  resource.associated_content.filter(function (item) {
                return  item.links.length > 0;
            });

            if (associations && associations.length > 0) {
                return (
                    associations.map((association,index) => (
                            <div className="associated-content">
                            <p className="associated-content-title"><strong>{association.lead_in_text}</strong></p>
                                <ul>
                                {association.links.map((link) => (<li key={index}><a href={link.link_target} onClick={(e) => e.preventDefault()}>{link.link_text}</a></li>))}
                                </ul>
                            </div>
                    ))
                );
            }
            else {
                return (null);
            }
        }
        else {
            return (null);
        }
    }
    else {
        return (null);
    }
}

AssociatedContent.propsTypes = {
    typeName: "AssociatedContent"
}

export default AssociatedContent;