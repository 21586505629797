import React, { createContext, useContext, useEffect, useState} from "react";
import UTILITIESHELPER from "../helpers/UtilitiesHelper";

export const RecentlyViewContext = createContext()
export const useRecentViewContext = () => useContext(RecentlyViewContext)
const RecentViewContextProvider = ({ children }) => {
    const [ recentViewHistory, setRecentViewHistory ] = useState({})

    useEffect(() => {
        setRecentViewHistory(JSON.parse(UTILITIESHELPER.getLocalStorage('recent-view-history')))
    }, [])

    const store = {
        recentViewHistory,
        setRecentViewHistory
    }
    return (
        <RecentlyViewContext.Provider value={store}>
            {children}
        </RecentlyViewContext.Provider>
    )
}

export default RecentViewContextProvider