import React, { useState, useRef } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import useOutsideClick from "../../hooks/useOutsideClick";

function TriggerEl({ isOpenContainer, title }) {
  const compareVersionRef = useRef(null);

  useOutsideClick(compareVersionRef, () => {
    isOpenContainer(false);
  });

  function onClickHanlder() {
    isOpenContainer((prevVal) => !prevVal);
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      isOpenContainer((prevVal) => !prevVal);
    }
  };

  return (
    <div tabIndex="0" onKeyPress={handleKeyPress} onClick={onClickHanlder}>
      <button
        ref={compareVersionRef}
        type="button"
        tabIndex="-1"
        className={cx("dd__collection-trigger dd__trigger-button article-version-trigger")}
      >
        <span className="dd__trigger-label version-label" tabIndex="-1">
          {title}
        </span>
      </button>
    </div>
  );
}

const ArticleCompareVersionDropdown = ({ options, compareVersionParam }) => {
  const [isOpen, setIsOpen] = useState(false);

  const title = options.filter((item) => {
    return item.value === compareVersionParam;
  })[0].label;

  function VersionsItem({ data, active = false }) {
    return (
      <Link
        to={data.href}
        tabIndex="-1"
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            window.location.href = data.href;
          }
        }}
      >
        <div
          className={
            "collection-menu-option article-version-option " +
            (active ? "option-active" : "")
          }
          tabIndex="0"
        >
          {data.label}
        </div>
      </Link>
    );
  }

  const OptionsList = () => {
    return options.map((item) => (
      <VersionsItem
        data={item}
        key={item.label}
        active={item.value === compareVersionParam}
      />
    ));
  };

  function getContainerShowVal(isShow) {
    setIsOpen(isShow);
  }

  return (
    <div className="dd">
      <TriggerEl isOpenContainer={getContainerShowVal} title={title} />
      {isOpen && (
        <div
          className="dd__container collection-menu article-version-menu"
          tabIndex="-1"
        >
          <div className="collection-menu-list" tabIndex="-1">
            <OptionsList />
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleCompareVersionDropdown;
