import React from 'react';

const HandleError = ( props ) => {

  //console.log("HandleError::error",props.error);

  return (
      <section className="error-container">
        <h1>{props.errorHeading}</h1>
      
        <p>An error has occurred. We apologize for any inconvenience.</p>
        <p><em>{props.errorMessage}</em></p>
      </section>
  )
  
};

export default HandleError;