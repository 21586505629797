import React, {  useRef } from 'react';
import cx from 'classnames';
import useOutsideClick from '../../hooks/useOutsideClick';
import { usePageContextLabels } from '../../contexts/PageContext';
import LINKHELPERS from '../../helpers/LinkHelpers';

const RedirectModal = ({
    shouldShowRedirectModal,
    setShouldShowRedirectModal,
    shouldShowOverlay,
    setShouldShowOverlay,
    redirectURL,
    messageRedirectTL
}) => {
    const redirectModalRef = useRef();
    const { getLabel } = usePageContextLabels();

    function closeModal() {
        if(shouldShowRedirectModal){
            setShouldShowOverlay(false);
            setShouldShowRedirectModal(false);
            document.body.style.overflow = 'unset';    
        }
    }

    function openInNewTab(url, target = "_blank") {
        var win = window.open(LINKHELPERS.sanitizeUrl(url), target);
        win.focus();
    }

    function redirectConrifmed() {
        closeModal();
        openInNewTab(redirectURL);
    }    

    if (shouldShowRedirectModal === true) {
        document.body.style.overflow = 'hidden';
    }

    useOutsideClick(redirectModalRef, closeModal)

    return (
        <div>
            {shouldShowOverlay &&
                <div onClick={closeModal} className='full-screen-darken-overlay-fixed' />
            }
            <div ref={redirectModalRef} className={cx("redirect-modal", { 'hide': !shouldShowRedirectModal })}>
                <div className="redirect-modal-header">
                    <span className="warning">&nbsp;</span> {getLabel("l_LinkModalExternalLink", "External Link")}
                    <button className="redirect-modal-close" onClick={closeModal} title="Close"></button>
                </div>
                <div className="redirect-modal-body">
                    <p>{messageRedirectTL}</p>
                </div>
                <div align="right">
                    <button
                        className="btn btn-secondary btn-redirect-no"
                        onClick={closeModal}
                        title={getLabel("g_No", "No")}
                    >
                        {getLabel("g_No", "No")}
                    </button>
                    <button
                        className="btn btn-primary btn-redirect-yes"
                        onClick={redirectConrifmed}
                        title={getLabel("g_Yes", "Yes")}
                    >
                        {getLabel("g_Yes", "Yes")}
                    </button>
                </div>
            </div>
        </div>
    )
}

RedirectModal.defaultProps = {
    setShouldShowRedirectModal: () => { },
    setShouldShowOverlay: () => { },
}

export default RedirectModal;
