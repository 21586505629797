import UTILITIESHELPER from "./UtilitiesHelper";

export const AnnotationHighlighter = (
  annotationRange,
  element,
  annotationColor,
  prefId
) => {
  var /**
     * Attribute added by default to every highlight.
     * @type {string}
     */
    DATA_ATTR = "data-highlighted",
    /**
     * Attribute used to group highlight wrappers.
     * @type {string}
     */
    TIMESTAMP_ATTR = "data-timestamp",
    NODE_TYPE = {
      ELEMENT_NODE: 1,
      TEXT_NODE: 3,
    },
    /**
     * Don't highlight content of these tags.
     * @type {string[]}
     */
    IGNORE_TAGS = [
      "SCRIPT",
      "STYLE",
      "SELECT",
      "OPTION",
      "BUTTON",
      "OBJECT",
      "APPLET",
      "VIDEO",
      "AUDIO",
      "CANVAS",
      "EMBED",
      "PARAM",
      "METER",
      "PROGRESS",
    ];

  /**
   * Returns true if elements a i b have the same color.
   * @param {Node} a
   * @param {Node} b
   * @returns {boolean}
   */
  function haveSameColor(a, b) {
    return dom(a).color() === dom(b).color();
  }

  /**
   * Fills undefined values in obj with default properties with the same name from source object.
   * @param {object} obj - target object
   * @param {object} source - source object with default values
   * @returns {object}
   */
 

  /**
   * Returns array without duplicated values.
   * @param {Array} arr
   * @returns {Array}
   */
  function unique(arr) {
    return arr.filter(function(value, idx, self) {
      return self.indexOf(value) === idx;
    });
  }

  var el = element;

  var highlightOptions = {
    color: annotationColor,
    highlightedClass: "highlighted",
    contextClass: "highlighter-context",
    onRemoveHighlight: function() {
      return true;
    },
    onBeforeHighlight: function() {
      return true;
    },
    onAfterHighlight: function() {},
  };

  /**
   * Utility functions to make DOM manipulation easier.
   * @param {Node|HTMLElement} [el] - base DOM element to manipulate
   * @returns {object}
   */
  var dom = function(el) {
    return /** @lends dom **/ {
      /**
       * Adds class to element.
       * @param {string} className
       */
      addClass: function(className) {
        if (el.classList) {
          el.classList.add(className);
        } else {
          el.className += " " + className;
        }
      },

      /**
       * Removes class from element.
       * @param {string} className
       */
      removeClass: function(className) {
        if (el.classList) {
          el.classList.remove(className);
        } else {
          el.className = el.className.replace(
            new RegExp("(^|\\b)" + className + "(\\b|$)", "gi"),
            " "
          );
        }
      },

      /**
       * Prepends child nodes to base element.
       * @param {Node[]} nodesToPrepend
       */
      prepend: function(nodesToPrepend) {
        var nodes = Array.prototype.slice.call(nodesToPrepend),
          i = nodes.length;

        while (i--) {
          el.insertBefore(nodes[i], el.firstChild);
        }
      },

      /**
       * Appends child nodes to base element.
       * @param {Node[]} nodesToAppend
       */
      append: function(nodesToAppend) {
        var nodes = Array.prototype.slice.call(nodesToAppend);

        for (var i = 0, len = nodes.length; i < len; ++i) {
          el.appendChild(nodes[i]);
        }
      },

      /**
       * Inserts base element after refEl.
       * @param {Node} refEl - node after which base element will be inserted
       * @returns {Node} - inserted element
       */
      insertAfter: function(refEl) {
        return refEl.parentNode.insertBefore(el, refEl.nextSibling);
      },

      /**
       * Inserts base element before refEl.
       * @param {Node} refEl - node before which base element will be inserted
       * @returns {Node} - inserted element
       */
      insertBefore: function(refEl) {
        return refEl.parentNode.insertBefore(el, refEl);
      },

      /**
       * Removes base element from DOM.
       */
      remove: function() {
        el.parentNode.removeChild(el);
        el = null;
      },

      /**
       * Returns true if base element contains given child.
       * @param {Node|HTMLElement} child
       * @returns {boolean}
       */
      contains: function(child) {
        return el !== child && el.contains(child);
      },

      /**
       * Wraps base element in wrapper element.
       * @param {HTMLElement} wrapper
       * @returns {HTMLElement} wrapper element
       */
      wrap: function(wrapper) {
        if (el.parentNode) {
          el.parentNode.insertBefore(wrapper, el);
        }

        wrapper.appendChild(el);
        return wrapper;
      },

      /**
       * Unwraps base element.
       * @returns {Node[]} - child nodes of unwrapped element.
       */
      unwrap: function() {
        var nodes = Array.prototype.slice.call(el.childNodes),
          wrapper;

        nodes.forEach(function(node) {
          wrapper = node?.parentNode;
          dom(node).insertBefore(node?.parentNode);
          dom(wrapper).remove();
        });

        return nodes;
      },

      /**
       * Returns array of base element parents.
       * @returns {HTMLElement[]}
       */
      parents: function() {
        var parent,
          path = [];

        while (!!(parent = el.parentNode)) {
          path.push(parent);
          el = parent;
        }

        return path;
      },

      /**
       * Normalizes text nodes within base element, ie. merges sibling text nodes and assures that every
       * element node has only one text node.
       * It should does the same as standard element.normalize, but IE implements it incorrectly.
       */
      normalizeTextNodes: function() {
        if (!el) {
          return;
        }

        if (el.nodeType === NODE_TYPE?.TEXT_NODE) {
          while (
            el.nextSibling &&
            el.nextSibling.nodeType === NODE_TYPE?.TEXT_NODE
          ) {
            el.nodeValue += el.nextSibling.nodeValue;
            el.parentNode.removeChild(el.nextSibling);
          }
        } else {
          dom(el.firstChild).normalizeTextNodes();
        }
        dom(el.nextSibling).normalizeTextNodes();
      },

      /**
       * Returns element background color.
       * @returns {CSSStyleDeclaration.backgroundColor}
       */
      color: function() {
        return el.style.backgroundColor;
      },

      /**
       * Creates dom element from given html string.
       * @param {string} html
       * @returns {NodeList}
       */
      fromHTML: function(html) {
        var div = document.createElement("div");
        div.innerHTML = html;
        return div.childNodes;
      },

      /**
       * Returns first range of the window of base element.
       * @returns {Range}
       */
      getRange: function() {
        var selection = dom(el).getSelection(),
          range;

        if (selection.rangeCount > 0) {
          range = selection.getRangeAt(0);
        }

        return range;
      },

      /**
       * Removes all ranges of the window of base element.
       */
      removeAllRanges: function() {
        var selection = dom(el).getSelection();
        selection.removeAllRanges();
      },

      /**
       * Returns selection object of the window of base element.
       * @returns {Selection}
       */
      getSelection: function() {
        return dom(el)
          .getWindow()
          .getSelection();
      },

      /**
       * Returns window of the base element.
       * @returns {Window}
       */
      getWindow: function() {
        return dom(el).getDocument().defaultView;
      },

      /**
       * Returns document of the base element.
       * @returns {HTMLDocument}
       */
      getDocument: function() {
        // if ownerDocument is null then el is the document itself.
        return el.ownerDocument || el;
      },
    };
  };

  /**
   * Highlights current range.
   * @param {boolean} keepRange - Don't remove range after highlighting. Default: false.
   * @memberof TextHighlighter
   */

  var range = annotationRange,
    wrapper,
    createdHighlights,
    normalizedHighlights, //eslint-disable-line
    timestamp;

  if (!range || range.collapsed) {
    return;
  }

  if (highlightOptions.onBeforeHighlight(range) === true) {
    timestamp = +new Date();
    wrapper = createWrapper(highlightOptions);
    wrapper.setAttribute(TIMESTAMP_ATTR, timestamp);
    createdHighlights = highlightRange(range, wrapper);
    normalizedHighlights = normalizeHighlights(createdHighlights);
  }

  /**
   * Returns fore color of the highlighted text based on background color.
   * @param {bgColor} - background color in hexadecimal string.
   * @returns rgb
   */
  function textColor(bgColor) {
    var threshold = 130;

    var hRed = hexToR(bgColor);
    var hGreen = hexToG(bgColor);
    var hBlue = hexToB(bgColor);


    function hexToR(h) { return parseInt((cutHex(h)).substring(0, 2), 16) }
    function hexToG(h) { return parseInt((cutHex(h)).substring(2, 4), 16) }
    function hexToB(h) { return parseInt((cutHex(h)).substring(4, 6), 16) }
    function cutHex(h) { return (h.charAt(0) === "#") ? h.substring(1, 7) : h }

    var cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
    if (cBrightness > threshold) { return "#000000"; } else { return "#ffffff"; }
  }

  /**
   * Creates wrapper for highlights.
   * TextHighlighter instance calls this method each time it needs to create highlights and pass options retrieved
   * in constructor.
   * @param {object} options - the same object as in TextHighlighter constructor.
   * @returns {HTMLElement}
   * @static
   */
  function createWrapper(options) {
    var foreColor = textColor(options.color);
    var mark = document.createElement("mark");
    mark.style.backgroundColor = options.color;
    mark.style.color = foreColor;
    mark.className = options.highlightedClass;
    mark.id=prefId;
    return mark;
  }

  /**
   * Highlights range.
   * Wraps text of given range object in wrapper element.
   * @param {Range} range
   * @param {HTMLElement} wrapper
   * @returns {Array} - array of created highlights.
   */
  function highlightRange(range, wrapper) {
    if (!range || range.collapsed) {
      return [];
    }

    var result = refineRangeBoundaries(range),
      startContainer = result.startContainer,
      endContainer = result.endContainer,
      goDeeper = result.goDeeper,
      done = false,
      node = startContainer,
      highlights = [],
      highlight,
      wrapperClone,
      nodeParent;
    do {
      if (goDeeper && node?.nodeType === NODE_TYPE?.TEXT_NODE) {
        if (
          IGNORE_TAGS.indexOf(node?.parentNode.tagName) === -1 &&
          node?.nodeValue.trim() !== ""
        ) {
          wrapperClone = wrapper.cloneNode(true);
          wrapperClone.setAttribute(DATA_ATTR, true);
          nodeParent = node?.parentNode;

          // highlight if a node is inside the el
          if (dom(el).contains(nodeParent) || nodeParent === el) {
            highlight = dom(node).wrap(wrapperClone);
            highlights.push(highlight);
          }
        }

        goDeeper = false;
      }
      if (
        node === endContainer &&
        !(endContainer.hasChildNodes() && goDeeper)
      ) {
        done = true;
      }

      if (node?.tagName && IGNORE_TAGS.indexOf(node?.tagName) > -1) {
        if (endContainer.parentNode === node) {
          done = true;
        }
        goDeeper = false;
      }
      if (goDeeper && node?.hasChildNodes()) {
        node = node?.firstChild;
      } else if (node?.nextSibling) {
        node = node?.nextSibling;
        goDeeper = true;
      } else {
        node = node?.parentNode;
        goDeeper = false;
      }
    } while (!done);

    return highlights;
  }

  /**
   * Takes range object as parameter and refines it boundaries
   * @param range
   * @returns {object} refined boundaries and initial state of highlighting algorithm.
   */
  function refineRangeBoundaries(range) {
    var startContainer = range.startContainer,
      endContainer = range.endContainer,
      ancestor = range.commonAncestorContainer,
      goDeeper = true;

    if (range.endOffset === 0) {
      while (
        !endContainer.previousSibling &&
        endContainer.parentNode !== ancestor
      ) {
        endContainer = endContainer.parentNode;
      }
      endContainer = endContainer.previousSibling;
    } else if (endContainer.nodeType === NODE_TYPE?.TEXT_NODE) {
      if (range.endOffset < endContainer.nodeValue.length) {
        endContainer.splitText(range.endOffset);
      }
    } else if (range.endOffset > 0) {
      endContainer = endContainer.childNodes.item(range.endOffset - 1);
    }

    if (startContainer.nodeType === NODE_TYPE?.TEXT_NODE) {
      if (range.startOffset === startContainer.nodeValue.length) {
        goDeeper = false;
      } else if (range.startOffset > 0) {
        startContainer = startContainer.splitText(range.startOffset);
        if (endContainer === startContainer.previousSibling) {
          endContainer = startContainer;
        }
      }
    } else if (range.startOffset < startContainer.childNodes.length) {
      startContainer = startContainer.childNodes.item(range.startOffset);
    } else {
      startContainer = startContainer.nextSibling;
    }

    return {
      startContainer: startContainer,
      endContainer: endContainer,
      goDeeper: goDeeper,
    };
  }

  /**
   * Sorts array of DOM elements by its depth in DOM tree.
   * @param {HTMLElement[]} arr - array to sort.
   * @param {boolean} descending - order of sort.
   */
  function sortByDepth(arr, descending) {
    arr.sort(function(a, b) {
      return (
        dom(descending ? b : a).parents().length -
        dom(descending ? a : b).parents().length
      );
    });
  }

  /**
   * Normalizes highlights. Ensures that highlighting is done with use of the smallest possible number of
   * wrapping HTML elements.
   * Flattens highlights structure and merges sibling highlights. Normalizes text nodes within highlights.
   * @param {Array} highlights - highlights to normalize.
   * @returns {Array} - array of normalized highlights. Order and number of returned highlights may be different than
   * input highlights.
   */
  function normalizeHighlights(highlights) {
    let normalizedHighlights;

    flattenNestedHighlights(highlights);
    //mergeSiblingHighlights(highlights);

    // omit removed nodes
    normalizedHighlights = highlights.filter(function(hl) {
      return hl.parentElement ? hl : null;
    });

    normalizedHighlights = unique(normalizedHighlights);
    normalizedHighlights.sort(function(a, b) {
      return a.offsetTop - b.offsetTop || a.offsetLeft - b.offsetLeft;
    });

    return normalizedHighlights;
  }

  /**
   * Flattens highlights structure.
   * Note: this method changes input highlights - their order and number after calling this method may change.
   * @param {Array} highlights - highlights to flatten.
   */
  function flattenNestedHighlights(highlights) {
    var again
      //,self = this
      ;

    sortByDepth(highlights, true);

    function flattenOnce() {
      var again = false;

      highlights.forEach(function(hl, i) {
        var parent = hl.parentElement,
          parentPrev = parent.previousSibling,
          parentNext = parent.nextSibling;

        if (isHighlight(parent)) {
          if (!haveSameColor(parent, hl)) {
            if (!hl.nextSibling) {
              dom(hl).insertBefore(parentNext || parent);
              again = true;
            }

            if (!hl.previousSibling) {
              dom(hl).insertAfter(parentPrev || parent);
              again = true;
            }

            if (!parent.hasChildNodes()) {
              dom(parent).remove();
            }
          } else {
            parent.replaceChild(hl.firstChild, hl);
            highlights[i] = parent;
            again = true;
          }
        }
      });

      return again;
    }

    do {
      again = flattenOnce();
    } while (again);
  }

  /**
   * Merges sibling highlights and normalizes descendant text nodes.
   * Note: this method changes input highlights - their order and number after calling this method may change.
   * @param highlights
   */
  /*function mergeSiblingHighlights(highlights) {
    //var self = this;

    function shouldMerge(current, node) {
      return (
        node &&
        node?.nodeType === NODE_TYPE?.ELEMENT_NODE &&
        haveSameColor(current, node) &&
        isHighlight(node)
      );
    }

    highlights.forEach(function(highlight) {
      var prev = highlight.previousSibling,
        next = highlight.nextSibling;

      if (shouldMerge(highlight, prev)) {
        dom(highlight).prepend(prev.childNodes);
        dom(prev).remove();
      }
      if (shouldMerge(highlight, next)) {
        dom(highlight).append(next.childNodes);
        dom(next).remove();
      }

      dom(highlight).normalizeTextNodes();
    });
  }
*/ 
  /**
   * Returns true if element is a highlight.
   * All highlights have 'data-highlighted' attribute.
   * @param el - element to check.
   * @returns {boolean}
   */
  function isHighlight(el) {
    return (
      el && el.nodeType === NODE_TYPE?.ELEMENT_NODE && el.hasAttribute(DATA_ATTR)
    );
  }
};

/**
 * Rebuilds and returns the range
 * @param rangePos - element with range start and end.
 * @returns range
 */
export function rebuildRange(rangePos) {
  var containerEl = document.getElementsByClassName(
    "article-content-container"
  )[0];
  var charIndex = 0,
    range = document.createRange();
  range.setStart(containerEl, 0);
  range.collapse(true);
  var nodeStack = [containerEl],
    node,
    foundStart = false,
    stop = false;

  while (!stop && (node = nodeStack.pop())) {
    if (node?.nodeType === 3) {
      var nextCharIndex = charIndex + node?.length;
      if (
        !foundStart &&
        rangePos.start >= charIndex &&
        rangePos.start <= nextCharIndex
      ) {
        range.setStart(node, rangePos.start - charIndex);
        foundStart = true;
      }
      if (
        foundStart &&
        rangePos.end >= charIndex &&
        rangePos.end <= nextCharIndex
      ) {
        range.setEnd(node, rangePos.end - charIndex);
        stop = true;
      }
      charIndex = nextCharIndex;
    } else {
      var i = node?.childNodes.length;
      while (i--) {
        nodeStack.push(node?.childNodes[i]);
      }
    }
  }
  return range;
}

/**
 * Gets text length
 * @param start - starting position of range.
 * @param end - ending position of range.
 * @returns textLength
 */
function getTextLength(start, end) {
  var textLength;
  var rangePos = {
    start: start,
    end: end,
  };
  var selRange = rebuildRange(rangePos);
  var sel = document.getSelection();
  sel.removeAllRanges();
  sel.addRange(selRange);
  textLength = document.getSelection().toString().length;
  return textLength;
}

/**
 * Gets Range Position of text to be highlighted
 * @param preSelectionRange - cloned range.
 * @param range - actual range.
 * @param selectedTextLength - initial range of selected text.
 * @returns range position of text to be highlighted
 */
export function rangeStartEndPosition(
  preSelectionRange,
  range,
  selectedTextLength
) {
  let rangePosition;
  var element = document.getElementsByClassName("article-content-container")[0];

  preSelectionRange.selectNodeContents(element);
  preSelectionRange.setEnd(range.startContainer, range.startOffset);
  var start = preSelectionRange.toString().length;
  var end = start + range.toString().length;
  if (selectedTextLength > 1000) {
    end = start + 1000;
    var textLength = getTextLength(start, end);
    while (textLength < 1000) {
      let textSelLength;
      end = end + 1;
       textSelLength = getTextLength(start, end);
      if (textSelLength === 1000) {
        break;
      }
    }
  }

  rangePosition = {
    start: start,
    end: end,
  };

  return rangePosition;
}

function getElementRefrence(elementId){
  return document.getElementById(elementId);
}

// get the element selected for further annotation operations
function getDocumentSelection(elementReference){
  var divRange = document.createRange();
  var reannotateSelection = window.getSelection();
  divRange.selectNodeContents(elementReference);
  reannotateSelection.removeAllRanges();
  reannotateSelection.addRange(divRange);
  return { documentSelection :document.getSelection(),selectionRange: reannotateSelection};
}

export function getAnnCategoryColor(CategoryList, AnnotationCategory) {
    const objIndex = CategoryList.findIndex(
        (obj) => obj.id === AnnotationCategory
    );
    if (objIndex !== -1) {
        return CategoryList[objIndex].color;
    }
    return "";
}

export function setCustomSelection(ann) {
    var el = getElementRefrence(ann.closest_guid);
    if (el) {
        const selectedObject = getDocumentSelection(el)
        const selectedText = selectedObject.documentSelection;

        if (selectedText.rangeCount > 0) {
            // for regenerating the range of existing valid annotation
            var wholeArticleElement = document.getElementsByClassName(
                "article-content-container"
            )[0];
            const content = selectedText.toString();
            var exactContentMatch = content.includes(ann.content)
            if (exactContentMatch === true && wholeArticleElement.innerHTML.includes(ann.raw_content)) {
                //Using annotation id instead of closest guid for uniquily identifying the divs
                var customDiv = "<span id='rerangeAnnotation_" + ann.annotation_guid + "'>" + ann.raw_content + "</span>"
                wholeArticleElement.innerHTML = wholeArticleElement.innerHTML.replace(ann.raw_content, customDiv);
                var annotatedEl = getElementRefrence('rerangeAnnotation_' + ann.annotation_guid);

                // check if the exact element in the matched
                if (annotatedEl !== null && !UTILITIESHELPER.isStringNullorEmpty(ann.closest_guid)) {
                    const annotatedselectedTextObject = getDocumentSelection(annotatedEl);
                    const annotatedselectedText = annotatedselectedTextObject.documentSelection;
                    if (annotatedselectedText.rangeCount > 0) {
                        const regeneratedRange = annotatedselectedText.getRangeAt(0);
                        const annotationClonedContent = regeneratedRange.cloneContents();
                        let recheckedPreSelectionRange = regeneratedRange.cloneRange();
                        const tmpEl = document.createElement("div");
                        tmpEl.append(annotationClonedContent);
                        const rawContent = tmpEl.innerHTML;
                        const content = annotatedselectedText.toString();
                        const selectedTextLength = document.getSelection().toString().length;
                        var rangePosition = rangeStartEndPosition(
                            recheckedPreSelectionRange,
                            regeneratedRange,
                            selectedTextLength
                        );
                        let payload = {
                            raw_content: rawContent,
                            content: content,
                            range_position_start: rangePosition.start,
                            range_position_end: rangePosition.end,
                            //preSelectionRange: recheckedPreSelectionRange,
                            //selectedTextLength,
                            is_annotations_version_updated: true
                        }
                        selectedObject.selectionRange.removeAllRanges();
                        return payload;
                    }
                }
                // when the content matches but the raw_content does not match
                // that means we have the senario where the dom does not match 
                // and this annotation should be marked as deleted
                else {
                    selectedObject.selectionRange.removeAllRanges();
                    ann.is_annotations_version_updated = false;
                    ann.content = "";
                    return ann
                }
            } else {
                // highlight whole div where the exact content does not match
                //Highlight only 1000 character - as per the requirement
                const range = selectedText.getRangeAt(0) > 1000 ? 1000 : selectedText.getRangeAt(0);
                const cloned = range.cloneContents();
                var recheckedPreSelectionRange = range.cloneRange();
                const tmpEl = document.createElement("div");
                tmpEl.append(cloned);
                const rawContent = tmpEl.innerHTML;

                const selectedTextLength = document.getSelection().toString().length;
                let rangePosition = rangeStartEndPosition(
                    recheckedPreSelectionRange,
                    range,
                    selectedTextLength
                );

                let payload = {
                    raw_content: rawContent,
                    content: content,
                    range_position_start: rangePosition.start,
                    range_position_end: rangePosition.end,
                    //preSelectionRange: recheckedPreSelectionRange,
                    //selectedTextLength,
                    is_annotations_version_updated: true
                }
                selectedObject.selectionRange.removeAllRanges();
                return payload;
            }
        }
    }
    else {
        ann.isAnnotationsVersionUpdated = false;
        ann.content = "";
        return ann;
    }
}

export function generateAnnotationPayload(annObject, latestArticleVersion) {
    const annPayload = {
        category: annObject.category,
        comment: annObject.comment,
        content: annObject.content,
        id: annObject.id,
        rangePosition: annObject.rangePosition,
        rawContent: annObject.rawContent,
        sidePanelEdit: true,
        pageURL: annObject.pageURL,
        pageBreadcrumb: annObject.pageBreadcrumb,
        closestGuid: annObject.closestGuid,
        date: new Date(),
        isAnnotationsVersionUpdated: annObject.isAnnotationsVersionUpdated,
        version: latestArticleVersion,
        checked: true        
    };

  return annPayload;
}
