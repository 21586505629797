import React, { useState, createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { usePageContext } from '../contexts/PageContext';

import TocHelpers from '../helpers/TocHelpers';
import CONTEXTHELPERS from '../helpers/ContextHelpers';
import UTILITIESHELPER from '../helpers/UtilitiesHelper';

export const TocContext = createContext()
export const useTocContext = () => useContext(TocContext)

const TocContextProvider = (props) => {
    const children = props?.children || null;
    const [toc, setToc] = useState(null);
    const [hasSelectedTocItems, setHasSelectedTocItems] = useState(true);
    const [tocAnnotations, setTocAnnotations] = useState(null);
    const [tocIconBookmark,setTocIconBookmark]=useState(null);
    const [flattenedToc, setFlattenedToc] = useState(null);
    const [pubInfo, setPubInfo] = useState(((props?.pubInfo !== undefined || props?.pubInfo !== null) ? props.pubInfo : {}));
    const [selectedItemTOC, setSelectedItemTOC] = useState({});
    const { context } = usePageContext();
    const location = useLocation();
    //this useState variable is used to identify landing page selection from crumbtrail
    const [isLandingPageSelected, setIsLandingPageSelected] = useState(false);
    const [tocItemTogglesStatus, setTocItemTogglesStatus] = useState([]);

    useEffect(() => {
        if (toc) {
            setFlattenedToc(TocHelpers.getFlatTocArray(toc));
        }
    }, [toc]);

    useEffect(() => {
        if (toc) {
            if (decodeURI(location?.pathname) === decodeURI(selectedItemTOC?.item?.url)) {
                //console.log("TocContext::useEffect:location:selectedItemTOC already the same as URL");
                return;
            }                        
            const matchedTocItem = flattenedToc?.find(x => decodeURI(location.pathname) === decodeURI(x?.url));
            if (matchedTocItem) {
                //console.log("TocContext::useEffect:location:FOUND selected TOC item with location.pathname:", location.pathname, matchedTocItem);
                setSelectedItemTOC({ item: matchedTocItem });
            }
        }
    }, [location]);//eslint-disable-line

    const store = {
        flattenedToc,
        pubInfo,
        toc,
        tocAnnotations,
        selectedItemTOC,
        tocIconBookmark,
        setSelectedItemTOC,
        setPubInfo,
        setToc,
        setTocAnnotations,
        setTocIconBookmark,
        isLandingPageSelected,
        setIsLandingPageSelected,
        tocItemTogglesStatus,
        setTocItemTogglesStatus,
        hasSelectedTocItems,
        setHasSelectedTocItems 
    }

    return (
        <TocContext.Provider value={store}>
            {children}
        </TocContext.Provider>
    )
}

export default TocContextProvider