import React, { useState, useEffect } from 'react';
import Dropdown from './Dropdown';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { usePageContext } from '../../contexts/PageContext'
import { COUNTRY_LANGUAGE, KNOWLEDGE_DOMAIN } from '../Constants/Constants';


const LanguageSelector = () => {
    const [languageDropdownData, setLanguageDropdownData] = useState([]);
    const { countryKDSettings, context, isCountryKDSettingsLoaded, selectedLanguage, setSelectedLanguage } = usePageContext();

    useEffect(() => {
        if (isCountryKDSettingsLoaded) {
            const allLanguageForCountry = UTILITIESHELPER.getLanguageListByMFandKD(countryKDSettings, context.memberFirm, context.knowledgeDomain || KNOWLEDGE_DOMAIN.auditing);
            if (allLanguageForCountry !== null && allLanguageForCountry !== undefined) {
                setLanguageDropdownData(allLanguageForCountry);
                if (selectedLanguage === null) {
                    allLanguageForCountry.filter(x => x.language === allLanguageForCountry[0]?.defaultLanguage)?.length > 0 ? setSelectedLanguage(allLanguageForCountry[0]?.defaultLanguage) : setSelectedLanguage(allLanguageForCountry[0]?.language || COUNTRY_LANGUAGE.DEFAULT_LANGUAGE);
                }
                else {
                    allLanguageForCountry.filter(x => x.language === selectedLanguage)?.length > 0 ? setSelectedLanguage(selectedLanguage) : setSelectedLanguage(allLanguageForCountry[0]?.language || COUNTRY_LANGUAGE.DEFAULT_LANGUAGE);
                }
            }
        }
    }, [isCountryKDSettingsLoaded, context.memberFirm, context.knowledgeDomain]);

    function LanguageListItem({ languages, onClick, index }) {
        function handleClick() {
            onClick(languages);
        }

        function onKeydown(e) {
            if (e.key === 'Enter') {
                onClick(languages);
                return;
            }
        }

        return (
            <div className={`language-selector-item ${languages?.language === selectedLanguage ? "option-active" : ""}`} onClick={handleClick} tabIndex="0" id={'option-' + index} data-testid={'option-' + index} onKeyDown={onKeydown}>
                <div className="language-selector-dd-category-label">{languages?.language}</div>
            </div>
        )
    }

    const listItems = languageDropdownData?.map((c, index) => (
        <LanguageListItem
            key={`${c.language}-${c.publicationID}`}
            languages={c}
            onClick={handleSelect}
            index={index}
        />
    ))


    function handleSelect(languageInfo) {
        setSelectedLanguage(languageInfo.language);
        UTILITIESHELPER.setLocalStorage("selected-user-language", languageInfo.language);
        document.dispatchEvent(new CustomEvent("dd:requestClose"));
    }

    function getTrigger(language) {
        const currentLanguageInfo = languageDropdownData?.find((x) => x.language.toLowerCase() === language?.toLowerCase());
        return (<><button type="button" className="home-mf__trigger-button" data-testid="ddl-selector">
            <span className="language_selector__trigger-label">{currentLanguageInfo?.language || COUNTRY_LANGUAGE.DEFAULT_LANGUAGE}</span>
        </button></>)
    }


    return (
        isCountryKDSettingsLoaded && <Dropdown
            triggerEl={getTrigger(selectedLanguage)}
            triggerText="Select Language"
            ddClassNames="language-selector-dd"
            containerClassNames="language-selector-dd-container"
        >
            <Dropdown.Panel additionalClassNames="language-selector-dd-panel">
                <Dropdown.ScrollArea
                    maxHeight="145px"
                    additionalClassNames="language-selector-dd-scroll-area">
                    {listItems}
                </Dropdown.ScrollArea>
            </Dropdown.Panel>
        </Dropdown>
    );
}

export default LanguageSelector;