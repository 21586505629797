import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import LinkHelpers from '../../helpers/LinkHelpers';
import useOutsideClick from '../../hooks/useOutsideClick';
import { usePageContextLabels } from '../../contexts/PageContext';
import { logs, page } from "../../helpers/log";

import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import { useTocContext } from '../../contexts/TocContext';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import { useLocation } from 'react-router-dom';
import { generateMiniCrumbtrailText } from '../Breadcrumb/Crumbtrail';
import { ErrorCodes,ErrorMessages } from '../Constants/Errors';
import FilterValue from '../ChatBot/FilterValue';
import ResearchAssistantMessage from '../ChatBot/ResearchAssistantMessage';
import { MEMBERFIRM_CONSTANTS, RESEARCH_ASSISTANT } from '../Constants/Constants';
import ShowIf from '../Common/ShowIf';

const ShareModal = ({
    shouldShowShareModal,
    setShouldShowShareModal,
    shouldShowOverlay,
    setShouldShowOverlay,
    setIsSectionPath,
    isSectionPath,
    setClosedToolbar,
    isResearhAssistant,
    researchAssistantResponseData,
    researchAssistantTitle,
    researchAssistantQuestion,
}) => {
    const shareModalRef = useRef();
    const [shouldShowSuccessMessage, setShouldShowSuccessMessage] = useState(false);
    const { getLabel } = usePageContextLabels();
    const { toc, selectedItemTOC } = useTocContext();
    const location = useLocation();
    const { guidebookToc } = useGuidebookTocContext();
    var context = CONTEXTHELPERS.generateContext(location.pathname);
    var shareProps = {
        pageType: UTILITIESHELPER.getSessionStorage("PageType"),
        eventType: 'share',
        pageUrl: window.location.href,
        selectedTocItem: UTILITIESHELPER.getSessionStorage("SelectedContent"),
        jobTitle:UTILITIESHELPER.getSessionStorage("Level"),
        memberFirm: UTILITIESHELPER.getSessionStorage("MemberFirm"),
        rpMemberFirm: UTILITIESHELPER.getLocalStorage('research-portal-member-firm'),
    }
    const divref = useRef(null);

    function closeModal() {
        setShouldShowShareModal(false);
        setShouldShowSuccessMessage(false);
        setShouldShowOverlay(false);
        document.body.style.overflow = isResearhAssistant !== true && 'unset';
    }

    useEffect(() => {
        return () => {
            closeModal();
        }
    }, []) //eslint-disable-line

    function copyText() {
        function listener(e) {
            if (isResearhAssistant === true) {
                if (divref.current) {
                    const paragraph = document.createElement("p");
                    paragraph.style.fontFamily = "Roboto, Verdana, sans-serif";
                    paragraph.style.fontSize = "24px";
                    paragraph.style.textAlign="center";
                    paragraph.innerHTML = getLabel("ra_CopyText","Copied from the generative AI Research Assistant. Below generative AI response may be incomplete and/or inaccurate, are not auditing advice, and should not be used for audit documentation. Review source documents and use professional judgment.");
                    const paragraphOuterHTML = paragraph.outerHTML;
                    const combinedContent = paragraphOuterHTML + divref.current.innerHTML;
                    e.clipboardData.setData('text/plain', combinedContent.replace(/<br>/g, '\n'));
                    e.clipboardData.setData('text/html', combinedContent);

                }
                
            }
            else {
                let sharedUrl = sessionStorage.getItem('shareUrl');
                let url = UTILITIESHELPER.ReplaceSpecialCharacterInURLForShareModel(sharedUrl);
                if (url.includes('reading-mode=true')) {
                    url = url.replace(/\?reading-mode=true/g, '');
                }

                e.clipboardData.setData('text/plain', url);
                e.clipboardData.setData('text/html', `<a href="${url}">${url}</a>`);
            }
            e.preventDefault();
        }

        try {
            document.addEventListener('copy', listener, { once: true });
            document.execCommand('copy');
            const selection = window.getSelection();
            selection.removeAllRanges();
            return true;
        }
        catch (err) {
            logs.error(page.Article, "ShareModal", ErrorMessages.shareModalCopyText, err,{eventId:ErrorCodes.Article});
            return false;
        }

    }

    if (shouldShowShareModal === true) {
        setClosedToolbar(true);
        document.body.style.overflow = 'hidden';
    }

    let sharedLink = window.location.href;
    let shareUrl;
    let tempShareUrl;
    const selection = document.getSelection();
    const selectionStr = selection.toString();
    const hashExists = window.location.hash;
    if (hashExists) {
        tempShareUrl = sharedLink.split('#')[0];
    } else {
        tempShareUrl = sharedLink;
    }
    if (isSectionPath === true && selectionStr.length > 0) {
        const outerParentId = selection.baseNode.parentNode.offsetParent.id.toString();
        const sectionParentId = selection.baseNode.parentNode.tagName.includes('MARK') ? selection.baseNode.parentNode.parentNode.id : selection.baseNode.parentNode.id.toString()
        if (sectionParentId.length > 0) {
            shareUrl = tempShareUrl + '#' + sectionParentId;
            sessionStorage.setItem('shareUrl', shareUrl);
        } else if (sectionParentId.length <= 0 && outerParentId.length > 0) {
            shareUrl = tempShareUrl + '#' + outerParentId;
            sessionStorage.setItem('shareUrl', shareUrl);
        } else {
            shareUrl = tempShareUrl;
            sessionStorage.setItem('shareUrl', shareUrl);
        }
    } else if (isSectionPath === true && selectionStr.length <= 0) {
        shareUrl = tempShareUrl;
        tempShareUrl = sessionStorage.getItem('shareUrl');
    } else {
        shareUrl = tempShareUrl;
        sessionStorage.setItem('shareUrl', shareUrl);
    }

    if (tempShareUrl.includes('reading-mode=true')) {
        shareUrl = tempShareUrl.replace(/\?reading-mode=true/g, '');
    }
    const shareModalField = UTILITIESHELPER.ReplaceSpecialCharacterInURLForShareModel(shareUrl);

    

    function displaySuccessMessage() {
        logs.event('share-copy', shareProps)
        if (copyText()) {
            setShouldShowSuccessMessage(true);
        }
    }

    function handleMailClick() {
        logs.event('share-email', shareProps)
        let url = sessionStorage.getItem('shareUrl');
        if (url.includes("reading-mode=true")) {
            url = url.replace(/\?reading-mode=true/g, '');
        }
        url = UTILITIESHELPER.ReplaceSpecialCharacterInURLForShareModel(url);
        var encodedurl = encodeURIComponent(url);
        var encodedTitle = encodeURIComponent(generateMiniCrumbtrailText(context, toc, guidebookToc, selectedItemTOC));
        var encodedCopyright = encodeURIComponent(getLabel("g_Copyright", `Copyright © ${new Date().getFullYear()} Deloitte Development LLC. All Rights Reserved`));
        var encodedBody = encodeURIComponent(getLabel("share_MessageIntro", "Your colleague wants to share this Research Portal article with you: "));
        
        encodedurl= encodedurl.replace(/%20/g, "_");
        const composedUrl = `mailto:?subject=${encodedTitle}&body=${encodedBody} ${encodedurl} ${encodedCopyright}`;
        // XSS Link Sanitization
        const sanitizedUrl =  LinkHelpers.sanitizeUrl(composedUrl);

        window.location.href = sanitizedUrl;
    }


    useOutsideClick(shareModalRef, () => {
        closeModal();
    }, ['.icon-share', '.toolbar']);

    return (
        <div>
            {shouldShowOverlay &&
                <div className='full-screen-darken-overlay-fixed' />
            }
            <div ref={shareModalRef} className={cx("share-modal", { 'hide': !shouldShowShareModal })}>
                <div className="share-modal-header">
                    <p>{getLabel("share_Share", "Share")}</p>
                    <button className="share-modal-close" onClick={closeModal} title="Close"></button>
                </div>
                <div className="share-modal-body">
                    <input type="hidden" id="hdnresearchAssistantQuestion" value={researchAssistantQuestion} />
                    <div className="share-modal-block" id="divShareContent" ref={divref }>
                        {
                            isResearhAssistant === true ? (
                                <>
                                <div className='share-modal-block-ra'>
                                    <p id="copy-text-field" style={{ fontFamily: '"Roboto", Verdana, sans-serif ', padding: "10px 0px" }}><span style={{ fontWeight: "bold", fontSize: "24px" }}>{getLabel('ra_Question','Question')}</span></p>
                                    <p style={{ fontFamily: '"Roboto", Verdana, sans-serif ', paddingBottom: "10px", fontSize: "14.66px" }}>{researchAssistantQuestion}</p>
                                    <p style={{ fontWeight: "bold", fontFamily: '"Roboto", Verdana, sans-serif', marginBottom: "10px", fontSize: "24px" }} >{researchAssistantTitle}</p>
                                    <div style={{ fontFamily: '"Roboto", Verdana, sans-serif', lineHeight:'20px', fontSize: "14.66px" }}><ResearchAssistantMessage messageLabel={researchAssistantResponseData?.label} message={researchAssistantResponseData?.response}/></div>
                                    <div style={{ marginTop: "15px", fontSize: "14.66px", fontWeight: "bold", fontFamily: '"Roboto", Verdana, sans-serif' }}>
                                        <FilterValue researchAssistantResponse={researchAssistantResponseData} />
                                    </div>
                                    <div className="research-assistant-forms">
                                      <ShowIf condition={researchAssistantResponseData?.documentSource && researchAssistantResponseData?.documentSource?.length > 0}>
                                        <h3 className="research-assistant-forms-heading" style={{ fontFamily: '"Roboto", Verdana, sans-serif', paddingTop: '10px', fontSize: "20px", fontWeight: "bold" }}>
                                            {getLabel('ra_DocumentSources', 'Sources')}
                                        </h3>
                                        {researchAssistantResponseData?.documentSource?.map((item, index) => (
                                            <>
                                                <div className="research-assistant-citation-data" style={{ fontFamily: '"Roboto", Verdana, sans-serif', display: "flex", fontSize: "13.33px" }}>
                                                    <span style={{ marginLeft: "2px", wordBreak:"normal" }}>{item.footnote}.</span>
                                                    &nbsp;&nbsp;
                                                    <span className="xref" style={{ marginLeft: "2px", textDecoration: 'underline' }}>{item.title}</span>
                                                </div>
                                                <br></br>
                                            </>
                                        ))}
                                       </ShowIf>
                                    </div>
                                    <ShowIf condition={String(window.DART.ENV.RA_IS_FORMS_AND_TEMPLATES_ENABLED).toUpperCase() === "TRUE"}>
                                    <div className="research-assistant-forms">
                                    <ShowIf condition={researchAssistantResponseData?.formSource && researchAssistantResponseData?.formSource?.length > 0}>
                                        <h3 className="research-assistant-forms-heading" style={{ fontFamily: '"Roboto", Verdana, sans-serif', paddingTop:'10px', fontSize: "20px", fontWeight: "bold"}}>
                                            {getLabel('ra_FormAndTemplates', 'Forms, Templates, and Examples to Consider')}
                                        </h3>
                                        {researchAssistantResponseData?.formSource?.map((item, index) => (
                                            <>
                                               
                                                <div className="research-assistant-citation-data" style={{ fontFamily: '"Roboto", Verdana, sans-serif', display: "flex", alignItems: "center", textDecoration: 'underline' }}>
                                                    <span className="xref" style={{ fontSize: "13.33px", marginLeft:"2px" }}> {item.title}</span>
                                                </div>
                                                <br></br>
                                            </>
 
                                        ))}
                                        </ShowIf>
                                    </div>
                                   </ShowIf>
                                    <div className="research-assistant-forms">
                                        <h3 className="research-assistant-forms-heading" style={{ fontFamily: '"Roboto", Verdana, sans-serif', paddingTop: '10px', fontSize: "20px", fontWeight: "bold" }}>
                                            {getLabel('ra_NotableExclusions', 'Content Excluded from Response')}
                                        </h3>
                                        
                                        <ul style={{padding:"0 0 0 15px", fontSize: "14.66px"}}>
                                            <li style={{fontFamily: '"Roboto", Verdana, sans-serif', fontSize: "14.66px"}}>{getLabel("ra_ProfessionalStandardContentExclusion", RESEARCH_ASSISTANT.contentExclustionProfessionalStandard)}</li>
                                            <li style={{fontFamily: '"Roboto", Verdana, sans-serif', fontSize: "14.66px"}}>{getLabel("ra_DeloitteCommunicationContentExclusion", RESEARCH_ASSISTANT.contentExclustionDeloitteCommunication)}</li>
                                            {researchAssistantResponseData?.memberFirm === MEMBERFIRM_CONSTANTS.us && <li style={{fontFamily: '"Roboto", Verdana, sans-serif', fontSize: "14.66px"}}>{getLabel("ra_ConditionalStringUS", RESEARCH_ASSISTANT.conditionalStringUS)}</li>}
                                        </ul>
                                    </div>
                                    
                                    <div>
                                        <p style={{ fontStyle: 'italic', fontSize: '14.66px', fontFamily: '"Roboto", Verdana, sans-serif', paddingTop:'20px' }}>
                                            While the Research Assistant's responses may appear conclusive, they (1) may not be complete or accurate, (2) do not represent auditing advice or documentation, and (3) should not be used as a basis for any auditing conclusions. Users should access the source documentation when performing their auditing research.
                                        </p>
                                        <p style={{ padding: "10px 0px", fontFamily: '"Roboto", Verdana, sans-serif', fontSize: "14.66px" }}>Confidential and Proprietary – for Use Solely by Authorized Personnel</p>
                                        <p style={{ paddingBottom: "10px", fontFamily: '"Roboto", Verdana, sans-serif', fontSize: "14.66px" }}>Copyright © 2024 Deloitte Development LLC. All Rights Reserved</p>
                                    </div>

                                </div>
                                </>
                            ) : (
                                <p id="copy-text-field">{shareModalField}</p>
                            )
                        }
                    </div>
                    <div className="d-flex items-center flex-gap-1">
                    <button
                        className="btn btn-secondary"
                        onClick={displaySuccessMessage}
                        title={getLabel('a_Copy', 'Copy')}
                    >{getLabel('a_Copy', 'Copy')}</button>
                        {isResearhAssistant !== true && <button
                            className="btn btn-secondary"
                            onClick={handleMailClick}
                            title={getLabel('a_Email', 'Email')}
                        >
                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M19.3333 0.666687H0.666667C0.266667 0.666687 0 0.933354 0 1.33335V14.6667C0 15.0667 0.266667 15.3334 0.666667 15.3334H19.3333C19.7333 15.3334 20 15.0667 20 14.6667V1.33335C20 0.933354 19.7333 0.666687 19.3333 0.666687ZM18.6667 13.0667L13.4 7.80002L18.6667 2.86669V13.0667ZM17.6667 2.00002L10 9.06669L2.33333 2.00002H17.6667ZM1.33333 2.86669L6.6 7.80002L1.33333 13.0667V2.86669ZM7.6 8.66669L9.53333 10.4667C9.66667 10.6 9.8 10.6667 10 10.6667C10.2 10.6667 10.3333 10.6 10.4667 10.4667L12.4 8.66669L17.7333 14H2.26667L7.6 8.66669Z" />
                            </svg>
                        </button>}
                    </div>
                </div>
                <div className={cx("share-modal-footer", { 'hide': !shouldShowSuccessMessage })}>
                    <div className="share-modal-success">
                        <p>
                            {
                                isResearhAssistant === true ? "Content successfully copied" :
                                    getLabel("s_LinkSuccessfullyCopied", "Link successfully copied")
                            }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

ShareModal.defaultProps = {
    setShouldShowShareModal: () => { },
    setShouldShowOverlay: () => { },
}

export default ShareModal;
