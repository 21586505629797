import React, { useCallback, useState } from 'react';
import { usePageContextLabels,usePageContext } from '../../contexts/PageContext';
import HTMLContent from '../Common/HTMLContent';
import ShowIf from '../Common/ShowIf';
import ViewAll from '../Common/ViewAll';
import CollectionTocSearchBadge from './CollectionTocSearchBadge';
import getIconSrc from '../../helpers/getIconSrc';

const CollectionTOC = ({ item, url, pageControllerType, idx, toc }) => {
    const { context } = usePageContext();
    const [isExpanded] = useState(false);
    const { getLabel } = usePageContextLabels();
    const pubType = context.pageSelectedPublicationTitle;
    const GetIconByPubType = useCallback(() => {
        let icon = getIconSrc(pageControllerType);
        return (
            <div className='collection-toc-sidebar-toc-icon'>
                <img className="sidebar-toc-icon-next-to-title" src={icon} alt="Icon" />
            </div >
        );  
    }, [pubType]);


    return (
        <>
            <div>
                <div className="collection-toc-icon-container" key={idx}>
                    <div className="collection-toc-icon-main">
                        {GetIconByPubType()}                        
                    </div>
                    <div className="collection-toc-icon-item-name">
                        <a href={url}>{item.linkText ? item.linkText : item.name}</a>
                    </div>
                    <div className="collection-toc-icon-searchbadge">
                        <CollectionTocSearchBadge
                            key={`badge_${idx}`}
                            tocItem={item}
                            pageControllerType={pageControllerType}
                            searchResultCount={toc.resultCount}
                        />
                    </div>
                </div>
                <ShowIf condition={isExpanded}>
                    <div className="collection-item-expandable-section">
                        <ShowIf condition={item?.linkAltText}>
                            <p className="collection-item-expandable-text-body"><HTMLContent>{item.linkAltText}</HTMLContent></p>
                        </ShowIf>
                        <ViewAll
                            text={getLabel("c_ViewPublication", "View Publication")}
                            href={url}
                            className="view-publication"
                        />
                    </div>
                </ShowIf>
            </div>
        </>
    )
}
export default CollectionTOC;