import React from 'react';
import Dropdown from '../Common/Dropdown';
import cx from 'classnames'
import { usePageContextLabels } from '../../contexts/PageContext';
import ShowIf from '../Common/ShowIf';


function CategoryTrigger({ category, modifiedCategories, isAllCategorySelected }) {
    const { getLabel } = usePageContextLabels();
    const getCategoriesLabel = () => {
        let selectedLabels = [];
        modifiedCategories.forEach(cat => {
            if (category.includes(cat.category_guid)) {
                selectedLabels.push(cat.label);
            }
        })
        let selectedData = selectedLabels.join(', ');
            return selectedData;
    }
    return (
        <div className='an-dd-category-trigger-dropdown'>
            {!category.length ? <div className="an-dd-category-header all">{getLabel("hh_FilterByCategory", "Filter by Category")}</div> :
                isAllCategorySelected ? <div className="an-dd-category-all all">{getLabel("hh_AllCategories", "All Categories")}
                </div> : modifiedCategories.map((ele) => {
                    return <>
                        {ele && category.length === 1 && category.includes(ele.category_guid) ?
                            <div key={'c' + ele.category_guid} style={{ width: "100%" }}>
                                <div className="an-dd-category-label-dropdown">
                                    <strong className='an-dd-cat-single-selection'>
                                        {
                                            ele?.label
                                        }
                                    </strong>
                                </div>
                            </div> : null}
                    </>
                })}
            {category?.length > 1 && !isAllCategorySelected ? <div className="an-dd-category-label-dropdown"> <strong className="an-dd-cat-selection">{getCategoriesLabel()}</strong> </div> : null}
        </div>
    )
}

function CategoryItem({ category, onClick, isChecked, classNameCheckmark, onChange }) {
    function handleClick() {
        onClick(category);
    }

    let classNames = 'an-dd-category-item-dropdown';

    ////styling for background category color
const backgroundColorStyle= {
    backgroundColor: category.color
  }
  

    return (
        <div className={classNames} onClick={handleClick}>
            <div className={cx("styled-checkbox", { 'checked': isChecked })}>
                <div style={{ visibility: isChecked ? 'visible' : 'hidden' }} className={cx(classNameCheckmark)}>
                    <div className="tick-mark"></div>
                </div>
            </div>
            <input type="checkbox" className="hidden-checkbox" checked={isChecked} onChange={onChange} tabIndex={1} />
            <ShowIf condition={category.color !== ""}>
                <div className="an-dd-category-chip-dropdown" style= {
                    backgroundColorStyle}></div>
            </ShowIf>
            <div className={cx("an-dd-label-dropdown", { 'all': category.color === "" })}>
            {category?.label?.length > 30 ? (category?.label === category?.label?.toUpperCase() ? category?.label?.substring(0, 27).concat('...') : category?.label?.substring(0, 30).concat('...')) : category?.label ? category.label : "" }
               </div>
        </div>
    )
}


function AnnotationPanelDropdown({ categories, selected, onSelect, isAllCategorySelected }) {
    //const [setIsOpen] = useState(false);
    const { getLabel } = usePageContextLabels();

    function handleSelect(category) {
        onSelect(category);
        //setIsOpen(true);
    }

  



    function isCheckedValue(c, selected, isAllCategorySelected) {
        if (c.category_guid === 'cat0') {
            return isAllCategorySelected;
        }
        if (c.category_guid && selected.length) {
            return selected.includes(c.category_guid)
        }
    }

    //NB: All Categories must ALWAYS be "cat0"!
    const modifiedCategories = [{ category_guid: 'cat0', label: getLabel("hh_AllCategories", "All Categories"), color: '' }, ...categories]
    const listItems = modifiedCategories.map((c) => (
        <CategoryItem
            key={`${c.label}-${c.color}`}
            category={c}
            onClick={handleSelect}
            onChange={handleSelect}
            isChecked={isCheckedValue(c, selected, isAllCategorySelected)}
            classNameCheckmark="checkmark-dropdown-panel"

        />
    ))

    function getTriggerEl() {
        return <CategoryTrigger category={selected} modifiedCategories={modifiedCategories} isAllCategorySelected={isAllCategorySelected} />;
    }

    // function getTriggerElClose() {
    //     document.dispatchEvent(new CustomEvent('dd:requestClose'));
    // }

    return (
        <Dropdown
            triggerEl={getTriggerEl()}
            ddClassNames="an-dd-dropdown"
            containerClassNames="an-dd-container-dropdown"
        >
            <Dropdown.Panel additionalClassNames="an-dd-panel-dropdown">
                <Dropdown.ScrollArea maxHeight="153px" additionalClassNames="an-dd-scroll-area-dropdown">
                    {listItems}
                </Dropdown.ScrollArea>
            </Dropdown.Panel>
        </Dropdown>
    );
}

export default AnnotationPanelDropdown;