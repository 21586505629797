import React, { useEffect, useState } from 'react';
import AnnotationDeleteModal from "./AnnotationDeleteModal";
import { useUserPreferencesContext, ACTIONS as ANN_ACTIONS } from '../../contexts/AnnotationsContext';
import CommonSearchbar from '../Common/CommonSearchbar';
import { usePageContextLabels,usePageContext } from '../../contexts/PageContext';
import CategoryDropdown from "../Common/CategoryDropdown";
import DateSelector from '../Common/DateSelector';
import { BookmarksListItems } from './BookmarksListItems';
import cx from 'classnames';
import BookmarkService from '../../helpers/BookmarkServices';
import { useLocation } from 'react-router-dom';
import CategoryService from '../../helpers/CategoryServices';

export const BookmarksDashboard = () => {
    const [bookmarks, setBookmarks] = useState([])
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState();
    const [filteredBookmarks, setFilteredBookmarks] = useState([]);
    const [shouldShowOverlayDelete, setShouldShowOverlayDelete] = useState(false);
    const [messageDelete, setMessageDelete] = useState('');
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedDate, setSelectedDate] = useState();
    const [isAllCategorySelected, setIsAllCategorySelected] = useState(false);
    const [filteredViewedBookmark, setFilteredViewedBookmark] = useState([]);
    const [deleteGuids, setDeleteGuids] = useState([]);
    const [value, setValue] = useState('');
    const [searchTerms, setSearchTerms] = useState(0);
    const { getLabel } = usePageContextLabels();
    const { state: annState, dispatch, viewClickedAnnotation, setViewClickedAnnotation } = useUserPreferencesContext();
    const [resetDates, setResetDates] = useState(false);
    const [dateFilter, setDatefilter] = useState(false);
    const location = useLocation();
    const [categories, setCategories] = useState([]);
    const { context, getMemberFirmID } = usePageContext();
    const memberFirmId = getMemberFirmID();
 
    useEffect(() => {
        return () => {
            setViewClickedAnnotation(null);
        }
    }, [])//eslint-disable-line

    useEffect(() => {
        if (viewClickedAnnotation && filteredBookmarks) {
            let newBookData = filteredBookmarks.filter(item => item.reference_identifier === viewClickedAnnotation)
            setFilteredViewedBookmark(newBookData);
        }
        else {
            setFilteredViewedBookmark([...filteredBookmarks]);
        }
    }, [filteredBookmarks, viewClickedAnnotation])

    useEffect(() => {
        const fetchCategories = async () => {
            var categories = await CategoryService.fetchCategories(location.pathname, memberFirmId);
            dispatch({
                type: ANN_ACTIONS.CATEGORY_LOAD,
                payload: categories,
            });
        };

        const fetchBookmark = async () => {
            const bookmarks = await BookmarkService.fetchBookmarkDashboard(location.pathname, memberFirmId);
            dispatch({
                type: ANN_ACTIONS.BOOKMARKS_LOAD,
                payload: bookmarks,
            });
        };

        fetchCategories();
        fetchBookmark();
    }, [memberFirmId]);//eslint-disable-line

    useEffect(() => {
        setCategories(annState?.categories);
    }, [annState?.categories]);//eslint-disable-line

    useEffect(() => {
        setBookmarks(annState?.bookmarks);
    }, [annState?.bookmarks]);//eslint-disable-line


    useEffect(() => {
        const resultsContainer = document.querySelector('.book-list');
        const searchTerms = resultsContainer?.querySelectorAll('.highlight-search');
        setSearchTerms(searchTerms);
        return () => {
            setSearchTerms([]);
        }
    }, [value]);

    useEffect(() => {
        setFilteredBookmarks(filterBookmarks());
    }, [selectedCategory, selectedDate, bookmarks]);//eslint-disable-line

    function modifyDeleteGuidsHandler(val, opr) {
        if (opr === true) {
            setDeleteGuids(pre => [...pre, val]);
        } else {
            setDeleteGuids(pre => pre.filter(guid => guid !== val));
        }
    }

    function deleteAnnotationModal() {
        if (deleteGuids && deleteGuids.length > 0) {
            setMessageDelete(getLabel("hh_BookmarkDeleteIntroDashboard", "You are about to permanently delete {0} bookmark(s).").replace('{0}', deleteGuids.length) + getLabel("hh_DeleteIntroProceed", "Would you like to proceed?"))
            setIsDeleteModalVisible(true);
            setShouldShowOverlayDelete(true);
        }
    }

    function filterBookmarks() {
        var filterBookmarks = bookmarks;
        if (selectedCategory && selectedCategory.length > 0) { //Filter by Category
            filterBookmarks = filterBookmarks.filter(item => selectedCategory.includes(item.category_guid));
        }
        if (selectedDate) { //Filter by date
            var fromDate = new Date(selectedDate[0]);
            var toDate = new Date(selectedDate[1]);
            toDate.setHours(23, 59, 59, 0);

            filterBookmarks = filterBookmarks.filter((item) => new Date(item.created_date) >= fromDate && new Date(item.created_date) <= toDate);
        }
        return filterBookmarks;
    }

    function handleDateSelection(date) {
        if (date)
            setDatefilter(true)
        setSelectedDate(date);
    }

    function handleCategorySelect(category) {
        if (category.category_guid !== "cat0") {
            setIsAllCategorySelected(false);
            if (selectedCategory.includes(category.category_guid)) {
                let categories = selectedCategory.filter(ele => ele !== category.category_guid);
                setSelectedCategory(prev => [...categories]);
            } else {
                setSelectedCategory(prev => [...prev, category.category_guid]);
            }
        } else {
            setIsAllCategorySelected(prev => {
                if (prev) {
                    setSelectedCategory(prev => []);
                } else {
                    let categories = annState.categories.map(ele => ele.category_guid);
                    setSelectedCategory(prev => [...categories]);
                }
                return !prev
            });
        }
        document.dispatchEvent(new CustomEvent('dd:requestClose'));

    }

    function clearFilters() {
        setSelectedCategory([])
        setResetDates(!resetDates)
        setDatefilter(false)
        setIsAllCategorySelected(false);
    }

    async function DeleteBookmarks(guids) {
        const bookmarks = await BookmarkService.deleteBookmarks(location.pathname, guids);
        if (bookmarks) {
            dispatch({
                type: ANN_ACTIONS.BOOKMARK_BULK_REMOVE,
                payload: guids,
            });
            setDeleteGuids([]);
        }
    }

    return (
        <>
            {categories && categories.length > 0 && <div className='bookmark-container cf-right'>
                <AnnotationDeleteModal
                    setShouldShowDeleteModal={setIsDeleteModalVisible}
                    shouldShowDeleteModal={isDeleteModalVisible}
                    setShouldShowOverlay={setShouldShowOverlayDelete}
                    shouldShowOverlay={shouldShowOverlayDelete}
                    deleteAnnotationMessage={messageDelete}
                    deleteGuids={deleteGuids}
                    deleteItems={DeleteBookmarks}
                    isBookmarkDelete={true}
                />
                <div>
                    <div className='d-flex' >
                        <div className='d-flex flex-row annot-filters'>
                            <div className='annot-filters-item'>
                                {getLabel("hh_AnnotationsFilterBy", "Filter By")}
                            </div>
                            <div className='annot-filters-item'>
                                <CategoryDropdown
                                    categories={categories}
                                    selectedCategories={selectedCategory}
                                    onSelect={handleCategorySelect}
                                    isAllCategorySelected={isAllCategorySelected}
                                    bookmarks={true}
                                    customClass='an-dd-category-trigger-dropdown-dashboard'
                                />
                            </div>
                            <div className='annot-filters-item'>
                                <DateSelector onChange={handleDateSelection} reset={resetDates} />
                            </div>
                            <button className={cx('annot-clear-filters', { 'annot-clear-filters-disabled': !dateFilter && !selectedCategory.length > 0 })} onClick={() => clearFilters("viewAll")}>
                                {getLabel("hh_AnnotationsClearAllFilters", "Clear All Filters")}
                            </button>
                        </div>
                    </div>
                    <div className='d-flex'>
                        {filteredViewedBookmark && filteredViewedBookmark.length > 0 &&
                            <div className='book-delete'>
                                <div className='icon-bin' onClick={deleteAnnotationModal} ></div>
                            </div>
                        }
                    </div>
                </div>
                <div className='book-list' id='book-list'>
                    {filteredViewedBookmark && filteredViewedBookmark.length > 0 && filteredViewedBookmark.map((item) => {
                        return <div key={item.bookmark_guid}>
                            <BookmarksListItems category={categories?.find(i => i.category_guid === item.category_guid)} item={item} searchText={value} modifyDeleteGuids={modifyDeleteGuidsHandler} />
                        </div>
                    })}
                    <div>
                        {(!filteredViewedBookmark || filteredViewedBookmark.length === 0) &&
                            <div>
                                <div className="notification">
                                    <span className="notification-warning">&nbsp;</span>
                                    <p className="notification-label annot-notification-label">No Bookmarks data available</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            }
            {filteredViewedBookmark && filteredViewedBookmark.length > 0 &&
                <div className='annot-main'>
                    <div className='annot-searchbar'>
                        <CommonSearchbar
                            placeholder={getLabel("s_Search", "Search")}
                            className="dashboard-search"
                            value={value}
                            setValue={setValue}
                            terms={searchTerms}
                        />
                    </div>
                    <div className='annot-right-delete-panel-bookmark' >
                        <div className='annot-delete-hr'></div>
                    </div>
                </div>
            }
        </>

    )
}
export default BookmarksDashboard;
