import React, { useState, useEffect } from 'react';
import ShowIf from "../Common/ShowIf";
import { usePageContextLabels } from "../../contexts/PageContext";
import { useThemeContext } from "../../contexts/ThemeContext";
import ResolveSources from './ResolveSources';
import { RESEARCH_ASSISTANT_SOURCE_TYPES } from '../Constants/Constants';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';

function ResearchAssistantSources({ isResearchAssistantLoading, researchAssistantResponse }) {
    const [isExpand, setIsExpand] = useState(true)
    const { getLabel } = usePageContextLabels();
    const memberFirm = CONTEXTHELPERS.getMemberFirmWithTaxonomyId(researchAssistantResponse?.inputFields?.userLibrariesSelected?.[0]);
    const memberFirmId =  researchAssistantResponse?.inputFields?.userLibrariesSelected?.[0];
    const knowledgeDomain = CONTEXTHELPERS.getknowledgeDomain(researchAssistantResponse?.inputFields?.userFilterSelected?.[1]?.filterID?.[0]);
    const knowledgeDomainId =  researchAssistantResponse?.inputFields?.userFilterSelected?.[1]?.filterID?.[0];
    const language = CONTEXTHELPERS.getLanguageByMemberFirm(memberFirm);
    const { isDarkMode } = useThemeContext();

    return (
        <>
            <ShowIf condition={!isResearchAssistantLoading && researchAssistantResponse !== null}>
                <div className='form-title' data-testid="isExpandBox" onClick={() => setIsExpand(!isExpand)}>
                    <img src= {isDarkMode ?'/images/white-down-arrow.svg' : '/images/gray-down-arrow.svg'} alt="up-down-arrow" data-testid={!isExpand ? 'up-down-arrow' : ''} className={`up-down-arrow ${!isExpand ? 'hidden-icon' : ''}`} />
                    <img src= {isDarkMode ?'/images/white-right-arrow.svg':'/images/gray-right-arrow.svg' } alt='down-arrow' data-testid={isExpand ? 'down-arrow': ''} className={`right-arrow ${isExpand ? 'hidden-icon' : ''}`} />
                    <h3 className="research-assistant-forms-heading">
                        {getLabel('ra_DocumentSources', 'Sources')}
                    </h3>
                </div>
            </ShowIf>
            {isExpand && <>
                {researchAssistantResponse?.documentSource?.map((item, index) => (
                    <div className="research-assistant-citation-data" key={`citation-${index}`}>
                        <div data-testid="resolve-source">
                            <ResolveSources sourceType={RESEARCH_ASSISTANT_SOURCE_TYPES.DOCUMENT} title={item.title} type={item.type} memberFirm={memberFirm} language={language} knowledgeDomain={knowledgeDomain} memberFirmId={memberFirmId} knowledgeDomainId={knowledgeDomainId} footnote={item.footnote} uniqueIdentifier={item.citation} pubGuid={item.pubID}/>
                        </div>
                    </div>
                ))}
            </>}

        </>
    )
}

export default ResearchAssistantSources