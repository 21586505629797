import React, { useState } from 'react';
import ShowIf from '../Common/ShowIf';
import UTILITIESHELPER from "../../helpers/UtilitiesHelper";
import { ErrorCodes,ErrorMessages } from '../Constants/Errors';
import { page,logs } from '../../helpers/log';
import { usePageContextLabels } from '../../contexts/PageContext';


export const ContentErrorMessage = () => {
    const [showHideErrorMessage, setShowHideErrorMessage] = useState(false);
    const {getLabel} = usePageContextLabels();
    function closeContentErrorMessage() {
        setShowHideErrorMessage(true);
    }
    if ((UTILITIESHELPER.getLocalStorage("isContentLoadError")?.toLowerCase() === 'true') && !showHideErrorMessage) {
    logs.error(page.Publication, 'Publication', ErrorMessages.contentErrorMessage,"error",{eventId:ErrorCodes.Publication}); 
    }
    return (
        <ShowIf condition={(UTILITIESHELPER.getLocalStorage("isContentLoadError")?.toLowerCase() === 'true') && !showHideErrorMessage}>
            <div className={"content-error-message"}>
                <span className="alert">&nbsp;</span>
                <p>{getLabel("err_ContentErrorMessage","Oh no, something has gone wrong! Please try again later. If the problem persists, please contact support. Code – ")} {ErrorCodes.Publication}</p>
                <button className="close-warning" onClick={closeContentErrorMessage}>&nbsp;</button>
            </div>
        </ShowIf>
    )
}

export default ContentErrorMessage;