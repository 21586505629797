import React from "react";
import { usePageContextLabels } from "../../contexts/PageContext";

const AskFollowUpButton = ({ handleAskFollowUpButton }) => {
    const { getLabel } = usePageContextLabels();

    return (
        <button className='ask-followup-question-button' data-testid="askme-button" onClick={() => handleAskFollowUpButton()}>
            <img src='/images/chat-box-white.svg' className='chat-icon-img' alt="Ask a Follow-Up Question" />
            <label className='ask-followup-label'>{getLabel('ra_ask_followup_question', 'Ask a Follow-Up Question')}</label>
            <img src='/images/gotonext-softwhite.svg' className='goto-next-icon' alt="Ask a Follow-Up Question" />
        </button>
    )
}

export default AskFollowUpButton;