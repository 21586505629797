import React, { memo } from "react";
import DOMPurify from "dompurify";
import ReactHtmlParser from "react-html-parser";

const ResearchAssistantQuery = ({ query }) => {
    const sanitizedData = DOMPurify.sanitize(query);
    return (
        <div className="convo-box conversation-ra-query">
            <div className="query-box">
                <label className="query-label" data-testid="query-label-id">{ReactHtmlParser(sanitizedData)}</label> 
            </div>
        </div>
    )
}

export default memo(ResearchAssistantQuery)