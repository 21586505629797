import React, { useState } from 'react';
import ShowIf from '../Common/ShowIf';


export const InfoNotifiation = (props) => {
    const [showNotification, setShowNotification] = useState(true);
    function closeNotification() {
        setShowNotification(false);
    }
    return (
        <ShowIf condition={showNotification}>
            <div className={"collection-alert-message"}>
                <span className="alert">&nbsp;</span>
                <p>{props.message}</p>
                <button className="close-warning" onClick={closeNotification}>&nbsp;</button>
            </div>
        </ShowIf>
    )
}

export default InfoNotifiation;