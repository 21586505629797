import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import LinkHelpers from '../../helpers/LinkHelpers';
import { get } from 'lodash';
import { getIconSrc } from "../Article/File";
import { usePageContextLabels } from '../../contexts/PageContext';
import ListItem from '../Common/ListItem';
import ViewAll from '../Common/ViewAll';
import ShowIf from '../Common/ShowIf';
import CollectionDropdown from "./CollectionDropdown";
import useCatalogSectionsQuery from "../../hooks/useCatalogSectionsQuery";
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';


const CatalogBox = ({ className, collection, currentPosition }) => {
    const { getLabel, getMFLabel } = usePageContextLabels()
    const friendlyUrl = get(collection, 'collectionType', "");
    const taxonomylists = get(collection, 'taxonomylist', []);
    const icon = get(collection, 'icon.Url') || getIconSrc(friendlyUrl);
    const altText = get(collection, 'friendlyUrl');
    const viewAllLabel = get(collection, 'viewAllLabel');
    const viewAllText = (viewAllLabel !== null && friendlyUrl !== "folio") ? getLabel(viewAllLabel, "View Collection") : "";
    const viewAllLink = (friendlyUrl.indexOf("dashboard") > -1) ? [friendlyUrl].join("/") : ["collection", friendlyUrl].join("/");
    const name = getMFLabel(get(collection, 'title'), "get(currentCollection, 'title')");
    const description = getMFLabel(get(collection, 'intro'), "get(currentCollection, 'intro')");
    const listTitle = getMFLabel(get(collection, 'linkstitle'), "get(currentCollection, 'linkstitle')");
    const maxItems = get(collection, 'resultLimit');

    //BDP:TODO:Update this to use the context object instead of the parameters, these are in the context/contextMemberFirm (as the context memberfirm!
    
    let { data: catalogBoxLinks, status } = useCatalogSectionsQuery(collection);
    if (!collection.publicationType) {
        catalogBoxLinks = collection.links;
    }
    
    const shouldShowLinks = () => {
        return (catalogBoxLinks?.length > 0 && status === 'success')
            || (status === 'loading' && catalogBoxLinks?.length === 0)
            || catalogBoxLinks?.length > 0
    };
    const sanitizedViewAllLink = LinkHelpers.sanitizeUrl(viewAllLink);

    return (
        <div className={cx("catalog-box", className, (collection.publicationType !== null && catalogBoxLinks?.length === 0) ? "hide" : "")}>
            <div className="d-flex catalog-box-top-row">
                <div className="icon-container">
                    <img className="catalog-box-icon" src={icon} alt={altText} />
                </div>
                <div>
                    <h2 className={cx("catalog-box-name", currentPosition === 0 ? 'catalog-box-name-big' : '')}>
                        <ShowIf condition={viewAllLink.indexOf("dashboard") > -1}>
                            <Link to={sanitizedViewAllLink} className={cx("catalog-title-link", className)}>
                                {name}
                            </Link>
                        </ShowIf>
                        <ShowIf condition={viewAllLink.indexOf("dashboard") === -1}>
                            {name}
                        </ShowIf>
                    </h2>
                    <div className="catalog-box-description">
                        {description}
                    </div>
                </div>
            </div>
            <ShowIf condition={taxonomylists.length > 0}>
                {taxonomylists.map((taxonomylistitem, i) => {
                    return (<div key={i}>
                        <ShowIf condition={i > 0}>
                            <div>
                                <span className="or-block">
                                    <em className="or">
                                        {getLabel("c_OR", "OR")}
                                    </em>
                                </span>
                            </div>
                        </ShowIf>
                        <CollectionDropdown
                            placeholder={get(taxonomylistitem, 'name')}
                            options={get(taxonomylistitem, 'items', [])}
                        />
                    </div>
                    )
                })}
            </ShowIf>
            <ShowIf condition={shouldShowLinks}>
                {!UTILITIESHELPER.isArrayNullorEmpty(catalogBoxLinks) &&
                    <div className="list-container">
                        {listTitle ? <h3 className="list-title">{listTitle}</h3> : null}
                        <ul className="list">
                            {catalogBoxLinks.slice(0, maxItems).map((item, i) => {
                                const href = get(item, 'linkExternal');
                                return (
                                    <ListItem
                                        key={href + i}
                                        href={href}
                                    >
                                        {get(item, 'linkText')}
                                    </ListItem>
                                )
                            })}
                        </ul>
                    </div>}
            </ShowIf>
            <ShowIf condition={viewAllText !== ""}>
                <ViewAll
                    text={viewAllText}
                    href={sanitizedViewAllLink}
                />
            </ShowIf>
        </div>
    )
}

CatalogBox.defaultProps = {
    listItems: [],
    maxItems: 5,
}

export default CatalogBox;
