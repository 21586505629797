import React, { useEffect } from 'react';
import AnnotationsDashboard from './AnnotationsDashboard';
import RecentlyViewed from './RecentlyViewed';
import { useNavigate } from "react-router-dom";
import { usePageContext, usePageContextLabels, usePageContextSettings } from '../../contexts/PageContext';
import { BookmarksDashboard } from './BookmarksDashboard';


const ResearchDashboard = () => {
    const { getLabel } = usePageContextLabels();
    const { context, isMemberFirmsLoaded } = usePageContext();
    const navigate = useNavigate();
    const { isDashboardEnabled, isAnnotationsEnabled, isRecentlyViewedEnabled, isBookmarksEnabled } = usePageContextSettings();

    useEffect(() => {
        if (isMemberFirmsLoaded && !isDashboardEnabled()) {
            navigate(`/${context.memberFirm}/${context.language}/${context.knowledgeDomain}`);
        }
    }, [isMemberFirmsLoaded]);//eslint-disable-line

    function navigateTo(dashboard) {
        navigate([context.pageBaseURL, dashboard].join("/"));
    }

    return (
        <>
            {isMemberFirmsLoaded && <div className="researchboard-panel-main">
                <div className='' >
                    <div className='pannels-container' >
                        <div className='annot-side-pannel-small'> <br />
                            <div className='annot-side-pannel-inner-container'>
                                <div className='side-pannel-body'>
                                    {isAnnotationsEnabled() &&
                                        <div className='an-nav-item-text-container'>
                                            <div className={context.pageType === 'annotations' ? 'an-nav-item-text-container-selected' : 'an-nav-item-text-container-unselected'}>
                                                <div onClick={() => { navigateTo('annotations') }} className={context.pageType === 'annotations' ? 'align-annot-left-panel-items-selected' : 'align-annot-left-panel-items'}>{getLabel("usp_annotations", "Annotations")}</div>
                                            </div>
                                        </div>
                                    }
                                    {isBookmarksEnabled() &&
                                        <div className='an-nav-item-text-container'>
                                            <div className={context.pageType === 'bookmarks' ? 'an-nav-item-text-container-selected' : 'an-nav-item-text-container-unselected'}>
                                                <div onClick={() => { navigateTo('bookmarks') }} className={context.pageType === 'bookmarks' ? 'align-annot-left-panel-items-selected' : 'align-annot-left-panel-items'}>{getLabel("usp_bookmarks", "Bookmarks")}</div>
                                            </div>
                                        </div>
                                    }
                                    {isRecentlyViewedEnabled() &&
                                        <div className='an-nav-item-text-container'>
                                            <div className={context.pageType === 'history' ? 'an-nav-item-text-container-selected' : 'an-nav-item-text-container-unselected'}>
                                                <div onClick={() => { navigateTo('history') }} className={context.pageType === 'history' ? 'align-annot-left-panel-items-selected' : 'align-annot-left-panel-items'}>{getLabel("usp_history", "History")}</div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {isAnnotationsEnabled() && context.pageType === 'annotations' &&
                            <AnnotationsDashboard></AnnotationsDashboard>
                        }
                        {isBookmarksEnabled() && context.pageType === 'bookmarks' &&
                            <BookmarksDashboard />
                        }
                        {isRecentlyViewedEnabled() && context.pageType === 'history' &&
                            <RecentlyViewed></RecentlyViewed>
                        }
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default ResearchDashboard;
