import React from 'react';
import PropTypes from 'prop-types';

function ScrollArea({ children, maxHeight, maxWidth, additionalClassNames }) {
    const styles = {};
    if (maxHeight !== null) {
        styles.maxHeight = maxHeight;
    }
    if (maxWidth !== null) {
        styles.maxWidth = maxWidth;
    }

    return (
        <div className={"dd__scroll-area " + additionalClassNames } style={styles}>
            {children}
        </div>
    )
}

ScrollArea.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    maxHeight: PropTypes.string,
    maxWidth: PropTypes.string,
    additionalClassNames: PropTypes.string
}

ScrollArea.defaultProps = {
    maxHeight: null,
    maxWidth: null,
    additionalClassNames: ''
}

export default ScrollArea;