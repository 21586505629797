import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        tenant: window.DART.ENV.REACT_APP_TENANT,
        clientId: window.DART.ENV.REACT_APP_CLIENTID,
        redirectUri: window.DART.ENV.REACT_APP_REDIRECTURI,
        authority: `https://login.microsoftonline.com/${ window.DART.ENV.REACT_APP_TENANT}`,
        // endpoints: {
        //     api: window.DART.ENV.REACT_APP_ENDPOINT_API
        // },
        cache: {
            cacheLocation: window.DART.ENV.REACT_APP_CACHELOCATION,
            storeAuthStateInCookie: true,
        },
        system: {	
            loggerOptions: {	
                loggerCallback: (level, message, containsPii) => {	
                    if (containsPii) {		
                        return;		
                    }		
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }	
                }	
            }	
        }
        // whitelist: window.DART.ENV.RP_REACT_APP_WHITELIST
    }
};
export const loginRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};