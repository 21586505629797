
import React, { useState, useEffect } from 'react'
import cx from 'classnames';
import throttle from 'lodash.throttle';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';

const HorizontalScrollbar = ({className, isRedingMode, shouldBeFixed}) => {
    let heightWarningNotProd = document.querySelector("#non-production-message")?.offsetHeight;
    heightWarningNotProd = heightWarningNotProd ? heightWarningNotProd : 0;
    const heightHeader = document.querySelector('.header-container')?.offsetHeight;
    const defaultTopSize = heightHeader + heightWarningNotProd;
    //Duplicate variable just for readability
    const heightPixelsToHideImageHeader = heightHeader + heightWarningNotProd;
    const topSizeAfterScrollHeader = heightWarningNotProd;

    const [scale, setScale] = useState(0);
    const [top, setTop] = useState(document.querySelector(".preview-warning") ? 80 : 52 );

    // Function for move the horizontal scrollbar to top while scrolling
    window.addEventListener('scroll', function() {
        const pixelsUserHasScrolled = window.scrollY;
        if (pixelsUserHasScrolled > heightPixelsToHideImageHeader) {
            setTop(topSizeAfterScrollHeader)
        } else {
            setTop(defaultTopSize - pixelsUserHasScrolled)
        }
    });

    useEffect(() => {
        const getDocHeight = () => {
            return Math.max(
                document.body.scrollHeight, document.documentElement.scrollHeight,
                document.body.offsetHeight, document.documentElement.offsetHeight,
                document.body.clientHeight, document.documentElement.clientHeight
            );
        }

        const calculateScrollDistance = () => {
            const scrollTop = window.pageYOffset;
            const docHeight = getDocHeight()
            const winHeight = window.innerHeight
            const totalDocScrollLength = docHeight - winHeight;
            const newScrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100) / 100;
            setScale(newScrollPostion || 0);
        }

        const throttledHandler = throttle(calculateScrollDistance, 16);
        window.addEventListener('scroll', throttledHandler, { passive: true })
        window.addEventListener('resize', throttledHandler);

        calculateScrollDistance();

        return () => {
            window.removeEventListener('scroll', throttledHandler);
            window.removeEventListener('resize', throttledHandler);
        }

    });

    //styling for horizontal
   const horizontalContainer= {
    top: !isRedingMode ? top + 'px' : 0
}

//styling for horiziontal scroll bar transform
const transformStyle= {
    transform: `scaleX(${scale})`
 }


    return (
        <>
            <div className={cx("d-flex scrollbar-horizontal-container", className, { "fixed" : shouldBeFixed })} style={horizontalContainer}>
                <div
                    id="scrollbar-horizontal"
                    style={transformStyle}
                    className="scrollbar-horizontal">
                </div>
            </div>
        </>
    )
}


export default HorizontalScrollbar;
