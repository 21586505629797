import { ErrorCodes, ErrorMessages } from "../components/Constants/Errors";
import RETRIEVALSERVICES from "../services/rp-service";
import CONTEXTHELPERS from "./ContextHelpers";
import { logs, page } from "./log";

async function fetchBookmarkDashboard(url, memberFirmId) {
    try {
        var model = {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
            timezone_offset: CONTEXTHELPERS.getTimezoneOffset()
        };
        const response = await RETRIEVALSERVICES.retrieveBookmark(model);
        return response ? response : [];
    } catch (err) {
        logs.error(page.Bookmark, 'BookmarkServices', ErrorMessages.bookmarkFetch, err, { eventId: ErrorCodes.Bookmark });
    }
}

async function fetchBookmarks(url, memberFirmId, guid = null) {
    try {
        var model = {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
            timezone_offset: CONTEXTHELPERS.getTimezoneOffset(),
            reference_identifier: guid
        };
        return await RETRIEVALSERVICES.retrieveBookmark(model);
    } catch (err) {
        logs.error(page.Bookmark, 'BookmarkServices', ErrorMessages.bookmarkFetch, err, { eventId: ErrorCodes.Bookmark });
    }
}

async function createBookmark(payload, memberFirmId) {
    try {
        payload.query_spec_base_model = CONTEXTHELPERS.getUPSQuerySpecBaseModel(payload.page_url, memberFirmId);
        return await RETRIEVALSERVICES.createBookmark(payload);
    } catch (err) {

        logs.error(page.Bookmark, 'BookmarkServices', ErrorMessages.bookmarkCreate, err, { eventId: ErrorCodes.Bookmark });

    }
}

async function deleteBookmarks(url, guidArray) {
    try {
        const model = {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url),
            guids: guidArray
        };
        return await RETRIEVALSERVICES.deleteBookmark(model);
    } catch (err) {
        logs.error(page.Bookmark, 'BookmarkServices', "Error while deleting the Bookmark", err);
    }
}

const BookmarkService = {
    fetchBookmarkDashboard,
    fetchBookmarks,
    createBookmark,
    deleteBookmarks
};

export default BookmarkService;