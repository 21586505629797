import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import cx from 'classnames';
import ShowIf from '../Common/ShowIf';
import IconX from '../Common/IconX';
import { usePageContextLabels } from '../../contexts/PageContext';
import { useTocContext } from '../../contexts/TocContext';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { useLocation } from 'react-router';


function CommonSearchbar({
    isExpanded,
    isFixed,
    style,
    className,
    placeholder,
    showSearchLink,
    value,
    setValue,
    terms,
    onClose,
    ...props
}) {
    const inputRef = useRef();
    const [isActive, setIsActive] = useState(isExpanded); //start off with search closed
    const { getLabel } = usePageContextLabels();
    const [searchTermIndex, setSearchTermIndex] = useState(-1);
    const [searchTerms, setSearchTerms] = useState(terms);
    const [searchLink] = useState('#');
    const [hasChanged, setHasChanged] = useState(false);
    const { pubInfo, toc } = useTocContext();
    const location = useLocation();
    var context = CONTEXTHELPERS.generateContext(location.pathname);


    useEffect(() => {
        setSearchTerms(document?.querySelector('.search-results')?.querySelectorAll('em'));
    }, [props.filteredResultCount]);

    useEffect(() => {
        setIsActive(isExpanded);
    }, [isExpanded]);

    useEffect(() => {
        if (isActive && inputRef.current) inputRef.current.focus();
        if (!isActive) {
            if (document.getElementsByClassName('search-badge-article').length === 0) {
                setValue("");
            }
        }
    }, [isActive,setValue])

    useEffect(() => {
        setValue(value);
    }, [value,setValue]);

    useEffect(() => {
        setSearchTerms(terms);
        setSearchTermIndex(-1);
    }, [terms]);

    const scrollToSelectedSearch = (term) => {
        var elementToScroll = document.getElementById('annot-list') || document.getElementById('book-list');
        if (elementToScroll) {
            elementToScroll.scrollTop = term.offsetTop - 180;
        }
    }

    const handlePrevNext = (direction, event) => {
        event.preventDefault();

        let index = searchTermIndex;

        if (index >= 0) {
            searchTerms[index].classList.remove('current');
        }

        index += (direction === 'prev') ? -1 : 1;

        if (index < 0) {
            index = searchTerms.length - 1;
        }

        if (index > searchTerms.length - 1) {
            index = 0;
        }

        if (index >= 0) {
            searchTerms[index].classList.add('current');
            scrollToSelectedSearch(searchTerms[index]);
        }

        setSearchTermIndex(index);

        // if selected term is hidden, expand section
        if (searchTerms[index].offsetParent === null) {
            expandParentSections(index);
        }

        setTimeout(() => moveTo(index), 0);
    }

    const expandParentSections = (index) => {
        const el = searchTerms[index];
        const sectionsToExpand = [];
        let currentEl = el;

        while (currentEl !== null) {
            if (currentEl.hasAttribute('data-expandable-uuid') && !currentEl.classList.contains('open')) {
                sectionsToExpand.push(currentEl.dataset.expandableUuid);
            }
            currentEl = currentEl.parentElement.closest('[data-expandable-uuid]');
        }
    }

    const moveTo = (index) => {
        let offset = searchTerms[index].getBoundingClientRect().top + window.pageYOffset + -130;

        window.scrollTo({
            top: offset,
            left: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        setSearchTermIndex(-1);

    }, [value]);

    useEffect(() => {
        UTILITIESHELPER.setLocalStorage("rp-is-search-start", false);
    }, [isFixed]);

    function handleChange(event) {
        setValue(event.target.value);
        setHasChanged(true);
        UTILITIESHELPER.setLocalStorage("rp-is-search-start", false);
    }

    function handleClose() {
        setIsActive(prevVal => !prevVal);
        onClose();
    }

    function getTitle() {
        if (pubInfo?.title?.title) {
            return CONTEXTHELPERS.generateFolioTitleFromFilename(get(context, 'pageFileName', ''));
        }

        return toc?.nav_title || "<unknown>";
    }

    function handleKeyDown(e) {
        if (e.keyCode === 32) {
          e.preventDefault();
          const { startString, endString } = UTILITIESHELPER.handleSpaceBarOnSearchSubmit(e);
          setValue(startString + ' ' + endString);
          return false;
        }
      }
const searchIconStyling= { cursor: isActive ? 'default' : 'pointer' }
const  imageStyling= { margin: isActive ? 'auto 5px auto 0' : 'auto' }
const inputSearchboxStyling= { width: isActive ? '100%' : 0, padding: isActive ? '' : 0 }



    return (
        <div className="d-flex">
            <div
                style={style}
                className={cx("article-search-container",
                    {
                        "article-search-container-expanded": isActive,
                        "article-search-container-fixed": isFixed,
                    }, className
                )}
            >
                <div className="article-search-border">
                    <div className="btn-search-icon-article d-flex ml-auto" style={searchIconStyling} title={isActive ? '' : getLabel('s_Search', 'Search')}>
                        <div className="d-flex w-100 justify-center items-center">
                            <button className="search-click d-flex flex-grow justify-center items-center btn-search-click"  onClick={() => setIsActive(true)}>
                                <img style={imageStyling} src="/images/search-default.svg" alt="Search" />
                                <input ref={inputRef} className="input-search-article" placeholder={placeholder} value={value} style={inputSearchboxStyling} onChange={handleChange} onKeyDown={handleKeyDown} onClick={(e) => e.preventDefault()} />
                            </button>
                            <ShowIf condition={isActive}>
                                <div className="next-prev-counter d-flex items-center">
                                    {searchTermIndex + 1}/{searchTerms?.length}
                                </div>
                                <div className="next-prev-btns d-flex items-stretch">
                                    <button
                                        type="button"
                                        className="next-prev-down"
                                        onClick={(e) => handlePrevNext('next', e)} disabled={!searchTerms?.length}
                                    >
                                        <img src="/images/chevron-right-bl.svg" alt="down" />
                                    </button>
                                    <button
                                        type="button"
                                        className="next-prev-up"
                                        onClick={(e) => handlePrevNext('prev', e)} disabled={!searchTerms?.length}
                                    >
                                        <img src="/images/chevron-right-bl.svg" alt="up" />
                                    </button>
                                </div>
                                <div className="d-flex items-center">
                                    <IconX className="icon-close-x"
                                        onClick={handleClose}
                                    />
                                </div>
                            </ShowIf>
                        </div>
                    </div>

                </div>
                <ShowIf condition={isActive && showSearchLink}>
                    {value && !hasChanged && (<div className="allresults"><a className="next-prev-more" href={searchLink}>{getLabel('s_BackToResults', 'Back to search results')}</a></div>)}
                    {value && hasChanged && (<div className="allresults"><a className="next-prev-more" href={searchLink}>{getLabel('s_SeeAllResultsIn', 'See all results in')} {getTitle()}</a></div>)}
                </ShowIf>
            </div>
        </div>
    )
}


CommonSearchbar.defaultProps = {
    isExpanded: false,
    isFixed: false,
    style: {},
    className: '',
    placeholder: '',
    showSearchLink: false,
    value: '',
    setValue: () => { },
    terms: [],
    onClose: () => { },
    returnUrl: null
}

export default CommonSearchbar