import React, { useState, useRef,useEffect } from 'react';
import { useUserPreferencesContext, ACTIONS } from '../../contexts/AnnotationsContext';
import cx from 'classnames';
import ShowIf from '../Common/ShowIf';
import AnnotationSidePanelEdit from './AnnotationSidePanelEdit';
import ANNSERVICES from "../../helpers/AnnotationServices";
import {useTocContext} from "../../contexts/TocContext";
import useOutsideClick from '../../hooks/useOutsideClick';
import { usePageContext,usePageContextLabels } from "../../contexts/PageContext";
import { useLocation } from "react-router-dom";
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import { useArticleContext } from '../../contexts/ArticleContext';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';

const Annotation = (props) => {

    const { state: annState, dispatch, setSelectedAnnotation,selectedId } = useUserPreferencesContext();
    const { setTocAnnotations } = useTocContext();
    const [selectedCategory, setSelectedCategory] = useState();
    const { comment, category_guid, content, id, onClick, active, date, isAnnotationsVersionUpdated } = props;
    //const [categoryguid, setCategoryguid] = useState(category_guid);
    const isContentOnly = comment === "";
    const [hover, setHover] = useState(false);
    const Annotateitems = document.querySelectorAll('.highlighted');
    const items = Array.from(Annotateitems);
    const getMarkTag = items.filter(a => a.id === id);
    var catColor;
    var Contents = "";
    const ref = useRef();
    const [isDeleted,setIsDeleted] = useState(content === '' && isAnnotationsVersionUpdated === true); //eslint-disable-line
    const [isValid, setIsValid] = useState(isDeleted === true ? false : !isAnnotationsVersionUpdated);
    const { context, getMemberFirmID } = usePageContext();
    const {guidebookArticle } = useGuidebookTocContext();
    const { articleVersion } = useArticleContext();
    const { getLabel } = usePageContextLabels();
    const location = useLocation();

    for (var i = 0; i < getMarkTag.length; i++) {
        const getContent = getMarkTag[i].innerText;
        Contents += getContent;
    }
    const [editOpen, setEditOpen] = useState(false);

    useEffect(() => {
        const annotationPanelNodeList = document.querySelector(".annotation-panel-body")
        const annotatedSelected = annotationPanelNodeList.querySelector('.an-selected');
        if (annotatedSelected) {
           let sidePanelWindow= annotationPanelNodeList.getBoundingClientRect();
           let offset =annotatedSelected?.getBoundingClientRect().top - sidePanelWindow.top;
            annotationPanelNodeList.scrollTo({
                top: offset,
                left: 0,
                behavior: "smooth",
            });
        }
    }, [selectedId]);

    function generateTextTruncate(text) {
        if(!active || (editOpen === true)){
            const len = 100;
            const ending = '...';
            if (text && text.length > len) {
                return text.substring(0, 100) + ending;
            }
        }
        return text;
    }

    function categoryColor() {
        const categoryArr = annState.categories;
        if (category_guid) {
            const category = categoryArr.find(
                (obj) => obj.category_guid === category_guid
            );
            catColor = category?.color;
            return `4px solid ${catColor}`;
        }
    }

    function categoryName() {
        const categoryArr = annState.categories;
        if (category_guid) {
            const category = categoryArr.find((obj) => obj.category_guid === category_guid);
            return category?.label;
        }
    }

    function show(e) {
        e.preventDefault();
        if (category_guid) {
            const category = annState.categories?.find((obj) => obj.category_guid === category_guid);
            setSelectedCategory(category);
            setEditOpen((prevVal) => !prevVal);
        }
    }

    async function updateInvalidAnnotation() {
        if (annState.annotations.length > 0) {
            let annData = annState.annotations.find(function (ann) {
                return ann.annotation_guid === id;
            });
            annData.is_annotations_version_updated = false;
            

            const payload = {
                annotation_guid: id,
                category_guid: category_guid,
                page_url: annData.page_url,
                reference_identifier: CONTEXTHELPERS.getGUIDFromContext(context),
                raw_content: annData.raw_content,
                content: annData.content,
                range_position_start: annData.range_position.start,
                range_position_end: annData.range_position.end,
                closest_guid: annData.closest_guid,
                comment: annData.comment,
                version: guidebookArticle?.version ?? articleVersion,
                page_bread_crumb: annData.page_bread_crumb,
                is_annotations_version_updated:false,
                checked: false
            };
            const annotation = await ANNSERVICES.updateAnnotation(location.pathname, payload, getMemberFirmID());
            dispatch({
                type: ACTIONS.ANNOTATIONS_UPDATE,
                payload: annotation,
            });
            setTocAnnotations(prev => {
                return prev.map(item => { return item.annotation_guid === annotation.annotation_guid ? annotation : item });
            });
        }
        setIsValid(true)
    }

    function handleCategorySelect(category) {
        setSelectedCategory(category);
        document.dispatchEvent(new CustomEvent('dd:requestClose'));
    }

    const editUpdate = () => {
        setEditOpen(false);
        setSelectedAnnotation(0);
    }

    async function deleteSingleAnnotation() {
        const annDelete = await ANNSERVICES.deleteAnnotation(location.pathname, [id], getMemberFirmID());
        if (annDelete) {
            const payload = {
                annotation_guid: id,
            }
            dispatch({
                type: ACTIONS.ANNOTATIONS_REMOVE,
                payload: payload,
                deleteId: id
            });
            setTocAnnotations(prev => { return prev.filter(q => q.annotation_guid !== id) });
        }
    }

    function setHoverValue(hoverValue) {
        setHover(hoverValue ? true : false)
    }

    useOutsideClick(ref, editUpdate);

    //setting the borderleft CSS styling
    const boderLeftStyle= {
        borderLeft: categoryColor()
    };

    return (
        <div
            className={cx("an", {
                "an-comment": isContentOnly,
                "an-selected": active,
                "an-hovered": hover,
            })}
            onClick={onClick}
            onMouseEnter={() => setHoverValue(true)}
            onMouseLeave={() => setHoverValue(false)}
            //ref={ref}
        >
            <div className='update-annotation-text'>

            </div>
            <div className="category-color" style={boderLeftStyle}></div>
            <div className="annotation-category">
                <header className="an_header">
                    {isDeleted === false && isValid === false &&
                        <div className='an_annot-warning'></div>
                    }
                    {isDeleted === true &&
                        <div className='an_annot-deleted'></div>
                    }
                    <div className="an__title">{categoryName()}</div>
                    <ShowIf condition={active || hover}>
                        <div className="an__actions">
                            {isDeleted === false && isValid === false &&
                                <button className="an-dd-category-save" onClick={updateInvalidAnnotation}>
                                    {" "}
                                </button>
                            }
                            {isValid === true && isDeleted === false &&
                            <button className="an-dd-category-edit" onClick={(e) => show(e)}>
                                {" "}
                            </button>
                            }
                            <button className="an-dd-category-delete" onClick={deleteSingleAnnotation}></button>
                        </div>
                    </ShowIf>
                </header>
               
                <ShowIf condition={isDeleted === false && isValid === true}>
                    <div
                        className={cx("an__content", {
                            "an_content-only": isContentOnly,
                            "an_content-comment": !isContentOnly,
                        })}
                    >
                        {generateTextTruncate(Contents)}
                    </div>
                </ShowIf>
                <ShowIf condition={isValid === false && isDeleted === false}>
                    <div
                        className={cx("an__content", {
                            "an_content-only": isContentOnly,
                            "an_content-comment": !isContentOnly,
                        })}
                    >    
                        {
                        getLabel("hh_AnnotationUpdatedMessage","Highlighted content has been updated. Please accept or delete the updated annotation.")
                        }
                    </div>
                </ShowIf>
                <ShowIf condition={isDeleted === true}>
                <div
                        className={cx("an__content", {
                            "an_content-only": isContentOnly,
                            "an_content-comment": !isContentOnly,
                        })}
                    >
                      {
                        getLabel("hh_AnnotationDeletedMessage","Highlighted content is no longer available.")
                        }
                    </div>
                </ShowIf>
                <ShowIf condition={!isContentOnly}>
                    <div className="an__comment">
                        <div className="an__comment-text">
                            {generateTextTruncate(comment)}
                        </div>
                    </div>
                </ShowIf>
            </div>
            <ShowIf condition={editOpen}>
                <AnnotationSidePanelEdit
                    onClick={handleCategorySelect}
                    selected={selectedCategory}
                    onSelect={handleCategorySelect}
                    categoryselected={categoryName()}
                    date={date}
                    content={content}
                    comments={comment}
                    onChange={editUpdate}
                    {...props}
                />
            </ShowIf>
        </div>
    );
}

export default Annotation;