import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { get, replace } from 'lodash';
import { logs, page } from '../../helpers/log';
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import { useArticleContext } from '../../contexts/ArticleContext';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import { useTocContext } from '../../contexts/TocContext';

import ArticleTitle from '../Article/ArticleTitle';
import HTMLContent from '../Common/HTMLContent';
import MainPannel from '../Common/MainPannel';
import PublicationListingSearch from './PublicationListingSearch';
import PublicationListingResults from './PublicationListingResults';
import SearchHelpers from '../../helpers/SearchHelpers';
import TocBody from './TocBody';
import { ErrorCodes, ErrorMessages } from '../Constants/Errors';
import ShowIf from '../Common/ShowIf';

const PublicationListing = ({
    isWideMode,
    tocItems,
    ...props
}) => {
    const location = useLocation();
    const { getGuidebookSectionData } = useGuidebookTocContext();
    const { selectedItemTOC } = useTocContext();
    const { context, isRelatedLinksPage } = usePageContext();
    const { searchText, setSearchText } = useArticleContext();
    const { getLabel, getMFLabel } = usePageContextLabels();
   
    const queryParams = new URLSearchParams(location?.search);
    const searchPhraseParam = queryParams.get('searchPhrase');

    const mainPannelStyling = {
        width: isWideMode ? '69%' : 'calc(69% - 100px)',
        marginRight: isWideMode ? 'auto' : 100,
        marginLeft: 'auto',
        paddingTop: 24,
    }

    //todo
    const articleTitleStyling={ textAlign: 'center' }
    const tocBodyStyling={ paddingLeft: 20 }

    if (searchPhraseParam !== null) {
        sessionStorage.setItem('searchPhraseParam', searchPhraseParam);
    }

    function getTocSectionTitle(_selectedItemTOC) {
        const sectionFriendlyPath = get(_selectedItemTOC, "item.sectionFriendlyPath", "");
        try {
            if (sectionFriendlyPath == "related" && context.pageSelectedPublicationGUID !== "") {
                const relatedContext = _selectedItemTOC.item.context.filter((item) => {
                    return item.publicationType.Key === context.pageSelectedPublicationGUID;
                })[0];

                return getMFLabel(`lbl_Folio_TitleSectionRelated${replace(relatedContext.publicationType.Description, ' ', '')}`, `publicationType.Description:[lbl_Folio_TitleSectionRelated${replace(relatedContext.publicationType.Description, ' ', '')}]`)
            }
            else {
                const section = getGuidebookSectionData(sectionFriendlyPath);
                if (section) {
                    return getMFLabel(section.title.Content.title, "section.title.Content.title");
                }
            }
            return sectionFriendlyPath;
        }
        catch (ex) {
            logs.error(page.Publication, "PublicationListing", ErrorMessages.guidebookSection, ex,{eventId:ErrorCodes.Publication});
            return sectionFriendlyPath;
        }
    }

    useEffect(() => {
        let paramSessStore = sessionStorage.getItem('searchPhraseParam');
        if (paramSessStore !== null) {
            const searchVal = paramSessStore;
            if (document.getElementsByClassName('search-badge-article').length !== 0) {
                setSearchText(SearchHelpers.toHumanReadable(searchVal));
            }
        }
    }, [searchText]);

    if (searchPhraseParam) {
        return (
            <PublicationListingResults isWideMode={isWideMode} {...props} />
        )
    }

    if ((Array.isArray(tocItems) && tocItems.length > 0) && selectedItemTOC && !!get(selectedItemTOC, 'item.title', false)) {
        //const sectionFriendlyPath = get(selectedItemTOC, "item.sectionFriendlyPath", "");
        
        //Need to see if we are in the same "place" to draw the listing page
        //  OTHERWISE we draw the listing page of EVERY section within the folio (page flashes multiple times!)
        //if (context.pageSelectedSection == sectionFriendlyPath) {

            const listingPageTitle = (() => {
                return get(selectedItemTOC, "item.sectionFriendlyPath", false)
                    ? getTocSectionTitle(selectedItemTOC)
                    : get(selectedItemTOC, 'title', false)
                        ? (get(selectedItemTOC, 'rich_text_title', false)) ? selectedItemTOC.rich_text_title : selectedItemTOC.title
                        : (get(selectedItemTOC, 'item.rich_text_title', false)) ? selectedItemTOC.item.rich_text_title : selectedItemTOC.item.title;
            });

            //REFACTOR:Similar to UTILITIESHELPER.getContentItemTitle() perhaps we should use that?
            //var listingPageTitle = !selectedItemTOC ? null : get(selectedItemTOC, "item.sectionFriendlyPath", false)
            //    ? getTocSectionTitle(selectedItemTOC) : get(selectedItemTOC, 'title', false)
            //        ? (get(selectedItemTOC, 'rich_text_title', false)) ? selectedItemTOC.rich_text_title : selectedItemTOC.title
            //        : (get(selectedItemTOC, 'item.rich_text_title', false)) ? selectedItemTOC.item.rich_text_title : selectedItemTOC.item.title;

            return (
                <>
                    <MainPannel style={mainPannelStyling}>
                        <div>
                            <ArticleTitle title={listingPageTitle} style={articleTitleStyling} />
                            <ShowIf condition={!isRelatedLinksPage}>
                                <PublicationListingSearch />
                            </ShowIf>
                            <p className="article-abstract"><HTMLContent>{selectedItemTOC.item.abstract || selectedItemTOC.item.sectionIntro}</HTMLContent></p>
                            <TocBody style={tocBodyStyling} classNameList="toc-side-pannel-list-main-panel">
                                {tocItems}
                            </TocBody>
                            <p className="article-additional"><HTMLContent>{selectedItemTOC.item.additional}</HTMLContent></p>
                            <p className="article-notices">
                                <HTMLContent>
                                    {(selectedItemTOC.item.notices === '')
                                        ? getLabel("g_Copyright", `Copyright © ${new Date().getFullYear()} Deloitte Development LLC. All Rights Reserved`)
                                        : selectedItemTOC.item.notices
                                    }
                                </HTMLContent>
                            </p>
                        </div>
                    </MainPannel>
                </>
            )
        //}
    }

    return null;
}

PublicationListing.defaultProps = {}

export default PublicationListing;
