import React from 'react';
import { usePageContextLabels } from '../../../../contexts/PageContext';

function ListItem({ memberFirm, onClick, disabled,clearSearchMemberFirm }) {
    const { getLabel } = usePageContextLabels();

    function handleClick(e) {
        e.preventDefault();
        onClick(memberFirm.reference.toLowerCase());
        clearSearchMemberFirm("");
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            onClick(memberFirm.reference.toLowerCase());
        }
    }

    return (
        <div
            className={"mfdd__list-item " + (disabled ? 'disabled' : '')}
            onClick={handleClick}
            tabIndex="0"
            onKeyPress={handleKeyPress}
        >{getLabel(`mf_Library_${memberFirm.reference}`, memberFirm.name, true)}</div>
    );
}

export default ListItem;
