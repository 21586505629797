
import React from 'react';
import cx from 'classnames';

const TocBody = ({ children, style, classNameList, tocMaxHeight }) => {
    function calcMaxHeight() {

        if (tocMaxHeight !== undefined && tocMaxHeight !== 'none') 
        {
            return tocMaxHeight
        }
        else {
            var sidePanelSmall = document.querySelector('.side-pannel-small');

            if (sidePanelSmall !== null) {
                var sidePanelSmallDim = sidePanelSmall.getClientRects();
                var elemTop = sidePanelSmallDim[0].top;
                var maxHeightCalc = `calc((100vh - 61px) - ${elemTop > 0 ? Math.round(elemTop) : 1}px)`

                return maxHeightCalc;
            }

            return 'none'
        }
        
    }
    
    return (
        <div style={{ maxHeight: calcMaxHeight()}}
            className="side-pannel-body">
            <div className="manual">
                <div className="toc">
                    <ul className={cx("toc-side-pannel-list", classNameList)}>
                        {children}
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default TocBody;
