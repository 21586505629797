import React, { useState } from 'react';
import { usePageContextLabels } from '../../contexts/PageContext'; 
import Copyright from './Copyright';

const PublicationDetailsModal = ({setShouldShowArticlePubModal,publicationData}) => {
  
  const [shouldShowDarkOverlay,setShouldShowDarkOverlay] = useState(true);
  const {getLabel} = usePageContextLabels();
  
  const closeModal = () => {
    setShouldShowDarkOverlay(false);
    setShouldShowArticlePubModal(false);
  };

  return (
    <div>
    {shouldShowDarkOverlay && (<div className='full-screen-darken-overlay-fixed' />)}
    <div className="articlepub-modal">        
        <div className= "articlepub-modal-header">
          <p>{getLabel("b_PublicationDetails", "Publication Details")}</p>          
          <button className="articlepub-modal-close" onClick={closeModal}>X</button>
        </div>
        <div className="articlepub-modal-body">
          <div className= "articlepub-modal-block">          
          <p>{getLabel("b_PublicationType","Publication Type")} : {publicationData.publicationType}</p>
          <p>{getLabel("b_PublicationReleaseDate","Release Date")} : {publicationData.publicationReleaseDate}</p>
          <p>{getLabel("b_Title","Title")} : {publicationData.title}</p>
          <p>{getLabel("b_PublicationVersion","Version")} : {publicationData.publicationVersion}</p>
          <p>{getLabel("b_PublicationIndustry","Industry")} : {publicationData.industry}</p>
          <p>{getLabel("b_PublicationMemberFirm","Member Firm")} : {publicationData.memberFirm}</p>
          <p>{getLabel("b_PublicationKnowledgeDomain","Knowledge Domain")} : {publicationData.knowledgeDomain}</p>
          <p>{getLabel("b_PublicationAuditWorkflowAreaAccount","Audit Workflow Area Account")} : {publicationData.auditWorkflowAreaAccount}</p>
          <p>{getLabel("b_PublicationAuditWorkflowAreaNonAccount","Audit Workflow Area Non-Account")} : {publicationData.auditWorkflowAreaNonAccount}</p>
          </div>
        <Copyright/>
        </div> 
    </div>    
   </div>
  );
};

export default PublicationDetailsModal;