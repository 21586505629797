import React, { useState } from 'react'
import { usePageContextLabels } from '../../contexts/PageContext';
import { RESEARCH_ASSISTANT } from '../Constants/Constants';
import HTMLContent from '../Common/HTMLContent';

const TosDeclaration = ({ handleIAgreeClick, isConvoPage, userCountry }) => {
    const { getLabel } = usePageContextLabels();
    const [expandMore, setExpandMore] = useState(isConvoPage ? false : true);

    return (<div className="convo-box conversation-ra-response">
        <div className="ra-response">
            <div className="search-assistant">
                <div className='search-assistant-ico'>
                    <button className="search-assistant-ico-img">&nbsp;</button>
                </div>
                <div className='search-assistant-body'>
                    {expandMore ?
                        <>
                            <label className='search-assistant-title' data-testid='research-assistant-title'>{getLabel('ra_ResearchAssistantTitle', 'Research Assistant')}</label>
                            <div className='tosdeclaration-intro'>
                                <p className="intro-content" data-testid="intro-content">{getLabel('ra_Introduction', RESEARCH_ASSISTANT.raIntroduction)}</p>
                                <div className='response-opacity'></div>
                            </div>

                            <div className='search-expand'>
                                <button data-testid="read-more-button-tos-dec" className='read-more-button' onClick={() => setExpandMore(!expandMore)}>{getLabel('ra_expandtoreadmoretosdec', 'REVIEW AND ACCEPT ACCEPTABLE USE GUIDELINES')}</button>
                            </div>
                        </>
                        : <>
                            <label className='search-assistant-title' data-testid='research-assistant-title'>{getLabel('ra_ResearchAssistantTitle', 'Research Assistant')}</label>
                            <div className='tosdeclaration-intro'>
                                <p className="intro-content" data-testid="intro-content">{getLabel('ra_Introduction', RESEARCH_ASSISTANT.raIntroduction)}</p>
                            </div>

                            <div className='tosdeclaration-head'>
                                <h6 className='tosdeclaration-title' data-testid='tosdeclaration-title'>{getLabel('ra_TocTitle', 'Generative AI Research Assistant – Acceptable Use Guidelines')}</h6>
                            </div>
                            <div className='tosdeclaration-content'>
                                <HTMLContent>{getLabel('ra_TermsOfUse', RESEARCH_ASSISTANT.termsofuse)}</HTMLContent>
                            </div>
                            <div className='tosdeclaration-footer'>
                                <button data-testid="IAgreeTC" className="agree"  onClick={() => handleIAgreeClick()}>
                                    <span>{getLabel("ra_agree", "I Agree")}</span>
                                </button>
                            </div>

                        </>}

                </div>
            </div>
        </div>
    </div>);
}

export default TosDeclaration