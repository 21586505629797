import React from 'react';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import ReactHtmlParser from 'react-html-parser';
import ShowIf from '../Common/ShowIf';
import { getIconSrc } from '../../components/Article/File';
import ARTICLEHELPERS from '../../helpers/ArticleHelpers';
import { PAGE_CONTROLLER_TYPE } from '../Constants/Constants';

const ChatBotCatalogSearchResultItem = (props) => {
  const { viewAllResultsUrl, document, url, highlights } = props;
  const isFormatBinary = document.format !== "";
  let documentformatarray = document.format.split(',');

    function getDocumentTitle() {
        let documenttitle = "";
        if (url.includes("/" + PAGE_CONTROLLER_TYPE.folio) ||
            url.includes("/" + PAGE_CONTROLLER_TYPE.manual) ||
            url.includes("/" + PAGE_CONTROLLER_TYPE.standards) ||
            url.includes("/" + PAGE_CONTROLLER_TYPE.standard)) {
            documenttitle = UTILITIESHELPER.isStringNullorEmpty(document.navTitle) ?
                (UTILITIESHELPER.isStringNullorEmpty(document.publicationNavTitle) ? ReactHtmlParser(document.publicationTitle) : ReactHtmlParser(document.publicationNavTitle))
                : ReactHtmlParser(document.navTitle);
        }
        else {
            documenttitle = UTILITIESHELPER.isStringNullorEmpty(document.publicationNavTitle) ?
                (UTILITIESHELPER.isStringNullorEmpty(document.publicationTitle) ? '' : ReactHtmlParser(document.publicationTitle))
                : ReactHtmlParser(document.publicationNavTitle);
        }
        return documenttitle;
    }

  return (
    <div className="collection-result-item-main">
      <div className="collection-result-item-main-container">

        <h2
          className="result-title-icon"
        >
          <ShowIf condition={isFormatBinary}>
            {documentformatarray.map((element, index) => {
              return (
                <img
                  key={`binary-icon-image${index}`}
                  className="icon-images"
                  src={getIconSrc(element)}
                  alt="Binary File"
                />
              );
            })}
          </ShowIf>
          {(url.includes("/folio")) ? (
            <a href={viewAllResultsUrl}>{getDocumentTitle()}</a>
          ) : (
            <a href={url}>{getDocumentTitle()}</a>
          )}
        </h2>
      </div>
      <p className="snippet">{ReactHtmlParser(ARTICLEHELPERS.getHighlightedText(highlights))}</p>
    </div>
  );
}

export default ChatBotCatalogSearchResultItem;
