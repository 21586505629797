
import React, { createContext, useContext } from 'react'
import { useNavigate, useLocation } from "react-router-dom";

export const ReadingModeContext = createContext()
export const useReadingModeContext = () => useContext(ReadingModeContext)

const ReadingModeContextProvider = ({ children }) => {

    const navigate = useNavigate();
    const location = useLocation()
    const queryParams = new URLSearchParams(location?.search);
    const isReadingMode = queryParams?.get('reading-mode') === 'true';

    const setIsReadingMode = (val) => {
        let isTrue = val;
        if (val === 'toggle') {
            isTrue = !isReadingMode;
        }
        if (isTrue) {
            queryParams.append('reading-mode', 'true');
        } else {
            queryParams.delete('reading-mode');
        }

        navigate({ search: queryParams.toString() });
    }

    const store = {
        isReadingMode,
        setIsReadingMode,
    }

    return (
        <ReadingModeContext.Provider value={store}>
            {children}
        </ReadingModeContext.Provider>
    )
}

export default ReadingModeContextProvider;