import { ErrorCodes, ErrorMessages } from "../components/Constants/Errors";
import RETRIEVALSERVICES from "../services/rp-service";
import CONTEXTHELPERS from "./ContextHelpers";
import UTILITIESHELPER from "./UtilitiesHelper";
import { logs, page } from "./log";

async function fetchHistory(url, memberFirmId) {
    try {
        const recentSearchUserPreference = {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
            timezone_offset: new Date().getTimezoneOffset()
        };
        const RecentlyViewedList = await RETRIEVALSERVICES.getRecentHistory(recentSearchUserPreference);
        return RecentlyViewedList;
    } catch (err) {
        logs.error(page.History, 'HistoryServices', ErrorMessages.recentlyViewedFetch, err, { eventId: ErrorCodes.History });
    }
}

async function fetchCatalogHistory(url, memberFirmId, resultLimit) {
    try {
        const recentSearchUserPreference = {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
            timezone_offset: new Date().getTimezoneOffset(),
            request_count: resultLimit ? resultLimit : 3
        };
        const RecentlyViewedList = await RETRIEVALSERVICES.getRecentHistory(recentSearchUserPreference);
        return RecentlyViewedList;
    } catch (err) {
        logs.error(page.History, 'HistoryServices', ErrorMessages.recentlyViewedFetch, err, { eventId: ErrorCodes.History });
    }
}

async function fetchHistoryDashboard(url, memberFirmId) {
    try {
        const recentSearchUserPreference =
        {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
            timezone_offset: new Date().getTimezoneOffset(),
            request_count: 50
        };

        const RecentlyViewedList = await RETRIEVALSERVICES.getRecentHistoryDashbaord(recentSearchUserPreference);
        return RecentlyViewedList;
    } catch (err) {
        logs.error(page.History, 'HistoryServices', ErrorMessages.recentlyViewedFetch, err, { eventId: ErrorCodes.History });
    }
}

function updateHistoryObject(url, title, memberFirmId, context = {}, recentViewHistory = {}) {
    const recentSearchUserPreference = {
        relative_url: url,
        title: UTILITIESHELPER.removeSpecialCharacterFromUrlText(title),
        created_date: JSON.parse(JSON.stringify(new Date()))
    };  
    let currentHistoryObj = {}
    const memberFirm = context.memberFirm || ''
    const knowledgeDomain = context?.knowledgeDomain || ''
    const recentlyViewCount = window.DART.ENV.REACT_APP_RECENTLY_VIEWED_LIMIT
    if(recentViewHistory) {
        let parseData = recentViewHistory
        if(parseData[memberFirm]) {
            let previosKdList = parseData[memberFirm]?.[knowledgeDomain] 
            if(previosKdList?.length) {
                const findIndex = previosKdList.findIndex(kd => 
                    kd.relative_url.toString() === recentSearchUserPreference.relative_url.toString()
                )
                if(findIndex > -1) {
                    previosKdList.splice(findIndex, 1)
                }
                previosKdList.unshift(recentSearchUserPreference)
                previosKdList.length > recentlyViewCount && previosKdList.pop()
                parseData[memberFirm][knowledgeDomain] = previosKdList
            } else {
                const currentData = []
                currentData.unshift(recentSearchUserPreference)
                parseData[memberFirm][knowledgeDomain] = currentData
            }
        } else {
            const currentData = []
            currentData.unshift(recentSearchUserPreference)
            parseData[memberFirm] = {
                [knowledgeDomain]: currentData
            }
        }
        currentHistoryObj = parseData
    } else {
        const currentData = []
        currentData.unshift(recentSearchUserPreference)
        if(memberFirm && knowledgeDomain) {
            currentHistoryObj = {
                [memberFirm]: {
                    [knowledgeDomain]: currentData
                }
            }

        }
    }
    UTILITIESHELPER.setLocalStorageJSON('recent-view-history', currentHistoryObj)
    return currentHistoryObj
}

const RecentViewedService = {
    fetchHistory,
    updateHistoryObject,
    fetchHistoryDashboard,
    fetchCatalogHistory
};

export default RecentViewedService;
