import React from 'react';
import cx from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import xss from 'xss';
import HTMLContent from '../../Common/HTMLContent';
import LinkHelpers from '../../../helpers/LinkHelpers';
import { usePageContext, usePageContextLabels } from '../../../contexts/PageContext';
import { useReadingModeContext } from '../../../contexts/ReadingModeContext';
import { useThemeContext } from '../../../contexts/ThemeContext';


const Footer = ({className}) => {
    const { context, footerLinks, isCatalogPage } = usePageContext();
    const { getLabel, getMFLabel } = usePageContextLabels();
    const { isReadingMode } = useReadingModeContext();
    const { isDarkMode } = useThemeContext();

    const createMarkupLink = (idx, link) => {
        if (link.linkInternal?.BinaryContent) {
            const linkSanitized = xss(`<a href="${link.linkInternal.BinaryContent.Url}" target="_blank">${getMFLabel(link.linkText, "link.linkText")}</a>`)
            return " " + linkSanitized;
        }
        if (link.linkExternal) {
            const linkSanitized = xss(`<a href="${LinkHelpers.resolveLandingPageLink(context, link.linkExternal)}" target=${link.linkExternal.startsWith('/') && !link.linkExternal.includes("content/cookies-notice") ? "" : "_blank"}>${getMFLabel(link.linkText, "link.linkText")}</a>`)
            return " " + linkSanitized;
        }
        //Plain html/text-output
        const linkSanitized = xss(getMFLabel(link.linkText, "link.linkText"));
        //WE only want a space BEFORE the text if its not a word
        if ((idx !== 0) && ((linkSanitized.length > 1) || (",.;/".indexOf(linkSanitized) === -1))) {
            return " " + linkSanitized;
        }
        return linkSanitized;
    }
    const isFooterLinksAnArray = Array.isArray(footerLinks) && footerLinks.length > 0;
    
    return (
        <footer className={cx("footer d-flex", { "footer-reading-mode": isReadingMode, "footer-catalog": isCatalogPage && !isDarkMode }, className)}>
            <div className="footer-item">
                {
                    isFooterLinksAnArray ? (
                        <>
                            {
                                footerLinks.map((link, idx) => (
                                    ReactHtmlParser(createMarkupLink(idx, link))
                                ))
                            }
                            <br />
                            <HTMLContent>
                                {getLabel("g_CopyrightFooter", "Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited (“DTTL”), its global network of member firms, and their related entities (collectively, the “Deloitte organization”). DTTL (also referred to as “Deloitte Global”) and each of its member firms and related entities are legally separate and independent entities, which cannot obligate or bind each other in respect of third parties. DTTL and each DTTL member firm and related entity is liable only for its own acts and omissions, and not those of each other. DTTL does not provide services to clients. Please see <a href=\"http://www.deloitte.com/about\" target=\"_blank\">www.deloitte.com/about</a> to learn more.")}
                            </HTMLContent>
                        </>
                    ) : (
                    <HTMLContent>
                        {getLabel("g_Copyright", `Copyright © ${new Date().getFullYear()} Deloitte Development LLC. All Rights Reserved`)}
                    </HTMLContent>
                    )
                }
            </div>
        </footer>
    )
}

export default Footer;
