
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'
import cx from 'classnames';

import ShowIf from "../Common/ShowIf"
import JumpLinksList from './JumpLinksList';
import IconX from '../Common/IconX';
import useOutsideClick from '../../hooks/useOutsideClick';
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';

import { getJumpLinks, hasJumpLinks } from '../../helpers/JumpLinkHelpers';

const ArticleTOC = ({ isOpen, setIsOpen, className, classNameContainer, pubData }) => {
    const iconClass = isOpen ? 'icon-article-toc-open' : '';
    const tocRef = useRef();
    const location = useLocation();
    const isGuidebook = location.pathname.indexOf('/folio/') !== -1;
    const [jumpLinks, setJumpLinks] = useState([]);
    const [containsJumpLinks] = useState(hasJumpLinks(pubData, isGuidebook));
    const { getLabel } = usePageContextLabels();
    const { isJournalPage } = usePageContext();

    useOutsideClick(tocRef, () => {
        setIsOpen(false)
    }, ['#article-toc-fixed']);

    useEffect(() => {
        // only build jump links on open and if they have not been generated before
        if (isOpen && jumpLinks.length === 0) {
            setJumpLinks(getJumpLinks(pubData, isGuidebook, isJournalPage));
        }
    }, [isOpen]);//eslint-disable-line

    const closeToc = useCallback(() => setIsOpen(false), []);//eslint-disable-line

    const toggleToc = () => setIsOpen(prevVal => !prevVal);
 
    //Styling for Icon props
const iconX ={
     margin: 'auto',
     marginRight: 10 , 
}


    return (
        <div
            id="article-toc-fixed"
            ref={tocRef}
            className={ containsJumpLinks ? cx("d-flex flex-column relative", classNameContainer) : 'invisible'}>
            <ShowIf condition={isOpen}>
                <div className={cx("article-toc article-toc-open absolute", className)}>
                    <div className="article-toc-header-container">
                        <button
                            className={cx("icon-article-toc", iconClass)}
                            onClick={toggleToc}
                        ></button>
                        <h2 className="article-toc-header-text">{getLabel("a_InArticleTocContents", "Contents")}</h2>
                        <IconX
                            style={iconX}
                            onClick={toggleToc}
                        />
                    </div>
                    <div>
                        {jumpLinks.length > 0 &&
                            <JumpLinksList scrollOffsetCompensation={-100} closeToc={closeToc} jumpLinks={jumpLinks} />
                        }
                    </div>
                </div>
            </ShowIf>
            <button className={cx("icon-article-toc", iconClass)} onClick={toggleToc}></button>
        </div>
    )
}

ArticleTOC.defaultProps = {
    setIsOpen: () => { },
}

export default ArticleTOC;
