import { useEffect, useState,useRef } from 'react';
import { useQuery } from 'react-query';
import RETRIEVALSERVICES from './rp-service';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import CONTEXTHELPERS from "../helpers/ContextHelpers";
import { FOLIO_SUBJECT_ACCOUNT, FOLIO_SUBJECT_NON_ACCOUNT } from '../components/Constants/Constants';
import UTILITIESHELPER from '../helpers/UtilitiesHelper';
import { logs, page } from '../helpers/log';
import { ErrorCodes, ErrorMessages } from '../components/Constants/Errors';
import { usePageContext } from '../contexts/PageContext';

/**
 * Custom hook to get User Information API using bearer token obtained from MSAL
 * @returns
 */
const useSearchService = () => {
    const { apiCall } = useFetchWithMsal();
    const { context, getMemberFirmIDs,getMemberFirmLanguages,getMemberFirmDefaultLanguage } = usePageContext();

    const SearchEndPoint = (window.DART && window.DART.ENV) ? window.DART.ENV.REACT_APP_SEARCH_ENDPOINT_API : undefined;
    const customheaders = { "INK_CONTENT_PRERELEASE": (window.DART && window.DART.ENV) && (window.DART.ENV.RP_REACT_INK_CONTENT_PRERELEASE ? window.DART.ENV.RP_REACT_INK_CONTENT_PRERELEASE : window.DART.ENV.RP_REACT_APP_PREVIEW) };
    /**
     * Execute a fetch request with the given options
     * @returns JSON response
     */
    // Used to prevent state updates after unmount
    const mounted = useRef(true);
    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    const getQueryKey = (querySpec,keyprefix) => {
        let queryKey = `${keyprefix}-${[...querySpec.knowledge_domain]}-${[...querySpec.member_firm]}`;
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.publication_type)) {
            queryKey = `${queryKey}-${querySpec.publication_type}`;
        }
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.tier)) {
            queryKey = `${queryKey}-${[...querySpec.tier]}`;
        }
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.industry)) {
            queryKey = `${queryKey}-${[...querySpec.industry]}`;
        }
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.audit_workflow_area_account)) {
            queryKey = `${queryKey}-${[...querySpec.audit_workflow_area_account]}`;
        }
        if (!UTILITIESHELPER.isArrayNullorEmpty(querySpec.audit_workflow_area_nonaccount)) {
            queryKey = `${queryKey}-${[...querySpec.audit_workflow_area_nonaccount]}`;
        }
        return queryKey
    }

    //BDP:TODO:THIS SHOULD RECEIVE THE PageContext object! IT SHOULD NOT GET IT OR GENERATE IT (AS ITS DONE TWICE IN THIS FUNCTION ALONE!!!)
    const generateQuerySpec = (publicationTypes, searchPhrase, result_limit) => {
        const knowledgeDomainID = CONTEXTHELPERS.getknowledgeDomainId(context.knowledgeDomain);
        
        let {tier, industry} = context;
        tier = tier ? tier : '';
        industry = industry ? industry : '';

        let audit_workflow_area_account = [];
        let audit_workflow_area_nonaccount = [];

        //Dynamic queries are only there in Guidebooks (for now)
        if (context.pageControllerType === "folio")
        {
            //e.g. Direction-Supervision-and-Review_66019141-66029848
            const pageDynamicQuery = context.pageFileName.split("_");
            if (pageDynamicQuery.length > 1)
            {
                //e.g. 66019141-66029848
                const pageDynamicCategory = pageDynamicQuery[1].split("-");
                if (pageDynamicCategory.length > 1)
                {
                    //e.g. 66019141
                    switch (pageDynamicCategory[0])
                    {
                        case FOLIO_SUBJECT_ACCOUNT.accountBalance:
                        case FOLIO_SUBJECT_ACCOUNT.auditingAccountingFolios:
                        case FOLIO_SUBJECT_ACCOUNT.accountingFolios:
                            audit_workflow_area_account = [ pageDynamicCategory[1] ]; //e.g. 66029848
                            break;

                        case FOLIO_SUBJECT_NON_ACCOUNT.nonAccountBalance:
                        case FOLIO_SUBJECT_NON_ACCOUNT.auditingNonAccountFolios:
                        case FOLIO_SUBJECT_NON_ACCOUNT.assuranceFolios:
                            audit_workflow_area_nonaccount = [ pageDynamicCategory[1] ]; //e.g. 66029848
                            break;
                    }
                }
            }
        }

        return {
            toc: false,
            result_limit: result_limit,
            member_firm: getMemberFirmIDs(),
            search_phrase: searchPhrase,
            default_language: getMemberFirmDefaultLanguage(),
            languages:getMemberFirmLanguages(),
            knowledge_domain_from_url: context.knowledgeDomain,
            knowledge_domain: [knowledgeDomainID],
            publication_type: publicationTypes ? publicationTypes : null,
            publication_id:[], // default
            tier: CONTEXTHELPERS.getTierIds(context.memberFirm, context.knowledgeDomain),
            industry: CONTEXTHELPERS.getIndustryIds(context.memberFirm, context.knowledgeDomain),
            audit_workflow_area_account,
            audit_workflow_area_nonaccount,
            effective_date: new Date(),
            version: 0
        }
    }

    const getPublicationTypesByCatalogSection = (catalogsection, KnowledgeDomain) => {
        let PublicationType = [];
        switch (catalogsection) {
                case "Folios":
                case "folio":
                    //Depending on knowledge domain, we have a different list...
                    if (KnowledgeDomain ==="17573178")
                    {
                        //Publication Types that are in an Accounting Folio
                        PublicationType.push("60194863"); //Roadmap (can appear in a folio and as stand alone manual (so will present as a manual!!)
                    }
                    // For Auditing and Assurance
                    else
                    {
                        PublicationType.push("60194861");  //Practical Guides //NOT USED (JENF)
                        PublicationType.push("65683594");
                        PublicationType.push("60194864");  // Announcements
                        PublicationType.push("65511719");  // Announcements External
                        PublicationType.push("66029857");  //RP Other Guidance	 66029857
                        PublicationType.push("66029858"); //FTEO Guide
                        PublicationType.push("68169717"); //Template Guide (Content Types)
                        PublicationType.push("58035381");
                        PublicationType.push("68489711");
                    }
                    break;
                //Structure Standard (spine publication)"
                case "Standards":
                case "standards":
                    PublicationType.push("48082111");
                    PublicationType.push("60194865");//Standard Section - Numbered
                    break;
                 //Structure Manual (spine publication)
                case "Manuals":
                case "manuals":
                    PublicationType.push("48082110"); //Manual Section
                    PublicationType.push("60194863");
                    PublicationType.push("60194866");//Roadmaps
                    break;
                case "Journals":
                case "deloitte-communications":
                    PublicationType.push("65511719");
                    PublicationType.push("60194864");
                    break;
                default:
                    //Else we have a Journal/other that we are not going to change as its already the ID we need.
                    break;
        }

        return PublicationType;
    }

    const getSearchParamsQuerySpec = (searchQuerySpec,pageType,searchParams) => {
        let PublicationType = [];

        if(!UTILITIESHELPER.isStringNullorEmpty(searchParams)) {
            let pubIDs = searchParams.split("/");
            switch(pubIDs[0]) {
                case "publication_id":
                    searchQuerySpec.publication_id = [...pubIDs];
                    searchQuerySpec.publication_id.splice(0,1);
                    //searchQuerySpec.publication_type = getPublicationTypesByCatalogSection(pageType,searchQuerySpec.knowledge_domain);
                    break;               
                default:
                    break;
            }
        }
        return searchQuerySpec;
    }

    async function getCatalogSearchResponses(catalogSection, searchPhrase, resultLimit) {
        let querySpec = generateQuerySpec([],searchPhrase,resultLimit);      
        let dataFormated = [];
        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(querySpec);
        let request = {
            url: `${SearchEndPoint}/search/${catalogSection}`,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestOptions.body)
        }

        try {
            const data = await apiCall(request, customheaders);
            dataFormated = (data === undefined || data?.length === 0) ? [] : data;
            logs.debug(page.Search, 'getCatalogSearchResponses', "useSearchService::getCatalogSearchResponses:[apiCall collectionType, dataFormated]:", searchPhrase, dataFormated);
        }
        catch (error) {
            logs.debug(page.Search, 'getCatalogSearchResponses', "useSearchService::getCatalogSearchResponses DEBUG ERROR:[searchPhrase, request, error]:", searchPhrase, request, error);
            logs.error(page.Search, 'getCatalogSearchResponses', 'Error with search result for ' + [searchPhrase], error, { eventId: ErrorCodes.Search });
            dataFormated = null;
        }

        return dataFormated;
    };

    async function getCollectionSearchResponses(pageType, searchPhrase, resultLimit, searchParams) {
        let querySpec = generateQuerySpec([], searchPhrase, resultLimit);
        let searchquerySpec = getSearchParamsQuerySpec(querySpec, pageType, searchParams);
        //querySpec.publication_type = getPublicationTypesByCatalogSection(pageType,querySpec.knowledge_domain);
        let dataFormated = [];        
        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(searchquerySpec);
        let request = {
            url: `${SearchEndPoint}/search/${pageType}`,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestOptions.body)
        }

        try {
            const data = await apiCall(request, customheaders);
            dataFormated = (data === undefined || data?.length === 0) ? [] : data;
            logs.debug(page.Search, 'getCollectionSearchResponses', "useSearchService::getCollectionSearchResponses:[apiCall collectionType, dataFormated]:", searchPhrase, dataFormated);
        }
        catch (error) {
            logs.debug(page.Search, 'getCollectionSearchResponses', "useSearchService::getCollectionSearchResponses DEBUG ERROR:[searchPhrase, request, error]:", searchPhrase, request, error);
            logs.error(page.Search, 'getCollectionSearchResponses', 'Error with search result for ' + [searchPhrase], error, { eventId: ErrorCodes.Search });
            dataFormated = null;
        }

        return dataFormated;
    };

    async function getPublicationSearchResponses(pageType, searchPhrase, resultLimit, searchParams) {
        let querySpec = generateQuerySpec([],searchPhrase,resultLimit);
        let searchquerySpec = getSearchParamsQuerySpec(querySpec,pageType,searchParams);
        let dataFormated = [];
        let requestOptions = RETRIEVALSERVICES.contextPostRequestOptions(searchquerySpec);
        let request = {
            url: `${SearchEndPoint}/search/${pageType}`,
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(requestOptions.body)
        }

        try {
            const data = await apiCall(request, customheaders);
            dataFormated = (data === undefined || data?.length === 0) ? [] : data;
            logs.debug(page.Search, 'getPublicationSearchResponses', "useSearchService::getPublicationSearchResponses:[apiCall collectionType, dataFormated]:", searchPhrase, dataFormated);
        }
        catch (error) {
            logs.debug(page.Search, 'getPublicationSearchResponses', "useSearchService::getPublicationSearchResponses DEBUG ERROR:[searchPhrase, request, error]:", searchPhrase, request, error);
            logs.error(page.Search, 'getPublicationSearchResponses', 'Error with search result for ' + [searchPhrase], error, { eventId: ErrorCodes.Search });
            dataFormated = null;
        }

        return dataFormated;
    };

    //NOTE: YOU HAVE TO INCLUDE YOUR USE CODE EXACTLY AS THEY APEAR:
    //const [getCatalogSearchResponses, getCollectionSearchResponses, getPublicationSearchResponses] = useSearchService();
    return {
        getCatalogSearchResponses,
        getCollectionSearchResponses,
        getPublicationSearchResponses
    };
};

export default useSearchService;
