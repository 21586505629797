import React from 'react'

export const BookmarkIcon = ({ fillcolor, onClick }) => {
   let  foreColor='';
  function textColor(bgColor) {
    let threshold = 130;
    let hRed = hexToR(bgColor);
    let hGreen = hexToG(bgColor);
    let hBlue = hexToB(bgColor);
    function hexToR(h) { return parseInt((cutHex(h)).substring(0, 2), 16) }
    function hexToG(h) { return parseInt((cutHex(h)).substring(2, 4), 16) }
    function hexToB(h) { return parseInt((cutHex(h)).substring(4, 6), 16) }
    function cutHex(h) { return (h.charAt(0) === "#") ? h.substring(1, 7) : h }
    let cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
    if (cBrightness > threshold) { return "#000000"; } else { return "#ffffff"; }
  }
  
  foreColor = textColor(fillcolor);

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill={fillcolor} onClick={onClick} className='bookmark-svg'
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.375" fill={fillcolor} stroke="black" strokeWidth="1.25" />
      <path d="M16 1.33714C24.0646 1.33714 30.6629 7.93543 30.6629 16C30.6629 24.0646 24.0646 30.6629 16 30.6629C7.93543 30.6629 1.33714 24.0646 1.33714 16C1.33714 7.93543 7.93543 1.33714 16 1.33714ZM16 0C7.13333 0 0 7.13333 0 16C0 24.8667 7.13333 32 16 32C24.8667 32 32 24.8667 32 16C32 7.13333 24.8667 0 16 0Z"
        fill="#000" background="green" />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M20.5 23V23C20.4162 23.0004 20.3337 22.9798 20.26 22.94L16 20.575L11.74 22.935V22.935C11.501 23.0734 11.1951 22.9918 11.0567 22.7529C10.9949 22.6463 10.9747 22.5206 11 22.4L11.95 17.665L8.64496 14.355H8.64496C8.44777 14.1617 8.44464 13.8451 8.63796 13.6479C8.72109 13.5631 8.83169 13.5107 8.94996 13.5L13.66 13.03L15.54 8.79498V8.79498C15.6514 8.54231 15.9465 8.4278 16.1992 8.53921C16.3134 8.58956 16.4046 8.68079 16.455 8.79498L18.335 13.03L23.05 13.5V13.5C23.3247 13.5274 23.5253 13.7724 23.4978 14.0471C23.4864 14.1615 23.436 14.2685 23.355 14.35L20.045 17.66L21 22.4V22.4C21.0552 22.6705 20.8807 22.9347 20.6102 22.99C20.5739 22.9974 20.537 23.0007 20.5 23L20.5 23Z"
        fill={foreColor} />
    </svg>
  )
}
