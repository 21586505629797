import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useArticleContext } from "../../contexts/ArticleContext";
import IconX from '../Common/IconX';
import ShowIf from '../Common/ShowIf';
import { scrollElementIntoView } from "./JumpLinksList";
import HTMLContent from '../Common/HTMLContent';

const FootnotePopup = ({ dependenciesToRecalculatePosition }) => {
    const [footnotePopupCoords, setFootnotePopupCoords] = useState({ x: 0, y: 0 });
    const [footnoteVerticalPosition, setFootnoteVerticalPosition] = useState('below');
    const isFootnotePositionedBelow = footnoteVerticalPosition === 'below';
    const { selectedFootnotePopup, setSelectedFootnotePopup, footNotesContentRef } = useArticleContext();
    const location = useLocation();

    const currentFootnote = (footNotesContentRef.current || []).find(footnote => footnote.number.toString() === selectedFootnotePopup);
    const currentFootNotesText = currentFootnote?.text || '';
    const modalDimensions = { height: 200, width: 425 };
    const offsetY = 15;

    const setPopupPosition = (_footnoteNum, el) => {
        if (!el) return;
        const popupBounds = el.getBoundingClientRect();
        const additionalPaddingBottom = 20;
        const amountPastViewportY = popupBounds.bottom + modalDimensions.height + offsetY + additionalPaddingBottom - window.innerHeight;
        const amountPastViewportXLeft = popupBounds.left - modalDimensions.width / 2 - 30;
        const amountPastViewportXRight = popupBounds.right + modalDimensions.width / 2 + 30 - window.innerWidth;
        const offsetXLeft = amountPastViewportXLeft < 0 ? amountPastViewportXLeft : 0;
        const offsetXRight = amountPastViewportXRight > 0 ? amountPastViewportXRight : 0;
        const articleContainer = document.querySelector('.article-container');
        const bounds = articleContainer.getBoundingClientRect();
        const x = ((popupBounds.left + popupBounds.right) / 2) - bounds.left - offsetXLeft - offsetXRight;
        const y = ((popupBounds.top + popupBounds.bottom) / 2) - bounds.top;
        setFootnoteVerticalPosition(amountPastViewportY > 0 ? 'above' : 'below');        
        setFootnotePopupCoords({ x, y });
    };

    useEffect(() => {
        const handleDocumentClick = (ev) => {
            if (!ev.target.classList.contains('footnote-link')) return;
            const footnoteNum = ev.target.dataset.footnoteNum;
            setSelectedFootnotePopup(footnoteNum);
            ev.preventDefault();
            setPopupPosition(footnoteNum, ev.target);
        };

        window.addEventListener('click', handleDocumentClick);
        return () => {
            window.removeEventListener('click', handleDocumentClick);
        }

    }, []);//eslint-disable-line

    useEffect(() => {
        const handleDocumentResize = () => {
            if (!selectedFootnotePopup) return;
            setPopupPosition(selectedFootnotePopup);
        }

        window.addEventListener('resize', handleDocumentResize);
        return () => {
            window.removeEventListener('resize', handleDocumentResize);
        }

    }, [selectedFootnotePopup])//eslint-disable-line

    useEffect(() => {
        if (!selectedFootnotePopup) return;
        setPopupPosition(selectedFootnotePopup);

    }, [...dependenciesToRecalculatePosition]);//eslint-disable-line

    useEffect(() => {
        setSelectedFootnotePopup();
        return () => setSelectedFootnotePopup()
    }, [location?.pathname]);//eslint-disable-line

    return (
        <ShowIf condition={selectedFootnotePopup}>
            <div
                className="d-flex"
                id="modal-footnote-popup"
                style={{
                    transform: `translate(-50%, ${isFootnotePositionedBelow ? '0' : '-100%'}) `,
                    left: footnotePopupCoords.x,
                    top: footnotePopupCoords.y + (isFootnotePositionedBelow ? offsetY : -1 * offsetY),
                    width: modalDimensions.width,
                    maxHeight: modalDimensions.height,
                }}
            >
                <div className="footnote-popup-text-container">
                    <div
                        className="footnote-popup-superscript"
                        onClick={() => {
                            const footnoteToScrollTo = document.getElementById(`footnote-${selectedFootnotePopup}`);
                            scrollElementIntoView(footnoteToScrollTo, -80);
                            setSelectedFootnotePopup(false);
                        }}
                    >
                        {currentFootnote && currentFootnote.label}
                    </div>
                    <span className="footnote-popup-inner-html"><HTMLContent>{currentFootNotesText}</HTMLContent></span>
                </div>
                <IconX
                    className="icon-x-footnote-popup"
                    onClick={() => setSelectedFootnotePopup(false)}
                />
            </div>
        </ShowIf>

    )
};

export default FootnotePopup;
