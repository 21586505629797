import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import AnnotationItem from './AnnotationItem';
import ANNSERVICES from "../../helpers/AnnotationServices";
import AnnotationDeleteModal from "./AnnotationDeleteModal";
import CommonSearchbar from '../Common/CommonSearchbar';
import CategoryDropdown from "../Common/CategoryDropdown";
import DateSelector from '../Common/DateSelector';
import { useUserPreferencesContext, ACTIONS as ANN_ACTIONS } from '../../contexts/AnnotationsContext';
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import { useLoadingContext } from '../../contexts/LoadingContext';
import { useLocation } from "react-router-dom";
import CategoryService from '../../helpers/CategoryServices';


const AnnotationsDashboard = () => {
    const [annotations, setAnnotations] = useState([])
    const [filteredAnnotations, setFilteredAnnotations] = useState([])
    const [annotationCategories, setAnnotationCategories] = useState([])
    const [value, setValue] = useState('');
    const [searchTerms, setSearchTerms] = useState(0);
    const { context, getMemberFirmID } = usePageContext();
    const { getLabel } = usePageContextLabels();
    const { state: annState, viewClickedAnnotation, setViewClickedAnnotation,dispatch } = useUserPreferencesContext();
    const [selectedAnnotationType, setSelectedAnnotationType] = useState("viewAll");
    const [deleteGuids, setDeleteGuids] = useState([]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState();
    const [shouldShowOverlayDelete, setShouldShowOverlayDelete] = useState(false);
    const [messageDelete, setMessageDelete] = useState('');
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedDate, setSelectedDate] = useState();
    const [isAllCategorySelected, setIsAllCategorySelected] = useState(false);

    const [filteredViewedAnnotation, setFilteredViewedAnnotation] = useState([]);
    const [resetDates, setResetDates] = useState(false);
    const [dateFilter, setDatefilter] = useState(false);

    const { setIsLoading } = useLoadingContext(true)
    const location = useLocation();
    const memberFirmId= getMemberFirmID();

    useEffect(() => {
        return () => {
            setViewClickedAnnotation(null);
        }
    }, [])//eslint-disable-line

    useEffect(() => {
        if (viewClickedAnnotation && filteredAnnotations) {
            let newAnnotData = filteredAnnotations.filter(item => item.reference_identifier === viewClickedAnnotation)
            setFilteredViewedAnnotation(newAnnotData);
        }
        else {
            setFilteredViewedAnnotation([...filteredAnnotations]);
        }
    }, [filteredAnnotations, viewClickedAnnotation])

    useEffect(() => {
        const fetchCategories = async () => {
            var categories = await CategoryService.fetchCategories(location.pathname, memberFirmId);
            dispatch({
                type: ANN_ACTIONS.CATEGORY_LOAD,
                payload: categories,
            });
        };

        const fetchAnnotations = async () => {
            var annotations = await ANNSERVICES.fetchAnnotations(location.pathname, memberFirmId);
            dispatch({
                type: ANN_ACTIONS.ANNOTATIONS_LOAD,
                payload: annotations,
            });
        };

        fetchCategories();
        fetchAnnotations();
        return () => {
            dispatch({
                type: ANN_ACTIONS.ANNOTATIONS_RESET
            });
        }
    }, [memberFirmId]);//eslint-disable-line

    useEffect(() => {
        if (annState?.categories) {
            let categories = annState.categories.filter(item => !item.is_specific_to_bookmark);
            setAnnotationCategories(categories);
        }
    }, [annState?.categories]);//eslint-disable-line

    useEffect(() => {
        if (annState?.annotations) {
            setAnnotations(annState?.annotations);
            setIsLoading(false);
        }
    }, [annState?.annotations]);//eslint-disable-line

    useEffect(() => {
        const resultsContainer = document.querySelector('.annot-list');
        const searchTerms = resultsContainer?.querySelectorAll('.highlight-search');
        setSearchTerms(searchTerms);
        return () => {
            setSearchTerms([]);
        }
    }, [value]);

    useEffect(() => {
        setFilteredAnnotations(filterAnnotations());
    }, [selectedAnnotationType, selectedCategory, selectedDate, annotations]);//eslint-disable-line

    function modifyDeleteGuidsHandler(val, opr) {
        if (opr === true) {
            setDeleteGuids(pre => [...pre, val]);
        } else {
            setDeleteGuids(pre => pre.filter(guid => guid !== val));
        }
    }

    function deleteAnnotationModal() {
        if (deleteGuids.length > 0) {
            setMessageDelete(getLabel("hh_DeleteIntro", "You are about to permanently delete {0} annotations.").replace('{0}', deleteGuids.length) + getLabel("hh_DeleteIntroProceed", "Would you like to proceed?"))
            setIsDeleteModalVisible(true);
            setShouldShowOverlayDelete(true);
        }
    }

    function filterAnnotations() {
        var filterAnnotations = annotations;
        if (selectedCategory && selectedCategory.length > 0) { //Filter by Category
            filterAnnotations = filterAnnotations.filter(item => selectedCategory.includes(item.category_guid));
        }
        if (selectedDate) { //Filter by date
            var fromDate = new Date(selectedDate[0]);
            var toDate = new Date(selectedDate[1]);
            toDate.setHours(23, 59, 59, 0);

            filterAnnotations = filterAnnotations.filter((item) => new Date(item.created_date) >= fromDate && new Date(item.created_date) <= toDate);
        }
        if (selectedAnnotationType !== "viewAll") {
            if (selectedAnnotationType === "Comments") {
                filterAnnotations = filterAnnotations.filter(item => item.annotation_type === "Comment");
            }
            else if (selectedAnnotationType === "Highlights") {
                filterAnnotations = filterAnnotations.filter(item => item.annotation_type === "Highlight");
            }
        }
        return filterAnnotations;
    }

    function handleDateSelection(date) {
        if (date)
            setDatefilter(true)
        setSelectedDate(date);
    }


    function handleCategorySelect(category) {
        if (category.category_guid !== "cat0") {
            setIsAllCategorySelected(false);
            if (selectedCategory.includes(category.category_guid)) {
                let categories = selectedCategory.filter(ele => ele !== category.category_guid);
                setSelectedCategory(prev => [...categories]);
            }
            else {
                setSelectedCategory(prev => [...prev, category.category_guid]);
            }
        }
        else {
            setIsAllCategorySelected(prev => {
                if (prev) {
                    setSelectedCategory(prev => []);
                }
                else {
                    let categories = annotationCategories.map(ele => ele.category_guid);
                    setSelectedCategory(prev => [...categories]);
                }
                return !prev
            });
        }
        document.dispatchEvent(new CustomEvent('dd:requestClose'));

    }

    function clearFilters() {
        setSelectedCategory([])
        setResetDates(!resetDates)
        setDatefilter(false)
    }

    async function DeleteAnnotations(guids) {
        const annotation = await ANNSERVICES.deleteAnnotation(location.pathname, guids, memberFirmId);
        if (annotation) {
            dispatch({
                type: ANN_ACTIONS.ANNOTATIONS_REMOVE_MULTIPLE,
                payload: guids,
            });
            setDeleteGuids([]);
        }
    }

    function accessibleCheck(e, type) {
        if (e.key === "Enter") {
            setSelectedAnnotationType(type);
        }
    }

    function isCheckedValue(annotation_guid) {
        return deleteGuids?.includes(annotation_guid);
    }

    return (
        <>
            {annotationCategories && annotationCategories.length > 0 && <div className='annot-container cf-right'>
                <AnnotationDeleteModal
                    setShouldShowDeleteModal={setIsDeleteModalVisible}
                    shouldShowDeleteModal={isDeleteModalVisible}
                    setShouldShowOverlay={setShouldShowOverlayDelete}
                    shouldShowOverlay={shouldShowOverlayDelete}
                    deleteAnnotationMessage={messageDelete}
                    deleteGuids={deleteGuids}
                    deleteItems={DeleteAnnotations}
                />
                <div>
                    <div className='d-flex' >
                        <div className='d-flex flex-row annot-filters'>
                            <div className='annot-filters-item filter-by-label'>
                                {getLabel("hh_AnnotationsFilterBy", "Filter By")}
                            </div>
                            {/* // Category Filter */}
                            <div className='annot-filters-item'>
                                <CategoryDropdown
                                    categories={annotationCategories}
                                    selectedCategories={selectedCategory}
                                    onSelect={handleCategorySelect}
                                    isAllCategorySelected={isAllCategorySelected}
                                    customClass='an-dd-category-trigger-dropdown-dashboard'
                                />
                            </div>
                            {/* //date filter */}
                            <div className='annot-filters-item'>
                                <DateSelector onChange={handleDateSelection} reset={resetDates} />
                            </div>
                            <button className={cx('annot-clear-filters', { 'annot-clear-filters-disabled': !dateFilter && !selectedCategory.length > 0 })} onClick={() => clearFilters()}>
                                {getLabel("hh_AnnotationsClearAllFilters", "Clear All Filters")}
                            </button>
                        </div>
                        <br />
                    </div>
                    <br />

                    <br />
                    <div className='d-flex'>
                        <div className='d-flex flex-row annot-view-filter'>
                            <div className="ann-sidemenu-radio-container-dashboard">
                                <div className='filter-by-label'>
                                    {getLabel("hh_AnnotationsView", "View")}
                                </div>
                                <div className="checkmarks-container-row" onClick={() => setSelectedAnnotationType("viewAll")} onKeyDown={(e) => accessibleCheck(e, "viewAll")} tabIndex={0}>
                                    <input id="annotation-view" type="radio" checked={selectedAnnotationType === "viewAll"} readOnly={true} tabIndex={1} /> <span className="checkmarks" tabIndex={1}></span>
                                    <label htmlFor="annotation-view" className={cx('radio-categories-dashboard', { 'radio-categories-dashboard-checked': selectedAnnotationType === "viewAll" })}> {getLabel("hh_AnnotationsAll", "View All")}</label>
                                </div>
                                <div className="checkmarks-container-row" onClick={() => setSelectedAnnotationType("Highlights")} onKeyDown={(e) => accessibleCheck(e, "Highlights")} tabIndex={0}>
                                    <input id="annotation-highlight" type="radio" checked={selectedAnnotationType === "Highlights"} readOnly={true} tabIndex={1} /> <span className="checkmarks" tabIndex={1}></span>
                                    <label htmlFor="annotation-highlight" className={cx('radio-categories-dashboard', { 'radio-categories-dashboard-checked': selectedAnnotationType === "Highlights" })}> {getLabel("hh_AnnotationsHighlightsOnly", "Highlights Only")}</label>
                                </div>
                                <div className="checkmarks-container-row" onClick={() => setSelectedAnnotationType("Comments")} onKeyDown={(e) => accessibleCheck(e, "Comments")} tabIndex={0}>
                                    <input id="annotation-comment" type="radio" checked={selectedAnnotationType === "Comments"} readOnly={true} tabIndex={1} /> <span className="checkmarks" tabIndex={1}></span>
                                    <label htmlFor="annotation-comment" className={cx('radio-categories-dashboard', { 'radio-categories-dashboard-checked': selectedAnnotationType === "Comments" })}>  {getLabel("hh_AnnotationsComentsOnly", "Comments Only")}</label>
                                </div>
                            </div>
                        </div>
                        {(filteredViewedAnnotation && filteredViewedAnnotation.length > 0) &&
                            <div className='annot-delete'>
                                <div className='icon-bin' onClick={deleteAnnotationModal}></div>
                            </div>
                        }
                    </div>
                </div>
                <div className='annot-list' id="annot-list">
                    {filteredViewedAnnotation.length > 0 && filteredViewedAnnotation.map((item) => {
                        return <div key={item.annotation_guid}>
                            <AnnotationItem category={annotationCategories?.find(i => i.category_guid === item.category_guid)} item={item} searchText={value} modifyDeleteGuids={modifyDeleteGuidsHandler} isChecked={isCheckedValue(item.annotation_guid)}></AnnotationItem>
                        </div>
                    })}
                    <div>
                        {(!filteredViewedAnnotation || filteredViewedAnnotation.length === 0) &&
                            <div>
                                <div className="notification">
                                    <span className="notification-warning">&nbsp;</span>
                                    <p className="notification-label annot-notification-label">No Annotations data available</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            }
            {filteredViewedAnnotation && filteredViewedAnnotation.length > 0 &&
                <div className='annot-main'>
                    <div className='annot-searchbar'>
                        <CommonSearchbar
                            placeholder={getLabel("s_Search", "Search")}
                            className="dashboard-search"
                            value={value}
                            setValue={setValue}
                            terms={searchTerms}
                        />
                    </div>
                    <div className='annot-right-delete-panel' >
                        <div className='annot-delete-hr'></div>
                    </div>
                </div>
            }
        </>
    )
}

export default AnnotationsDashboard;
