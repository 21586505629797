import UTILITIESHELPER from './UtilitiesHelper';
import LinkHelpers from './LinkHelpers';
import TierHelpers from './TierHelpers';
import IndustryHelpers from './IndustryHelpers';
import ProfileHelpers from './ProfileHelpers';
import RETRIEVALSERVICES from '../services/rp-service';
import { MEMBERFIRM_CONSTANTS, KNOWLEDGE_DOMAIN_ID, REGEX_PATTERN, KNOWLEDGE_DOMAIN, LINK_REDIRECTION_CONSTANTS } from '../components/Constants/Constants';
import SearchHelpers from './SearchHelpers';

var debugUserId = "24601";
function generateContextPosition(urlArray, position, defaultValue = "") {
    if (urlArray.length - 1 >= position) {
        return urlArray[position];
    }
    return defaultValue;
}

const getTier = (context) => {
    const sessionValue = TierHelpers.getSessionStorage();
    if (sessionValue && sessionValue.hasOwnProperty(context.memberFirm)) {
        const mfValue = sessionValue[context.memberFirm][context.knowledgeDomain];
        if(Array.isArray(mfValue) && mfValue.length > 0) {
            const value = mfValue.map(x => x.id);
            return value.join(',');
        }
    }
    return null;
};

const getIndustry = (context) => {
    const sessionValue = IndustryHelpers.getSessionStorage();
    if (sessionValue && sessionValue.hasOwnProperty(context.memberFirm)) {
        const mfValue = sessionValue[context.memberFirm][context.knowledgeDomain];
        if(Array.isArray(mfValue) && mfValue.length > 0) {
            const value = mfValue.map(x => x.id);
            return value.join(',');
        }
    }
    return null;
};

function getFallbackUrl(url, parentLevel = false, includeDomain = false) {

    var offSet = 0;
    var fallBackUrl = "";

    //Work around for the array-position
    if (url.indexOf("://") < 0) {
        offSet = 2;
    }

    if (url.indexOf("#") > 0) {
        url = url.split("#")[0];
    }

    if (url.indexOf("?") > 0) {
        url = url.split("?")[0];
    }

    var urlArray = url.split("/");

    if (offSet === 0 && includeDomain) {
        fallBackUrl = `${generateContextPosition(urlArray, 0)}//${generateContextPosition(urlArray, 2)}`;
    }

    //Landing    :: .../{memberFirm}/{language}
    //Catalog    :: .../{memberFirm}/{language}/{knowledgeDomain}
    //Collection :: .../{memberFirm}/{language}/{knowledgeDomain}/{collection}/{collectionName}

    let memberFirm = generateContextPosition(urlArray, 3 - offSet, MEMBERFIRM_CONSTANTS.dtt);
    let language = generateContextPosition(urlArray, 4 - offSet, "en-US");
    let knowledgeDomain = generateContextPosition(urlArray, 5 - offSet, ""); //accounting/auditing/assurance/etc
    let whereAreWe = generateContextPosition(urlArray, 6 - offSet, ""); // (collection/manual/folio/etc)

    switch (whereAreWe) {
      case "collection":
        //mf/lang/knowledgeDomain/collection --> mf/lang/knowledgeDomain
        fallBackUrl = `${fallBackUrl}/${memberFirm}/${language}/${knowledgeDomain}`;
        break;

      // case "professional-news":
      // case "deloitte-communications":
      //     //TODO: Need to see if we are on an article that we do not have, and fall back to the year...
      //     //TODO: Need to see if we are on a year, and fall back onto the Brand
      //     //FIRST: There is no TOC, i.e. we have "nothing" so we fall back onto the Collection of the Brand
      //     fallBackUrl = `${fallBackUrl}/${memberFirm}/${language}/${knowledgeDomain}/collection/${whereAreWe}`;
      //     break;

      case "professional-news":
      case "deloitte-communications": //TODO: Need to see if we are on an article that we do not have, and fall back to the year... //TODO: Need to see if we are on a year, and fall back onto the Brand //FIRST: There is no TOC, i.e. we have "nothing" so we fall back onto the Collection of the Brand
        if (parentLevel) {
          fallBackUrl = `${fallBackUrl}/${memberFirm}/${language}/${knowledgeDomain}/collection/${whereAreWe}`;
        } else {
          fallBackUrl = `${fallBackUrl}/${memberFirm}/${language}/${knowledgeDomain}/${whereAreWe}/${generateContextPosition(urlArray, 7 - offSet, "")}`;
        }
        break;

      case "manual":
      case "roadmap":
      case "standard":
      case "standards":
        //For MF change, we fallback to the publication landing page...
        if (parentLevel) {
          fallBackUrl = `${fallBackUrl}/${memberFirm}/${language}/${knowledgeDomain}/${whereAreWe}/${generateContextPosition(
            urlArray,
            7 - offSet,
            ""
          )}/${generateContextPosition(urlArray, 8 - offSet, "")}`;
        } else {
          //FIRST: There is no Publication/TOC, i.e. we have "nothing" so we fall back onto the Collection of the manual type
          fallBackUrl = `${fallBackUrl}/${memberFirm}/${language}/${knowledgeDomain}/collection/${whereAreWe}`;
        }
        break;

        case "folio":
        case "guide":
        //For MF change, we fall back to the current Folio (root/landing) which is just the filename
        const folioFallback = parentLevel
          ? `/${whereAreWe}/${generateContextPosition(urlArray, 7 - offSet, "test")}`
          : "";
        //FIRST: There is no Folio/TOC, i.e. we have "nothing" so we fall back to the catalog page
        fallBackUrl = `${fallBackUrl}/${memberFirm}/${language}/${knowledgeDomain}${folioFallback}`;
        break;

      default:
        fallBackUrl = "/";
        console.log("ContextHelpers::getFallbackUrl:Still need to implement location:", whereAreWe);
        break;
    }

    const composedUrl = decodeURI(fallBackUrl + '?wormhole=true');
    const sanitizedUrl = LinkHelpers.sanitizeUrl(composedUrl);

    return sanitizedUrl;
}

function getNewLanguageUrl(url, newLanguage) {

    var offSet = 0;
    //Work around for the array-position
    if (url.indexOf("://") < 0) {
        offSet = 2;
    }

    if (url.indexOf("#") > 0) {
        var urlHash = url.split("#");
        url = urlHash[0];
    }

    var urlArray = url.split("/");

    //Landing    :: .../3{memberFirm}/1{language}
    //Catalog    :: /4{memberFirm}/1{language}/2{knowledgeDomain}
    let memberFirm = generateContextPosition(urlArray, 3 - offSet, MEMBERFIRM_CONSTANTS.dtt);
    if (memberFirm === '') {
        memberFirm = MEMBERFIRM_CONSTANTS.dtt;
    }

    memberFirm = memberFirm.toLowerCase();

    let language = generateContextPosition(urlArray, 4 - offSet, "en-US");

    if (!newLanguage) {
        newLanguage = "en-US";
	}

    if (url.indexOf(`/${language}`) > 0) {
        url = url.replace(`/${language}`, `/${newLanguage}`)
    }
    else {
        url = `${url}${memberFirm}/${newLanguage}`;
    }

    return decodeURI(url);
}

//TODO:BDP:This function should ONLY be called from the PageContext
//It should also use the routInfo instead of the URL, as we need to "listen" to url changes within the PageContext
//  and update the PageCOntext.context each time!
function generateContext(url, searchPhrase, searchParams, searchResults, memberFirm, language, pubtype) {
    

    var offSet = 0;
    //console.log("ContextHelper::generateContext:url",url);

   if (!memberFirm) {
        memberFirm = "";
    }
    if (!language) {
        language = "";
    }

    //Work around for the array-position
    if (url.indexOf("://") < 0) {
        offSet = 2;
    }

    var token = '';
    var context = { userToken: token, uid: resolverUserId(null), pageRequestURL: url, globalPersonUid: RETRIEVALSERVICES.UPSUserID()};

    if (url.indexOf("#") > 0) {
        var urlHash = url.split("#");
        url = urlHash[0];
        context.pageHashTag = urlHash[1];
    }

    //TODO: See if we want to put this into another filed/param/something else...
    if (url.indexOf("?") > 0) {
        var urlQuestion = url.split("?");
        const queryParams = new URLSearchParams(`?${urlQuestion[1]}`);
        //Check for compare versions...
        const compareVersion = queryParams.get("compareVersion");
        context.versionCompare = compareVersion || 0;
        url = urlQuestion[0];
        //Check for search in the URL (add it for the "landing" on search pages where we do not pass the searchPhrase into this funciton!)
        if (searchPhrase !== undefined && !REGEX_PATTERN.doubleQuoted.test(searchPhrase))
        {
            let containsSeparator = /[-+|]/.test(searchPhrase);
            if(containsSeparator)
            {
                let separator = searchPhrase.match(/[-+|]/)[0];
                let pattern = `[a-z]\\${separator}[a-z]`;
                searchPhrase = SearchHelpers.parseSearchPhrase(searchPhrase,separator,pattern);
            }
            searchPhrase = SearchHelpers.SanitizeSpecialCharacters(searchPhrase);
        }
        else{
            const searchPhraseQS = queryParams.get("searchPhrase");
            if (searchPhraseQS) {
                //context.pageSearchPhrase = searchPhraseQS;
            }
        }
    }

    var urlArray = url.split("/");
    var pageControllerTypeOrSelectedSection = "";

    //In the case of content/docid - we are doing a link-resolve, so do not need to process context any further then "here"
    //  in case if url contains "content/docid" that means it's a TL links which is now transformed to research portal links
    // http://localhost:2001/content/docid=4_000000
    // pageControllerType = 4_000000, (assigned docid value to pageControllerType because pageControllerType is used in RP BE to resolve links in RP)
    //one more example where request came from TL to RP https://qinkrpservice.aaps.deloitte.com/content/docid=2_342973%23section_3_rule_3524-342973
    if (url?.toLowerCase().includes(LINK_REDIRECTION_CONSTANTS.Link_Redirection_URL_Identifier.toLowerCase())) {
        //context.pageControllerType = url.split(LINK_REDIRECTION_CONSTANTS.link_URL_Param_KeyValue_Separator)[1];
        var queryParams = extractQueryParameterValues(url);

        if (queryParams?.length > 0) {
            if (!UTILITIESHELPER.isStringNullorEmpty(queryParams[0]))
                context.pageControllerType = queryParams[0];

            if (!UTILITIESHELPER.isStringNullorEmpty(queryParams[1]))
                context.urlMemberFirm = queryParams[1];
        }

        context.memberFirm = (memberFirm === "") ? ProfileHelpers.getLastorDefaultMemberFirm() : memberFirm;
        context.language = (language === "") ? getLanguageByMemberFirm(context.memberFirm) : language;

        let languageSettings = UTILITIESHELPER.getCurrentLanguageLocalStorage(context.language);
        if (context.language !== languageSettings.languageCodeFE) {
            context.language = languageSettings.languageCodeFE;
        }

        context.languageDebugMT = UTILITIESHELPER.getIsDebugMTLocalStorage();

        //we are using "context.pageFileName" to resolve link so it is mandatory to reslove topic
        context.pageFileName = (context.pageControllerType === undefined || context.pageControllerType === null) ? "" : context.pageControllerType;
        //var e = new Error();
        //console.log("ContextHelper::generateContext:TL-Link:context, location",context, e.stack)
        return context;
    }

    //Landing    :: .../3{memberFirm}/1{language}
    //Catalog    :: /4{memberFirm}/1{language}/2{knowledgeDomain}
    context.memberFirms=[];
    context.memberFirm = (memberFirm === "") ? generateContextPosition(urlArray, 3 - offSet, memberFirm) : memberFirm;
    if (context.memberFirm === "") {
        //If not in URL: We fall back to the profile-helper/user-mf/session/etc
        context.memberFirm = ProfileHelpers.getFallbackMemberFirm(MEMBERFIRM_CONSTANTS.dtt);
        //console.log("ContextHelper::generateContext:Fallback to Profile as memberfirm Is empty? ", context.memberFirm);
    }

    context.memberFirm = context.memberFirm.toLowerCase();
    let fallbackLanguage = (language === "") ? getLanguageByMemberFirm(context.memberFirm) : language;

    context.language = generateContextPosition(urlArray, 4 - offSet, fallbackLanguage);

    //Check and fallback to user's previous language settings
    let languageSettings = UTILITIESHELPER.getCurrentLanguageLocalStorage(context.language);
    if (context.language !== languageSettings.languageCodeFE) {
        context.language = languageSettings.languageCodeFE;
    }

    context.languageDebugMT = UTILITIESHELPER.getIsDebugMTLocalStorage();

    //As the PageContext only had KD (and not content/dashboard), we need to re-get this parameter!
    context.knowledgeDomain = generateContextPosition(urlArray, 5 - offSet, ""); //accounting/auditing/assurance/etc/content]
    context.tier = getTier(context);
    context.industry = getIndustry(context);

    //Folio or Manual or TimeCapsule or Journal or Content PageName/etc (if we have this parameter)
    context.pageControllerType = pageControllerTypeOrSelectedSection = generateContextPosition(urlArray, 6 - offSet, "");
    context.pageCollectionName = generateContextPosition(urlArray, 7 - offSet, "");
    //Set Session Storage for Page
    sessionStorage.setItem('PageType', pageControllerTypeOrSelectedSection);

    if(context.memberFirm==='link')
    {
        context.pageFileName=context.language;
    }
    else if (context.knowledgeDomain === "link") {
        context.pageFileName = generateContextPosition(urlArray, 6 - offSet, "");
    }
    else if (context.knowledgeDomain === "content") {
        //This is general content, like FAQ (not knowledgeDomain specific!) content/PageName OR content/FolderName/PageName
        context.pageFileName = url.substring(url.indexOf(context.knowledgeDomain));
    }
    else if (pageControllerTypeOrSelectedSection !== "") {
        switch (pageControllerTypeOrSelectedSection) {
            case "resource":
                context.pageFileName = generateContextPosition(urlArray, 7 - offSet, "Modal");
                break;

            case "folio":
            case "guide":
                //Folio  :: .../0{memberFirm}/1{language}/2{knowledgeDomain}/3="guidebook"
                //             /4{guidebookFileName}/5{guidebookSelectedSection}
                //             /6{guidebookSelectedPublicationTitle}/7{guidebookSelectedPublicationGUID}
                //             /8{guidebookSelectedContentItemTitle}/9{guidebookSelectedContentItemGUID}
                context.pageFileName = generateContextPosition(urlArray, 7 - offSet, "test");
                context.pageSelectedSection = generateContextPosition(urlArray, 8 - offSet, "");
                context.pageSelectedPublicationTitle = generateContextPosition(urlArray, 9 - offSet, "");
                context.pageSelectedPublicationGUID = generateContextPosition(urlArray, 10 - offSet, "");
                context.pageSelectedContentItemTitle = generateContextPosition(urlArray, 11 - offSet, "");
                context.pageSelectedContentItemGUID = generateContextPosition(urlArray, 12 - offSet, "");
                context.pageSelectedSpineTargetPublicationGUID = ""; //NO Spine publications in Guidebooks
                context.version = 0;
                context.versionBase = 0;
                // context.versionCompare = 0;
                context.pageType = pageControllerTypeOrSelectedSection;
                break;

            case "timecapsule":
                context.version = 0; //BETA: We are always comparing version with latest!
                context.versionBase = -1; //Setting this to -1 tells the retreival controller that we are doing version!
                // context.versionCompare = parseInt(generateContextPosition(urlArray, 7 - offSet, "0"), 10);
                context.pageSelectedPublicationGUID = generateContextPosition(urlArray, 8 - offSet, "");
                context.pageSelectedContentItemGUID = generateContextPosition(urlArray, 9 - offSet, "");;
                context.pageSelectedSpineTargetPublicationGUID = generateContextPosition(urlArray, 10 - offSet, "");
                context.pageType = pageControllerTypeOrSelectedSection;
                break;

            case "collection":
                context.pageFileName = generateContextPosition(urlArray, 7 - offSet, "Generic");
                context.pageType = context.pageFileName;
                break;

            case "deloitte-communications":
            case "professional-news":
                //"/global/en-EN/auditing/[6]deloitte-communications/[7]Practice%20Alerts/[8]2023/[9]GUID-69BAE4E2-C36C-44D8-85C9-6F14D78C0FA1"
                //context.pageControllerType = 'journal'
                context.pageType = 'journal'
                //pageSelectedSection === "deloitte-communications"
                context.pageSelectedSection = generateContextPosition(urlArray, 6 - offSet, "");
                //JournalBrand === "Practice Alerts"
                context.pageSelectedPublicationTitle = generateContextPosition(urlArray, 7 - offSet, "");
                //Volume === "2023" (We abose the pageSelectedPublicationGUID here for the Volume)
                context.pageSelectedPublicationGUID = generateContextPosition(urlArray, 8 - offSet, ""); //check context.pageSelectedContentItemGUID;
                //Selected Issue
                context.pageSelectedContentItemGUID = generateContextPosition(urlArray, 9 - offSet, "");
                //We "abuse" the spine pug here, so that we actually "Get" the publication!
                context.pageSelectedSpineTargetPublicationGUID = context.pageSelectedContentItemGUID;
                //"/global/en-EN/auditing/[6]deloitte-communications/[7]Practice%20Alerts/[8]2023/[9]GUID-69BAE4E2-C36C-44D8-85C9-6F14D78C0FA1"
                context.pageBaseURL = "/" + context.memberFirm + "/" + context.language + "/" + context.knowledgeDomain + "/" + context.pageControllerType + "/" + context.pageSelectedPublicationTitle + "/" + context.pageSelectedPublicationGUID
                break;

            case "dashboard":
                //global/en-US/auditing/dashboard/recentviewed
                //Dashboard ::  0{memberFirm}/1{language}/2{knowledgeDomain}/3{publicationType}
                //
                context.pageFileName = "index.cshtml";
                context.pageType = generateContextPosition(urlArray, 7 - offSet, "annotations");
                context.pageSelectedSection = ""; //generateContextPosition(urlArray, 6 - offSet, ""); --> pageControllerType already!
                context.pageSelectedPublicationTitle = "";
                context.pageSelectedPublicationGUID = "";
                context.pageSelectedContentItemTitle = "";
                context.pageSelectedContentItemGUID = "";
                context.pageSelectedSpineTargetPublicationGUID = "";
                context.version = 0;
                context.versionBase = 0;
                context.pageBaseURL = `/${context.memberFirm}/${context.language}/${context.knowledgeDomain}/${context.pageControllerType}`;
                break;

            default:
                //Manual     :: /0{memberFirm}/1{language}/2{knowledgeDomain}/3{publicationType}
                //              /4{publicationTitle}/5{publicationGUID}/
                //              /6{selectedItemTitle}/7{selectedItemGUID}/8{selectedSpineTargetPublicationGUID}/
                //              /9=v{publicationVersion}/10=c{publicationVersionCompare}/
                context.pageFileName = "index.cshtml";
                context.pageType = pageControllerTypeOrSelectedSection;
                context.pageSelectedSection = ""; //generateContextPosition(urlArray, 6 - offSet, ""); --> pageControllerType already!
                context.pageSelectedPublicationTitle = generateContextPosition(urlArray, 7 - offSet, "");
                context.pageSelectedPublicationGUID = generateContextPosition(urlArray, 8 - offSet, "");
                context.pageSelectedContentItemTitle = generateContextPosition(urlArray, 9 - offSet, "");
                context.pageSelectedContentItemGUID = generateContextPosition(urlArray, 10 - offSet, "");;
                context.pageSelectedSpineTargetPublicationGUID = generateContextPosition(urlArray, 11 - offSet, "");
                context.version = generateContextPosition(urlArray, 12 - offSet, 0);
                context.versionBase = 0;
                // context.versionCompare = generateContextPosition(urlArray, 13 - offSet, 0);
                context.pageBaseURL = "/" + context.memberFirm + "/" + context.language + "/" + context.knowledgeDomain + "/" + context.pageControllerType + "/" + context.pageSelectedPublicationTitle + "/" + context.pageSelectedPublicationGUID
                break;
        }
    }

    if (!context.pageBaseURL) {
        context.pageBaseURL = "/" + context.memberFirm + "/" + context.language; //We will always have these on every page we need the pageBaseUrl
        if (context.knowledgeDomain) { context.pageBaseURL += "/" + context.knowledgeDomain; }
        if (context.pageControllerType) { context.pageBaseURL += ("/" + context.pageControllerType); } //Guidebooks and Manuals have these
        if (context.pageFileName) { context.pageBaseURL += ("/" + context.pageFileName); } //Guidebooks and Manuals have these
    }

    if (searchPhrase !== undefined) {
        context.pageSearchPhrase = searchPhrase;
    }

    if (searchParams !== undefined) {
        context.pageSearchParameters = searchParams;
    }

    if (searchResults !== undefined) {
        //Should we "remove" the highlights?
        context.pageSearchResponse = searchResults;
    }

    if(pubtype !== undefined){
        context.pageType = pubtype;
    }

    //var e = new Error();
    //console.log("ContextHelper::generateContext:context, location",context, e.stack)
    return context;
}

//this method will return mf id and doc id when doc id contains double semicolon (;;) in the url.
//"https://abcd.com/content/docid=2_427478&memberFirm=17573163"
function extractQueryParameterValues(url) {
    var allParams = url?.split('&');
    if (allParams.length > 0)
    {
        var queryParams = allParams?.map((value) => {
            return value?.split('=')[1];
        });
        return queryParams;
    }    
    return null;
}

function getMemberFirmTaxonomyId(memberFirm){
    if (!UTILITIESHELPER.isStringNullorEmpty(memberFirm)) {
        //TODO: This needs to come from the PageContext.MemberFirms Taxonomy!!
        switch (memberFirm.toLowerCase()) {
            case "dtt": return "17573177"; //Global Audit Content (DTT)
            case "us": return "17573163"; //United States
            case "gb": return "17574057"; //UK
            case "ca": return "17574007"; //Canada
            case "in": return "49867231"; //India
            case "mx": return "49867232"; //Mexico
            case "za": return "49867233"; //Southern Africa
            default: return null;
        }
    }
    return null;
}

function getMemberFirmWithTaxonomyId(memberFirm){
    if (!UTILITIESHELPER.isStringNullorEmpty(memberFirm)) {
        //TODO: This needs to come from the PageContext.MemberFirms Taxonomy!!
        switch (memberFirm.toLowerCase()) {
            case "17573177": return "dtt"; //Global Audit Content (DTT)
            case "17573163": return "us"; //United States
            case "17574057": return "gb"; //UK
            case "17574007": return "ca"; //Canada
            case "49867231": return "in"; //India          
            default: return null;
        }
    }
    return null;
}

function getLanguageByMemberFirm(memberFirm) {
    //This needs to take the language from the memberfirm settings in the context, for now we will always return "en-US" untill we implement that/
    return "en-US";
}

function generateFolioTitleFromFilename(pageFileName, titleCase = true) {
    var folioTitle = pageFileName
        .replace(".cshtml", "")
        .split('_')[0]
        .replace(/_/g, " ")
        .replace(/---/g, "~") //temporary change so that the --- can be ignored by the - removal
        .replace(/-/g, " ")
        .replace(/~/g, " - ");

    if (titleCase) {
        folioTitle = UTILITIESHELPER.titleCase(folioTitle);
    }

    return folioTitle;
}

function isSameLanguage(previousLanguage, currentLanguage) {
    //The first time we load, we have no language, so need to load
    if (previousLanguage === "") {
        return false;
    }
    //This should never be a case, as only when lange changes, will it come in here!
    if (previousLanguage === currentLanguage) {
        return true;
    }
    if (previousLanguage.startsWith("en")) {
        //All versions of english are the same!
        return currentLanguage.startsWith("en")
    }
    return false;
}

//resolveMemberFirmPath - Memberfirm and language are NOT in the URL
//	we are landing on the home page, and are deturmining target/default/previous memberFirm and language
const resolveMemberFirmPath = (contextPath, targetMemberFirm, targetKD, context) => {

    //console.log("resolveMemberFirmPath::contextPath, targetMemberFirm, targetKD, ", contextPath, targetMemberFirm, targetKD, context);

    //For sites content, there is NO difference between the 3 "English" languages
    //	Here we are maintaining the user's previous/default targetLanguage settings
    let targetLanguage = (context.language.startsWith("en") ? "en-US" : context.language);

    //targetKD === "" when we are on the landing page (no target KD)
    if (targetKD === "") {
        //If we are going to "dtt" then we want no
        if (targetMemberFirm === MEMBERFIRM_CONSTANTS.global && targetLanguage.startsWith("en") && contextPath !== "/") {
            //We want to prevent changing the URL if the fallback targetMemberFirm/language is dtt/english!
            //  contextPath as if we were already on the "/" then the context will NOT change, and NOT update!
            return "/";
        }

        return `/${targetMemberFirm}/${targetLanguage}`;
    }

    //IF we are deeper then the catalog page... OR we are ON the catalog page...
    //If the current path does NOT "end" with the KD (we have more then just the KD in the url, we are deeper)
    if (contextPath.indexOf(`/${context.knowledgeDomain}/`)) {
        //If we are ON the same KD as we want to "remain" on
        if (targetKD === context.knowledgeDomain || targetKD === `/${context.knowledgeDomain}`) {
            //We return the full URL with the new MF and the same KD (as we want the URL to stay the same)
            return contextPath.replace(`/${context.memberFirm}/`, `/${targetMemberFirm}/`);
        }
    }

    //We are in header, and either changing MF/KD, so need the "full" url to the target, different KD (no folio/manual/page/content)
    return `/${targetMemberFirm}/${targetLanguage}${targetKD}`;
}

function resolverUserId(value) {
    if (value === null || value === undefined || value === '') {
        var user = getAccountID();

        if (user === null || user === undefined) {
            return debugUserId;
        }
        //console.log("ContextHelper::resolverUserId:user", user)
        return user;
    }

    return value;
}

function getAccountID() {
    return UTILITIESHELPER.getSessionStorage('accountid');
}

function getknowledgeDomainId(knowledgeDomain){
    if(!UTILITIESHELPER.isStringNullorEmpty(knowledgeDomain)){
        switch (knowledgeDomain.toLowerCase()) {
            case KNOWLEDGE_DOMAIN.accounting: return KNOWLEDGE_DOMAIN_ID.accountingId;
            case KNOWLEDGE_DOMAIN.auditing: return KNOWLEDGE_DOMAIN_ID.auditingId;
            case KNOWLEDGE_DOMAIN.assurance: return KNOWLEDGE_DOMAIN_ID.assuranceId;
            default: return null
        }
    }
    return null;
}

function getknowledgeDomain(knowledgeDomainId){
    if(!UTILITIESHELPER.isStringNullorEmpty(knowledgeDomainId)){
        switch (knowledgeDomainId.toLowerCase()) {
            case KNOWLEDGE_DOMAIN_ID.accountingId: return KNOWLEDGE_DOMAIN.accounting;
            case KNOWLEDGE_DOMAIN_ID.auditingId: return KNOWLEDGE_DOMAIN.auditing;
            case KNOWLEDGE_DOMAIN_ID.assuranceId: return KNOWLEDGE_DOMAIN.assurance;
            default: return null
        }
    }
    return null;
}

function getTierIds(memberFirm, knowledgeDomain){
    const sessionValue = TierHelpers.getSessionStorage();
    if (sessionValue && sessionValue.hasOwnProperty(memberFirm)) {
        const mfValue = sessionValue[memberFirm][knowledgeDomain];
        if (Array.isArray(mfValue) && mfValue.length > 0) {
            const value = mfValue.map(x => x.id);
            return value;
        }
    }
    return [];
};

function getIndustryIds(memberFirm, knowledgeDomain){
    const sessionValue = IndustryHelpers.getSessionStorage();
    if (sessionValue && sessionValue.hasOwnProperty(memberFirm)) {
        const mfValue = sessionValue[memberFirm][knowledgeDomain];
        if (Array.isArray(mfValue) && mfValue.length > 0) {
            const value = mfValue.map(x => x.id);
            return value;
        }
    }
    return [];
};

function getUPSQuerySpecBaseModel(href, memberFirmId = null) {
    let context = generateContext(href);
    return {
        opm_user_identifier: context.globalPersonUid,
        member_firm_id: memberFirmId ? memberFirmId : ProfileHelpers.getSelectedMemberFirmTaxonomyId(),
        knowledge_domain_id: getknowledgeDomainId(context.knowledgeDomain)
    };
}

function getTimezoneOffset() {
    return new Date().getTimezoneOffset();
}

function sortCategories(categories, latestUsedCategoryGuid) {
    if (categories?.length > 0) {
        UTILITIESHELPER.sortAlphaCaseInsensitive(categories, "label");
        var index = categories.findIndex(a => a.category_guid === latestUsedCategoryGuid);
        if (index > -1) {
            const selectedcategory = categories.splice(index, 1);
            categories.splice(0, 0, selectedcategory[0]);
        }
        index = categories.findIndex(a => a.label === "Reading List");
        if (index > -1) {
            const selectedcategory = categories.splice(index, 1);
            categories.splice(0, 0, selectedcategory[0]);
        }
    }
}

function getGUIDFromContext(context) {
    return UTILITIESHELPER.isStringNullorEmpty(context.pageSelectedSpineTargetPublicationGUID) ? (UTILITIESHELPER.isStringNullorEmpty(context.pageSelectedContentItemGUID) ? context.pageSelectedPublicationGUID : context.pageSelectedContentItemGUID) : context.pageSelectedSpineTargetPublicationGUID;

}


const CONTEXTHELPERS = {
    generateContext,
    generateFolioTitleFromFilename,
    getFallbackUrl,
    getLanguageByMemberFirm,
    getNewLanguageUrl,
    isSameLanguage,
    resolverUserId,
    resolveMemberFirmPath,
    debugUserId,
    getUPSQuerySpecBaseModel,
    getTimezoneOffset,
    sortCategories,
    getGUIDFromContext,
    getknowledgeDomainId,
    getknowledgeDomain,
    getMemberFirmTaxonomyId,
    getMemberFirmWithTaxonomyId,
    getAccountID,
    getTierIds,
    getIndustryIds
}

export default CONTEXTHELPERS;
