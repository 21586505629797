import UTILITIESHELPER from "./UtilitiesHelper";
import { get } from "lodash";
function getSectionTitle(item, selectedSpinePubGuidList) {
    if (item && item.parentTitle && selectedSpinePubGuidList.includes(item.parentGuid)) {        
        return "</br> <h2 class=\"article-title\">" + item.parentTitle + "</h2> </br>";
    }
    return "";
}

function getBinaryList(selectedList) {
    var fileRequests = [];
      selectedList.forEach((article) => {
        if (article?.binaries?.length > 0) {
            article.binaries.forEach((binary) => {
                if (binary?.isSelected) {
                    var fileNameFormat =  UTILITIESHELPER.removeLargeSpacesFromRichText(binary.binaryTitle);
                    fileNameFormat = UTILITIESHELPER.restrictLengthOfFileName(fileNameFormat);
                    var object = {
                        fileName: UTILITIESHELPER.removeSpecialCharacterFromFileName(fileNameFormat) + binary.binaryFormat,
                        fileUri: binary.binaryURI
                    };
                    fileRequests.push(object);
                }
            })
            if (article.content?.length > 0) {
                if (article.content.binaries?.length > 0) {
                    article.content.binaries.forEach((binary) => {
                        var fileNameFormat =  UTILITIESHELPER.restrictLengthOfFileName(binary.binaryTitle);
                        fileNameFormat=UTILITIESHELPER.removeLargeSpacesFromRichText(fileNameFormat);
                        if (binary?.isSelected) {
                            var object = {
                                fileName: UTILITIESHELPER.removeSpecialCharacterFromFileName(fileNameFormat) + binary.binaryFormat,
                                fileUri: binary.binaryURI
                            };
                            fileRequests.push(object);
                        }
                    })
                }
            }
        }
        
    })
    return fileRequests;
}
//This method is responsible to keep file name unique with in the binary list, to keep file name unique we need to remane file name in case if duplicate file name is available 
function renameDuplicateFileNames(binariesList, articleTextFileName) {
    var tempUniqueFileName = [];
    if (!UTILITIESHELPER.isStringNullorEmpty(articleTextFileName))
        tempUniqueFileName.push(articleTextFileName + ".docx");

    binariesList.forEach(function (a, i) {
        if (tempUniqueFileName) {
            if (tempUniqueFileName.includes(a.fileName)) {
                var newFileName = renameFile(a.fileName);
                tempUniqueFileName.push(newFileName);
                a.fileName = newFileName;
            }
            else {
                tempUniqueFileName.push(a.fileName);
            }
        }
        else {
            tempUniqueFileName.push(a.fileName);
        }
        
    });
    return binariesList;
}

export const checkIfDisplayDownloadEnable = (_pubData) => {
    return ((get(_pubData, "content[0].content.display_download")?.toLowerCase() === "true") ||
        (get(_pubData, "content[0].subItems[0].content.display_download")?.toLowerCase() === "true"));
}

export const getHeaderTitleNameInReadingName = () => {
    var titleOnReadingMode = "";
    if (!UTILITIESHELPER.isStringNullorEmpty(document.querySelector('h1.article-title-reading-mode')?.textContent))
        titleOnReadingMode = document.querySelector('h1.article-title-reading-mode')?.textContent;
    else if (!UTILITIESHELPER.isStringNullorEmpty(document.querySelector('h2.header-title-reading-mode')?.textContent))
        titleOnReadingMode = document.querySelector('h2.header-title-reading-mode')?.textContent;
    else if (!UTILITIESHELPER.isStringNullorEmpty(document.querySelector('h2.article-title')?.textContent))
        titleOnReadingMode = document.querySelector('h2.article-title')?.textContent;
    else titleOnReadingMode = "";

    return titleOnReadingMode;
}
//This method extract if any extension and then add underscore (_) at the last position of the filename and againg concat the extension.
function renameFile(fileName) {
    var fileElements = fileName.split('.');
    return `${fileElements[0]}_.${fileElements[1]}`;
}

function replaceDivWithHeaderTag(article, levelAdjustmentforHTag, isPublication){
    let parentClassElements;
    if(isPublication){
        parentClassElements = article.querySelectorAll('.subtopic');
    }else{
        parentClassElements = article.querySelectorAll('details');
    }
    parentClassElements.forEach(parentClassElement => {
        const level = identifyHierarchyLevelOfParent(parentClassElement, isPublication);
        const divElement = parentClassElement.querySelector('.subtopicheading');
        if(divElement) {
            const heading = document.createElement(`h${level+levelAdjustmentforHTag}`);
            heading.textContent = divElement.textContent;
            // As we are explicitly converting the .subheading classNames to H tags as per the level , the CSS classes written in word.css are not getting applied .
            // The Aspose is by default adding the font sizes for Heading tags .
            // As our H3 class is of 16px and not matching with Aspose ..changing to 16px of H3 tags
            if (`${level + levelAdjustmentforHTag}` === '3') {
                heading.style.fontSize = '21px';
            }
            divElement.parentNode.replaceChild(heading, divElement);
        }
    });
}

function identifyHierarchyLevelOfParent(element, isPublication) {
    let level = 0;
    let currentElement = element;
    while (currentElement.parentElement !== null) {
        if(isPublication){
            if (currentElement.parentElement.classList.contains("subtopic")) {
                level++;
            }
        }else{  
            if (currentElement.tagName.toLowerCase() === 'details') {
                level++;
            }
        }
        currentElement = currentElement.parentElement;
    }
    return level;
}



const DOWNLOADHELPERS = {
    getSectionTitle,
    getBinaryList,
    renameDuplicateFileNames,
    checkIfDisplayDownloadEnable,
    getHeaderTitleNameInReadingName,
    replaceDivWithHeaderTag
}

export default DOWNLOADHELPERS;