import React, { createContext, useContext, useReducer,useState } from "react";

export const AnnotationsContext = createContext();
export const useUserPreferencesContext = () => useContext(AnnotationsContext);

export const ACTIONS = {
    CATEGORY_LOAD: "categories::load",
    CATEGORY_CREATE: 'categories::create',
    CATEGORY_UPDATE: "categories::update",
    CATEGORY_REMOVE: 'categories::remove',
    ANNOTATIONS_LOAD: "annotations::load",
    ANNOTATIONS_CREATE: "annotaions::create",
    ANNOTATIONS_UPDATE: "annotaions::update",
    ANNOTATIONS_UPDATE_MULTIPLE: "annotaions::updatemultiple",
    ANNOTATIONS_REMOVE: "annotations::remove",
    ANNOTATIONS_REMOVE_MULTIPLE: "annotations::removemultiple",
    ANNOTATIONS_RESET: "annotations::reset",
    PANEL_SHOW: "annotations::panel::show",
    PANEL_HIDE: "annotations::panel::hide",
    MODAL_SHOW: "annotations::modal::show",
    MODAL_HIDE: "annotations::modal::hide",
    ANNOTATION_TEMP_SET: "annotation::temp::set",
    ANNOTATION_TEMP_RESET: "annotation::temp::set",
    BOOKMARKS_CREATE: "bookmarks::create",
    BOOKMARKS_LOAD: "bookmarks::load",
    BOOKMARKS_RESET: "bookmarks::reset",
    BOOKMARK_UPDATE: "bookmark::update",
    BOOKMARK_REMOVE: "bookmark::remove",
    BOOKMARK_BULK_REMOVE: "bookmark::bulk::remove",
    RECENT_HISTORY_CREATE: "history::create",
    RECENT_HISTORY_LOAD: "history::load",
    RECENT_HISTORY_RESET: "history::reset",
    RECENT_HISTORY_UPDATE: "history::update",
    RECENT_HISTORY_REMOVE: "history::remove",
    RECENT_SEARCH_CREATE: "search::create",
    RECENT_SEARCH_LOAD: "search::load",
    RECENT_SEARCH_RESET: "search::reset",
    RECENT_SEARCH_UPDATE: "search::update",
    RECENT_SEARCH_REMOVE: "search::remove",
};

const initialState = {
    categories: [],
    annotations: [],
    isPanelVisible: false,
    isModalVisible: false,
    tempAnnotation: null,
    bookmarks: [],
    recentHistory: [],
    recentSearch: []
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.PANEL_SHOW:
            return { ...state, isPanelVisible: true };
        case ACTIONS.PANEL_HIDE:
            return { ...state, isPanelVisible: false };
        case ACTIONS.MODAL_SHOW:
            return { ...state, isModalVisible: true, tempAnnotation: action.payload };
        case ACTIONS.MODAL_HIDE:
            return { ...state, isModalVisible: false, tempAnnotation: null };

        //Categories Start
        case ACTIONS.CATEGORY_LOAD:
            return {
                ...state,
                categories: action.payload
            };
        case ACTIONS.CATEGORY_CREATE:
            const categoryList = state.categories.filter((book) =>
                book.category_guid !== action.payload.category_guid);
            return {
                ...state,
                categories: [...categoryList, action.payload],
            };
        case ACTIONS.CATEGORY_UPDATE:
            return {
                ...state,
                categories: state.categories.map((cat) =>
                    cat.category_guid === action.payload.category_guid ? action.payload : cat),
            };
        case ACTIONS.CATEGORY_REMOVE:
            return {
                ...state,
                categories: state.categories.filter((cat) =>
                    cat.category_guid !== action.payload.category_guid),
            };
        //Categories End

        //Annotation Start
        case ACTIONS.ANNOTATIONS_LOAD:
            return {
                ...state,
                annotations: action.payload
            };
        case ACTIONS.ANNOTATIONS_CREATE:
            return {
                ...state,
                annotations: [...state.annotations, action.payload],
                isModalVisible: false,
                tempAnnotation: null,
            };
        case ACTIONS.ANNOTATIONS_UPDATE:
            return {
                ...state,
                annotations: state.annotations.map((ann) =>
                    ann.annotation_guid === action.payload.annotation_guid ? action.payload : ann),
            };

        case ACTIONS.ANNOTATIONS_UPDATE_MULTIPLE:
            const annotationAfterUpdatess = state.annotations.map(annot => {
                const match = action.payload.find(item => item.annotation_guid === annot.annotation_guid);
                return match ? { ...annot, sidePanelEdit: match.sidePanelEdit } : annot;
            });
            return {
                ...state,
                annotations: annotationAfterUpdatess,

            };

        case ACTIONS.ANNOTATIONS_REMOVE:
            const annotationAfterDelete = state.annotations.filter((ann) =>
                ann.annotation_guid !== action.payload.annotation_guid);
            return {
                ...state,
                annotations: annotationAfterDelete,
                isSidePanelDelete: true,
                deleteId: action.payload.annotation_guid,
            };

        case ACTIONS.ANNOTATIONS_REMOVE_MULTIPLE:

            const annotationAfterDeletes = state.annotations.filter(a => !(action.payload.includes(a.annotation_guid)));
            return {
                ...state,
                annotations: annotationAfterDeletes,
                isSidePanelDelete: true,
                deleteId: action.payload,
            };
        case ACTIONS.ANNOTATIONS_RESET:
            state.annotations = [];
            return state;
        //Annotation End

        //bookmarks start
        case ACTIONS.BOOKMARKS_LOAD:
            return {
                ...state,
                bookmarks: action.payload
            };
        case ACTIONS.BOOKMARKS_CREATE:
            const bookmarksList = state.bookmarks.filter((book) =>
                book.bookmark_guid !== action.payload.bookmark_guid);// to avoid dublication item we will check already exist or not
            return {
                ...state,
                bookmarks: [...bookmarksList, action.payload],
            };
        case ACTIONS.BOOKMARK_REMOVE:
            const bookmarkAfterDelete = state.bookmarks.filter((book) =>
                book.bookmark_guid !== action.payload.bookmark_guid);
            return {
                ...state,
                bookmarks: bookmarkAfterDelete,
                deleteId: action.payload.bookmark_guid,
            };

        case ACTIONS.BOOKMARK_BULK_REMOVE:
            const bookmarkAfterbulkDelete = state.bookmarks.filter((book) =>
                !action.payload.includes(book.bookmark_guid));
            return {
                ...state,
                bookmarks: bookmarkAfterbulkDelete
            };
        //Bookmark end  

        // ! recent history state reducers
        // * required at the start of the page load
        case ACTIONS.RECENT_HISTORY_CREATE:
            const historyAfterCreate = action.payload;
            return {
                ...state,
                recentHistory: historyAfterCreate,
            };

        // ! may not be required
        case ACTIONS.RECENT_HISTORY_LOAD:
            const historyAfterLoad = [...state.recentHistory, action.payload];
            return { ...state, history: historyAfterLoad };
        // ! may not be required
        case ACTIONS.RECENT_HISTORY_RESET:
            state.recentHistory = [];
        // * used to update the state with new entry directly
        // this implementation is the same as CREATE, 
        // This actions can be modified if required in future
        break;
        case ACTIONS.RECENT_HISTORY_UPDATE:
            const historyAfterUpdate = action.payload;
            return {
                ...state,
                recentHistory: historyAfterUpdate,
            };
        // ! may not be required
        case ACTIONS.RECENT_HISTORY_REMOVE:
            const historyAfterDelete = state.recentHistory.filter((history) =>
                history.id !== action.payload.id);
            return {
                ...state,
                recentHistory: historyAfterDelete,
                deleteId: action.payload.id,
            };

        // ! recent search state reducers
        // * required at the start of the page load
        case ACTIONS.RECENT_SEARCH_CREATE:
            const searchAfterCreate = action.payload;
            return {
                ...state,
                recentSearch: searchAfterCreate,
            };

        case ACTIONS.RECENT_SEARCH_LOAD:
            return { ...state, recentSearch: action.payload };

        case ACTIONS.RECENT_SEARCH_RESET:
            state.recentSearch = [];
            break;
        case ACTIONS.RECENT_SEARCH_UPDATE:
            const searchAfterUpdate = action.payload;
            return {
                ...state,
                recentSearch: searchAfterUpdate,
            };

        case ACTIONS.RECENT_SEARCH_REMOVE:
            const searchAfterDelete = state.recentSearch.filter((rSearch) =>
                rSearch.id !== action.payload.id);
            return {
                ...state,
                recentSearch: searchAfterDelete,
                deleteId: action.payload.id,
            };
        default:
            return state;
    }
};

export default function AnnotationsContextProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [selectedAnnotation, setSelectedAnnotation] = useState(0);
    const [selectedId, setSelectedId] = useState();
    const [isDropDownCategoryOpen, setIsDropDownCategoryOpen] = useState(false);
    const [viewedAnnotation, setViewedAnnotation] = useState(null);
    const [viewClickedAnnotation, setViewClickedAnnotation] = useState(null);
    const [viewedBookmark, setViewedBookmark] = useState(null);

    const store = {
        state,
        dispatch,
        selectedAnnotation,
        setSelectedAnnotation,
        viewedAnnotation,
        setViewedAnnotation,
        viewClickedAnnotation,
        setViewClickedAnnotation,
        viewedBookmark,
        setViewedBookmark,
        isDropDownCategoryOpen, setIsDropDownCategoryOpen,
        selectedId,setSelectedId
    };

    return (
        <AnnotationsContext.Provider value={store}>
            {children}
        </AnnotationsContext.Provider>
    );
}
