import React, { createContext, useContext } from 'react'
import { reactPlugin ,logs,ai } from "../helpers/log";

export const AppInsightsContext = createContext()
export const usePageContext = () => useContext(AppInsightsContext)

const AppInsightsContextProvider = ({children}) => {
 

  const store = {
      reactPlugin,
      logs,
      ai      
  }

  return (
    <AppInsightsContext.Provider value={store}>
        {children}
    </AppInsightsContext.Provider>
  )
}

export default AppInsightsContextProvider