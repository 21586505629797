import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { logs, page } from '../helpers/log';

function useUserProfileService() {
    const { apiCall } = useFetchWithMsal();
    const DCSEndPoint = window.DART.ENV.REACT_APP_DCS_ENDPOINT_API;
    const customheaders = { "INK_CONTENT_PRERELEASE": (window.DART.ENV.RP_REACT_INK_CONTENT_PRERELEASE ? window.DART.ENV.RP_REACT_INK_CONTENT_PRERELEASE : window.DART.ENV.RP_REACT_APP_PREVIEW) };

    async function getUserProfileInfo() {
        var response = null;
        let request = {
            url: `${DCSEndPoint}/OPM/userprofile`,
            method: 'GET',
            mode: 'cors'
        }
        try {
            response = await apiCall(request, customheaders)
        }
        catch {
            logs.error(page.DCSService, 'RPService', "Error in getting user Profile info api", request.url);
        }
        finally {
            return response;
        }

    };

    async function getCountryKDSettings() {
        var response = null;
        let request = {
            url: `${DCSEndPoint}/OPM/settings`,
            method: 'GET',
            mode: 'cors'
        }
        try {
            response = await apiCall(request, customheaders)
        }
        catch {
            logs.error(page.DCSService, 'RPService', "Error in getting user Profile info api", request.url);
        }
        finally {
            return response;
        }

    };

    return { getUserProfileInfo, getCountryKDSettings };
};

export default useUserProfileService;