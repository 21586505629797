import React, { useState, useEffect } from 'react'
import './styles/main.scss'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './config/queryClientConfig'
import AppInsightsContextProvider from './contexts/AppInsightsContext'
import LoadingContextProvider from './contexts/LoadingContext'
import PageContextProvider from './contexts/PageContext'
import TocContextProvider from './contexts/TocContext'
import GuidebookTocContextProvider from './contexts/GuidebookTocContext'
import SearchResultContextProvider from './contexts/SearchResultContext'
import RAContextProvider from './contexts/ResearchAssistantContext'
import ErrorHandler from './middleware/ErrorHandler'
import Routes from './Routes'
import ReadingModeContextProvider from './contexts/ReadingModeContext'
import PreviewWarning from './components/PreviewWarning/PreviewWarning'
import AnnotationsContextProvider from './contexts/AnnotationsContext'
import LogoutContext from './contexts/LogoutContext'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './config/msalConfig'
import { useLocation } from 'react-router-dom'
import { REGEX_PATTERN } from './components/Constants/Constants'
import UTILITIESHELPER from './helpers/UtilitiesHelper'
import SessionExpiredModal from './components/Loading/SessionExpiredModal'
import './styles/chatbot.scss'
import { useMsal } from '@azure/msal-react'
import RecentViewContextProvider from './contexts/RecentViewContext'
import  FolioPickerContext from './contexts/FolioPickerContext'



function App() {
  const [logoutState, setLogoutState] = useState('')
  const value = { logoutState, setLogoutState }
  const location = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [shouldShowOverlayModal, setShouldShowOverlayModal] = useState(false)
  const { instance } = useMsal();

  useEffect(() => {
    if (
      location?.pathname === '/' ||
      REGEX_PATTERN.homePageUrl.test(location?.pathname)
    ) {
      document.body.classList.remove(
        'prod-body-container',
        'nonprod-body-container',
        'research-assistant'
      )
    } else {
      if (UTILITIESHELPER.checkIsProdSite()) {
        document.body.classList.add('prod-body-container')
      } else {
        document.body.classList.add('nonprod-body-container')
      }
      }
      if (location?.pathname === '/researchassistant') {
          document.body.classList.add('research-assistant');
      }
  }, [location])

  useEffect(() => {
    localStorage.setItem('logout-event', value.logoutState)
  }, [value.logoutState])

  useEffect(() => {
    const handler = (evt) => {
      if (evt.key === 'logout-event' && evt.newValue !== ' ') {
        const msalInstance = new PublicClientApplication(msalConfig)
        var currentAccount = msalInstance.getActiveAccount()
        window.localStorage.clear()
        window.sessionStorage.clear()
        sessionStorage.setItem('timeoutvalue', '');
        msalInstance.logoutRedirect({
          logoutHint: currentAccount.idTokenClaims.login_hint,
        })
      }
    }
    window.addEventListener('storage', handler)

    return () => {
      window.removeEventListener('storage', handler)
    }
  }, [])

  useEffect(() => {
    let idleWait
    const value = sessionStorage.getItem('timeoutvalue');
    if(value){
       var currentAccount = instance.getActiveAccount();
       setLogoutState(currentAccount?.idTokenClaims?.login_hint);
       localStorage.setItem('logout-event', 'started');
       localStorage.removeItem('logout-event');
       sessionStorage.setItem('timeoutvalue', '');
       instance.logoutRedirect({ logoutHint: currentAccount?.idTokenClaims?.login_hint });
    }
        const handleActivity = (e) => {
            resetTimeout();
        };

    const resetTimeout = () => {
      clearTimeout(idleWait)
      idleWait = setTimeout(() => {
        sessionStorage.setItem('timeoutvalue', idleWait);
        setShowModal(true)
        setShouldShowOverlayModal(true)
      }, window.DART.ENV.RP_REACT_SESSION_EXPIRTAION) // 60 minutes in milliseconds
    }
    if(idleWait ===undefined){
        sessionStorage.setItem('timeoutvalue', '');
        }
    document.addEventListener('mousemove', handleActivity)
    document.addEventListener('click', handleActivity)
    document.addEventListener('keydown', handleActivity)

    resetTimeout()

    return () => {
      document.removeEventListener('mousemove', handleActivity)
      document.removeEventListener('click', handleActivity)
      document.removeEventListener('keydown', handleActivity)
      clearTimeout(idleWait)
    }
  }, [])

  return (
    <LogoutContext.Provider value={value}>
      <ErrorHandler>
        <PreviewWarning></PreviewWarning>
        <LoadingContextProvider>
          <AppInsightsContextProvider>
            <PageContextProvider>
              <FolioPickerContext>
              {showModal && (
                <SessionExpiredModal
                  SetIsModalOpen={setShowModal}
                  isModalOpen={showModal}
                  setShouldShowOverlay={setShouldShowOverlayModal}
                  shouldShowOverlay={shouldShowOverlayModal}
                ></SessionExpiredModal>
              )}
              <TocContextProvider>
                <GuidebookTocContextProvider>
                  <ReadingModeContextProvider>
                    <SearchResultContextProvider>
                      <AnnotationsContextProvider>
                        <RAContextProvider>
                          <RecentViewContextProvider>
                            <Routes />
                          </RecentViewContextProvider>
                        </RAContextProvider>
                      </AnnotationsContextProvider>
                    </SearchResultContextProvider>
                  </ReadingModeContextProvider>
                </GuidebookTocContextProvider>
              </TocContextProvider>
              </FolioPickerContext>
            </PageContextProvider>
          </AppInsightsContextProvider>
        </LoadingContextProvider>
      </ErrorHandler>
    </LogoutContext.Provider>
  )
}

export default App
