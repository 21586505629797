import React from "react";

export const AnnotationNotification = ({
  notificationText,
  closeNotification,
}) => {
  return (
    <div className="an-notification">
      <span className="notification-warning">&nbsp;</span>
      <p className="notification-label">{notificationText}</p>
      <button className="an-notification-close" onClick={closeNotification} />
    </div>
  );
};
