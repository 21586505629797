import React from 'react'
import { usePageContextLabels, usePageContext } from "../../contexts/PageContext";
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import { useLocation } from 'react-router-dom';
import LINKHELPERS from '../../helpers/LinkHelpers';

const ResearchAssistantTile = () => {
    const { getLabel } = usePageContextLabels();
    const { searchPhrase } = usePageContext();
    const location = useLocation();
    var context = CONTEXTHELPERS.generateContext(location.pathname);

    const handleOpenNewTab = () => {
        localStorage.setItem('research-assistant-searchphrase', searchPhrase);
        const linkUrl = `/${context.memberFirm}/${context.language}/${context.knowledgeDomain}`;
        window.open(LINKHELPERS.sanitizeUrl(linkUrl), '_blank');
    }

    return (
        <div className='advertisement-container'>
            <div className='advertisement-box'>
                <div className='chat-icon'>
                    <img className='chat-icon-img' />
                </div>
                <div className='advertisement-content'>
                    <label className='finding-answer'>
                        {getLabel('ra_notfindinganswer','Not finding the answer you need?')}
                        <span className='link-icon' onClick={handleOpenNewTab}>
                            <img className='goto-next-icon'/>
                        </span>
                    </label>
                    <label className='try-research-assistant'>
                        {getLabel('ra_trywidersearch','Try a wider search using Research Assistant')}
                    </label>
                </div>
            </div>
            <div>
                <button className='move-catalog' onClick={handleOpenNewTab}>
                    <img src='/images/right-arrow-white.svg' className='right-arrow' />
                </button>
            </div>
        </div>
    )
}
export default ResearchAssistantTile;