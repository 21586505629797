import React, { memo, useEffect, useRef } from "react";
import ChatbotResearchAssistant from "../../ChatBot/ChatbotResearchAssistant";

const ResearchAssistantResponse = ({ message, messgaeResponse, countId, currentIndex }) => {

    const conversationBox = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            conversationBox.current.scrollIntoView({ behavior: "smooth", block: "end" })
        })
    }, [message])
    return (
        <div className="convo-box conversation-ra-response" ref={conversationBox}>
            <div className="ra-response">
                <ChatbotResearchAssistant isResearchAssistantLoading={message.loader} expandMore={false} researchAssistantResponse={message?.response} isConversationalMode={true} messgaeResponse={messgaeResponse && messgaeResponse} countId={countId && countId} currentIndex={currentIndex} isChat={true} errorMessage={message?.errorMessage} />
            </div>
        </div>
    )
}

export default memo(ResearchAssistantResponse)     