
import React from 'react';
import cx from 'classnames';

const MainPannel = ({ className, children, style }) => {
    return (
        <div style={style} className={cx("main-pannel", className)}>
            {children}
        </div>
    )
}

export default MainPannel;
