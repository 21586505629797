
import React, { useState, useEffect, useCallback } from "react";
import Dropdown from '../Common/Dropdown';
import { usePageContext } from "../../contexts/PageContext";
import { useNavigate, useLocation } from "react-router-dom";
import { ACTION, useFolioPickerContext } from '../../contexts/FolioPickerContext';
import cx from 'classnames';
import TierHelpers from '../../helpers/TierHelpers';
import IndustryHelpers from '../../helpers/IndustryHelpers';


function FolioTrigger({ folio, searchQuery, setSearchQuery }) {
    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    return (

        <div className="an-dd-folio-trigger">
            <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder={folio.label} className={cx("folio-search-input", { "placeholder-bold": isFocused })} onFocus={handleFocus} onBlur={handleBlur} />
        </div>
    )
}

function FolioItem({ folio, selected, onClick }) {
    const { setIsDropdownOpen } = useFolioPickerContext();
    const selectedString = selected.id
    const folioString = folio.id
    setIsDropdownOpen(true);

    function handleClick() {
        onClick(folio);
    }
    return (
        <div className="an-dd-folio-item" onClick={handleClick}>
            <div className={selectedString === folioString ? "an-dd-folio-selection" : "an-dd-folio-non-selection"}></div>
            <div className="an-dd-folio-name">{folio.name}</div>
        </div>
    )
}

function FolioDropdown({ folioPickerLists, selected, onSelect, isFixed, searchQuery, setSearchQuery }) {
    const { isDropdownOpen, setIsDropdownOpen } = useFolioPickerContext();
    const [sidebarHeight, setSideBarHeight] = useState(0);
    function handleSelect(folio) {
        onSelect(folio);
        setIsDropdownOpen(false);
    }
    function moveSelectedToTop(list, selected) {
        const selectedItem = list?.find(item => item.id == selected.id);
        const filteredList = list?.filter(item => item.id != selected.id);
        return selectedItem ? [selectedItem, ...filteredList] : filteredList;
    }
    const filteredFolioPickerLists = folioPickerLists?.items?.filter((folio) => (folio.label.toLowerCase()).includes(searchQuery.toLowerCase()));
    const newList = moveSelectedToTop(filteredFolioPickerLists, selected);


    function getTriggerEl() {

        return <FolioTrigger folio={selected} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />;
    }

    useEffect(() => {
        const sidePannelBody = document.querySelector(".side-pannel-inner-container");
        const breadCrumbHeight = document.querySelector('.crumbtrail')
        if (sidePannelBody) {
            setSideBarHeight({ height: sidePannelBody.offsetHeight - 28 - breadCrumbHeight.offsetHeight, width: sidePannelBody.offsetWidth })
        }
    }, []);

    return (
        <div className="foliopicker-dropdown-main">
            <Dropdown
                triggerEl={getTriggerEl()}
                ddClassNames="an-dd"
                isFolioDropdown={true}
                sidebarHeight={sidebarHeight}
                isFixed={isFixed}
                containerClassNames={`an-dd-container-folio ${isFixed ? 'container-folio-fixed' : ''}`}
                controlledOpen={isDropdownOpen}
                setControlledOpen={setIsDropdownOpen}
                onChange={setIsDropdownOpen}

            >

                <Dropdown.Panel additionalClassNames="an-dd-panel-folio">
                    <Dropdown.ScrollArea
                        additionalClassNames={`an-dd-scroll-area-folio ${isFixed ? 'scroll-folio-fixed' : ''}`}
                    >
                        {newList.length > 0 ? newList.map((item) => (
                            <FolioItem
                                key={`$${item.id}`}
                                folio={item}
                                onClick={handleSelect}
                                selected={selected}
                            />
                        )) : (
                            <div className="an-dd-folio-item no-options">No option</div>
                        )}
                    </Dropdown.ScrollArea>
                </Dropdown.Panel>
            </Dropdown>
        </div>
    );
}

function FolioPickerDropdown({ folioPickerLists, folioPickerId, selected, isFixed }) {

    const { state, folioDispatch } = useFolioPickerContext();
    const [selectedFolio, setSelectedFolio] = useState(selected);
    const { memberFirm, language, knowledgeDomain ,context, tier, setTier, industry, setIndustry, setCurrentTier, setCurrentIndustry } = usePageContext();
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');

    const clearTierList = () => {
        const tier = TierHelpers.removeLocalStorageWithValue(context.knowledgeDomain, context.memberFirm);
        setTier(tier);
        setCurrentTier(tier);
    }

    const clearIndustryList = () => {
        const industry = IndustryHelpers.removeLocalStorageWithValue(context.knowledgeDomain, context.memberFirm);
        setIndustry(industry);
        setCurrentIndustry(industry);
    }

    function handleFolioSelect(folio) {
        setSelectedFolio(folio);

        const payload = {
            selected: folio.name,
            id: folio.id,
            name: folio.label,
            taxonomyId: folioPickerId,
            folioLists: state.folioLists,
            memberFirm:context.memberFirm

        }

        folioDispatch({
            type: ACTION.FOLIOPICKER_LOAD,
            payload: payload,
        });

        if (tier[context.memberFirm][context.knowledgeDomain]?.length > 0) {
            clearTierList();
        }
        if (industry[context.memberFirm][context.knowledgeDomain]?.length > 0) {
            clearIndustryList();
        }
        const folioLabel = folio.href.substring(folio.href.lastIndexOf("/")+1);
        navigate(`/${memberFirm}/${language}/${knowledgeDomain}/folio/${folioLabel}`);
     
    }
    return (
        <div className={cx("folio-picker", { 'folio-fixed': isFixed })}>

            {folioPickerLists && (
                <FolioDropdown
                    folioPickerLists={folioPickerLists}
                    folioPickerId={folioPickerId}
                    selected={selectedFolio}
                    onSelect={handleFolioSelect}
                    isFixed={isFixed}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                   

                />
            )}
        </div>
    );
}

export default FolioPickerDropdown