export const MEMBERFIRM_CONSTANTS = {
    global: "global",
    dtt: "dtt",
    us: "us"
}

export const KNOWLEDGE_DOMAIN = {
    accounting: "accounting",
    assurance: "assurance",
    auditing: "auditing"
}

export const KNOWLEDGE_DOMAIN_ID = {
    accountingId: "17573178",
    assuranceId: "68489712",
    auditingId: "17573941"
}

//querySpec.AuditWorkflowAreaAccount
export const FOLIO_SUBJECT_ACCOUNT = {
    accountBalance: "17653684",
    accountingFolios: "66029487",
    auditingAccountingFolios: "66019138"
}

export const PUBLICATIONTYPE_IDS = {
    manual : "60194866",
    standards : "60194865",
    deloitteCommunications:"60194864",
    professionalNews: "65511719",
    externalCollection: "86191938"
}

export const CONTENT_TYPE_IDS = {
    section: "17377912",
    sectionWithIntro: "65469055",
    article: "63887580",
    externalArticle: "86191937",
    template: "17323604",
}

export const ARTICLE_TYPES = [CONTENT_TYPE_IDS.article, CONTENT_TYPE_IDS.externalArticle];

//querySpec.AuditWorkflowAreaNonAccount
export const FOLIO_SUBJECT_NON_ACCOUNT = {
    assuranceFolios: "69200678",
    auditingNonAccountFolios: "66019141",
    nonAccountBalance: "48082103"
}

export const PAGE_CONTROLLER_TYPE = {
    deloitteCommunications: "deloitte-communications",
    folio: "folio",
    journals: "journals",
    manual: "manual",
    professionalNews: "professional-news",
    roadmap: "roadmap",
    standards: "standards",
    standard : "standard", //Introduced a new value for all External Standards 
    guidance: "guidance",
    collection: "collection"

}

export const FOLIO_SECTION_TYPE = {
    alerts: "alerts",
    guidance: "guidance",
    qna: "qna",
    templates: "templates",
    other: "other",
    related: "related",
    //Potentially depretiated, but these are the ones that we had in folios...
    disclosures: "disclosures",
    modelfs: "modelfs",
    dww: "dww",
    roadmap: "roadmap",
    gra: "gra",
    investment: "investment",
    objectives: "objectives",
    embedded: "embedded",
    examples: "examples",
    questionsandanswers: "questions-and-answers",
    scenarios: "scenarios",
    omniatemplates: "omnia-templates",
    rptemplates:"rp-templates"
}

export const PUBTYPES_CATALOG_PAGE = ["folio", "manual", "standards", "deloitte-communications", "professional-news"]

export const TEMPLATE_REPOSITORY_PUBLICATION_TYPES = {
    template_repository_publicaton_type: "68169717",
    template_repository_omnia_publicaton_type: "58035381",
    template_repository_account_publicaton_type: "68489711"
}

export const REGEX_PATTERN = {
    doubleQuoted: /^"([^"]*)"$/,
    wildcards: /^[\*,\/,\?].*/, //eslint-disable-line
    homePageUrl: /^\/{1}[a-z]{1,}\/{1}[a-z \- A-Z]+$/,
    DoubleForwardSlash: /(\/{2,3})/g,
    error4xxSeries: /4\d{2}/,
    error5xxSeries:  /5\d{2}/,
    anyDigit: /\d/
}

export const ARTICLE_DOWNLOAD = {
    HEADER: "Download Alert",
    BODY: "Recommended download size has exceeded. Please select fewer items to download.",
    SIZE_LIMIT: 40428800
}

export const RESEARCH_ASSISTANT_FILTERS = {
    Frameworks: "Frameworks",
    KnowledgeDomain: "KnowledgeDomain"
}


export const RESEARCH_ASSISTANT = {
    FEEDBACK_URL: "https://deloitteus.service-now.com/sp?id=kb_article&sys_id=51414a5d1b8dc1d49cbfa8e82d4bcb33",
    RA_CONVO_URL: "/researchassistant",
    AMBIGUOUS: "AMBIGUOUS",
    UNANSWERABLE: "UNANSWERABLE",
    raIntroduction: 'Hello! I am the Research Assistant. I am a generative AI assistant that can help research audit-related questions based on content in Research Portal. I have access to Deloitte guidance, including manuals, guides, practice aids, and Q&As.',
    unableToAnswerText: 'For very detailed questions on what audit procedures you need to perform, you will need to refer to Omnia (and/or Levvia). I am also not going to answer questions related to internal Deloitte policies (talent, independence etc.).',
    conditionalStringINT: "Be sure to review any local requirements, policies, and/or guidance that may be relevant to an engagement in your jurisdiction.",
    conditionalStringUS : "Securities and Exchange Commission(SEC) statements",
    contentExclustionProfessionalStandard : 'Professional standards and other external materials',
    contentExclustionDeloitteCommunication : 'Deloitte communications',
    termsofuse: "<p class='tosdeclaration-heading'>Purpose</p><p>Research Assistant is an integrated search and conversational AI tool within Research Portal. It enables Deloitte professionals to efficiently search for information within Research Portal. By using this tool, you agree to these terms. If you do not agree, do not use the tool.</p><p class='tosdeclaration-heading'>Internal Use Only</p><p>Research Assistant is intended solely for the internal use for Deloitte professionals.</p><p class='tosdeclaration-heading'>Descriptive of Service</p><ul><li>Research Assistant is an integrated search and conversational AI tool within Research Portal. Leveraging generative AI, it assists with content retrieval, answers questions, and facilitates interactive query handling.</li><li>Research Assistant is only available in jurisdictions that have specifically approved its use.</li><li>Users must not use the tool for any unauthorized commercial purpose or for personal gain.</li><li>Users must not interfere with its operation or input inappropriate or sensitive content.</li><li>All content accessed through Research Assistant is owned by Deloitte.</li></ul><p class='tosdeclaration-heading'>Output Quality</p><p>Output quality is your responsibility. You must ensure the accuracy, completeness and relevance of any output generated. Responses from Research Assistant may be inaccurate, unclear, incomplete, or out of date. Users must review all outputs and associated source material thoroughly.</p>"
}

export const RESEARCH_ASSISTANT_SOURCE_TYPES = {
    DOCUMENT: "DOCUMENT",
    FORMS_AND_TEMPLATES: "FORMS_AND_TEMPLATES"
}
    

export const APPLICATION_INSIGHTS_CONSTANTS = {
    annotation_name: "ANNOTATION",
    annotation_eventType: "annotation",
    bookmark_name: "BOOKMARK",
    bookmark_eventType: "bookmark"
}

export const SESSION_STORAGE_CONSTANTS = {
    selected_content: "SelectedContent",
    page_type: "PageType",
    level: "Level"
}

export const LOCAL_STORAGE_CONSTANTS = {
    research_portal_memberfirm: "research-portal-member-firm"
}

export const RESEARCH_ASSISTANT_ERROR_TYPES = {
    NO_CONTENT:"NO_CONTENT"
}

export const COUNTRY_LANGUAGE = {
    DEFAULT_LANGUAGE: "en-US"
}

export const MemberFirmID = {
    dtt: '17573177'
}
export const REVISION_HISTORY = {
    revision_history: '67748346'
}
export const Learning_Api_Call = {
    "title": {
        "Content": {
            "icon": null,
            "preTitle": null,
            "title": "Learning Resorces",
            "intro": "Learning Resorces",
            "body": null
        },
        "SchemaId": 1721,
        "Metadata": {
            "view": null,
            "altText": null,
            "standardMeta": {
                "friendlyPath": "learning",
                "dateCreated": "0001-01-01T00:00:00",
                "description": null,
                "name": null,
                "introText": null,
                "author": null
            }
        },
        "Id": 1811,
        "ContextId": 0,
        "Namespace": "tcm",
        "Folder": {
            "Id": 465,
            "Title": "Sections"
        },
        "LinkUrl": null,
        "BinaryContent": null,
        "XpmMetadata": null,
        "MvcData": null,
        "ComponentTemplate": null,
        "PublicationGUID": null
    },
    "context": [
        {
            "resultLimit": 0,
            "publicationType": {
                "Metadata": null,
                "Id": 1751,
                "Namespace": "1",
                "Description": "Announcements",
                "Key": "60194864",
                "TaxonomyId": 442,
                "Title": "Announcement"
            },
            "auditWorkflowArea": null,
            "auditWorkflowAreaAccount": null,
            "industry": null,
            "contentType": null,
            "contextFilterArea": null,
            "knowledgeDomainFilterArea": null,
            "publicationLists": null,
            "viewAllLinkText": null,
            "publicationListsQuerySpec": null
        },
        {
            "resultLimit": 0,
            "publicationType": {
                "Metadata": null,
                "Id": 2489,
                "Namespace": "1",
                "Description": "Announcements",
                "Key": "65511719",
                "TaxonomyId": 442,
                "Title": "Announcement - External"
            },
            "auditWorkflowArea": null,
            "auditWorkflowAreaAccount": null,
            "industry": null,
            "contentType": null,
            "contextFilterArea": null,
            "knowledgeDomainFilterArea": null,
            "publicationLists": null,
            "viewAllLinkText": null,
            "publicationListsQuerySpec": null
        }
    ],
    "tableOfContents": null,
    "embeddedPublication": null,
    "sectionFriendlyPath": "learning",
    "sectionContainsPublications": true
}
export const LINK_REDIRECTION_CONSTANTS = {
    Link_Redirection_URL_Identifier: "/content/docid="
}
