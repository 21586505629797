import React, { useEffect} from 'react';
import MainPannel from "../Common/MainPannel"
import Searchbar from "../Common/Searchbar"
import ShowIf from '../Common/ShowIf'
import UtilitiesHelper from '../../helpers/UtilitiesHelper'
import { useTocContext } from '../../contexts/TocContext';
import { usePageContextLabels, usePageContext } from '../../contexts/PageContext';
import HTMLContent from '../Common/HTMLContent';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import cx from 'classnames';
import { useSearchResultContext } from '../../contexts/SearchResultContext';

const PublicationLandingMainPannel = ({
    publicationType,
    pubLandingTitle,
    preface,
    currentAsOfDate,
    isWideMode,
    pubData
}) => {

    const { setSelectedItemTOC } = useTocContext();
    const { getLabel } = usePageContextLabels();
    const { guidebookTocLoaded, setGuidebookSectionsLoaded } = useGuidebookTocContext()
    const { pageControllerType, setSearchPhrase } = usePageContext();
    const { setSearchResults } = useSearchResultContext();
    const dateValue = UtilitiesHelper.generateFriendlyDate(currentAsOfDate);
    const pubNoticesText = pubData.publicationTOC?.context?.pub_notices_text;

    
    useEffect(() => {
        setSelectedItemTOC({});
        setGuidebookSectionsLoaded(0);
        setSearchPhrase('');
        setSearchResults([]);
    }, []);//eslint-disable-line

    return (
        <MainPannel
            style={{ marginRight: isWideMode ? 'auto' : '' }}
            className="ml-auto d-flex flex-column"
        >

            <div className="publication-landing-body">
                <h2 className="m-auto publication-landing-heading">
                    {pubLandingTitle || getLabel("g_Loading", "Loading...")}
                </h2>
                <Searchbar
                    placeholder={`${getLabel("s_Search", "Search")} ${publicationType}`}
                    className={cx("searchbar-publication-landing", {
                            "searchbar-publication-landing-disabled": (!guidebookTocLoaded&& pageControllerType==="folio"),
                    })}
                       disableState={(!guidebookTocLoaded&& pageControllerType==="folio") ? true : false}
                />
                <div className="article-preface"><HTMLContent>{preface}</HTMLContent></div>
                <ShowIf condition={dateValue !== ""}>
                    <div className="article-preface" >
                        {getLabel("b_BinaryCurrentAsOf", "Current as of:") + " " + dateValue}
                    </div>
                </ShowIf>
            </div>

            <ShowIf condition={!pubNoticesText}>
                <p className="article-copyright">
                    <HTMLContent>{getLabel("g_Copyright", `Copyright © ${new Date().getFullYear()} Deloitte Development LLC. All Rights Reserved`)}</HTMLContent>
                </p>
            </ShowIf>
        </MainPannel>
    )
}

PublicationLandingMainPannel.defaultProps = {
    pubLandingTitle: '',
    preface: '',
};

export default PublicationLandingMainPannel;
