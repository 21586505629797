import React, { useEffect } from "react";
import "./styles/noAccess.scss";

const NoAccess = () => {
  const serviceNowUrl = window.DART.ENV.REACT_APP_SUPPORT_SERVICENOW;

  return (
    <div className="dashboard-body">
      <img className="dashboard-background" src="/images/2510.jpg" alt="Background image" />
      <section className="dashboard">
        <div className="dashboard-content">
          <h1>Welcome to the Research Portal</h1>
          <p>
            To continue your research experience, please perform a one-time
            action of pressing CTRL+Shift+R or CTRL+F5 to clear your browser
            cache.
          </p>
          <div>

                <p>
                  Continuing to have trouble? Click {" "}
                  <a className="no-access-anchor"
                    title="Service Now"
                    href={serviceNowUrl}
                    target="_blank"
                  >here</a> for more Information
                </p>

          </div>
        </div>
      </section>
      <footer className="footer footer-homepage">
        <div className="footer-item">
          Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited
          (“DTTL”), its global network of member firms, and their related
          entities (collectively, the “Deloitte organization”). DTTL (also
          referred to as “Deloitte Global”) and each of its member firms and
          related entities are legally separate and independent entities, which
          cannot obligate or bind each other in respect of third parties. DTTL
          and each DTTL member firm and related entity is liable only for its
          own acts and omissions, and not those of each other. DTTL does not
          provide services to clients. Please see
          <a className="footer-href" href="http://www.deloitte.com/about">
            &nbsp; www.deloitte.com/about
          </a>{" "}
          to learn more.
        </div>
      </footer>
    </div>
  );
};

export default NoAccess;
