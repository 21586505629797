import React, { useState, useRef, useContext, useEffect, useMemo } from 'react';
import { usePageContextLabels, usePageContext } from '../../contexts/PageContext';
import useDCSService from '../../services/useDCSService';
import { KNOWLEDGE_DOMAIN, RESEARCH_ASSISTANT_FILTERS, MEMBERFIRM_CONSTANTS } from '../Constants/Constants';
import ShowIf from '../Common/ShowIf';
import cx from 'classnames';
import Dropdown from '../Common/Dropdown';
import ResearchAssistantResponse from '../Common/ResearchAssistantConversation/ResearchAssistantResponse';
import ResearchAssistantQuery from '../Common/ResearchAssistantConversation/ResearchAssistantQuery';
import ProfileHelpers from '../../helpers/ProfileHelpers';
import FollowUpQuestions from './FollowUpQuestions';
import DropdownPreferencesRA from './DropdownPreferncesRA';
import { useResearchAssistantContext, ACTIONS } from '../../contexts/ResearchAssistantContext';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import ChatbotIntroduction from './ChatbotIntroduction';
import { useThemeContext } from "../../contexts/ThemeContext";
import { useMsal } from '@azure/msal-react';
import LogoutContext from '../../contexts/LogoutContext';
import { Link } from 'react-router-dom';
import useOutsideClick from '../../hooks/useOutsideClick';
import { usePageContextSettings } from '../../contexts/PageContext';
import OptionList from "../Common/Layout/DropdownMemberFirm/OptionList";
import Option from "../Common/Layout/DropdownMemberFirm/Option";
import ResearchAssistantErrorMessage from '../Common/ResearchAssistantErrorMessage';
import TosDeclaration from './TosDeclaration';


const ConversationMode = () => {
    const userSettingsRef = useRef();
    const userInputRef = useRef();
    const { isDashboardEnabled } = usePageContextSettings();
    const { getLabel } = usePageContextLabels();
    const { countryKDSettings, isResearchAssistantEnabled, isUserProfileLoaded, isCountryKDSettingsLoaded, researchAssistantMemeberfirms, userCountry } = usePageContext();
    const { dispatch, state: raState, tosAgreed, setTosAgreed } = useResearchAssistantContext();
    const [isResearchAssistantLoading, setIsResearchAssistantLoading] = useState(false);
    const [researchAssistantResponse, setResearchAssistantResponse] = useState();
    const [isResearchAssistantChat, setIsResearchAssistantChat] = useState(false);
    const { retrieveChatBotSearchResult } = useDCSService();
    const [searchPhrase, setSearchPhrase] = useState('');
    const [memberFirmSelected, setMemberFirmSelected] = useState();
    const [raApiFailure, setRaApiFailure] = useState(null);
    const messageTypeAnswer = "Answer";
    const messageTypeQuery = "Query";
    const [messages, setMessages] = useState([]);
    const { hasDarkModePreference, setHasDarkModePreference, isDarkMode } = useThemeContext();
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const [showRPInfoMessage, setShowRPInfoMessage] = useState(true)
    const [showTextAreaWithScroll, setShowTextAreaWithScroll] = useState(false)
    const [chatTextCount, setChatTextCount] = useState(0)
    const isResearchAssistantEnabledForKD = UTILITIESHELPER.isResearchAssistantEnabledForKD(countryKDSettings, raState.memberFirm, raState.knowledgeDomain);
    const isResearchAssistantActiveForUser = isResearchAssistantEnabled && isResearchAssistantEnabledForKD ? true : false;
    let userProfileInside;
    if (firstName && lastName && firstName.length > 0 && lastName.length > 0) {
        userProfileInside = firstName.charAt(0) + lastName.charAt(0);
    } else {
        userProfileInside = <img className="default-profile" src="/images/signin-placeholder.svg" alt="Default User" />;
    }
    const [count, setCount] = useState(0)
    const tiers = [];
    const { instance } = useMsal();
    const { setLogoutState } = useContext(LogoutContext);
    const [isPageSettingsOpen, setIsPageSettingsOpen] = useState(false);
    const [conversationStarted, setConversationStarted] = useState(false);
    const [filterApplied, setfilterApplied] = useState(null);
    const [previousMessage, setPreviousMessage] = useState([]);
    const isRAFooter = !UTILITIESHELPER.isStringNullorEmpty(getLabel('ra_disclaimer', ''));
    //ResearchAssistant Response from Catalog Page
    useEffect(() => {
        if (isUserProfileLoaded && isCountryKDSettingsLoaded) {
            if (!isResearchAssistantActiveForUser) {
                window.open("/", "_self");
            }
        }
    }, [isUserProfileLoaded, isCountryKDSettingsLoaded]);


    useEffect(() => {
        const researchAssistantResponseLocal = UTILITIESHELPER.getLocalStorage('research-assistant-catalog-conversation');
        if (!UTILITIESHELPER.isStringNullorEmpty(researchAssistantResponseLocal) && researchAssistantResponseLocal !== undefined) {
            const response = JSON.parse(researchAssistantResponseLocal);
            if (response) {
                setShowRPInfoMessage(false)
                setMessages([...messages, { type: messageTypeQuery, response: response?.message, id: 1 }, { type: messageTypeAnswer, response: null, loader: true, id: 1, errorMessage: null }]);
                setIsResearchAssistantChat(true);
                setResearchAssistantResponse(response);
                setCount(1);
                const CurrentfilterData = {
                    memberFirm: response.memberFirm,
                    knowledgeDomain: response.knowledgeDomain,
                    tierSelected: response.tier
                }
                setfilterApplied(CurrentfilterData);

            }
        }
        UTILITIESHELPER.removeLocalStorage('research-assistant-catalog-conversation');
    }, []);

    useEffect(() => {
        document.title = "Research Portal – Research Assistant";
        var raTakeOverData = {
            memberFirm: !UTILITIESHELPER.isStringNullorEmpty(
                UTILITIESHELPER.getLocalStorage('research-assistant-memberfirm')
            )
                ? UTILITIESHELPER.getLocalStorage('research-assistant-memberfirm')
                : UTILITIESHELPER.getLocalStorage('MemberFirm'),

            memberFirmId: !UTILITIESHELPER.isStringNullorEmpty(
                UTILITIESHELPER.getLocalStorage('research-assistant-member-firm-Id')
            ) ?
                UTILITIESHELPER.getLocalStorage('research-assistant-member-firm-Id')
                : UTILITIESHELPER.getLocalStorage('member-firm-Id'),

            language: !UTILITIESHELPER.isStringNullorEmpty(
                UTILITIESHELPER.getLocalStorage('research-assistant-language')
            )
                ? UTILITIESHELPER.getLocalStorage('research-assistant-language')
                : 'en-US',

            knowledgeDomain: (!UTILITIESHELPER.isStringNullorEmpty(
                UTILITIESHELPER.getLocalStorage('research-assistant-knowledgedomain')
            )
                ? UTILITIESHELPER.getLocalStorage(
                    'research-assistant-knowledgedomain'
                )
                : KNOWLEDGE_DOMAIN.auditing),

            tier: !UTILITIESHELPER.isStringNullorEmpty(
                UTILITIESHELPER.getLocalStorage('research-assistant-tier-selected')
            )
                ? JSON.parse(localStorage.getItem('research-assistant-tier-selected'))
                : [],
            tierSelected :  !UTILITIESHELPER.isStringNullorEmpty(
                UTILITIESHELPER.getLocalStorage('research-assistant-tier')
            )
                ? JSON.parse(localStorage.getItem('research-assistant-tier'))
                : []

        }


        dispatch({
            type: ACTIONS.RA_LOAD,
            payload: raTakeOverData,
        })

        setMemberFirmSelected(raTakeOverData.memberFirm);

    }, [researchAssistantMemeberfirms])

    useEffect(() => {
        setMemberFirmSelected(raState.memberFirm);
    }, [raState.memberFirm])


    useEffect(() => {
        if (conversationStarted) {
            setPreviousMessage([]);
        }

    }, [conversationStarted])

    useEffect(() => {
        if (researchAssistantResponse?.response) {
            const addmessage = (newmsg) => {
                const updatedMessages = [...previousMessage];
                updatedMessages.push(newmsg);
                if (updatedMessages.length > 3) {
                    updatedMessages.shift();
                }
                setPreviousMessage(updatedMessages);
            };
            const prevMsg = {
                message: researchAssistantResponse.message,
                response: researchAssistantResponse.response,
                timestamp: researchAssistantResponse.timestamp
            };
            addmessage(prevMsg);

            const msg = [...messages]
            const res = { type: messageTypeAnswer, response: researchAssistantResponse, loader: false, id: count, errorMessage: raApiFailure }
            if(raApiFailure) setRaApiFailure(null)
            msg.splice(msg.length - 1, 1, res)
            setMessages([...msg]);

        } else {
            if (messages.length) {
                const msg = [...messages]
                const res = { type: messageTypeAnswer, response: null, loader: false, id: 1, errorMessage: raApiFailure }
                if(raApiFailure) setRaApiFailure(null)
                msg.splice(msg.length - 1, 1, res)
                setMessages([...msg]);
            }
        }
    }, [researchAssistantResponse])

    useEffect(() => {
        if (userInputRef?.current?.scrollHeight > 39 && !chatTextCount) {
            if (!showTextAreaWithScroll) {
                searchPhrase.split('\n').length < 2 ? setChatTextCount(searchPhrase.length - 1) : setChatTextCount(null)
            }
            setShowTextAreaWithScroll(true)
        }
        if (userInputRef?.current && showTextAreaWithScroll) {
            const scrlHeight = userInputRef.current.scrollHeight
            userInputRef.current.style.height = 'auto'; // Reset the height
            if (userInputRef?.current?.scrollHeight > 91) {
                userInputRef.current.style.overflowY = 'scroll'; // Show scrollbar
            } else {
                userInputRef.current.style.overflowY = 'hidden'; // Hide scrollbar
            }
            userInputRef.current.style.height = scrlHeight <= 39 ? 30 + 'px' : userInputRef?.current?.scrollHeight + 'px'; // Limit height to maxHeight
            userInputRef.current.style.maxHeight = 82 + 'px'; // Limit height to maxHeight
        }
    }, [userInputRef?.current?.scrollHeight])

    useEffect(() => {
        if (userInputRef?.current && !showTextAreaWithScroll) {
            userInputRef.current.style.height = 'auto'; // Reset the height
            userInputRef.current.style.height = 40 + 'px'; // Limit height to maxHeight
            userInputRef.current.style.overflowY = 'hidden'; // Hide scrollbar
        } else if (userInputRef?.current && showTextAreaWithScroll) {
            userInputRef.current.style.height = 'auto'; // Reset the height
            userInputRef.current.style.height = 30 + 'px'; // Limit height to maxHeight
            userInputRef.current.style.overflowY = 'hidden'; // Hide scrollbar
        }
    }, [showTextAreaWithScroll])

    const handleIAgreeClick = async () => {
        UTILITIESHELPER.setLocalStorage("ra_tou_agreed_status", true);
        UTILITIESHELPER.setLocalStorage("ra_tou_agreed_version", String(window.DART.ENV.RA_TOSDESC_VERSION));
        setTosAgreed(true);
    };

    const handleSubmit = event => {
        event.preventDefault();
        if (isResearchAssistantLoading) {
            return;
        }
        parentOnTrigger(searchPhrase);
        searchPhrase.trim();
        setSearchPhrase('');
        setCount(count + 1)
    };
    const onKeyDown = event => {
        if(event.key === 'Enter' && !event.shiftKey) {
            setShowTextAreaWithScroll(false)
            setChatTextCount(0)
            userInputRef.current.style.height = 40 + 'px';
            handleSubmit(event)
        }
    }

    const handleChange = event => {
        parentOnTrigger("");
        setSearchPhrase(event.target.value);
        if (
            ((event.target.value.length <= chatTextCount && userInputRef?.current?.scrollHeight < 53) ||
                event.target.value.length === 0 || chatTextCount === null) && event.target.value.split('\n').length < 2
        ) {
            setShowTextAreaWithScroll(false)
            setChatTextCount(0)
            userInputRef.current.style.height = 40 + 'px';
        }
    };

    const handleFollowUpClick = (value) => {
        parentOnTrigger("");
        setSearchPhrase(value);
    };

    const closeErrorNotification=()=>{
        let errorPayload = {
            hasError:false
        }

        dispatch({
            type: ACTIONS.RA_HIDE_ERROR,
            payload: errorPayload,
        })
    }

    //Main API calls here
    const parentOnTrigger = async (typedSearchPhrase) => {
        let conversation;
        const fetchChatBotResponse = async () => {
            try {
                setIsResearchAssistantChat(true);
                setIsResearchAssistantLoading(true);
                var payload = {
                    consentToRetainData: true,
                    userLibrariesSelected: [raState.memberFirmId],

                    userFilterSelected: [
                        {
                            filterField: RESEARCH_ASSISTANT_FILTERS.Frameworks,
                            filterID: raState.tier[raState.memberFirm] && raState.tier[raState.memberFirm][raState.knowledgeDomain] && raState.tier[raState.memberFirm][raState.knowledgeDomain].length ? raState.tier[raState.memberFirm][raState.knowledgeDomain].map(x => x.id) : []
                        },
                        {
                            filterField: RESEARCH_ASSISTANT_FILTERS.KnowledgeDomain,
                            filterID: [`${CONTEXTHELPERS.getknowledgeDomainId(raState.knowledgeDomain)}`]
                        }

                    ],
                    currentMessage: typedSearchPhrase,
                    previousMessages: conversation ? [] : previousMessage
                }


                setMessages([...messages, { type: messageTypeQuery, response: typedSearchPhrase, id: count + 1, newconversation: conversation }, { type: messageTypeAnswer, response: null, loader: true, id: count, errorMessage: null }]);
                let chatbotres = await retrieveChatBotSearchResult(payload);
                if (chatbotres) {
                    if(chatbotres instanceof Error){
                        setRaApiFailure(chatbotres.message);
                        setResearchAssistantResponse({});   
                    }
                    else{
                        chatbotres.memberFirm = raState.memberFirm
                        chatbotres.tier = raState.tier[raState.memberFirm] && raState.tier[raState.memberFirm][raState.knowledgeDomain] && raState.tier[raState.memberFirm][raState.knowledgeDomain].length ? raState.tier[raState.memberFirm][raState.knowledgeDomain].sort((a, b) => a.id - b.id).map(x => x.value) : [];
                        chatbotres.knowledgeDomain=raState.knowledgeDomain
                        setResearchAssistantResponse(chatbotres);
                        setRaApiFailure(null);
                    }
                    setIsResearchAssistantLoading(false);
                }
                else {
                    setResearchAssistantResponse({});
                    setIsResearchAssistantLoading(false);
                    setRaApiFailure(null);
                }
            }
            catch (err) {
                setResearchAssistantResponse({});
                setIsResearchAssistantLoading(false);
                setRaApiFailure(null);
            }
        }
        if (String(typedSearchPhrase)?.length > 1 && typedSearchPhrase && isResearchAssistantActiveForUser) {
            setIsResearchAssistantLoading(true);
            document.body.style.overflow = 'hidden';
            const CurrentfilterData = {
                memberFirm: raState.memberFirm,
                knowledgeDomain: raState.knowledgeDomain,
                tierSelected: raState.tierSelected
            }
            if (filterApplied !== null && JSON.stringify(filterApplied) !== JSON.stringify(CurrentfilterData)) {
                conversation = true;
                setConversationStarted(true);
                setPreviousMessage([]);
            }
            else {
                conversation = false;
                setConversationStarted(false);
            }
            const filterData = {
                memberFirm: raState.memberFirm,
                knowledgeDomain: raState.knowledgeDomain,
                tierSelected: raState.tierSelected
            }
            setfilterApplied(filterData);


            fetchChatBotResponse();
        }
    }

    function onClickProfile() {
        document.dispatchEvent(new CustomEvent('dd:requestClose'));
        setIsPageSettingsOpen(prevVal => !prevVal)
    }

    useOutsideClick(userSettingsRef, () => {
        setIsPageSettingsOpen(false);
    });

    const logout = () => {
        var currentAccount = instance.getActiveAccount();
        setLogoutState(currentAccount?.idTokenClaims?.login_hint);
        localStorage.setItem('logout-event', 'started');
        localStorage.removeItem('logout-event');
        sessionStorage.setItem('timeoutvalue', '');
        instance.logoutRedirect({ logoutHint: currentAccount.idTokenClaims.login_hint });
    }

    function setDarkMode() {
        setHasDarkModePreference(prevVal => !prevVal);
        setIsPageSettingsOpen(false);
    }

    //MemberfirmItem 
    function MemberFirmItem({ memberFirm, onClick }) {
        const { getLabel } = usePageContextLabels();
        const [showKnowledgeDomains, setShowKnowledgeDomains] = useState(false);
        const { dispatch, state: raState } = useResearchAssistantContext();

        function handleClick() {
            onClick(memberFirm.country);
        }

        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                onClick(memberFirm.country);
            }
        }
        const options = () => {
            const handleOptionsClick = (kd) => {
                ProfileHelpers.setLastMemberFirmResearchAssistant(memberFirm.country);
                var contexts = {
                    memberFirm: memberFirm.country,
                    memberFirmId: memberFirm.defaultMemberFirm,
                    knowledgeDomain: kd.name,
                    language: !UTILITIESHELPER.isStringNullorEmpty(
                        UTILITIESHELPER.getLocalStorage('research-assistant-language')
                    )
                        ? UTILITIESHELPER.getLocalStorage('research-assistant-language')
                        : 'en-US',

                    tier: { [memberFirm.country]: { [kd.name]: [] } },

                    tierSelected: []

                }
                dispatch({
                    type: ACTIONS.RA_LOAD,
                    payload: contexts,
                })

            }

            if (memberFirm.knowledgeDomain && memberFirm.knowledgeDomain.length > 0) {
                return memberFirm.knowledgeDomain.map(kd => {
                    const fallback = kd.name[0].toUpperCase() + kd.name.slice(1);

                    return (
                        <Option
                            key={kd.name}
                            label={getLabel(kd.label, fallback, true, memberFirm.country)}
                            onClick={() => handleOptionsClick(kd)}
                        ></Option>
                    )
                })
            } else {
                return null
            }

        }
        const handleShowKnowledgeDomains = () => setShowKnowledgeDomains(!showKnowledgeDomains)

        return (

            <div className="mfdd__card" style={{ width: '100%' }}>
                <div className="mfdd__card-header">
                    <div onClick={handleShowKnowledgeDomains}>
                        <ShowIf
                            condition={memberFirm.knowledgeDomain && memberFirm.knowledgeDomain.length}>
                            {
                                !showKnowledgeDomains ? <i className='fa fa-caret-right'></i> :
                                    <i className='fa fa-caret-down'></i>
                            }
                        </ShowIf>

                        <span className="mfdd__card-title">
                            {getLabel(`mf_Library_${memberFirm.country}`, memberFirm.country, true)}
                        </span>

                    </div>
                </div>
                <ShowIf condition={showKnowledgeDomains}>
                    <OptionList>
                        {options()}
                    </OptionList>
                </ShowIf>
            </div>

        )
    }
    //MemberfirmSelector
    function MemberFirmSelector({ memberFirms, onChange, collapsible = false }) {
        const { getLabel } = usePageContextLabels();
        memberFirms = memberFirms.filter(x => x.defaultMemberFirm === "17573177" || x.defaultMemberFirm === "17573163")

        function getTrigger(mfKey) {
            const currentMemberFirm = memberFirms.find((x) => x.country === mfKey);
            let country
            if (!currentMemberFirm && mfKey) {
                country = MEMBERFIRM_CONSTANTS.dtt;
                mfKey = MEMBERFIRM_CONSTANTS.dtt;
            } else if (!currentMemberFirm) {
                return "null";
            } else {
                country = currentMemberFirm.country;
            }
            const value = getLabel(`mf_Library_${mfKey}`, country, true);
            return (<Trigger value={value} />)
        }

        function Trigger({ value }) {
            return (
                <button type="button" className="home-mf__trigger-button">
                    <span className="home-mf__trigger-label">{value}</span>
                </button>
            )
        }
        const activeMemberFirms = useMemo(() => {
            function handleClick(mfName) {
                onChange(mfName);
                document.dispatchEvent(new CustomEvent('dd:requestClose'));
            }
            return memberFirms.map(currentMemberFirm => (
                <MemberFirmItem
                    key={currentMemberFirm.country}
                    memberFirm={currentMemberFirm}
                    memberFirmId={currentMemberFirm.defaultMemberFirm}
                    onClick={handleClick}
                    collapsible={collapsible}
                    researchAssistant="true"

                />
            ));
        }, [raState.memberFirm, memberFirms, onChange]);

        return (
            <Dropdown
                triggerEl={getTrigger(memberFirmSelected)}
                triggerText={getLabel("mf_addlibrary", "Add Library")}
                ddClassNames={`home-mf mf-dropdown`}
                triggerClassNames="home-mf__trigger"
                containerClassNames={`home-mf__containers ${collapsible ? 'home-mf-collapsible' : ''}`}
            >
                <Dropdown.Panel additionalClassNames="mfdd__panel researchassistant-panel">
                    <Dropdown.ScrollArea Height="85px">
                        {activeMemberFirms}
                    </Dropdown.ScrollArea>
                </Dropdown.Panel>
            </Dropdown>
        );
    }

    return (

        <div className="conversation-container">
            <div className="conversation-container-header d-flex">
                <div className="container-box-header">
                    <div className='container-main'>
                        <h1 className="logo">
                            <a href="/" className="deloitte-rp-logo">
                                <ShowIf condition={!isDarkMode}>
                                    <img className="primary-logo" src="/images/cache/1/49/2567.svg" alt="Deloitte" />
                                </ShowIf>
                                <ShowIf condition={isDarkMode}>
                                    <img className="primary-logo" src="/images/cache/1/49/2551.svg" alt="Deloitte" />
                                </ShowIf>

                            </a>
                        </h1>
                        <h5 className="conversation-container-heading">
                            {getLabel("research-assist-heading", "Research Portal - Research Assistant")}
                        </h5>
                    </div>
                </div>
                <div className="d-flex header-buttons">

                    <a href={`${raState.memberFirm}/${raState.language}/content/support`} title="ResearchAssistant-Support" className="support" alt="questionmark" target="_blank" rel="noreferrer">

                    </a>

                    <div className="profile-main">
                        <div
                            className="profile" onClick={onClickProfile}>
                            <span className="user-initials">
                                {userProfileInside}
                            </span>
                        </div>
                        <ShowIf condition={isPageSettingsOpen}>
                            <div className="page-settings" ref={userSettingsRef}>
                                <strong className="page-settings-text">{getLabel("ps_PageSettings", "Page Settings")}</strong>

                                <ShowIf condition={isDashboardEnabled()}>

                                    <div className="dark-mode-row-container">
                                        <button
                                            className="toggle-container-dark-mode d-flex"
                                        >
                                            <Link
                                                onClick={() => window.open(`/${raState.memberFirm}/${raState.language}/${raState.knowledgeDomain}/dashboard/annotations`, '_blank')}
                                                className="dashboard-link text-toggle"
                                            >
                                                {getLabel("ps_UserDashboard", "User Dashboard")}
                                            </Link>
                                        </button>
                                    </div>
                                    <hr className='divide-bar' />
                                </ShowIf>


                                <div className="dark-mode-row-container">
                                    <button
                                        className="toggle-container-dark-mode d-flex"
                                        onClick={() => setDarkMode()}
                                    >
                                        <div className="text-toggle text-toggle-dark-mode">
                                            {getLabel("ps_ReadingMode", "Dark Mode")}
                                        </div>
                                        <div className={cx("toggle-dark-mode-dropdown", { "toggle-dark-mode-dropdown-off": !hasDarkModePreference })} />
                                    </button>
                                </div>
                                <hr className='divide-bar' />
                                <div className="dark-mode-row-container">
                                    <button
                                        className="toggle-container-dark-mode d-flex"
                                        onClick={() => logout()}
                                    >
                                        {getLabel("ps_Logout", "Logout")}

                                    </button>
                                </div>
                            </div>
                        </ShowIf>
                    </div>
                </div>
            </div>
            <ShowIf condition={raState.hasError}>
                <ResearchAssistantErrorMessage errorType={raState.errorType} closeErrorNotification={closeErrorNotification}/>
            </ShowIf>

            <ShowIf condition={!isResearchAssistantChat && showRPInfoMessage && tosAgreed}>
                <div className={cx ("conversation-box", {"chatbot-introduction" : messages.length === 0,
                     "hidden-footer":!isRAFooter})}>
                  <ChatbotIntroduction />
                </div>
            </ShowIf>
            <ShowIf condition={!tosAgreed}>
                <div className={`conversation-box chatbot-introduction`}>
                    <TosDeclaration handleIAgreeClick = {handleIAgreeClick} isConvoPage={true} userCountry = {userCountry}/>
                </div>
            </ShowIf>
            <ShowIf condition={isResearchAssistantChat && messages?.length > 0 && tosAgreed}>
                <div className={cx ("conversation-box", {"hidden-footer":!isRAFooter}, {'conversationbox-with-error': raState.hasError})}>
                <ShowIf condition={showRPInfoMessage}>
                        <ChatbotIntroduction />
                    </ShowIf>
                    {messages?.map((message, index) => (

                        <>
                            {message.newconversation === true &&
                                <div className='conversation-msg'>
                                    <div className='new-convo'><span className='new-convo-msg'>New conversation started</span></div>
                                </div>
                            }
                            {
                                message.type === messageTypeQuery ?
                                    <ResearchAssistantQuery query={message.response} key={`query${index}`} /> :
                                    <ResearchAssistantResponse message={message} messgaeResponse={messages && messages} countId={message.id} currentIndex={index + 1} key={`response-${index}`}/>
                            }
                        </>

                    ))}

                </div>

            </ShowIf>

            <div className="search-assist">
                <ShowIf condition={researchAssistantMemeberfirms}>
                    <div className='search-assist-body'>
                        <form onSubmit={handleSubmit} className='research-assistant-conversation'>
                            <div className='search-input-box'>
                                {showTextAreaWithScroll && <label
                                    className={cx("catalog-chatboat-modal-input", 'chatbot-input', 'chatbot-input-label')}
                                />}
                                <textarea
                                    ref={userInputRef}
                                    placeholder={getLabel("ra_research_assist_search", "Ask a question")}
                                    className={cx({ "catalog-chatboat-modal-input": showTextAreaWithScroll, 'textarea-input-scroll': showTextAreaWithScroll }, 'chatbot-input')}
                                    value={searchPhrase || ''}
                                    onChange={handleChange}
                                    onKeyDown={onKeyDown}
                                    autoComplete="off"
                                    maxLength='500'
                                    id="catalog-chatboat-modal-search"
                                    data-testid="catalog-chatboat-modal-search-input"
                                    rows={1}
                                />
                            </div>

                            <div className='memberfirm-list'>
                                <MemberFirmSelector memberFirms={researchAssistantMemeberfirms} memberFirm={memberFirmSelected} collapsible={true}></MemberFirmSelector>
                            </div>
                            <div className='tier-list'>
                                <DropdownPreferencesRA />
                            </div>

                            <div className='send-message'>
                                <button type="submit"  className='move-catalog' disabled={isResearchAssistantLoading || !tosAgreed} data-testid='submit-chat'>
                                    <img src='/images/right-arrow-white.svg' className='right-arrow' alt='' />
                                </button>
                            </div>
                        </form>
                        <div className='followup-sections'>
                            <FollowUpQuestions isResearchAssistantLoading={isResearchAssistantLoading} researchAssistantResponse={researchAssistantResponse} handleFollowUpClick={handleFollowUpClick} />
                        </div>
                    </div>
                </ShowIf>
            </div>
        </div>


    )
}

export default ConversationMode
