
import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

const ViewAll = ({ href, text, className }) => {
    return (
        <Link
            to={href}
            className={cx("view-all", className)}
        >
            {text}
        </Link>
    )
}

export default ViewAll;
