import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import ShowIf from '../Common/ShowIf';
import { useReadingModeContext } from '../../contexts/ReadingModeContext';
import HTMLContent from '../Common/HTMLContent';

//For search
import { useArticleContext } from '../../contexts/ArticleContext';
import getTextWithSearchHighlight from '../../helpers/getTextWithSearchHighlight';

export const Annotations = (props) => {
    const firstUpdate = useRef(true);
    const { searchText } = useArticleContext();
    var cookieAnnotationDisplay = UTILITIESHELPER.annotationDisplayStatus();
    var override = true;

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
    }, []);

    if (firstUpdate.current && cookieAnnotationDisplay === false) {
        override = false;
    }

    const [open, setOpen] = useState(override);
    const { isReadingMode } = useReadingModeContext();

    //needed for handling the nesting along with search.
    function createMarkup(annotation) {
        var annotationText = annotation.lead_in_text + ":";
        annotation.links.forEach((link) => { annotationText += " <a data-scope='Internal' href=" + link.link_target + " onClick=\"(e) => e.preventDefault()\">" + link.link_text + "</a>" })
        return annotationText;
    }

    // Don't display annotations in Reading Mode;
    if(isReadingMode) return null;

    if (props.item.content) {
        //var resource = ARTICLEHELPER.extractAnnotationOrAssociatedContent(props.item, props.publication);
        var resource = props.item.content.associations;
        
        if (!UTILITIESHELPER.isObjectNullorEmpty(resource) && !UTILITIESHELPER.isObjectNullorEmpty(resource.annotation_links)) {
            var annotations = resource.annotation_links.filter(function (item) {
                return  item.links.length > 0;
            });

            if (annotations && annotations.length > 0) {
                return (
                    annotations.map((annotation, anindex) => (
                        <>
                            <ShowIf condition={anindex !== 0}>
                                <br/>
                            </ShowIf>
                            <i key={anindex} className={open === true ? 'annotation open' : 'annotation'}>
                                <button className="annotation-icon" onClick={() => setOpen(!open)}>&nbsp;</button>
                                <ShowIf condition={open}>
                                    <span className="annotation-text"><HTMLContent>{getTextWithSearchHighlight(createMarkup(annotation), searchText)}</HTMLContent></span>
                               </ShowIf>
                            </i>
                        </>
                    ))
                );
            }
            else {
                return (null);
            }
        }
        else {
            return (null);
        }
    }
    else {
        return (null);
    }
}

Annotations.propsTypes = {
    title: PropTypes.string,
    typeName: "Annotations"
}

export default Annotations;
