//This constant contain the site keys
export const SCS_KEYS = {
    wildcardsPrefixSearchLimitation: "s_WildcardsPrefixSearchLimitationNotification"
}

//This constant contain the fallback literals for site keys
export const SCS_FALLBACKS = [
    {
        key: SCS_KEYS.wildcardsPrefixSearchLimitation,
        value: "Please refine your search to exclude characters such as (*, ?, /, etc.,). For more information, refer to Quick Reference Guide."
    }
];

//it will return fallback value by site key
export const getFallback = (key) => {
    const foundItem = SCS_FALLBACKS.find((item) => item.key === key);
    return foundItem ? foundItem.value : null;
}