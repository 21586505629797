import React, { useState, useRef, useEffect } from "react";
import useOutsideClick from '../../hooks/useOutsideClick';
import UTILITIESHELPER from "../../helpers/UtilitiesHelper";
import cx from 'classnames';

export const OutageNotification = () => {
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [outageNotification, setOutageNotification] = useState(UTILITIESHELPER.isStringNullorEmpty(window.DART.ENV.REACT_APP_OUTAGE_NOTIFICATION_MESSAGES) ? [] : JSON.parse(window.DART.ENV.REACT_APP_OUTAGE_NOTIFICATION_MESSAGES));
    const userSettingsRef = useRef();
    const currentDate= new Date();
    const filteredNotification=outageNotification.filter(notification=> {

        const notificationDate= new Date(notification.notificationDate);
        return notificationDate > currentDate ;
    });
    const onClickProfile = () => {
        setIsNotificationOpen(prevVal => !prevVal);
        if (document.querySelector(".notification-modal-open")) {
            UpdateNotificationDisplayStatus(outageNotification);
        }
        document.dispatchEvent(new CustomEvent('dd:requestClose'));
    }
    const UpdateNotificationDisplayStatus = (filteredNotification) => {
        const updatedNotifications = filteredNotification?.map(notification => ({
            ...notification,
            isNew: false
        }));
        setOutageNotification(updatedNotifications);
        UTILITIESHELPER.setLocalStorage("ViewedOutageNotification", JSON.stringify(updatedNotifications));
    }

    useOutsideClick(userSettingsRef, () => {
        if (document.querySelector(".notification-modal-open")) {
            const notificationListLocatState = UTILITIESHELPER.getLocalStorage("OutageNotificationList");
            const notificationList = (UTILITIESHELPER.isStringNullorEmpty(notificationListLocatState) || notificationListLocatState === "undefined") ? [] : JSON.parse(UTILITIESHELPER.getLocalStorage("OutageNotificationList"));
            UpdateNotificationDisplayStatus(notificationList);
            setIsNotificationOpen(false);
        }
    });

    useEffect(() => {

        if (filteredNotification && filteredNotification.length > 0) {
            const viewedNotificationString = UTILITIESHELPER.getLocalStorage("ViewedOutageNotification");
            const userViewedNotification = (UTILITIESHELPER.isStringNullorEmpty(viewedNotificationString) || viewedNotificationString === "undefined") ? [] : JSON.parse(UTILITIESHELPER.getLocalStorage("ViewedOutageNotification"));
            const updatedList = filteredNotification?.map(notification => {
                const updateNotificationAvailable = userViewedNotification.find(update => update.notificationId === notification.notificationId);
                if (updateNotificationAvailable) {
                    return {
                        ...notification,
                        isNew: updateNotificationAvailable.isNew
                    };
                }
                return notification;
            });
            UTILITIESHELPER.setLocalStorage("OutageNotificationList", JSON.stringify(updatedList))
            setOutageNotification(updatedList)
        }

    }, []) //eslint-disable-line

    return (
        <div className="outage-notification-outer" ref={userSettingsRef}>
            <div className="notification-bell-container">
            <button data-testid="notification-button" onClick={()=>onClickProfile()} className={cx("notification-bell", { "notification-modal-open": isNotificationOpen })} data-title={"Notification"} title={"Notification"}>&nbsp;</button>
            </div>
      
            
            <div className={"outage-notification-items-box " + (isNotificationOpen ? "" : 'hide')}>
            {( filteredNotification &&filteredNotification?.length>0 ?  ( 
                    filteredNotification?.sort((a, b) => new Date(b.notificationDate) - new Date(a.notificationDate))?.map((notification, i) => {
                        return (
                            <div className="outage-notification-item" key={i}>
                                <div className="notification-message">
                                    <div className={`notification-message-dot ${notification.isNew ? "blue" : "gray"}`} />
                                    <strong className="message-title">{notification.type}</strong>
                                    <p className="message-content">{notification.message}</p>
                                </div>
                                {filteredNotification.length !== (i + 1) && <div><span className="message-divider"></span></div>}
                            </div>
                        )
                    })
                ): (
                    <div className="no-notification">
                      <strong className="message-title"><p className="message-content">No New Notification</p></strong>   
                        </div>      
                    )
         )}
        </div>
        </div>
    );
}