import React from 'react';
import cx from 'classnames'

import Loading from "../../Loading/Loading";
import { useLoadingContext } from "../../../contexts/LoadingContext";
import { usePageContext } from '../../../contexts/PageContext';
import ShowIf from '../ShowIf';

const LayoutChildren = ({children}) => {
    const { isLoading } = useLoadingContext()
    //BDP: This causes the content/content Page to never load as it does not use any of these values
    //  the child should not be hidden while loading regardless anyway, but I added check for contnet pages ONLY
    const { context, hasGottenCorrectTierValue, hasVerifiedIndustry } = usePageContext();

    return (
        <div className="d-flex flex-wrap justify-between catalog-page-search-bar">
            <Loading className={cx('p-absolute', 'layout-loader',{'hidden': !isLoading})} />
            <ShowIf condition={context.knowledgeDomain === "content" || (hasGottenCorrectTierValue && hasVerifiedIndustry)}>
                {children}
            </ShowIf>
        </div>
    )
}

export default LayoutChildren;
