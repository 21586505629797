
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { usePageContextLabels } from '../../contexts/PageContext';
import getIconSrc from '../../helpers/getIconSrc';
import ShowIf from '../Common/ShowIf';
import ViewAll from '../Common/ViewAll';
import HTMLContent from '../Common/HTMLContent';

const CollectionItemExpandable = ({ title, href, icon, text }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const { getLabel } = usePageContextLabels();

    const collectionArrowStyling= {
        transform: isExpanded ? 'rotate(90deg)' : '' 
    }

    return (

        <div className="collection-item-container collection-item-expandable-container d-flex">
            <div className="border-none" href={href}>
                <div className="d-flex collection-item">
                    <div
                        style={collectionArrowStyling}
                        onClick={() => setIsExpanded(prevVal => !prevVal)}
                        className="arrow-right"
                        data-testid="arrow-right"
                    />
                    <img className="icon-type-small" src={getIconSrc(icon)} alt="Publication Icon" />
                    <Link
                        to={href} 
                        className="collection-item-expandable-title"
                    >
                        {title}
                    </Link>
                </div>
                <ShowIf condition={isExpanded}>
                    <div className="collection-item-expandable-section">
                        <ShowIf condition={text}>
                            <p className="collection-item-expandable-text-body"><HTMLContent>{text}</HTMLContent></p>
                        </ShowIf>
                        <ViewAll
                            text={getLabel("c_ViewPublication","View Publication")}
                            href={href}
                            className="view-publication"
                        />
                    </div>
                </ShowIf>
            </div>
        </div>

    )
}

export default CollectionItemExpandable;
