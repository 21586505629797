import React, { createContext, useContext, useReducer,useState } from "react";
import UTILITIESHELPER from "../helpers/UtilitiesHelper";

export const FolioPickerContext = createContext();
export const useFolioPickerContext = () => useContext(FolioPickerContext);

export const ACTION = {
    FOLIOPICKER_LOAD: "folio::load",
    FOLIOPICKER_SELECT: "folio::select",
    FOLIOPICKER_LOAD_TAXONOMY:"folio::loadTaxonomy",
    CONTENTTAG_LOADAll_TAXONOMY:"folio::loadAllFolioTaxonomy"
  
};

const initialState = {
    taxonomyLists:[],
    selected:"",
    id :"",
    name:"",
    taxonomyId:"",
    folioLists:[],
    taxonomyNonAccountList:[],
    allFolio:{},
    memberFirm:''
};

const reducer = (state, action) => {

    switch (action.type) {
      
        case  ACTION.FOLIOPICKER_LOAD:
            return { ...state, folioLists:action.payload.folioLists, selected:action.payload.selected, id:action.payload.id, name:action.payload.name, taxonomyId:action.payload.taxonomyId, memberFirm:action.payload.memberFirm};    
        case  ACTION.FOLIOPICKER_SELECT:
                return { ...state,folioLists:action.payload.folioLists,allFolio:action.payload.allFolio}; 
        case  ACTION.FOLIOPICKER_LOAD_TAXONOMY:
            return {...state,folioLists:action.payload.folioLists,memberFirm:action.payload.memberFirm} 
        case  ACTION.CONTENTTAG_LOADAll_TAXONOMY:
                return {...state,allFolio:action.payload.allFolio} 
              
        default:
            return state;
    }
};

export default function FolioPickerContextProvider({ children }) {
    const [state, folioDispatch] = useReducer(reducer, initialState);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    
    const store = {
        state,
        folioDispatch,
        isDropdownOpen,
        setIsDropdownOpen

    };

    return (
        <FolioPickerContext.Provider value={store}>
            {children}
        </FolioPickerContext.Provider>
    );
}

