import React, { useEffect, useState } from 'react';
import Dropdown from '../Common/Dropdown';
import ListItem from '../Common/Layout/DropdownMemberFirm/ListItem';
import List from '../Common/Layout/DropdownMemberFirm/List';

function ManagePanel({ searchMemberFirm, setSearchMemberFirm, memberFirms, handleAddMemberFirm, dropdownScrollAreaMaxHeight }) {
    const [filteredMemberFirms, setFilteredMemberFirms] = useState(memberFirms);

    useEffect(() => {
        const delayDebounceFnInputId = setTimeout(() => {
            let filteredMemberFirms = memberFirms.filter(memberFirm => searchMemberFirm === '' ? true : memberFirm.label.toLowerCase().includes(searchMemberFirm.toLowerCase()));
            filteredMemberFirms = [...new Set(filteredMemberFirms)];
            setFilteredMemberFirms(filteredMemberFirms);
        }, 300);
        return _ => clearTimeout(delayDebounceFnInputId);
    }, [searchMemberFirm, 300]);//eslint-disable-line

    useEffect(() => {
        setFilteredMemberFirms(memberFirms);
    }, [memberFirms])

    return (
        <Dropdown.ScrollArea maxHeight={dropdownScrollAreaMaxHeight || "350px"} additionalClassNames="mfdd__list-container">
            <List>
                <div
                    className="input-dropdown"
                    tabIndex="0"
                >
                    <input
                        type="text"
                        className="collection-dropdown-input"
                        autoCapitalize="none"
                        autoComplete="off"
                        autoCorrect="off"
                        spellCheck="false"
                        aria-autocomplete="list"
                        placeholder="Search Country"
                        tabIndex="-1"
                        value={searchMemberFirm}
                        onChange={(e) => setSearchMemberFirm(e.target.value)}
                    />
                </div>
                {filteredMemberFirms && filteredMemberFirms.length > 0 ? filteredMemberFirms.map((currentMemberFirm) => {
                    const disabled = false; //TODO:Get profile MF - what are we matching on here? - profileMemberFirms.indexOf(currentMemberFirm.reference.toLowerCase()) > -1;
                    return (
                        <ListItem
                            key={currentMemberFirm.id}
                            memberFirm={currentMemberFirm}
                            disabled={disabled}
                            onClick={handleAddMemberFirm}
                            clearSearchMemberFirm={() => { setSearchMemberFirm("") }}
                        />
                    );
                }) : <div class="mfdd__list-item no-options" tabindex="0">No option</div>
                }
            </List>
        </Dropdown.ScrollArea>
    )
}

export default ManagePanel;
