import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import cx from 'classnames';
import ShowIf from '../Common/ShowIf';
import { replace } from 'lodash'
import { useTocContext } from '../../contexts/TocContext';
import { usePageContext, usePageContextSettings } from '../../contexts/PageContext';
import TocSearchBadge from './TocSearchBadge';
import TocAnnotationBadge from './TocAnnotationBadge';
import LINKHELPERS from '../../helpers/LinkHelpers';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import { logs } from '../../helpers/log';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { PAGE_CONTROLLER_TYPE } from '../Constants/Constants';


export const IsCurrentPublicationOrTopic = (itemID = '', currentPathname = '') => {
    //If there is no GUID in the path, then we have not selected a TOC item
    if (!currentPathname.includes('/GUID-')) {
        return false;
    }
    const currentPathSplit = currentPathname.split('/GUID-');
    //If the current URL is only on a Publication, there will be only one GUID, or none
    if (currentPathSplit.length === 2) {
        return itemID.includes(currentPathSplit[1].split('/')[0]);
    }
    //We only care about the TopicGUID, if this is a spine pub, the topic GUID will still be unique and the same
    return itemID.includes(currentPathSplit[2]);
}

const TableOfContentsItem = (
    props
) => {

    const {
        item,
        shouldHighlightSelected,
        includeSubItems,
        sectionContainsPublications, //Default === true
        sectionFriendlyPath, //Folio only
        isListingPage, //Default === false
        parentGuid, //Starts off NULL
        isRelatedLinksPage
    } = props;

    // const location = useLocation();
    const { selectedItemTOC, setSelectedItemTOC, tocIconBookmark, tocAnnotations } = useTocContext();
    const { context } = usePageContext();

    // const { setGuidebookPublication } = useGuidebookTocContext();
    const [isExpanded, setIsExpanded] = useState(isListingPage || false) //item.isExpanded BE No longer sets this, so we cannot rely on it any more as DCS does not set this
    const [isSelected, setIsSelected] = useState()
    const isExpandable = (Array.isArray(item.subItems) && item.subItems.length) && (includeSubItems && item.isExpandable);
    const isGuidebook = context.pageControllerType === 'folio';
    const { isAnnotationsEnabled, isRestrictedAccessMemberfirm } = usePageContextSettings();
    const { searchResults } = useSearchResultContext();
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isAnnotationAvailable, setIsAnnotationAvailable] = useState(false);
    const itemURL = LINKHELPERS.sanitizeUrl(item.url);
    const clickProps = {
        eventType: "click",
        pageUrl: LINKHELPERS.sanitizeUrl(window.location.href),
        pageType: UTILITIESHELPER.getSessionStorage("PageType"),
        jobTitle: UTILITIESHELPER.getSessionStorage("Level"),
        memberFirm: UTILITIESHELPER.getSessionStorage("MemberFirm"),
        rpMemberFirm: UTILITIESHELPER.getLocalStorage("research-portal-member-firm"),
    };

    useEffect(() => {
        setIsSearchActive(!UTILITIESHELPER.isArrayNullorEmpty(searchResults));
    }, [searchResults]);

    useEffect(() => {
        setIsAnnotationAvailable(!UTILITIESHELPER.isArrayNullorEmpty(tocAnnotations));
    }, [tocAnnotations]);

    useEffect(() => {
        if (!!item
            && 'tocHash' in item
            && !!selectedItemTOC
            && 'item' in selectedItemTOC
            && !!selectedItemTOC.item
            && 'tocHash' in selectedItemTOC.item) {

            if (!isExpanded && selectedItemTOC.item.tocHash.includes(item.tocHash)) {
                setIsExpanded(true);
            }
            setIsSelected(item.tocHash === selectedItemTOC.item.tocHash);
        }
        else if (isSelected) {
            setIsSelected(false);
        }
        //eslint-disable-next-line
    }, [selectedItemTOC]); //item, 

    const getClassNameExpanded = (_isExpandable, _isExpanded, _isListingPage) => {
        if (!_isExpandable) return '';
        return (_isExpanded) ? 'toc-item-icon-expandable-open' : 'toc-item-icon-expandable-closed';
    }


    if ((sectionFriendlyPath === "alerts") && includeSubItems && item.subItems.length < 2) {
        var TableOfContentsItems = [];
        item.subItems.forEach((subItem, index) => {
            TableOfContentsItems.push(<TableOfContentsItem
                key={`TOC_${item.id}_${subItem.id}_${index}`}
                item={subItem}
                shouldHighlightSelected={shouldHighlightSelected}
                includeSubItems={true} //!isGuidebook --> we will ALWAYS be in a guildebook if we have a sectionFriendlyPath :S
                sectionContainsPublications={sectionContainsPublications}
                // isListingPage={isListingPage}
                isListingPage={isExpandable}
                parentGuid={item.id}
                parentNavTitle={item.title}
                isRelatedLinksPage={isRelatedLinksPage}
            />);
        })
        return TableOfContentsItems;
    }

    let titleSelected = isListingPage ? item.title : item.nav_title;

    const isBadged = () => {
        if (selectedItemTOC && selectedItemTOC?.item) {
            return (item.tocHash.includes(selectedItemTOC.item.id));
        }
        else return true;
    };

    function bookmark(items) {
        return tocIconBookmark?.filter(
            function (data) {
                return LINKHELPERS.getGUID(data.page_url) === LINKHELPERS.getGUID(decodeURI(items.url))
            }
        ).length > 0 ? true : false;
    }

    function logToAppInsights(tocItem) {
        if (tocItem !== "") {
            clickProps.selectedTocItem = tocItem;
            if (!UTILITIESHELPER.isStringNullorEmpty(context.pageCollectionName)) {
                clickProps.collectionName = decodeURI(context.pageCollectionName);
            }
            if (clickProps.pageType !== '') {
                logs.event('click', clickProps);
            }
        }
    }

    return (
        <li className={cx({ 'expand': isExpandable, 'open': isExpandable && (isExpanded || isListingPage) })}>
            <div className="d-flex toc-item-head">
                <button
                    className={cx('toc-item-icon', getClassNameExpanded(isExpandable, isExpanded, isListingPage),
                        { 'cursor-pointer': isExpandable },
                        {'bookmark-item-icon': context.pageControllerType===PAGE_CONTROLLER_TYPE.standard ?(isExpandable!==true && bookmark(item)): bookmark(item)},
                    )}
                    onClick={(ev) => {
                        if (isExpandable) {
                            setIsExpanded(prevVal => !prevVal);
                        }
                    }}
                >
                </button>
                {context.pageControllerType===PAGE_CONTROLLER_TYPE.standard && isExpandable===true && bookmark(item) &&
                    <button className={'toc-item-icon bookmark-item-icon expandable-bookmark-item-icon'}>
                    </button>
                }
                <Link
                    id={item.id}
                    to={itemURL}
                    data-parent-id={parentGuid}
                    className={cx("d-flex m-auto sidebar-toc-item-link", { 'selected-link': isSelected, "toc-link-non-expandable": !isExpandable })}
                    target={LINKHELPERS.TocUrlContainsText(itemURL, '/link/') ? '_blank' : ''}
                >
                    <div
                        className={cx("toc-item-text-container", { "toc-item-text-container-selected": isSelected && shouldHighlightSelected })}
                        onClick={() => {
                            if (!isSelected && !LINKHELPERS.TocUrlContainsText(itemURL, '/link/')) {
                                setSelectedItemTOC({ item: item });
                                UTILITIESHELPER.setSessionStorage('SelectedContent', item.nav_title);
                                logToAppInsights(item.nav_title)
                            }
                            if (isExpandable) {
                                setIsExpanded(prevVal => !prevVal);
                            }
                        }}
                    >
                        {item?.pre_title} {replace(titleSelected, /&amp;/g, '&')}
                    </div>
                </Link>
                <ShowIf condition={isAnnotationAvailable && isAnnotationsEnabled() && !isRestrictedAccessMemberfirm() && !isListingPage && !isSearchActive}>
                    <TocAnnotationBadge key={`badge_anno_${item.id}`} tocItem={item} />
                </ShowIf>
                <ShowIf condition={isBadged && isSearchActive}>
                    <TocSearchBadge key={`badge_p_${item.id}`} tocItem={item} />
                </ShowIf>
            </div>
            <ShowIf condition={includeSubItems}>
                <ul className={(isExpanded) ? "show" : "hide"}>
                    {item.subItems?.map((subItem, index) => {
                        return (
                            <TableOfContentsItem
                                key={`TOC_${item.id}_${subItem.id}_${index}`}
                                includeSubItems={!isGuidebook}
                                isListingPage={isListingPage}
                                item={subItem}
                                parentGuid={item.id}
                                parentNavTitle={item.title}
                                sectionContainsPublications={sectionContainsPublications}
                                shouldHighlightSelected={shouldHighlightSelected}
                            />
                        )
                    })}
                </ul>
            </ShowIf>
        </li>
    )
};

TableOfContentsItem.defaultProps = {
    expandTocSection: () => { },
    item: null,
    includeSubItems: true,
    isListingPage: false,
    sectionContainsPublications: true,
    sectionFriendlyPath: '',
    shouldHighlightSelected: true,
}

export default TableOfContentsItem;