import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';

import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import TOCHELPERS from '../../helpers/TocHelpers';
import SH from '../../helpers/StorageHelpers';
import HTMLContent from '../Common/HTMLContent';
import ShowIf from '../Common/ShowIf';

import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import { useTocContext } from '../../contexts/TocContext';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';


export const generateMiniCrumbtrailText = (context, toc, guidebookToc, selectedItemTOC) => {
    //const { getLabel } = usePageContextLabels();
   
    function generateLabel(text, isDynamicFolio = false) {
        //cleans up the label to be user friendly
        let generatedLabel="";
        if (text === undefined) {
            return "";
        }

        if (isDynamicFolio) {
            generatedLabel = CONTEXTHELPERS.generateFolioTitleFromFilename(text, false);
        }
        else {
            generatedLabel = text
                .replace(".cshtml", "")
                .replaceAll("_", " ")
                .replace(" - - ", " - ");
           // text = text;
        }

        return generatedLabel;
    }

    var crumbtrailArray = [];

    if (context.pageControllerType === "folio") {
        if (context.pageFileName) {
            const linkUrl = `/${context.memberFirm}/${context.language}/${context.knowledgeDomain}/${context.pageControllerType}/${context.pageFileName}`;
            const linkText = generateLabel(get(context, 'pageFileName', ''), true);

            crumbtrailArray.push({ linkUrl, linkText });
        }

        if (selectedItemTOC && !!get(selectedItemTOC, 'item.tocHash', false)) {
            const selectedTocHash = selectedItemTOC.item.tocHash;
            const [sectionKey, tocId] = selectedTocHash.split('_');
            let section = toc[sectionKey] ? toc[sectionKey] : toc.find(el => el.sectionFriendlyPath === sectionKey);

            // Filter out invalid tableOfContents content by checking if tableOfContents object has values or not
            section.tableOfContents = section.tableOfContents?.filter(tocContent => tocContent !== null);
            const currentToc = section.tableOfContents.find(x => x.id === tocId);

            if (currentToc?.subItems) {
                const branch = TOCHELPERS.getTocBranchFromHash(currentToc, selectedItemTOC.item.id).filter(x => x !== undefined);
                branch.forEach(segment => {
                    crumbtrailArray.push({
                        item: segment,
                        linkUrl: segment.url,
                        linkText: segment.nav_title
                    })
                })
            }

        }

        return crumbtrailArray.map(({ linkText }) => linkText).join(' | ');
    }
    else {
        if (toc?.subItems) {
            const branch = TOCHELPERS.getTocBranchFromHash(toc, context.pageSelectedContentItemGUID);
            branch.forEach(segment => {
                crumbtrailArray.push({
                    item: segment,
                    linkUrl: segment.url,
                    linkText: segment.nav_title
                })
            })
        }
        return crumbtrailArray[0].linkText + " | " + crumbtrailArray[crumbtrailArray.length - 1].linkText;
    }
}

const Crumbtrail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [crumbs, setCrumbs] = useState([]);
    const { toc, selectedItemTOC, setSelectedItemTOC, setIsLandingPageSelected } = useTocContext();
    const { context, sitesLabelsLoaded, isCatalogPage, memberFirm, region } = usePageContext();
    const { getLabel, getMFLabel } = usePageContextLabels();
    const { setSearchResults } = useSearchResultContext();
    const { getGuidebookSectionData, guidebookSections } = useGuidebookTocContext();
    const queryParams = new URLSearchParams(location?.search);
    const searchPhrase = queryParams?.get('searchPhrase');
    const compareVersionParam = queryParams?.get('compareVersion');
    
    const [cumbTopHeight,setCumbTopHeight] =useState(0);
    
    useEffect(()=>{
        var navHeight=0;
        let heightOfCrum=document.getElementsByClassName("scrollbar-horizontal-container")[0]?.clientHeight;
        if(!heightOfCrum)
        {
            heightOfCrum=0;
        }
        let previewHeight=document.getElementsByClassName("preview-warning")[0]?.clientHeight;
        if(!previewHeight)
        {
            previewHeight=0; 
        }
        navHeight=previewHeight+ heightOfCrum;
        setCumbTopHeight(navHeight);
    },[])

    //BDP: the shouldShowCrumbtrail should be done in here (as to weather it is shown or not)
    //if (!context.shouldShowCrumbtrail) {
    //    return null
    //}

    useEffect(() => {
        function generateLabel(text, isDynamicFolio = false) {
            //cleans up the label to be user friendly
            let generatedLabel="";
            if (UTILITIESHELPER.isStringNullorEmpty(text)) {
                return "";
            }
            //if text contains any HTML encoded character need to decodeURI
            let decodedtext = decodeURI(text);

            if (decodedtext.startsWith("lbl_")) {
                return getMFLabel(decodedtext, "CrumbTrail::generateLabel:text");
            }

            if (isDynamicFolio) {
                generatedLabel = CONTEXTHELPERS.generateFolioTitleFromFilename(decodedtext, false)
            }
            else {
                generatedLabel = decodedtext
                    .replace(".cshtml", "")
                    .replaceAll("_", " ")
                    .replace(" - - ", " - ")
               // text = text;
            }

            return generatedLabel;
        }

        function getSelectedSection() {
            if (guidebookSections.length === 0) return null;

            var currentSection = context.pageSelectedSection;

            var selectedSection;

            guidebookSections.forEach(guidebookSection => {
                if (guidebookSection.sectionFriendlyPath === currentSection) {
                    selectedSection = guidebookSection;
                }
            })

            return selectedSection;
        }

        function generateCrumbtrail() {
            var crumbtrailArray = [];

            if (context.knowledgeDomain && context.knowledgeDomain !== "content") {
                let link = {
                    linkUrl: `/${context.memberFirm}/${context.language}/${context.knowledgeDomain}`,
                    linkText: getLabel(`mf_${context.knowledgeDomain}Catalog`, "Catalog...")
                }
                //Well, this condition ...
                // arguments && link?.linkText == 'INTL Audit and Assurance') 
                //  thanks to the "content" change will NEVER be correct...
                //  thats why we never use "content" in comparision - as it could be translated!
                //context.knowledgeDomain == "assurance"
                if ((location.pathname.split('/').pop() === 'recentviewed')) {
                    link.linkText = getLabel("hh_MyDashboard", "MY DASHBOARD");
                }
                crumbtrailArray.push(link);
            }

            // If we do not have a current pageControllerType, return trail
            if (!context.pageControllerType) return crumbtrailArray;

            // Else generate rest of the bread crumb
            switch (context.pageControllerType) {
                case "collection":
                    const collectionTitle = () => {
                        //These should be coming from the collection/catalog object on the page!
                        //we do not have access to them yet

                        switch (context.pageFileName) {
                            case 'standards':
                            case 'manual':
                            case 'deloitte-communications':
                            case 'professional-news':
                            case 'roadmap':
                            case 'guidance':
                                return getLabel(`ct_Collection_${context.pageFileName}`, `[${context.pageFileName}] Collection`);

                            default:
                                return getLabel("ct_Collection", "Collection");
                        }
                    };

                    let link = {
                        linkUrl: `/${context.memberFirm}/${context.language}/${context.knowledgeDomain}/${context.pageControllerType}/${context.pageFileName}`,
                        linkText: collectionTitle()
                    }
                    // we dont want to show collection crumb when in dollection folio
                    //because folio collection is not designed only in case of collection search page
                    if (context.pageFileName !== 'folio')
                        crumbtrailArray.push(link);
                    break;

                case "folio":
                    //guidebook landing
                    if (context.pageFileName) {
                        const linkUrl = `/${context.memberFirm}/${context.language}/${context.knowledgeDomain}/${context.pageControllerType}/${context.pageFileName}`;
                        //BDP: Ultimately we do not care about the "test" folio title as thats not a "production" folio so we will just take the dynamic name regardless!
                        //   The previous mechanism broke because of the new labels, we cannot make work-arounds for "test" folios that may cause it to break the real folio!
                        const linkText = generateLabel(get(context, 'pageFileName', ''), true);

                        crumbtrailArray.push({ linkUrl, linkText });
                    }

                    if (context.pageSelectedSection) {
                        var section = {};
                        const selectedTocHash = selectedItemTOC?.item?.tocHash;
                        if (selectedTocHash) {
                            const [sectionKey ] = selectedTocHash.split('_');
                            section = getGuidebookSectionData(sectionKey);
                            if (section) {
                                // Filter out invalid tableOfContents content by checking if tableOfContents object has values or not
                                section.tableOfContents = section.tableOfContents?.filter(tocContent => tocContent !== null);
                            }
                        } else if (selectedItemTOC?.item) {
                            section = selectedItemTOC.item
                        }
                        let link = {
                            item: section,
                            linkUrl: `/${context.memberFirm}/${context.language}/${context.knowledgeDomain}/${context.pageControllerType}/${context.pageFileName}/${context.pageSelectedSection}`,
                            linkText: generateLabel(get(getSelectedSection(), 'title.Content.title', ''))
                        }
                        crumbtrailArray.push(link);
                    }

                    if (selectedItemTOC && !!get(selectedItemTOC, 'item.tocHash', false)) {
                        const selectedTocHash = selectedItemTOC.item.tocHash;
                        const [sectionKey, tocId] = selectedTocHash.split('_');
                        const section = getGuidebookSectionData(sectionKey);
                        if (section) {
                            // Filter out invalid tableOfContents content by checking if tableOfContents object has values or not
                            section.tableOfContents = section.tableOfContents?.filter(tocContent => tocContent !== null);
                            const currentToc = section.tableOfContents.find(x => x.id === tocId);

                            if (currentToc?.subItems) {
                                const branch = TOCHELPERS.getTocBranchFromHash(currentToc, selectedItemTOC.item.id).filter(x => x !== undefined);
                                branch.forEach(segment => {
                                    crumbtrailArray.push({
                                        item: segment,
                                        linkUrl: segment.url,
                                        linkText: segment.nav_title
                                    })
                                })
                            }
                        }
                    }
                    break;

                case "dashboard":
                    crumbtrailArray.push({
                        linkUrl: `/${context.memberFirm}/${context.language}/${context.knowledgeDomain}/${context.pageControllerType}`,
                        linkText: getLabel(`usp_${context.pageType}`, "Dashboard")
                    });
                    break;

                default:
                    if (context.knowledgeDomain === "content") {
                        //In the labels, we need to have these e.g. c_ArticleContentFileName_support (where support is the file name in sites)
                        const linkText = getLabel(`c_ArticleContentFileName_${context.pageControllerType}`, context.pageControllerType);
                        const linkUrl = "";
                        crumbtrailArray.push({ linkUrl, linkText });
                    }
                    else if (toc?.subItems) {
                        const branch = TOCHELPERS.getTocBranchFromHash(toc, context.pageSelectedContentItemGUID);
                        branch.forEach(segment => {
                            crumbtrailArray.push({
                                item: segment,
                                linkUrl: segment.url,
                                linkText: segment.nav_title
                            })
                        })
                    }
            }

            //Add "Search Results" to the crumbtrail if exists, this means we are on a search result page.
            if (searchPhrase) {
                let link = {
                    linkUrl: "search",
                    linkText: getLabel("s_SearchResults", "Search Results")
                }
                crumbtrailArray.push(link);
            }
            //Add "Compare Versions" to the crumbtrail if exists, this means we are comparing versions
            if (compareVersionParam) {
                let link = {
                    linkUrl: "compare",
                    linkText: getLabel("compare_ViewAndCompareVersions", "View and Compare")
                }
                crumbtrailArray.push(link);
            }

            return crumbtrailArray;
        }
               
        const newCrumbs = generateCrumbtrail();
        if(JSON.stringify(crumbs) !== JSON.stringify(newCrumbs)) {
            setCrumbs(newCrumbs);
        }
        //eslint-disable-next-line
    }, [context, sitesLabelsLoaded, toc,location?.search, region])
    //Context is the first thing that changes all the time!
    //sitesLabelsLoaded as we always NEED them to have been loaded, so may need to re-draw once they have loaded!
    //toc as when landing on a manual/folio, we need to re-draw once its loaded
    //Previous ones (not needed any more): (These get caught when context changes!)
    //  guidebookToc, guidebookSections, selectedItemTOC, guidebookPublication, guidebookArticle, searchPhrase, sitesLabels

    const renderCrumbs = (trail) => {
        if (trail.length === 0) return null;
        const linked = trail.slice(0, -1);
        const active = trail[trail.length - 1];

        const renderedCrumbs = linked.map((c, i) => {
            const onClick = (e) => {
                //clear search on change
                SH.removeSessionStorage('searchPhraseParam');
                setSearchResults([]);

                if (!!c && 'item' in c) {
                    e.preventDefault();
                    if(!UTILITIESHELPER.isObjectNullorEmpty(c.item)) {
                        setSelectedItemTOC({ item: c.item });
                    }
                    setIsLandingPageSelected(false);
                    navigate(c.linkUrl);
                }
                else {
                    //below check to confirm when publication Landing Page
                    if (c.linkUrl?.split('/').length === 6) {
                        setIsLandingPageSelected(true);//this useState variable only used in case of folio
                    }
                    else {
                        setIsLandingPageSelected(false);
                    }
                    setSelectedItemTOC({});
                }
            }
            return (
                <Link
                    key={c.linkUrl + i.toString()}
                    to={c.linkUrl}
                    onClick={onClick}
                    className="breadcrumb"
                >
                    {c.linkText}
                </Link>
            )
        })

        const activeCrumb = (
            <span
                key={active.linkUrl + 'active'}
                className="breadcrumb breadcrumb-active"
            >
                {active.linkText}
            </span>
        )

        renderedCrumbs.push(activeCrumb);

        return renderedCrumbs;
    }

    return (
        <div className="crumbtrail" style={{top:cumbTopHeight}}>
            <Link to={`/${context.memberFirm}/${context.language}`} onClick={() => setSelectedItemTOC({})} className="breadcrumb-home">{getLabel("g_ResearchPortalCrumbtrailHome", "Research Portal Home")}</Link>
            <div className="crumbtrail-main">{renderCrumbs(crumbs)}
            <ShowIf condition={memberFirm.toLowerCase()!=="dtt" && context.knowledgeDomain === 'accounting' && isCatalogPage}>
            <span className='crumbtrail-accounting' data-testid='global-content-text'>For Global Accounting content, go to <HTMLContent>{getLabel("l_LinkToDart")}</HTMLContent> or <HTMLContent>{getLabel("l_LinkToTL")}</HTMLContent> </span>
            </ShowIf>
            </div>
        </div>
    )
}


export default Crumbtrail;