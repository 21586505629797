import React from "react";
import { RESEARCH_ASSISTANT, RESEARCH_ASSISTANT_FILTERS } from "../Constants/Constants";
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import { usePageContextLabels } from '../../contexts/PageContext';
import ShowIf from "../Common/ShowIf";
import { MEMBERFIRM_CONSTANTS } from "../Constants/Constants";

const FilterValue = ({ researchAssistantResponse }) => {
    const { getLabel} = usePageContextLabels();
    const getMemberFirm = () => {
        return CONTEXTHELPERS.getMemberFirmWithTaxonomyId(researchAssistantResponse?.inputFields?.userLibrariesSelected[0]);
    }
    const getFilterValuesString = (filterType) => {
        let filterValueString = "";
        if (researchAssistantResponse && researchAssistantResponse.inputFields && researchAssistantResponse.inputFields.userFilterSelected) {
            researchAssistantResponse.inputFields.userFilterSelected.forEach((item) => {
                if (filterType === RESEARCH_ASSISTANT_FILTERS.KnowledgeDomain && item?.filterField === RESEARCH_ASSISTANT_FILTERS.KnowledgeDomain) {
                    filterValueString = CONTEXTHELPERS.getknowledgeDomain(item?.filterID[0]);
                }
                if (filterType === RESEARCH_ASSISTANT_FILTERS.Frameworks && item?.filterField === RESEARCH_ASSISTANT_FILTERS.Frameworks) {
                    filterValueString = item?.filterID?.length && item?.filterID?.length > 0 ? getTierValues(item?.filterID) : "";
                }
            });
        }
        return filterValueString;
    }

    const getTierValues = (tierValues) => {
        const tierData = researchAssistantResponse.tier;
        let tierString = "";
        if(tierData && tierData?.length > 0){
            if (tierData.length === 1) {
                tierString = ", " + tierData[0];
            } else {
                const firsts = tierData?.slice(0, tierData.length - 1);
                const last = tierData[tierData.length - 1];
                tierString = ", " + firsts.join(', ') + ' and ' + last;
            }
        }
        return tierString;
    }
    return (
        <>
        <label data-testid="filterValue">{getLabel('raContentUsedToGenerate','Content used to generate this answer:')} {getLabel(`mf_${getFilterValuesString(RESEARCH_ASSISTANT_FILTERS.KnowledgeDomain)}Catalog_${getMemberFirm()}`)}{getFilterValuesString(RESEARCH_ASSISTANT_FILTERS.Frameworks)}</label>
        <ShowIf condition={researchAssistantResponse?.memberFirm === MEMBERFIRM_CONSTANTS.dtt}>
            <div className="conditional-sites-string">{getLabel("ra_ConditionalStringINT",RESEARCH_ASSISTANT.conditionalStringINT)}</div>
        </ShowIf>
        </>
    )
}

export default FilterValue;