
import React,{ useEffect, useState } from 'react';
import { useUserPreferencesContext,ACTIONS as ANN_ACTIONS } from '../../contexts/AnnotationsContext';
import getTextWithSearchHighlight from '../../helpers/getTextWithSearchHighlight';
import HTMLContent from '../Common/HTMLContent';
import { useNavigate} from 'react-router-dom';
import LINKHELPERS from '../../helpers/LinkHelpers';


const AnnotationItem = ({
    category,
    item,
    modifyDeleteGuids,
    searchText,
    isChecked
}) => {
    const { setViewedAnnotation, setSelectedAnnotation, state: annState,dispatch } = useUserPreferencesContext();
    const [annotItem, setAnnotItem] = useState(item);
    const navigate = useNavigate();

    useEffect(() => {
        setAnnotItem(prev => {
            return {
                ...prev,
                checked: isChecked
            }
        });
    }, [item]);//eslint-disable-line

	function redirectToArticle() {
        const articleGuid = LINKHELPERS.getGUID(annotItem.page_url);
        const articleAnnotations = annState?.annotations?.filter(a => a.reference_identifier === articleGuid);
		dispatch({
			type: ANN_ACTIONS.ANNOTATIONS_LOAD,
            payload: articleAnnotations ? articleAnnotations : [],
		});
		setSelectedAnnotation(annotItem);
        setViewedAnnotation(articleGuid);
		navigate(annotItem.page_url);
	}

    function handleChange() {
        setAnnotItem(prev => {
            modifyDeleteGuids(annotItem.annotation_guid, !prev.checked)
            return {
                ...prev,
                checked: !prev.checked
            }
        });
    }

    function getTruncatedFormattedText(content) {
        var truncatedContent = content != null ? content.length > 100 ? content.substr(0, 100) + '...' : content : "";
        return truncatedContent
    }

    return (
        <>
            <div className='d-flex'>
                <div className='d-flex annot-item-container' onClick={redirectToArticle}>
                    <div className='annot-color' style={{ backgroundColor: category.color }} />
                    <div className='annot-item-inner-container'>
                        <div className='annot-item-inner-container-adj' >

                            <div className=''>
                                {annotItem?.is_annotations_version_updated === true &&
                                    <div className='d-flex'>
                                        <div className='an_annot-deleted'></div>
                                        <div className='annot-text'>Highlighted content is no longer available</div>
                                    </div>
                                }
                                {annotItem?.is_annotations_version_updated !== true &&
                                    <div className='annot-text'>
                                        <HTMLContent>
                                            {getTextWithSearchHighlight(getTruncatedFormattedText(annotItem.content), searchText)}
                                        </HTMLContent>
                                    </div>
                                }


                            </div>
                            <div className='annot-breadcrumb'>
                                <HTMLContent>
                                    {getTextWithSearchHighlight(annotItem.page_bread_crumb, searchText)}
                                </HTMLContent>
                            </div>

                            {(annotItem.comment !== '' && annotItem.comment !== undefined) &&
                                <div className='annot-comment-container'>
                                    <div className='annot-comment' >
                                        <HTMLContent>{getTextWithSearchHighlight(annotItem.comment, searchText)}</HTMLContent>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className='annot-delete-checkbox-container'>
                    <input className='annot-delete-checkbox' onChange={handleChange} defaultChecked={isChecked} id={annotItem.annotation_guid} type='checkbox'></input>
                </div>
            </div>

        </>
    )
}

export default AnnotationItem;
