import React from "react";
import { usePageContextLabels } from "../../contexts/PageContext";
import { RESEARCH_ASSISTANT } from "../Constants/Constants";

const ChatbotIntroduction = () => {
    const { getLabel } = usePageContextLabels();
    return (
        <div className="convo-box conversation-ra-response">
            <div className="ra-response">
                <div className="search-assistant">
                    <div className='search-assistant-ico'>
                        <button className="search-assistant-ico-img">&nbsp;</button>
                    </div>
                    <div className='search-assistant-body'>
                        <label className='search-assistant-title' data-testid='research-assistant-title'>{getLabel('ra_ResearchAssistantTitle', 'Research Assistant')}</label>
                        <div className='search-assistant-response-body'>
                            <p className="intro-content" data-testid="intro-content">{getLabel('ra_Introduction', RESEARCH_ASSISTANT.raIntroduction)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ChatbotIntroduction