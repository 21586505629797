import React from "react";
import { usePageContextLabels } from '../../contexts/PageContext';
import HTMLContent from '../Common/HTMLContent';

export const Copyright = (props) => {
    const { getLabel } = usePageContextLabels();
    const defaultCopyright = getLabel("g_Copyright", `Copyright © ${new Date().getFullYear()} Deloitte Development LLC. All Rights Reserved`)
    const htmlText = (props.text === "" || props.text === undefined) ? defaultCopyright : props.text;

  return (
    <div className="copyright">
      <div><HTMLContent>{htmlText}</HTMLContent></div>
    </div>
  );
};
export default Copyright;
