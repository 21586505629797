import {QueryClient} from "react-query";

const STALE_TIME = 10 * (60 * 1000); // 10 mins
const CACHE_TIME = 15 * (60 * 1000); // 15 mins

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount:false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            staleTime: STALE_TIME,
            cacheTime: CACHE_TIME
        }
    }
});
