import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { usePageContextLabels, usePageContext } from "../../contexts/PageContext";
import RETRIEVALSERVICES from "../../services/rp-service";
import cx from "classnames";
import { get } from "lodash";
import EmbeddedArticle from "../Publication/EmbeddedArticle";
import RenderAllContainer from "../Article/RenderAllContainer";
import LINKHELPERS from "../../helpers/LinkHelpers";
import RedirectModal from "../Article/RedirectModal";
import { logs, page } from '../../helpers/log';


export const getIsArticlePdf = (_pubData) => {
  return (
    get(_pubData, "content[0].content.format")?.toLowerCase() === ".pdf" ||
    get(_pubData, "content[0].subItems[0].content.format")?.toLowerCase() ===
      ".pdf"
  );
};

function SearchTemplateModal({ closeModal, pageUrl, isScrollDisabled = false, searchParam }) {
    const [shouldShowDarkOverlay] = useState(true);
    const [memberFirm, language, knowledgeDomain, pageFileName] = pageUrl.substring(1).split("/");
    const { getLabel } = usePageContextLabels();
    const [embeddedPubData, setEmbeddedPubData] = useState({});
    const [publicationData, setPublicationData] = useState(null);
    const [isWideMode] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isRedirectModalVisible, setIsRedirectModalVisible] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');
    const [shouldShowOverlayRedirect, setShouldShowOverlayRedirect] = useState(false);
    const [messageRedirectTL, setMessageRedirectTL] = useState('');
    const navigate = useNavigate();
    const isArticlePdf = getIsArticlePdf(publicationData);
    const isEmbeddedMode = false;
    const { memberFirms } = usePageContext();
    const renderEmbeddedOrNormal = () => {
        if (isLoading) {
            return (
                <div className="search-modal-container cf-right shimmerArticleCard shimmerArticleWrapper" data-testid="loading-spinner">
                    <h2 className="article-title shimmerArticleCardBG shimmerArticleCardTitleLine">
                        <span className="ph"></span>
                    </h2>
                    <div className="article-content-container">
                        <div className="article">
                            <div className="body">
                                <div className="shimmerArticleCardBG shimmerArticleCardIntroLine"></div>
                                <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                                <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                                <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                                <div className="shimmerArticleCardBG shimmerArticleCardContentLine shimmerArticleCardContentLineEnd"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else if (isEmbeddedMode) {
            return <EmbeddedArticle data={embeddedPubData} />;
        }
        else {
            return articleContent;
        }
    };

    const handleDocumentClick = useCallback(async (ev) => {
        if (ev.target.nodeName === "path" && ev.target.id !== '') {
            ev.preventDefault();
        }
        else {
            let targetBaseUrl = `/${memberFirm}/${language}/${knowledgeDomain}`;
            const tocUrls = LINKHELPERS.getTocUrls(targetBaseUrl);

            (async () => {
                let navigatableLink = await LINKHELPERS.getNavigatableLink({
                    ev,
                    tocUrls,
                    memberFirm: memberFirm, language: language, knowledgeDomain: knowledgeDomain,
                    messageCannotOpenTab: getLabel("l_InternalLinkCannotBeOpenedInANewTab", "Internal link cannot be opened in a new tab/window in Pilot"),
                    messageCannotResolve: getLabel("l_LinkCannotBeResolved", "Link cannot be resolved."),
                    isSearchResultLink: false,
                    popupContent: true,
                    memberFirms
                });
                if (navigatableLink) {
                    if (LINKHELPERS.isLocalToThisPageLink(tocUrls, navigatableLink)) {
                        window.open(LINKHELPERS.sanitizeUrl(navigatableLink), '_blank', 'noreferrer');
                    }
                    else {
                        if (navigatableLink.split(";").length > 1) {
                            // TL link : Set Redirect Modal visible and send parameters from here
                            setMessageRedirectTL(getLabel("l_LinkModalViewLinkTL", "The content requested is not currently available in ORP. Would you like to see this content in Technical Library?"))
                            setRedirectURL(navigatableLink.split(";")[0]);
                            setShouldShowOverlayRedirect(true);
                            setIsRedirectModalVisible(true);
                        }
                        else {
                            window.open(LINKHELPERS.sanitizeUrl(navigatableLink), '_blank', 'noreferrer');
                        }
                    }
                }
            })();
        }
    }, []);//eslint-disable-line

    const renderArticleContent = (pubData) => (
        <RenderAllContainer pubData={pubData} excludeBinaries={false} isScrollDisabled={isScrollDisabled} />
    );

    const articleContent = renderArticleContent(publicationData);




    const retrievePublication = useCallback(async (pageUrl, isCancelled) => {
        try {
            setEmbeddedPubData({});
            setIsLoading(true);
            let res = await RETRIEVALSERVICES.retrieveGuidebookPublication(pageUrl);
            if (!isCancelled) {
                if (res) {
                    // Filter out invalid content by checking if content object has an object_type.
                    res.content.forEach((content) => {
                        content.subItems = content.subItems.filter(({ content }) => {
                            return content !== null && content.object_type !== null;
                        });
                    });

                    if (pageUrl.includes("?wormhole=true")) {
                        closeModal();
                        navigate(
                            `/${memberFirm}/${language}/${knowledgeDomain}/collection/folio?${searchParam}&wormhole=true`
                        );
                    } else {
                        setPublicationData(res);
                        setIsLoading(false);
                    }
                } else {
                    //we assume here that the article was not available for this MF, so we WH UP to current publication in the folio (will WH from there if that does not exist)
                    closeModal();
                    navigate(
                        `/${memberFirm}/${language}/${knowledgeDomain}/collection/folio?${searchParam}&wormhole=true`
                    );
                }

                let embeddedRes = await RETRIEVALSERVICES.retrieveEmbedded();
                if (embeddedRes) {
                    setEmbeddedPubData(embeddedRes);
                }
            }
        } catch (err) {
            //we assume here that the article was not available for this MF, so we WH UP to current publication in the folio (will WH from there if that does not exist)
            logs.info(page.Catalog, 'SearchTemplateModal', `retrievePublication::Catalog:Article was not available for this MF. pageURL: ${pageUrl}`);
            closeModal();
            navigate(
                `/${memberFirm}/${language}/${knowledgeDomain}/collection/folio?${searchParam}&wormhole=true`
            );
        }
    }, []);//eslint-disable-line

    useEffect(() => {
        let isCancelled = false;
        retrievePublication(pageUrl, isCancelled);
        const articlePopupContainerElem = document.querySelector(".search-article-container");
        articlePopupContainerElem.addEventListener("click", handleDocumentClick);
        return () => {
            isCancelled = true;
        };
    }, [retrievePublication, pageUrl, handleDocumentClick]);
    const searchTemplateModalStyle = {
        paddingBottom: isArticlePdf ? 0 : 12.5,
        paddingRight: isWideMode ? 70 : "",
    }

    return (
        <div>
            {shouldShowDarkOverlay && (
                <div className="full-screen-darken-overlay-fixed" />
            )}
            {
                <div className="annot-modal search-modal">
                    <div className="an-modal-header">
                        <div className="an-modal-title">
                            {getLabel("b_BinaryDownload", "Download")}
                        </div>
                        <button className="an-modal-close" onClick={closeModal} />
                    </div>
                    <div className="search-modal-body">
                        <div data-testid="document"
                            className={cx(
                                "article-container cf-right search-article-container",
                                {
                                    "article-container-wide-mode": isWideMode,
                                    "article-container-wide-annotation-on": isWideMode,
                                }
                            )}
                        >
                            <div
                                className={cx("d-flex article-container-inner", {
                                    "article-container-inner-for-pdf": isArticlePdf,
                                    "m-auto wide-margin": isWideMode,
                                })}
                            >
                                <div className="search-template-modal"
                                    style={searchTemplateModalStyle}>
                                    <div>
                                        <div>
                                            {renderEmbeddedOrNormal()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <RedirectModal
                                setShouldShowRedirectModal={setIsRedirectModalVisible}
                                shouldShowRedirectModal={isRedirectModalVisible}
                                redirectURL={redirectURL}
                                setShouldShowOverlay={setShouldShowOverlayRedirect}
                                shouldShowOverlay={shouldShowOverlayRedirect}
                                messageRedirectTL={messageRedirectTL}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default SearchTemplateModal;
