import React, { useState } from 'react';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { useParams } from 'react-router-dom';
import ShowIf from '../Common/ShowIf';
import HTMLContent from '../Common/HTMLContent';
export const ContentItem = (props) => {
    const {pageControllerType} = useParams();
    const [markup,setmarkup] = useState(props.subItem?.content?.text ? props.subItem?.content?.text : '' );//eslint-disable-line
    
    function createTitle() {

        if (props.subItem !== null && props.subItem.content !== null && props.subItem.content.title !== null) {
            var title =UTILITIESHELPER.decodeSpecialCharacters(props.subItem.content.title);
            const itemReferenceID = (props.subItem.content.reference_id) ? props.subItem.content.reference_id : "";
            return itemReferenceID + " " + title;
        }

        return null;
    }

    const isPdf = props.subItem.content.format === ".pdf";

    return (
        <>
             <ShowIf condition={!isPdf && (pageControllerType !== "folio" && pageControllerType !== "manual")}>
                <h2>{createTitle()}</h2>
            </ShowIf>
            {/* <ShowIf condition={get(markup, '__html')}> */}
                <div><HTMLContent>{markup}</HTMLContent></div>
        </>
    );
}

ContentItem.propsTypes = {
    typeName: "ContentItem"
}

export default ContentItem;