import React from 'react';
import ShowIf from '../Common/ShowIf';
import { usePageContext } from '../../contexts/PageContext';
import { useEffect } from 'react';
import { appInsightUIMapping, logs, page } from '../../helpers/log';
import {  ErrorTypes } from '../Constants/Errors';


export const MessageNotification = () => {
    const { notification, setNotification, context } = usePageContext();
    function closeContentErrorMessage() {
        setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null, error: null });
    }

    useEffect(() => {
        if (notification && notification.display && notification.appInsightUIMapping && notification.componentName) {
            switch (notification.appInsightUIMapping) {
                case appInsightUIMapping.Error:
                    if (notification.error)
                        logs.error(notification.page,notification.componentName, notification.componentName, notification.message, notification.error, { memberFirm: context.memberFirm, knowledgeDomain: context.knowledgeDomain,eventId:notification.eventId });
                    break;
                case appInsightUIMapping.Information:
                    logs.info(page.Search, notification.componentName, notification.message);
                    break;
                case appInsightUIMapping.Warning:
                    logs.warning(page.Search, notification.componentName, notification.message);
                    break;
                default://Do nothing
                    break;
            }
        }
    }, [notification.display]); //eslint-disable-line

    if (notification) {
        return (
            <ShowIf condition={notification.display}>
                <div className={"message-notification-" + notification.type}>
                    <span className="alert">&nbsp;</span>
                    <p>{notification.message}</p>
                    <button className="close" onClick={closeContentErrorMessage}>&nbsp;</button>
                </div>
            </ShowIf>
        )
    }
    return null;
}

export default MessageNotification;