import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { usePageContext, usePageContextLabels, usePageContextSettings } from "../../contexts/PageContext";
import Loading from '../Loading/Loading';
import LINKHELPERS from '../../helpers/LinkHelpers';
import RedirectModal from '../Article/RedirectModal';
import { logs,page, } from '../../helpers/log';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import SearchHelpers from '../../helpers/SearchHelpers';
import { ErrorCodes, ErrorMessages } from '../Constants/Errors';

export const LinkHandler = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { context, memberFirm, language, memberFirms } = usePageContext();
    const { getLabel } = usePageContextLabels();

    const [isRedirectModalVisible, setIsRedirectModalVisible] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');
    const [shouldShowOverlayRedirect, setShouldShowOverlayRedirect] = useState(false);
    const [messageRedirectTL, setMessageRedirectTL] = useState('');

    let tlMemberFirmTaxonomyId = !UTILITIESHELPER.isStringNullorEmpty(context?.urlMemberFirm) ? context.urlMemberFirm : null;

    let targetIdentifier = (context.pageFileName !== null && context.pageFileName !== undefined) ? context.pageFileName : "";

    if (!UTILITIESHELPER.isNullOrUndefined(context.pageHashTag)) {
        targetIdentifier += `#${context.pageHashTag}`;
    }

    if (!UTILITIESHELPER.isNullOrUndefined(context.searchPhrase)) {
        targetIdentifier += `?${SearchHelpers.toQueryString(SearchHelpers.parse(context.searchPhrase))}`;
    }    

    useEffect(() => {
        const resolveLink = async () => {
            try {
                const navigatableLink = await LINKHELPERS.resolveLink(
                    targetIdentifier, null, null,
                    memberFirm, language, null,
                    getLabel("l_LinkCannotBeResolved", "Link cannot be resolved."),
                    false,
                    memberFirms,
                    false,
                    tlMemberFirmTaxonomyId
                );

                if (navigatableLink) {
                    if (navigatableLink.indexOf(";ExtrenalTL") > 1) {
                        // TL link : Set Redirect Modal visible and send parameters from here
                        setMessageRedirectTL(getLabel("l_LinkModalViewLinkTL", "The content requested is not currently available in ORP. Would you like to see this content in Technical Library?"));
                        setShouldShowOverlayRedirect(true);
                        setIsRedirectModalVisible(true);
                        setRedirectURL(navigatableLink.split(";")[0]);
                        return;
                    }
                    else {
                        navigate(encodeURI(navigatableLink));
                        logs.debug(page.Link, 'LinkResolver', 'The resolved link target is: ' + location.pathname, { memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'), knowledgeDomain: context.knowledgeDomain })
                        return;
                    }
                }
            }
            catch (err) {
                logs.error(page.Link, 'LinkResolver', ErrorMessages.linkResolver + ' ' + targetIdentifier, err, { memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'), knowledgeDomain: context.knowledgeDomain, eventId: ErrorCodes.Link })
            }

            logs.debug(page.Link, 'LinkResolver', 'Unable to resolve target ' + targetIdentifier, { memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'), knowledgeDomain: context.knowledgeDomain })

            navigate("", 500);
        }

        if (context?.knowledgeDomain?.length || memberFirms?.length) {
            resolveLink();
        }

    }, [context.knowledgeDomain, memberFirms]); //eslint-disable-line

    return (
        <>
            <Loading />
            <RedirectModal
                setShouldShowRedirectModal={setIsRedirectModalVisible}
                shouldShowRedirectModal={isRedirectModalVisible}
                redirectURL={redirectURL}
                setShouldShowOverlay={setShouldShowOverlayRedirect}
                shouldShowOverlay={shouldShowOverlayRedirect}
                messageRedirectTL={messageRedirectTL}
            />
        </>
    );
}

export default LinkHandler;