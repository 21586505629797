import React, { useState } from 'react';
import ShowIf from '../Common/ShowIf';
import HTMLContent from '../Common/HTMLContent';

export const EmbeddedArticleBlockSubsection = (props) => {
    const [subsectionOpen, toggleSubsection] = useState(false);
    const isQnA = props.isQnA;
    const isObjective = props.isObjective;
    const subItem = props.subItem;
    const innerSubItem = props.innerSubItem;

    return (
        <div className={subsectionOpen ? "expandable-article-subsection open" : "expandable-article-subsection"}>

            <button onClick={() => toggleSubsection((prevVal) => !prevVal)}><h2>{isObjective ? subItem.content.title : innerSubItem.content.title}</h2></button>

            <div className="expandable-article-subsection-body open">
                <ShowIf condition={isQnA}>
                    <p className="title">{innerSubItem.content.question?.title} </p>
                    <span><HTMLContent>{innerSubItem.content.question?.text}</HTMLContent></span>
                    {innerSubItem.content.answer?.map((answer) => {
                        return (
                            <>
                                <p className="title">{answer.title}</p>
                                <span><HTMLContent>{answer.text}</HTMLContent></span>
                            </>
                        )
                    })}
                </ShowIf>

                <ShowIf condition={isObjective}>
                    <p className="title">{innerSubItem.content.objective?.title}</p>
                    <span><HTMLContent>{innerSubItem.content.objective?.text}</HTMLContent></span>

                    <p className="title">{innerSubItem.content.why?.title}</p>
                    <span><HTMLContent>{innerSubItem.content.why?.text}</HTMLContent></span>

                </ShowIf>

                <span><HTMLContent>{innerSubItem.content.text}</HTMLContent></span>
            </div>
        </div>
    )


}

export default EmbeddedArticleBlockSubsection;
