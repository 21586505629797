import CatalogBox from "./CatalogBox";
import React from 'react';


const CatalogCards = ({catalogs, isLoading}) => {
    const renderCatalogBox = (currentCollection, currentPosition) => {
        if (isLoading) {
            return null
        }

        return (
            <CatalogBox
                key={`cb_${currentPosition}`}
                collection={currentCollection}
                currentPosition={currentPosition}
            />
        )
    }

    return (
        <div className='catalog-main' data-testid='catalog-main'>
            <div className="col">
                {catalogs.map((collection, i) => {
                    if (collection.column == null) {
                        if (i % 2 === 0) {
                            return renderCatalogBox(collection, 10 + i)
                        }
                    } else if (collection.column === 1) {
                        return renderCatalogBox(collection, 10 + i)
                    }
                    return null;
                })}
            </div>
            <div className="col">
                {catalogs.map((collection, i) => {
                    if (collection.column == null) {
                        if (i % 2 !== 0) {
                            return renderCatalogBox(collection, 20 + i)
                        }
                    } else if (collection.column === 2) {
                        return renderCatalogBox(collection, 20 + i)
                    }
                    return null;
                })}
            </div>
        </div>
    )
}

export default CatalogCards;
