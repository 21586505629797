import React, { useEffect, useRef, useState } from 'react';
import { useArticleContext } from '../../contexts/ArticleContext';
import {  usePageContextLabels,usePageContext } from '../../contexts/PageContext';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import { useTocContext } from '../../contexts/TocContext';
import Searchbar from "../Common/Searchbar"


const PublicationListingSearch = ({ isFixed, isExpanded, style, className }) => {
    const inputRef = useRef();
    const [isActive, setIsActive] = useState(isExpanded); //start off with search closed
    const { searchText, setSearchText } = useArticleContext(); //For setting the search text
    const { getLabel,getMFLabel } = usePageContextLabels();
    const { setSearchResults } = useSearchResultContext();
    const { selectedItemTOC } = useTocContext();
    const searchLabel = getLabel("s_Search", "Search");
    const { setSearchPhrase,  } = usePageContext();

    useEffect(() => {
        setSearchPhrase(searchText);
    }, [searchText]);

    
    useEffect(() => {
        setIsActive(isExpanded);
    }, [isExpanded]);

    useEffect(() => {
        if (isActive && inputRef.current) inputRef.current.focus();
        if (!isActive) {
            setSearchText("");
        }
    }, [isActive])//eslint-disable-line


    function setValue(value) {
        if (value !== searchText) {
            setSearchResults([]);
            setSearchText(value);
        }
    }

    function handleClose() {
        setSearchResults([]);
        setIsActive(false);
        sessionStorage.removeItem('searchPhraseParam');
    }

    function getPlaceholder() {
        if (selectedItemTOC && selectedItemTOC.hasOwnProperty('sectionFriendlyPath')) {
            return `${searchLabel} ${selectedItemTOC.title}`;
        } else {
            if(selectedItemTOC && selectedItemTOC.item && selectedItemTOC.item.hasOwnProperty('sectionFriendlyPath')){
                return `${searchLabel} ${getMFLabel(selectedItemTOC.item.title?.Content?.title, "selectedItemTOC.item.title?.Content?.title")}`;
            }
            return `${searchLabel} ${selectedItemTOC?.item?.nav_title}`;
        }
    }

    return (
        <Searchbar
        placeholder={getPlaceholder()}
        value={searchText || '' }
        style={style}
        className="searchbar-publication-landing"
        setValue={(setValue)}
        terms={[]}
        isExpanded={isExpanded}
        isFixed={isFixed}
        onClose={handleClose}
        showSearchLink
    />
    )

}

PublicationListingSearch.defaultProps = {

}

export default PublicationListingSearch;
