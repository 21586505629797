import React, {useEffect} from 'react'
import LINKHELPERS from '../../helpers/LinkHelpers'
import { usePageContextLabels, usePageContext } from '../../contexts/PageContext'
import { GotoIcon } from './GotoIcon'
import UTILITIESHELPER from '../../helpers/UtilitiesHelper'
import {PAGE_CONTROLLER_TYPE} from '../Constants/Constants';
import { ACTIONS, useResearchAssistantContext } from '../../contexts/ResearchAssistantContext'
import { RESEARCH_ASSISTANT_ERROR_TYPES } from '../Constants/Constants';

function DocumentSource({...props}) {
  const { getLabel } = usePageContextLabels()
  const {memberFirms } = usePageContext()
    const { dispatch } = useResearchAssistantContext();
    const { title, type, memberFirm, language, knowledgeDomain, memberFirmId, knowledgeDomainId, footnote, isPopupFootnote, uniqueIdentifier ,pubGuid} = props
  const getNavigatableLink = async (uid, mf, lang, kd) => {
    const navigatableLink = await LINKHELPERS.resolveLink(
      uid,
      pubGuid,
      null,
      mf,
      lang,
      kd,
      getLabel('l_LinkCannotBeResolved', 'Link cannot be resolved.'),
      false,
      memberFirms,
      true
    )
    if(navigatableLink === '') {
      const errorPayload = {
        errorType: RESEARCH_ASSISTANT_ERROR_TYPES.NO_CONTENT,
        hasError: true
      }
      dispatch({
        type: ACTIONS.RA_ERROR,
        payload: errorPayload,
      })
    }
    const uri = UTILITIESHELPER.getBaseDomain() + navigatableLink
    if (navigatableLink) {
        window.open(LINKHELPERS.sanitizeUrl(uri), '_blank')
    }
  }


  const getIcons= (type)=> {

    
      if (!UTILITIESHELPER.isStringNullorEmpty(type)) {
           if (type === PAGE_CONTROLLER_TYPE.manual)
              return '/images/manual-tile.svg';
          else if (type === PAGE_CONTROLLER_TYPE.standards)
              return '/images/standard-tile.svg';
          else if (type === PAGE_CONTROLLER_TYPE.deloitteCommunications)
              return '/images/deloitte-tile.svg';
          else if (type === PAGE_CONTROLLER_TYPE.professionalNews)
              return '/images/external-tile.svg';
          else
              return '/images/folio-tile.svg';
      }
      else {
          return '/images/folio-tile.svg';
      }
  }

  

  return (
    <div className='resolve-document-source'>
          {!isPopupFootnote && (
            <div
              className='footnote footnote-click'
              id={`{"footnote-top-${uniqueIdentifier}"}`}
            >
              <a
                style={{
                  color: '#0076a8',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  pointerEvents: 'none'
                 
                }}
                className='footnote-link fn-in-title'
                data-footnote-num={`{"{${uniqueIdentifier}}"}`}
                href='/#'
                data-testid={`testid-${footnote}`}
              > 
                {footnote}
              </a>
            </div>
          )}

<div className="source-icon-container">
  <img className="source-icon" src={getIcons(type)} alt="ico" />
   </div>
          <div
            className='sourcelink'
            onClick={() =>
              getNavigatableLink(
                uniqueIdentifier,
                memberFirm,
                language,
                knowledgeDomain
              )
            }
          >
            {title}
            {!isPopupFootnote && (
              <GotoIcon
                className='goto-next-icon'
                handleClick={() =>
                  getNavigatableLink(
                    uniqueIdentifier,
                    memberFirm,
                    language,
                    knowledgeDomain
                  )
                }
              />
            )}
          </div>
        </div>
  )
}

export default DocumentSource
