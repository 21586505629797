import React, { useEffect, useState } from 'react';
import ShowIf from '../Common/ShowIf';
import AskFollowUpButton from '../Common/AskFolllowUpButton';
import ResearchAssistantForms from './ResearchAssistantForms';
import { usePageContextLabels } from "../../contexts/PageContext";
import ShareReactionPanel from '../Common/ShareReactionPanel';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import FootnotePopup from './FootNotePopup';
import FilterValue from './FilterValue';
import ResearchAssistantSources from './ResearchAssistantSources';
import UserFeedbackModal from './UserFeedbackModal';
import ShareModal from '../Article/ShareModal';
import ResearchAssistantNotableExclusions from './ResearchAssistantNotableExclusions';
import { MEMBERFIRM_CONSTANTS, RESEARCH_ASSISTANT } from '../Constants/Constants';
import ResearchAssistantMessage from './ResearchAssistantMessage';
import ResearchAssistantInfoModal from './ResearchAssistantInfoModal';
import { ACTIONS, useResearchAssistantContext } from '../../contexts/ResearchAssistantContext';
import { useThemeContext } from '../../contexts/ThemeContext';
function ChatbotResearchAssistant(
    {
        expandMore,
        setExpandMore,
        isResearchAssistantLoading,
        researchAssistantResponse,
        isConversationalMode,
        searchPhrase,
        countId,
        messgaeResponse,
        currentIndex = 1,
        isChat = false,
        handleAskFollowUpButton,
        errorMessage
    }
) {
    const { getLabel } = usePageContextLabels();
    const [questiontitle, setquestionTitle] = useState("");
    const [shouldShowShareModal, setShouldShowShareModal] = useState(false);
    const [shouldShowOverlay, setShouldShowOverlay] = useState(false);
    const [isSectionPath, setIsSectionPath] = useState(false);
    const [closedToolbar, setClosedToolbar] = useState(false);
    const { dispatch, state: raState } = useResearchAssistantContext();
    const [converstionpageQuestion, setConverstionpageQuestion] = useState("");
    const [researchAssitantResponse, setresearchAssitantResponse] = useState("")
    const { isDarkMode } = useThemeContext()
    const [interval, setIntervalLimit] = useState(null)
    const shareHandlerClick = () => {
        if (messgaeResponse && countId) {

            const question = messgaeResponse?.find((data) => (
                data.id === countId && data.type === "Query"
            ))
            setConverstionpageQuestion(question.response)
            const questionResponse = messgaeResponse?.find((data) => (
                data.id === countId && data.type === "Answer"
            ))
            setresearchAssitantResponse(questionResponse?.response)
        }
        setShouldShowShareModal(true);
        setShouldShowOverlay(true);
        setIsSectionPath(false);
    }


    const questions = searchPhrase
    useEffect(() => {

        setquestionTitle(questions);
    }, [questions])
    const [message, setMessage] = useState()
    const [chatResponse, setChatResponse] = useState()
    const [documentSource, setDocumentSource] = useState()
    const [isFeedBackModalOpen, setIsFeedBackModalOpen] = useState(false);
    const [isFeedBackType, setFeedBackType] = useState('');

    useEffect(() => {
        if(JSON.stringify(researchAssistantResponse?.response) !== JSON.stringify(chatResponse)) {
            const { message, documentSource } = UTILITIESHELPER.replaceFootnoteWithLinkInChat(researchAssistantResponse)
            setMessage(message)
            setDocumentSource(documentSource)
            setChatResponse(researchAssistantResponse?.response)
        }
    }, [researchAssistantResponse?.response])

    function callDynamicText(text, searchinfo, index) {
        const container = document.getElementById('text-wrapper');
        if (container) {
            container.innerHTML='';
            container.classList = `loading loading02 loader-title${index}`
            container.style.animationDuration = `${window.DART.ENV.RA_LOADER_ANIMATION_TIME_DIFFERENCE/1000}s`
            text.split(' ').forEach((char, index) => {
                const span = document.createElement('span');
                span.innerHTML = char + '&nbsp;';
                span.style.animationDelay = `${index * 0.05}s`;
                container.appendChild(span);
            });
            const infoElement = document.getElementById('text-info')
            if(infoElement) {
                infoElement.style.animationDuration = `${window.DART.ENV.RA_LOADER_ANIMATION_TIME_DIFFERENCE/1000 + .2}s`
                infoElement.innerHTML = searchinfo
                infoElement.classList = `textbracket loader-description${index}`
            }
        }
    }
    useEffect(() => {
        if (!isResearchAssistantLoading) {
            clearInterval(interval)
        }
        if (isResearchAssistantLoading) {
            callDynamicText(getLabel('ra_Analyzing_Question', 'Analyzing question...'), getLabel('ra_Analyzing_Question_Descrption', '(Hold tight, average response time is 15 seconds)'), 1);
            let count = 0
             var loaderInterval = setInterval(() => {
                if(count === 0) {
                    callDynamicText(getLabel('ra_SearchingForResources', 'Searching for sources...'), getLabel('ra_SearchingForResources_Description', '(Scanning manuals and folios for you...don\'t refresh!)'), 2);
                } else if(count === 1) {
                    callDynamicText(getLabel('ra_Reading_Resources', 'Reading sources...'), getLabel('ra_Reading_Resources_Description', '(Carefully reviewing what I found...hang in there!)'), 3);
                } else if(count === 2) {
                    callDynamicText(getLabel('ra_Writing_Response', 'Writing response...'), getLabel('ra_Writing_Response_Description', '(Pulling it all together...don\'t refresh!)'), 4);
                } else {
                    callDynamicText(getLabel('ra_DoubleCheckingResponse', 'Double checking response...'), getLabel('ra_DoubleCheckingResponse_Description', '(Giving it a quick review...thanks for waiting!)'), 5);
                }
                count++;
        }, window.DART.ENV.RA_LOADER_ANIMATION_TIME_DIFFERENCE)
            setIntervalLimit(loaderInterval)
        }
    }, [isResearchAssistantLoading]);

    const feedBackButtonClick = (feedBackType) => {
        window.open(window.DART.ENV.RA_FEEDBACK_URL, "_blank");
        //This feature no longer need for current release
        // setIsFeedBackModalOpen(true);
        // setFeedBackType(feedBackType);
    }
    const handlePopup = (popupIndex) => {
        dispatch({
            type: ACTIONS.RA_INFO_INDEX,
            payload: popupIndex,
        })
    }

    return (
        <div className="search-assistant">
            <ShowIf condition={shouldShowShareModal}>
            <ShareModal
                setShouldShowShareModal={setShouldShowShareModal}
                shouldShowShareModal={shouldShowShareModal}
                setShouldShowOverlay={setShouldShowOverlay}
                shouldShowOverlay={shouldShowOverlay}
                setIsSectionPath={setIsSectionPath}
                isSectionPath={isSectionPath}
                setClosedToolbar={setClosedToolbar}
                researchAssistantQuestion={converstionpageQuestion ? converstionpageQuestion : questiontitle}
                researchAssistantTitle={"Research Assistant"}
                researchAssistantResponseData={researchAssitantResponse ? researchAssitantResponse : researchAssistantResponse}
                isResearhAssistant={true}

            />
            </ShowIf>
            {currentIndex === raState.selectedInfoIndex && <ResearchAssistantInfoModal handlePopup={handlePopup} />}
            <div className='search-assistant-ico'>
                <button className="search-assistant-ico-img"  data-testid="search-assistant-ico-img">&nbsp;</button>
            </div>
            <div className='search-assistant-body'>
                <label className='search-assistant-title' data-testid='research-assistant-title'>
                    {getLabel('ra_ResearchAssistantTitle', 'Research Assistant')}
                    <img src='/images/Question-mark-lightblue.svg' data-testid='question-info-icon' className='ra-info-icon' onClick={() => handlePopup(currentIndex)} />
                </label>
                <ShowIf condition={!isResearchAssistantLoading && errorMessage === null && researchAssistantResponse !== null && researchAssistantResponse !== undefined && !UTILITIESHELPER.isStringNullorEmpty(researchAssistantResponse?.response)}>
                   <label className='ai-content-disclaimer'>{UTILITIESHELPER.isStringNullorEmpty(getLabel('ra_AIContentDisclaimer', 'Below generative AI response may be incomplete and/or inaccurate, are not auditing advice, and should not be used for audit documentation. Review source documents and use professional judgment.')) ? '' : getLabel('ra_AIContentDisclaimer', 'Below generative AI response may be incomplete and/or inaccurate, are not auditing advice, and should not be used for audit documentation. Review source documents and use professional judgment.')}</label>
                </ShowIf>
                <div className='search-assistant-response-body'>
                    <div className='search-assistant-response-container'>
                        <ShowIf condition={isResearchAssistantLoading}>
                            <div className='research-assistant-loader'>
                                <div className='search-assistant-response'>
                                    <div className='search-assistant-response-content-loader'>
                                        <div className='loading-chatbot' data-testid='loader-circle'>
                                            <div className="loading loading02" id="text-wrapper" />
                                            <label className='textbracket' id='text-info' />
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </ShowIf>
                        <ShowIf condition={!isResearchAssistantLoading}>
                            <div className='search-assistant-response' id={`research-assistant-box-${currentIndex}`}>
                                <FootnotePopup documentSource={documentSource} currentIndex={currentIndex} isChat={isChat} researchAssistantResponse={researchAssistantResponse} />
                                <ShowIf condition={researchAssistantResponse !== null && researchAssistantResponse !== undefined && researchAssistantResponse?.response !== null && errorMessage === null && Object.values(researchAssistantResponse).length !== 0}>
                                    {expandMore ?
                                        <div className='research-assistant-container'>
                                            <div className='search-assistant-response-content'>
                                                <ResearchAssistantMessage messageLabel={researchAssistantResponse?.label} message={message} />
                                                <div className='response-opacity'></div>
                                            </div>
                                        </div>
                                        :
                                        <div className='research-assistant-container'>
                                            <div className='research-assistant-responses'>
                                                <ResearchAssistantMessage messageLabel={researchAssistantResponse?.label} message={message}/>
                                            </div>
                                            <div className='filter-value'>
                                                <FilterValue researchAssistantResponse={researchAssistantResponse} />
                                            </div>
                                            <ShowIf condition={researchAssistantResponse?.documentSource?.length}>
                                                <div className="research-assistant-forms" data-testid="research-assistant-sources">
                                                    <ResearchAssistantSources isResearchAssistantLoading={isResearchAssistantLoading} researchAssistantResponse={researchAssistantResponse}  />
                                                </div>
                                            </ShowIf>
                                            <ShowIf condition={String(window.DART.ENV.RA_IS_FORMS_AND_TEMPLATES_ENABLED).toUpperCase() === "TRUE"}>
                                                <div className="research-assistant-forms">
                                                    <ResearchAssistantForms isResearchAssistantLoading={isResearchAssistantLoading} researchAssistantResponse={researchAssistantResponse} />
                                                </div>
                                            </ShowIf>
                                            <div className="research-assistant-forms" data-testid="notable-content-exclusion">
                                                <ResearchAssistantNotableExclusions isResearchAssistantLoading={isResearchAssistantLoading} researchAssistantResponse={researchAssistantResponse}  />
                                            </div>
                                            <ShowIf condition={!isConversationalMode}>
                                                <div className='askme-followup-box'>
                                                    <AskFollowUpButton handleAskFollowUpButton={handleAskFollowUpButton} />
                                                </div>
                                            </ShowIf>
                                        </div>}
                                    
                                </ShowIf>
                                <ShowIf condition={ errorMessage !== null}>
                                    <div className='search-assistant-response-content'>
                                        <p>{errorMessage}</p>
                                    </div>
                                </ShowIf>
                                <ShowIf condition={(researchAssistantResponse === null || researchAssistantResponse === undefined || researchAssistantResponse?.response === null) && errorMessage === null}>
                                    <div className='search-assistant-response-content'>
                                        <p>{getLabel('ra_unable_to_retrive', 'Unable to retrieve answer. Please try again later.')}</p>
                                    </div>
                                </ShowIf>
                            </div>
                            <div className='search-assistant-help-button'>
                                <label className='opacity-box'></label>
                                <div>
                                    <ShowIf condition={!isResearchAssistantLoading && researchAssistantResponse !== null && researchAssistantResponse !== undefined && researchAssistantResponse?.response !== null && Object.values(researchAssistantResponse).length !== 0}>
                                        <ShareReactionPanel feedBackButtonClick={feedBackButtonClick} shareHandlerClick={shareHandlerClick} />
                                    </ShowIf>
                                </div>
                            </div>
                        </ShowIf>
                        <ShowIf condition={isFeedBackModalOpen}>
                            <UserFeedbackModal userFeedBackType={isFeedBackType} setIsFeedBackModalOpen={setIsFeedBackModalOpen} isFeedBackModalOpen={isFeedBackModalOpen}></UserFeedbackModal>
                        </ShowIf>
                    </div>
                    <ShowIf condition={!isResearchAssistantLoading && expandMore && researchAssistantResponse !== null && researchAssistantResponse !== undefined && researchAssistantResponse?.response !== null && errorMessage === null}>
                        <div className='search-expand'>
                            <button data-testid="read-more-button" className='read-more-button' onClick={() => setExpandMore(!expandMore)}>{getLabel('ra_readmore','READ MORE')}</button>
                        </div>
                    </ShowIf>
                </div>
            </div>
        </div>
    )
}

export default ChatbotResearchAssistant