import React from "react";

export const GotoIcon = ({ handleClick }) => {
    return (
        <svg
            onClick={handleClick}
            className="annotation_comment"
            width="11px"
            height="10px"
            style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
            cursor="pointer"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="goto-icon"
        >
            <path fill="#F8FCF7" d="M 4.5,-0.5 C 5.16667,-0.5 5.83333,-0.5 6.5,-0.5C 7.31683,1.30771 6.65017,2.80771 4.5,4C 6.38673,4.16258 7.88673,3.32925 9,1.5C 9.27842,2.4158 9.77842,3.08247 10.5,3.5C 10.5,3.83333 10.5,4.16667 10.5,4.5C 9.56552,5.56764 9.23219,6.90097 9.5,8.5C 6.5,8.5 3.5,8.5 0.5,8.5C 0.5,5.83333 0.5,3.16667 0.5,0.5C 2.09903,0.767809 3.43236,0.434475 4.5,-0.5 Z" />
            <path fill="#24363e" d="M 6.5,-0.5 C 7.83333,-0.5 9.16667,-0.5 10.5,-0.5C 10.5,0.833333 10.5,2.16667 10.5,3.5C 9.77842,3.08247 9.27842,2.4158 9,1.5C 7.88673,3.32925 6.38673,4.16258 4.5,4C 6.65017,2.80771 7.31683,1.30771 6.5,-0.5 Z" />
            <path fill="#040609" d="M -0.5,-0.5 C 1.16667,-0.5 2.83333,-0.5 4.5,-0.5C 3.43236,0.434475 2.09903,0.767809 0.5,0.5C 0.5,3.16667 0.5,5.83333 0.5,8.5C 3.5,8.5 6.5,8.5 9.5,8.5C 9.23219,6.90097 9.56552,5.56764 10.5,4.5C 10.5,6.16667 10.5,7.83333 10.5,9.5C 6.83333,9.5 3.16667,9.5 -0.5,9.5C -0.5,6.16667 -0.5,2.83333 -0.5,-0.5 Z" />
        </svg>
    );
};
