
import React from 'react';

const RecentlyViewedItem = ({ item }) => {

    var title = item.title?.split('/');
    var header = getTitleToDisplay(title, 1);
    var breadcrumb = item.title;

    function getTitleToDisplay(title, index) {
        var finalHeader = (title && title?.length > 0) ? title[title?.length - index].trim() : "";
        if (finalHeader?.length === 0) {
            return getTitleToDisplay(title, index + 1);
        }
        else {
            return finalHeader;
        }
    }

    return (
        <>
            <div className="recently-viewed-item-body">
                <p className="recently-viewed-item-heading">
                    <a className="recently-viewed-item-heading-link" href={item.relative_url} data-testid="recently-viewed-item-heading-link">
                        {
                            decodeURIComponent(header)
                        }
                    </a>
                </p>
                <p className="recently-viewed-item-breadcrumb" data-testid="recently-viewed-item-breadcrumb">{decodeURIComponent(breadcrumb)}</p>
            </div>
        </>
    )
}

export default RecentlyViewedItem;