
import {useEffect} from 'react';

// additionalSelectors Array is way to check the click occured outside of 
// other elements besides the main ref provided

const useOutsideClick = (ref, callback, additionalSelectors = []) => {
    
// eslint-disable
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
        //eslint-disable-next-line
      }, []);
};

export default useOutsideClick;
