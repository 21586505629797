
import React from 'react';
import cx from 'classnames';
import { useArticleContext } from '../../contexts/ArticleContext';
import JumpLinkMain from "./JumpLinkMain"
import JumpLinkSub from './JumpLinkSub';
import { getOffsetTop } from './RenderAllContainer';
import ShowIf from '../Common/ShowIf';

export const scrollElementIntoView = (element, offsetCompensation = 0) => {
    if (!element) return;
    const offsetTop = getOffsetTop(element);
    const offsetCalc = offsetTop + offsetCompensation;
    window.scrollTo(0, offsetCalc);
};

const JumpLinksList = ({ closeToc, jumpLinks, className, scrollOffsetCompensation }) => {

    const { setOpenMultipleExpandedSections } = useArticleContext();

    function jumpHandler(e, jumpClass) {
        const targetEl = document.querySelector('[class*=' + jumpClass + ']');
        const sectionsToExpand = [];
        let currentEl = targetEl;

        while (currentEl !== null) {
            if (currentEl.hasAttribute('data-expandable-uuid') && !currentEl.classList.contains('open')) {
                sectionsToExpand.push(currentEl.dataset.expandableUuid);
            }
            currentEl = currentEl.parentElement.closest('[data-expandable-uuid]');
        }

        if (sectionsToExpand.length) {
            setOpenMultipleExpandedSections(sectionsToExpand, true);
        }

        const scrollToTarget = () => {
            scrollElementIntoView(targetEl, scrollOffsetCompensation);
            closeToc();
        }

        e.preventDefault();
        setTimeout(scrollToTarget, 0);
    }

    function createJumpLinksSubTree(jumpLink, level) {
        return (
            <ShowIf condition={Array.isArray(jumpLink?.children) && jumpLink.children.length > 0}>
                <ol className="article-jump-links-sub" >
                    {jumpLink.children.filter(child => child?.title).map((child, i) => (
                        <div key={i} className={'article-jump-links-sub-'+ level} >
                            <JumpLinkSub key={i} title={child.title} href={child.href} jumpClass={child.jumpClass} jumpClickHandler={jumpHandler} />
                            
                            {createJumpLinksSubTree(child, level + 1)}
                        </div>
                    ))}
                </ol>
            </ShowIf>
        );
    }

    return (
        <ol className={cx("article-jump-links", className)}>
            {jumpLinks.filter(jumpLink => jumpLink?.title).map((jumpLink, i) => (
                <div key={i} className="article-jump-links-main">
                    <JumpLinkMain title={jumpLink.title} href={jumpLink.href} jumpClass={jumpLink.jumpClass} jumpClickHandler={jumpHandler} />
                    
                    { createJumpLinksSubTree(jumpLink, 0) }
                </div>
            ))}

        </ol>
    )
}

JumpLinksList.defaultProps = {
    closeToc: () => { },
    scrollOffsetCompensation: 0,
}

export default JumpLinksList
