import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import { throttle } from 'lodash';
import RETRIEVALSERVICES from '../../services/rp-service';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import RenderAllContainer from '../Article/RenderAllContainer';
import FootnotePopup from '../Article/FootnotePopup';
import { usePageContext, usePageContextLabels, usePageContextSettings } from '../../contexts/PageContext';
import { getArticleTitle } from '../Publication/Article';
import ArticleCompareVersionDropdown from './ArticleCompareVersionDropdown';

function calcIfNavShouldBeFixed(el) {
    if (!el) return;
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    return !elemTop
}

const ArticleCompareVersions = () => {
    const location = useLocation();
    const navElemRef = useRef();
    const { context, compareVersionParam, language } = usePageContext();
    const { isCompareEnabled } = usePageContextSettings();
    const { getLabel } = usePageContextLabels();
    const [shouldCompareVersions, setShouldCompareVersions] = useState(true);
    const [pastVersions, setPastVersions] = useState([]);
    const [isNavFixed, setIsNavFixed] = useState(false);
    const [pubData, setPubData] = useState({})
    const mostRecentVersion = pubData?.version || '...';
    const mostRecentDate = UTILITIESHELPER.formatDate(pubData?.current_date || '', language, "shortDate");
    const compareVersionDate = UTILITIESHELPER.formatDate(pubData?.publicationVersion?.current_date || '', language, "shortDate");
    const rawCompareTitle = getArticleTitle(pubData, false, false, null, false, context);
    const compareTitle = rawCompareTitle.replace( /(<([^>]+)>)/ig, '');
    var articlebody = document.querySelectorAll(".compare-versions-article-body a");

    const handleDocumentClick = async (ev) => {
        ev.preventDefault();
    }

    useEffect(() => {
        const handleScroll = (ev) => {
            const isNavFixedUpdated = calcIfNavShouldBeFixed(navElemRef.current)
            setIsNavFixed(isNavFixedUpdated)
        }

        const retrievePubVersions = async () => {
            try {
                const res = await RETRIEVALSERVICES.retrieveManualPublicationVersions();
                setPastVersions(res.data)
            } catch {

            }
        };

        if (isCompareEnabled()) {
            retrievePubVersions();
        }

        const throttledScroll = throttle(handleScroll, 16);
        window.addEventListener('scroll', throttledScroll, { passive: true })
        window.addEventListener('resize', throttledScroll);

        return () => {
            window.removeEventListener('scroll', throttledScroll);
            window.removeEventListener('resize', throttledScroll);
        };
    }, []);//eslint-disable-line

    useEffect(() => {
        window.scrollTo(0, 0);
        const retrieveArticleVersions = async () => {
            setPubData({})
            try {
                const res = await RETRIEVALSERVICES.retrieveManualPublication();
                setPubData(res);
            } catch {

            }
        };

        if (isCompareEnabled()) {
            retrieveArticleVersions();
        }
    }, [compareVersionParam]);//eslint-disable-line

    useEffect(() => {
        articlebody = document.querySelectorAll(".compare-versions-article-body a");//eslint-disable-line
        if (articlebody !== null) {
            articlebody.forEach(element => {
                element.addEventListener("click", handleDocumentClick);
            });
        }
        return () => { 
            if (articlebody !== null) {
                articlebody.forEach(element => {
                    element.removeEventListener("click", handleDocumentClick);
                });
            }
        }
    }, [articlebody]);

    const getVersion = (version) => {
        const versionNum = version?.version;
        const versionDate = UTILITIESHELPER.formatDate(version?.current_date, language, "shortDate");
        return ({
            value: versionNum,
            label: `V${versionNum} ${versionDate}`,
            href: `${location?.pathname}?compareVersion=${versionNum}`,
        })

    }
    const renderPastVersionsDropdown = () => {
        if (!pastVersions?.length) return "...";

        const versionOptions = pastVersions.slice(1).map((version) => {
            return getVersion(version);
        });

        return (
            <ArticleCompareVersionDropdown
                options={versionOptions}
                compareVersionParam={compareVersionParam}
            />
        );
    };

    return (
        <div className="w-100 d-flex flex-column">
            <div
                ref={navElemRef}
                className={
                    cx("compare-versions-header",
                        {
                            'compare-versions-header-fixed': isNavFixed,
                        })}
            >
                <div className="d-flex">
                    <div className="compare-versions-title-and-version-container">
                        <h1 className="compare-versions-title">
                            {getLabel('compare_ViewAndCompareVersions', 'View and Compare Versions')}
                        </h1>
                        <div className="d-flex compare-versions-published-version-text-container">
                            <div className="compare-versions-published-version-text">
                                {getLabel('compare_PublishedVersion', 'Published Version:')}
                            </div>
                            <div>V{mostRecentVersion} {mostRecentDate}</div>
                        </div>
                    </div>
                    <div className="d-flex flex-column toggle-compare-versions-container">
                        <div>{getLabel('compare_CompareChanges', 'CompareChanges')}</div>
                        <button
                            onClick={() => setShouldCompareVersions((prevVal) => !prevVal)}
                            className={
                                cx("toggle-compare-versions",
                                    { 'toggle-compare-versions-on': shouldCompareVersions }
                                )}
                            title="Toggle Compare Versions"
                        ></button>
                    </div>
                    <div className="d-flex flex-column compare-versions-past-versions-dropdown-container">
                        <div>{getLabel('compare_PastVersions', 'Past Versions')}</div>
                        {renderPastVersionsDropdown()}
                    </div>
                </div>
            </div>
            <div className="compare-versions-article-body flex-grow">
                <div>
                    <span className="font-weight-700">
                        {getLabel('compare_PastVersion', 'Past Version:')}
                    </span>V{compareVersionParam}  {compareVersionDate}
                </div>
                <h1 className="compare-versions-article-title">{compareTitle}</h1>
                <div className="article-container article-container-wide-mode">
                    <FootnotePopup dependenciesToRecalculatePosition={[false]} />
                    <RenderAllContainer pubData={shouldCompareVersions ? pubData : pubData.publicationVersion} excludeBinaries={false} />
                </div>
            </div>
            <div></div>
        </div>
    )
};

export default ArticleCompareVersions;
