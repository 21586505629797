
import React from 'react';
import { usePageContext, usePageContextLabels } from '../../../contexts/PageContext';
import ShowIf from '../ShowIf';

const PageTitle = () => {
    const { pageTitle, pageDescription } = usePageContext()
    const { getMFLabel } = usePageContextLabels();

    return (
        <>
            <h2 className="page-title">
                {getMFLabel(pageTitle, "pageTitle")}
            </h2>
            <ShowIf condition={pageDescription}>
                <div className="page-description">
                    {getMFLabel(pageDescription, "pageDescription")}
                </div>
            </ShowIf>
        </>
    )
}

export default PageTitle