// src/screens/Layouts/HomeLayout.js

import React, {useEffect} from "react";
import HomeHeader from "./HomeHeader";
import Footer from "../Common/Layout/Footer";

const HomeLayout = ({ children }) => {

    useEffect(() => {
        document.body.classList.add('home');
        return () => document.body.classList.remove('home');
    }, [])
  
  return (
    <>
      <HomeHeader />
      <main id="main">
        {children}
      </main>
      <Footer className="footer-homepage" />
    </>
  );
};

export default HomeLayout;
