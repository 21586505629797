import React from 'react'
import cx from 'classnames'
import ShowIf from './ShowIf'

const Checkbox = ({ isChecked, onChange, label, classNameCheckboxContainer, classNameCheckmark, disabled, showBinaryPanel = false, displayBinaryHadler, parentId = 0, binaryCount = 0, accordianState = "close", onKeyDown }) => (

  <div className={cx(classNameCheckboxContainer)}>
    <label className="d-flex" tabIndex={0} onKeyDown={onKeyDown}>
      <div className={cx("styled-checkbox", { 'checked': isChecked }, { 'disabled': disabled })}>
        <div style={{ visibility: isChecked ? 'visible' : 'hidden' }} className={cx(classNameCheckmark, { 'disabled': disabled })}>
            <div className="tick-mark"></div>
        </div>
      </div>
      <input type="checkbox" className="hidden-checkbox" checked={isChecked} onChange={onChange} disabled={disabled}   tabIndex={1}/>
      <label className="checkbox-label">{label}</label>
    </label>
        <ShowIf condition={showBinaryPanel}>
            <div className="display article-level-select-document" onClick={() => displayBinaryHadler(parentId)}>
                <div className="binary-panel binary-panel-article">
                    {/*//added +1 for Article Text checkbox option*/}
                    Select Documents {binaryCount > 0 ? "(" + (binaryCount + 1) + ")" : ""}
                </div>
                <div className={"binary-download-" + accordianState} ></div>
            </div>
        </ShowIf>
    </div>

)

Checkbox.defaultProps = {
    onChange: () => { },
    classNameCheckmark: "checkmark",
    classNameCheckboxContainer: "checkbox-container"
}


//className={cx(classNameCheckmark, { 'disabled': disabled })}
export default Checkbox