import { REGEX_PATTERN } from '../components/Constants/Constants';
import UTILITIESHELPER from '../helpers/UtilitiesHelper';
const pluralSuffix = ["s", "S", "'s", "'S"];

function escapeRegExp(string) {
    // This method is used to escape ristricted special characters for regex.
    // If any other character need to be added in future please add below
    return string.replace(/[.*?^${}()[\]\\]/g, "\\$&"); // $& means the whole matched string
}

//Check if the rest part of the search text is present in the content in the next index,
//If match found then only highlight the current splitted search text else dont
function IsSearchTextPresentInNeighbors(textItems, textItem, splittedSearch, postIndex=false, preIndex=false) {
    if (textItems.length > 0)
    {
      if (postIndex && (RegExp (splittedSearch[1], 'gi').test(extractTextByIndexValue(textItems, textItem.itemIndex, 1, postIndex)) || RegExp(splittedSearch[1], 'gi').test(extractTextByIndexValue(textItems, textItem.itemIndex, 2, postIndex))) && (RegExp (splittedSearch[2], 'gi').test(extractTextByIndexValue(textItems, textItem.itemIndex, 1, postIndex)) || RegExp(splittedSearch[2], 'gi').test(extractTextByIndexValue(textItems, textItem.itemIndex, 2, postIndex))))
      {
        return true;
      }
      else if(preIndex && (RegExp (splittedSearch[0], 'gi').test(extractTextByIndexValue(textItems, textItem.itemIndex, 1, postIndex)) || RegExp(splittedSearch[0], 'gi').test(extractTextByIndexValue(textItems, textItem.itemIndex, 2, postIndex))) && (RegExp (splittedSearch[1], 'gi').test(extractTextByIndexValue(textItems, textItem.itemIndex, 1, postIndex)) || RegExp(splittedSearch[1], 'gi').test(extractTextByIndexValue(textItems, textItem.itemIndex, 2, postIndex)))){
        return true;
      }
      else{
        return false;
      }
    }
    else{
        return false;
    }
}

function extractTextByIndexValue(textItems, index, value, postIndex) {
    var textValue = postIndex ? textItems[index + value] : textItems[index - value];
    return textValue?textValue.str : "";
}

//Public Functions
function apply(content, rawSearchPhrase, isPDF=false, textItems = [], textItem={}) {
    const parsedPhrase = parse(rawSearchPhrase, content);
    return mark(content, parsedPhrase, isPDF, textItems, textItem);
}

function fromQueryString(params) {
    return (new URLSearchParams(params)).get('searchPhrase');
}

function hasSearchResults(results) {
    var hasResults = false;
    results.forEach((resultItem) => {
        if (resultItem.highlights?.length > 0) {
            hasResults = true;
        }
    })
    return hasResults;
}

function mark(content, searchPhrase, isPDF, textItems, textItem) {

    if (searchPhrase.length > 0 && searchPhrase[0] === '-') {
        return content;
    }
    // enforce minimum serach phrase length of 3
    if (searchPhrase.length < 3) {
        return content;
    }

    if(searchPhrase && (searchPhrase.includes('*') || searchPhrase.includes('?')) && RegExp('^[a-z].*[a-z]$', 'gi').test(searchPhrase)) {
        let splitArray = searchPhrase.includes('*') ? searchPhrase.split('*') : searchPhrase.split('?');
        if(splitArray.length>1)
        {
            let regex =  searchPhrase.includes('*')?"\\b" + `${splitArray[0]}.+?${splitArray[1]}` :"\\b" + `${splitArray[0]}.{1}?${splitArray[1]}`;// eslint-disable-line
            return markWithWildChars(content, regex);
        }
    }

    else if(searchPhrase && (searchPhrase.includes('+')) && RegExp('[a-z] ?\\+ ?[a-z]', 'gi').test(searchPhrase))
    {
        searchPhrase = searchPhrase.replace(/\s+/g, "");
        let splitArray = searchPhrase.split('+');
        if(splitArray.length>1)
        {
            let regex = "\\b"+`${splitArray[0].trim()}` + "+\\s*[\r\n]*" + `${splitArray[1].trim()}`+"\\b";// eslint-disable-line
            return markWithOperators(content,regex);
        }
    }

    else if(searchPhrase && (searchPhrase.includes('-')) && (!searchPhrase.includes('"')) && RegExp('[a-z] ?\- ?[a-z]', 'gi').test(searchPhrase))// eslint-disable-line
    {
        searchPhrase = searchPhrase.replace(/\s+/g, "");// eslint-disable-line
        let splitArray = searchPhrase.split('-');
      
        if(splitArray.length>1)
        {
            let regex = "\\b"+`${splitArray[0].trim().replace('(','')}`+"\\b"// eslint-disable-line
            UTILITIESHELPER.removeBrackets(regex)
            return markWithOperators(content,UTILITIESHELPER.removeBrackets(regex));
        }
    }

    else if(searchPhrase && (searchPhrase.includes('/')) && (!searchPhrase.includes('"')) && RegExp('[a-z] ?\\ ?[a-z]', 'gi').test(searchPhrase))// eslint-disable-line
    {
        searchPhrase = searchPhrase.replace(/\s+/g, "");// eslint-disable-line
        let splitArray = searchPhrase.split('/');
        if(splitArray.length>1)
        {
            let regex = "\\b"+`${splitArray[0].trim()}|${splitArray[1].trim()}`+"\\b"// eslint-disable-line
            return markWithOperators(content,regex);
        }
    }

    else if(searchPhrase && isPDF && searchPhrase.includes('"') && searchPhrase.includes('-'))
    {
        searchPhrase = searchPhrase.replace(/\s+/g, "").replace(/"/g,'');
        let splitArray = searchPhrase.split('-');
        let splittedSearch = [];
        splittedSearch.push(splitArray[0],'-',splitArray[1]);
        if(RegExp(splittedSearch[0], 'gi').test(content))
        {
            if(IsSearchTextPresentInNeighbors(textItems,textItem,splittedSearch,true,false))
            {
                return markSearchPhrase(content, splittedSearch[0]);
            }
        }
        if(RegExp(splittedSearch[2], 'gi').test(content))
        {
            if(IsSearchTextPresentInNeighbors(textItems,textItem,splittedSearch,false,true))
            {
                return markSearchPhrase(content, splittedSearch[2]);
            }
        }
        return markSearchPhrase(content, searchPhrase);
    }

    //check for space + asterisk(" *")
    if (RegExp('\\s\\*', 'g').test(searchPhrase)) {
        return markAll(content, searchPhrase);
    }

    return markSearchPhrase(content, searchPhrase);
}

function markSearchPhrase(content, searchPhrase) {
    let regex = getRegex(searchPhrase, content);
    if (regex === null) {
        return content;
    }

    const m = (content, pre = '', post = '') => `${pre}<mark class="highlight-search">${content}</mark>${post}`;

    return content.toString().split(new RegExp('(<\/?.*?>)', 'gi')).map(function (value) { // eslint-disable-line
        value = UTILITIESHELPER.removeLargeSpacesFromRichText(value);
        if (!new RegExp('(<\/?.*?>)', 'gi').test(value) && value && regex.test(value))// eslint-disable-line
         {
            return value.toString().replace(regex, (a) => m(a));
        }
        return value;
    }).join(" ");
}

function parse(searchPhrase, content) {
    let phrase = `${searchPhrase}`; // clone

    phrase = phrase
      .toString()
      .trim()
      .replace(/\(/g, "(")
      .replace(/\)/g, ")")
      .replace(/\[/g, "[")
      .replace(/\]/g, "]")
      .replace(/\\/g, "\\")
      .replace(/\//g, "/")
      // Replace Text Operators
      .replace(/\sAND\s|\s&&\s/g, "+") // AND, && => +
      .replace(/\sOR\s/g, "|")
      .replace(/\sNOT\s/g, "-")
      // Normalize Operator Gaps
      .replace(/[\+\|\-]$/, "")// eslint-disable-line
      .trim();

    if (content !== undefined) {
      if (content.includes("’")) {
        return phrase = phrase.replace("'", "’");
      } else {
        if (phrase.includes("’")) {
          return phrase = phrase.replace("’", "'");
        }
      }
    }
    return phrase;
}

function toHumanReadable(searchPhrase) {
    return searchPhrase?.replace(/\|/g, ' | ')?.replace(/\s+/g, ' ');
}

function toQueryString(searchPhrase) {
    return (new URLSearchParams({ searchPhrase })).toString();
}

function markAll(htmlSting, searchPhrase) {
    // split the complete string by all html tag example.(<div>), then loop through all the splitted value and wrap with highligt div where from where the search phrase starts
    const m = (content, pre = '', post = '') => `${pre}<mark class="highlight-search">${content}</mark>${post}`;

    //Split content by html tags
    let resString = htmlSting.toString().split(new RegExp('(<\/?.*?>)', 'gi'));// eslint-disable-line
    let firstOccuranceSearchString = searchPhrase.match(new RegExp('([\\w]*)\\s+\\*', 'gi'))[0].match(new RegExp('\\w*', 'gi'))[0];//extract character from search phrase if available
    let isSearchStarted = (UTILITIESHELPER.getLocalStorage("rp-is-search-start")=== "true");
    return resString.map(function (value) {
        if (!value.trim()) {
            return "";
        }

        let isContainsSearchString = new RegExp(firstOccuranceSearchString, 'gi').test(value);// set startHighlight to when content includes searchPhrase OR searchPhrase only contains " *"

        if (!new RegExp('(<\/?.*?>)', 'gi').test(value) && !isSearchStarted && isContainsSearchString && firstOccuranceSearchString) { // eslint-disable-line
            //this condition checks when to start the highlight isSearchStarted(if false highlight not startted yet),
            //isContainsSearchString(string contains search phrase or not), firstOccuranceSearchString(check search phrase should have any keyword before "any *", this contains "any")
            UTILITIESHELPER.setLocalStorage("rp-is-search-start", true);
            isSearchStarted = true;
            if (isContainsSearchString) {
                return value.replace(new RegExp(`${firstOccuranceSearchString}.*`, 'gi'), (match) => { return m(match) });
            }
        }

        // eslint-disable-next-line
        if (new RegExp('(<\/?.*?>)', 'gi').test(value) || !isSearchStarted) {
            return value;
        }
        return m(value);

    }).join(" ");
}

function markWithWildChars(htmlString, regex) {
    let resString = htmlString.toString().split(new RegExp('(<\/?.*?>)', 'gi'));  // eslint-disable-line
    //Split content by html tags

    //eslint-disable-next-line
    return resString.map(function (value) {
        if(regex.includes('*') || regex.includes('?'))
        {
          value=value.replace(/\s+/g,' ')
          var words = value.split(" ");
          if (!new RegExp("(<([^>]+)>)", "gi").test(value)) {
            return words
              .map(function(word) {
                return word.replace(new RegExp(regex, "gi"), `<mark class="highlight-search">$&</mark>`);
              })
              .join(" ");
          }
          else{
            return words
              .map(function(word) {
                return word;
              })
              .join(" ");
          }
        }
    }).join(" ");
}

function markWithOperators(htmlString, regex) {
    //Split content by html tags
    let resString = htmlString.toString().split(new RegExp('(<\/?.*?>)', 'gi'));// eslint-disable-line
  
    return resString.map(function (value) {
        return value.replace(new RegExp(regex, 'gi'), `<mark class="highlight-search">$&</mark>`);
    }).join(" ");
}

function markSearchTermsIgnoringPluralEndings(terms) {
    for (var element of pluralSuffix) {
        var index = terms.lastIndexOf(element);
        if (index > -1 && index > terms.length - 3) {
            terms = "\\b"+terms.slice(0, index) + "+(" + element + ")?\\b";
            break;
        }
    }
    return terms;
}

function getRegex(searchPhrase) {
    let terms = searchPhrase.match(/-"(?:\\"|[^"])+"|[a-zA-Z0-9\|\+?*\'-’$]+|"(?:\\"|[^"])+"/gi)// eslint-disable-line
    if (terms === null) return null;
    //regex to check if search term is within double quotes
    var doubleQuoteRegex = /("(.*?)")/g ///(^\"[a-zA-Z0-9\+\s]+\"$)/g

    //This list is created because on line 129 the map method can't contain regex test function call
    //as the regex test() calls multiple times on the same global regular expression instance
    //will advance past the previous hence will always return false inside map scope
    var doubleQuotedTermList = terms.filter(e => doubleQuoteRegex.test(e))

    terms = terms.filter(x => x[0] !== '-')

    // regex to find given special characters from the search phrase, if got any spcl character then need to escape those character
    let regexToCheckSpecialCharacter = new RegExp("[[$({)}\\]\\//]", "g");

    terms = terms.map(x => {
        //If search term is within double quotes, then match exact term(s) with boundaries
        //so that entire sentence can be parsed
        if (doubleQuotedTermList.includes(x)) {
            //remove double quotes to get the exact term
            x = x.slice(1, -1);
            x = x.replace('+', '\\+');
            if (regexToCheckSpecialCharacter.test(x)) {
                return escapeRegExp(x);
            }
            return `\\b${escapeRegExp(x)}\\b(?!['’])` // "(?!['’])" this regex is used to exclude these "'`" special character by using negetive lookahead regex
        }

        else {
            return escapeRegExp(x)
                .replace(/^\||^\+|\"/g, '') // eslint-disable-line
                .replace(/\?/g, '[a-zA-Z0-9]{0,}')
                .replace(/\*/g, '[a-zA-Z0-9]{0,}')
                .replace(/\s/g, '\\s{0,}(?:<.+?>){0,}\\s{0,}');
        }
    })

    terms = terms.filter(x => x !== '');
    terms = terms.join('|');

    if (new RegExp("(\\b\\w+[a-zA-Z0-9]("+pluralSuffix.join("|")+")\\b)", "gi").test(terms)) {
        terms = markSearchTermsIgnoringPluralEndings(terms);
    }

    return RegExp(terms, 'gi');
}

function replaceOperator(operator){
    switch(operator){
        case '+':
            return ' && ';
        case '|':
            return ' || ';
        case '-':
            return ' -';
        default:
            return operator;
    }
}

//This is a helper method to parse the search phrase having operators '+,-,| etc..'
function parseSearchPhrase(searchPhrase, separator, pattern)
{
    if(!UTILITIESHELPER.isStringNullorEmpty(searchPhrase))
    {
        searchPhrase = searchPhrase.replace(/\s+/g, "");
        if(searchPhrase.includes(separator) && RegExp(pattern, 'gi').test(searchPhrase)){
            let splitArray = searchPhrase.split(separator);
            if (splitArray.length > 1) {
                searchPhrase = `${splitArray[0].trim()}${replaceOperator(separator)}${splitArray[1].trim()}`;
            }
        }
    }
    return searchPhrase;
}

function IsSearchPrefixWithWildcards(searchPhrase) {
    if (!UTILITIESHELPER.isStringNullorEmpty(searchPhrase)) {
        if (REGEX_PATTERN.wildcards.test(searchPhrase)) {
            return true;
        }
    }
    return false;
}

function getCollectionSearchLimitFromAppSettings() {
    return window.DART.ENV.REACT_APP_COLLECTION_SEARCH_LIMIT
    //return RETRIEVALSERVICES.retreiveCollectionSearchLimit();//This is causing unnesccssary call, we are reading from client appsettings.token.js
}

function SanitizeSpecialCharacters(searchPhrase) {
    if (!UTILITIESHELPER.isStringNullorEmpty(searchPhrase)) {
        searchPhrase = searchPhrase.replace('/', '\\/');
    }
    return searchPhrase;
}

export default {
    apply,
    fromQueryString,
    hasSearchResults,
    mark,
    parse,
    toHumanReadable,
    toQueryString,
    parseSearchPhrase,
    getCollectionSearchLimitFromAppSettings,
    IsSearchPrefixWithWildcards,
    SanitizeSpecialCharacters
}
