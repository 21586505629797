import React from 'react';
import { usePageContext } from '../../contexts/PageContext';

const RecentSearches = ({ handleClick, recentHistoryList, globalRecentSearches }) => {
    const { context } = usePageContext();
    let styling;
    recentHistoryList= JSON.parse(recentHistoryList)?.filter(mem=>mem.memberfirm===context.memberFirm && mem.knowledgeDomain===context.knowledgeDomain);
    if (globalRecentSearches == null) {
        styling = {
            display: 'none',
        }
    } else if (!globalRecentSearches) {
        styling = {
            boxShadow: '3px 4px 8px #879090b5', maxWidth: '500px', marginTop: '-1px', textAlign: 'initial', background: 'white'
        }
    } else {
        styling = {
            boxShadow: '3px 4px 8px #879090b5', maxWidth: '500px', textAlign: 'initial', background: 'white'
        }
    }

    function RecentSearchClickHandler(searchphrase) {
        handleClick(searchphrase);
    }
    return (
        <div className='recent-search-main' data-testid="recent-search-data">
            <div style={styling}>
                <div>
                    <div className='recent-search-tab'>Recent Searches</div>
                    {recentHistoryList &&
                        recentHistoryList.map((item, index) => {
                            return (
                                <div key={`idx_${index}_${item.search_phrase}`} title={item.search_phrase} onClick={() => RecentSearchClickHandler(item.search_phrase)} className='recent-search-item' style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} >
                                    <span>{item.search_phrase}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

RecentSearches.defaultProps = {
    globalRecentSearches: false
}

export default RecentSearches;
