import React, { useState, useRef } from "react";
import {
    useUserPreferencesContext,
    ACTIONS,
} from "../../contexts/AnnotationsContext";
import Dropdown from '../Common/Dropdown';
import { usePageContextLabels,usePageContext } from "../../contexts/PageContext";

import { useLocation } from "react-router-dom";
import CategoryService from "../../helpers/CategoryServices";
import { useTocContext } from "../../contexts/TocContext";

//const location = useLocation();

function CategoryTrigger({ category }) {

    //Style for backgroundStyle for categoryTrigger
    const backgroundColorStyle = {
        backgroundColor: category.color
    }
    return (
        <div className="an-dd-category-trigger">
            <div className="an-dd-category-chip" style={backgroundColorStyle}></div>
            <div className="an-dd-category-label">{category.label}</div>
        </div>
    )
}

function CategoryItem({ category, selected, onClick, onEditClick, onDeleteClick }) {
    function handleClick() {
        onClick(category);
    }

    let classNames = "an-dd-category-item";
    if (selected.category_guid === category.category_guid) classNames += " ad-dd-category-item--selected";

    //styling for background category color
    const backgroundColorStyle = {
        backgroundColor: category.color
    }

    return (
        <div className={classNames} onClick={handleClick}>
            <div className="an-dd-category-chip" style={backgroundColorStyle}></div>
            <div className="an-dd-category-label">{category.label}</div>
        </div>
    )
}


function CategoryDropdown({ categories, selected, onSelect, onClose, onSave, annotationNumber, bookmarkNumber, selectedCategoryId }) {
    const { getLabel } = usePageContextLabels();
    const [shouldShowDarkOverlay, setshouldShowDarkOverlay] = useState(true);
    const annotationNumbers = annotationNumber;
    let btnRef = useRef();

    function handleSelect(category) {
        onSelect(category);
    }

    function handleCloseClick() {
        setshouldShowDarkOverlay(false);
        onClose();
    }

    function handleSaveClick() {
        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }
        onSave(selected);
    }

    let listItem = categories.filter(a => a.category_guid !== selectedCategoryId);
    const listItems = listItem.map((c, index) => (
        <CategoryItem
            key={`${c.label}-${c.color}`}
            category={c}
            onClick={handleSelect}
            selected={selected}
        />
    ))

    function getTriggerEl() {
        return <CategoryTrigger category={selected} />;
    }

    return (

        <div>
            {shouldShowDarkOverlay &&
                <div className="full-screen-darken-overlay" onClick={handleCloseClick} />
            }
            <div className="ancatdelete-modal">
                <div className="ancatdelete-modal-header">
                    <div className="ancatdelete-modal-title">
                        {getLabel(
                            "hh_ChooseCategoryForHighligh",
                            "Choose a new category for your annotations"
                        )}
                    </div>
                    <button className="an-modal-close" onClick={handleCloseClick} />
                </div>
                <div className="ancatdelete-modal-body">
                    <Dropdown
                        triggerEl={getTriggerEl()}
                        ddClassNames="an-dd"
                        containerClassNames="an-dd-container"
                    >
                        <Dropdown.Panel additionalClassNames="an-dd-panel">
                            <Dropdown.ScrollArea
                                maxHeight="90px"
                                additionalClassNames="an-dd-scroll-area"
                            >
                                {listItems}
                            </Dropdown.ScrollArea>
                        </Dropdown.Panel>
                    </Dropdown>
                </div>
                <div className="ancatdelete-modal-footer">
                    <div className="ancatdelete-Update-text">{getLabel("hh_DeleteUpdate", "Update Category of all related annotations:")}
                        &nbsp; {annotationNumbers} {getLabel("hh_DeleteOutro", "annotation(s).")}  &nbsp; {bookmarkNumber} {getLabel("hh_Bookmark", "bookmark(s)")}</div>
                    <button className="an-modal-save" onClick={handleSaveClick} ref={btnRef}>
                        {getLabel("hh_CategorySave", "SAVE")}
                    </button>
                </div>
            </div>
        </div>
    );
}




function AnnotationDelete({ categories, selected, onUpdate, onDelete, onClose, selectedCategoryid, sidePanelClose, isSidePanelOpen, annotationCount, bookmarkCount }) {
    const { getLabel } = usePageContextLabels();
    const { state: annState, dispatch } = useUserPreferencesContext();
    const [categorydropdown, setCategorydropdown] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(selected);
    const location = useLocation();
    const [shouldShowDarkOverlay, setshouldShowDarkOverlay] = useState(true);
    let btnRef = useRef();
    const { context, getMemberFirmID } = usePageContext(); //eslint-disable-line
    const { setTocAnnotations, setTocIconBookmark } = useTocContext();


    function handleDeleteClick() {
        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }
        onDelete();

    }

    function handleCloseClick() {
        setshouldShowDarkOverlay(false);
        onClose();
    }
    function handleUpdateCategory() {
        setCategorydropdown(true)
    }
    function handleCategorySelect(category) {
        setSelectedCategory(category);
        document.dispatchEvent(new CustomEvent("dd:requestClose"));
    }
    function handleClose() {
        setCategorydropdown(false);
    }
    const handleUpdateAnnotationDelete = async (category) => {
        const categoryDelete = await CategoryService.deleteCategory(location.pathname, selectedCategoryid, getMemberFirmID(), category.category_guid, true);
        if (categoryDelete) {
            dispatch({
                type: ACTIONS.CATEGORY_REMOVE,
                payload: {
                    category_guid: selectedCategoryid,
                }
            });
        }
        var updatedBookmarks = annState?.bookmarks?.map(item => {
            if (item.category_guid === selectedCategoryid) {
                item.category_guid = category.category_guid;
            }
            return item;
        });
        dispatch({
            type: ACTIONS.BOOKMARKS_LOAD,
            payload: updatedBookmarks ? updatedBookmarks : [],
        });
        setTocIconBookmark(prev => {
            return prev.map(item => {
                if (item.category_guid === selectedCategoryid) {
                    item.category_guid = category.category_guid;
                }
                return item;
            });
        });
        var updatedAnnotations = annState?.annotations?.map(item => {
            if (item.category_guid === selectedCategoryid) {
                item.category_guid = category.category_guid;
            }
            return item;
        });
        dispatch({
            type: ACTIONS.ANNOTATIONS_LOAD,
            payload: updatedAnnotations ? updatedAnnotations : [],
        });
        setTocAnnotations(prev => {
            return prev.map(item => {
                if (item.category_guid === selectedCategoryid) {
                    item.category_guid = category.category_guid;
                }
                return item;
            });
        });
        handleCloseClick();
        dispatch({ type: ACTIONS.MODAL_HIDE });
        if (isSidePanelOpen) {
            sidePanelClose();
        }
    }

    return (
        <div >
            {shouldShowDarkOverlay &&
                <div className="full-screen-darken-overlay" onClick={handleCloseClick} />
            }
            <div className="annotationdelete-modal">
                <div className="annotationdelete-modal-header">
                    <div className="an-modal-title">
                        {getLabel(
                            "hh_DeleteCategory",
                            "Delete Category"
                        )}
                    </div>
                    <button className="annotationdelete-modal-close" onClick={handleCloseClick} />
                </div>
                <div
                    className="annotationdelete-modal-body">
                    {getLabel("hh_DeleteIntro", "You are about to permanently delete {0} annotations.").replace('{0}', annotationCount)}
                    &nbsp; {bookmarkCount}  {getLabel("hh_Bookmark", "bookmark(s)")}
                    <div className="annotationdelete-label" > {getLabel("hh_DeleteIntroProceed", " Would you like to proceed?")} </div>

                </div>
                <div className="annotation-modal-delete">
                    <button className="an-modal-deletepopup" onClick={handleDeleteClick} ref={btnRef}>
                        {getLabel("hh_DeleteButton", "Delete")}
                    </button>
                </div>
                <div className="annotationdelete-modal-update" onClick={handleUpdateCategory}>
                    <p> {getLabel("hh_DeleteUpdate", "Update Category of all related annotations:")} {annotationCount}
                        {getLabel("hh_DeleteOutro", "annotation(s).")}
                        &nbsp; {bookmarkCount}  {getLabel("hh_Bookmark", "bookmark(s)")} </p>
                </div>
            </div>
            {categorydropdown && (
                <CategoryDropdown
                    categories={categories}
                    selected={selectedCategory}
                    onSelect={handleCategorySelect}
                    onClose={handleClose}
                    onSave={handleUpdateAnnotationDelete}
                    annotationNumber={annotationCount}
                    bookmarkNumber={bookmarkCount}
                    selectedCategoryId={selectedCategoryid}
                />
            )}
        </div>
    );
}
export default AnnotationDelete;