import React, { useEffect,useMemo,useCallback} from 'react';
import { useNavigate, useLocation, useParams, useMatch } from 'react-router-dom';
import { get } from 'lodash';
import { logs, page } from '../../helpers/log';
import { usePageContext, usePageContextSettings } from '../../contexts/PageContext';
import { useTocContext } from '../../contexts/TocContext';
import RETRIEVALSERVICES from '../../services/rp-service';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import Loading from '../Loading/Loading';
import ManualArticle from './ManualArticle';
import Publication from '../Publication';
import TableOfContentsItem from '../Publication/TableOfContentsItem';
import TOCHELPERS from '../../helpers/TocHelpers';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import RecentViewedService from '../../helpers/RecentlyViewedServices';
import { ACTIONS, useUserPreferencesContext } from '../../contexts/AnnotationsContext';
import BookmarkService from '../../helpers/BookmarkServices';
import ANNSERVICES from '../../helpers/AnnotationServices';
import { ErrorCodes, ErrorMessages } from '../Constants/Errors';

const Manual = () => {
    const navigate = useNavigate();
    const { currentTier,currentIndustry, context, getMemberFirmID } = usePageContext();
    const { memberFirm, language, knowledgeDomain, pageControllerType, manualTitle, manualGUID} = useParams();
    const path = "/:memberFirm/:language/:knowledgeDomain/:pageControllerType/:manualTitle/:manualGUID";
    const routeInfo = useMatch(path);
    const  isExact = routeInfo ? routeInfo.pattern.end : false;
    const url = `/${memberFirm}/${language}/${knowledgeDomain}/${pageControllerType}/${manualTitle}/${manualGUID}`;
    const selectedItemTitle = context.pageSelectedContentItemTitle;
    const { pubInfo, setPubInfo, toc, setToc, setTocAnnotations, setSelectedItemTOC, setTocIconBookmark } = useTocContext();
    const { setSearchResults, setFilteredSearchResults, setActiveFilters,setTocControl } = useSearchResultContext();//eslint-disable-line
    const { isAnnotationsEnabled, isBookmarksEnabled, isRestrictedAccessMemberfirm} = usePageContextSettings();
    const { state: annState, dispatch } = useUserPreferencesContext();
    const location = useLocation();


    useEffect(() => {
        const retreiveAnnotationList = async (_url) => {
            try {
                setTocAnnotations([]);
                const annotations = await ANNSERVICES.fetchAnnotations(location.pathname, getMemberFirmID());
                if (annotations) {
                    setTocAnnotations(annotations);
                }
            }
            catch (err) {
                logs.error(page.Annotation, "Manual", ErrorMessages.annotationTOC, err,{eventId:ErrorCodes.Annotation});
            }
        }

        const retreiveBookmarkList = async (_url) => {
            try {
                setTocIconBookmark([]);
                const bookmarks = await BookmarkService.fetchBookmarks(location.pathname, getMemberFirmID());
                if (bookmarks) {
                    setTocIconBookmark(bookmarks);
                }
            }
            catch (err) {
                logs.error(page.Annotation, "Manual", ErrorMessages.bookmarkTOC, err,{eventId:ErrorCodes.Bookmark});
            }
        }

        const retrieveManual = async () => {
            try {
                setToc({});
                const res = await RETRIEVALSERVICES.retrieveManual();
                logs.debug(page.Manual, 'retrieveManual', "retrieveManual:res", res);
                if (res.tableOfContents?.subItems?.length>0) {
                    const hashedToc = TOCHELPERS.buildTocHashes(res.tableOfContents);
                    const selectedTocItem = TOCHELPERS.getSelectedTocNode(hashedToc);
                    setToc(hashedToc);
                    setSelectedItemTOC({item: selectedTocItem});
                    setPubInfo(res);
                    //We only log this if we are landing on the publication root (not on an article!)
                    if (selectedItemTitle === undefined) {
                        logs.trackPageView({
                            name: `${pageControllerType} - ${manualTitle}`,
                            properties: {
                                memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                                serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                                level: UTILITIESHELPER.getSessionStorage('Level')
                            }
                        })
                    }
                }
                else {
                    //Whole-Manual fallback (for when the entire TOC is not available)
                    navigate(CONTEXTHELPERS.getFallbackUrl(window.location.href,false));
                }
            }
            catch (err) {
                logs.error(page.Annotation, "Manual", ErrorMessages.ManualPublication, err,{eventId:ErrorCodes.Annotation});
                //Whole-Manual fallback (for when the entire TOC is not available)
                navigate(CONTEXTHELPERS.getFallbackUrl(window.location.href));
            }
        }

        if (isAnnotationsEnabled() && !isRestrictedAccessMemberfirm()) {
            retreiveAnnotationList(url);
        }

        if (isBookmarksEnabled() && !isRestrictedAccessMemberfirm()) {
            retreiveBookmarkList(url);
        }

        retrieveManual();

        return () => {
            //Unload Entire Manuals
            setToc({});
            setPubInfo({});
            sessionStorage.removeItem('searchPhraseParam');
            setSearchResults([]);
            setFilteredSearchResults([]);
            setActiveFilters([]);
            //setTocControl([]);
        }       
        //eslint-disable-next-line
    }, [manualGUID, language, memberFirm, currentTier, currentIndustry])


    const renderTocBody = useCallback((contextTOC) => {
        if (!contextTOC || !contextTOC?.subItems?.length) {
            return null;
        }
        return contextTOC.subItems.map((subItem, index) => {
            return (
                <TableOfContentsItem
                    key={`TOC_${subItem.id}_${index}`}
                    item={subItem}
                />
            )
        });
    }, []);
    const pageProps = useMemo(() => ({
        basePath: path,
        currentAsOfDate: get(pubInfo, 'current_date', ''),
        hasAllTocDataLoaded: !!toc?.subItems,
        isExact,
        navTitle: get(toc, 'nav_title', ''),
        preface: get(toc, 'abstract', '') + get(toc, 'notices', ''),
        pubData: pubInfo,
        pubLandingTitle: get(toc, 'title', ''),
        pubLandingUrl: url,
        publicationType: get(pubInfo?.currentPageContext, 'pageType', ''),
        renderArticle: (props) => {
            return <ManualArticle {...props} />;
        },
        tocBody: renderTocBody(toc),
        url,
    }), [pubInfo,toc,renderTocBody]);

    if (!toc || !toc.subItems) {
        return <Loading />;
    }

    return (
        <Publication {...pageProps} />
    )
};

export default Manual
