
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import { useEmbeddedModeContext } from '../../contexts/EmbeddedModeContext';
import { usePageContext, usePageContextLabels, usePageContextSettings } from '../../contexts/PageContext';
import { logs,page } from '../../helpers/log';

import RETRIEVALSERVICES from '../../services/rp-service';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import ArticleResolver from '../Publication/ArticleResolver';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import ARTICLEHELPERS from '../../helpers/ArticleHelpers';
import { useTocContext } from '../../contexts/TocContext';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import { ErrorCodes, ErrorMessages } from '../Constants/Errors';
import RecentViewedService from '../../helpers/RecentlyViewedServices';
import { useRecentViewContext } from '../../contexts/RecentViewContext';


const FolioArticle = (props) => {
    const { hasFetchedArticleData, setHasFetchedArticleData, navTitle } = props;
    const { context,  isNavigatedFromCollectionSearch,
        isSpinePage,searchPhrase,
        isJournalPage, getMemberFirmID } = usePageContext();
    const { guidebookArticle, setGuidebookArticle } = useGuidebookTocContext();
    const { setIsEmbeddedMode } = useEmbeddedModeContext();
    const [embeddedPubData, setEmbeddedPubData] = useState({});
    const [publicationVersions, setPublicationVersions] = useState([])
    const { isCompareEnabled, isRecentlyViewedEnabled,isRestrictedAccessMemberfirm } = usePageContextSettings();
    const navigate = useNavigate();
    const { setSearchResults,setActiveFilters,tocControl, setAreSearchResultsLoading,latestRetrieveSearchResultsTimeStampRef } = useSearchResultContext();
    const {  toc, flattenedToc } = useTocContext();
    const { getMFLabel } = usePageContextLabels();
    const { getGuidebookSectionData } = useGuidebookTocContext();
    const { recentViewHistory, setRecentViewHistory } = useRecentViewContext()

    useEffect(() => {
        // There is a memory leak being caused by fetching the pub after the user navigates away
        // https://dev.to/pallymore/clean-up-async-requests-in-useeffect-hooks-90h
        let isCancelled = false;
        const retrievePublication = async () => {
            try {
                let res = await RETRIEVALSERVICES.retrieveGuidebookPublication();
                logs.debug(page.GuidebookArticle, 'retrieveGuidebookPublication', "retrieveGuidebookPublication:context, res ", context, res);

                if(!isCancelled) {
                    if (res) {
                        // Filter out invalid content by checking if content object has an object_type.
                        res.content.forEach(content => {
                            content.subItems = content.subItems.filter(({ content }) => {
                                return content !== null && content.object_type !== null;
                            });
                        })

                        setGuidebookArticle(res);
                        setHasFetchedArticleData(true);

                        logs.trackPageView({
                            name: `Folio - navTitle - ${context.pageSelectedPublicationTitle} - ${context.pageSelectedContentItemTitle}`,
                            properties: {
                                memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                                serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                                level: UTILITIESHELPER.getSessionStorage('Level')
                            }
                        })
                        if (isRecentlyViewedEnabled() && !isCancelled && !isRestrictedAccessMemberfirm()) {
                            try {
                                // new recently viewed implementation
                                const recentViewUpdatedHistory = await RecentViewedService.updateHistoryObject(window.location.pathname, `${navTitle} / ${context.pageSelectedPublicationTitle} / ${context.pageSelectedContentItemTitle}`, getMemberFirmID(), context, recentViewHistory);
                                setRecentViewHistory(recentViewUpdatedHistory)
                            }
                            catch(error){
                               logs.error(page.GuidebookArticle, "RecentlyViewed", ErrorMessages.recentlyViewed, error,{eventId:ErrorCodes.GuidebookArticle});
                            }
                        }
                    }
                    else {
                        //we assume here that the article was not available for this MF, so we WH UP to current publication in the folio (will WH from there if that does not exist)
                        navigate(`/${context.memberFirm}/${context.language}/${context.knowledgeDomain}/folio/${context.pageFileName}?wormhole=true`);
                    }

                    if (context.pageSelectedSection === "guidance") {
                        //TODO: Perhaps we should also ONLY be loading versions for guidance items?
                        //retrievePubVersions();

                        let embeddedRes = await RETRIEVALSERVICES.retrieveEmbedded();
                        if (embeddedRes) {
                            setEmbeddedPubData(embeddedRes);
                        }
                    }
                }
            }
            catch (err) {
                logs.error(page.GuidebookArticle, 'GuidebookArticle', ErrorMessages.guidebookArticle, err,{eventId:ErrorCodes.GuidebookArticle});
                //we assume here that the article was not available for this MF, so we WH UP to current publication in the folio (will WH from there if that does not exist)
                navigate(`/${context.memberFirm}/${context.language}/${context.knowledgeDomain}/folio/${context.pageFileName}?wormhole=true`);
            }
        }

        const retrievePubVersions = async () => {
            try {
                setPublicationVersions([]);
                const res = await RETRIEVALSERVICES.retrieveManualPublicationVersions();
                logs.debug(page.GuidebookArticle, 'retrieveManualPublicationVersions', "retrieveManualPublicationVersions:res ", res);
                setPublicationVersions(res.data)
            } catch {
                setPublicationVersions([]);
            }
        };

        //Only load versions and content if we have a selected item...
        if ((context.pageSelectedContentItemGUID && context.pageSelectedContentItemGUID.includes("GUID")) ||
            context.pageSelectedSection === "alerts" && context.pageSelectedPublicationGUID && context.pageSelectedPublicationGUID.includes("GUID")
        ) {
            //Only load versions when we are not in alerts & announcements
            if (isCompareEnabled() && context.pageSelectedSection !== "alerts" && !isCancelled) {
                retrievePubVersions();
            }
            if(!isCancelled) {
                retrievePublication();
            }
        }
        else {
            setHasFetchedArticleData(true);
        }

        return () => {
            isCancelled = true;
            setEmbeddedPubData({});
            setGuidebookArticle({});
            setHasFetchedArticleData(false);
        };
    }, [context.pageSelectedContentItemGUID,context.pageSelectedPublicationGUID]) //context.pageSelectedPublicationGUID, 


    const getSecondMostRecentVersion = () => {
        try {
            return publicationVersions?.length > 1 ? publicationVersions[1]?.version : null;
        }
        catch {
            return null;
        }
    }


    //ctrl+shift+e listener
    useEffect(() => {
        var map = { "Control": false, "Shift": false, "E": false };

        const handleDocumentKeyPress = (ev) => {
            if (ev.key in map) {
                map[ev.key] = true;
                if (map["Control"] && map["Shift"] && map["E"]) {
                    setIsEmbeddedMode((prevVal) => !prevVal);
                }
            }
        }

        const handleDocumentKeyUp = (ev) => {
            map = { "Control": false, "Shift": false, "E": false };
        }

        window.addEventListener('keydown', handleDocumentKeyPress);
        window.addEventListener('keyup', handleDocumentKeyUp);

        return () => {
            window.removeEventListener('keydown', handleDocumentKeyPress);
            window.removeEventListener('keyup', handleDocumentKeyPress);
            setIsEmbeddedMode(false);
        }
    }, [window.location.href])

    useEffect(() => {
        if(isNavigatedFromCollectionSearch && !UTILITIESHELPER.isNullOrUndefined(flattenedToc) && searchPhrase){
            const retrieveSearchResults = async () => {
                const currentReqTime = Date.now();
                latestRetrieveSearchResultsTimeStampRef.current = currentReqTime;
    
                if( UTILITIESHELPER.isArrayNullorEmpty(flattenedToc)
                    || !Array.isArray(flattenedToc) || flattenedToc?.length === 0
                    || currentReqTime !== latestRetrieveSearchResultsTimeStampRef.current)
                    return ;
    
                try {
                    setAreSearchResultsLoading(true);
                    setSearchResults([]);
                    setActiveFilters([]);

                    let results = tocControl.find(x => decodeURI(x.href) == decodeURI(context.pageBaseURL))?.searchResult
                    if (!UTILITIESHELPER.isArrayNullorEmpty(results)) {
                        let searchresults = results.filter(x => x.highlightsCount > 0);
                        const extendedResults = ARTICLEHELPERS.mapSearchResultsToSelectedToc(searchresults,context,isJournalPage,flattenedToc,isSpinePage,getMFLabel,toc,getGuidebookSectionData);

                        if(!UTILITIESHELPER.isArrayNullorEmpty(extendedResults)){
                            setSearchResults(extendedResults);
                        }
                        else{
                            setSearchResults([]);
                        }
                    }
                    else{
                        setSearchResults([]);
                    }
                    setAreSearchResultsLoading(false);
                }
                catch (err) {
                    logs.error(page.Publication, 'PublicationSearch', ErrorMessages.publicationSearch, err, { memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'), knowledgeDomain: context.knowledgeDomain,eventId:ErrorCodes.Publication });
                    navigate(CONTEXTHELPERS.getFallbackUrl(window.location.href));
                }
            }
    
            retrieveSearchResults()
        }
    }, [isNavigatedFromCollectionSearch,flattenedToc]);


    if (!guidebookArticle || !guidebookArticle?.content) {

        return (
            <div className="article-container cf-right shimmerArticleCard shimmerArticleWrapper">
                <h2 className="article-title shimmerArticleCardBG shimmerArticleCardTitleLine"><span className="ph"></span></h2>
                <div className="article-content-container">
                    <div className="article">
                        <div className="body">
                            <div className="shimmerArticleCardBG shimmerArticleCardIntroLine"></div>
                            <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                            <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                            <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                            <div className="shimmerArticleCardBG shimmerArticleCardContentLine shimmerArticleCardContentLineEnd"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <ArticleResolver
            {...props}
            pubData={guidebookArticle}
            embeddedPubData={embeddedPubData}
            hasFetchedArticleData={hasFetchedArticleData}
            setHasFetchedArticleData={setHasFetchedArticleData}
            secondMostRecentVersion={getSecondMostRecentVersion()}
            enableArticleTag={true}
        />
    )
}

export default FolioArticle;
