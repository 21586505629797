import React, { useState } from 'react';
import EmbeddedArticleBlockSection from './EmbeddedArticleBlockSection';

export const EmbeddedArticleBlock = (props) => {
    const [blockOpen, toggleBlock] = useState(false);
    const content = props.content;

    return (
        <div className={blockOpen ? "expandable-article-block open" : "expandable-article-block"}>
            <button onClick={() => toggleBlock((prevVal) => !prevVal)}><h3 className="jumplink-5">{content.title}</h3></button>
            {content.subItems.map((subItem, i) => {
                return (<EmbeddedArticleBlockSection key={subItem.id + i} subItem={subItem} isQnA={content.title === "Q&As"} isObjective={content.title === "Audit Objectives"} />)
            })}
        </div>
    )


}

export default EmbeddedArticleBlock;
