
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import HTMLContent from '../Common/HTMLContent';
import ShowIf from '../Common/ShowIf';
import ArticleTag from './ArticleTag';

const ArticleTitle = ({ title, className, style, enableArticleTag, shouldBeFixed }) => {
    // Variables to handle search <em></em> react element
    const [titleContent, setTitleContent] = useState('');
    const [isSearchResult, setIsSearchResult] = useState(false);
    const [reactElement, setReactElement] = useState();
    // End variables to handle search <em></em> react element

    // Use effect to handle search <em></em> react element
    useEffect(() => {
        if (title instanceof Array) {
            setTitleContent(title[0])
            setIsSearchResult(true)
            setReactElement(title[1])
        } else {
            setTitleContent(title)
        }
    }, [title]);

    return (
        <>
            <h2 style={style} className={cx("article-title", className)}>
                <HTMLContent>{titleContent}</HTMLContent>
                <ShowIf condition={isSearchResult}>
                    {reactElement}
                </ShowIf>
                <ShowIf condition={enableArticleTag && !shouldBeFixed}>
                    <ArticleTag />
                </ShowIf>
            </h2>
            <ShowIf condition={enableArticleTag && shouldBeFixed}>
                <ArticleTag classNames={"inArticle-tag-fixed"} />
            </ShowIf>
        </>
    )
}

ArticleTitle.defaultProps = {
    enableArticleTag: false,
    shouldBeFixed: false
}

export default ArticleTitle;
