import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserPreferencesContext } from "../../contexts/AnnotationsContext";
import { usePageContext, usePageContextLabels } from "../../contexts/PageContext";
import AnnotationPanelDropdown from "./AnnotationPanelDropdown";
import Annotation from "../Annotations/Annotation";
import cx from 'classnames';
import { useGuidebookTocContext } from "../../contexts/GuidebookTocContext";
import { useArticleContext } from "../../contexts/ArticleContext";


export const AnnotationPanel = ({
   id,
   shouldBeFixed,
   articleAnnotations
}) => {
    const navigate = useNavigate();
    const { state: annState, selectedAnnotation, setSelectedAnnotation,selectedId,setSelectedId } = useUserPreferencesContext();
    const { memberFirm, language, knowledgeDomain, setAnnotationPanelOpen } = usePageContext();
    const { getLabel } = usePageContextLabels();
    const [annotationCategories, setAnnotationCategories] = useState(annState?.categories?.filter(item => !item.is_specific_to_bookmark));
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedAnnotationType, setSelectedAnnotationType] = useState("viewAll");
    const [isAnnotationList,setIsAnnotationList]= useState(false);
    const [selectedAnnotationList, setSelectedAnnotationList] = useState(articleAnnotations);
    const [count, setCount] = useState();
    const shouldBeFix = shouldBeFixed;
    const [isAllCategorySelected, setIsAllCategorySelected] = useState(false);
    const [containsInvalidAnnotations,setContainsInvalidAnnotations] = useState(false);//eslint-disable-line
    const [containsDeletedAnnotations,setContainsDeletedAnnotations] = useState(false);//eslint-disable-line
    const { guidebookArticle } = useGuidebookTocContext();
    const { articleVersion } = useArticleContext();

    useEffect(() => {
        if (JSON.stringify(selectedAnnotationList) !== JSON.stringify(annState.annotations)) {
              setSelectedAnnotationList(annState.annotations);
              setIsAnnotationList(prevVal => !prevVal);
        }
       // }
        // if (annotateTerms && selectedAnnotation !== 0 && selectedAnnotation !== undefined) {
        //     const payload = {
        //         id: selectedAnnotation.annotation_guid,
        //     };
        //     setSelectedAnnotation(payload);
        // }
    }, [annState.annotations]);

    useEffect(() => {
        let category = annState?.categories?.filter(item => !item.is_specific_to_bookmark);
        if (JSON.stringify(category) !== JSON.stringify(annotationCategories)) {
        setAnnotationCategories(category ? category : []);
        }
    }, [annState.categories]);


    useEffect(() => {
        const resultsContainer = document.querySelector('.article-content-container');
        if (resultsContainer) {
        if (selectedAnnotation) {
            const resultsContainer = document.querySelector('.article-content-container');
            if (resultsContainer) {
                const annotationsHighlighter = resultsContainer?.querySelectorAll('.highlighted');
                const items = Array.from(annotationsHighlighter);
                const highlighterDOM = items.find((obj) => obj.id === selectedAnnotation.annotation_guid);
                if (highlighterDOM) {
                    const articleContainer = document.querySelector('.article-container');
                    let articleOffset = articleContainer.getBoundingClientRect().top;
                    //Removed the hardcoded value
                    let offset = highlighterDOM.getBoundingClientRect().top - articleOffset;
                    window.scrollTo({
                        top: offset,
                        left: 0,
                        behavior: "smooth",
                    });
                    setSelectedId(selectedAnnotation.annotation_guid);
                }
            }
            }
        }
    }, [selectedAnnotation]);//eslint-disable-line

    useEffect(() => {
        var filterAnnotations = annState.annotations;
        if (selectedCategory && selectedCategory.length > 0) { //Filter by Category
            filterAnnotations = filterAnnotations.filter(item => selectedCategory.includes(item.category_guid));
        }
        if (selectedAnnotationType !== "viewAll") {
            if (selectedAnnotationType === "Comments") {
                filterAnnotations = filterAnnotations.filter(item => item.annotation_type === "Comment");
            }
            else if (selectedAnnotationType === "Highlights") {
                filterAnnotations = filterAnnotations.filter(item => item.annotation_type === "Highlight");
            }
        }
        setSelectedAnnotationList(filterAnnotations);
        setCount(filterAnnotations.length);
    }, [selectedAnnotationType, selectedCategory,isAnnotationList]);

    // function isCategorySelected(guid) {
    //     let selected = false;
    //     if (selectedList !== null) {
    //         selectedList.forEach((element) => {
    //             if (element.category_guid === guid) {
    //                 selected = true;
    //             }
    //         });
    //     }
    //     return selected;
    // }
    function handleCategorySelect(category) {
        if (category.category_guid !== "cat0") {
            setIsAllCategorySelected(false);
            if (selectedCategory.includes(category.category_guid)) {
                let categories = selectedCategory.filter(ele => ele !== category.category_guid);
                setSelectedCategory(prev => [...categories]);

            } else {
                setSelectedCategory(prev => [...prev, category.category_guid]);
            }
        } else {
            setIsAllCategorySelected(prev => {
                if (prev) {
                    setSelectedCategory(prev => []);
                } else {
                    let categories = annotationCategories.map(ele => ele.category_guid);
                    setSelectedCategory(prev => [...categories]);
                }
                return !prev
            });
        }
        document.dispatchEvent(new CustomEvent('dd:requestClose'));
    }

    // function handleCheckboxClick(item) {
    //     if (isCategorySelected(item.category_guid)) {
    //         removeCategoryFromSelectedList(item);
    //     }
    //     else {
    //         addCategoryToSelectedList(item);
    //     }
    // }

    // function addCategoryToSelectedList(item) {
    //     var newArray = [];

    //     selectedList.forEach((_item) => {
    //         newArray.push(_item);
    //     });
    //     newArray.push(item);
    //     setSelectedList(newArray);
    // }

    // function removeCategoryFromSelectedList(item) {
    //     var newArray = [];
    //     selectedList.forEach((selectedItem) => {
    //         if (item.category_guid !== selectedItem.category_guid) {
    //             newArray.push(selectedItem);
    //         }
    //     });
    //     setSelectedList(newArray);
    // }

    // function accessibleCheckbox(e, item) {
    //     if (e.key === "Enter") {
    //         handleCheckboxClick(item);
    //     }
    // }

    function accessibleCheck(e, type) {
        if (e.key === "Enter") {
            setSelectedAnnotationType(type);
        }
    }

    function navigateToDashboard(e) {
        e.preventDefault();
        navigate(`/${memberFirm}/${language}/${knowledgeDomain}/dashboard/annotations`);
    }

    return (
        <>
            <div className={
                cx({
                    'annotation-panel-wrapper slide-in': true,
                    'annotation-panel-wrapper annotation-panel-fixed annotations-panel-fixed-adjust-height': shouldBeFix
                })} >
                <div className="annotation-panel">
                    <div className="annotation-panel-header">
                        <div className="d-flex">
                            {containsInvalidAnnotations === true &&
                                <div className='an_annot-panel-warning'></div>
                            }
                            {containsDeletedAnnotations === true &&
                                <div className='an_annot-deleted'></div>
                            }
                            <p>
                                {getLabel("hh_Annotations", "Annotations")}
                            </p>
                        </div>

                        <button
                            className="annotation-panel-annotate"
                            title={getLabel("hh_Dashboad", "Dashboard")}
                            onClick={(e) => { navigateToDashboard(e) }}
                        ></button>
                        <button
                            className="annotation-panel-close"
                            title={getLabel("g_Close", "Close")}
                            onClick={() => {
                                setAnnotationPanelOpen(false);
                                setSelectedAnnotationType("");
                                setSelectedId();
                                setSelectedAnnotation(0)
                            }}
                        ></button>
                    </div>
                    <div className="annotation-panel-heading-body">
                        <p>{getLabel("hh_AnnotationsIntro", "To <b>add an annotation</b>, highlight the text and select the highlight and comment icon")}</p>
                    </div>
                    <div className="annotation-panel-dropdown-categories">
                        <AnnotationPanelDropdown
                            categories={annotationCategories}
                            selected={selectedCategory}
                            onSelect={handleCategorySelect}
                            isAllCategorySelected={isAllCategorySelected}
                        />
                    </div>
                    <div className="ann-sidemenu-radio-container">
                        <div className="checkmarks-container-row" onClick={() => setSelectedAnnotationType("viewAll")} onKeyDown={(e) => accessibleCheck(e, "viewAll")} tabIndex={0}>
                            <input id="annotation-view" type="radio" checked={selectedAnnotationType === "viewAll"} tabIndex={1} />
                            <label htmlFor="annotation-view" className="radio-categories"> {getLabel("hh_AnnotationsViewAll", "View All")}</label>
                        </div>
                        <div className="checkmarks-container-row" onClick={() => setSelectedAnnotationType("Highlights")} onKeyDown={(e) => accessibleCheck(e, "Highlights")} tabIndex={0}>
                            <input id="annotation-highlight" type="radio" checked={selectedAnnotationType === "Highlights"} tabIndex={1} />
                            <label htmlFor="annotation-highlight" className="radio-categories"> {getLabel("hh_AnnotationsViewHighlightsOnly", "Highlights Only")}</label>
                        </div>
                        <div className="checkmarks-container-row" onClick={() => setSelectedAnnotationType("Comments")} onKeyDown={(e) => accessibleCheck(e, "Comments")} tabIndex={0}>
                            <input id="annotation-comment" type="radio" checked={selectedAnnotationType === "Comments"} tabIndex={1} />
                            <label htmlFor="annotation-comment" className="radio-categories">  {getLabel("hh_AnnotationsViewComentsOnly", "Comments Only")}</label>
                        </div>
                    </div>
                    <div className={cx({ "annotation-panel-body": true, "height": shouldBeFix })} >
                        {selectedAnnotationList && selectedAnnotationList.length > 0 && selectedAnnotationList.sort((a, b) => a["range_position"].end > b["range_position"].end ? 1 : -1).map((item, index) => (
                            <Annotation
                                key={index}
                                category_guid={item.category_guid}
                                comment={item.comment}
                                content={item.content}
                                id={item.annotation_guid}
                                range={item.range}
                                rawContent={item.raw_content}
                                date={(item.created_date || new Date())}
                                active={item.annotation_guid === selectedId}
                                isAnnotationsVersionUpdated={item.version !== (guidebookArticle?.version ?? articleVersion)}
                                onClick={() => {
                                    setSelectedAnnotation(item);
                                }}
                            />
                        ))}
                    </div>
                    <div className="annotation-panel-footer">
                        <div className="annotation-article-count">
                            <strong>{count}</strong> {getLabel("hh_ArticleAnnotations", "Article Annotations")}
                        </div>
                        <button className="btn annotation-my-dashboard"
                            onClick={(e) => { navigateToDashboard(e) }}>{getLabel("hh_MyDashboard", "MY DASHBOARD")}</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AnnotationPanel;
