
import React, {useEffect,useState} from 'react';
import cx from 'classnames';
import ShowIf from '../Common/ShowIf';
import ArticleTOC from '../Article/ArticleTOC';
import ArticleTitle from '../Article/ArticleTitle';
import SidebarTocTitle from './SidebarTocTitle';
import UTILITIESHELPER from "../../helpers/UtilitiesHelper";
import { PAGE_CONTROLLER_TYPE } from "../Constants/Constants";

const FixedNavArticle = ({
    isWideMode, setIsWideMode,
    isJumpLinksOpen, setIsJumpLinksOpen,
    title,
    navTitle,
    pubLandingUrl,
    shouldBeFixed,
    pubData,
    showInArticleTOC,
    removeInArticleTOC,
    enableArticleTag,
    publicationType
}) => {
    const [height,setHeight] =useState(0);
    
    useEffect(()=>{
        var navHeight=0;
        let previewHeight=document.getElementsByClassName("preview-warning")[0]?.clientHeight
        if(!previewHeight)
        {
            previewHeight=0; 
        }
        let heightOfCrum=document.getElementsByClassName("crumbtrail")[0]?.clientHeight;

        navHeight=previewHeight+ heightOfCrum;
        setHeight(navHeight);
    },[])
    
    return (
        <div
            className="fixed-nav-article" style={{
                top: height}}>
            <div className="d-flex flex-wrap justify-between m-auto fixed-nav-article-main">
                <div
                    className="d-flex fixed-nav-article-main-wrapper">
                    <ShowIf condition={!isWideMode}>
                        <div
                            className="side-pannel-heading side-pannel-heading-article-fixed-nav"
                        >
                            <div className="sidebar-toc-title-and-icon-x-outer-container-fixed">
                                <SidebarTocTitle
                                    shouldBeFixed={shouldBeFixed}
                                    navTitle={navTitle}
                                    pubLandingUrl={pubLandingUrl}
                                    isFixed
                                    publicationType={publicationType}
                                />
                            </div>
                            <button className={cx("toc-item-icon-collapse-fixednav-article",{"nav-article": publicationType!==PAGE_CONTROLLER_TYPE.folio})}
                                onClick={() => setIsWideMode(true)}></button>
                        </div>
                    </ShowIf>
                </div>
                <div
                    className={
                        cx("article-text-and-title-container",
                            { "article-text-and-title-container-wide-mode": isWideMode }
                        )}
                >
                    <ShowIf condition={!removeInArticleTOC}>
                        <ArticleTOC
                            isOpen={isJumpLinksOpen}
                            setIsOpen={setIsJumpLinksOpen}
                            pubData={pubData}
                            classNameContainer={cx({ 'invisible': !showInArticleTOC })}

                        />
                    </ShowIf>
                    <ArticleTitle
                        title={title}
                        className="article-title-fixed"
                        style={removeInArticleTOC ? { paddingLeft: 0 } : {}}
                        enableArticleTag={enableArticleTag}
                        shouldBeFixed={shouldBeFixed}
                    />
                </div>

            </div>
        </div>
    )

};

FixedNavArticle.defaultProps = {
    setIsWideMode: () => { },
    setIsJumpLinksOpen: () => { },
    title: '',
    showInArticleTOC: true,
    removeInArticleTOC: false,
    enableArticleTag: false
}

export default FixedNavArticle;
