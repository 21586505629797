import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const JumpLinkMain = ({ title, href, jumpClass, jumpClickHandler }) => {
    return (
        <li className="jumplink-container-main">
            <a
                href={href}
                data-jumplink={jumpClass}
                onClick={(e) => jumpClickHandler(e, jumpClass)}
            > {ReactHtmlParser(title)} </a>

        </li>
    )
}

export default JumpLinkMain