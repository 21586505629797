import SearchHelpers from "./SearchHelpers";

const getTextWithSearchHighlight = (markup, searchText) => {
  let htmlContent = '';
  if (markup === null) return "";
  if (!markup || !/[a-zA-Z0-9]/g.test(searchText)) return markup ?? "";

  if (typeof markup === "string" || markup instanceof String) {
    htmlContent = markup;
  } else {
    htmlContent = `${markup}`;
  }
 
  const markHtml = SearchHelpers.apply(htmlContent ?? "", searchText);
  return markHtml;
};

export default getTextWithSearchHighlight;
