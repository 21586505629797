import React, { useState, useEffect, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { replace } from 'lodash'
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import { useTocContext } from '../../contexts/TocContext';
import ShowIf from '../Common/ShowIf';

export const checkIsSelected = (itemHref = '', currentPathname = '') => {
    return currentPathname.endsWith(itemHref);
}

const TocItemRelatedContent = ( props ) => {
    const {
        basePath,
        includeRoot,
        isRelatedLinksPage,
        publicationType,
        sectionData,
        subItems,
        shouldHighlightSelected
    } = props;

    const { context } = usePageContext();
    const { getMFLabel } = usePageContextLabels();
    const { selectedItemTOC, setSelectedItemTOC } = useTocContext();
    const location = useLocation();
    const [isSelected, setIsSelected] = useState(checkIsSelected(publicationType.Key, location.pathname) && !isRelatedLinksPage);

    useEffect(() => {
        if (isSelected) {
            //When the selectedItemTOC changes, we need to make sure its unselected...
            if (!isRelatedLinksPage && !!selectedItemTOC && 'item' in selectedItemTOC && !!selectedItemTOC.item) {
                //If the selectedItemTOC is not the current one, then we need to unslect it...
                if (!checkIsSelected(publicationType.Key, location.pathname)) {
                    setIsSelected(false);
                }
            }
        }
        else {
            if (!isRelatedLinksPage && !!selectedItemTOC && 'item' in selectedItemTOC && !!selectedItemTOC.item) {
                //If the selectedItemTOC is the current one, then we need to unslect it...
                if (checkIsSelected(publicationType.Key, location.pathname)) {
                    setIsSelected(true);
                }
            }
        }
    }, [selectedItemTOC]);

    useEffect(() => {
        //When the page loads, we will see if we need to "select" the current TOC
        if (isSelected && !isRelatedLinksPage) {
            //If there is already a selectedItemToc (then we should be fine to ignore this)
            if (!!selectedItemTOC && 'item' in selectedItemTOC && !!selectedItemTOC.item) {
                setIsSelected(false);
            }
            else {
                setSelectedItemTOC({ item: sectionData });
            }
        }
    }, []);

    return (
        <>
            <ShowIf condition={includeRoot}>
                <li className="toc-list-item-related-content d-flex">
                    <div className='toc-item-icon' />
                    <Link to={`${basePath}/related/${publicationType.Description}/${publicationType.Key}`}
                        className={cx("d-flex m-auto sidebar-toc-item-link toc-link-non-expandable", { 'selected-link': isSelected })}
                    >
                        <div
                            className={cx("toc-item-text-container",
                                { "toc-item-text-container-selected": isSelected && shouldHighlightSelected }
                            )}
                            onClick={() => {                            
                                if (!isSelected) {
                                    setSelectedItemTOC({ item: sectionData });
                                    if (!isRelatedLinksPage) {
                                        setIsSelected(true);
                                    }
                                }
                            }}
                        >
                            {getMFLabel(`lbl_Folio_TitleSectionRelated${replace(publicationType.Description, ' ', '')}`, `publicationType.Description:[lbl_Folio_TitleSectionRelated${replace(publicationType.Description, ' ', '')}]`)}
                        </div>
                    </Link>
                </li>
            </ShowIf>
            <ShowIf condition={isRelatedLinksPage && subItems !== null && subItems.length > 0}>
                <ul className={cx('toc-section-children')}>
                    {subItems?.map((subItem, index) => {
                        return (
                            <li key={index} className="toc-list-item-related-content d-flex">
                                <div className='toc-item-icon' />
                                <Link 
                                    to={`/${context.memberFirm}/${context.language}/link/${subItem.id}`}
                                    className={cx("d-flex m-auto sidebar-toc-item-link toc-link-non-expandable")}
                                    target="_blank"
                                >
                                    <div className={cx("toc-item-text-container")}>
                                        {subItem.title}
                                    </div>
                                </Link>
                            </li>
                        )
                    })} 
                </ul>
            </ShowIf>
        </>
    )
};

TocItemRelatedContent.defaultProps = {
    shouldHighlightSelected: true,
    includeRoot: true,
    isRelatedLinksPage: false,
    subItems: null
}

export default TocItemRelatedContent;

