import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import cx from 'classnames';
import ShowIf from '../Common/ShowIf';
import {  replace } from 'lodash'
import { useTocContext } from '../../contexts/TocContext';
import { usePageContext, usePageContextSettings } from '../../contexts/PageContext';
import TocSearchBadge from '../Publication/TocSearchBadge';
import TocAnnotationBadge from '../Publication/TocAnnotationBadge';
import LINKHELPERS from '../../helpers/LinkHelpers';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import { logs } from '../../helpers/log';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';



export const IsCurrentPublicationOrTopic = (itemID = '', currentPathname = '') => {
    //If there is no GUID in the path, then we have not selected a TOC item
    if (!currentPathname.includes('/GUID-')) {
        return false;
    }
    const currentPathSplit = currentPathname.split('/GUID-');
    //If the current URL is only on a Publication, there will be only one GUID, or none
    if (currentPathSplit.length === 2) {
        return itemID.includes(currentPathSplit[1].split('/')[0]);
    }
    //We only care about the TopicGUID, if this is a spine pub, the topic GUID will still be unique and the same
    return itemID.includes(currentPathSplit[2]);
}

const FolioTableOfContentsItem = (
    props
) => {
    const {
        basePath,
        item,
        shouldHighlightSelected,
        includeSubItems,
        sectionContainsPublications, //Default == true
        isListingPage, //Default == false
        parentGuid, //Starts off NULL
        isPubExpandable
    } = props;

    const itemID = item.id;
    
    const { selectedItemTOC, setSelectedItemTOC, tocIconBookmark, tocAnnotations } = useTocContext();
    const { context } = usePageContext();
    const { isAnnotationsEnabled,isRestrictedAccessMemberfirm } = usePageContextSettings();
    const [isSelected, setIsSelected] = useState(context.pageSelectedContentItemGUID === itemID || (context.pageSelectedContentItemGUID === "" && context.pageSelectedPublicationGUID === itemID));
    const [isExpanded, setIsExpanded] = useState(isPubExpandable && (isListingPage || isSelected || context.pageSelectedPublicationGUID === itemID));
    const isExpandable = (includeSubItems && isPubExpandable && ((Array.isArray(item.subItems) && item.subItems.length > 0) || (Array.isArray(item.children) && item.children.length > 0)));
    const isGuidebook = context.pageControllerType === 'folio';
    const { searchResults } = useSearchResultContext();
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isAnnotationAvailable, setIsAnnotationAvailable] = useState(false);
    
    const clickProps = {
        eventType: "click",
        pageUrl: LINKHELPERS.sanitizeUrl(window.location.href),
        pageType: UTILITIESHELPER.getSessionStorage("PageType"),
        jobTitle: UTILITIESHELPER.getSessionStorage("Level"),
        memberFirm: UTILITIESHELPER.getSessionStorage("MemberFirm"),
        rpMemberFirm: UTILITIESHELPER.getLocalStorage('research-portal-member-firm'),
    };
 
    
    const itemTitle = isListingPage ? item.title : item.nav_title;
    const itemURL = LINKHELPERS.sanitizeUrl(item.url || `${basePath}/${itemTitle}/${itemID}`);

    useEffect(() => {
        setIsSearchActive(!UTILITIESHELPER.isArrayNullorEmpty(searchResults));
    }, [searchResults]);

    useEffect(() => {
        setIsAnnotationAvailable(!UTILITIESHELPER.isArrayNullorEmpty(tocAnnotations));
    }, [tocAnnotations]);

    useEffect(() => {
        if (!!item
            && 'tocHash' in item
            && !!selectedItemTOC
            && 'item' in selectedItemTOC
            && !!selectedItemTOC.item
            && 'tocHash' in selectedItemTOC.item) {

            if (!isExpanded && selectedItemTOC.item.tocHash.includes(item.tocHash) && item.tocHash !== selectedItemTOC.item.tocHash) {
                setIsExpanded(true);
            }
            setIsSelected(item.tocHash === selectedItemTOC.item.tocHash);
        }
        else if (isSelected) {
            setIsSelected(false);
        }
    }, [selectedItemTOC]);

    useEffect(() => {
        //When the page loads, we will see if we need to "select" the current TOC
        if (isSelected) {
            //If there is already a selectedItemToc (then we should be fine to ignore this)
            !!selectedItemTOC && 'item' in selectedItemTOC && !!selectedItemTOC.item
                ? setIsSelected(false)
                : setSelectedItemTOC({ item });
        }
    }, []);

    const getClassNameExpanded = (_isExpandable, _isExpanded, _isListingPage) => {
        if (!_isExpandable) return '';
        return (_isExpanded) ? 'toc-item-icon-expandable-open' : 'toc-item-icon-expandable-closed';
    }


    //BDP:TODO:Figure out how to address this...and if its not working as it should!
    //  deeper items may not have the correct tocHas any more with the new folio api response!
    const isBadged = () => (selectedItemTOC && selectedItemTOC.item) ? (item.tocHash.includes(selectedItemTOC.item.id)) : true;


    function bookmark(_itemURL) {
        return tocIconBookmark?.filter(
            function (data) {
                return LINKHELPERS.getGUID(data.page_url) === LINKHELPERS.getGUID(decodeURI(_itemURL))
            }
        ).length > 0 ? true : false;
    }
    
    function logToAppInsights(tocItem)
    {
        if(tocItem!=="")
        {
            clickProps.selectedTocItem = tocItem;
            if(!UTILITIESHELPER.isStringNullorEmpty(context.pageCollectionName))
            {
                clickProps.collectionName = decodeURI(context.pageCollectionName);
            }
            if(clickProps.pageType!=='')
            {
                logs.event('click', clickProps);
            }
        }
    }
    
    return (
        <li className={cx({ 'expand': isExpandable, 'open': isExpandable && (isExpanded || isListingPage) })}>
            <div className="d-flex toc-item-head">
                <button
                    className={cx('toc-item-icon', getClassNameExpanded(isExpandable, isExpanded, isListingPage),
                        { 'cursor-pointer': isExpandable },
                        { 'bookmark-item-icon': bookmark(itemURL) },
                    )}
                    onClick={() => {
                        if (isExpandable) {
                            setIsExpanded(prevVal => !prevVal);
                        }
                    }}                 
                >
                </button>
                <Link
                    id={itemID}
                    to={itemURL}
                    data-parent-id={parentGuid}
                    className={cx("d-flex m-auto sidebar-toc-item-link", { 'selected-link': isSelected, "toc-link-non-expandable": !isExpandable })}
                    target={LINKHELPERS.TocUrlContainsText(itemURL,'/link/') ? '_blank' : ''}
                >
                    <div
                        className={cx("toc-item-text-container", { "toc-item-text-container-selected": isSelected && shouldHighlightSelected })}
                        onClick={() => {                            
                            if (!isSelected && !isListingPage && !LINKHELPERS.TocUrlContainsText(itemURL,'/link/')) {
                                setSelectedItemTOC({ item: item });
                                setIsSelected(true);
                                UTILITIESHELPER.setSessionStorage('SelectedContent', item.nav_title);
                                logToAppInsights(item.nav_title)
                            }
                            if (isExpandable && !isListingPage) {
                                setIsExpanded(prevVal => !prevVal);
                            }
                        }}
                        data-testid="FolioSectionToc-item"
                    >
                        {item?.pre_title} {replace(itemTitle, /&amp;/g, '&')}
                    </div>
                </Link>
                <ShowIf condition={isAnnotationAvailable && isAnnotationsEnabled()  && !isRestrictedAccessMemberfirm() && !isListingPage && !isSearchActive}>
                    <TocAnnotationBadge key={`badge_anno_${itemID}`} tocItem={item} />
                </ShowIf>
                <ShowIf condition={isBadged && isSearchActive}>
                    <TocSearchBadge key={`badge_p_${itemID}`} tocItem={item} />
                </ShowIf>
            </div>
            <ShowIf condition={includeSubItems}>
                <ul className={(isExpanded) ? "show" : "hide"}>
                    {item.subItems?.map((subItem, index) => {
                        return (
                            <FolioTableOfContentsItem
                                key={`TOC_${itemID}_${subItem.id}_${index}`}
                                basePath={`${basePath}`}
                                includeSubItems={!isGuidebook}
                                isListingPage={isListingPage}
                                item={subItem}
                                parentGuid={itemID}
                                sectionContainsPublications={sectionContainsPublications}
                                shouldHighlightSelected={shouldHighlightSelected}
                            />
                        )
                    })}                   
                </ul>
            </ShowIf>
        </li>
    )
};

FolioTableOfContentsItem.defaultProps = {
    expandTocSection: () => { },
    item: null,
    includeSubItems: true,
    isListingPage: false,
    sectionContainsPublications: true,
    sectionFriendlyPath: '',
    shouldHighlightSelected: true,
    parentGuid: null
}

export default FolioTableOfContentsItem;