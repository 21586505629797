
import React from 'react';
import { Link } from 'react-router-dom';

const ListItem = ({href, children}) => {
    return (
        <li className="list-item">
            <Link
                className="list-item-link"
                to={href}
            >
                {children}
            </Link>
        </li>
    )
};

export default ListItem;
