import React, { createContext, useContext, useReducer,useState } from "react";
import UTILITIESHELPER from "../helpers/UtilitiesHelper";

export const ResearchAssistantContext = createContext();
export const useResearchAssistantContext = () => useContext(ResearchAssistantContext);

export const ACTIONS = {
    RA_LOAD: "ra::load",
    RA_INFO_INDEX: "ra::infoindex",
    RA_ERROR:"ra::ra_error",
    RA_HIDE_ERROR:"ra::ra_hide_error"
};

const initialState = {
    memberFirm: '',
    memberFirmId :0,
    language: '',
    knowledgeDomain:'',
    tier:{},
    tierSelected:[],
    selectedInfoIndex: null,
    errorType:'',
    hasError:false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.RA_LOAD:
            console.log('RA_ActionHit',action);
            return { ...state, memberFirm: action.payload.memberFirm, memberFirmId: action.payload.memberFirmId,language: action.payload.language, knowledgeDomain: action.payload.knowledgeDomain,tier: action.payload.tier,tierSelected:action.payload.tierSelected};

        case ACTIONS.RA_INFO_INDEX:
            return { ...state, selectedInfoIndex: action.payload };

        case ACTIONS.RA_ERROR:
            return {...state, errorType: action.payload.errorType, hasError: action.payload.hasError};

        case ACTIONS.RA_HIDE_ERROR:
            return {...state, hasError: action.payload.hasError}
            
        default:
            return state;
    }
};

export default function RAContextProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [tosAgreed, setTosAgreed] = useState(UTILITIESHELPER.getLocalStorage("ra_tou_agreed_status") === "true" && String(window.DART.ENV.RA_TOSDESC_VERSION) === UTILITIESHELPER.getLocalStorage("ra_tou_agreed_version"));
    

    const store = {
        state,
        dispatch,
        tosAgreed, setTosAgreed
    };

    return (
        <ResearchAssistantContext.Provider value={store}>
            {children}
        </ResearchAssistantContext.Provider>
    );
}

