import React from "react"
import ShareIcon from "../ShareIcon"
import ThumbsDownIcon from "../ThumbsDownIcon"
import ThumbsUpIcon from "../ThumbsUpIcon"

const ShareReactionPanel = ({ feedBackButtonClick, shareHandlerClick }) => {
    return (
        <>
            <ShareIcon shareHandlerClick={shareHandlerClick} />
            <ThumbsUpIcon feedBackButtonClick={feedBackButtonClick} />
            <ThumbsDownIcon feedBackButtonClick={feedBackButtonClick}/>
        </>
    )
}
export default ShareReactionPanel