import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import cx from 'classnames';
import ShowIf from '../Common/ShowIf';
import CONTEXTHELPERS from '../../helpers/ContextHelpers'
import  { logs, page } from '../../helpers/log';
import { useTocContext } from '../../contexts/TocContext';
import { usePageContext, usePageContextLabels, usePageContextSettings } from '../../contexts/PageContext';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import TocItemRelatedContent from '../RelatedContent/TocItemRelatedContent';
import FolioTableOfContentsItem from './FolioTableOfContentsItem';
import TocAnnotationBadge from '../Publication/TocAnnotationBadge';
import TocSearchBadge from '../Publication/TocSearchBadge';
import useDCSService from '../../services/useDCSService';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import { ErrorCodes, ErrorMessages } from '../Constants/Errors';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';

const FolioSectionTOC = ({ basePath, sectionFriendlyPath }) => {
    const { context, isFolioSectionlisting } = usePageContext();
    const { getMFLabel } = usePageContextLabels();
    const { selectedItemTOC, setSelectedItemTOC, tocAnnotations } = useTocContext();
    const { isAnnotationsEnabled , isRestrictedAccessMemberfirm} = usePageContextSettings();
    const { setGuidebookSectionsLoaded, getGuidebookSectionData, setGuidebookSectionData} = useGuidebookTocContext();
    const { generateQuerySpec, getFolioSection } = useDCSService();
    const location = useLocation();
    let currentSectionData = getGuidebookSectionData(sectionFriendlyPath);
    const hasBeenFetched = get(currentSectionData, 'hasBeenFetched', false);
    const isSelected = (context.pageSelectedSection === sectionFriendlyPath) && (context.pageSelectedPublicationGUID === "");
    const [isExpanded, setIsExpanded] = useState(context.pageSelectedSection === sectionFriendlyPath);
    const { searchResults } = useSearchResultContext();
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isAnnotationAvailable, setIsAnnotationAvailable] = useState(false);

    useEffect(() => {
        setIsSearchActive(!UTILITIESHELPER.isArrayNullorEmpty(searchResults));
    }, [searchResults]);

    useEffect(() => {
        setIsAnnotationAvailable(!UTILITIESHELPER.isArrayNullorEmpty(tocAnnotations));
    }, [tocAnnotations]);


    useEffect(() => {
        if (!isExpanded) {
            //When the selectedItemTOC changes, we need to make sure its unselected...
            if (!!currentSectionData && !!selectedItemTOC && 'item' in selectedItemTOC && !!selectedItemTOC.item) {
                //If the selectedItemTOC is inside this section, then we need to expand it...
                if (selectedItemTOC.item?.tocHash?.indexOf(sectionFriendlyPath) === 0) {
                    setIsExpanded(true);
                }
            }
        }
    }, [selectedItemTOC]);



    const relatedContentToc = (sectionData) => {
        return sectionData.context?.map(item => {
            if (sectionData.tableOfContents.some((tableOfContentsItem) => tableOfContentsItem.publication_type === item.publicationType.Key)) {
                return <TocItemRelatedContent key={item.publicationType.Key} {...item} sectionData={sectionData} basePath={basePath} />
            }
        });
    }


    const renderTocBody = (_sectionItem) => {
        if (!_sectionItem || !_sectionItem.item?.tableOfContents?.length) return null;


        if (sectionFriendlyPath === "related") return relatedContentToc(_sectionItem.item);


        if (sectionFriendlyPath === "templates" && _sectionItem.item?.tableOfContents[0]?.publication_type !== "66029858") {
            //Check and remove duplicates...
            let templates = [];
            let included = "";
            _sectionItem.item.tableOfContents.map((tableOfContentItem) => {
                tableOfContentItem.subItems.map((subItem) => {
                    if(included.indexOf(subItem.id) < 0) {
                        subItem.tocID = tableOfContentItem.id;
                        subItem.basePath = tableOfContentItem.url;

                        included = `${included}|${subItem.id}`;
                        templates.push(subItem);
                    }
                })
            })
            templates.sort((a, b) => a.nav_title.localeCompare(b.nav_title));

            return templates.map((subItem, index) => {
                return (
                    <FolioTableOfContentsItem
                        key={`TOC_${subItem.tocID}_${subItem.id}_${index}`}
                        basePath={`${subItem.basePath}`}
                        item={subItem}
                        includeSubItems={false}
                        sectionFriendlyPath={sectionFriendlyPath}
                        isPubExpandable={false}
                    />
                )
            })
        }

        if (sectionFriendlyPath === "alerts") {

            UTILITIESHELPER.sortArrayDesc(_sectionItem.item.tableOfContents,'current_date')
            return _sectionItem.item.tableOfContents.map((tableOfContentItem, index) => {
          
                return (
                    <FolioTableOfContentsItem
                        key={`TOC_${tableOfContentItem.id}_${index}`}
                        basePath={`${_sectionItem.basePath}/${tableOfContentItem.url}`}
                        item={tableOfContentItem}
                        includeSubItems={(sectionFriendlyPath !== 'alerts')}
                        sectionFriendlyPath={sectionFriendlyPath}
                        isPubExpandable={tableOfContentItem.isExpandable}
                    />
                );
               
            })
        }

        return _sectionItem.item.tableOfContents.map((tableOfContentItem, index) => {
          
            return (
                <FolioTableOfContentsItem
                    key={`TOC_${tableOfContentItem.id}_${index}`}
                    basePath={`${_sectionItem.basePath}/${tableOfContentItem.url}`}
                    item={tableOfContentItem}
                    includeSubItems={(sectionFriendlyPath !== 'alerts')}
                    sectionFriendlyPath={sectionFriendlyPath}
                    isPubExpandable={tableOfContentItem.isExpandable}
                />
            );
           
        })

    }

    const sectionItem = useMemo(() => {
        return {
            basePath: context.pageBaseURL,
            sectionTitle: getMFLabel(get(currentSectionData, 'title.Content.title'), "title.Content.title"),
            item: currentSectionData,
            includeSubItems: (sectionFriendlyPath !== 'alerts'),
            isFolioSection: true,
            isRelatedContentListingPage: (sectionFriendlyPath === 'related')
        }
    }, [sectionFriendlyPath, currentSectionData]);

   function recurseSelectedCurrentPublication(tableOfContents, urlContext) {
    let selectedPublication = [];

    tableOfContents.forEach(currentContent => {
        const subItems = currentContent?.subItems?.length ? recurseSelectedCurrentPublication(currentContent.subItems, urlContext) : [];
        selectedPublication = currentContent.id === urlContext.pageSelectedContentItemGUID ? [...selectedPublication, currentContent] : subItems.length ? [...selectedPublication, currentContent] : selectedPublication;
    });

    return selectedPublication;
}


    useEffect(() => {
        const getSectionData = async () => {
            try {
                const querySpec = generateQuerySpec(null, 0);
                //Get the current section's data/toc
                const res = await getFolioSection(querySpec, sectionFriendlyPath);

                //Put the response into the table of contents where it will be read from...
                currentSectionData.tableOfContents = res;
                const urlContext = CONTEXTHELPERS.generateContext(window.location.href)
                if (res?.length && urlContext?.pageSelectedSection === sectionFriendlyPath && !location.search.includes('searchPhrase') && !(urlContext?.pageHashTag)) {
                    const selectCurrentTemplate = recurseSelectedCurrentPublication(res, urlContext)
                    const currentTemplate = selectCurrentTemplate?.length && selectCurrentTemplate[0].subItems.find(template => template.id === urlContext.pageSelectedContentItemGUID)
                    let url = "";
                    if (selectCurrentTemplate?.length && UTILITIESHELPER.CheckIfContainsUnicodes(selectCurrentTemplate[0].url)) {
                        url = UTILITIESHELPER.ReplaceSpecialCharacterInURL(selectCurrentTemplate[0].url);
                    }
                    else if (selectCurrentTemplate?.length) {
                        url = selectCurrentTemplate[0].url;
                    }
                    let href="";
                    if (UTILITIESHELPER.CheckIfContainsUnicodes(window.location.href)) {
                        href = UTILITIESHELPER.ReplaceSpecialCharacterInURL(window.location.href);
                    }
                    else {
                        href = window.location.href;
                    }
                    if (selectCurrentTemplate?.length && !decodeURI(href).toLowerCase().includes(decodeURI(url).toLowerCase())) {
                        window.open(`${basePath}/${selectCurrentTemplate[0].url}/${encodeURIComponent(currentTemplate.nav_title || currentTemplate.title)}/${currentTemplate.id}`, '_self')
                    }
                }
                logs.debug(page.FolioSectionTOC, 'getFolioSections', "getFolioSections:sectionFriendlyPath, currentSectionData(incl toc res) ", sectionFriendlyPath, querySpec, currentSectionData);

                //Put the whole section back into the guidebook context object (it needs to be updated like this for some reason, it doesnt just update it through the object ref)
                setGuidebookSectionData(sectionFriendlyPath, currentSectionData);
                //Increment the section-loaded count (to check wormhole)
                setGuidebookSectionsLoaded(prev => prev + 1);
                //When we have just retreived the current section within the folio...
                if (sectionFriendlyPath === context.pageSelectedSection) {
                    //As long as we are "in" this section, we will be expanded! (no longer have to do this after the page is loaded)
                    if (!isExpanded) {
                        setIsExpanded(true);
                    }
                    //If we are on the listing page, we need to set the current selectedItemTOC so that the listing page shows.
                    if (isFolioSectionlisting) {
                        setSelectedItemTOC({ item: currentSectionData });
                    }
                }
            }
            catch (err) {
                logs.error(page.FolioSectionTOC,'getData', ErrorMessages.guidebookSection +' '+ sectionFriendlyPath, err, {eventId:ErrorCodes.GuidebookSectionTOC});
            }
        };
        getSectionData();
    }, []);

    //While the Section Data is loading, we will present the section's "loading" TOC...
    if (!hasBeenFetched) {
        return (
            <div className="d-flex tocWrapper">
                <div className="tocSpinnerWrapper">
                    <svg className="spinner" width="14px" height="14px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle className="path" fill="none" strokeWidth="5" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>
                </div>
                <div className="tocSpinnerText">{sectionItem.sectionTitle}</div>
            </div>
        )
    }
    else {
        if (currentSectionData && Array.isArray(currentSectionData.tableOfContents) && currentSectionData.tableOfContents.length > 0) {
            //TODO:BDP: When the pageContext has the functions to check what sections/url-parts have what features
            //  this should be moved to one of them (in the existing enabler ticket!)
            //  Sections, are either badgable/searchable etc so this is part of that!
            const isBadged = !"related".includes(sectionFriendlyPath);

            return (
                <>
                    <div className="d-flex">
                        <button onClick={() => setIsExpanded(prevVal => !prevVal)} className={cx("toc-item-icon cursor-pointer", isExpanded ? 'guidebook-section-toc-open' : 'guidebook-section-toc-closed')}></button>
                        <Link to={`${sectionItem.basePath}/${sectionFriendlyPath}`} className="my-auto link-guidebook-section-toc" id={sectionItem.sectionTitle}>
                            <div
                                onClick={() => { setIsExpanded(prevVal => !prevVal); setSelectedItemTOC({ item: currentSectionData }); }}
                                className={cx("toc-item-text-container", { "toc-item-text-container-selected": isSelected })}>
                                {sectionItem.sectionTitle}
                            </div>
                        </Link>
                        <div className="badge-section-wrapper">
                            <ShowIf condition={isAnnotationAvailable && isAnnotationsEnabled() && !isRestrictedAccessMemberfirm() &&  !isSearchActive}>
                                <TocAnnotationBadge key={`badgeAnno_${sectionItem.id}`} tocItem={sectionItem} />
                            </ShowIf>
                            <ShowIf condition={isBadged && isSearchActive}>
                                <TocSearchBadge key={`badge_section_${sectionFriendlyPath}`} tocItem={{ tocHash: sectionFriendlyPath }} />
                            </ShowIf>
                        </div>
                    </div>
                    <ul className={cx('toc-section-children', isExpanded ? 'show' : 'hide')}>
                        {renderTocBody(sectionItem)}
                    </ul>
                </>
            )
        }
        //else, we have loaded section data and we have no content
        return null;
    }
}

export default FolioSectionTOC;
