// Browser Storage Helpers

// Session Storage
function getSessionStorage(key) {
    var sessionStorage = window.sessionStorage;
    var item = sessionStorage.getItem(key);
    return item;
}

function setSessionStorage(key, value) {
    var sessionStorage = window.sessionStorage;
    sessionStorage.setItem(key, value);
}

function getSessionStorageJSON(key) {
    var sessionStorage = window.sessionStorage;
    return JSON.parse(sessionStorage.getItem(key));
}

function setSessionStorageJSON(key, value) {
    var sessionStorage = window.sessionStorage;
    sessionStorage.setItem(key, JSON.stringify(value));
}

function removeSessionStorage(key) {
    sessionStorage.removeItem(key);
}

// Local Storage
function getLocalStorage(key) {
    var localStorage = window.localStorage;
    var item = localStorage.getItem(key);
    return item;
}

function setLocalStorage(key, value) {
    var localStorage = window.localStorage;
    localStorage.setItem(key, value);
}

function getLocalStorageJSON(key) {
    var localStorage = window.localStorage;
    return JSON.parse(localStorage.getItem(key));
}

function setLocalStorageJSON(key, value) {
    var localStorage = window.localStorage;
    localStorage.setItem(key, JSON.stringify(value));
}

function removeLocalStorage(key) {
    var localStorage = window.localStorage;
    localStorage.removeItem(key);
}

export default {
    getSessionStorage,
    getSessionStorageJSON,
    setSessionStorage,
    setSessionStorageJSON,
    removeSessionStorage,
    getLocalStorage,
    getLocalStorageJSON,
    setLocalStorage,
    setLocalStorageJSON,
    removeLocalStorage
}