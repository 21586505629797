import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { logs, appInsightUIMapping, page } from "../../helpers/log";
import { usePageContextLabels, usePageContext, usePageContextSettings } from '../../contexts/PageContext';
import cx from 'classnames';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import SearchHelpers from '../../helpers/SearchHelpers';
import RecentSearches from '../Common/RecentSearches.js';
import RECENTSEARCHSERVICES from '../../helpers/RecentSearchService.js';
import { ACTIONS, useUserPreferencesContext } from '../../contexts/AnnotationsContext';
import { SCS_KEYS, getFallback } from '../Constants/SCSConstants';
import { ErrorTypes } from '../Constants/Errors';
import ShowIf from '../Common/ShowIf.js';
import { PAGE_CONTROLLER_TYPE } from '../Constants/Constants';

const CollectionSearchbar = ({ className, placeholder, classNameForm, autocomplete = "on", parentOnTrigger, parentOnSubmit, showRecentSearches = false, calledFrom, isResearchAssistantEnabled = false, setSearchTrigger }) => {
    const { searchPhrase, setSearchPhrase } = usePageContext();
    const { setSearchTimeStamp } = useSearchResultContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { getLabel } = usePageContextLabels();
    const ref = useRef();
    const { context, setNotification, getMemberFirmID } = usePageContext();
    const [showRecentHistory, setShowRecentHistory] = useState(false);
    const wildcardsPrefixSearchLimitationNotification = getLabel(SCS_KEYS.wildcardsPrefixSearchLimitation, getFallback(SCS_KEYS.wildcardsPrefixSearchLimitation));
    const { state: annState, dispatch } = useUserPreferencesContext();
    const showRecentSearch = (calledFrom === page.Collection || calledFrom === page.Article) ? true : null;
    const { isRecentSearchEnabled } = usePageContextSettings();
    useEffect(() => {
        function getRecentSearches() {
            const RecentSearchdata =   localStorage.getItem('recentSearch');
           // await RECENTSEARCHSERVICES.getRecentSearch(location.pathname, getMemberFirmID());
            let recentSearchList = RecentSearchdata != null ? (RecentSearchdata ?? []) : []
            let recentSearchData= UTILITIESHELPER.isArrayNullorEmpty(recentSearchList) ? recentSearchList: JSON.parse(recentSearchList);
            dispatch({
                type: ACTIONS.RECENT_SEARCH_LOAD,
                payload: JSON.stringify(recentSearchData),
            });
        }
        if (isRecentSearchEnabled() && UTILITIESHELPER.isArrayNullorEmpty(annState.recentSearch))
            getRecentSearches();
    }, [])//eslint-disable-line

    function RecentSearchClickHandler(searchphrase) {
        setSearchPhrase(searchphrase)
        setTimeout(() => ref.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true })), 500)
    }

    function setRecentSearchesData(_recentHistory, _recentHistoryList) {
    }

    const handleFocus = () => {
        if (showRecentHistory === false) {
            // load data when focused first time on the searchbox 
            setShowRecentHistory(true)
        }
    }

    if (!placeholder) {
        placeholder = getLabel("s_Search", "Search");
    }



    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearchTrigger((prev) => prev + 1)
            parentOnTrigger(searchPhrase);
        }
    }

    const handleChange = event => {
        setSearchPhrase(event.target.value);
        const currentLocation = window.location.pathname;

        if ([PAGE_CONTROLLER_TYPE.manual,
            PAGE_CONTROLLER_TYPE.standards,
            PAGE_CONTROLLER_TYPE.standard,
            PAGE_CONTROLLER_TYPE.guidance,
            PAGE_CONTROLLER_TYPE.roadmap,
            PAGE_CONTROLLER_TYPE.folio].includes(context.pageControllerType)) {

            const parsedSearchPhrase = SearchHelpers.parse(searchPhrase);
            const qs = SearchHelpers.toQueryString(parsedSearchPhrase);

            navigate(`${currentLocation}?${qs}`);

            logs.trackEvent({
                name: 'Search',
                properties: {
                    searchText: searchPhrase,
                    memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                    serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                    level: UTILITIESHELPER.getSessionStorage('Level')
                }
            })
            return;
        }
    };

    useEffect(() => {
        setSearchPhrase("");
    }, [location?.pathname])//eslint-disable-line

    const handleSubmit = event => {
        event.preventDefault();
        setSearchTimeStamp(Date.now())

        const _searchPhrase = searchPhrase.trim();

        if (UTILITIESHELPER.isStringNullorEmpty(_searchPhrase) || _searchPhrase.length < 2) {
            alert(getLabel("s_SearchPhraseTooShort", "Phrase too short (for now)"));
            return;
        }
        if (SearchHelpers.IsSearchPrefixWithWildcards(_searchPhrase)) {
            setNotification({ message: wildcardsPrefixSearchLimitationNotification, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'CollectionSearchbar' });
            return;
        }
        setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null });

        const currentLocation = window.location.pathname;

        if ([PAGE_CONTROLLER_TYPE.collection,
            PAGE_CONTROLLER_TYPE.manual,
            PAGE_CONTROLLER_TYPE.standards,
            PAGE_CONTROLLER_TYPE.standard,
            PAGE_CONTROLLER_TYPE.guidance,
            PAGE_CONTROLLER_TYPE.roadmap,
            PAGE_CONTROLLER_TYPE.folio].includes(context.pageControllerType)) {
            const parsedSearchPhrase = SearchHelpers.parse(_searchPhrase);
            const qs = SearchHelpers.toQueryString(parsedSearchPhrase);

            navigate(`${currentLocation}?${qs}`);

            logs.trackEvent({
                name: 'Search',
                properties: {
                    searchText: _searchPhrase,
                    memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                    serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                    level: UTILITIESHELPER.getSessionStorage('Level')
                }
            })
            return;
        }

        return;
    };

    return (
        <>

            <form className={classNameForm} onSubmit={handleSubmit} ref={ref} id="form-collection-search" data-testid="form-collection-search">
                <ShowIf condition={!isResearchAssistantEnabled}>      <label htmlFor="collection-search" className="visually-hidden">{getLabel("s_Search", "Search")}</label></ShowIf>
                <input
                    className={cx("searchbar", className)}
                    placeholder={placeholder}
                    value={searchPhrase || ''}
                    onChange={handleChange}
                    autoComplete={autocomplete}
                    id="collection-search"
                    onKeyDown={handleKeyDown}
                    onFocus={handleFocus}
                    data-testid="collection-search-input"
                />
                {showRecentHistory && !UTILITIESHELPER.isArrayNullorEmpty(annState.recentSearch) &&
                    <RecentSearches recentHistoryList={annState.recentSearch} handleClick={RecentSearchClickHandler} setRecentSearchesData={setRecentSearchesData} globalRecentSearches={showRecentSearch} />
                }
            </form>

            <ShowIf condition={isResearchAssistantEnabled}>
                <div align="right" className='chatbot-button-go'>
                    <button
                        className="chatbot-go"
                        onClick={() => { parentOnTrigger(searchPhrase) }}
                        title={getLabel("Go", "Go")}
                    >
                    </button>

                </div>
            </ShowIf>
            <ShowIf condition={!isResearchAssistantEnabled && calledFrom === page.Catalog}>
                <div align="right" className='chatbot-button-go'>
                    <button
                        className="non-chatbot-go"
                        onClick={() => { parentOnTrigger(searchPhrase) }}
                        title={getLabel("Go", "Go")}
                    >
                        {getLabel("Search", "Search")}
                    </button>

                </div>
            </ShowIf>
        </>
    )
}
CollectionSearchbar.defaultProps = {
    placeholder: "",
    style: {},
    className: ''
}
export default CollectionSearchbar;