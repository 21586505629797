import React, { useEffect, useRef, useState } from 'react';
import NavigableSearchbar from '../Common/NavigableSearchbar';
import { useArticleContext } from '../../contexts/ArticleContext';
import { usePageContextLabels, usePageContext } from '../../contexts/PageContext';
import SearchHelpers from '../../helpers/SearchHelpers';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import { appInsightUIMapping } from '../../helpers/log';
import { SCS_KEYS, getFallback } from '../Constants/SCSConstants';
import { ErrorTypes } from '../Constants/Errors';


const ArticleSearch = ({ isFixed, isExpanded, style, className }) => {
    const inputRef = useRef();
    const [isActive, setIsActive] = useState(isExpanded); //start off with search closed
    const { searchText, setSearchText, pdfPage, setPdfPage } = useArticleContext(); //For setting the search text
    const [searchTerms, setSearchTerms] = useState(0);
    const { getLabel } = usePageContextLabels();
    const { setSearchPhrase, setNotification } = usePageContext();
    const { setSearchResults, areSearchResultsLoading } = useSearchResultContext();
    const [localValue, setLocalValue] = useState(searchText);
    const wildcardsPrefixSearchLimitationNotification = getLabel(SCS_KEYS.wildcardsPrefixSearchLimitation, getFallback(SCS_KEYS.wildcardsPrefixSearchLimitation));

    useEffect(() => {
        if (areSearchResultsLoading) {
            sessionStorage.removeItem('searchPhraseParam');
        }

        // Clear search text context value when component unmounts
        return () => setSearchText("")
    }, []);//eslint-disable-line

    useEffect(() => {
        setIsActive(isExpanded);
    }, [isExpanded]);

    useEffect(() => {
        if (isActive && inputRef.current) inputRef.current.focus();
        if (!isActive) {
            setLocalValue("");
            setSearchText("");
        }
    }, [isActive])//eslint-disable-line

    useEffect(() => {
        if (searchText !== localValue) {
            setLocalValue(searchText);
        }
    }, [searchText]);//eslint-disable-line

    useEffect(() => {
        const resultsContainer = document.querySelector('.article-content-container');
        const searchTerms = resultsContainer?.querySelectorAll('.highlight-search');

        setSearchTerms(searchTerms);

        return () => {
            setPdfPage(false);
            setSearchTerms([]);
        }

    }, [searchText, pdfPage]);//eslint-disable-line

    function setValue(value) {
        if (value !== localValue) {
            setLocalValue(value);
            setSearchResults([]);
            if (value.length >= 3) {
                if (SearchHelpers.IsSearchPrefixWithWildcards(value)) {
                    setNotification({ message: wildcardsPrefixSearchLimitationNotification, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'ArticleSearch' });
                    return;
                }
                setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null });
                setSearchText(value);
                setSearchPhrase(value);
            } else {
                setSearchText('');
                setSearchPhrase('');
            }
        }
    }

    const handleClose = () => {
        setSearchResults([]);
        setIsActive(false);
        setSearchPhrase('');
    };

    // FGP :: finish implementation of localValue to avoid changing context when search phrase is too short.

    return (
        <NavigableSearchbar
            placeholder={getLabel("s_Search", "Search")}
            value={localValue}
            style={style}
            className={className}
            setValue={setValue}
            terms={searchTerms}
            isExpanded={isExpanded}
            isFixed={isFixed}
            onClose={handleClose}
            showSearchLink
            disableRecentSearch={true}
        />
    )

}

ArticleSearch.defaultProps = {}

export default ArticleSearch;
