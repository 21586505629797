import ReactHtmlParser from 'react-html-parser';

const HTMLContent = ({ children }) => {
    let htmlContent = ''
    if (children === undefined) {
        htmlContent = ''
    }
    else {
        if (typeof children === 'string' || children instanceof String) {
            htmlContent = children
        }
        else {
            htmlContent = `${children}`
        }
    }

    return ReactHtmlParser(htmlContent);;
}

export default HTMLContent