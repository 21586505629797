import React from 'react';
import { usePageContextLabels } from '../../contexts/PageContext'; 
import { useResearchAssistantContext} from '../../contexts/ResearchAssistantContext';
        

const TriggerValueResearchAssistant = () => {
    const {state: raState} = useResearchAssistantContext();
    const Title = () => {
        const { getLabel } = usePageContextLabels();
        const defaultTitle = getLabel("ra_FIltersResearchAssistant", "Filters");
        const buildTitle = (parts) => {
            let title = parts.map(x => x.label).join('; ');
            if (title.length > 7) {
                title = title.slice(0, 7).concat('...');
            }
            title += ` (${parts.length})`;
            return title;
        }

        const getTitle = () => {
       
            if (raState.tier) {
                if (raState.tier[raState.memberFirm] && raState.tier[raState.memberFirm][raState.knowledgeDomain] && raState.tier[raState.memberFirm][raState.knowledgeDomain].length) {
                    return buildTitle(raState.tier[raState.memberFirm][raState.knowledgeDomain]);
                }
            }
            return defaultTitle;
        }
        return <span className='dd__trigger-label'>{getTitle()}</span>
    }
    return (
        <button type='button' className="dd__trigger-button">
            <Title />
        </button>
    )
};
export default TriggerValueResearchAssistant
