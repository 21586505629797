
import { get } from 'lodash';

const INDEXED_COMPONENT = 'indexed';
const SECTION_COMPONENT = 'section';
const SECTION_COMPONENT_WITH_TEXT_BLOCK = 'section with text block';
const FIGURE_COMPONENT = 'figure';
const SIMPLE_NARRATIVE_COMPONENT = 'Simple Narrative';
const FULL_NARRATIVE_COMPONENT = 'Full Narrative';
const FILE_COMPONENT = 'File';

const BLUE_VARIANT = 'blue-variant';
const RED_VARIANT = 'red-variant';
const BLACK_VARIANT = 'black-variant';
const REVISION_HISTORY= 'revision history';
const getComponentInfo = (contentTypeId) => {

const decoratorInfo = {
    '67748346': {
        name: 'Revison History',
        component: REVISION_HISTORY,
        isInPilot: true,
    },
    '60194859': {
        name: 'Application guidance',
        component: INDEXED_COMPONENT,
        isInPilot: true,
    },
    '63781317': {
        name: 'Manual guidance',
        component: INDEXED_COMPONENT,
        isInPilot: true,
    },
    '18735284': {
        name: 'Policy',
        component: INDEXED_COMPONENT,
        isInPilot: true,
    },
    '60194860': {
        name: 'Requirement',
        component: INDEXED_COMPONENT,
        isInPilot: true,
    },
    '17377912': {
        name: 'Section',
        component: SECTION_COMPONENT,
        isInPilot: true,
    },
    '63887580': {
        name: 'Article',
        component: SECTION_COMPONENT_WITH_TEXT_BLOCK,
        isInPilot: true,
    },
    '86191937': {
        name: 'ExternalArticle',
        component: SECTION_COMPONENT_WITH_TEXT_BLOCK,
        isInPilot: true,
    },
    '63781318': {
        name: 'Process flow overview',
        component: SECTION_COMPONENT_WITH_TEXT_BLOCK,
        isInPilot: false,
    },
    '63781319': {
        name: 'Process flow step',
        component: SECTION_COMPONENT_WITH_TEXT_BLOCK,
        isInPilot: false,
    },
    '65469055': {
        name: 'Section with Intro',
        component: SECTION_COMPONENT_WITH_TEXT_BLOCK,
        isInPilot: true,
    },
    '67748346': {
        name: 'Revison History',
        component: SECTION_COMPONENT_WITH_TEXT_BLOCK,
        isInPilot: true,
    },
    '48693746': {
        name: 'Example',
        component: FIGURE_COMPONENT,
        variant: BLUE_VARIANT,
        isInPilot: true,
    },
    '48082120': {
        name: 'Information',
        component: SIMPLE_NARRATIVE_COMPONENT,
        isInPilot: true,
    },
    '60194857': {
        name: 'Pitfall',
        component: FIGURE_COMPONENT,
        varian: RED_VARIANT,
        isInPilot: true,
    },
    '65469056': {
        name: 'Preface',
        component: FULL_NARRATIVE_COMPONENT,
        isInPilot: true,
    },
    '63781322': {
        name: 'Scenario',
        component: FULL_NARRATIVE_COMPONENT,
        isInPilot: true,
    },
    '63781320': {
        name: 'Step Documentation',
        component: FULL_NARRATIVE_COMPONENT,
        isInPilot: false,
    },
    '63781321': {
        name: 'Step summary how',
        component: FULL_NARRATIVE_COMPONENT,
        isInPilot: false,
    },
    '60247324': {
        name: 'Pub Context',
        isInPilot: true,
    },
    '60262021': {
        name: 'Pub Context - Spine',
        isInPilot: true,
    },
    '60194856': {
        name: 'Q&A',
        component: FIGURE_COMPONENT,
        variant: BLACK_VARIANT,
        isInPilot: true,
    },
    '17323604': {
        name: 'Template',
        component: FILE_COMPONENT,
        isInPilot: true,
    },
    '18735295': {
        name: 'Template',
        component: FILE_COMPONENT,
        isInPilot: true,
    },

}

return get(decoratorInfo, `${contentTypeId}`, {})



};

const isStructuredArticle = (contentTypeId) => {
    const info = getComponentInfo(contentTypeId);

    switch(info.component) {
        case FILE_COMPONENT:
        case SECTION_COMPONENT:
        case FIGURE_COMPONENT:
        case SECTION_COMPONENT_WITH_TEXT_BLOCK:
        case INDEXED_COMPONENT:
        case FULL_NARRATIVE_COMPONENT:
        case REVISION_HISTORY:
            return info.isInPilot;
        default:
            return false;
    }
}

const isFigureOrSidebar = (contentTypeId) => {
    const info = getComponentInfo(contentTypeId);

    switch(info.component) {
        case FIGURE_COMPONENT:
            return info.isInPilot;
        default:
            return false;
    }
}

const isRevisionHistory = (contentTypeId) => {
    const info = getComponentInfo(contentTypeId);

    switch(info.component) {
        case SECTION_COMPONENT_WITH_TEXT_BLOCK:
            return info.isInPilot;
        default:
            return false;
    }
}

const isSection = (contentTypeId) => {
    const info = getComponentInfo(contentTypeId);

    switch(info.component) {
        case SECTION_COMPONENT:
            return info.isInPilot;
        default:
            return false;
    }
}

const isIndexedComponent = (contentTypeId) => {
    const info = getComponentInfo(contentTypeId);

    switch(info.component) {
        case INDEXED_COMPONENT:
            return true
        default:
            return false;
    }
}

const isFile = (contentTypeId, containsBinary) => {
    const info = getComponentInfo(contentTypeId);

    switch(info.component) {
        case FILE_COMPONENT:
        case SECTION_COMPONENT:
            return info.isInPilot && containsBinary;
        default:
            return false;
    }
}

export {
    isStructuredArticle,
    isFigureOrSidebar,
    isIndexedComponent,
    isSection,
    isFile,
    isRevisionHistory
};

export default getComponentInfo;
