import React, { useState, useRef, useLayoutEffect, useEffect, useCallback } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useArticleContext } from '../../contexts/ArticleContext';
import SearchHelpers from '../../helpers/SearchHelpers';
import LinkHelpers from '../../helpers/LinkHelpers';
import ReactHtmlParser from 'react-html-parser';
import RETRIEVALSERVICES from '../../services/rp-service';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';


// const PdfPagination = ({ current, total, onChange }) => {
//     function handlePrevious() {
//         onChange({
//             pageNumber: Math.max(1, current - 1)
//         });
//     }

//     function handleNext() {
//         onChange({
//             pageNumber: Math.min(total, current + 1)
//         });
//     }

//     return (
//         <div className="pdf-pagination">
//             <button type="button" disabled={current === 1} onClick={handlePrevious}></button>
//             <span>{`${current} / ${total ?? '...'}`}</span>
//             <button type="button" disabled={current === total} onClick={handleNext}></button>
//         </div>
//     )
// }

const SinglePdfDownload = ({ title, file }) => {
    const styles = { height: 0, width: 0, overflow: 'hidden', display: 'none' }
    // XSS Link Sanitization
    const sanitizedFileUri = LinkHelpers.sanitizeUrl(file);
    const handleClick = (e) => {
        e.preventDefault();
        const link = document.createElement('a');
        link.href = sanitizedFileUri;
        link.download = title;
        link.click();
        link.remove();
    }

    //TODO: Translate this
    return <button id="single-pdf-download" style={styles} type="button" onClick={handleClick}>Download</button>
}

const PDF = ({ uri, fileTitle, format }) => {
    // const token = UTILITIESHELPER.getAuthToken();
    // const baseDomain = UTILITIESHELPER.getBaseDomain();
    fileTitle=UTILITIESHELPER.removeLargeSpacesFromRichText(fileTitle);
    fileTitle = fileTitle.replace(/\u2013|\u2014/g, "-");
    fileTitle= UTILITIESHELPER.removeSpecialCharacterFromFileName(fileTitle);
    fileTitle = UTILITIESHELPER.restrictLengthOfFileName(fileTitle);
    const composedTitle = `${fileTitle.replaceAll('/', '%2F')}${format}`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageWidth, setPageWidth] = useState(null);
    const { searchText, setSearchText, setPdfPage } = useArticleContext();
    const [textItems, setTextItems] = useState();
    const [pdfDoc, setPdfDoc] = useState(null);
    const [composedUri, setComposedUri] = useState("");
    const [blobUri, setBlobUri] = useState(null);
    const [isPDFRendered, setIsPDFRendered] = useState(false);

    // Ref to PDF Document div element
    const inputRef = useRef();
    const handleResize = (entries) => {
        if (!Array.isArray(entries)) {
            return;
        }

        const entry = entries[0];
        // HACK: subtract 2px to compensate for border width due to clipping
        setPageWidth(entry.contentRect.width - 2);
    }
    useEffect(() => {
        loadPdf();
       
    }, []);//eslint-disable-line
    const loadPdf = async () => {
        const blob = await RETRIEVALSERVICES.requestPdfLoading(uri, composedTitle);
        setBlobUri(window.URL.createObjectURL(blob))
        let base64String;
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            base64String = reader.result;
            setComposedUri(base64String?.substr(base64String?.indexOf(',') + 1));
        };
    }
    
    // useEffect(()=>{

    //     if (document.readyState === 'complete') {

    //         setTimeout(function() {
    //             navigateToSearchPahse();
    //         }, 1000);
    //       } else {
    //         window.addEventListener('ready', navigateToSearchPahse);
    //         // Remove the event listener when component unmounts
    //         return () => window.removeEventListener('load', navigateToSearchPahse);
    //       }
    // },[])

    // using LayoutEffect hook to avoid repaint via sync execution
    useLayoutEffect(() => {
        if (!inputRef.current) {
            return;
        }

        let RO = new ResizeObserver(handleResize);
        RO.observe(inputRef.current);

        return () => {
            RO.disconnect();
            RO = null;
        }

    }, [inputRef])

    const onDocumentLoadSuccess = useCallback(async (loadedPdfDoc) => {
        setPdfDoc(loadedPdfDoc);
    }, []);

    useEffect(() => {
        const fetchPageContent = async () => {
            if (pdfDoc) {
                setNumPages(pdfDoc.numPages);
                const page = await pdfDoc.getPage(pageNumber);
                const content = await page.getTextContent();
                const texts = content.items;
                setTextItems(texts);
            }
        };

        fetchPageContent();
       
        if(pageNumber !== undefined && isPDFRendered)
        {
            onNavigableItemClick({
                pageNumber: pageNumber
            });
        }

        return () => {
            // Perform any necessary cleanup here
        };
    }, [pageNumber, pdfDoc]);

    const onNavigableItemClick = ({ pageNumber }) => {
        setPageNumber(pageNumber);
        const element = document.querySelector(`[data-page-number="${pageNumber}"]`);
        if (element) {
            element.scrollIntoView({
                behavior: 'auto',
                block: 'start',
                inline: 'center'
            });
        }
    };



    const onKeyboardUp = (e) => {
        if (e.keyCode === 37) {
            onNavigableItemClick({
                pageNumber: Math.max(1, pageNumber - 1)
            });
            setSearchText(searchText)
        }

        if (e.keyCode === 39) {
            onNavigableItemClick({
                pageNumber: Math.min(numPages, pageNumber + 1)
            });
            setSearchText(searchText)
        }

        e.stopPropagation();
    }

    useEffect(() => {
        document.addEventListener('keyup', onKeyboardUp);

        return () => {
            document.removeEventListener('keyup', onKeyboardUp);
        }
    })

    const textRenderer = useCallback(textItem => {
        // If no search text, return original string
        if (!searchText) {
            return textItem.str;
        }
        const marked = SearchHelpers.apply(textItem.str, searchText, true, textItems, textItem);
        return ReactHtmlParser(marked);
    }, [searchText, textItems]);

    const getTextSuccess = (current) => {
        const element = document.querySelector('.highlight-search');
        if (element) {
            element.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
        }
        if(current === numPages)
        {
            setIsPDFRendered(true);
        }
        setPdfPage(true);
    }

    return (
        <div className="pdf" Key={pageNumber}>
            <Document
                file={`data:application/pdf;base64,${composedUri}`}
                className={["pdf-document"]}
                inputRef={inputRef}
                externalLinkTarget={'_blank'}
                renderMode="canvas"
                onLoadSuccess={onDocumentLoadSuccess}
                onItemClick={onNavigableItemClick}
            >
                {Array.from(new Array(numPages), (el, index) => (<Page
                    className={["pdf-page"]}
                    width={pageWidth}
                    pageNumber={index + 1}
                    renderAnnotationLayer={true}
                    // renderInteractiveForms={true}
                    customTextRenderer={textRenderer}
                    onGetTextSuccess={()=>{getTextSuccess(index+1)}}
                />))}
            </Document>
            <SinglePdfDownload file={blobUri} title={composedTitle} />
        </div>
    )
};

export default PDF;
