import RETRIEVALSERVICES from '../services/rp-service';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { get } from 'lodash';
import { logs, page } from '../helpers/log.js';
import ARTICLEHELPERS from '../helpers/ArticleHelpers';
import UTILITIESHELPER from "../helpers/UtilitiesHelper";
import SearchHelpers from './SearchHelpers';
import { ErrorCodes, ErrorMessages } from '../components/Constants/Errors';
import { PAGE_CONTROLLER_TYPE, PUBLICATIONTYPE_IDS } from '../components/Constants/Constants';
import ProfileHelpers from './ProfileHelpers';
const linkHashSeparator = "_|RP-HASH|_";
const KnowledgeDomainInSequence = ["17573941", "68489712", "17573178"];
//Expected input: id: "GUID-57E01081-B02B-4FD5-8A72-2032275453E4=1=en-US="
function cleanGUID(guid) {
    let cleanedGuid = guid;

    if (cleanedGuid.includes("=")) {
        cleanedGuid = cleanedGuid.split("=")[0];
    }
    if (cleanedGuid.includes("#")) {
        const guidArr = cleanedGuid.split("#");
        cleanedGuid = guidArr[0];
    }
    return cleanedGuid;
}

// To get the GUID of Article
function getGUID(locationUrl){
    const path = locationUrl
    const arry = path.split("/");
    const lastElement = arry[arry.length - 1];
    return lastElement;
}

function cleanLocationURL(targetBaseUrl) {
    let location = window.location.href;
    if (location.includes("#")) {
        location = location.split("#")[0];
    }
    if (location.includes("?")) {
        location = location.split("?")[0];
    }
    return location.substring(location.indexOf(targetBaseUrl));
}

function deturmineJournalType(journalBrand) {
    // Accounting Roundup - Accounting
    // IFRS Educational Material - Accounting - External
    // IFRS in Focus - Accounting
    // IFRS Proposals - Accounting - External
    // Need to Know - Accounting
    // Audit Smart One Pagers - Auditing
    // Practice Alerts - Auditing
    // NAA weekly digest - Auditing & Accounting
    // Technically Speaking - Auditing & Accounting
    //TODO: Need to confirm if its available, and then change this to get it from pub-type:
    //Announcement === 60194864 --> deloitte-communications
    //Announcement - External === 65511719 -> professional-news

    return (journalBrand === "IFRS Educational Material" || journalBrand === "IFRS Proposals") ? "professional-news" : "deloitte-communications";
}

function extractGUIDs(hrefValue) {
    let isLinkCT = !hrefValue.startsWith("GUID");
    let targetTopicGuid = hrefValue;
    let targetElementGuid = null;

    //Mechanism for getting the guid from a query parameter, or the new html links. 
    //  href="..._Revenue_Recognition_Contract_Term=GUID-DC964530-0ACE-4F6E-A654-7590F2EAD2B8=4=en-US=.html#SL679768029-359834"
    //  href="GUID-AC622958-6D64-43C4-B8F1-FF79EBDEEE52=1=en-US=.html"
    if (targetTopicGuid.includes("=")) {
        //if we have a #, lets grab the targetElement ouf of there first...
        if (targetTopicGuid.includes("#")) {
            targetElementGuid = targetTopicGuid.split("#")[1];
        }
        const guidArr = targetTopicGuid.split("=");
        if (guidArr[1].startsWith("GUID")) {
            //we will then get "GUID-DC964530-0ACE-4F6E-A654-7590F2EAD2B8" from the first example above
            targetTopicGuid = guidArr[1];
        }
        else {
            //we will then get "GUID-AC622958-6D64-43C4-B8F1-FF79EBDEEE52" from the example above
            targetTopicGuid = guidArr[0];
        }
    }

    //# denotes the element to scroll down to, we then have an publication/article and #element in the href
    //  and in the case of CT Links
    if (targetTopicGuid.includes("#")) {
        const guidArr = targetTopicGuid.split("#");

        //If this is is a non-CT link (internal)
        if (targetTopicGuid.startsWith("GUID")) {
            targetTopicGuid = guidArr[0];
            targetElementGuid = guidArr.length > 1 ? guidArr[1] : null;
        }
        else {
            //CT Links - Now we can resolve CT links, so we split them out and still look on the current page for the targetElement
            //  in the BE, the two will be re-joined before making the request.
            targetTopicGuid = guidArr[0]; //null (in the past)
            targetElementGuid = guidArr[1];
        }
    }

    //When we have a / at this point then we actually have pub...article/element
    if (targetElementGuid?.includes("/")) {
        const urlArr = targetElementGuid.split("/");
        //targetTopicGuid = urlArr[0]; (we no longer replace the TopicGuid (as that now remains the 1st GUID))
        targetElementGuid = urlArr[1];
    }

    //If they are the same at this point, then we clear the element!
    if (targetElementGuid === targetTopicGuid) {
        targetElementGuid = null;
    }

    //logs.debug(page.Link, 'LinkResolver', "targetTopicGuid, targetElementGuid:",targetTopicGuid, targetElementGuid);
    return { targetTopicGuid, targetElementGuid, isLinkCT };
}
function existsInsideParentClass(child, classname) {
    if (hasClass(child, classname)) {
        return true;
    }

    try {
        //Throws TypeError if child doesn't have parent any more
        return child.parentNode && existsInsideParentClass(child.parentNode, classname);
    }
    catch (TypeError) {
        return false;
    }
}
function hasClass(child, classname) {
    if (child.className.split(' ').indexOf(classname) >= 0) {
        return true;
    }
    return false;
}
function isFootnoteTopLink(child) {
    if (child.href.split('/').pop().indexOf('#footnote-top-') >= 0) {
        return true;
    }
    return false;
}
function getClosestLinkElement(ev) {
    var el
    if (Element.prototype.closest) {
        el = ev.target.closest('a');
    }
    else {
        //else the long way
        el = ev.target;
        while (el && el.tagName !== 'a') {
            el = el.parentNode;
        }
    }
    return el;
}
function getDataSource(ev) {
    var el = getClosestLinkElement(ev)
    return el?.dataset?.scope;
}

function getDynamicFolioSectionOrType(publication_type) {
    switch (publication_type) {
        case "65683596": //Disclosures in practice
            return "disclosures";

        case "65683649": //Model Financial Statement
            return "modelfs";

        case "65683594": //Q&A
            return "qna";

        case "48082106": //Auditing: Guided Risk Assessment
        case "60194867": //Auditing::Process Flow
        case "48082109": //Auditing::Omnia Core Policy
        case "66029858": //Auditing::RP Template Repository 66029858 66019138-66074179, 
        case "58035381": //New:Template Repository
        case "68489711": //New:Template Repository - Account (GRA)
        case "68169717": //New:Template Repository - RP
            return "templates";

        case "60194862": //Auditing::DeloitteWWF-RP
        case "66029856": //Auditing::Illustrative Example
        case "66029857": //Auditing::RP Other Guidance
        case "63868156": //Auditing::GRA-Guide..Guidebook
            return "other";

        case "60194863": //Accounting::Roadmap --> Knowledge domain different!!
            return "roadmap";

        case "48082110": //Spine: Manual Section - I need the parent spine pub id! (otherwise the link opens up in context of pub - not spine-pub)
        case "60194866": //Structure Manual
            return "manual";

        case "65469054": //Standard Section-Unumbered
        case "48082111": //Spine: Standard Section - I need the parent spine pub id! (otherwise the link opens up in context of pub - not spine-pub)
        case "60194865": //Structure Standards
            return "standards";
        case PUBLICATIONTYPE_IDS.externalCollection:
            return PAGE_CONTROLLER_TYPE.standard;
        case "60194864": //Alerts & Ann
            return "alerts";
        //these are default - already guidance
        case "60194861": //Auditing::Practical-Guide..Guidebook
            break;

        default:
            logs.info(page.Link, 'LinkResolver', "publication_type not found or defined, defaulting to guidance", publication_type);
            break;
    }

    return "guidance";
}

function getTargetKnowledgeDomain(knowledgeDomain, publication) {
    let currentKnowledgeDomain = "";

    switch (knowledgeDomain) {
        case "accounting": currentKnowledgeDomain = "17573178"; break;
        case "auditing": currentKnowledgeDomain = "17573941"; break;
        case "assurance": currentKnowledgeDomain = "68489712"; break;
        default:
            logs.debug(page.Link, 'LinkHelper', "The given knowledge domain is not valid " + knowledgeDomain);
        //return knowledgeDomain;
    }

    //If we find the knowledgeDomain in the list of knowledge domains of the publication, we have a match, and can stay in the same KD!
    //We need to search here as most he time we have multiple values here, and some of them are NOT valid tiers!
    let targetKnowledgeDomain = publication.metadata?.knowledge_domain?.find(tier => tier === currentKnowledgeDomain) ?? "";

    //logs.debug(page.Link, 'LinkHelper', "The target knowledge domain targetKnowledgeDomain, currentKnowledgeDomain", targetKnowledgeDomain, currentKnowledgeDomain);

    //We only set/reset this if we GET the tier, otherwise we leave it as the "current" knowledgeDomain!
    if (UTILITIESHELPER.isStringNullorEmpty(targetKnowledgeDomain)) {
        //The publication is not within the current knowledge domain, so we attempt to get one of the target knowledgeDomain(s)        
        let dynamicTierArray = KnowledgeDomainInSequence.filter(tier => publication.metadata?.knowledge_domain?.includes(tier)); //validate when dynamicTierArray is empty it should not break

        if (!UTILITIESHELPER.isArrayNullorEmpty(dynamicTierArray)) {
            switch (dynamicTierArray[0]) {
                case "17573178": targetKnowledgeDomain = "accounting"; break;
                case "17573941": targetKnowledgeDomain = "auditing"; break;
                case "68489712": targetKnowledgeDomain = "assurance"; break;
                default:
                    logs.debug(page.Link, 'LinkHelper', "No knowledge domain is set for the current value of " + dynamicTierArray[0]);
                    break;
            }
        }
    }
    else {
        //If we find the knowledgeDomain in the list of knowledge domains of the publication, we have a match, and can stay in the same KD!
        return knowledgeDomain;
    }

    return targetKnowledgeDomain;
}

function GetTargetTopicOrSpineTargetPublicationGUID(publication) {
    return (publication?.spine?.object_id === null) ? publication.content.object_id : publication.id;
}

async function getNavigatableLink({ ev,
    tocUrls,
    memberFirm,
    language,
    knowledgeDomain,
    messageCannotOpenTab,
    messageCannotResolve,
    isSearchResultLink,
    popupContent = false,
    memberFirms = null }) {
    const el = getClosestLinkElement(ev);

    if (!el?.href) {
        return false;
    }

    if (hasClass(el, "footnote-link")) {
        return false;
    }
    if (isFootnoteTopLink(el)) {
        return false;
    }

    //Need to account for links within the foot-note popup!
    if (!popupContent && !existsInsideParentClass(el, "article-content-container") && !existsInsideParentClass(el, "footnote-popup-text-container")) {
        //Then we are NOT in the "DCS-response content, and can "ignore" the link completely
        return false;
    }

    let hrefValue = el?.href;
    let location = window.location.href;
    let prefixLocation = location.substring(0, location.lastIndexOf('/') + 1);

    if(popupContent && el?.tagName==="A")
    {   
        let href = el.getAttribute('href')
        if(href==="")
        {
            ev.preventDefault();
            alert(messageCannotResolve);
            return false;
        }
    }

    //We assume if the current href contains the current domain url, that it did not start with http, i.e. it was an internal link
    if (hrefValue.indexOf(prefixLocation) > -1) {
        //Internal link, so we handle it...
        ev.preventDefault();

        if (ev.shiftKey) {
            alert(messageCannotOpenTab);
            return false;
        }

        hrefValue = hrefValue.replace(prefixLocation, '');
    }
    //TODO:BDP: May need to do an elseIf here for TL links?!
    else {
        //External link, so continue...
        return false;
    }

    let datasetPublication = el?.dataset?.publication;

    return await resolveLink(hrefValue,
        datasetPublication,
        tocUrls,
        memberFirm,
        language,
        knowledgeDomain,
        messageCannotResolve,
        isSearchResultLink,
        memberFirms);
}

function getNavigatableLinkFromTOC(tocUrls, targetTopicGuid, targetElementGuid, findSpineTarget = false) {
    if (tocUrls && targetTopicGuid) {
        const targetUrl = tocUrls.find(item => (!item.includes("/examples/") && !item.includes("/related/") && (item.indexOf(targetTopicGuid) > 0)));
        if (targetUrl !== undefined) {
            if (findSpineTarget) {
                const targetUrlParts = targetUrl.split("/");
                //If this is NOT the spine-part of the URL (i.e. the last part of the URL)
                if (targetUrlParts[targetUrlParts.length-1] !== targetTopicGuid) {
                    logs.debug(page.Link, 'LinkResolver', "getNavigatableLinkFromTOC::targetTopicGuid is NOT the spine-pub (last guid in url):", targetUrl, targetTopicGuid)
                    return null;
                }
            }

            if (targetElementGuid === targetTopicGuid) {
                targetElementGuid = null;
            }
            targetElementGuid = (targetElementGuid) ? "#" + targetElementGuid : "";

            //logs.debug(page.Link, 'LinkResolver', "getNavigatableLinkFromTOC::CURRENT CONTEXT LINK: GUID FOUND in tocUrls:", targetUrl)
            return `${targetUrl}${targetElementGuid}`;
        }
        //logs.debug(page.Link, 'LinkResolver', "getNavigatableLinkFromTOC::GUID NOT FOUND in tocUrls:", tocUrls, targetTopicGuid);
    }
    else {
        logs.warning(page.Link, 'LinkResolver', "getNavigatableLinkFromTOC::targetTopicGuid is null or TOC Not available to resolve in current TOC",null);
    }
    return null;
}

function getTocUrls(targetBaseUrl) {
    try {
        const linksNodeList = (document.querySelector('.side-pannel-inner-container'))?.querySelectorAll('a') || [];
        const links = Array.prototype.slice.call(linksNodeList) || [];

        return links.map(link => link.href.substring(link.href.indexOf(targetBaseUrl)))
    }
    catch {
        return [];
    }
}
function isElementInPage(targetElementGuid) {
    //When we look for an element in the page, we ONLY look in the article part, not in the TOC! (or anywhere elase)
    const elementInPage = targetElementGuid ? ARTICLEHELPERS.findElementInArticleArea(targetElementGuid) : null;
    if (elementInPage) {
        logs.debug(page.Link, 'LinkResolver', "isElementInPage::targetElementGuid/GUID FOUND in Page:", targetElementGuid, elementInPage);
        return true;
    }
    //logs.debug(page.Link, 'LinkResolver', "isElementInPage::targetElementGuid/GUID NOT FOUND in Page:", targetElementGuid);
    return false;
}

function isFolioTargetTemplateType(publication_type) {
    //case "66029858": //Auditing::RP Template Repository 66029858 66019138-66074179, 
    //case "58035381": //New:Template Repository
    //case "68489711": //New:Template Repository - Account (GRA)
    //case "68169717": //New:Template Repository - RP
    return "|66029858|58035381|68489711|68169717".indexOf(publication_type) > 0;
}

function isLocalToThisPageLink(tocUrls, url) {
    if (url.includes("#")) {
        url = url.split("#")[0];
    }
    const isLocal = tocUrls.includes(url);
    //logs.debug(page.Link, 'LinkResolver', "isLocalToThisPageLink", isLocal, url);
    return isLocal;
}

function CheckLinkForTL(href, messageCannotResolve, isResearchAssistant) {
    //TODO:Remove the hard coded way to make sure we have a TL link!
    //href = "https://techlib.deloitteresources.com/#/content/0901ff8181478b1e";
    //https://techlib.deloitteresources.com/#/content/3_100489#pg-4.1.1-10_-100489
    //https://techlib.deloitteresources.com/#/content/3_100481%23book-international_standard_on_auditing_220-100481
    //https://techlib.deloitteresources.com/#/content/2_359834%23SL679768029-359834

    if (href.indexOf("GUID") < 0 && href.indexOf("||") < 0 && href.indexOf("%7C%7C") < 0) {
        //logs.debug(page.Link, 'LinkResolver', "CheckLinkForTL:Identified as a CT link:", href);
        return `https://techlib.deloitteresources.com/#/content/${href?.replace('#', '%23')};ExtrenalTL`;
    }
    if(isResearchAssistant) {
        return ''
    } else {
        alert(messageCannotResolve);    
    }
    return false;
}

function encodeSpecialCharacters(title) {
    if (!UTILITIESHELPER.isNullOrUndefined(title)) {
        var actualTitle = title.replace(/%/gi, '%25').replace(/\?/gi, "%3F").replace(/\//gi, '%2F');
        return actualTitle;
    }
    return null;
}

function resolveLandingPageLink({ memberFirm, language }, linkTarget) {
    //logs.debug(page.Link, 'LinkResolver', "linkTarget", linkTarget);

    if (linkTarget.startsWith('/')) {
        linkTarget = linkTarget.substring(1);
        return `/${memberFirm}/${language}/${linkTarget}`;
    }
    else {
        return linkTarget;
    }
}

//some more functionality need to verify
function resolveCollectionPageSearchLink(item, memberFirm, language, knowledgeDomain, pageControllerType, searchPhrase, catalogCollection, publicationOnly = false) {
    if (item.document?.publicationType === PUBLICATIONTYPE_IDS.externalCollection) {
        pageControllerType = PAGE_CONTROLLER_TYPE.standard;
    } 
    let urlPrefix = `/${memberFirm}/${language}/${knowledgeDomain}/${pageControllerType}`;

    if ("folio".indexOf(pageControllerType) > -1) {
        try {
            let folioSection = getDynamicFolioSectionOrType(item.document.publicationType);
            let topicUrlPart = !publicationOnly ? `/${UTILITIESHELPER.encodeSpecialCharacters(item.document?.navTitle)}/${cleanGUID(item.document?.topicId)}` : "";
            let publicationUrlPart = `${folioSection}/${UTILITIESHELPER.encodeSpecialCharacters(item.document?.publicationNavTitle)}/${item.document?.publicationId}${UTILITIESHELPER.isStringNullorEmpty(topicUrlPart) ? "" : topicUrlPart}`;
            let dynamicFolioUrl = null;

            //For these types, we need to look at article-level-taging (AuditWorkFlowAreaNonAccount or AuditWorkFlowAreaAccount for those PublicationTypes at Article level)
            //GUIDES_PUBLICATON_TYPE "60194861" and ROADMAP_PUBLICATON_TYPE "60194863";
            //First we try get the one, then the other, if none are found, then we look at publication level tagging... 
            if ("60194861,60194863".indexOf(item.document.publicationType) > -1) {
                dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, item.document.auditWorkflowNonAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAuditWorkflowAreaNonAccount.items', []));
                if (dynamicFolioUrl === null) {
                    dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, item.document.auditWorkflowNonAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAssurance.items', []));
                }
                if (dynamicFolioUrl === null) {
                    dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, item.document.auditWorkflowAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAccountBalance.items', []));
                }
                if (dynamicFolioUrl !== null) {
                    return dynamicFolioUrl;
                }
            } 
            if ("60194864".indexOf(item.document.publicationType) > -1) {
                let publicationAlertsUrlPart = `${folioSection}/${UTILITIESHELPER.encodeSpecialCharacters(item.document?.navTitle)}/${item.document?.publicationId}`;
                dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationAlertsUrlPart, item.document.publicationAuditWorkflowNonAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAuditWorkflowAreaNonAccount.items', []));
                if (dynamicFolioUrl === null) {
                    dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationAlertsUrlPart, item.document.publicationAuditWorkflowNonAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAssurance.items', []));
                }
                if (dynamicFolioUrl === null) {
                    dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationAlertsUrlPart, item.document.publicationAuditWorkflowAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAccountBalance.items', []));
                }
                if (dynamicFolioUrl !== null) {
                    return dynamicFolioUrl;
                }
            }
            dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, item.document.publicationAuditWorkflowNonAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAuditWorkflowAreaNonAccount.items', []));
            if (dynamicFolioUrl === null) {
                dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, item.document.publicationAuditWorkflowNonAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAssurance.items', []));
            }
            if (dynamicFolioUrl === null) {
                dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, item.document.publicationAuditWorkflowAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAccountBalance.items', []));
            }
            if (dynamicFolioUrl !== null) {
                return dynamicFolioUrl;
            }

            logs.error(page.Link, 'LinkResolver', ErrorMessages.searchLinkResolve, null, {document:item.document,eventId:ErrorCodes.Link});
        }
        catch (err) {
            logs.error(page.Link, 'LinkResolver', ErrorMessages.folioLinkResolve, err,{document:item.document,eventId:ErrorCodes.Link} );
        }
    }
    else if ("deloitte-communications|professional-news".indexOf(pageControllerType) > -1) {
        //deloitte-communications/Practice%20Alerts/2023/GUID-A94A1363-DFDA-48B1-A822-31D31049A5A3
        //While this goes to the right place, Anand's code prevents the page from presenting the article/pdf as the search is trying to kick in!
        let publicationUrlPart = `${item.document?.publicationJournalBrand}`;
        if (item.document?.publicationTitle) {
            publicationUrlPart = `${item.document?.publicationJournalBrand}/${UTILITIESHELPER.encodeSpecialCharacters(item.document?.publicationTitle)}/${item.document.publicationId}`;
        }
        return `${urlPrefix}/${publicationUrlPart}?${SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase))}`;
    }
    else {
        let publicationUrlPart = `${UTILITIESHELPER.encodeSpecialCharacters(item.document?.publicationNavTitle)}/${item.document?.publicationId}`;
        let topicUrlPart = "";
        if (!publicationOnly && item.document?.navTitle !== "") {
            if (item.document?.topicId !== "") {
                topicUrlPart = `/${UTILITIESHELPER.encodeSpecialCharacters(item.document?.navTitle)}/${cleanGUID(item.document?.topicId)}`;
            }
        }
        return `${urlPrefix}/${publicationUrlPart}${topicUrlPart}?${SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase))}`;
    }
    //When we canont resolve, we return a # for now?
    return `${urlPrefix}`;
}

function resolveRAFormsAndTemplatesLink(document, memberFirm, language, knowledgeDomain, pageControllerType, searchPhrase, catalogCollection, publicationOnly = false) {
    let urlPrefix = `/${memberFirm}/${language}/${knowledgeDomain}/${pageControllerType}`;

    if ("folio".indexOf(pageControllerType) > -1) {
        try {
            let folioSection = getDynamicFolioSectionOrType(document.publicationType);
            let topicUrlPart = !publicationOnly ? `/${UTILITIESHELPER.encodeSpecialCharacters(document?.navTitle)}/${cleanGUID(document?.topicId)}` : "";
            let publicationUrlPart = `${folioSection}/${UTILITIESHELPER.encodeSpecialCharacters(document?.publicationNavTitle)}/${document?.publicationId}${UTILITIESHELPER.isStringNullorEmpty(topicUrlPart) ? "" : topicUrlPart}`;
            let dynamicFolioUrl = null;

            //For these types, we need to look at article-level-taging (AuditWorkFlowAreaNonAccount or AuditWorkFlowAreaAccount for those PublicationTypes at Article level)
            //GUIDES_PUBLICATON_TYPE "60194861" and ROADMAP_PUBLICATON_TYPE "60194863";
            //First we try get the one, then the other, if none are found, then we look at publication level tagging... 
            if ("60194861,60194863".indexOf(document.publicationType) > -1) {
                dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, document.auditWorkflowNonAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAuditWorkflowAreaNonAccount.items', []));
                if (dynamicFolioUrl === null) {
                    dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, document.auditWorkflowNonAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAssurance.items', []));
                }
                if (dynamicFolioUrl === null) {
                    dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, document.auditWorkflowAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAccountBalance.items', []));
                }
                if (dynamicFolioUrl !== null) {
                    return dynamicFolioUrl;
                }
            } 
            if ("60194864".indexOf(document.publicationType) > -1) {
                let publicationAlertsUrlPart = `${folioSection}/${UTILITIESHELPER.encodeSpecialCharacters(document?.navTitle)}/${document?.publicationId}`;
                dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationAlertsUrlPart, document.publicationAuditWorkflowNonAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAuditWorkflowAreaNonAccount.items', []));
                if (dynamicFolioUrl === null) {
                    dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationAlertsUrlPart, document.publicationAuditWorkflowNonAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAssurance.items', []));
                }
                if (dynamicFolioUrl === null) {
                    dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationAlertsUrlPart, document.publicationAuditWorkflowAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAccountBalance.items', []));
                }
                if (dynamicFolioUrl !== null) {
                    return dynamicFolioUrl;
                }
            }
            dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, document.publicationAuditWorkflowNonAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAuditWorkflowAreaNonAccount.items', []));
            if (dynamicFolioUrl === null) {
                dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, document.publicationAuditWorkflowNonAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAssurance.items', []));
            }
            if (dynamicFolioUrl === null) {
                dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, document.publicationAuditWorkflowAccount, get(UTILITIESHELPER.isObjectNullorEmpty(catalogCollection[0]) ? catalogCollection : catalogCollection[0], 'Content.taxonomyAccountBalance.items', []));
            }
            if (dynamicFolioUrl !== null) {
                return dynamicFolioUrl;
            }

            logs.error(page.Link, 'LinkResolver', ErrorMessages.searchLinkResolve, null, {document:document,eventId:ErrorCodes.Link});
        }
        catch (err) {
            logs.error(page.Link, 'LinkResolver', ErrorMessages.folioLinkResolve, err,{document:document,eventId:ErrorCodes.Link} );
        }
    }
    else if ("deloitte-communications|professional-news".indexOf(pageControllerType) > -1) {
        //deloitte-communications/Practice%20Alerts/2023/GUID-A94A1363-DFDA-48B1-A822-31D31049A5A3
        //While this goes to the right place, Anand's code prevents the page from presenting the article/pdf as the search is trying to kick in!
        let publicationUrlPart = `${document?.publicationJournalBrand}`;
        if (document?.publicationTitle) {
            publicationUrlPart = `${document?.publicationJournalBrand}/${UTILITIESHELPER.encodeSpecialCharacters(document?.publicationTitle)}/${document.publicationId}`;
        }
        return `${urlPrefix}/${publicationUrlPart}?${SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase))}`;
    }
    else {
        let publicationUrlPart = `${UTILITIESHELPER.encodeSpecialCharacters(document?.publicationNavTitle)}/${document?.publicationId}`;
        let topicUrlPart = "";
        if (!publicationOnly && document?.navTitle !== "") {
            if (document?.topicId !== "") {
                topicUrlPart = `/${UTILITIESHELPER.encodeSpecialCharacters(document?.navTitle)}/${cleanGUID(document?.topicId)}`;
            }
        }
        return `${urlPrefix}/${publicationUrlPart}${topicUrlPart}?${SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase))}`;
    }
    //When we canont resolve, we return a # for now?
    return `${urlPrefix}`;
}

function getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, taggedArray, subjectArray) {
    if ((Array.isArray(taggedArray) && taggedArray.length > 0)) {
        const firstSubject = taggedArray[0];
        const matchedSubject = subjectArray.filter(x => x.id === firstSubject);
        if ((Array.isArray(matchedSubject) && matchedSubject.length > 0)) {
            //logs.debug(page.Link, 'LinkResolver', "getDynamicFolioSubject::matchedSubject match:", firstSubject, matchedSubject[0]);
            return `${matchedSubject[0].href}/${publicationUrlPart}?${SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase))}`;
        }
        //As we look in multiple subject taxonomies for the same tagged subject, we will not warn if we cannot find one!
        //logs.warn(page.Link, 'LinkResolver', "getDynamicFolioSubject::No matching subject found for the first tagged subject:", firstSubject);
    }
    return null;
}

//some more functionality need to verify
function resolveSearchLink(item, memberFirm, language, knowledgeDomain, pageControllerType, searchPhrase, catalogCollection, publicationOnly = false) {
    if (item.document?.publicationType === PUBLICATIONTYPE_IDS.externalCollection) {
        pageControllerType = PAGE_CONTROLLER_TYPE.standard;
    }
    let urlPrefix = `/${memberFirm}/${language}/${knowledgeDomain}/${pageControllerType}`;

    //logs.debug(page.Link, 'LinkResolver', "resolveSearchLink::resolveSearchLink::item+:", urlPrefix, item);

    if ("folio".indexOf(pageControllerType) > -1) {
        let folioSection = getDynamicFolioSectionOrType(item.document.publicationType);
        let publicationUrlPart = publicationOnly === true ? `${folioSection}/${UTILITIESHELPER.encodeSpecialCharacters(item.document?.publicationNavTitle)}/${item.document?.publicationId}` : "";

        try {
            let dynamicFolioUrl = null;
            let taxonomyAuditWorkflowAreaNonAccountitems =  (get(catalogCollection[0], 'taxonomylist', []))?.filter(list => list.id === "66019141" )[0]?.items || [];
            let taxonomyAuditWorkflowAreaAccountitems =  (get(catalogCollection[0], 'taxonomylist', []))?.filter(list => list.id === "66019138" )[0]?.items || [];
            //For these types, we need to look at article-level-taging (AuditWorkFlowAreaNonAccount or AuditWorkFlowAreaAccount for those PublicationTypes at Article level)
            //GUIDES_PUBLICATON_TYPE "60194861" and ROADMAP_PUBLICATON_TYPE "60194863";
            //First we try get the one, then the other, if none are found, then we look at publication level tagging...
            if ("60194861,60194863".indexOf(item.document.publicationType) > -1) {
                dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, item.document.auditWorkflowNonAccount, taxonomyAuditWorkflowAreaNonAccountitems);
                if (dynamicFolioUrl === null) {
                    dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, item.document.auditWorkflowNonAccount, get(catalogCollection[0], 'Content.taxonomyAssurance.items', []));
                    //console.log("assurance check: ", dynamicFolioUrl)
                }
                if (dynamicFolioUrl === null) {
                    dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, item.document.auditWorkflowAccount, taxonomyAuditWorkflowAreaAccountitems);
                }
                if (dynamicFolioUrl !== null) {
                    return dynamicFolioUrl;
                }
            }

            dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, item.document.publicationAuditWorkflowNonAccount, taxonomyAuditWorkflowAreaNonAccountitems);
            if (dynamicFolioUrl === null) {
                dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, item.document.publicationAuditWorkflowNonAccount, get(catalogCollection[0], 'Content.taxonomyAssurance.items', []));
                //console.log("assurance check: ", dynamicFolioUrl)
            }
            if (dynamicFolioUrl === null) {

                dynamicFolioUrl = getDynamicFolioWithSubjectUrl(searchPhrase, publicationUrlPart, item.document.publicationAuditWorkflowAccount, taxonomyAuditWorkflowAreaAccountitems);
            }
            if (dynamicFolioUrl !== null) {
                //console.log("Found tag on #2 found on Publication-Level-Tagging:", dynamicFolioUrl);
                return dynamicFolioUrl;
            }
            logs.error(page.Link, 'LinkResolver', ErrorMessages.searchLinkResolve_Folio, null, {document:item,eventId:ErrorCodes.Link});
        }
        catch (err) {
            logs.error(page.Link, 'LinkResolver',ErrorMessages.folioLinkResolve , err, {document:item.document,eventId:ErrorCodes.Link});
        
        }
    }
    else if ("deloitte-communications|professional-news".indexOf(pageControllerType) > -1) {
        //deloitte-communications/Practice%20Alerts/2023/GUID-A94A1363-DFDA-48B1-A822-31D31049A5A3
        //While this goes to the right place, Anand's code prevents the page from presenting the article/pdf as the search is trying to kick in!
        let publicationUrlPart = `${item.document?.publicationJournalBrand}`;
        if (item.document?.publicationTitle) {
            publicationUrlPart = `${item.document?.publicationJournalBrand}/${UTILITIESHELPER.encodeSpecialCharacters(item.document?.publicationTitle)}/${item.document.publicationId}`;
        }
        return `${urlPrefix}/${publicationUrlPart}?${SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase))}`;
    }
    else {
        let publicationUrlPart = `${UTILITIESHELPER.encodeSpecialCharacters(item.document?.publicationNavTitle)}/${item.document?.publicationId}`;
        let topicUrlPart = "";
        if (!publicationOnly && item.document?.navTitle !== "") {
            if (item.document?.topicId !== "") {
                topicUrlPart = `/${UTILITIESHELPER.encodeSpecialCharacters(item.document?.navTitle)}/${cleanGUID(item.document?.topicId)}`;
            }
        }
        return `${urlPrefix}/${publicationUrlPart}${topicUrlPart}?${SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase))}`;
    }

    //When we canont resolve, we return a # for now?
    return `${urlPrefix}?${SearchHelpers.toQueryString(SearchHelpers.parse(searchPhrase))}`;
}
//BDP:TODO: This function needs to get passed, or have the PageContext.MemberFirms - List ot MF's from Taxonomy, to derrive the correct MF ID'
async function resolveLink(hrefValue, datasetPublication, tocUrls, memberFirm, language, knowledgeDomain, messageCannotResolve, isSearchResultLink, memberFirms, isResearchAssistant = false, tlMemberFirmTaxonomyId = null) {
    // Since the CT Links were initially designed to have %23 instead of # this replace will work as fail proof implementation.
    let targetTopicGuid = hrefValue?.replace('%23', '#');
    let targetElementGuid = null;
    let isLinkCT = false;
    let navigatableLink = null;
    let location = cleanLocationURL(`/${memberFirm}/${language}/${knowledgeDomain}`);
   
    

    ({ targetTopicGuid, targetElementGuid, isLinkCT } = extractGUIDs(targetTopicGuid));

    logs.debug(page.Link, 'LinkResolver', "resolveLink::hrefValue, targetTopicGuid, targetElementGuid:", hrefValue, targetTopicGuid, targetElementGuid);

    //In all cases, we look first if the link is in the current Page, then the TOC
    if (!isSearchResultLink) {
        if (isElementInPage(targetElementGuid)) {
            logs.debug(page.Link, 'LinkResolver', "resolveLink::#0.2 [#HREF] Target targetElementGuid found on page", location, targetElementGuid);
            return `${location}#${targetElementGuid}`;
        }
        if (isElementInPage(targetTopicGuid)) {
            logs.debug(page.Link, 'LinkResolver', "resolveLink::#0.1 [#HREF] Target targetTopicGuid found on page", location, targetTopicGuid);
            return `${location}#${targetTopicGuid}`;
        }
    }

    if (tocUrls !== null) {
        //When we have a legacy ID, we push the targetElement in here instead, just in case its found in the TOC
        let navigatableLink = getNavigatableLinkFromTOC(tocUrls, targetTopicGuid || targetElementGuid, targetElementGuid);
        if (navigatableLink) {
            logs.debug(page.Link, 'LinkResolver', "resolveLink::#1 [HREF] Target targetTopicGuid found in current TOC",targetTopicGuid)
            return navigatableLink;
        }
    }

    //We have not navigated to another topic on the current page or in the current TOC, 
    //so lets take a look if we can find the target with an API call.
    try {
        //We send what we "had" in the sanitized href value to the BE to do a link resolve;
        let publication = null
        let opmMF = ProfileHelpers.getMemberFirm();
        //check id MF in query string parameter available
        // if yes then extrach and pass it to this method to fetch country code from countrySettings by MF paramenter value
        //example scenario: "/content/docid=2_427478;memberFirm=17573163"
        let memberFirmSettingObjectByTL = UTILITIESHELPER.getMmeberFirmSettingByTlTaxonomyId(memberFirms, tlMemberFirmTaxonomyId);
        let tlMemberFirm = !UTILITIESHELPER.isObjectNullorEmpty(memberFirmSettingObjectByTL) ? memberFirmSettingObjectByTL.reference.toLowerCase() : null;

        publication = (await RETRIEVALSERVICES.resolveTargetLink(targetTopicGuid, memberFirm, targetElementGuid, datasetPublication, opmMF, knowledgeDomain, tlMemberFirm));      
        let isExternalCollection = publication.publication_type === PUBLICATIONTYPE_IDS.externalCollection;
        logs.debug(page.Link, 'LinkResolver', "resolveLink::resolveLink API Response(hrefValue):", hrefValue, publication);

        if (publication?.content?.object_id) {
            //If we did not have a target element # to scroll down to in target page (i.e. no anchor tag in orriginal href)
            if (!targetElementGuid) {
                //If the target content GUID is not the same as the orriginal href's GUID, 
                //  then the original target GUID becomes the targetElement (i.e. the target GUID becomes the new anchor tag to scroll down to on the target page)
                if (publication.content.object_id !== targetTopicGuid) {
                    logs.debug(page.Link, 'resolveLink::LinkResolver', "#2 [API] REPLACE targetElementGuid. No orriginal anchor, and target is not the same as orriginal guid, so orriginal href-guid becomes the targetElement/ahchor in new link. [targetTopicGuid->targetElementGuid]",targetTopicGuid)
                    targetElementGuid = targetTopicGuid;
                    if(isExternalCollection){
                        if(publication.nested_topic_id){
                            targetElementGuid=publication.nested_topic_id;
                        }
                    }
                    
                    if (isElementInPage(targetElementGuid)) {
                        logs.debug(page.Link, 'LinkResolver', "resolveLink::#2.1 [#HREF] Target targetElementGuid found on page", location, targetElementGuid);
                        return `${location}#${targetElementGuid}`;
                    }
                }
            }

            targetTopicGuid = GetTargetTopicOrSpineTargetPublicationGUID(publication);

            if (isElementInPage(targetTopicGuid)) {
                logs.debug(page.Link, 'LinkResolver', "resolveLink::#2.2 [#HREF] Target targetTopicGuid found on page", location, targetTopicGuid);
                return `${location}#${targetTopicGuid}`;
            }

            //With the CT/Standard links, we now have a converted guid and old guid cases, and we need to address BOTH
            //  To be generic, we will then pass BOTH the "id"'s to the # and look for both of them on the page!
            let potentialTargetElementGuid
            if (isExternalCollection) {
                potentialTargetElementGuid = (publication?.nested_topic_id) ? publication.nested_topic_id : targetElementGuid;
            }
            else {
                potentialTargetElementGuid = (isLinkCT) ? (publication.content.content?.object_id) ? publication.content.content.object_id : (publication.content?.object_id) ? publication.content.object_id : targetElementGuid : targetElementGuid;
            }

            logs.debug(page.Link, 'LinkResolver', "resolveLink::Targets:targetTopicGuid, targetElementGuid, potentialTargetElementGuid", targetTopicGuid, targetElementGuid, potentialTargetElementGuid);

            //We need to check the target first, to ensure that its not the same as the target topic guid. If it is not, then we replace the targetElementGuid with it (as thats then the article we would like to scroll down to)
            if ((targetTopicGuid !== potentialTargetElementGuid) && (targetTopicGuid.indexOf(potentialTargetElementGuid) === -1) && (targetElementGuid !== potentialTargetElementGuid)) {
                logs.debug(page.Link, 'LinkResolver', "resolveLink::If Condition (targetTopicGuid !== potentialTargetElementGuid) && (targetTopicGuid.indexOf(potentialTargetElementGuid) === -1) && (targetElementGuid !== potentialTargetElementGuid)", true);

                //If the context.context.object_id is the same as the context.object_id (then we have the spine context, and we can just ignore the addition as we are navigating to this article/pub anyway)
                //if ((publication.content.content?.object_id) && (publication.content.object_id !== publication.content.content?.object_id)) {
                //Orrignial, and then addition for appendix links on the same page:
                if (((publication.content.content?.object_id) && (publication.content.object_id !== publication.content.content?.object_id))
                    || (cleanGUID(publication.context.id) !== publication.content?.object_id)) {
                    if (isElementInPage(potentialTargetElementGuid)) {
                        logs.debug(page.Link, 'LinkResolver', "resolveLink::#2.3 [#HREF] Target potentialTargetElementGuid found on page", location, potentialTargetElementGuid);
                        return `${location}#${potentialTargetElementGuid}`;
                    }
                    
                    targetElementGuid = potentialTargetElementGuid + linkHashSeparator + targetElementGuid;
                    logs.debug(page.Link, 'LinkResolver', "resolveLink::#2 [API] NEW targetElementGuid with potentialTargetElementGuid included:", targetElementGuid)
                }
            }

            if (tocUrls !== null) {
                navigatableLink = getNavigatableLinkFromTOC(tocUrls, targetTopicGuid, targetElementGuid, publication?.spine?.object_id !== null);
                if (navigatableLink) {
                    logs.debug(page.Link, 'LinkResolver', "resolveLink::#2 [API] Target publication.content.object_id found in current TOC, navigatableLink, targetElementGuid", targetTopicGuid, navigatableLink, targetElementGuid);
                    return navigatableLink;
                }
            }

            if (isSearchResultLink) {
                //We HAVE to find the target in the current TOC!
                //go one level heigher to see if we can find that resolved GUID in the TOC...
                publication = (await RETRIEVALSERVICES.resolveTargetLink(publication?.content?.object_id, memberFirm, "", datasetPublication, opmMF, knowledgeDomain, tlMemberFirm));
                logs.debug(page.Link, 'LinkResolver', "resolveLink::resolveLink Second API Response for search #2: ", publication.content.object_id, publication);

                targetTopicGuid = GetTargetTopicOrSpineTargetPublicationGUID(publication);

                if (tocUrls !== null) {
                    navigatableLink = getNavigatableLinkFromTOC(tocUrls, targetTopicGuid, targetElementGuid, publication?.spine?.object_id !== null);
                    if (navigatableLink) {
                        //logs.debug(page.Link, 'LinkResolver', "resolveLink::#2.s.2 [API] Target toc.content.object_id found in current TOC", targetTopicGuid);
                        return navigatableLink;
                    }
                }

                //else-recurse one level heigher
                publication = (await RETRIEVALSERVICES.resolveTargetLink(publication?.content?.object_id, memberFirm, "", datasetPublication, opmMF, knowledgeDomain, tlMemberFirm));

                //logs.debug(page.Link, 'LinkResolver', "resolveLink::resolveLink API Response for search #3: ", publication);

                if (tocUrls !== null) {
                    navigatableLink = getNavigatableLinkFromTOC(tocUrls, publication?.content?.object_id, targetElementGuid, publication?.spine?.object_id !== null);
                    if (navigatableLink) {
                        //logs.debug(page.Link, 'LinkResolver', "resolveLink::#2.s.3 [API] Target toc.content.object_id found in current TOC", publication.content.object_id);
                        return navigatableLink;
                    }
                    let errormessage=`Cannot find target in the current Table Of Contents on this Page. Therefore cannot resolve/navigate to that location.TopicId:${publication?.content?.object_id} TargetElementId:${targetElementGuid}`;
                    logs.error(page.Link, "ResolveLink", errormessage);
                    alert("Cannot find target in the current Table Of Contents on this Page. Therefore cannot resolve/navigate to that location.");
                }

                //If we did not find the target in the current TOC, then we cannot navigate to it!!
                return false;
            }

            //nav_title is not available, so try "fix" the title.
            if (publication.content.title.includes("—")) {
                publication.content.title = publication.content.title.split("—")[1].trim();
            }
            let publicationUrlPart = `${encodeSpecialCharacters(publication.context.nav_title)}/${publication.id}`;
            let topicUrlPart = `/${encodeSpecialCharacters(publication.content.title)}/${publication.content.object_id}`;
            
            logs.debug(page.Link, 'LinkResolver', "publication.publication_type .. publicationUrlPart/topicUrlPart", publicationUrlPart, topicUrlPart);

            if (isFolioTargetTemplateType(publication.publication_type)) {
                logs.debug(page.Link, 'LinkResolver', "resolveLink::isFolioTargetTemplateType:SWITCH for Template type: publication.publication_type, publicationUrlPart/topicUrlPart", publicationUrlPart, topicUrlPart);
                //We are going to change the GUID around, as the target "element" is actually the target topic
                topicUrlPart = `/${encodeSpecialCharacters(publication.content.title)}/${publication.content.content.object_id}`;
                //We going to clear the target element so that the page does not scroll, as we are navigating to a Template!
                targetElementGuid = "";
            }

            //Spine: Manual/Standard Section - I need the parent spine pub id! (otherwise the link opens up in context of pub - not spine-pub)
            if (publication.publication_type !== null && publication.publication_type !== "" && "48082110,48082111,65469054".includes(publication.publication_type)) {
                logs.debug(page.Link, 'LinkResolver', "resolveLink::Spine, Section Manual/Standard:publication.publication_type", publication.publication_type);

                targetTopicGuid = cleanGUID(publication.context.id);
                if (!targetElementGuid && publication.content.object_id !== targetTopicGuid && publication.id !== targetTopicGuid) {
                    logs.debug(page.Link, 'LinkResolver', "resolveLink::No orriginal anchor, and target is not the same as orriginal guid, so orriginal href-guid becomes the targetElement/ahchor in new link. [targetTopicGuid->targetElementGuid]", targetTopicGuid)
                    targetElementGuid = publication.content.object_id;
                }

                if (tocUrls !== null) {
                    navigatableLink = getNavigatableLinkFromTOC(tocUrls, targetTopicGuid, targetElementGuid);
                    if (navigatableLink) {
                        logs.debug(page.Link, 'LinkResolver', "resolveLink::#3.1 [API]Spine: Target publication.content.object_id found in current TOC", targetTopicGuid);
                        return navigatableLink;
                    }
                    //As we have the spine, try again to find it in the TOC (just in case we have the wrong context)
                    navigatableLink = getNavigatableLinkFromTOC(tocUrls, cleanGUID(publication.id), targetElementGuid, true);
                    if (navigatableLink) {
                        logs.debug(page.Link, 'LinkResolver', "resolveLink::#3.2 [API]SpineTarget: Target publication.id found in current TOC (As SpineTarget)", targetTopicGuid);
                        return navigatableLink;
                    }
                }

                //We ALWAYS used to have the spine object when we had these types, but now we are getting a null spine?!
                //For this reason we wil check first, and NOT replace it
                if (publication.spine?.object_id !== null && publication.spine?.object_id !== "") {
                    logs.debug(page.Link, 'LinkResolver', "resolveLink::Replacing publicationUrlPart with this spine:", publication.spine);

                    // when "publication_type": "48082110" or "48082111"
                    // spine: "content_type": "60194866"
                    // Test%20Auditing%20Manual/ === spine.nav_title
                    // GUID-93F87B9A-72AA-4A82-A8E8-03C2DA6F3354/ === spine.object_id
                    publicationUrlPart = `${encodeSpecialCharacters(publication.spine.nav_title)}/${publication.spine.object_id}`;
                }

                //While we are setting this here, below this will usually get replaced/removed anyway!
                // [Manual%20Section%20title%20-%20Heading%201]%20Materiality/ === context.title
                // GUID-57E01081-B02B-4FD5-8A72-2032275453E4/ === context.id (strip =..)
                // GUID-EF227232-4B83-4C1A-AE49-D485E5CCCBA4# === id === object_id === targetTopicGuid
                // GUID-BB1294A4-CC1C-480C-B182-7E4F2FE553E4 === href (original)
                topicUrlPart = `/${encodeSpecialCharacters(publication.context.nav_title)}/${targetTopicGuid}/${publication.id}`;

                if (publication.spine?.content_type !== null && publication.spine?.content_type !== "") {
                    //We update the publication type with the spine's type, as that is the "publication" that we are ultimately navigating to, that houses the target publication
                    publication.publication_type = publication.spine.content_type;
                }
            }

            //Section with intro topic (65469055) (should we also clear?)

            //Pub Context Topic (60247324) (e.g. inside normal pub ===> landing page)
            //Pub Context Spine Topic (60262021) (e.g inside the 13100 pub ===> land on the spine pub)
            // - are not in the TOC, so we just navigate to the Publication
            if (publication.content.content_type !== null && publication.content.content_type !== "" && "60247324".includes(publication.content.content_type)) {
                if (publication.spine.object_id !== null && publication.spine.object_id !== "") {
                    logs.debug(page.Link, 'LinkResolver', "resolveLink::Content type is Context topic Publication Context: Changing link to spine and context, targetElementGuid", targetElementGuid);

                    if (tocUrls !== null) {
                        navigatableLink = getNavigatableLinkFromTOC(tocUrls, cleanGUID(publication.context.id), targetElementGuid);
                        if (navigatableLink) {
                            logs.debug(page.Link, 'LinkResolver', "resolveLink::#3.2 [API] Spine Target publication.content.object_id found in current TOC", cleanGUID(publication.context.id));
                            return navigatableLink;
                        }
                    }

                    publicationUrlPart = `${encodeSpecialCharacters(publication.spine.nav_title)}/${publication.spine.object_id}`;
                    topicUrlPart = `/${encodeSpecialCharacters(publication.context.nav_title)}/${cleanGUID(publication.context.id)}/${publication.id}`;
                    if (!isLinkCT) {
                        targetElementGuid = "";
                    }
                }
                else {
                    //As long as we are not navigating to a template topic, we will clear the topicUrlPart so that we land on the spine!
                    if (!isFolioTargetTemplateType(publication.publication_type)) {
                        logs.debug(page.Link, 'LinkResolver', "resolveLink::NOT.isFolioTargetTemplateType:Content type is Context topic Publication Context: but spine is null, so clearing the topicUrlPart so we end up on the landing page with no anchor.", topicUrlPart, targetElementGuid)
                        topicUrlPart = "";
                        targetElementGuid = "";
                    }
                }
            }

            if (publication.content.content_type !== null && publication.content.content_type !== "" && "60262021".includes(publication.content.content_type)) {
                logs.debug(page.Link, 'LinkResolver', "resolveLink::Content type is Context topic, clearing the topicUrlPart so we end up on the landing page with no anchor.", topicUrlPart, targetElementGuid)
                topicUrlPart = "";
                targetElementGuid = "";
            }

            let targetMemberFirm = publication.target_country_code;
            let targetKnowledgeDomain = publication.target_knowledge_domain;
            let targetLanguage = publication.target_language;

            //logs.debug(page.Link, 'LinkResolver', "resolveLink::targetMemberFirm, targetKnowledgeDomain", targetMemberFirm, targetKnowledgeDomain)

            //Add on the # element as the last thing - if it exists as it will be added to the end of the url by default.
            targetElementGuid = (targetElementGuid) ? "#" + targetElementGuid : "";

            // case "65511719": //Journal (External alerts & announcements)
            // case "60194864": //Journal (alerts & announcements)
            if (publication.publication_type !== null && publication.publication_type !== "" && (publication.publication_type === "65511719" || publication.publication_type === "60194864")) {
                if (tocUrls !== null) {
                    navigatableLink = getNavigatableLinkFromTOC(tocUrls, publication.id, "");
                    if (navigatableLink) {
                        //logs.debug(page.Link, 'LinkResolver', "resolveLink::#4 [API] Journal:Target publication.id found in current TOC", publication.id);
                        return navigatableLink;
                    }
                }

                //TODO: Need to confirm if its available, and then change this to get it from pub-type:
                //deloitte = PubType === announcement
                //others = PubType === announcement - external
                //Announcement === 60194864
                //Announcement - External === 65511719
                logs.debug(page.Link, 'LinkResolver', "resolveLink::resolveLink-URL:", `/${targetMemberFirm}/${targetLanguage}/${targetKnowledgeDomain}/${deturmineJournalType(publication.context.journal_brand)}/${publication.context.journal_brand}/${publication.current_date.split("-")[0]}/${publication.id}${targetElementGuid}`);
                return `/${targetMemberFirm}/${targetLanguage}/${targetKnowledgeDomain}/${deturmineJournalType(publication.context.journal_brand)}/${publication.context.journal_brand}/${publication.current_date.split("-")[0]}/${publication.id}${targetElementGuid}`;
            }

            let dynamicFolioSectionOrType = getDynamicFolioSectionOrType(publication.publication_type);
            //logs.debug(page.Link, 'LinkResolver', "resolveLink::dynamicFolioSectionOrType, publication", dynamicFolioSectionOrType, publication);

            //Only if we have this dynamic folio-subject value, are we able to navigate to a folio!
            if (publication.taxonomyname) {
                logs.debug(page.Link, 'LinkResolver', "resolveLink::resolveLink-URL folio-type with publication.taxonomyname:", `/${targetMemberFirm}/${targetLanguage}/${targetKnowledgeDomain}/folio/${publication.taxonomyname}/${dynamicFolioSectionOrType}/${publicationUrlPart}${topicUrlPart}${targetElementGuid}`);

                return (`/${targetMemberFirm}/${targetLanguage}/${targetKnowledgeDomain}/folio/${publication.taxonomyname}/${dynamicFolioSectionOrType}/${publicationUrlPart}${topicUrlPart}${targetElementGuid}`);
            }
            //else if ("auditing|accounting|assurance".indexOf(targetKnowledgeDomain) === -1) {
            //    return false;
            //}
            else {
                logs.debug(page.Link, 'LinkResolver', "resolveLink::resolveLink-URL publication-type:", `/${targetMemberFirm}/${targetLanguage}/${targetKnowledgeDomain}/${dynamicFolioSectionOrType}/${publicationUrlPart}${topicUrlPart}${targetElementGuid}`);
                //At this point, we assume that the target is a "publication" and the URL should be generatable
                return (`/${targetMemberFirm}/${targetLanguage}/${targetKnowledgeDomain}/${dynamicFolioSectionOrType}/${publicationUrlPart}${topicUrlPart}${targetElementGuid}`);
            }
        }
    }
    catch (err) {
        logs.error(page.Link, 'LinkResolver', ErrorMessages.linkResolver, err,{eventId:ErrorCodes.Link});
    }

    return CheckLinkForTL(hrefValue, messageCannotResolve, isResearchAssistant )
}

const getMemberFirmSettings = (mf, memberFirms) => {
    return memberFirms?.filter(s => s.memberFirmSettings.country === mf || s.memberFirmSettings.defaultMemberFirm === mf)
}
function resolveMemberFirmUrl(baseUrl, currentLocation) {
    //For unit testing purposes
    if (!currentLocation) {
        currentLocation = window.location.pathname;
    }

    var urlArray = currentLocation.split("/");
    if (currentLocation !== baseUrl) {
        let newLink = baseUrl;
        urlArray.forEach((item, index) => {
            if (index > 2) {
                newLink = newLink + '/' + item;
            }
        });

        return newLink;
    }

    return baseUrl;
}

function resolveMemberFirmUrlBackToHome(baseUrl, currentLocation) {
    if (!currentLocation) {
        //var urlArray = window.location.pathname.split("/");
        if (window.location.pathname !== baseUrl) {
            let newLink = baseUrl;
            //urlArray.forEach((item, index) => {
            //	if (index > 2 && index < 5) {
            //		newLink = newLink + '/' + item;
            //	}
            //});

            return newLink;
        }
    }
    else {
        //var urlArray = window.location.pathname.split("/");
        if (currentLocation !== baseUrl) {
            var newLink = baseUrl;
            //urlArray.forEach((item, index) => {
            //	if (index > 2 && index < 5) {
            //		newLink = newLink + '/' + item;
            //	}
            //});

            return newLink;
        }
    }

    return baseUrl;
}

function TocUrlContainsText(text, searchText) {
    return text?.includes(searchText);
}


const LINKHELPERS = {
    cleanGUID,
    existsInsideParentClass,
    getClosestLinkElement,
    getDataSource,
    getGUID,
    getNavigatableLink,
    getTocUrls,
    isLocalToThisPageLink,
    resolveLandingPageLink,
    resolveLink,
    resolveMemberFirmUrl,
    resolveMemberFirmUrlBackToHome,
    resolveSearchLink,
    resolveCollectionPageSearchLink,
    sanitizeUrl,
    TocUrlContainsText,
    cleanLocationURL,
    deturmineJournalType,
    getDynamicFolioSectionOrType,
    GetTargetTopicOrSpineTargetPublicationGUID,
    isFolioTargetTemplateType,
    CheckLinkForTL,
    encodeSpecialCharacters,
    getDynamicFolioWithSubjectUrl,
    extractGUIDs,
    getTargetKnowledgeDomain,
    getNavigatableLinkFromTOC,
    hasClass,
    isFootnoteTopLink,
    isElementInPage,
    resolveRAFormsAndTemplatesLink
}

export default LINKHELPERS;