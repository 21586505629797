import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate, useParams } from 'react-router-dom'
import { usePageContext, usePageContextLabels, usePageContextSettings } from '../../contexts/PageContext';
import { logs, page } from '../../helpers/log';
import RETRIEVALSERVICES from '../../services/rp-service';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import ArticleResolver from '../Publication/ArticleResolver';
import Loading from '../Loading/Loading';
import { useArticleContext } from '../../contexts/ArticleContext';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import { useTocContext } from '../../contexts/TocContext';
import ARTICLEHELPERS from '../../helpers/ArticleHelpers';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import RecentViewedService from '../../helpers/RecentlyViewedServices';
import { ErrorCodes, ErrorMessages } from '../Constants/Errors';
import { useRecentViewContext } from '../../contexts/RecentViewContext';


const JournalArticle = (props) => {

    const { tierVal, industryVal, getMemberFirmID } = usePageContext();
    const { hasFetchedArticleData, setHasFetchedArticleData } = props;
    const { knowledgeDomain, memberFirm, language} = useParams();
     
    const [pubData, setPubData] = useState({});
    const { isRecentlyViewedEnabled,isRestrictedAccessMemberfirm } = usePageContextSettings();
    const { recentViewHistory, setRecentViewHistory } = useRecentViewContext()

    const { setArticleVersion } = useArticleContext();

    const navigate = useNavigate();

    const {
        context,
        isNavigatedFromCollectionSearch,
        isSpinePage,
        isJournalPage
    } = usePageContext();

    const selectedItemTitle = context.pageSelectedPublicationGUID;
    const selectedItemGUID = context.pageSelectedContentItemGUID;
    const selectedSpineTargetPublicationGUID = context.pageSelectedSpineTargetPublicationGUID;
    const journalType = context.pageControllerType;
    const journalBrand = context.pageSelectedPublicationTitle;

    const { setSearchResults, setActiveFilters, tocControl,
        setAreSearchResultsLoading, latestRetrieveSearchResultsTimeStampRef } = useSearchResultContext();

    const { toc, flattenedToc } = useTocContext();

    const { getMFLabel } = usePageContextLabels();
    const { getGuidebookSectionData } = useGuidebookTocContext();

    useEffect(() => {

        // There is a memory leak being caused by fetching the pub after the user navigates way
        // https://dev.to/pallymore/clean-up-async-requests-in-useeffect-hooks-90h

        const retrievePublication = async () => {
            try {
                //Only load a journal if we have a journal
                if (selectedItemGUID !== undefined) {
                    setHasFetchedArticleData(false)
                    const res = await RETRIEVALSERVICES.retrieveJournalPublication();
                    logs.debug(page.Article, 'JournalArticle', "retrieveJournalPublication:context, res", context, res);

                    if (!UTILITIESHELPER.isNullOrUndefined(res)) {
                        setPubData(res);
                        setArticleVersion(res.version);
                    }
                    setHasFetchedArticleData(true)

                    const journalTitle = res?.content[0]?.content?.nav_title;

                    logs.trackPageView({
                        name: `Journal - ${journalBrand} - ${selectedItemTitle} - ${journalTitle}`,
                        properties: {
                            memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                            serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                            level: UTILITIESHELPER.getSessionStorage('Level')
                        }
                    })

                    if (isRecentlyViewedEnabled() && !isRestrictedAccessMemberfirm()) {
                        try {
                            // new recently viewed implementation
                            const recentViewUpdatedHistory = await RecentViewedService.updateHistoryObject(window.location.pathname, `${journalBrand} / ${selectedItemTitle} / ${journalTitle}`, getMemberFirmID(), context, recentViewHistory);
                            setRecentViewHistory(recentViewUpdatedHistory)
                        }
                        catch (error) {
                            logs.error(page.JournalArticle, "RecentlyViewed", ErrorMessages.recentlyViewedUpdate, error,{eventId:ErrorCodes.JournalArticle});
                        }
                    }
                }
            }
            catch (err) {
               logs.error(page.JournalArticle, "JournalArticle", ErrorMessages.recentlyViewed, err,{eventId:ErrorCodes.JournalArticle});
                //If the article cannot load, then we wormhole up to the parent manual, window.location reloads the WHOLE page, which is NOT necesary
                //selectedItemTitle: "2021"
                Navigate(`/${memberFirm}/${language}/${knowledgeDomain}/${journalType}/${journalBrand}?wormhole=true`);
            }
        }

        retrievePublication()
        //eslint-disable-next-line
    }, [selectedItemGUID, selectedSpineTargetPublicationGUID, tierVal, industryVal])

    useEffect(() => {
        if (isNavigatedFromCollectionSearch ) {
            const MapSearchResults = async () => {
                const currentReqTime = Date.now();
                latestRetrieveSearchResultsTimeStampRef.current = currentReqTime;

                if (UTILITIESHELPER.isArrayNullorEmpty(flattenedToc)
                    || !Array.isArray(flattenedToc) || flattenedToc?.length === 0
                    || currentReqTime !== latestRetrieveSearchResultsTimeStampRef.current)
                    return;

                try {
                    setAreSearchResultsLoading(true);
                    setSearchResults([]);
                    setActiveFilters([]);

                    let results = tocControl.find(x => x.id === decodeURI(context.pageSelectedPublicationTitle))?.searchResult
                    if (!UTILITIESHELPER.isArrayNullorEmpty(results)) {
                        let searchresults = results.filter(x => x.highlightsCount > 0)
                        const extendedResults = ARTICLEHELPERS.mapSearchResultsToSelectedToc(searchresults, context, isJournalPage, flattenedToc, isSpinePage, getMFLabel, toc, getGuidebookSectionData);

                        if (!UTILITIESHELPER.isArrayNullorEmpty(extendedResults)) {
                            setSearchResults(extendedResults);
                        }
                        else {
                            setSearchResults([]);
                        }
                    }
                    else {
                        setSearchResults([]);
                    }
                    setAreSearchResultsLoading(false);
                }
                catch (err) {
                    logs.error(page.Publication, 'JournalSearch', ErrorMessages.journalSearch, err, { memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'), knowledgeDomain: context.knowledgeDomain,eventId:ErrorCodes.Publication });
                    navigate(CONTEXTHELPERS.getFallbackUrl(window.location.href));
                }
            }
            MapSearchResults()

        }
        //eslint-disable-next-line
    }, [isNavigatedFromCollectionSearch, flattenedToc]);


    if (!hasFetchedArticleData) {
        return <Loading />
    }

    return (
        <ArticleResolver
            {...props}
            pubData={pubData}
        />
    )
}

export default JournalArticle;
