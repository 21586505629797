import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import { logs,  appInsightUIMapping } from "../../helpers/log";
import { usePageContextLabels,usePageContext, usePageContextSettings } from '../../contexts/PageContext';
import cx from 'classnames';
import RECENTSEARCHSERVICES from './../../helpers/RecentSearchService';
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import SearchHelpers from '../../helpers/SearchHelpers';
import RecentSearches from './RecentSearches';
import { SCS_KEYS, getFallback } from '../Constants/SCSConstants';
import { ACTIONS, useUserPreferencesContext } from '../../contexts/AnnotationsContext';
import { ErrorTypes } from '../Constants/Errors';


const Searchbar = ({ style, className, placeholder, value = "", classNameForm,  disableState = false }) => {
    const { setSearchTimeStamp } = useSearchResultContext();
    const navigate = useNavigate();
    const { getLabel } = usePageContextLabels();
    const [recentHistory, setRecentHistory] = useState();//eslint-disable-line
    const [showRecentHistory, setShowRecentHistory] = useState(false);
    const location = useLocation();
   // const [PageURL] = useState(location.pathname);
    const { context, setPageURLWhereSearchInnitiated, setSearchPhrase, searchPhrase, setNotification, getMemberFirmID } = usePageContext();//eslint-disable-line
    //const guid = !UTILITIESHELPER.isNullOrUndefined(context.pageSelectedSpineTargetPublicationGUID) ? context.pageSelectedContentItemGUID : context.pageSelectedSpineTargetPublicationGUID;
    const inputRef = useRef();
    const { state: annState, dispatch } = useUserPreferencesContext();
    const { isRecentSearchEnabled } = usePageContextSettings();

    const wildcardsPrefixSearchLimitationNotification = getLabel(SCS_KEYS.wildcardsPrefixSearchLimitation, getFallback(SCS_KEYS.wildcardsPrefixSearchLimitation));

    const handleClickOutsideSearchInput = event => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setShowRecentHistory(false);
        }
    };


    function updateRecentSearches(searchphrase) {
        const updatedRecentSearchList =
        {
            "search_phrase": searchphrase,
            "memberfirm": context.memberFirm,
            "knowledgeDomain": context.knowledgeDomain,
            "created_date": JSON.parse(JSON.stringify(new Date())),

        }
        let previousRecentSearch = JSON.parse(localStorage.getItem('recentSearch'));
        let recentSearchList = previousRecentSearch != null ? (previousRecentSearch.filter(recentsearch=>recentsearch.memberfirm===context.memberFirm && recentsearch.knowledgeDomain===context.knowledgeDomain)?? []) : []
        const existingIndex = recentSearchList.findIndex(item => item.search_phrase === searchphrase);
        if (existingIndex !== -1) {
            recentSearchList.splice(existingIndex, 1);
        }
        recentSearchList.unshift(updatedRecentSearchList);
       
        if (recentSearchList.length > window.DART.ENV.REACT_APP_RECENTLY_SEARCH_LIMIT) {
            recentSearchList.pop();
        }
        const allRecentSearches= previousRecentSearch != null ? (previousRecentSearch.filter(recentsearch=>recentsearch.memberfirm!==context.memberFirm  ||  recentsearch.knowledgeDomain !==context.knowledgeDomain)?? []) : []
        allRecentSearches.push(...recentSearchList);
        localStorage.setItem("recentSearch", JSON.stringify(allRecentSearches));
        dispatch({
            type: ACTIONS.RECENT_SEARCH_LOAD,
            payload: JSON.stringify(allRecentSearches),
        });
    }

    if (!placeholder) {
        placeholder = getLabel("s_Search", "Search");
    }

    const handleChange = event => {
        setSearchPhrase(event.target.value);
    };

    useEffect(() => {
        setSearchPhrase('');
    }, [])//eslint-disable-line

   

    useEffect(() => {
        async function getRecentSearches() {
            const RecentSearchdata =
            localStorage.getItem('recentSearch');

            //Achyuth Dorepally
            // await RECENTSEARCHSERVICES.getRecentSearch(location.pathname, getMemberFirmID());
            const recentSearchList = RecentSearchdata != null ? (RecentSearchdata ?? []) : []
            let recentSearchData= UTILITIESHELPER.isArrayNullorEmpty(recentSearchList) ? recentSearchList: JSON.parse(recentSearchList);
          //  recentSearchData=  recentSearchData?.filter(mem=>mem.memberfirm===context.memberFirm && mem.knowledgeDomain===context.knowledgeDomain);
              dispatch({
                  type: ACTIONS.RECENT_SEARCH_LOAD,
                  payload: JSON.stringify(recentSearchData),
              });
        }
        if (isRecentSearchEnabled() && UTILITIESHELPER.isArrayNullorEmpty(annState.recentSearch))
            getRecentSearches();

        document.addEventListener('click', handleClickOutsideSearchInput, false)

        return () => {
            document.removeEventListener('click', handleClickOutsideSearchInput, false)
        }
    }, [])//eslint-disable-line

    function RecentSearchClickHandler(searchphrase) {
        setSearchPhrase(searchphrase);
        navigateToPage(searchphrase);
    }

    function setRecentSearchesData(_recentHistory, _recentHistoryList) {
        setRecentHistory(_recentHistory);
    }

    function navigateToPage(searchphrase) {
        if (searchphrase.length < 3) {
            alert(getLabel("s_SearchPhraseTooShort", "Phrase too short (for now)"));
            return;
        }
        if (SearchHelpers.IsSearchPrefixWithWildcards(searchphrase)) {
            setNotification({ message: wildcardsPrefixSearchLimitationNotification, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'Searchbar' });
            return;
        }
        else {
            setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null });
        }
        const currentLocation = window.location.pathname;
        if ((currentLocation.indexOf("/manual/") > 0) ||
            (currentLocation.indexOf("/standards/") > 0) ||
            (currentLocation.indexOf("/standard/") > 0) ||
            (currentLocation.indexOf("/roadmap/") > 0) ||
            (currentLocation.indexOf("/deloitte-communications/") > 0) ||
            (currentLocation.indexOf("/professional-news/") > 0) ||
            (currentLocation.indexOf("/folio/") > 0) ||
            (currentLocation.indexOf("/guidance/") > 0 && (currentLocation.indexOf("/accounting/") > 0))) {
            const parsedSearchPhrase = SearchHelpers.parse(searchphrase);
            const qs = SearchHelpers.toQueryString(parsedSearchPhrase);

            navigate(`${currentLocation}?${qs}`);
            setPageURLWhereSearchInnitiated(`${currentLocation}?${qs}`)
            setSearchPhrase(searchphrase)
             if (isRecentSearchEnabled()) {
                updateRecentSearches(searchphrase);
            }

            logs.trackEvent({
                name: 'Search',
                properties: {
                    searchText: searchphrase,
                    memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                    serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                    level: UTILITIESHELPER.getSessionStorage('Level')
                }
            })
            return;
        }
    }

    const handleFocus = () => {
        if (showRecentHistory === false) {
            setShowRecentHistory(true)
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        setSearchTimeStamp(Date.now())
        navigateToPage(searchPhrase);
        return;
    };

    return (
        <div className= "searchbar-main-content">
            <div className="searchbar-main-content-wrapper">
                <form className={classNameForm} onSubmit={handleSubmit}>
                    <label htmlFor="landing-search" className="visually-hidden">{getLabel("s_Search", "Search")}</label>
                    <input
                        ref={inputRef}
                        className={cx("searchbar", className)}
                        placeholder={placeholder}
                        value={searchPhrase ?? ""}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        style={style}
                        id="landing-search"
                        data-testid="searchbar-publication-landing-search"
                        autoComplete="off"
                        disabled={disableState}
                    />
                </form>

                {showRecentHistory === true && !UTILITIESHELPER.isArrayNullorEmpty(annState.recentSearch) &&
                    <RecentSearches recentHistoryList={annState.recentSearch} handleClick={RecentSearchClickHandler} setRecentSearchesData={setRecentSearchesData} />
                }
            </div>
        </div>
    )
}

Searchbar.defaultProps = {
    placeholder: "",
    style: {},
    className: ''
}

export default Searchbar;
