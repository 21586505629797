import React, { useEffect, useState } from 'react';
import RecentlyViewedItem from './RecentlyViewedItem';
import RecentViewedService from '../../helpers/RecentlyViewedServices';
import ShowIf from '../Common/ShowIf';
import { usePageContext } from '../../contexts/PageContext';
import { useLocation } from 'react-router-dom';
import { useRecentViewContext } from '../../contexts/RecentViewContext';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';

const RecentlyViewed = () => {
    const [list,setList] = useState([]);
    const location = useLocation();
    const { context, getMemberFirmID } = usePageContext();
    const memberFirmId = getMemberFirmID();
    const { setRecentViewHistory } = useRecentViewContext()
    const today = todayDate();
    const yesterday = YesterdayDate();
    const dayBeforeYesterday = dayBeforeYesterdayDate();
    const thisweek = getThisWeek();
    const lastweek = getLastWeek();

    useEffect(() => {
        const fetchHistory = async () => {
            const recentHistoryDetails = JSON.parse(UTILITIESHELPER.getLocalStorage('recent-view-history'))
            setRecentViewHistory(recentHistoryDetails)
            var list = recentHistoryDetails?.[context.memberFirm]?.[context.knowledgeDomain] || []
            var todaylist = [];
            var yesterdaylist = [];
            var thisweeklist = [];
            var lastweeklist = [];
            var earlierlist = [];
            list.forEach(element => {
                var singleEl = {
                    created_date: new Date(element.created_date),
                    title: element.title,
                    relative_url: element.relative_url
                }
                if (singleEl.created_date != null && singleEl.created_date > yesterday) {
                    todaylist.push(singleEl)
                } else if (singleEl.created_date !== null && singleEl.created_date < today && singleEl.created_date > dayBeforeYesterday) {
                    yesterdaylist.push(singleEl)
                } else if (singleEl.created_date !== null && singleEl.created_date > thisweek[0] && singleEl.created_date < thisweek[1]) {
                    thisweeklist.push(singleEl)
                } else if (singleEl.created_date !== null && singleEl.created_date > lastweek[0] && singleEl.created_date < lastweek[1]) {
                    lastweeklist.push(singleEl)
                } else if (singleEl.created_date !== null) {
                    earlierlist.push(singleEl)
                }
            });
            const dataList = []
            if(todaylist.length) dataList.push({ title: 'Today', data: todaylist})
            if(yesterdaylist.length) dataList.push({ title: 'Yesterday', data: yesterdaylist})
            if(thisweeklist.length) dataList.push({ title: 'This Week', data: thisweeklist})
            if(lastweeklist.length) dataList.push({ title: 'Last Week', data: lastweeklist})
            if(earlierlist.length) dataList.push({ title: 'Earlier', data: earlierlist})
            setList(dataList);
        };
        fetchHistory();
    }, [memberFirmId]);//eslint-disable-line

    // Date functions

    function todayDate() {
        const date = new Date();
        date.setHours(23, 59, 59, 100);
        const dateCopy = new Date(date.getTime());
        return dateCopy;
    }

    function YesterdayDate() {
        const date = new Date(today);
        date.setHours(23, 59, 59, 100);
        const dateCopy = new Date(date.getTime());
        dateCopy.setDate(today.getDate() - 1)
        return dateCopy;
    }

    function dayBeforeYesterdayDate() {
        const date = new Date(today);
        date.setHours(23, 59, 59, 999);
        const dateCopy = new Date(date.getTime());
        dateCopy.setDate(today.getDate() - 2)
        return dateCopy;
    }

    function getThisWeek() {
        var currDate = new Date;
        var yesterdaycopy = new Date(yesterday);
        currDate.setHours(0, 0, 0, 0);
        var firstday = new Date(currDate.setDate(currDate.getDate() - currDate.getDay()));
        var lastday = new Date(yesterdaycopy.setDate(yesterdaycopy.getDate() - 1));
        return [firstday, lastday];
    }

    function getLastWeek() {
        let today = new Date();
        let t = today.getDay();
        let sunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - t - 7); //sunday from last week
        let todaydate = today
        todaydate.setHours(23, 59, 59, 100);
        todaydate.setDate(todaydate.getDate() - t - 1);
        let saturday = new Date(todaydate); //saturday from last week
        return [sunday, saturday];
    }

    return (
        <>
            <div style={{ width: '66%' }}>
                <ShowIf condition={!list || list.length === 0}>
                    <div>
                        <div className="notification">
                            <span className="notification-warning">&nbsp;</span>
                            <p data-testid="no-data-available" className="notification-label annot-notification-label">No history data available</p>
                        </div>
                    </div>
                </ShowIf>
                <div>
                    {list && list.length > 0 &&
                        list.map((item, p) => {
                            return (
                                <div key={item.title + p}>
                                    <h2 data-testid='day-type'>{item.title}</h2>
                                    <hr></hr>
                                    {
                                        item.data.map((element, i) => {
                                            return (
                                                <RecentlyViewedItem key={element.relative_url + i} item={element}></RecentlyViewedItem>
                                            )
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    )
}
export default RecentlyViewed;