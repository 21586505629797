import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import throttle from 'lodash.throttle';
import cx from 'classnames';
import ShowIf from '../Common/ShowIf';
import NavigableSearchbar from '../Common/NavigableSearchbar';
import Loading from '../Loading/Loading';
import CONTEXTHELPERS from '../../helpers/ContextHelpers';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
import SearchHelpers from '../../helpers/SearchHelpers';
import { appInsightUIMapping, logs,page } from "../../helpers/log";
import { useSearchResultContext } from '../../contexts/SearchResultContext';
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import { setMaxHeightTocBody } from './Article';
import HorizontalScrollbar from '../Article/HorizontalScrollbar';
import FixedNavArticle from './FixedNavArticle';
import { useTocContext } from '../../contexts/TocContext';
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import SearchResult from '../Common/SearchResult';
import ReactHtmlParser from 'react-html-parser';
import ARTICLEHELPERS from '../../helpers/ArticleHelpers';
import { SCS_KEYS, getFallback } from '../Constants/SCSConstants';
import { ErrorCodes, ErrorMessages, ErrorTypes } from '../Constants/Errors';
import useSearchService from '../../services/useSearchService';
import LINKHELPERS from '../../helpers/LinkHelpers';
import ResearchAssistantTile from '../Publication/ResearchAssistantTile';


const PublicationSearch = ({
    isWideMode,
    setIsWideMode,
    pubData,
    publicationType,
    hasAllTocDataLoaded,
    pubLandingUrl,
    navTitle,
    setShouldBeFixed
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { getLabel, getMFLabel } = usePageContextLabels();
    const { isJournalPage, isSpinePage, context, searchPhrase, setSearchPhrase, setPageURLWhereSearchInnitiated, setNotification, isResearchAssistantEnabled, countryKDSettings } = usePageContext();
    const [currentSearchPhrase, setCurrentSearchPhrase] = useState(searchPhrase ? SearchHelpers.toHumanReadable(searchPhrase) : SearchHelpers.toHumanReadable(SearchHelpers.fromQueryString(LINKHELPERS.sanitizeUrl(location?.search))));
    const [searchTerms, setSearchTerms] = useState([]);
    const [isFixed, setIsFixed] = useState(false);
    const { toc, flattenedToc } = useTocContext();
    const { getGuidebookSectionData } = useGuidebookTocContext();
    const [updateRecentSearches, setUpdateRecentSearches] = useState(0);
    const searchService = useSearchService();

    const {
        searchResults,
        setSearchResults,
        activeFilters,
        setActiveFilters,
        areSearchResultsLoading,
        setAreSearchResultsLoading,
        searchTimeStamp,
        setSearchTimeStamp,
        latestRetrieveSearchResultsTimeStampRef
    } = useSearchResultContext();
    const [isValidSearchPhrase, setIsValidSearchPhrase] = useState(false);
    const wildcardsPrefixSearchLimitationNotification = getLabel(SCS_KEYS.wildcardsPrefixSearchLimitation, getFallback(SCS_KEYS.wildcardsPrefixSearchLimitation));
    const isResearchAssistantEnabledForKD =  UTILITIESHELPER.isResearchAssistantEnabledForKD(countryKDSettings, context.memberFirm, context.knowledgeDomain);
    const isResearchAssistantActiveForUser = isResearchAssistantEnabled && isResearchAssistantEnabledForKD ? true : false;


    function isScrolledIntoView(el) {
        if (!el) return;
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        if (!isWideMode) {
            setMaxHeightTocBody(rect)
        }
        // Only completely visible elements return true:
        const progressBarOffset = 8
        var isVisible = (elemTop >= progressBarOffset)

        return isVisible;
    }

    useEffect(() => {
        setPageURLWhereSearchInnitiated(location?.pathname + location?.search)
        let queryparamsearchphrase = SearchHelpers.toHumanReadable(SearchHelpers.fromQueryString(LINKHELPERS.sanitizeUrl(location?.search)));
        if (SearchHelpers.IsSearchPrefixWithWildcards(queryparamsearchphrase)) {
            setNotification({ message: wildcardsPrefixSearchLimitationNotification, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'PublicationSearch' });
            setIsValidSearchPhrase(false);
            setAreSearchResultsLoading(false);
            setSearchResults([]);
            return;
        }
        else {
            setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null });
            setIsValidSearchPhrase(true);
        }
        setSearchPhrase(queryparamsearchphrase);
        setCurrentSearchPhrase(queryparamsearchphrase);
    }, [])//eslint-disable-line


    useEffect(() => {
        const articleContainerElem = document.querySelector('.article-container');
        if (articleContainerElem) {
            setMaxHeightTocBody(articleContainerElem.getBoundingClientRect());
            const handleScroll = (ev) => {
                if (!isWideMode) {
                    setMaxHeightTocBody(articleContainerElem.getBoundingClientRect())
                }
                setIsFixed(() => {
                    let isTitleFixed = !isScrolledIntoView(articleContainerElem);
                    setShouldBeFixed(isTitleFixed);
                    return isTitleFixed;
                });
            }
            const throttledScroll = throttle(handleScroll, 16)
            window.addEventListener('scroll', throttledScroll, { passive: true })

            return () => {
                window.removeEventListener('scroll', throttledScroll);
                const sidePannelBody = document.querySelector('.side-pannel-body');
                if (sidePannelBody) {
                    sidePannelBody.style.maxHeight = 'none';
                }
            }
        }
    }, [isWideMode, areSearchResultsLoading])//eslint-disable-line

    useEffect(() => {
        let isCancelled = false;

        const retrieveSearchResults = async (currentsearchPhrase) => {
            const currentReqTime = Date.now();
            // latestRetrieveSearchResultsTimeStampRef.current = currentReqTime;
            if (latestRetrieveSearchResultsTimeStampRef) {
                latestRetrieveSearchResultsTimeStampRef.current = currentReqTime;
            }

            if(UTILITIESHELPER.isStringNullorEmpty(currentsearchPhrase) || UTILITIESHELPER.isArrayNullorEmpty(flattenedToc)
                || !hasAllTocDataLoaded || !Array.isArray(flattenedToc) || flattenedToc?.length === 0
                || isCancelled || currentReqTime !== latestRetrieveSearchResultsTimeStampRef.current)
                return ;

            try {

                if(isCancelled){
                    // asynchornous call is still in progress
                    return ;
                }

                setAreSearchResultsLoading(true);
                setSearchResults([]);
                setActiveFilters([]);

                let pubIds = ARTICLEHELPERS.getpubIdsToQuery(pubData, flattenedToc, isSpinePage, context);
                //let results = await RETRIEVALSERVICES.retrieveSearchResults(currentsearchPhrase, pubIds);
                //let results = await searchService.getCatalogSearchResponses(pubIds, currentsearchPhrase, 3);

                var results = await searchService.getPublicationSearchResponses(context.pageType, currentsearchPhrase, get(window,'DART.ENV.REACT_APP_COLLECTION_SEARCH_LIMIT',0), pubIds);
                if (!UTILITIESHELPER.isArrayNullorEmpty(results)) {
                    let searchresults = results.filter(x => x.highlightsCount > 0);
                    const extendedResults = ARTICLEHELPERS.mapSearchResultsToSelectedToc(searchresults, context, isJournalPage, flattenedToc, isSpinePage, getMFLabel, toc, getGuidebookSectionData);

                    if(!UTILITIESHELPER.isArrayNullorEmpty(extendedResults)){
                        setSearchResults(extendedResults);
                    }
                    else{
                        setSearchResults([]);
                    }
                }
                else{
                    setSearchResults([]);
                }
                setAreSearchResultsLoading(false);
            }
            catch (err) {
                logs.error(page.Publication, 'PublicationSearch', ErrorMessages.publicationSearch, err, { memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'), knowledgeDomain: context.knowledgeDomain,eventId:ErrorCodes.Publication });
                navigate(CONTEXTHELPERS.getFallbackUrl(window.location.href));
            }
        }

        if ((!UTILITIESHELPER.isStringNullorEmpty(currentSearchPhrase) || !isCancelled) && isValidSearchPhrase)
            retrieveSearchResults(currentSearchPhrase)

        return () => {
            isCancelled = true;

        }
        //TODO: Need another listener for the search toggle?
    }, [hasAllTocDataLoaded, searchTimeStamp, toc, flattenedToc, currentSearchPhrase, isValidSearchPhrase]);//eslint-disable-line


    useEffect(() => {
        const resultsContainer = document.querySelector('.search-results');
        const searchTerms = resultsContainer?.querySelectorAll('em');

        setSearchTerms(searchTerms);

        return () => {
            setSearchTerms([]);
        }

    }, [searchResults]);


    const getSearchTitle = () => {
        return currentSearchPhrase ? ReactHtmlParser(`${getLabel("s_SearchResults", "Search Results")} ${getLabel("s_SearchResultsFor", "for")} <em>${SearchHelpers.toHumanReadable(currentSearchPhrase)}</em>`) : '';
    }

    const handleSubmit = (event, searchPhraseFromNavigationBarRecentSearches) => {
        if (event) {
            event.preventDefault()
            return false
        }

        const _searchPhrase = (searchPhraseFromNavigationBarRecentSearches ? searchPhraseFromNavigationBarRecentSearches : searchPhrase)?.trim();
        setSearchTimeStamp(Date.now())
        if (_searchPhrase.length < 3) {
            alert(getLabel("s_SearchPhraseTooShort", "Phrase too short (for now)"));
            return;
        }

        if (SearchHelpers.IsSearchPrefixWithWildcards(_searchPhrase)) {
            setNotification({ message: wildcardsPrefixSearchLimitationNotification, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'PublicationSearch' });
            setIsValidSearchPhrase(false);
            return;
        }
        else {
            setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null });
            setIsValidSearchPhrase(true);
        }

        setUpdateRecentSearches(updateRecentSearches + 1)

        const parsedSearchPhrase = SearchHelpers.parse(_searchPhrase);
        const readableSearchPhrase = SearchHelpers.toHumanReadable(parsedSearchPhrase);

        setSearchPhrase(readableSearchPhrase);
        setCurrentSearchPhrase(readableSearchPhrase);

        const qs = SearchHelpers.toQueryString(parsedSearchPhrase);
        const currentLocation = window.location.pathname;

        navigate(`${currentLocation}?${qs}`);
        logs.trackEvent({
             name: 'Search',
             properties: {
                 searchText: _searchPhrase,
                 memberFirm: UTILITIESHELPER.getSessionStorage('MemberFirm'),
                 serviceArea: UTILITIESHELPER.getSessionStorage('ServiceArea'),
                 level: UTILITIESHELPER.getSessionStorage('Level')
             }
        })
         return;
    };

    function setValue(value) {

        if (!UTILITIESHELPER.isStringNullorEmpty(value)) {
            if (SearchHelpers.IsSearchPrefixWithWildcards(value)) {
                setNotification({ message: wildcardsPrefixSearchLimitationNotification, type: ErrorTypes.Error, display: true, appInsightUIMapping: appInsightUIMapping.Warning, componentName: 'PublicationSearch' });
                setIsValidSearchPhrase(false);
                setAreSearchResultsLoading(false);
                return;
            }
            else {
                setNotification({ message: '', type: ErrorTypes.Error, display: false, appInsightUIMapping: null, componentName: null });
                setIsValidSearchPhrase(true);
            }
            setCurrentSearchPhrase(value)
            setSearchPhrase(value)
            const qs = SearchHelpers.toQueryString(value);
            const currentLocation = window.location.pathname;
            navigate(`${currentLocation}?${qs}`);
        }
    }

    function handleClose() {
        setSearchResults([]);
        setSearchPhrase('');
        navigate(pubLandingUrl);
    }
    function isAllOnOffButtonExist(){
        return document.querySelectorAll('.toc-side-pannel-all-on-off-toggle').length > 0
    }
    // Loading trapdoor
    if (areSearchResultsLoading) return <Loading />;

    const filteredResults = searchResults.filter(result => {
        if (activeFilters.length === 0) return true;
        let matchedFilters = [];
        if(context.pageControllerType ==="standard"){
            matchedFilters = activeFilters.filter(x => result.tocHash === x || result.tocHash.includes(x));
        }
        else{
            matchedFilters = activeFilters.filter(x => result.tocHash === x || result.tocHash.startsWith(x));
        }
        return matchedFilters.length === 0
    })

    function resolvedSearchResultSet(){
        var resolvedSearchResultSet = [];
        filteredResults.length > 0 && filteredResults.forEach((result, idx) => {
            let idCount = idx + 1;
            if (result !== null) {
                if (isResearchAssistantActiveForUser && ((filteredResults.length <= 3 && idCount === filteredResults.length) || (filteredResults.length > 3 && idCount === 3))) {
                    resolvedSearchResultSet.push(<SearchResult key={idx + '_' + result.tocHash} searchPhrase={currentSearchPhrase} {...result} />);
                    resolvedSearchResultSet.push(<ResearchAssistantTile />)
                } else {
                    resolvedSearchResultSet.push(<SearchResult key={idx + '_' + result.tocHash} searchPhrase={currentSearchPhrase} {...result} />);
                }
            }
        })
        
        if (isResearchAssistantActiveForUser && !filteredResults?.length) {
            resolvedSearchResultSet.push(<ResearchAssistantTile />)
        }
        return resolvedSearchResultSet;
    }

    return (
        <>
            <ShowIf condition={!areSearchResultsLoading && searchResults?.length > 0}>
                <HorizontalScrollbar shouldBeFixed={isFixed} />
            </ShowIf>
            <ShowIf condition={isFixed}>
                <FixedNavArticle
                    isWideMode={isWideMode}
                    setIsWideMode={setIsWideMode}
                    isJumpLinksOpen={false}
                    title={getSearchTitle()}
                    navTitle={navTitle}
                    pubLandingUrl={pubLandingUrl}
                    shouldBeFixed={true}
                    pubData={pubData}
                    removeInArticleTOC={true}
                    publicationType={publicationType}
                />
            </ShowIf>
            <div
                className={cx("article-container cf-right", {
                    "article-container-wide-mode": isWideMode,
                    'invisible': false,
                })}
            >
                <div className={cx("search-results-container", { "search-results-container-wide-mode": isWideMode })}>
                    <form onSubmit={(e)=>handleSubmit(e, null)} >
                        <NavigableSearchbar
                            placeholder={`${getLabel("s_Search", "Search")} ${publicationType}`}
                            value={searchPhrase}
                            setValue={setValue}
                            terms={searchTerms}
                            style={isFixed ? { top: '1.75rem' } : { top: '.5rem', right: '17px' }}
                            isExpanded={true}
                            isFixed={isFixed}
                            onClose={handleClose}
                            submitFromRecentSearch={handleSubmit}
                            triggerUpdateRecentSearches={updateRecentSearches}
                            filteredResultCount={filteredResults?.length}
                            calledFrom={page.Article}
                        />
                    </form>
                    <h1 className="publication-listing-titles">{getSearchTitle()}</h1>
                    <ShowIf condition={!filteredResults.length && !isAllOnOffButtonExist()}>
                        <div>{getLabel("s_SearchReturnedNoResults", "Your search returned no results")}</div>
                    </ShowIf>
                    <ShowIf condition={!filteredResults.length && isAllOnOffButtonExist()}>
                        <div className='search-message-ontoggle-alloff'>{getLabel("s_SearchReturnedNoResultsDueToAllOnOff", "Please turn on a toggle in the Table of Contents to view results.")}</div>
                    </ShowIf>
                    <div className="search-results">
                        {resolvedSearchResultSet()}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PublicationSearch;
