import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import ShowIf from '../Common/ShowIf';
import IconX from '../Common/IconX';
import { usePageContextLabels,usePageContext } from '../../contexts/PageContext';
import useOutsideClick from '../../hooks/useOutsideClick';
import cx from 'classnames';
import { useArticleContext } from "../../contexts/ArticleContext";
import { KNOWLEDGE_DOMAIN } from '../Constants/Constants';

const ArticleTag = ({ classNames }) => {
    const tagRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const { getLabel } = usePageContextLabels();
    const iconClass = isOpen ? 'icon-article-tag-open' : '';
    const{ allIndustry, knowledgeDomain,
        allTier}=usePageContext();
        const { articleTags} = useArticleContext();

    useOutsideClick(tagRef, () => {
        setIsOpen(false)
    }, ['#article-tag-fixed']);
    const toggleTag = () => setIsOpen(prevVal => !prevVal);
    //Styling for Icon props
    const iconX = {
        margin: 'auto',
        marginRight: 10,
    }

    const getFrameWork = () => {
        const tiers = [];
    
        const addTiers = (items, key) => {
            items.map(tierElement => {
                tiers.push(<span>{tierElement[key]}</span>);
            });
        };
    
        if (articleTags?.tier?.length > 0) {
            addTiers(articleTags.tier, 'name');
        } else if (allTier) {
            switch (knowledgeDomain) {
                case KNOWLEDGE_DOMAIN.auditing:
                    addTiers(allTier.gaasItems, 'value');
                    break;
                case KNOWLEDGE_DOMAIN.assurance:
                    addTiers(allTier.assuranceItems, 'value');
                    break;
                default:
                    break;
            }
        }
    
        return tiers;
    };

    const getIndustry=()=>{
        const industry=[];
        if(articleTags?.industry?.length>0){
            articleTags.industry.map(tierElement=>{
            industry.push(<>
                            <span>
                                {tierElement.name}
                            </span>
                        </>);
            })
        }
        else if(allIndustry?.items){
                allIndustry.items.map(tierElement=>{
                industry.push(<>
                                <span>
                                    {tierElement.value}
                                </span>
                             </>);
                }); 
            }
            return industry;
    }
    const Folio=()=>{
        const folio=[];
        if(articleTags?.auditWorkflowAreaAccount?.length>0){
            articleTags.auditWorkflowAreaAccount.map(WaElement=>{
                folio.push(<>
                            <span>
                                {WaElement.name}
                            </span>
                        </>);
            })
        }
        if(articleTags?.auditWorkflowAreaNonAccount){
                articleTags.auditWorkflowAreaNonAccount.map(nawElement=>{
                    folio.push(<>
                                <span>
                                    {nawElement.name}
                                </span>
                            </>);
                }); 
            }
            return folio;
    }
    return (
        <div
            id="article-tag-fixed"
            ref={tagRef}
            className={cx("d-flex", "flex-column", "relative", "article-tag-fixed", classNames)}>
            <ShowIf condition={isOpen}>
                <div className="article-tag article-tag-open absolute" data-testid="articleTagContent">
                    <div className="article-tag-header-container">
                        <h2 className="article-tag-header-text" data-testid="articleTagTitle">{getLabel("a_InArticleTagTitle", "Applicability")}</h2>
                        <IconX
                            style={iconX}
                            onClick={toggleTag}
                        />
                    </div>
                    <div className="article-tag-content" data-testid="articleTagBody">
                        <div>
                            <h3>{getLabel("a_InArticleTagFrameworks", "Frameworks")}:</h3>
                            <div>
                                {getFrameWork()}
                            </div>
                        </div>
                        <div>
                            <h3>{getLabel("a_InArticleTagIndustries", "Industries")}:</h3>
                            <div>
                                 {getIndustry()}
                            </div>
                        </div>
                        <div>
                            <h3>{getLabel("a_InArticleTagFolios", "Folios")}:</h3>
                            <div>
                                {Folio()}
                            </div>
                        </div>
                    </div>
                </div>
            </ShowIf>
            <button className={cx("icon-article-tag", iconClass)} onClick={toggleTag} data-testid="articleTagIconBtn"></button>
        </div>
    );
}

ArticleTag.defaultProps = {
    classNames: ""
}

export default ArticleTag