import React from 'react';
import { useParams } from 'react-router';  
import { usePageContext, usePageContextLabels } from '../../../../contexts/PageContext';         

const TriggerValue = () => {
    const {knowledgeDomain} = useParams();
    const Title = () => {
        const { getLabel } = usePageContextLabels();
        const defaultTitle = getLabel("c_Preferences", "Preferences");
        const { tier, industry, memberFirm } = usePageContext();

        const buildTitle = (parts) => {
            let title = parts.map(x => x.label).join('; ');
            if (title.length > 7) {
                title = title.slice(0, 7).concat('...');
            }
            title += ` (${parts.length})`;
            return title;
        }

        const getTitle = () => {
            if (industry && !tier) {
                if (industry[memberFirm] && industry[memberFirm][knowledgeDomain] && industry[memberFirm][knowledgeDomain].length) {
                    return buildTitle(industry[memberFirm][knowledgeDomain]);
                }
            }
            if (tier && !industry) {
                if (tier[memberFirm] && tier[memberFirm][knowledgeDomain] && tier[memberFirm][knowledgeDomain].length) {
                    return buildTitle(tier[memberFirm][knowledgeDomain]);
                }
            }
            if (tier && industry) {
                const isTierHasValue = tier[memberFirm] && tier[memberFirm][knowledgeDomain] && tier[memberFirm][knowledgeDomain].length;
                const isIndustryHasValue = industry[memberFirm] && industry[memberFirm][knowledgeDomain] && industry[memberFirm][knowledgeDomain].length;
                if (isTierHasValue && isIndustryHasValue) {
                    return buildTitle([...tier[memberFirm][knowledgeDomain], ...industry[memberFirm][knowledgeDomain]]);
                }
                if (isTierHasValue && !isIndustryHasValue) {
                    return buildTitle(tier[memberFirm][knowledgeDomain]);
                }
                if (!isTierHasValue && isIndustryHasValue) {
                    return buildTitle(industry[memberFirm][knowledgeDomain]);
                }
            }
            return defaultTitle;
        }
        return <span className='dd__trigger-label'>{getTitle()}</span>
    }

    return (
        <button type='button' className="dd__trigger-button">
            <Title />
        </button>
    )
};

export default TriggerValue;
