import React, { useState } from "react";
import ShowIf from "../Common/ShowIf";
import { usePageContextLabels } from "../../contexts/PageContext";
import FormIcon from "./FormIcon";
import ResolveSources from "./ResolveSources";
import { RESEARCH_ASSISTANT_SOURCE_TYPES } from "../Constants/Constants";
import CONTEXTHELPERS from "../../helpers/ContextHelpers";
import { GotoIcon } from "./GotoIcon";
import { useThemeContext } from "../../contexts/ThemeContext";
import { getIconSrc } from '../../components/Article/File';


const ResearchAssistantForms = ({ isResearchAssistantLoading, researchAssistantResponse }) => {
    const [isExpand, setIsExpand] = useState(false)
    const { getLabel } = usePageContextLabels();
    const memberFirm = CONTEXTHELPERS.getMemberFirmWithTaxonomyId(researchAssistantResponse?.inputFields?.userLibrariesSelected?.[0]);
    const memberFirmId =  researchAssistantResponse?.inputFields?.userLibrariesSelected?.[0];
    const knowledgeDomain = CONTEXTHELPERS.getknowledgeDomain(researchAssistantResponse?.inputFields?.userFilterSelected?.[1]?.filterID?.[0]);
    const knowledgeDomainId =  researchAssistantResponse?.inputFields?.userFilterSelected?.[1]?.filterID?.[0];
    const language = CONTEXTHELPERS.getLanguageByMemberFirm(memberFirm);
    const { isDarkMode } = useThemeContext();

    return (
        <>
            <ShowIf condition={!isResearchAssistantLoading && researchAssistantResponse !== null && researchAssistantResponse?.formSource && researchAssistantResponse?.formSource?.length > 0}>
                <div className='form-title' data-testid="modal-comment-input" onClick={() => setIsExpand(!isExpand)}>
                  
                    <img src={ isDarkMode ? '/images/white-down-arrow.svg': '/images/gray-down-arrow.svg'} alt="down-arrow" className={`up-down-arrow ${!isExpand ? 'hidden-icon' : ''}`} />
                    <img src={ isDarkMode ? '/images/white-right-arrow.svg':'/images/gray-right-arrow.svg'} alt='right-arrow' className={`right-arrow ${isExpand ? 'hidden-icon' : ''}`} />                   
                    <h3 className="research-assistant-forms-heading">
                        {getLabel('ra_FormAndTemplates', 'Forms, Templates, and Examples to Consider')}
                    </h3>
                </div>
                
            </ShowIf>
            {(isExpand && researchAssistantResponse?.formSource !== null && researchAssistantResponse?.formSource?.length > 0) && <>
                {researchAssistantResponse?.formSource?.map((item, index) => (
                    <div className="research-assistant-citation-data" key={`citation-${index}`} data-testid="form-data">
                        <div className="form-source-icon-container">
                            <img className="form-source-icon" src={getIconSrc(item?.format?.toLowerCase())} alt="ico" />
                        </div>
                        <div>
                            <ResolveSources sourceType={RESEARCH_ASSISTANT_SOURCE_TYPES.FORMS_AND_TEMPLATES} title={item.title} format={item.format} memberFirm={memberFirm} language={language} knowledgeDomain={knowledgeDomain} memberFirmId={memberFirmId} knowledgeDomainId={knowledgeDomainId} contentItemGuid={item.guid} pubGuid={item.pubID}/>
                        </div>
                    </div>
                ))}
            </>}
        </>
    )
}

export default ResearchAssistantForms;