import React,{ useEffect, useState } from 'react';
import { useUserPreferencesContext } from '../../contexts/AnnotationsContext';
import getTextWithSearchHighlight from '../../helpers/getTextWithSearchHighlight';
import HTMLContent from '../Common/HTMLContent';
import { useNavigate } from 'react-router-dom';
import LINKHELPERS from '../../helpers/LinkHelpers';

export const BookmarksListItems = ({
    category,
    item,
    modifyDeleteGuids,
    searchText,
}) => {
    const {setViewedBookmark} = useUserPreferencesContext();
    const [BookmarkItem, setBookmarkItem] = useState(item);
    const navigate = useNavigate();
    const bookmarkTitle = BookmarkItem.page_bread_crumb.split('/').slice(1).join('|');

    useEffect(() => {
        setBookmarkItem(prev => {
            return {
                ...prev,
                checked: false
            }
        })
    }, [item]);

    function redirectToArticle() {
        setViewedBookmark(LINKHELPERS.getGUID(BookmarkItem.page_url));
        navigate(BookmarkItem.page_url);
    }

    function handleChange() {
        setBookmarkItem(prev => {
            modifyDeleteGuids(BookmarkItem.bookmark_guid, !prev.checked)
            return {
                ...prev,
                checked: !prev.checked
            }
        });
    }

    return (

        <>
            <div className='d-flex'>
                <div className='d-flex bookmark-item-container' onClick={redirectToArticle}>
                    <div className='bookmark-color' style={{ backgroundColor: category.color }} />
                    <div className='bookmark-item-inner-container'>
                        <div className='bookmark-item-inner-container-adj' >
                            <div className='bookmark-text'><HTMLContent>{getTextWithSearchHighlight(bookmarkTitle, searchText)}</HTMLContent></div>
                            <div className='bookmark-category'><HTMLContent>{getTextWithSearchHighlight(category.label, searchText)}</HTMLContent></div>
                        </div>
                    </div>
                </div>
                <div className='annot-delete-checkbox-container'>
                    <input className='annot-delete-checkbox' onChange={handleChange} defaultChecked={BookmarkItem.checked} id={BookmarkItem.bookmark_guid} type='checkbox'></input>
                </div>
            </div>

        </>
    )
}
