import React, { useState, createContext, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import CONTEXTHELPERS from '../helpers/ContextHelpers';
import TOCHELPERS from '../helpers/TocHelpers';
import { usePageContext } from '../contexts/PageContext';
import { useTocContext } from './TocContext';

export const GuidebookTocContext = createContext()
export const useGuidebookTocContext = () => useContext(GuidebookTocContext)

const GuidebookTocContextProvider = ({ children }) => {
    const { context } = usePageContext();
    const navigate = useNavigate();
    const [guidebookSectionsLoaded, setGuidebookSectionsLoaded] = useState(0);  //A counter for each time we have a loaded section loaded
    const [guidebookPublication, setGuidebookPublication] = useState({});       //The res of the retrieveGuidebook API call
    const [guidebookSections, setGuidebookSections] = useState([]);             //The array of sections within the guidebookPublication
    const [guidebookTocLoaded, setGuidebookTocLoaded] = useState(false);        //When the TOC loads, and HAS contnet, then this will become true (otherwise stays false and wormholes)
    const [guidebookToc, setGuidebookToc] = useState({});
    const [guidebookArticle, setGuidebookArticle] = useState({}); 
    const { hasSelectedTocItems } = useTocContext();

    const getGuidebookSectionData = (sectionFriendlyPath) => {
        //console.log("getGuidebookSectionData::guidebookSections", sectionFriendlyPath, guidebookTocSections);
        return guidebookSections?.find(x => x.sectionFriendlyPath === sectionFriendlyPath);
        //return get(guidebookSections, `${sectionId}`, null);
    }

    const setGuidebookSectionData = (sectionFriendlyPath, newSectionData) => {
        //Remove the "null" toc items from the array of TOC items
        newSectionData.tableOfContents = newSectionData.tableOfContents.filter(x => x !== null)

        const oldSection = getGuidebookSectionData(sectionFriendlyPath);

        oldSection.hasBeenFetched = true;
        oldSection.context = newSectionData.context;
        
        if (Array.isArray(newSectionData.tableOfContents) && newSectionData.tableOfContents.length > 0) {
            let basePath=`${context.pageBaseURL}`;
            //if new, then we need to look at buildFolioTocHashes
            //oldSection.tableOfContents = newSectionData.tableOfContents.map(tocs => TOCHELPERS.buildTocHashes(tocs, sectionFriendlyPath, basePath));
            oldSection.tableOfContents = newSectionData.tableOfContents.map(tocs => TOCHELPERS.buildFolioTocHashes(tocs, sectionFriendlyPath, basePath));
        }
    }

    const allSectionsLoadedAndHaveToc = () => {
        //First check that all sections are loaded...
        //  as we ONLY call this once we have fetched all sections
        //  this loop SHOULD never actually return false, as ALL sections
        //  should have been fetched (true) already!

        //eslint-disable-next-line
        guidebookSections.map(sectionItem => {
            if (!sectionItem.hasBeenFetched || sectionItem.hasBeenFetched !== true) {
                //console.log("GuidebookTocContextProvider::allSectionsLoadedAndHaveToc:This section is NOT fetched yet, so cannot check for all having toc content yet:", sectionItem.sectionFriendlyPath, sectionItem);
                return false;
            }
        });
        //We need at least ONE section that DOES have a toc to be considered that we "have" a TOC!
        let atLeastOneSectionHasToc = false;
        guidebookSections.forEach(sectionItem => {
            if (sectionItem.tableOfContents !== null && Array.isArray(sectionItem.tableOfContents) && sectionItem.tableOfContents.length > 0) {
                //console.log("GuidebookTocContextProvider::allSectionsLoadedAndHaveToc:This section has been fetched and HAS toc", sectionItem.sectionFriendlyPath, sectionItem);
                atLeastOneSectionHasToc = true;
                return; //We can break, as we only need ONE true!
            }
        });

        //console.log("GuidebookTocContextProvider::allSectionsLoadedAndHaveToc:All sections have been fetched, and atLeastOneSectionHasToc === ", atLeastOneSectionHasToc);
        return atLeastOneSectionHasToc;
    }

    useEffect(() => {
        //console.log("GuidebookTocContextProvider::guidebookPublication:", guidebookPublication);
        const guidebookSections = get(guidebookPublication, 'guideBook.Content.guidebookSections', []);
        if (!Array.isArray(guidebookSections) || guidebookSections.length > 0) {
            //console.log("GuidebookTocContextProvider::setGuidebookSections with the recently updated/retreived guidebookPublication:", guidebookPublication);
            setGuidebookSections(guidebookSections);
        }
    }, [guidebookPublication])


    useEffect(() => {
        if (guidebookSections && Array.isArray(guidebookSections) && guidebookSections.length > 0) {
            if (guidebookSectionsLoaded === guidebookSections.length) {
                //console.log("GuidebookTocContextProvider::useEffect:guidebookSectionsLoaded and section-count has changed/incremented ... Check here for completeness toc", guidebookSectionsLoaded, guidebookSections.length, guidebookSections);

                //If we must worm hole out...
                if (allSectionsLoadedAndHaveToc()) {
                    setGuidebookTocLoaded(true); 
                    if (!hasSelectedTocItems){
                        navigate(context.pageBaseURL + "?wormhole=true");
                    }
                }
                else {
                    //When we have no TOC in any section, we wormhole out.
                    //console.log('GuidebookTocContextProvider::useEffect:worm-hole out.', CONTEXTHELPERS.getFallbackUrl(window.location.href, false));
                    navigate(CONTEXTHELPERS.getFallbackUrl(window.location.href, false));
                }
            }
        }
    }, [guidebookSectionsLoaded]) //eslint-disable-line

    const store = {
        //Functions
        setGuidebookSectionsLoaded,
        getGuidebookSectionData, setGuidebookSectionData,
        //Properties
        guidebookPublication, setGuidebookPublication,
        guidebookSections, setGuidebookSections,
        guidebookToc, setGuidebookToc,
        guidebookTocLoaded, setGuidebookTocLoaded,
        guidebookArticle, setGuidebookArticle
    }

    return (
        <GuidebookTocContext.Provider value={store}>
            {children}
        </GuidebookTocContext.Provider>
    )
}

export default GuidebookTocContextProvider