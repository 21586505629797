
import React, { useCallback, useEffect, useState } from 'react';
import { useThemeContext } from '../../../../contexts/ThemeContext';
import { usePageContext, usePageContextLabels } from '../../../../contexts/PageContext';
import { logs, page } from "../../../../helpers/log";
import RETRIEVALSERVICES from '../../../../services/rp-service';
import UTILITIESHELPER from '../../../../helpers/UtilitiesHelper';
import TierHelpers from '../../../../helpers/TierHelpers';
import IndustryHelpers from '../../../../helpers/IndustryHelpers';
import Dropdown from '../../Dropdown';
import TriggerValue from './TriggerValue';
import { ErrorCodes, ErrorMessages } from '../../../Constants/Errors';
import useDCSService from '../../../../services/useDCSService';
import { MemberFirmID } from '../../../Constants/Constants';

const sortDisplayOrderAsc = ((a, b) => a.display_order - b.display_order);  
const sortFrameworksOrderAsc = ((a, b) => a.id - b.id); 
const MenuListItem = ({ value, index, children, setIsShown, setValue }) => {
    return (
        <button key={UTILITIESHELPER.generateUUID()}
            onMouseEnter={() => onMouseHover(setIsShown, setValue, index)}
            onKeyPress={(e) => handleKeyPress(e, setIsShown, setValue, index)}
            className={`uk-selector__option dropdown-btn ${index === value ? 'active' : ''}`}
        >
            {children}
        </button>
    )
}

function onMouseHover(setIsShown, setValue, index) {
    setIsShown(true);
    setValue(index);
}

const handleKeyPress = (event, setIsShown, setValue, index) => {
    if (event.key === "Enter") {
        onMouseHover(setIsShown, setValue, index)
    }
};

const SubMenu = ({ className, title, options, selected, onChange }) => {
    return (
        <div className={`submenu-col ${className}`}>
            <h4>{title}</h4>

            <ul>
                {options.map((option) => {
                    const isChecked = selected?.filter(x => x.id === option.id)?.length > 0;
                    return (
                        <SubMenuItem
                            key={UTILITIESHELPER.generateUUID()}
                            option={option}
                            onChange={onChange}
                            checked={isChecked}
                        />
                    )
                })}
            </ul>
        </div>
    )
}

const SubMenuItem = ({ option, checked, onChange }) => {
    const id = `check_${option.id}`;
    function onClick(e) {
        e.preventDefault();
        onChange(option);
    }
    return (
        <li>
            <label className="container" htmlFor={id} onClick={onClick} > {option.label}
                <input type="checkbox" id={id} name={option.name} value={option.value} checked={checked} onChange={() => onChange(option)} onKeyPress={(e) => handleCheckboxClick(e, option, onChange)}></input>
                    <span className="checkmark-header"></span>
            </label>
        </li>
    )
}

const handleCheckboxClick = (event, option, onChange) => {
    if (event.key === "Enter") {
        onChange(option)
    }
};


const DropdownPreferences = () => {
    const { isDarkMode } = useThemeContext();
    const { context, tier, setTier, industry, setIndustry,setCurrentTier,setCurrentIndustry, taxonomyMemberFirmContext,
		setTaxonomyMemberFirmContext, countryKDSettings, isCountryKDSettingsLoaded , setAllIndustry , setAllTier } = usePageContext();
    const { getLabel } = usePageContextLabels();
    const [data, setData] = useState(null);
    const [isShown, setIsShown] = useState(false);
    const [value, setValue] = useState();
    const { retrieveFrameworkList } = useDCSService()

    const parseResults=useCallback((frameworks, industries) =>{
        const narrowScope = ({ id, name, value, label, display_order }) => ({ id, name, value, label, display_order })
        //If frameworks/industries cannot load, then the filters (and probably all other taxonomies) cannot be loaded
        //  renders the page in-oprable anyway! We should throw a much begger error for this.
        if (!frameworks || !industries) {
            logs.error(page.PageContext, 'retrieveIndustryList', ErrorMessages.industryList,"error",{eventId:ErrorCodes.PageContext});
            return null;
        }

        console.log('Frameworks', frameworks);
        
        return {
            frameworks: {
                accounting: context.knowledgeDomain === "accounting" ? frameworks.gaapItems.map(narrowScope) : [],
                auditing: context.knowledgeDomain === "auditing" ? frameworks.gaasItems.map(narrowScope) : [],
                assurance: context.knowledgeDomain === "assurance" ? frameworks.assuranceItems.map(narrowScope) : []
            },
            industries: industries.items.map(narrowScope)
        }
    },[context.knowledgeDomain])

    useEffect(() => {
        async function fetchData() {
            if(isCountryKDSettingsLoaded && !UTILITIESHELPER.isStringNullorEmpty(context.knowledgeDomain)) {
                const resIndustry = await RETRIEVALSERVICES.retrieveIndustryList(context);
                const TaxonomyMemberfirmValue= countryKDSettings
                            ?.filter(x => x.country === context.memberFirm)[0]?.knowledgeDomain?.filter(x => x.name === (context.knowledgeDomain ? context.knowledgeDomain : 'auditing'))[0]?.taxonomyMemberFirm ;
                const taxonomyArray = (UTILITIESHELPER.isStringNullorEmpty(TaxonomyMemberfirmValue)? MemberFirmID.dtt :TaxonomyMemberfirmValue)
                if(JSON.stringify(taxonomyArray) !== JSON.stringify(taxonomyMemberFirmContext)) {
                    setTaxonomyMemberFirmContext(taxonomyArray)
                }
                const resFramework = await retrieveFrameworkList(taxonomyArray);
                setAllIndustry(resIndustry);
                setAllTier(resFramework);
                const parsed = parseResults(resFramework, resIndustry);
                if (parsed) {
                    setData(parsed);
                }
            }
        }
        fetchData();
        //eslint-disable-next-line
    }, [context.memberFirm, context.language, context.knowledgeDomain, isCountryKDSettingsLoaded]);

    useEffect(() => {
        console.log('taxonomyMemberFirmContext', taxonomyMemberFirmContext)
    }, [taxonomyMemberFirmContext])


    const clearCheckList = () => {
        const tier = TierHelpers.removeLocalStorageWithValue(context.knowledgeDomain, context.memberFirm);
        const industry = IndustryHelpers.removeLocalStorageWithValue(context.knowledgeDomain, context.memberFirm);
        setTier(tier);
        setIndustry(industry);
        setCurrentIndustry(tier);
        setCurrentTier(industry);

    }

    const MenuList = ({ children }) => {
        //Handle checkbox change
        const onTierChange = (option) => {
            const mfTier = getMfPrefs(tier, context.memberFirm);
            const find = mfTier?.find(x => x.id === option.id);
            let tiers = [];
            if (find) {
                tiers = mfTier.filter(x => x.id !== find.id);
            } else {
                tiers = [...mfTier, option].sort(sortFrameworksOrderAsc);
            }
            const tierObject = { ...tier, [context.memberFirm]: { ...tier[context.memberFirm], [context.knowledgeDomain]: tiers } };
            TierHelpers.setLocalStorage(tierObject);
            setTier(tierObject);
            setCurrentTier(tierObject);
        }

        //Handle checkbox change
        const onIndustryChange = (option) => {
            const mfIndustry = getMfPrefs(industry, context.memberFirm);
            const find = mfIndustry?.find(x => x.id === option.id);
            let industries = [];
            if (find) {
                industries = mfIndustry.filter(x => x.id !== find.id);
            } else {
                industries = [...mfIndustry, option]?.sort(sortDisplayOrderAsc);
            }
            const industryObject = { ...industry, [context.memberFirm]: { ...industry[context.memberFirm], [context.knowledgeDomain]: industries } };
            IndustryHelpers.setLocalStorage(industryObject);
            setIndustry(industryObject);
            setCurrentIndustry(industryObject);
        }

        function getMfPrefs(prefs, mf) {
            return prefs.hasOwnProperty(mf) ? prefs[mf][context.knowledgeDomain] : [];
        }

        return (
            <section className="dropdown-wrapper" onMouseLeave={() => { setIsShown(false); setValue(null) }}>
                <div className="menu-list uk-selector__menu-list">
                    {children.length === undefined ? children : children.map((child, index) => (
                        <MenuListItem
                            key={UTILITIESHELPER.generateUUID()}
                            index={index}
                            setIsShown={setIsShown}
                            value={value}
                            setValue={setValue}>
                            {child}
                        </MenuListItem>
                    ))}
                    <button
                        type="button"
                        className={`btn btn-text clear-btn ${isDarkMode ? 'dark-btn' : ''}`}
                        onClick={clearCheckList}
                        disabled={tier[context.memberFirm][context.knowledgeDomain]?.length === 0 && industry[context.memberFirm][context.knowledgeDomain]?.length === 0}
                    >
                        {getLabel("dd_FiltersClearAll", "Clear All")}
                    </button>
                </div>
                {isShown && data && value === 0 && (
                    <div className={`dropdown__submenu ${((data.frameworks.auditing.length > 0) || (data.frameworks.accounting.length > 0) || (data.frameworks.assurance.length > 0)) ? 'show' : 'hide'}`} >
                        {data.frameworks.auditing.length > 0 && <SubMenu className="col-left" title={getLabel("dd_FrameworkAuditing", "Auditing Framework")} options={data.frameworks.auditing} selected={getMfPrefs(tier, context.memberFirm)} onChange={onTierChange} />}
                        {data.frameworks.accounting.length > 0 && <SubMenu className="col-right" title={getLabel("dd_FrameworkAccounting", "Accounting Framework")} options={data.frameworks.accounting} selected={getMfPrefs(tier, context.memberFirm)} onChange={onTierChange} />}
                        {data.frameworks.assurance.length > 0 && <SubMenu className="col-right" title={getLabel("dd_FrameworkAssurance", "Assurance Framework")} options={data.frameworks.assurance} selected={getMfPrefs(tier, context.memberFirm)} onChange={onTierChange} />}
                    </div>
                )}
                {isShown && data && value === 1 && (
                    <div className={'dropdown__submenu industry'} >
                        <SubMenu className="" title="" options={data.industries} selected={getMfPrefs(industry, context.memberFirm)} onChange={onIndustryChange} />
                    </div>
                )}
            </section>
        )
    };

    return (
        <Dropdown
            triggerEl={<TriggerValue />}
            triggerText={getLabel("c_Preferences", "Preferences")}
            containerClassNames="uk-selector__menu"
        >
            <Dropdown.Panel additionalClassNames="mfdd__panel">
                <MenuList>
                    {getLabel("dd_Frameworks", "Frameworks")}
                    {getLabel("dd_Industry", "Industry")}
                </MenuList>
            </Dropdown.Panel>
        </Dropdown>
    )
};

export default DropdownPreferences;