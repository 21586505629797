import React, { useRef, useState } from "react";
import { usePageContextLabels } from "../../contexts/PageContext";
import HTMLContent from '../Common/HTMLContent';
import useOutsideClick from "../../hooks/useOutsideClick";
import { RESEARCH_ASSISTANT } from "../Constants/Constants";

const ResearchAssistantInfoModal = ({ handlePopup }) => {
    const raInfoModalRef = useRef(null)
    const [showTermsAndCondition, setShowTermsAndCondition] = useState(false)
    const { getLabel, userCountry } = usePageContextLabels()

    useOutsideClick(raInfoModalRef, () => {
        handlePopup(null);
    }, []);

    return (
        <>
            <div className='research-assistant-popup'>
            </div>
            <div className='research-assistant-modal' ref={raInfoModalRef}>
                <div className='modal-header'>
                    <div className='modal-title' data-testid='ra-title'>
                        {getLabel('ra_title', 'Research Assistant')}
                    </div>
                    <div className='modal-close-icon'>
                        <button className="share-modal-close" title="Close" data-testid='ra-close-popup' onClick={() => handlePopup(null)}></button>
                    </div>
                </div>
                <div className='modal-body'>
                    <div className='modal-content'>
                        <p className='modal-info' data-testid='about_intro'>{getLabel('ra_Introduction', RESEARCH_ASSISTANT.raIntroduction)}</p>
                    </div>
                    <div>
                        {!showTermsAndCondition &&
                            <div className='expandButtonContainer'>
                                <label className='expandTandC' data-testid='expandButton' onClick={() => setShowTermsAndCondition(!showTermsAndCondition)}>{getLabel('ra_ExpandToRevisssewTermsOfUse', 'REVIEW ACCEPTABLE USE GUIDELINES')}</label>
                            </div>
                        }
                        {showTermsAndCondition && <>
                            <label className="tostitle" data-testid='generative-ai-title'>{getLabel('ra_TocTitle', 'Generative AI Research Assistant – Acceptable Use Guidelines')}</label>
                            <div className='tosdeclaration-content'>
                                <HTMLContent>{getLabel('ra_TermsOfUse', RESEARCH_ASSISTANT.termsofuse)}</HTMLContent>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResearchAssistantInfoModal