import React, { useState, useEffect, useRef } from "react";
import {
    useUserPreferencesContext,
    ACTIONS,
} from "../../contexts/AnnotationsContext";
import CategoryDropdown from "./CategoryDropdown";
import ColorPicker from "./ColorPicker";
import AnnotationDelete from "./AnnotationDelete";
import { usePageContextLabels, usePageContext } from "../../contexts/PageContext";
import ANNSERVICES from "../../helpers/AnnotationServices";
import ARTICLEHELPERS from "../../helpers/ArticleHelpers";
import LINKHELPERS from "../../helpers/LinkHelpers";
import { useLocation } from "react-router-dom";
import { AnnotationNotification } from "./AnnotationNotification";
import ShowIf from "../Common/ShowIf";
import { rangeStartEndPosition } from "../../helpers/AnnotationHighlightHelper";
import { useTocContext } from "../../contexts/TocContext";
import { useGuidebookTocContext } from '../../contexts/GuidebookTocContext';
import { useArticleContext } from '../../contexts/ArticleContext';
import CONTEXTHELPERS from "../../helpers/ContextHelpers";
import CategoryService from "../../helpers/CategoryServices";
import UTILITIESHELPER from "../../helpers/UtilitiesHelper";
import { logs } from "../../helpers/log";
import { APPLICATION_INSIGHTS_CONSTANTS, LOCAL_STORAGE_CONSTANTS, SESSION_STORAGE_CONSTANTS } from "../Constants/Constants";

function AnnotationModal({
    content,
    rawContent,
    range,
    preSelectionRange,
    selectedTextLength,
    closestGuid
}) {
    const MAX_COMMENT_LENGTH = 1000;
    const { state: annState, dispatch } = useUserPreferencesContext();
    const [annotationCategories, setAnnotationCategories] = useState(annState?.categories?.filter(item => !item.is_specific_to_bookmark));
    const [selectedCategory, setSelectedCategory] = useState(annotationCategories ? annotationCategories[0] : []);
    const [editableCategory, setEditableCategory] = useState({
        category: {},
        isEditable: false,
    });
    const [isPickerShown, setPickerShown] = useState(false);
    const [isDeleteAnnotationShown, setDeleteAnnotationShown] = useState(false);
    const [comment, setComment] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const { getLabel } = usePageContextLabels();
    const location = useLocation();
    const [PageURL] = useState(LINKHELPERS.sanitizeUrl(location.pathname));
    const { annotationPanelOpen, setAnnotationPanelOpen, context, getMemberFirmID } = usePageContext(false);//eslint-disable-line
    const [shouldShowDarkOverlay, setshouldShowDarkOverlay] = useState(true);
    const { setTocAnnotations } = useTocContext();
    const { setTocIconBookmark } = useTocContext();
    let btnRef = useRef();
    const { guidebookArticle } = useGuidebookTocContext();
    const { articleVersion } = useArticleContext();
    const { setIsDropDownCategoryOpen } = useUserPreferencesContext();
    const [annotationsCount, setAnnotationsCount] = useState();
    const [bookmarksCount, setBookmarksCount] = useState();
    useEffect(() => {
        if (selectedTextLength > 1000) {
            setShowNotification(true);
        }
    }, [selectedTextLength]);

    function handleCategoryAddClick() {
        setPickerShown(true);
        setEditableCategory({ category: {}, isEditable: false });
    }

    function handleCategoryEditClick(category) {
        setPickerShown(true);
        setEditableCategory({ category: category, isEditable: true });
    }
    async function handleCategoryDeleteClick(category) {
        setEditableCategory({ category: category, isEditable: true })
        const categoryDelete = await CategoryService.deleteCategory(location.pathname, category.category_guid, getMemberFirmID(), null, false);
        setAnnotationsCount(categoryDelete.number_annotation_linked);
        setBookmarksCount(categoryDelete.number_Bookmark_linked)
        setDeleteAnnotationShown(true);
    }
    function handleCategorySelect(category) {
        setIsDropDownCategoryOpen(false);
        setSelectedCategory(category);
        document.dispatchEvent(new CustomEvent("dd:requestClose"));
    }

    function handlePickerClose() {
        setPickerShown(false);
    }

    function handleCloseClick() {
        setIsDropDownCategoryOpen(false);
        setshouldShowDarkOverlay(false);
        dispatch({ type: ACTIONS.MODAL_HIDE });
    }

    const handlePickerSave = async (category) => {
        category.category_guid = await CategoryService.createCategory(location.pathname,
            category, getMemberFirmID()
        );
        dispatch({
            type: ACTIONS.CATEGORY_CREATE,
            payload: category,
        });
        setPickerShown(false);
        setSelectedCategory(category);
    };

    const handlePickerUpdate = async (category) => {

        category.category_guid = await CategoryService.updateCategory(location.pathname, category, category.category_guid, getMemberFirmID());

        dispatch({
            type: ACTIONS.CATEGORY_UPDATE,
            payload: category,
        });

        setPickerShown(false);
        setSelectedCategory(category);
    };

    useEffect(() => {
        setTocIconBookmark(annState.bookmarks);
    }, [annState.bookmarks]); //eslint-disable-line

    useEffect(() => {
        let category = annState?.categories?.filter(item => !item.is_specific_to_bookmark);
        if (JSON.stringify(category) !== JSON.stringify(annotationCategories)) {
            setAnnotationCategories(category ? category : []);
        }
    }, [annState.categories,annotationCategories]);


    const handleDelete = async () => {
        const categoryDelete = await CategoryService.deleteCategory(location.pathname, editableCategory.category.category_guid, getMemberFirmID(), null, true);
        if (categoryDelete) {
            dispatch({
                type: ACTIONS.CATEGORY_REMOVE,
                payload: { category_guid: editableCategory.category.category_guid },
            });

            dispatch({
                type: ACTIONS.ANNOTATIONS_REMOVE_MULTIPLE,
                payload: annState.annotations.filter(i => i.category_guid === editableCategory.category.category_guid).map(item => item.annotation_guid),
            });
            setTocIconBookmark(prev => {
                return prev.filter(i => i.category_guid !== editableCategory.category.category_guid);
            });

            dispatch({
                type: ACTIONS.BOOKMARK_BULK_REMOVE,
                payload: annState.bookmarks.filter(i => i.category_guid === editableCategory.category.category_guid).map(item => item.bookmark_guid),
            });
            setTocAnnotations(prev => {
                return prev.filter(i => i.category_guid !== editableCategory.category.category_guid);
            });
            setDeleteAnnotationShown(false);
        }
    }

    function handleAnnotationDeleteClose() {
        setDeleteAnnotationShown(false)
    }

    function handleCommentChange(e) {
        const value = e.target.value;
        setComment(value.slice(0, MAX_COMMENT_LENGTH));
    }

    // function getNodeIndex(n) {
    //     var i = 0;
    //     if (n) {
    //         while ((n = n.previousSibling)) i++;
    //     }
    //     return i;
    // }

    const handleSaveClick = async () => {
        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }
        var rangePosition = rangeStartEndPosition(
            preSelectionRange,
            range,
            selectedTextLength
        );

        let payload = {
            category_guid: selectedCategory.category_guid,
            page_url: PageURL ?? '',
            reference_identifier: CONTEXTHELPERS.getGUIDFromContext(context),
            raw_content: rawContent,
            content: content,
            range_position_start: rangePosition.start,
            range_position_end: rangePosition.end,
            closest_guid: closestGuid,
            comment: (comment.trim() && comment.trim().length > 0) ? comment : "",
            version: guidebookArticle?.version ?? articleVersion,
            page_bread_crumb: ARTICLEHELPERS.getBreadcrumbData(),
            is_annotations_version_updated: false,
            checked: false
        };
        payload = await ANNSERVICES.createAnnotation(location.pathname, payload, getMemberFirmID());
        if (payload) {
            dispatch({
                type: ACTIONS.ANNOTATIONS_CREATE,
                payload,
            });
            setTocAnnotations(prev => { return [...prev, payload] });

            if (annotationProps.selectedTocItem !== null && annotationProps.pageType !== '') {
                logs.trackEvent({
                    name: APPLICATION_INSIGHTS_CONSTANTS.annotation_name,
                    properties: annotationProps,
                });
            }
        }
        CONTEXTHELPERS.sortCategories(annState?.categories, selectedCategory.category_guid);
        document.getSelection().empty();
    };

    function closeNotification() {
        setShowNotification(false);
    }

    const annotationProps = {
        eventType: APPLICATION_INSIGHTS_CONSTANTS.annotation_eventType,
        pageUrl: context.pageRequestURL,
        selectedTocItem: UTILITIESHELPER.getSessionStorage(SESSION_STORAGE_CONSTANTS.selected_content),
        pageType: UTILITIESHELPER.getSessionStorage(SESSION_STORAGE_CONSTANTS.page_type),
        jobTitle: UTILITIESHELPER.getSessionStorage(SESSION_STORAGE_CONSTANTS.level),
        memberFirm: UTILITIESHELPER.getLocalStorage(LOCAL_STORAGE_CONSTANTS.research_portal_memberfirm)
    };

    return (
        // <div className="an-modal-overlay" >
        <div >
            {shouldShowDarkOverlay &&
                <div className='full-screen-darken-overlay-fixed' onClick={handleCloseClick} />
            }
            <div className="annot-modal">
                <div className="an-modal-header">
                    <div className="an-modal-title">
                        {getLabel(
                            "hh_ChooseCategoryForHighlight",
                            "Choose a category for your highlight"
                        )}
                    </div>
                    <button className="an-modal-close" onClick={handleCloseClick} />
                </div>
                <div
                    className={
                        showNotification
                            ? "an-modal-with-notification-content"
                            : "an-modal-content"
                    }
                >
                    <ShowIf condition={showNotification}>
                        <AnnotationNotification
                            notificationText="The first 1000 characters of the selected text will be highlighted"
                            closeNotification={closeNotification}
                        />
                    </ShowIf>
                    <CategoryDropdown
                        categories={annotationCategories}
                        selectedCategories={selectedCategory}
                        onSelect={handleCategorySelect}
                        onAddClick={handleCategoryAddClick}
                        onEditClick={handleCategoryEditClick}
                        onDeleteClick={handleCategoryDeleteClick}
                    />
                    <div className="an-modal-comment">
                        <textarea
                            id="an-modal-comment-input"
                            className="an-modal-comment-input"
                            value={comment}
                            onChange={handleCommentChange}
                            placeholder={getLabel(
                                "hh_OptionalTypeCommentHere",
                                "(Optional) Type comment here..."
                            )}
                        ></textarea>
                        <span className="an-modal-comment-count">
                            {comment.length}/{MAX_COMMENT_LENGTH}
                        </span>
                    </div>
                    <div className="an-modal-footer">
                        <button className="an-modal-save" onClick={handleSaveClick}
                            ref={btnRef}>
                            {getLabel("hh_CategorySave", "SAVE")}
                        </button>
                    </div>
                </div>
            </div>
            {isPickerShown && (
                <ColorPicker
                    onUpdate={handlePickerUpdate}
                    onSave={handlePickerSave}
                    onClose={handlePickerClose}
                    colour={editableCategory.category.color ?? "#FFFF00"}
                    labelTxt={editableCategory.category.label ?? ""}
                    isUpdate={editableCategory.isEditable}
                    category_guid={editableCategory.category.category_guid}
                />
            )}
            {isDeleteAnnotationShown && (
                <AnnotationDelete
                    onDelete={handleDelete}
                    onClose={handleAnnotationDeleteClose}
                    categories={annotationCategories}
                    selected={selectedCategory}
                    selectedCategoryid={editableCategory.category.category_guid}
                    annotationCount={annotationsCount}
                    bookmarkCount={bookmarksCount}
                />
            )}

        </div>
    );
}

export default AnnotationModal;