
import React,{ memo } from 'react';
import { usePageContext } from '../../contexts/PageContext';
import { useTocContext } from '../../contexts/TocContext';
import { useReadingModeContext } from '../../contexts/ReadingModeContext';
import { useEmbeddedModeContext } from '../../contexts/EmbeddedModeContext';

import Article from "./Article"
import ArticleReadingMode from './ArticleReadingMode';

const ArticleResolver = memo((props) => {
    const { pubData, renderArticleContent, getArticleTitle, getArticleHasLoaded, embeddedPubData } = props;
    const { context } = usePageContext();
    const { selectedItemTOC } = useTocContext();
    const { isReadingMode } = useReadingModeContext();
    const { isEmbeddedMode } = useEmbeddedModeContext();
    const articleContent = renderArticleContent(pubData)
    const isGuidebook = context.pageControllerType === "folio";
    const articleTitle = getArticleTitle(pubData, isGuidebook, isEmbeddedMode, embeddedPubData, isReadingMode, context);
    const articleHasLoaded = getArticleHasLoaded(pubData);
    const isFiltered = selectedItemTOC.item?.isFiltered;

    const additionalProps = {
        articleTitle,
        articleContent,
        articleHasLoaded,
        isGuidebook,
        isFiltered
    }

    if (isReadingMode) {
        return <ArticleReadingMode {...props} {...additionalProps} />
    }

    return <Article {...props} {...additionalProps} />
});

ArticleResolver.defaultProps = {
    renderArticleContent: () => {},
    getArticleTitle: () => {}
}

export default ArticleResolver;
