
import React, { useState, createContext, useContext } from 'react'

export const LoadingContext = createContext()
export const useLoadingContext = () => useContext(LoadingContext)

const LoadingContextProvider = ({children}) => {
  const [isLoading, setIsLoading] = useState(false)

  const store = {
      isLoading,
      setIsLoading,
  }

  return (
    <LoadingContext.Provider value={store}>
        {children}
    </LoadingContext.Provider>
  )
}

export default LoadingContextProvider