import React from 'react';
import { Navigate } from 'react-router-dom';
import UTILITIESHELPER from '../../helpers/UtilitiesHelper';
const DO_NOT_LOGIN = (window.location.href.indexOf("localhost") > 0);

export const PrivateRoute = ({ children }) => {
    
    return (DO_NOT_LOGIN || UTILITIESHELPER.isValidAccessToken()) ? <>{children}</> : <Navigate to="/unauthorized" />;
};

export default PrivateRoute;

