import React,{useEffect} from 'react';
import ShowIf from '../ShowIf';
import { useThemeContext } from '../../../contexts/ThemeContext';
import { useReadingModeContext } from '../../../contexts/ReadingModeContext';
import ProfileHelpers from '../../../helpers/ProfileHelpers';
import { usePageContext, usePageContextLabels } from '../../../contexts/PageContext';
import DropdownPreferences from './PreferencesDropdown/DropdownPreferences';
import DropdownPageSettings from './DropdownPageSettings';
import Crumbtrail from '../../Breadcrumb/Crumbtrail';
import LinkHelpers from '../../../helpers/LinkHelpers';
import cx from 'classnames';
import xss from 'xss';
import ReactHtmlParser from 'react-html-parser';
import MemberFirmSelector from "../../Home/MemberFirmSelector";
import {useNavigate, useLocation} from "react-router-dom";
import CONTEXTHELPERS from "../../../helpers/ContextHelpers";
import { KNOWLEDGE_DOMAIN } from '../../Constants/Constants';
import UTILITIESHELPER from '../../../helpers/UtilitiesHelper';
import LanguageSelector from '../LanguageSelector';
import { OutageNotification } from '../OutageNotification';

const HeaderLayout = (props) => {
    const { headerData } = props;
    const { isDarkMode } = useThemeContext();
    const { isReadingMode } = useReadingModeContext();
    const { context, memberFirm, memberFirms, hasGottenCorrectTierValue, hasVerifiedIndustry, isCatalogPage, knowledgeDomain ,getMemberFirmID, tier, isResearchAssistantEnabled, countryKDSettings} = usePageContext();
    const { getLabel } = usePageContextLabels();
    const navigate = useNavigate();
    const location = useLocation();
    const FAQLink = "/content/support"
    const isResearchAssistantEnabledForKD =  UTILITIESHELPER.isResearchAssistantEnabledForKD(countryKDSettings, context.memberFirm, context.knowledgeDomain);
    const isResearchAssistantActiveForUser = isResearchAssistantEnabled && isResearchAssistantEnabledForKD ? true : false;

    const createMarkupSupportLinkSanitized = () => {
        const link = `<a href="${LinkHelpers.resolveLandingPageLink(context, FAQLink)}" class="support-link" title="${getLabel("hh_Support", "Support")}"></a>`
        const linkSanitized = xss(link, { whiteList: { a: ['href', 'class', 'title'] } });
        return (
            <>{ ReactHtmlParser(linkSanitized) }</>
        )
    }

    function handleMemberFirmChange(targetMemberFirm) {
        ProfileHelpers.setLastMemberFirm(targetMemberFirm);
        navigate(CONTEXTHELPERS.resolveMemberFirmPath(location.pathname, targetMemberFirm, context.language, true, context));
    }

    function setResearchAssistantTakeOverData(){
        UTILITIESHELPER.setRATakeOverData(context, tier, null, getMemberFirmID());
    }

    return (
            <div style={{ width: isReadingMode ? '100%' : '' }} className={cx("layout-header")}>
                <header className="header-new">
                    <div className={isCatalogPage === true ? 'd-flex header-container catalog-header' : 'd-flex header-container'}>
                        <div className="d-flex header-inner-container">
                            <div className="d-flex mr-auto">
                                <h1 className="logo-group">
                                    <a onClick={() => {ProfileHelpers.setLastMemberFirm(memberFirm)}} href="/" className="deloitte-rp-logo">
                                        <ShowIf condition={isCatalogPage === true && !isDarkMode}>
                                            <img className="primary-logo" src={headerData?.Content?.logoDark?.BinaryContent?.Url} alt={headerData?.Content?.logoDark?.Metadata?.altText || " "} />
                                        </ShowIf>
                                        <ShowIf condition={isCatalogPage === false && !isDarkMode}>
                                            <img className="primary-logo" src={headerData?.Content?.logo?.BinaryContent?.Url} alt={headerData?.Content?.logo?.Metadata?.altText || " "} />
                                        </ShowIf>
                                        <ShowIf condition={isDarkMode}>
                                            <img className="primary-logo" src={headerData?.Content?.logo?.BinaryContent?.Url} alt={headerData?.Content?.logo?.Metadata?.altText || " "} />
                                        </ShowIf>
                                    </a>
                                </h1>

                                <div style={{width: '165px', marginRight: '20px'}}>
                                    <MemberFirmSelector memberFirms={memberFirms} onChange={handleMemberFirmChange} collapsible={true}></MemberFirmSelector>
                                </div>

                                <ShowIf condition={knowledgeDomain!==KNOWLEDGE_DOMAIN.accounting && hasGottenCorrectTierValue && hasVerifiedIndustry}>
                                    <DropdownPreferences />
                                </ShowIf>

                                <div style={{width: '100px', marginLeft: '20px'}}>
                                    <LanguageSelector/>
                                </div>
                        </div>
                        <ShowIf condition={isResearchAssistantActiveForUser}>
                        <a href='/researchassistant' target="_blank">
                        <button className='researchAssistant' onClick={setResearchAssistantTakeOverData}>
                                <img src={isDarkMode ? '/images/chat-box-circle-white.svg' :'/images/chat-box-circle-black.svg'} className='chat-icon-img' />
                                <label className='researchAssistant-label'>{getLabel('ra_ResearchAssistantTitle', 'Research Assistant')}</label>
                                <img src={isDarkMode ? '/images/gotonext-blue.svg' : '/images/gotonext.svg'} className='goto-next-icon' />
                            </button>
                            </a></ShowIf>
                            <div className="d-flex">

                                {createMarkupSupportLinkSanitized()}
                                {window.DART.ENV.REACT_APP_OUTAGE_NOTIFICATION_ENABLED ? <OutageNotification /> : null}
                                <DropdownPageSettings />
                            </div>
                        </div>
                    </div>
                   
                </header>
            </div>
    )
}

export default HeaderLayout
