import React from 'react';
import PropTypes from 'prop-types';

function AddButton({ active, label, onClick, isHome, isDisabled}) {
    function handleClick() {
        onClick();
    }

    return (
        <button
            type="button"
            className={"mfdd__add-button " + (active ? 'active ':'') + (isHome ? 'home ':'') + (isDisabled ? 'disabled ': '')}
            onClick={handleClick}
        >{label}</button >
    )
}

AddButton.propTypes = {
    active: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}

export default AddButton;