import React from 'react';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalConfig } from './config/msalConfig';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import IndexApp  from './indexApp';

import {
  BrowserRouter as Router,
} from "react-router-dom";

if (window.DART.ENV.CONSOLE_MESSAGES_ENABLED !== 'true') {
  console.log = function () {};
  console.info = function() {};
  console.warn = function() {};
} else {
  console.log('Console logs are active in the development environment.');
}

const DO_NOT_LOGIN = (window.location.href.indexOf("localhost") > 0);
const msalInstance = !DO_NOT_LOGIN ? new PublicClientApplication(msalConfig) : null;
window.msalInstance = msalInstance;

if(!DO_NOT_LOGIN){

  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  msalInstance.handleRedirectPromise()
  .then(authResult => {
      const account = msalInstance.getActiveAccount();
      if (account !== undefined && account?.username !== undefined) {
        const tokenKey = `${account.homeAccountId}-login.windows.net-accesstoken-${account.idTokenClaims.aud}-${account.idTokenClaims.tid}-profile openid email user.read--`;
        sessionStorage.setItem("token", sessionStorage.getItem(tokenKey));
        sessionStorage.setItem("username", account?.username);
          sessionStorage.setItem("accountid", account?.homeAccountId) //+ account?.localAccountId;
          // Silently acquires an access token which is then attached to a request for MS Graph data
          const accessTokenRequest = {
              account: account,
              scopes: [`${window.DART.ENV.REACT_APP_CLIENTID}/.default`]
          };
          msalInstance
              .acquireTokenSilent(
                  accessTokenRequest)
              .then((accessTokenResponse) => {
                  // Acquire token silent success
                  let accessToken = accessTokenResponse.accessToken;
                  sessionStorage.setItem("IsValidToken", (accessToken && accessToken.length > 0) ? true : false)
                  console.info('AccessToken Successfully Created');
              })
              .catch((error) => {
                  console.error('Access Token issue');
                  if (error instanceof InteractionRequiredAuthError) {
                      msalInstance.acquireTokenRedirect(accessTokenRequest);
                  }
              });
      }
      else if(!account ){
          // redirect anonymous user to login page
          msalInstance.loginRedirect();
      }
  });
}

const AppWithAuthentication = () => (

  DO_NOT_LOGIN ? <IndexApp DoNotLogin={DO_NOT_LOGIN}/> :
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <IndexApp DoNotLogin={DO_NOT_LOGIN}/>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h5 className="card-title">Please sign-in to see your profile information.</h5>
      </UnauthenticatedTemplate>
    </MsalProvider>
);

const root = createRoot(document.getElementById('root'));
root.render(
  <Router>
    <AppWithAuthentication />
  </Router>
  );
