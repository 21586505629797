import React from 'react';
import ShowIf from '../ShowIf';
import { useThemeContext } from '../../../contexts/ThemeContext';

const HeaderImage = (props) => {
    const { headerData, hasFetchedHeaderData } = props;
    const { isDarkMode } = useThemeContext();

    return (
        <ShowIf condition={headerData && hasFetchedHeaderData && !isDarkMode}>
            <section className="header-image" aria-label="MemberFirm Banner">
                <img src={headerData?.BinaryContent?.Url} alt="Header"/>
            </section>
        </ShowIf>
    )
}

export default HeaderImage