import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { globalHistory } from '@reach/router'

const href = window.location.href;
const localDebug = (href.indexOf("localhost") > 0 || href.indexOf("devndinkrpservice") > 0);

const appInsightUIMapping = {
  None: 0,
  Critical: 0,
  Error: 1,
  Debug: 2,
  Information: 2,
  Trace: 2,
  Warning: 2
}

const page = {
    Annotation: 'Annotation',
    Article: 'Article',
    Catalog: 'Catalog',
    Collection: 'Collection',
    DCSService: 'DCSService',
    Folio: 'Folio',
    FolioSectionTOC: 'FolioSectionTOC',
    FolioArticle: 'FolioArticle',
    GuidebookSectionTOC: 'GuidebookSectionTOC',
    GuidebookArticle: 'GuidebookArticle',
    Header: 'Header',
    Link:'LinkResolver',
    PageContext: 'PageContext',
    Publication: 'Publication',
    Search: 'Search',
    History: 'History',
    JournalArticle: 'JournalArticle',
    RPService:'RPService',
    Highlight: 'highlight',
    Bookmark:'Bookmark',
    Login:'Login'
}

//object message = { page: string, section: 'welcome section', component: 'banner', action: 'click', exception , message }

var ai = null;
const reactPlugin = new ReactPlugin()
if (Boolean(window.AppInsight) && Boolean(window.AppInsight.ENV.InstrumentationKey) && Boolean(globalHistory) && Boolean(window.AppInsight.ENV.EnableTracking)) {
    ai = new ApplicationInsights({
        config: {
            instrumentationKey: window.AppInsight.ENV.InstrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: globalHistory }
            },
            loggingLevelTelemetry: appInsightUIMapping[window.AppInsight.ENV.LogLevel] ? appInsightUIMapping[window.AppInsight.ENV.LogLevel] : 0,
            disableFetchTracking: false,
            disableTelemetry: !(window.AppInsight.ENV.EnableTracking),
            enableAutoRouteTracking: true
        }
    })
    ai.loadAppInsights()

    ai.appInsights.addTelemetryInitializer((envelope) => {
        envelope.tags['ai.cloud.role'] = window.AppInsight.ENV.LogLevel
        envelope.tags["ai.cloud.roleInstance"] = 'ResearchPortal_Instance'
        envelope.data.timeNow = Date(Date.now()).toString()
    })

}


export const { appInsights } = ai || {}

const logs = {
    timeDistance: function (date1, date2) {
        let distance = Math.abs(date1 - date2);
        const hours = Math.floor(distance / 3600000);
        distance -= hours * 3600000;
        const minutes = Math.floor(distance / 60000);
        distance -= minutes * 60000;
        const seconds = Math.floor(distance / 1000);
        return `${hours}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
    },
    trackEvent: function (results) {
        if (ai && ai.appInsights && typeof ai.appInsights.trackEvent === 'function') {
            ai.appInsights.trackEvent(results);
        }
    },
    trackPageView: function (results) {
        if (ai && ai.appInsights && typeof ai.appInsights.trackPageView === 'function') {
            ai.appInsights.trackPageView(results);
        }
    },
    trackPageViewPerformance: function (results) {
        if (ai && ai.appInsights && typeof ai.appInsights.trackPageViewPerformance === 'function') {
            ai.appInsights.trackPageViewPerformance(results);
        }
    },
    trackException: function (results) {
        if (ai && ai.appInsights && typeof ai.appInsights.trackException === 'function') {
            ai.appInsights.trackException(results);
        }
    },
    trackTrace: function (results) {
        if (ai && ai.appInsights && typeof ai.appInsights.trackTrace === 'function') {
            ai.appInsights.trackTrace(results);
        }
    },
    trackMetric: function (results) {
        if (ai && ai.appInsights && typeof ai.appInsights.trackMetric === 'function') {
            ai.appInsights.trackMetric(results);
        }
    },
    trackPageVisitTime: function (results) {
        if (ai && ai.appInsights && typeof ai.appInsights.trackPageVisitTime === 'function') {
            ai.appInsights.trackPageVisitTime(results);
        }
    },
    startTrackPage: function (results) {
        if (ai && ai.appInsights && typeof ai.appInsights.startTrackPage === 'function') {
            ai.appInsights.startTrackPage(results);
        }
    },
    stopTrackPage: function (name, url, results) {
        if (ai && ai.appInsights && typeof ai.appInsights.stopTrackPage === 'function') {
            ai.appInsights.stopTrackPage(name, results);
        }
    },
    startTrackEvent: function (results) {
        if (ai && ai.appInsights && typeof ai.appInsights.startTrackEvent === 'function') {
            ai.appInsights.startTrackEvent(results);
        }
    },
    stopTrackEvent: function (name, results) {
        if (ai && ai.appInsights && typeof ai.appInsights.stopTrackEvent === 'function') {
            ai.appInsights.stopTrackEvent(name, results);
        }
    },
    flush: function () {
        if (ai && ai.appInsights && typeof ai.appInsights.flush === 'function') {
            ai.appInsights.flush();
        }
    },
    addTelemetryInitializerTags: function (results) {
        if (results && ai && ai.appInsights) {
            ai.appInsights.addTelemetryInitializer((envelope) => {
                for (const key in results) {
                    envelope.tags[key] = results[key]
                }
            })
        }
    },

    debug: function (page, component, message, ...properties) {
        logs.trackTrace({ message: page, properties: { component: component, details: message, properties }, severityLevel: SeverityLevel.Debug });
        if (localDebug) console.log(message, ...properties);
    },
    info: function (page, component, message) {
        logs.trackTrace({ message: page, properties: { component: component, details: message }, severityLevel: SeverityLevel.Information });
        if (localDebug) console.log(message);
    },
    warning: function (page, component, message) {
        logs.trackTrace({ message: page, properties: { component: component, details: message }, severityLevel: SeverityLevel.Warning });
        if (localDebug) console.log(message);
    },
    error: function (page, component, message, error, ...properties) {
        logs.trackException({ message: page, properties: { component: component, details: message, properties },eventId:properties[0]?.eventId !== undefined || null ? properties[0].eventId :1000, error: new Error(error), severityLevel: SeverityLevel.Error });
        if (localDebug) console.log(message, error,properties[0]?.eventId !==undefined || null ? properties[0]?.eventId :1000);
    },
    critical: function (page, component, message, error) {
        logs.trackException({ message: page, properties: { component: component, details: message }, error: new Error(error), severityLevel: SeverityLevel.Error });
        if (localDebug) console.log(message, error);
    },
    event:function (name,properties){
        logs.trackEvent({name: name, properties: properties })
    }
   
}

export { logs, ai, reactPlugin, page, appInsightUIMapping }

export const logLevels = SeverityLevel

export default (Component) => withAITracking(reactPlugin, Component)

