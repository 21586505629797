import React from 'react';
import Dropdown from '../Annotations/Dropdown';
import { usePageContextLabels } from '../../contexts/PageContext';

function CategoryTrigger({ selectedCategories }) {
    //styling for background category color
const backgroundColorStyle= {
    backgroundColor: selectedCategories.color
  }
    return (
        <div className='an-dd-category-trigger'>
            <div className="an-dd-category-chip" style={backgroundColorStyle}></div>
            <div className="an-dd-category-label">{selectedCategories.label}</div>
        </div>
    )
}

function CategoryItem({ category, selectedCategories, onClick, onEditClick, onDeleteClick, index }) {
    function handleClick() {
        onClick(category);
    }

    function handleEditClick(event) {
        onEditClick(category)
        event.stopPropagation();
    }

    function handleDeleteClick(event) {
        onDeleteClick(category)
        event.stopPropagation();
    }

    function onKeydown(e) {
        if (e.key === 'Enter') {
            onClick(category);
            document.querySelector('#an-modal-comment-input').focus();
            return;
        }
        const nextIndex = e.key === 'ArrowDown' ? '#option-' + (index + 1) : '#option-' + (index - 1);
        const nextOptionItem = document.querySelector(nextIndex);
        if (nextOptionItem) {
            nextOptionItem.focus();
        }
    }

    let classNames = 'option-item an-dd-category-item';
    if (selectedCategories.category_guid === category.category_guid) classNames += ' option-item ad-dd-category-item--selected';

  //styling for background category color
   const backgroundColorStyle= {
    backgroundColor: category.color
  } 

    return (
        <div className={classNames} onClick={handleClick} tabIndex="0" id={'option-' + index} onKeyDown={onKeydown}>
            <div className="an-dd-category-chip" style={backgroundColorStyle}></div>
            <div className="an-dd-category-label">{category.label}</div>
            <button type="button" className="an-dd-category-edit" onClick={(e) => handleEditClick(e)}></button>
            <button type="button" className={(selectedCategories.category_guid === category.category_guid) ? 'hide' : 'an-dd-category-delete'} onClick={(e) => handleDeleteClick(e)}></button>
        </div>
    )
}


function CategoryDropdown({ categories, selectedCategories, onSelect, onEditClick, onAddClick, onDeleteClick }) {
    const { getLabel } = usePageContextLabels();

    function handleSelect(category) {
        onSelect(category);
    }

    function handleAddClick(e) {
        e.stopPropagation();
        onAddClick();
    }

    function handleEditClick(category, index) {
        onEditClick(category, index);
    }

    function handleDeleteClick(category, index) {
        onDeleteClick(category, index);
    }

    const listItems = categories.map((c, index) => (
        <CategoryItem
            key={`${c.label}-${c.color}`}
            category={c}
            onClick={handleSelect}
            selectedCategories={selectedCategories}
            index={index}
            onEditClick={(e) => handleEditClick(e, index)}
            onDeleteClick={(e) => handleDeleteClick(e, index)}
        />
    ))

    function getTriggerEl() {
        return <CategoryTrigger selectedCategories={selectedCategories}/>;
    }

    return (
      <Dropdown
        triggerEl={getTriggerEl()}
        ddClassNames="an-dd"
        containerClassNames="an-dd-container"
      >
        <Dropdown.Panel additionalClassNames="an-dd-panel">
          <Dropdown.ScrollArea
            maxHeight="145px"
            additionalClassNames="an-dd-scroll-area"
          >
            {listItems}
          </Dropdown.ScrollArea>
          <button className="an-dd-category-add" onClick={handleAddClick}>
            <div className="an-dd-category-add-image"></div>
            <div className="an-dd-category-add-label">
              {getLabel("hh_CategoryCreate", "CREATE NEW CATEGORY")}
            </div>
          </button>
        </Dropdown.Panel>
      </Dropdown>
    );
}

export default CategoryDropdown;