import { ErrorCodes, ErrorMessages } from "../components/Constants/Errors";
import RETRIEVALSERVICES from "../services/rp-service";
import CONTEXTHELPERS from "./ContextHelpers";
import { logs, page } from "./log";

async function fetchCategories(url, memberFirmId) {
    try {
        var response = await RETRIEVALSERVICES.retrieveCategories(CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId));
        return response ? response : [];
    } catch (err) {
        logs.error(page.Bookmark, 'CategoryService', ErrorMessages.categoryFetch, err, { eventId: ErrorCodes.Annotation});
    }
}

async function createCategory(url, payload, memberFirmId) {
    try {
        payload.query_spec_base_model = CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId);
        return await RETRIEVALSERVICES.createCategory(payload);
    } catch (err) {
        logs.error(page.Bookmark, 'CategoryService', ErrorMessages.categoryCreate, err, {eventId: ErrorCodes.Annotation});
    }
}

async function updateCategory(url, payload, category_guid, memberFirmId) {
    try {
        payload.query_spec_base_model = CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId);
        return await RETRIEVALSERVICES.updateCategory(payload, category_guid);
    } catch (err) {
        logs.error(page.Bookmark, 'CategoryService', ErrorMessages.categoryUpdate, err, { eventId: ErrorCodes.Annotation });
    }
}
async function deleteCategory(url, category_guid, memberFirmId, target_category_guid = null, delete_confirmation = null) {
    try {
        var payload = {
            query_spec_base_model: CONTEXTHELPERS.getUPSQuerySpecBaseModel(url, memberFirmId),
            category_guid: category_guid,
            target_category_guid: target_category_guid,
            delete_confirmation: delete_confirmation
        };
        return await RETRIEVALSERVICES.deleteCategory(payload);
    } catch (err) {
        logs.error(page.Bookmark, 'CategoryService', ErrorMessages.categoryDelete, err, { eventId: ErrorCodes.Annotation });
    }
}

const CategoryService = {
    fetchCategories,
    createCategory,
    updateCategory,
    deleteCategory
};

export default CategoryService;