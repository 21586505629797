
import React, { useState, useLayoutEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { useTocContext } from '../../contexts/TocContext';
import { usePageContextLabels } from '../../contexts/PageContext';

import ShowIf from '../Common/ShowIf';

const PrevNextArticle = ({ articleHasLoaded, className }) => {
    const { selectedItemTOC } = useTocContext();
    const [hrefs, setHrefs] = useState({ prevHref: null, nextHref: null });
    const location = useLocation();
    const { getLabel } = usePageContextLabels();


    useLayoutEffect(() => {
        try {
            const selectedNode = document.querySelector('.selected-link');
            const sectionNodes = selectedNode.closest('.toc-section-children');
            const linksNodeList = (sectionNodes || document.querySelector('.side-pannel-inner-container'))?.querySelectorAll('a') || [];
            const links = Array.prototype.slice.call(linksNodeList) || [];

            if (!links.length) {
                return setHrefs({
                    prev: null,
                    next: null,
                });
            }

            const selectedLinkIndex = links.findIndex((link) => link.classList.contains('selected-link'))
            const isWithinSection = !!sectionNodes;
            const getsectionHref = () => {
                const parentHref = sectionNodes.previousSibling?.getElementsByTagName('a')[0];
                return parentHref;
            };

            const prevLink = links[selectedLinkIndex - 1] || (isWithinSection ? getsectionHref() : links[0]);
            const nextLink = links[selectedLinkIndex + 1] || (isWithinSection ? getsectionHref() : links[0]);
      
            setHrefs({
                prev: {pathname: new URL(prevLink?.href).pathname, canDisplayInReadingMode: prevLink.classList.contains('toc-link-non-expandable')},
                next: {pathname: new URL(nextLink?.href).pathname, canDisplayInReadingMode: nextLink.classList.contains('toc-link-non-expandable')},
            })
         
        } catch {
            setHrefs({
                prev: null,
                next: null,
            })
        }
      
    }, [selectedItemTOC])

    const constructPath = ( canDisplayInReadingMode, queryString,pathname = "") => {

        if(!canDisplayInReadingMode) {
            return pathname;
            
        }
       return `${pathname}${queryString}`;
    }
    return (
        <ShowIf condition={articleHasLoaded && hrefs?.prev?.pathname && hrefs?.next?.pathname}>
            <div className={cx("prev-next-article-links-container d-flex", className)}>
                <Link 
                    className="d-flex prev-next-text prev-text"  
                    to={constructPath(hrefs?.prev?.canDisplayInReadingMode, location.search,hrefs?.prev?.pathname)}
                >
                    {getLabel("a_PreviousArticle", "Previous Article")}
                </Link>
                <Link 
                    className="d-flex prev-next-text next-text" 
                    to={constructPath(hrefs?.next?.canDisplayInReadingMode, location.search,hrefs?.next?.pathname)}
                >
                    {getLabel("a_NextArticle", "Next Article")}
                </Link>
            </div>
        </ShowIf>
    )
};

export default PrevNextArticle;
