import { MEMBERFIRM_CONSTANTS } from '../components/Constants/Constants';
import SH from './StorageHelpers';
import UTILITIESHELPER from './UtilitiesHelper';

const STORAGE_KEY_MEMBER_FIRM = 'MemberFirm'; //Previously: "research-portal-member-firm"
const STORAGE_KEY_MEMBER_FIRM_TAXONOMY_ID = 'member-firm-Id';
const STORAGE_KEY_SERVICE_AREA = 'ServiceArea';
const STORAGE_KEY_LEVEL = 'Level';
const STORAGE_KEY_SUBSCRIBED_MFS = 'rp::subs:memberFirm';
const STORAGE_KEY_LAST_MEMBER_FIRM = 'research-portal-member-firm';
const STORAGE_KEY_LAST_LANGUAGE = 'cur-mt-name';
const STORAGE_KEY_LANGUAGE = "mt-name";

const lc = (w) => String(w).toLowerCase()
const sorter = (a, b) => lc(a).localeCompare(lc(b))


export function initializeProfile(memberFirm = MEMBERFIRM_CONSTANTS.dtt, countryCode = MEMBERFIRM_CONSTANTS.us, serviceArea = null, level = null) {
    SH.setLocalStorage(STORAGE_KEY_MEMBER_FIRM, lc(countryCode));
    if(UTILITIESHELPER.isObjectNullorEmpty(SH.getLocalStorage(STORAGE_KEY_LAST_MEMBER_FIRM))){
        SH.setSessionStorage(STORAGE_KEY_LAST_MEMBER_FIRM, lc(memberFirm));
    }
    SH.setSessionStorage(STORAGE_KEY_SERVICE_AREA, serviceArea);
    SH.setSessionStorage(STORAGE_KEY_LEVEL, level);

    if (SH.getLocalStorageJSON(STORAGE_KEY_SUBSCRIBED_MFS) === null) {
        SH.setLocalStorageJSON(STORAGE_KEY_SUBSCRIBED_MFS, []);
      
    }
    SH.setLocalStorageJSON("researchAssistant::subs:memberFirm", []);
}

export function getMemberFirm() {
    return getFallbackMemberFirm(lc(SH.getLocalStorage(STORAGE_KEY_MEMBER_FIRM)));
}

export function getServiceArea() {
    return SH.getSessionStorage(STORAGE_KEY_SERVICE_AREA);
}

export function getLevel() {
    return SH.getSessionStorage(STORAGE_KEY_LEVEL);
}

// MemberFirm Subscription
export function getMFSubs() {

    return SH.getLocalStorageJSON(STORAGE_KEY_SUBSCRIBED_MFS).map(x => lc(x)).sort(sorter);
}

export function addMFSub(memberFirm) {
    const subs = [...getMFSubs(), lc(memberFirm)].sort(sorter);
    SH.setLocalStorageJSON(STORAGE_KEY_SUBSCRIBED_MFS, subs);
}

export function removeMFSub(memberFirm) {
    const subs = getMFSubs()
        .filter(mf => lc(mf) !== lc(memberFirm))
        .sort(sorter);
    SH.setLocalStorageJSON(STORAGE_KEY_SUBSCRIBED_MFS, subs);
}

// All member firms, opm firm then mf subs
export function getAllMemberFirms() {
    return [...new Set([getMemberFirm(), ...getMFSubs()])];
}


// These two function support the "remembering" of the previously selected
// member firm for revisit to the home/landing page.
export function setLastMemberFirm(memberFirm) {
    if (memberFirm) {
        //clog(`memberFirm[${memberFirm}]`)
        SH.setLocalStorage(STORAGE_KEY_LAST_MEMBER_FIRM, lc(memberFirm));
    }
}

export function setLastMemberFirmResearchAssistant(memberFirm) {
    if (memberFirm) {
        SH.setLocalStorage("research-assistant-memberfirm",lc(memberFirm));
    }
}

export function getLastorDefaultMemberFirm() {
    const last = SH.getLocalStorage(STORAGE_KEY_LAST_MEMBER_FIRM);
    let defaultMem = SH.getLocalStorage(STORAGE_KEY_MEMBER_FIRM);
    return (last === null) ? defaultMem : String(last);
}

export function setSelectedMemberFirmTaxonomyId(memberFirmId) {
    SH.setLocalStorage(STORAGE_KEY_MEMBER_FIRM_TAXONOMY_ID, memberFirmId);
}

export function getSelectedMemberFirmTaxonomyId() {
    return SH.getLocalStorage(STORAGE_KEY_MEMBER_FIRM_TAXONOMY_ID);
}

export function getLanguageConfig(urlLanguage) {
    // Retrieve current language selection
    const ssName = SH.getSessionStorage(STORAGE_KEY_LAST_LANGUAGE);

    // If we have a current language in session storage, return lang info
    if (ssName) {
        const abbreviation = SH.getSessionStorage("cur-mt-abbreviation");
        let languageCodeFE = SH.getSessionStorage("cur-mt-languageCodeFE");

        if (!languageCodeFE || languageCodeFE === "null" || languageCodeFE === "") {
            languageCodeFE = urlLanguage;
        }

        return { name: ssName, abbreviation, languageCodeFE };
    }

    // Retrieve local storage language configuration
    const lsName = SH.getLocalStorage(STORAGE_KEY_LANGUAGE);

    // If we have a language in local storage, return lang info
    if (lsName) {
        const abbreviation = SH.getLocalStorage("mt-abbreviation");
        let languageCodeFE = SH.getLocalStorage("mt-languageCodeFE");

        if (!languageCodeFE || languageCodeFE === "null" || languageCodeFE === "") {
            languageCodeFE = urlLanguage;
        }

        return { name: lsName, abbreviation,languageCodeFE };
    }

    // Fallback to English
    return { name: "English", abbreviation: "EN", languageCodeFE: urlLanguage };
}

// Fallback member firm if the users home firm does not have a home member firm catalog. 
export const getFallbackMemberFirm = (memberFirm) => {
    //added new check of memberFirm !=="content" because in below case getting "content" in position of member firm
    //https://qinkrpservice.aaps.deloitte.com/content/docid=2_342973%23section_3_rule_3524-342973
    if (memberFirm && memberFirm !== "" && memberFirm !== "content") {
        memberFirm = lc(memberFirm);
        //ONLY when the incoming memberFirm is blank, do we need to fall back to a default (previous)
        //THERFORE, I am not sure why we were doing this before...
        /*if (memberFirm !== "us" && memberFirm !== "dtt") {
            console.log("getFallbackMemberFirm:Fall back to dtt? Current memberFirm:", memberFirm)
            memberFirm = 'dtt';
        }
        else {
            memberFirm = lc(memberFirm);
        }*/
    }
    else {
        //User/session is initialized with user's MF as last-viewed
        memberFirm = getLastorDefaultMemberFirm();
    }

    if (memberFirm === undefined || memberFirm === null || memberFirm === "") {
        const defaultMem = SH.getLocalStorage(STORAGE_KEY_MEMBER_FIRM);
        memberFirm = defaultMem ?? "dtt";
	}

    //clog(`memberFirm[${memberFirm}]`);
    return memberFirm;
}

export const getFallbackLanguageByMemberFirm = (memberFirm, language) => {
    let languageSettings = getLanguageConfig(language);
    SH.setLocalStorage("research-portal-language", language);
    if (language !== languageSettings.languageCodeFE) {
        language = languageSettings.languageCodeFE;
    }
    //added new check of language.includes("docid") because in below case getting "docid=2_342973%23section_3_rule_3524-342973" in position of language
    //https://qinkrpservice.aaps.deloitte.com/content/docid=2_342973%23section_3_rule_3524-342973
    if (!language || language.startsWith("en") || language.includes("docid")) {
        switch (memberFirm) {
            //UK is also having its content in DCS as en-US
            //case "uk": language = "en-UK"; break;
            default: language = "en-US"; break;
        }
    }

    //WalkmeIntegration
    window.langVar = language;

    //clog(`memberFirm[${memberFirm}] language[${language}]`);
    return language;
}

export function isSelectedMFAndOPMMemberFirmAreSame() {
    //STORAGE_KEY_LAST_MEMBER_FIRM = selected MF
    //STORAGE_KEY_MEMBER_FIRM = OPM MF
    if (UTILITIESHELPER.isStringNullorEmpty(SH.getLocalStorage(STORAGE_KEY_MEMBER_FIRM)) || UTILITIESHELPER.isStringNullorEmpty(SH.getLocalStorage(STORAGE_KEY_LAST_MEMBER_FIRM)))
        return false;

    if (lc(SH.getLocalStorage(STORAGE_KEY_MEMBER_FIRM)) === lc(SH.getLocalStorage(STORAGE_KEY_LAST_MEMBER_FIRM)))
        return true;

    return false;
}

const ProfileHelpers = {
    getMemberFirm,
    getServiceArea,
    getLevel,
    getMFSubs,
    addMFSub,
    removeMFSub,
    getAllMemberFirms,
    getLastorDefaultMemberFirm,
    setLastMemberFirm,
    getFallbackLanguageByMemberFirm,
    getFallbackMemberFirm,
    setSelectedMemberFirmTaxonomyId,
    getSelectedMemberFirmTaxonomyId,
    setLastMemberFirmResearchAssistant,
    isSelectedMFAndOPMMemberFirmAreSame
}

export default ProfileHelpers;