import React, { useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import ShowIf from '../Common/ShowIf';
import cx from 'classnames';
import { useSearchResultContext } from '../../contexts/SearchResultContext';

const CollectionTocSearchBadge = ({ tocItem, pageControllerType, searchResultCount }) => {
    const { setActiveFilters, allButtonsOn, setAllButtonsOn, setAllButtonText, isTriggerAllOnOff } = useSearchResultContext();
    const [isActive, setIsActive] = useState(true);

    let query = new URLSearchParams(useLocation().search);
    const isSearchActive = !!query?.get('searchPhrase');

    useEffect(() => { 
        var tempActiveFilter = ("folio".indexOf(pageControllerType) > -1) ? tocItem.id : ("deloitte-communications|professional-news".indexOf(pageControllerType) > -1) ? tocItem.linkExternal : tocItem.PublicationGUID;
		if(allButtonsOn){
			setIsActive(false);
            setActiveFilters(prevFilters => [...prevFilters, tempActiveFilter])
            setAllButtonText(true);
		}else{
			setIsActive(true);
            setActiveFilters(prevFilters => prevFilters.filter(x => x !== tempActiveFilter))
            setAllButtonText(false);
		}

	}, [allButtonsOn, isTriggerAllOnOff]) //eslint-disable-line

    useEffect(() => {
        return () => {
            setAllButtonsOn(false);
        }
    }, []) //eslint-disable-line


    function toggleHandler() {
        var tempActiveFilter = ("folio".indexOf(pageControllerType) > -1) ? tocItem.id : ("deloitte-communications|professional-news".indexOf(pageControllerType) > -1) ? tocItem.linkExternal : tocItem.PublicationGUID;

        if (isActive) {
            setIsActive(false);
            setActiveFilters(prevFilters => [...prevFilters, tempActiveFilter])
            
        }
        else {
            if (searchResultCount > 0) {
                setIsActive(true);
                setActiveFilters(prevFilters => prevFilters.filter(x => x !== tempActiveFilter))
            }
        }
    }

    return (
        <>
            <ShowIf condition={isSearchActive}>
                {(
                    <button className={cx(
                        {
                            'search-badge': !isActive,
                            'search-badge-active': isActive
                        })} onClick={toggleHandler}>
                        {searchResultCount}
                    </button>
                )}
            </ShowIf>
            <ShowIf condition={isSearchActive && isActive && searchResultCount === 'DOT'}>
                <div className="search-dot">&nbsp;</div>
            </ShowIf>
        </>
    )
}

export default CollectionTocSearchBadge;