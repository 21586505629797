import React,{ useState,useEffect } from 'react';

import cx from 'classnames';
import getIconSrc from '../../helpers/getIconSrc';
import { useParams } from 'react-router-dom';


const IconWideModeTOC = ({ onClick, className,shouldBeFixed}) => {
    const { pageControllerType } = useParams();
    const [height,setHeight] =useState(0);

    
useEffect(()=>{
    var navHeight=0;
    let previewHeight=document.getElementsByClassName("preview-warning")[0]?.clientHeight

    if(!previewHeight)
    {
        previewHeight=0; 
    }

    let heightOfCrum=document.getElementsByClassName("crumbtrail")[0]?.clientHeight;
    navHeight=previewHeight+ heightOfCrum;

    setHeight(navHeight);
    console.log("shouldBeFixed"+shouldBeFixed);
},[])

    return (
        <div className={cx("icon-closed-sidebar-container", className)} style={{
            top: shouldBeFixed? height : 15 }}
            >
			<button onClick={onClick} className="icon-closed-sidebar"></button>
			<img className="icon-closed-sidebar-img" src={getIconSrc(pageControllerType)} alt="Open TOC" />
        </div>
    )
};

IconWideModeTOC.defaultProps = {
    onClick: () => { },
    className: '',
};

export default IconWideModeTOC;