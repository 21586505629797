
function generateRecentHistoryLinks(recentHistory) {
    return recentHistory.map(x => ({
        PublicationGUID: null,
        PublicationType: null,
        linkAltText: decodeURIComponent(x.title) ,
        linkDocs: null,
        linkExternal: x.relative_url,
        linkInternal: null,
        linkText: decodeURIComponent(x.title)
    }))
  }

  function setRecentHistoryLinks(res, recentdata){
    res.CatalogCollection.filter(x=> x.title === "lbl_Catalog_TitleGenericRecentlyViewedPublications_Title")[0].links= recentdata;
    return res;
  }

const RecentlyViewedHelper = {
    generateRecentHistoryLinks,
    setRecentHistoryLinks
};

export default RecentlyViewedHelper;
