import React, { useEffect, useState, useCallback } from 'react'
import { usePageContext, usePageContextLabels } from '../../contexts/PageContext';
import cx from "classnames";
import RedirectModal from '../Article/RedirectModal';
import LINKHELPERS from '../../helpers/LinkHelpers';
import { logs, page } from '../../helpers/log';
import useDCSService from '../../services/useDCSService';
import { ACTIONS, useResearchAssistantContext } from '../../contexts/ResearchAssistantContext';
import { RESEARCH_ASSISTANT_ERROR_TYPES } from '../Constants/Constants';
import RenderFormsAndTemplates from '../Article/RenderFormsAndTemplates';

export const getIsArticlePdf = (_pubData) => {
    return (
        _pubData?.content[0]?.content?.format?.toLowerCase() === ".pdf"
    );
};

function RaFormsAndTemplatesModal(props) {
    const { dispatch } = useResearchAssistantContext();
    const [shouldShowDarkOverlay] = useState(true);
    const { getLabel } = usePageContextLabels();
    const [publicationData, setPublicationData] = useState(null);
    const [isWideMode] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isRedirectModalVisible, setIsRedirectModalVisible] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');
    const [shouldShowOverlayRedirect, setShouldShowOverlayRedirect] = useState(false);
    const [messageRedirectTL, setMessageRedirectTL] = useState('');
    const isArticlePdf = getIsArticlePdf(publicationData);
    const { memberFirms } = usePageContext();
    const { generateQuerySpec, retrievePublicationForTopic } = useDCSService();

    const renderEmbeddedOrNormal = () => {
        if (isLoading) {
            return (
                <div className="search-modal-container cf-right shimmerArticleCard shimmerArticleWrapper" data-testid="loading-spinner">
                    <h2 className="article-title shimmerArticleCardBG shimmerArticleCardTitleLine">
                        <span className="ph"></span>
                    </h2>
                    <div className="article-content-container">
                        <div className="article">
                            <div className="body">
                                <div className="shimmerArticleCardBG shimmerArticleCardIntroLine"></div>
                                <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                                <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                                <div className="shimmerArticleCardBG shimmerArticleCardContentLine"></div>
                                <div className="shimmerArticleCardBG shimmerArticleCardContentLine shimmerArticleCardContentLineEnd"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return articleContent;
        }
    };

    const handleDocumentClick = useCallback(async (ev) => {
        if (ev.target.nodeName === "path" && ev.target.id !== '') {
            ev.preventDefault();
        }
        else {
            let targetBaseUrl = `/${props.memberFirm}/${props.language}/${props.knowledgeDomain}`;
            const tocUrls = LINKHELPERS.getTocUrls(targetBaseUrl);

            (async () => {
                let navigatableLink = await LINKHELPERS.getNavigatableLink({
                    ev,
                    tocUrls,
                    memberFirm: props.memberFirm, language: props.language, knowledgeDomain: props.knowledgeDomain,
                    messageCannotOpenTab: getLabel("l_InternalLinkCannotBeOpenedInANewTab", "Internal link cannot be opened in a new tab/window in Pilot"),
                    messageCannotResolve: getLabel("l_LinkCannotBeResolved", "Link cannot be resolved."),
                    isSearchResultLink: false,
                    popupContent: true,
                    memberFirms
                });
                if (navigatableLink) {
                    if (LINKHELPERS.isLocalToThisPageLink(tocUrls, navigatableLink)) {
                        window.open(LINKHELPERS.sanitizeUrl(navigatableLink), '_blank', 'noreferrer');
                    }
                    else {
                        if (navigatableLink.split(";").length > 1) {
                            // TL link : Set Redirect Modal visible and send parameters from here
                            setMessageRedirectTL(getLabel("l_LinkModalViewLinkTL", "The content requested is not currently available in ORP. Would you like to see this content in Technical Library?"))
                            setRedirectURL(navigatableLink.split(";")[0]);
                            setShouldShowOverlayRedirect(true);
                            setIsRedirectModalVisible(true);
                        }
                        else {
                            window.open(LINKHELPERS.sanitizeUrl(navigatableLink), '_blank', 'noreferrer');
                        }
                    }
                }
            })();
        }
    }, []);//eslint-disable-line

    const renderArticleContent = (pubData) => (
        <RenderFormsAndTemplates pubData={pubData} excludeBinaries={false} isScrollDisabled={props.isScrollDisabled} />
    );

    const articleContent = renderArticleContent(publicationData);

    const resolveFormsAndTemplatesUrl = async (topicId, publicationId) => {
        //retrieve Publication data for Topic
        const querySpec = generateQuerySpec(null, 0);
        querySpec.member_firm = [props.memberFirmId];
        querySpec.knowledge_domain = [props.knowledgeDomainId];
        querySpec.content_item = topicId;
        return await retrievePublicationForTopic(querySpec, topicId, publicationId);
    }

    const retrievePublication = useCallback(async (isCancelled) => {
        let errorPayload = {}
        try {
            setIsLoading(true);
            const publication = await resolveFormsAndTemplatesUrl(props.contentItemGuid, props.pubGuid);
            if (publication == null) {
                props.closeModal()
                errorPayload.errorType = RESEARCH_ASSISTANT_ERROR_TYPES.NO_CONTENT;
                errorPayload.hasError = true
                dispatch({
                    type: ACTIONS.RA_ERROR,
                    payload: errorPayload,
                })
            }
            if (!isCancelled) {
                if (publication) {
                    setPublicationData(publication);
                    setIsLoading(false);
                    if (publication.content[0].content.format === ".pdf" && publication.content[0].content.display_download !== "true") {
                        props.closeModal()
                        errorPayload.errorType = RESEARCH_ASSISTANT_ERROR_TYPES.NO_CONTENT
                        errorPayload.hasError = true
                        dispatch({
                            type: ACTIONS.RA_ERROR,
                            payload: errorPayload,
                        })
                    }
                }
                else {
                    //we assume here that the article was not available for this MF, so we WH UP to current publication in the folio (will WH from there if that does not exist)
                    props.closeModal();
                    errorPayload.errorType = RESEARCH_ASSISTANT_ERROR_TYPES.NO_CONTENT;
                    errorPayload.hasError = true
                    dispatch({
                        type: ACTIONS.RA_ERROR,
                        payload: errorPayload,
                    })
                }
            }
        } catch (err) {
            //we assume here that the article was not available for this MF, so we WH UP to current publication in the folio (will WH from there if that does not exist)
            logs.info(page.Catalog, 'RaFormsAndTemplatesModal', `retrievePublication::Catalog:Article was not available for this MF. pageURL: ${props.contentItemGuid}`);
            props.closeModal();
            errorPayload.errorType = RESEARCH_ASSISTANT_ERROR_TYPES.NO_CONTENT
            errorPayload.hasError = true
            dispatch({
                type: ACTIONS.RA_ERROR,
                payload: errorPayload,
            })
        }
    }, []);//eslint-disable-line

    useEffect(() => {
        let isCancelled = false;
        retrievePublication(props.pageUrl, isCancelled);
        const articlePopupContainerElem = document.querySelector(".search-article-container");
        articlePopupContainerElem.addEventListener("click", handleDocumentClick);
        return () => {
            isCancelled = true;
        };
    }, [retrievePublication, props.pageUrl, handleDocumentClick]);


    const searchTemplateModalStyle = {
        paddingBottom: isArticlePdf ? 0 : 12.5,
        paddingRight: isWideMode ? 70 : "",
    }

    return (
        <div>
            {shouldShowDarkOverlay && (
                <div className="full-screen-darken-overlay-fixed" />
            )}
            {
                <div className="annot-modal search-modal">
                    <div className="an-modal-header">
                        <div className="an-modal-title">
                            {getLabel("b_BinaryDownload", "Download")}
                        </div>
                        <button className="an-modal-close" onClick={props.closeModal} />
                    </div>
                    <div className="search-modal-body">
                        <div data-testid="document"
                            className={cx(
                                "article-container cf-right search-article-container",
                                {
                                    "article-container-wide-mode": isWideMode,
                                    "article-container-wide-annotation-on": isWideMode,
                                }
                            )}
                        >
                            <div
                                className={cx("d-flex article-container-inner", {
                                    "article-container-inner-for-pdf": isArticlePdf,
                                    "m-auto wide-margin": isWideMode,
                                })}
                            >
                                <div className="search-template-modal"
                                    style={searchTemplateModalStyle}>
                                    <div>
                                        <div>
                                            {renderEmbeddedOrNormal()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <RedirectModal
                                setShouldShowRedirectModal={setIsRedirectModalVisible}
                                shouldShowRedirectModal={isRedirectModalVisible}
                                redirectURL={redirectURL}
                                setShouldShowOverlay={setShouldShowOverlayRedirect}
                                shouldShowOverlay={shouldShowOverlayRedirect}
                                messageRedirectTL={messageRedirectTL}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default RaFormsAndTemplatesModal;