
import React from 'react';

const SidePannel = ({ heading, children }) => {
    return (
        <div className="side-pannel-small">
            <div className="side-pannel-inner-container">
                <div className="side-pannel-heading">{heading}</div>
                <div className="side-pannel-body">
                {children}
                </div>
            </div>
        </div>
    )
}

export default SidePannel;
