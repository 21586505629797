
import React from 'react';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import { useReadingModeContext } from '../../../contexts/ReadingModeContext';
import HeaderImage from './HeaderImage';
import HeaderLayout from './HeaderLayout';
import RETRIEVALSERVICES from '../../../services/rp-service';
import { usePageContext } from '../../../contexts/PageContext';
import ShowIf from '../ShowIf';
import { logs, page } from '../../../helpers/log';
import { ErrorCodes, ErrorMessages } from '../../Constants/Errors';
import UTILITIESHELPER from '../../../helpers/UtilitiesHelper'
import Crumbtrail from '../../Breadcrumb/Crumbtrail';

const Header = () => {
    const { context } = usePageContext();
    const { isReadingMode } = useReadingModeContext();
	const [headerData, setHeaderData] = useState({});
	const [hasFetchedHeaderData, setHasFetchedHeaderData] = useState(false);
    const [shouldShowCrumbtrail, setShouldShowCrumbtrail] = useState(true);
    const isProdSite = UTILITIESHELPER.checkIsProdSite()

	useEffect(() => {
        const retreiveResource = async (resource) => {
            try {
                setHeaderData({})
                setHasFetchedHeaderData(false)
                const res = await RETRIEVALSERVICES.retrieveResource(context, resource);
                logs.debug(page.Header, 'retrieveResource', "Header:retrieveResource:res ", res);
                setHeaderData(res.header);
                setHasFetchedHeaderData(true);
            }
            catch (err) {
                setHasFetchedHeaderData(false);
                setHeaderData({});
                logs.error(page.Header, 'Header', ErrorMessages.header + '' + resource, err,{eventId:ErrorCodes.Header});
            }
        }

        //This may cause the header to load, and re-load each time the user switches
        //  This should be handled better so that it does not do that!
        if (!isReadingMode) {
            //Header is the ONLY resource that is NOT language-dependant, but memberFirm-dependant
            retreiveResource("header");
        }
    }, [context.memberFirm, isReadingMode]); //eslint-disable-line

    useEffect(() => {
        if (context.pageRequestURL.endsWith("/settings")) {
            setShouldShowCrumbtrail(false);
        }
        else {
            setShouldShowCrumbtrail(true);
        }

    }, [context.pageRequestURL]);

    return (
        <ShowIf condition={!isReadingMode}>
            <div className={cx("header-section-container", { 'header-content-section': isProdSite})}>
                <HeaderImage headerData={headerData} hasFetchedHeaderData={hasFetchedHeaderData} />
                <HeaderLayout headerData={headerData} shouldShowCrumbtrail={shouldShowCrumbtrail} />
            </div>
            <ShowIf condition={shouldShowCrumbtrail}>
                        <Crumbtrail  />
            </ShowIf>
            
        </ShowIf>
    )
}

export default Header;